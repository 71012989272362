import { ChevronRightIcon, EnvelopeIcon } from "@heroicons/react/20/solid";

export default function EmpProfielRow({ emp, setIsOpen }) {
  return (
    <li key={emp.email}>
      <div className="flex items-center px-4 py-2 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 px-4 grid grid-cols-2 md:grid md:grid-cols-4 md:gap-4">
            <div>
              <p className="text-sm text-gray-900">Alumni: </p>
              <p className="truncate text-sm font-medium text-indigo-600">
                {emp.firstName ?? ""}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span>Email: {emp?.email}</span>
              </p>
            </div>
            <div className="flex flex-center">
              <div className="h-10 w-10 flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={`https://logo.clearbit.com/${emp.companyDomain ?? ""}`}
                  alt=""
                />
              </div>
              <div className="text-indigo-600 pl-2 pt-2">
                {emp.companyName ?? ""}
              </div>
            </div>
          </div>
        </div>
        <div>
          <button
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Book a meeting
          </button>
          {emp.calendlyLink ? (
            <a
              target="_blank"
              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              href={emp.calendlyLink}
            >
              Calendly
            </a>
          ) : (
            <></>
          )}
        </div>
      </div>
    </li>
  );
}
