import { Navigate, useLocation } from "react-router-dom";

const PrivateRouteStudent = ({ loggedInStudentUser, children }) => {
  const location = useLocation();
  const isAuth = localStorage.getItem("isAuth");

  if (!isAuth) {
    return (
      <Navigate to="/signin" replace state={{ path: location.pathname }} />
    );
  }

  return children;
};
export default PrivateRouteStudent;
