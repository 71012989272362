import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { useAuth } from "../../../context/cognitoUser";
import { USER_TYPE } from "../../../constants/constants";
import ShowAndHidePassword from "../../../common/components/ShowAndHidePassword";
import AlertBanner from "../../../common/components/AlertBanner";
import { Auth } from "aws-amplify";

export default function SignUpRec() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [email, setEmail] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const { globalUserType, setGlobalUserType, user, setUser } = useAuth();
  const userAttributes = { "custom:user_type": USER_TYPE.RECRUITER };
  setGlobalUserType(USER_TYPE.RECRUITER);

  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setGlobalUserType(USER_TYPE.RECRUITER);

    try {
      const { user } = await Auth.signUp({
        username: email,
        password: passwordInput,
        attributes: userAttributes,
        autoSignIn: {
          enabled: true,
        },
      });
      await setUser(user);
      navigate("/confirm-email", {
        state: { email: email },
      });
    } catch (error) {
      setIsProcessing(false);
      if (error.name === "UsernameExistsException") {
        alert(error.message);
        navigate("/hire/signin");
        return;
      }
      setErrorMessage(error.message);
      setIsError(true);
      setIsDanger(true);
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-2 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <a href="/">
            <img
              className="mx-auto h-12 w-auto"
              src="/logo512.png"
              alt="FutureForte, Get referral for your dream company with one Click. FutureForte. "
            />
          </a>

          <h2 className=" hidden md:block mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign Up Your Account
          </h2>
          <p className="text-center pt-4">
            Hire the best talent within few minutes
          </p>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <ShowAndHidePassword
                  passwordInput={passwordInput}
                  setPasswordInput={setPasswordInput}
                />
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <a
                    href="/hire/signin"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Have an account already? Log in
                  </a>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  By signing up you agree to our <br />
                  <a
                    href="/toc"
                    target="_blank"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    target="_blank"
                    href="/privacy-policy"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>

              <div>
                <button
                  disabled={isProcessing}
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign Up
                </button>
              </div>
              <AlertBanner
                errorMessage={errorMessage}
                isDanger={isDanger}
                setIsError={setIsError}
                isError={isError}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
