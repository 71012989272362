import React from "react";

import AXIOS_CLIENT from "../../../utils/api_client";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context/cognitoUser";
import { USER_TYPE } from "../../../constants/constants";

function OnboardingMain() {
  const navigate = useNavigate();
  const handleSubmit = (event) => {};
  const {
    globalUserType,
    setGlobalUserType,
    user,
    setUser,
    userSession,
    setUserSession,
  } = useAuth();
  const path = localStorage.getItem("lastLocationForFederatedSignIn");

  const [isError, setIsError] = useState(false);
  const [uid, setUid] = useState();
  const [errorMessage, setErrorMessage] = useState("_");
  const [isDanger, setIsDanger] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState();
  const [s3bucketLink, sets3bucketLink] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    console.log("From redirecting", user);
    if (user) {
      if (path == null || path === "undefined") {
        if (globalUserType === USER_TYPE.STUDENT) {
          navigate("/student/dashboard");
        } else if (globalUserType === USER_TYPE.EMP) {
          navigate("/employee/dashboard");
        } else {
          navigate("/student/dashboard");
        }
      } else {
        navigate(path);
      }
    }
  }, [user]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      sid: uid,
      email: email,
      userName: uid,
      firstName: firstName,
      lastName: lastName,
      resumeLink: s3bucketLink,
    };
    const config = {
      headers: {
        username: uid,
      },
    };

    AXIOS_CLIENT.put(`student/user/${uid}`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setIsError(true);
        setErrorMessage("Your data is successfully updated!");
        setIsProcessing(false);
        navigate("/student/dashboard");
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setIsProcessing(false);
        setErrorMessage(error.toString());
      });
  };

  const singleFileChangedHandler = (event) => {
    if (event.target.files[0]) {
      setIsProcessing(true);
      setSelectedFile(event.target.files[0]);
      setFileName(event.target.files[0].name);

      const data = new FormData();
      // If file selected
      data.append(
        "profileImage",
        event.target.files[0],
        event.target.files[0].name
      );
      AXIOS_CLIENT.post("api/util/resume-upload", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
        .then((response) => {
          if (response?.status && 200 === response?.status) {
            // If file size is larger than expected.
            if (response.data.error) {
              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                setIsDanger(true);
                setErrorMessage("Max size: 2MB");
                setIsError(true);
              } else {
                // If ot the given file typen
                setIsDanger(true);
                setErrorMessage(response.data.error.toString());
                setIsError(true);
              }
            } else {
              // Success
              sets3bucketLink(response.data.location);
              setIsDanger(false);
              setErrorMessage("Resume updated!");
              setIsError(true);
            }
          }
          setIsProcessing(false);
        })
        .catch((error) => {
          // If another error
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
          setIsProcessing(false);
        });
    } else {
      // if file not selected throw error
      setIsDanger(true);
      setErrorMessage("Please upload file");
      setIsError(true);
      setIsProcessing(false);
    }
  };

  return (
    <>
      <p>Redirecting ...</p>
    </>
  );
}

export default OnboardingMain;
