import { useEffect, useState, useRef } from "react";

import SearchableSelection from "../../../SignUp/components/SearchableSelection";
import { companyList } from "../../../../common/consts/Consts";
import { useAuth } from "../../../../context/cognitoUser";
import AXIOS_CLIENT from "../../../../utils/api_client";
import Select from "react-select";
import { fabClasses } from "@mui/material";
import AlertBanner from "../../../../common/components/AlertBanner";

export default function RecProfileForm({
  rid,
  loggedInStudentUser,
  studentSession,
}) {
  const [email, setEmail] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [isCompanyVerified, setIsCompanyVerified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("_");
  const [isDanger, setIsDanger] = useState(false);

  const scrollRef = useRef();

  useEffect(() => {
    if (studentSession?.idToken?.payload?.email)
      setEmail(studentSession?.idToken?.payload?.email);
  }, [studentSession]);

  useEffect(() => {
    if (email) {
      const curDomain = email.substring(email.lastIndexOf("@") + 1);
      setCompanyDomain(curDomain);
    }
  }, [email]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [errorMessage]);

  useEffect(() => {
    if (rid)
      AXIOS_CLIENT.get(`rec/user/${rid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            if (userInfo.email) setEmail(userInfo.email);
            if (userInfo.firstName) setFirstName(userInfo.firstName);
            if (userInfo.lastName) setLastName(userInfo.lastName);
            if (userInfo.companyName) setCompanyName(userInfo.companyName);
            if (userInfo.isCompanyVerified)
              setIsCompanyVerified(userInfo.isCompanyVerified);
            if (userInfo.companyEmail) {
              const email = userInfo.companyEmail;
              const curDomain = email.substring(email.lastIndexOf("@") + 1);
              setCompanyDomain(curDomain);
            }
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
        });
  }, [rid]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      rid: rid,
      uid: rid,
      email: email,
      firstName: firstName,
      lastName: lastName,
      companyDomain: companyDomain,
      companyEmail: email,
      companyName: companyName,
      isCompanyVerified: true,
    };

    AXIOS_CLIENT.put(`rec/user`, userObject)
      .then((res) => {
        setIsDanger(false);
        setErrorMessage(res.data.toString());
        setIsError(true);
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
        setIsProcessing(false);
      });
  };

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSubmitForm}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Enter your information
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              {/* email */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address
                </label>
                <span>{email}</span>
              </div>
              {/* First Name  */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  First name *
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="firstName"
                    required
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>
              {/* Last Name */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Last name *
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    name="lastName"
                    id="lastName"
                    autoComplete="lastName"
                    required
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>

              {/* Compnay Name  */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Company Name *
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                    value={companyName}
                    name="companyName"
                    id="companyName"
                    autoComplete="companyName"
                    required
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              onClick={() => {
                setIsProcessing(false);
              }}
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              disabled={isProcessing}
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
        <div ref={scrollRef}>
          <AlertBanner
            errorMessage={errorMessage}
            isDanger={isDanger}
            setIsError={setIsError}
            isError={isError}
          />
        </div>
      </form>
    </>
  );
}
