import MainNavbar from "../../common/components/MainNavbar";
import JoinOurTeam from "./components/JoinOurTeam";

export default function OpenRoles() {
  return (
    <>
      <MainNavbar />
      <JoinOurTeam />
    </>
  );
}
