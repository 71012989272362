import { useState } from "react";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import MainNavbarFloating from "../../../common/components/MainNavbarFloating";
import CoverLetterGenForm from "./components/CoverLetterGenForm";

const features = [
  {
    name: "Automated Cover Letter Generation",
    imageSrc:
      "https://cdn-icons-png.flaticon.com/128/2043/2043043.png",
    description:
      "CoverLetterGen utilizes advanced algorithms to automatically generate customized cover letters based on the user's resume and the job description.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Personalized Content",
    imageSrc: 
      "https://cdn-icons-png.flaticon.com/128/5050/5050214.png",
    description:
      "The tool tailors each cover letter to highlight the candidate's relevant skills, experiences, and qualifications, ensuring a compelling match with the specific job requirements.",
    icon: LockClosedIcon,
  },
  {
    name: "Time-Saving Efficiency",
    imageSrc: 
      "https://cdn-icons-png.flaticon.com/128/8890/8890404.png",
    description:
      "Say goodbye to hours spent crafting cover letters from scratch. With CoverLetterGen, users can create professional cover letters in seconds, freeing up valuable time for other job search activities.",
    icon: ArrowPathIcon,
  },
  {
    name: "Job Description Integration",
    imageSrc: 
      "https://cdn-icons-png.flaticon.com/128/6808/6808956.png",
    description:
      " CoverLetterGen dynamically incorporates key phrases and keywords from the job description into the cover letter, demonstrating a strong alignment with the employer's requirements",
    icon: FingerPrintIcon,
  },
];

const faqs = [
  {
    id: 1,
    question: "Is it FREE?",
    answer: "Yes, absolutely. It's FREE for our users.",
  },
  {
    id: 2,
    question: "Are you storing my data?",
    answer:
      "No, we are not storing your data. Your data will be automatically removed from the server.",
  },
  {
    id: 3,
    question: "How does the CoverLetterGen tool work?",
    answer:
      "The CoverLetterGen uses custom AI 🌟 models to evaluate your resume and job description. It then generates a customized cover letter that highlights your relevant skills and experiences, tailored specifically for the job you're applying to.",
  },
  {
    id: 4,
    question:
      "Does CoverLetterGen support different industries and job positions?",
    answer:
      "Yes, CoverLetterGen is designed to cater to various industries and job positions. It takes into account the specific requirements mentioned in the job description and crafts a cover letter that aligns with those requirements, ensuring relevance and effectiveness across different fields.",
  },
];

export default function CoverLetterGen() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div className="bg-white">
      <MainNavbarFloating />

      <main className="isolate">
        {/* Hero section */}
        <div className="relative pt-8">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="py-16 sm:pt-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  CoverLetterGen &#128394;
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Generate tailored made cover letter for job requirements. Our
                  AI will analyze your resume, job decription and generate
                  simple yet effective cover letter.
                </p>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>

        {/* Main Section */}
        <CoverLetterGenForm />

        {/* Feature section */}
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-32 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Your Personalized Cover Letters in Seconds!
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Generate feedback for your resume. Our AI will analyze your resume
              and provide you with actionable feedback on how to improve your
              resume
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-12 w-12 items-center justify-center rounded-lg bg-indigo-100">
                      <img src={feature.imageSrc}
                        className="h-8 w-8 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* FAQs */}
        <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
              >
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                  {faq.question}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {/* CTA section */}
        <div className="relative -z-10 mt-16 px-6 lg:px-8">
          <div
            className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
              style={{
                clipPath:
                  "polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Get job referral for your
              <br />
              dream company
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
              Looking for a referral: First, complete your profile and select
              the companies you want to get referrals for. Your profile will
              then be visible to employees of those companies and they will be
              able to refer you. No more cold emails or messages on LinkedIn
              required.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="/signup"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Get started
              </a>
              <a
                href="/signup"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div
            className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>
      </main>
    </div>
  );
}
