import axios from "axios";
import { useEffect, useState } from "react";
import { USER_TYPE } from "../../../../../../constants/constants";
import AXIOS_CLIENT from "../../../../../../utils/api_client";
import "./conversation.css";

export default function Conversation({ currentChat, conversation, currentUser, globalUserType}) {
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState(' ');
  const [companyName, setCompanyName] = useState(' ');
  const [tn, setTn] = useState('/image6.png');

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  useEffect(() => {
    const friendId = conversation.members.find((m) => m !== currentUser.username);

    const getUser = async () => {
      try {
      
        if(globalUserType === USER_TYPE.STUDENT){
          var res = await AXIOS_CLIENT(`emp/user/${friendId}`);
          setUser( res.data.data[0]);
          setFirstName(res.data.data[0].firstName);
          if(res.data.data[0].companyName)setCompanyName(res.data.data[0].companyName);
          if(res.data.data[0].profilePicThumnail)setTn(res.data.data[0].profilePicThumnail);
        }
        else{
          var res = await AXIOS_CLIENT(`student/user/${friendId}`);
          setFirstName(res.data.data[0].firstName);
          if(res.data.data[0].school) setCompanyName(res.data.data[0].school);
          setUser( res.data.data[0]);
          if(res.data.data[0].profilePicThumnail)setTn(res.data.data[0].profilePicThumnail);
        }
       
      } catch (err) {
      }
    };
    getUser();
  }, [currentUser, conversation]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className={classNames( currentChat == conversation ? "conversation bg-gray-100": "conversation", "grid grid-rows-2 md:grid md:grid-cols-2")}

    >
      <img
        className="conversationImg"
 src ={tn}
        // src={
        //   user?.profilePicture
        //     ? PF + user.profilePicture
        //     : PF + "person/noAvatar.png"
        // }
        alt=""
      />
      <div className="flex flex-col">
      <span className="conversationName">{firstName}</span>
     <span className="conversationName text-sm text-gray-500">{companyName}</span>
   
   </div> 
   </div>
  );
}
