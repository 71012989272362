import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import {
  Bars3BottomLeftIcon,
  BellIcon,
  InboxIcon,
  XMarkIcon,
  Cog6ToothIcon,
  UsersIcon,
  PaperAirplaneIcon,
  ListBulletIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";

import { useAuth } from "../../context/cognitoUser";
import InMaking from "../../common/in_making/InMaking";
import SettingsMainRec from "./tabs/settings/RecSettings";
import RecProfileForm from "./tabs/profile/RecProfileMain";
import PostJobMain from "./tabs/postJob/PostJobMain";
import JobsListMain from "./tabs/jobs/JobsList";
import Messenger from "../student/dashboard/messenger/Messenger";
import { Auth } from "aws-amplify";

const navigation = [
  { name: "Post Job", href: "#", icon: PaperAirplaneIcon, current: true },
  { name: "Jobs", href: "#", icon: ListBulletIcon, current: false },
  { name: "Candidates", href: "#", icon: UserPlusIcon, current: false },
  { name: "Messages", href: "#", icon: InboxIcon, current: false },
  { name: "Profile", href: "#", icon: UsersIcon, current: false },
  { name: "Settings", href: "#", icon: Cog6ToothIcon, current: false },
];
const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RecDashboardMain() {
  const {
    globalUserType,
    setGlobalUserType,
    user,
    setUser,
    userSession,
    setUserSession,
  } = useAuth();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentNav, setCurrentNav] = useState("Post Job");

  const [rid, setRid] = useState();
  const [email, setEmail] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) setRid(user.username);
  }, [user]);

  useEffect(() => {
    if (userSession) {
      setEmail(userSession.idToken.payload.email);
    }
  }, [userSession]);

  function mainContent() {
    switch (currentNav) {
      case "Post Job":
        return (
          <PostJobMain email={email} rid={rid} setCurrentNav={setCurrentNav} />
        );

      case "Settings":
        return (
          <SettingsMainRec
            email={email}
            rid={rid}
            setCurrentNav={setCurrentNav}
          />
        );

      case "Messages":
        return <Messenger />;

      case "Candidates":
        return <Messenger />;

      case "Jobs":
        return <JobsListMain rid={rid} />;

      case "Profile":
        return <RecProfileForm rid={rid} />;
      case "Sign out": {
        async function signOut() {
          try {
            await Auth.signOut();
            setUser(null);
            localStorage.removeItem("userType");
            localStorage.removeItem("isAuth");
            localStorage.removeItem("accessToken");
            navigate("/");
          } catch (error) {
            console.log("error signing out: ", error);
          }
        }
        signOut();
      }
      default:
        return <InMaking />;
    }
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex flex-shrink-0 items-center px-4">
                    <a href="/">
                      <span className="sr-only">FutureForte</span>
                      <img
                        alt="FutureForte: Democratize career guidance for college students "
                        className="h-8 w-10 sm:h-10"
                        src="/logo512.png"
                      />
                    </a>
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation.map((item) => (
                        <button
                          key={item.name}
                          onClick={() => {
                            setCurrentNav(item.name);
                          }}
                          className={classNames(
                            item.name === currentNav
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-white pt-5">
            <div className="flex flex-shrink-0 items-center px-4">
              <a href="/">
                <span className="sr-only">FutureForte</span>
                <img
                  alt="FutureForte: Democratize career guidance for college students "
                  className="h-8 w-10 sm:h-10"
                  src="/logo512.png"
                />
              </a>
            </div>

            <div className="mt-5 flex flex-grow flex-col">
              <nav className="flex-1 space-y-1 px-2 pb-4">
                {navigation.map((item) => (
                  <a
                    onClick={() => {
                      setCurrentNav(item.name);
                    }}
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.name === currentNav
                        ? "bg-gray-100 text-gray-900"
                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1"></div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <span className="sr-only">View Notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>

                      <img
                        className="h-8 w-10 rounded-full"
                        src="/image6.png"
                        alt="FutureForte: Democratize career guidance for college students "
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              onClick={() => {
                                setCurrentNav(item.name);
                              }}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {currentNav}
                </h1>
              </div>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                {mainContent()}

                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
