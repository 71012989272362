import { Menu } from "@headlessui/react";
import {
  BarsArrowUpIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import RegistrationImage from "../../contest/registration/RegistrationImage";

const projects = [
  {
    name: "ResumeGPT ✨",
    desc: "Generate feedback for your resume. Get actionable feedback on how to improve your resume",
    href: "/services/resume-review",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Resume"],
    starred: true,
    active: true,
    logo: "onlyreferrals.co",
  },

  {
    name: "CoverLetterGen 🖊",
    desc: "enerate tailored made cover letter for job requirements.",
    href: "/services/cover-letter",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Resume"],
    starred: true,
    active: true,
    logo: "onlyreferrals.co",
  },

  {
    name: "Fall '23 Internship",
    desc: "List of fall '23 internship curated by FutureForte",
    href: "/learn/fall-23-internship-jobboard",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Internship", "New-grad"],
    starred: true,
    active: true,
    logo: "onlyreferrals.co",
  },
  {
    name: "Summer '24 Internship",
    desc: "List of summer '24 internship curated by FutureForte",
    href: "/learn/summer-24-internship-jobboard",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Internship", "New-grad"],
    starred: true,
    active: true,
    logo: "onlyreferrals.co",
  },
  {
    name: "Tech Conferences",
    desc: "List of tech conferences in USA",
    href: "/learn/tech-conferences",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "Tech Conferences",
    location: "United states",
    tags: ["Tech conferences"],
    starred: true,
    active: true,
    logo: "onlyreferrals.co",
  },
  {
    name: "YC Startup",
    desc: "Opportunities for new-grad and internship at YC companies",
    href: "https://www.workatastartup.com/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Startup", "Tech"],
    starred: true,
    active: true,
    logo: "workatastartup.com",
  },
  {
    name: "Startup.jobs",
    desc: "Explore jobs in startups",
    href: "https://startup.jobs/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Startup", "Tech"],
    starred: true,
    active: true,
    logo: "startup.jobs",
  },
  {
    name: "Startup Search by Contrary Venture",
    desc: "Discover best opportunties in startup",
    href: "https://startupsearch.com/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Startups", "Tech"],
    starred: true,
    active: true,
    logo: "startupsearch.com",
  },

  {
    name: "Otta's Curated Job Board",
    desc: "Take the survey and find the perfect job",
    href: "https://otta.com/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Design", "Tech", "Software Engineering"],
    starred: true,
    active: true,
    logo: "otta.com",
  },

  {
    name: "Job Board by Simplify",
    desc: "Browse opportunities compiled by Simplify",
    href: "https://simplify.jobs/lists",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Internships", "New-grad", "Tech"],
    starred: true,
    active: true,
    logo: "simplify.jobs",
  },
  {
    name: "Wealthfront",
    desc: "Browse opportunities compiled by Wealthfront",
    href: "https://blog.wealthfront.com/career-launching-companies-list/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Startup", "Tech"],
    starred: true,
    active: true,
    logo: "wealthfront.com",
  },

  {
    name: "Creative Morning",
    desc: "Browse opportunities compiled by Creative Morning",
    href: "https://creativemornings.com/jobs",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Tech", "Design"],
    starred: true,
    active: true,
    logo: "creativemornings.com",
  },

  {
    name: "Cryptocurrency",
    desc: "Explore web3 and blockchain related jobs",
    href: "https://cryptocurrencyjobs.co/web3/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Crypto", "Blockchain", "Tech"],
    starred: true,
    active: true,
    logo: "cryptocurrencyjobs.co",
  },
  {
    name: "Crypto Job List",
    desc: "Explore jobs in web3, blockchain and De-Fi",
    href: "https://cryptojobslist.com/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Crypto", "Blockchain", "Tech"],
    starred: true,
    active: true,
    logo: "cryptojobslist.com",
  },

  {
    name: "Behance Job Board",
    desc: "Jobs baord by Behance for design",
    href: "https://www.behance.net/joblist",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Design"],
    starred: true,
    active: true,
    logo: "behance.net",
  },

  {
    name: "Coroflot Job Board",
    desc: "Explore opportunites Coroflot platform",
    href: "https://www.coroflot.com/design-jobs",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Design"],
    starred: true,
    active: true,
    logo: "coroflot.com",
  },
  {
    name: "Dribble's Job Board",
    desc: "The #1 job board for graphic design",
    href: "https://dribbble.com/jobs",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Design"],
    starred: true,
    active: true,
    logo: "dribbble.com",
  },

  {
    name: "Ideate Labs",
    desc: "UI UX jobs curated by Ideate Labs",
    href: "https://www.ideatelabs.co/job-board",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Design"],
    starred: true,
    active: true,
    logo: "ideatelabs.co",
  },

  {
    name: "AIGA's Design Jobs",
    desc: "Opportunities curated by Professional Association of Design",
    href: "https://designjobs.aiga.org/",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Design"],
    starred: true,
    active: true,
    logo: "aiga.org",
  },

  {
    name: "John Cannon's VC Jobs",
    desc: "Opportunities in the VC firm",
    href: "https://johngannonblog.com/venture-capital-jobs-",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["VC", "Startup"],
    starred: true,
    active: true,
    logo: "johngannonblog.com",
  },
  {
    name: "Startup and VC",
    desc: "Explore jobs in VC firm",
    href: "https://www.startupandvc.com/venture-capital-jobs",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["VC", "Startup"],
    starred: true,
    active: true,
    logo: "startupandvc.com",
  },

  {
    name: "WAVE Job Board",
    desc: "Job-board by Women's Association of Venture and Equity ",
    href: "https://women-wave.org/classifieds.php?type=job",
    siteHref: "#",
    repoHref: "#",

    tech: "",
    lastDeploy: "New grad and Internship",
    location: "United states",
    tags: ["Women in Tech", "Tech"],
    starred: true,
    active: true,
    logo: "starchapter.com/",
  },
];

function renderList(arr) {
  return arr ? (
    <ul role="list" className=" divide-gray-200">
      {arr.map((item) => (
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
          {item}
        </span>
      ))}
    </ul>
  ) : (
    <></>
  );
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function JobDashboardCenterColumn() {
  return (
    <div className="bg-white lg:min-w-0 lg:flex-1">
      {/* List of contest */}
      {/* <div>
        <RegistrationImage />
      </div> */}

      {/* <h1>Top Job Board</h1> */}
      <div className="border-b border-t border-gray-200 pl-4 pr-6 pt-4 pb-4 sm:pl-6 lg:pl-8 xl:border-t-0 xl:pl-6 xl:pt-6"></div>

      {/* List of job boards */}
      <ul
        role="list"
        className="divide-y divide-gray-200 border-b border-gray-200"
      >
        {projects.map((project) => (
          <li
            key={project.href}
            className="relative py-5 pl-4 pr-6 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
          >
            <div className="flex items-center justify-between space-x-4">
              {/* Repo name and link */}
              <div className="min-w-0 space-y-3">
                <div className="flex items-center space-x-3">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={`https://logo.clearbit.com/${project.logo}`}
                        alt=""
                      />
                    </div>
                  </div>

                  <h2 className="text-sm font-medium">
                    <a target="_blank" href={project.href}>
                      <span className="absolute inset-0" aria-hidden="true" />
                      {project.name}{" "}
                      <span className="sr-only">
                        {project.active ? "Running" : "Not running"}
                      </span>
                    </a>
                  </h2>
                </div>
                <span className="group relative flex items-center space-x-2.5 text-sm font-medium text-gray-500">
                  {project.desc}
                </span>
              </div>
              <div className="sm:hidden">
                <ChevronRightIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              {/* Repo meta info */}
              <div className="hidden flex-shrink-0 flex-col items-end space-y-3 sm:flex">
                <p className="flex items-center space-x-4">
                  <a
                    href={project.siteHref}
                    className="relative text-sm font-medium text-gray-500 hover:text-gray-900"
                  >
                    Visit site
                  </a>
                  <button
                    type="button"
                    className="relative rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">
                      {project.starred
                        ? "Add to favorites"
                        : "Remove from favorites"}
                    </span>
                    <StarIcon
                      className={classNames(
                        project.starred
                          ? "text-yellow-300 hover:text-yellow-400"
                          : "text-gray-300 hover:text-gray-400",
                        "h-5 w-5"
                      )}
                      aria-hidden="true"
                    />
                  </button>
                </p>
                <p className="flex space-x-2 text-sm text-gray-500">
                  <span>{project.tech}</span>

                  {renderList(project.tags)}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
