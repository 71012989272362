import { useEffect } from "react";
import StudentDashboardMain from "../../../student/dashboard/StudentDashboardMain";
import UniAdminDashboardMain from "./UniAdminDashboardMain";
export default function UniAdminDashboard() {
  const TITLE = "FutureForte: Democratize career guidance for college students";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return <UniAdminDashboardMain />;
}
