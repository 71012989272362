import { useEffect, useState, useRef } from "react";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import { Switch } from "@headlessui/react";
import PortfolioFormRow from "../../../../student/dashboard/profile/components/PortfolioFormRow";
import AlertBanner from "../../../../../common/components/AlertBanner";
import Select from "react-select";
import { MENTORSHIP_SERVICES } from "../../../../../common/consts/Consts";

export default function EmpMentorServices({ eid, email }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("_");
  const [isDanger, setIsDanger] = useState(false);
  const scrollRef = useRef();
  const [isOpenToMentorship, setIsOpenToMentorship] = useState(false);
  const [topmateLink, setTopmateLink] = useState();
  const [calendlyLink, setCalendlyLink] = useState();
  const [personalMeetingLink, setPersonalMeetingLink] = useState();
  const [empInfo, setEmpInfo] = useState();
  const [mentorshipServices, setMentorshipServices] = useState();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [errorMessage]);

  useEffect(() => {
    if (eid)
      AXIOS_CLIENT.get(`emp/user/${eid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            setEmpInfo(response.data.data[0]);
            if (userInfo.isOpenToMentorship)
              setIsOpenToMentorship(userInfo.isOpenToMentorship);
            if (userInfo.personalMeetingLink)
              setPersonalMeetingLink(userInfo.personalMeetingLink);
            if (userInfo.calendlyLink) setCalendlyLink(userInfo.calendlyLink);
            if (userInfo.topmateLink) setTopmateLink(userInfo.topmateLink);
            if (userInfo.mentorshipServices)
              setMentorshipServices(userInfo.mentorshipServices);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
        });
  }, [eid]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      eid: eid,
      email: email,
      isOpenToMentorship: isOpenToMentorship,
      topmateLink: topmateLink,
      calendlyLink: calendlyLink,
      isCompanyVerified: true,
      mentorshipServices: mentorshipServices,
    };

    const config = {
      headers: {
        username: eid,
      },
    };

    AXIOS_CLIENT.put(`emp/user/${eid}`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setErrorMessage(res.data.toString());
        setIsError(true);
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
        setIsProcessing(false);
      });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <form
        className="space-y-4 divide-y divide-gray-200"
        onSubmit={onSubmitForm}
      >
        {/* Mentorship details */}
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Mentorship
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            You can add topmate, calendly or geeksandexpert link.
          </p>
        </div>

        {/* Open to mentor */}
        <div className="pt-4 flex">
          <label className="text-gray-900">Are you open to mentorship?</label>

          <div className="pl-4">
            <Switch.Group as="div" className="flex flex-row items-center">
              <Switch
                data-te-toggle="tooltip"
                title=" If you get an email for successful referral then pls confirm
                      it will udpate your total open referral count and allow you
                      request more."
                checked={isOpenToMentorship}
                onChange={() => {
                  if (isProcessing === false) {
                    setIsOpenToMentorship(!isOpenToMentorship);
                  }
                }}
                className={classNames(
                  isOpenToMentorship ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    isOpenToMentorship ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>

              <Switch.Label as="span" className="ml-3 text-sm">
                <span className="font-medium text-gray-900">
                  {isOpenToMentorship ? "Yes" : "No"}
                </span>
              </Switch.Label>
            </Switch.Group>
          </div>
        </div>

        {isOpenToMentorship ? (
          <div className="pt-4 space-y-4 sm:space-y-2">
            {/* List of services */}
            <div className="sm:grid sm:grid-cols-3  sm:items-start sm:gap-2  ">
              <div className="flex flex-col">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium my-0 sm:mt-px sm:pt-1 py-0"
                >
                  Mentorships
                </label>
                <p className="text-xs text-gray-500 py-2 px-2">
                  Add services that you are offering to students.
                </p>
              </div>
              <Select
                defaultValue={mentorshipServices}
                isMulti
                name="colors"
                options={MENTORSHIP_SERVICES}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={setMentorshipServices}
              />
            </div>

            {/* Perosonal meeting link */}
            <div className="sm:grid sm:grid-cols-3  sm:items-start sm:gap-2  ">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-1"
              >
                Personal meeting link
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0">
                <input
                  onChange={(e) => {
                    setPersonalMeetingLink(e.target.value);
                  }}
                  value={personalMeetingLink}
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="firstName"
                  placeholder="https://zoom.us/my/testing"
                  className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                />
              </div>
            </div>
            {/* Topmate  */}
            <PortfolioFormRow
              title="Topmate"
              value={topmateLink}
              setValue={setTopmateLink}
              placeholder="https://topmate.io/tony_stark"
            />
            {/* Calendly  */}
            <PortfolioFormRow
              title="Calendly"
              value={calendlyLink}
              setValue={setCalendlyLink}
              placeholder="https://calendly.com/tony_stark/30min"
            />
          </div>
        ) : (
          <></>
        )}

        <div className="pt-5">
          <div className="flex justify-center">
            <button
              disabled={isProcessing}
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              disabled={isProcessing}
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
        <div ref={scrollRef}>
          <AlertBanner
            errorMessage={errorMessage}
            isDanger={isDanger}
            setIsError={setIsError}
            isError={isError}
          />
        </div>
      </form>
    </>
  );
}
