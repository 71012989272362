import JoinOurTeam from "../../openRoles/components/JoinOurTeam";

const faqs = [
  {
    id: 0,
    question: "What is FutureForte? ",
    answer:
      "FutureForte is a platform that connects students with alumni to facilitate career guidance and mentorship. It aims to bridge the gap between students seeking valuable insights for their career paths and alumni who can provide industry expertise and guidance.",
  },
  {
    id: 1,
    question: "What's the best thing about FutureForte?",
    answer:
      "No more cold emails or messages on LinkedIn needed for job referrals and career guidance. Show your interest in companies and your profile will then be visible to alumni working for those companies.",
  },
  {
    id: 2,
    question: "How does FutureForte work?",
    answer:
      "FutureForte operates by creating a network of alumni and students. Students can create profiles, outline their career goals, and seek mentorship from alumni. Alumni, on the other hand, can offer guidance, share experiences, and connect with students who match their areas of expertise.",
  },
  {
    id: 3,
    question: "How can I benefit as a student on FutureForte? ",
    answer:
      "As a student on FutureForte, you gain access to a network of experienced alumni who can provide valuable career guidance, share industry insights, offer mentorship, and potentially help with job referrals. It is an opportunity to tap into the wisdom and expertise of professionals who have walked a similar path.      ",
  },
  {
    id: 4,
    question: "What advantages does FutureForte offer to alumni?     ",
    answer:
      "For alumni, FutureForte provides an avenue to give back to their alma mater by offering mentorship and guidance to students. It allows them to share their professional experiences, contribute to the growth of future professionals, and expand their own network within the student community.    ",
  },
  {
    id: 5,
    question: "How can I join FutureForte? ",
    answer:
      "To join FutureForte, you can sign up as either a student or an alumnus/alumna through the platform's registration process. Once registered, you can create a profile, specify your interests, and start connecting with the respective user group.      ",
  },
  {
    id: 6,
    question: "Is it FREE to use the FutureForte for career guidance?",
    answer: "Yes, FutureForte is FREE for students and employees to use.",
  },
];

export default function FaqSection() {
  return (
    <div className="bg-white">
      <main>
        <div className="bg-blue-gray-50">
          {/* </section> */}

          {/* FAQ */}
          <section
            className="mx-auto max-w-md divide-y-2 divide-blue-gray-200 py-24 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8"
            aria-labelledby="faq-heading"
          >
            <h2
              className="text-3xl font-bold tracking-tight text-blue-gray-900"
              id="faq-heading"
            >
              Frequently asked questions
            </h2>
            <div className="mt-6 pt-10">
              <dl className="space-y-10 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-x-8 md:gap-y-12 md:space-y-0">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="text-lg font-medium text-blue-gray-900">
                      {faq.question}
                    </dt>
                    <dd className="mt-2 text-base text-blue-gray-500">
                      {faq.answer}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </section>
        </div>

        {/* CTA Section */}
        <JoinOurTeam />

        {/* Newsletter Section */}
        <section
          className="mx-auto max-w-md divide-y-2 divide-blue-gray-200 py-24 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-32 lg:px-8"
          aria-labelledby="faq-heading"
        >
          <div className="flex flex-row items-center justify-around py-4 px-4 sm:px-6 lg:px-8 max-md:px-2">
            <div className="lg:w-0 lg:flex-1">
              <h2
                className="text-3xl font-bold tracking-tight text-blue-gray-900 sm:text-4xl"
                id="newsletter-heading"
              >
                Join our WhatsApp community for trending jobs.
              </h2>
              <p className="max-md:hidden mt-3 max-w-3xl text-lg text-blue-gray-500">
                Every week, we send out different blogs that help you get jobs
                and internships. These blogs include topics such as how to make
                a resume, interview preparation tips, and interview experiences.
                Additionally, we also post about trending job and internship
                opportunities.
              </p>
            </div>
            <div>
              <button
                type="submit"
                onClick={() =>
                  window.open(
                    "https://chat.whatsapp.com/EePx1vnurLpC0mA0xBCpZl",
                    "_blank"
                  )
                }
                className="flex w-full items-center rounded-md border border-transparent bg-indigo-600 py-2.5 px-3.5 text-l max-md:text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                WhatsApp Group&nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />{" "}
                </svg>
              </button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
