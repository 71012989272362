import { useEffect, useState } from "react";
import CopyCliboard from "../../../../common/components/CopyClipboard";

export default function RenderCoverLetter({ cvHeader, cvBody }) {
  const [fullText, setFullText] = useState();
  useEffect(() => {
    setFullText(`${cvHeader}
  ${cvBody}`);
  }, [cvHeader, cvBody]);
  return cvHeader ? (
    <div className="border shadow rounded-md p-8 mt-4 bg-neutral-50">
      <div className="flex flex-row">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Here's your personalised cover letter 🎉
        </h1>
        <CopyCliboard text={fullText} />
      </div>
      <p className="text-xl leading-8 text-gray-700 mt-2">{cvHeader}</p>
      <span className="whitespace-pre-line">{cvBody} </span>
    </div>
  ) : (
    <></>
  );
}
