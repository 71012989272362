import { useEffect, useState } from "react";

import EmpProfielRow from "./components/EmpProfielRow";
import AXIOS_CLIENT from "../../../../../../utils/api_client";
import LoadingPulse from "../../../../../../common/components/LoadingPulse";
import AlertBanner from "../../../../../../common/components/AlertBanner";
import MeetingModalOuter from "./components/MeetingModalOuter";

export default function StudentCGHistory({ sid, email }) {
  const [emps, setEmps] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [curIndex, setCurIndex] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AXIOS_CLIENT.get(`student/api/referral/get_accepted_cgc`, {
      params: { sid: sid },
    })
      .then((response) => {
        if (response.data.data.length > 0) {
          setEmps(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
      });
  }, []);

  const mainContent = emps ? (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {emps.map((emp, index) => (
          <EmpProfielRow emp={emp} isOpen={isOpen} setIsOpen={setIsOpen} />
        ))}
      </ul>
    </div>
  ) : (
    <>Cannot find any mentor who has accepted your request.</>
  );

  return isLoading ? (
    <LoadingPulse />
  ) : (
    <div className="pt-4">
      {mainContent}
      <MeetingModalOuter isOpen={isOpen} setIsOpen={setIsOpen} />

      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </div>
  );
}
