import { useEffect } from "react";
import RecDashboardMain from "./RecDashboardMain";

export default function RecDashboard() {
  const TITLE = "FutureForte: Hire the best talents within few minutes";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return <RecDashboardMain />;
}
