/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const incentives = [
  {
    name: "Philanthropy and Giving Back",
    imageSrc:
      "https://cdn-icons-png.flaticon.com/128/3047/3047883.png",
    description:
      "By contributing to students' professional growth, alumni feel a sense of fulfillment in giving back to their alma mater. Alumni who participate in University events are 3x more likely to donate.",
  },
  {
    name: "Continuous Engagement",
    imageSrc:
      "https://cdn-icons-png.flaticon.com/128/11182/11182611.png",
    description:
      "FutureForte encourages ongoing engagement between alumni and the university community, strengthening the bond between both parties.",
  },
  {
    name: "Alumni Success Stories",
    imageSrc:
      "https://cdn-icons-png.flaticon.com/128/4728/4728607.png",
    description:
      "The platform highlights alumni success stories, inspiring and motivating students while showcasing the university's impact on careers.",
  },
];

export default function FosterAlumniEng() {
  return (
    <div className="bg-gray-50 mt-8">
      <div className="mx-auto max-w-7xl py-24 sm:px-2 sm:py-16 lg:px-4">
        <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
          <div className="grid grid-cols-1 items-center gap-x-16 gap-y-10 lg:grid-cols-2">
            <div>
              <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                Foster Alumni Engagement
              </h2>
              <p className="mt-4 text-gray-500">
                FutureForte fosters alumni engagement by providing a dedicated
                platform for alumni to connect with students, offer mentorship,
                and share their expertise. It creates opportunities for
                meaningful interactions, networking, and knowledge sharing,
                strengthening the bond between alumni and University community.
              </p>
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg bg-gray-100">
              <img
                src="https://images.unsplash.com/photo-1561489413-985b06da5bee?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=870&q=80"
                alt=""
                className="object-cover object-center"
              />
            </div>
          </div>
          <div className="mt-16 grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
            {incentives.map((incentive) => (
              <div key={incentive.name} className="sm:flex lg:block">
                <div className="sm:flex-shrink-0">
                  <img className="h-16 w-16" src={incentive.imageSrc} alt="" />
                </div>
                <div className="mt-4 sm:ml-6 sm:mt-0 lg:ml-0 lg:mt-6">
                  <h3 className="text-sm font-medium text-gray-900">
                    {incentive.name}
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    {incentive.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
