export default function PortfolioFormRow({
  title,
  value,
  setValue,
  placeholder,
}) {
  return (
    <div className="sm:grid sm:grid-cols-3  sm:items-start sm:gap-2  ">
      <label
        htmlFor="firstName"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-1"
      >
        {title}
      </label>
      <div className="mt-1 sm:col-span-2 sm:mt-0">
        <input
          onChange={(e) => {
            setValue(e.target.value);
          }}
          value={value}
          type="text"
          name="firstName"
          id="firstName"
          autoComplete="firstName"
          placeholder={placeholder}
          className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
        />
      </div>
    </div>
  );
}
