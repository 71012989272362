import React, { useEffect } from "react";
import SignUpMain from "./components/SignUpMain";

export default function SignUp() {
  const TITLE = "FutureForte: Singup and get job referral with one click";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-1 md:gap-8">
        <SignUpMain />
      </div>
    </div>
  );
}
