import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import LinkAndTextRow from "./LinkAndTextRow";
import RenderListStudentOverlay from "./RenderListStudentOverlay";
import AXIOS_CLIENT from "../../../../../../utils/api_client";
import { EMP_DASHBOARD_NAV } from "../../../../../../constants/constants";
import CopyCliboard from "../../../../../../common/components/CopyClipboard";
import { useNavigate } from "react-router-dom";

export default function StudentProfileOverlay({
  index,
  isOpen,
  setIsOpen,
  selectedSid,
  setSelectedSid,
  referral,
  eid,
  removeElement,
  isEnable,
  setIsError,
  setErrorMessage,
  setIsDanger,
}) {
  const [name, setName] = useState("");
  const [studentInfo, setStudentInfo] = useState();
  const [about, setAbout] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [resumeLink, setResumeLink] = useState("");
  const [tools, setTools] = useState([]);
  const [skills, setSkills] = useState([]);
  const [linkedInUrl, setLinkedInUrl] = useState("");
  const [githubUrl, setGithubUrl] = useState("");
  const [portfolioUrl, setPortfolioUrl] = useState("");
  const [otherUrls, setOtherUrls] = useState([]);
  const [workExs, setWorkExs] = useState([]);
  const [educations, setEducations] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Need to update this
    setEmail("");
    setName("");
    setContact("");
    setAbout("");
    setResumeLink("");
    setTools();
    setSkills();
    setLinkedInUrl();
    setGithubUrl();
    setPortfolioUrl();
    setWorkExs();
    setEducations();

    if (selectedSid) {
      AXIOS_CLIENT.get(`student/user/${selectedSid}`)
        .then((response) => {
          if (response.data.data.length >= 0) {
            const userInfo = response.data.data[0];
            setStudentInfo(userInfo);
            if (userInfo.firstName && userInfo.lastName)
              setName(userInfo.firstName + " " + userInfo.lastName);
            if (userInfo.email) setEmail(userInfo.email);
            if (userInfo.contact) setContact(userInfo.contact);
            if (userInfo.about) setAbout(userInfo.about);
            if (userInfo.resumeLink) setResumeLink(userInfo.resumeLink);
            if (userInfo.skills) {
              setSkills(userInfo.skills);
            }
            if (userInfo.tools) setTools(userInfo.tools);
            if (userInfo.linkedInUrl) setLinkedInUrl(userInfo.linkedInUrl);
            if (userInfo.githubUrl) setGithubUrl(userInfo.githubUrl);
            if (userInfo.portfolioUrl) setPortfolioUrl(userInfo.portfolioUrl);
            if (userInfo.otherUrls) setOtherUrls(userInfo.otherUrls);
            if (userInfo.workExs) setWorkExs(userInfo.workExs);
            if (userInfo.educations) setEducations(userInfo.educations);
          }
        })
        .catch((error) => {});
    }
  }, [selectedSid]);

  const onAcceptReferral = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = referral;
    userObject["eid"] = eid;
    userObject["referralsAccepted"] = true;

    const config = {
      headers: {
        username: eid,
      },
    };

    await AXIOS_CLIENT.put(
      `emp/api/referral/accept_referral/${referral._id}`,
      userObject,
      config
    )
      .then((res) => {
        removeElement(index);
        try {
          AXIOS_CLIENT.put(`api/conversations/emp`, referral)
            .then((res) => {
              setIsProcessing(false);
              navigate("/employee/dashboard/messages");
            })
            .catch((err) => {});
        } catch (err) {
          setIsProcessing(false);
        }
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setErrorMessage("Error while processing your request");
        setIsProcessing(false);
      });
  };

  const renderJobIds = referral?.jobIds ? (
    <ul role="list" className="divide-gray-200">
      {referral.jobIds.map((item) => (
        <li key={item}>
          <div className="flex flex-row">
            <CopyCliboard text={item} />

            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              {item}
            </p>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Student Information
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setIsOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {/* Replace with your content */}
                        {/* <div
                          className="h-full border-2 border-dashed border-gray-200"
                          aria-hidden="true"
                        /> */}
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Applicant Information
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                              Personal details and application.
                            </p>
                          </div>
                          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Full name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {name}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Email address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {email}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Contact
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {contact}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Application for
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {renderJobIds}
                                </dd>
                              </div>

                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  About
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {about}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Attachments
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <ul
                                    role="list"
                                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                  >
                                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                      <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon
                                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 w-0 flex-1 truncate">
                                          Resume
                                        </span>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        {resumeLink ? (
                                          <a
                                            href={resumeLink}
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                          >
                                            Download
                                          </a>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Other Information
                            </h3>
                          </div>
                          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <LinkAndTextRow
                                label="LinkedIn"
                                value={linkedInUrl}
                              />
                              <LinkAndTextRow
                                label="Github"
                                value={githubUrl}
                              />
                              <LinkAndTextRow
                                label="Portfolio"
                                value={portfolioUrl}
                              />
                              <RenderListStudentOverlay
                                arr={skills}
                                label="Skills"
                              />
                              <RenderListStudentOverlay
                                arr={tools}
                                label="Tools and Tech-stacks"
                              />
                            </dl>
                          </div>
                        </div>

                        {/* /End replace */}
                      </div>
                    </div>
                    {isEnable ? (
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={onAcceptReferral}
                          disabled={isProcessing}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Accept
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
