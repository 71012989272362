import { useState } from "react";
import Select from "react-select";
import { MAX_REFERRAL_REQUEST } from "../../../../../../constants/constants";
import AXIOS_CLIENT from "../../../../../../utils/api_client";
import { companyList } from "../../../../../../common/consts/Consts";

export default function ReferralRequestForm({
  email,
  sid,
  student,
  setIsDanger,
  setIsError,
  setErrorMessage,
  totalOpenReferral,
  setTotalOpenReferral,
}) {
  var jobIds = [];
  const [jobId1, setJobId1] = useState("");
  const [jobId2, setJobId2] = useState("");
  const [jobId3, setJobId3] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const [companyDomain, setCompanyDomain] = useState();
  const [companyName, setCompanyName] = useState();

  function handleJobId1(e) {
    setJobId1(e.target.value);
  }

  function handleJobId2(e) {
    setJobId2(e.target.value);
  }
  function handleJobId3(e) {
    setJobId3(e.target.value);
  }
  const onRequestReferral = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    if (!companyDomain) {
      setIsDanger(true);
      setErrorMessage("Please select company");
      setIsError(true);
      setIsProcessing(false);
      return;
    }
    if (jobId1) jobIds.push(jobId1);
    if (jobId2) jobIds.push(jobId2);
    if (jobId3) jobIds.push(jobId3);
    if (jobIds.length === 0) {
      setIsDanger(true);
      setErrorMessage("You should add atleast one Job IDs or URL");
      setIsError(true);
      setIsProcessing(false);
      return;
    }
    if (totalOpenReferral > MAX_REFERRAL_REQUEST - 1) {
      setIsDanger(true);
      setErrorMessage(
        `You can request upto ${MAX_REFERRAL_REQUEST} referrals! Please wait until other open referral request closed or delete earlier requests.`
      );
      setIsError(true);
      setIsProcessing(false);
      return;
    }

    const userObject = {
      sid: sid,
      email: email,
      schoolDomain: student.schoolDomain,
      companyName: companyName,
      companyDomain: companyDomain,
      referralsAccepted: false,
      referralsCompleted: false,
      jobIds: jobIds,
      student: student._id,
    };

    const config = {
      headers: {
        username: sid,
      },
    };

    AXIOS_CLIENT.post(`student/api/referral`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setErrorMessage("Referral request successfully submitted");
        setIsError(true);
        setIsProcessing(false);
        setJobId1("");
        setJobId2("");
        setJobId3("");
        setTotalOpenReferral(totalOpenReferral + 1);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage("Error while submitting request!");
        setIsError(true);
        setIsProcessing(false);
      });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <form className="space-y-8" onSubmit={onRequestReferral}>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Company
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-96">
                <Select
                  options={companyList}
                  onChange={(val) => {
                    setCompanyName(val.label);
                    setCompanyDomain(val.domain);
                  }}
                  placeholder="Select Company"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <div className="flex flex-col">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Job ID or link
                </label>
                <p className="text-xs text-gray-500 py-2 px-2">
                  Enter job IDs or links where you are requesting referral. You
                  can add upto three job IDs.
                </p>
              </div>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-96">
                <input
                  onChange={handleJobId1}
                  value={jobId1}
                  type="text"
                  name="jobId1"
                  id="jobId1"
                  className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Example:1229900 or https://www.zon.jobs/230777"
                />
                <input
                  type="text"
                  name="jobId2"
                  value={jobId2}
                  onChange={handleJobId2}
                  id="jobId2"
                  className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Example:1229900 or https://www.zon.jobs/230777"
                />
                <input
                  type="text"
                  name="jobId3"
                  id="jobId3"
                  value={jobId3}
                  onChange={handleJobId3}
                  className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Example:1229900 or https://www.zon.jobs/230777"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-5">
          <div className="flex justify-center">
            <button
              type="submit"
              className={
                isProcessing
                  ? classNames(
                      "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                    )
                  : classNames(
                      "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    )
              }
              onSubmit={onRequestReferral}
            >
              Request Referral
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
