import { Navigate, useLocation } from "react-router-dom";

const PrivateCommonRoute = ({ children }) => {
  const location = useLocation();
  const isAuth = localStorage.getItem("isAuth");
  var alerted = localStorage.getItem("alerted");

  if (!isAuth) {
    if (!alerted) {
      alert(
        "User not registered! Redirecting to sign-in. You can signin using Google one click"
      );
      localStorage.setItem("alerted", true);
    }
    return (
      <Navigate to="/signin" replace state={{ path: location.pathname }} />
    );
  }

  return children;
};
export default PrivateCommonRoute;
