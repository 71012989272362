import { useState, useEffect } from "react";
import ReferralUpdateModal from "./ReferralUpdateModal";
import { format } from "timeago.js";
import CopyCliboard from "../../../../../../common/components/CopyClipboard";
import AXIOS_CLIENT from "../../../../../../utils/api_client";

export default function MatchesRow({
  index,
  referral,
  totalOpenReferral,
  setTotalOpenReferral,
  referralRequests,
  setReferralRequests,
}) {
  const sid = referral.sid;
  const [openModal, setOpenModal] = useState(false);

  const renderJobIds = referral?.jobIds ? (
    <ul role="list" className="divide-gray-200">
      {referral.jobIds.map((item) => (
        <li key={item}>
          <div className="flex flex-row">
            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              {item}
            </p>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

  const updateReferralRequests = (index) => {
    const newArray = referralRequests.toSpliced(index, 1);
    setReferralRequests(newArray);
  };

  const handleDeleteReferral = (event) => {
    event.preventDefault();

    AXIOS_CLIENT.delete(`student/api/referral/${referral._id}`, {
      headers: {
        username: sid,
      },
      data: {
        sid: sid,
      },
    })
      .then((res) => {
        setTotalOpenReferral(totalOpenReferral - 1);
        updateReferralRequests(index);
      })
      .catch((error) => { });
  };

  const handleUpdateReferral = (event) => {
    setOpenModal(true);
  };

  const renderModal = openModal ? (
    <ReferralUpdateModal
      sid={sid}
      referral={referral}
      openModal={openModal}
      setOpenModal={setOpenModal}
    />
  ) : (
    <></>
  );

  return (
    <>
      <div className="flex items-center px-4 py-2 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 px-4 grid grid-cols-2 md:grid md:grid-cols-4  md:gap-4">
            {/* <div className="text-indigo-600">{referral.companyName ?? ""}</div> */}
            <div className="flex flex-center">
              <div className="h-10 w-10 flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={`https://logo.clearbit.com/${referral.companyDomain}`}
                  alt=""
                />
              </div>
              <div className="text-indigo-600 pl-2 pt-2">
                {referral.companyName ?? ""}
              </div>
            </div>
              <div className="md:block py-2">
                <div className="flex flex-row whitespace-nowrap">
                  <p className="text-sm text-gray-900">Job Id: </p>
                  <div className="text-ellipsis overflow-hidden hover:text-clip text-sm text-gray-900">
                    {renderJobIds}
                  </div>
                </div>
              </div>
            <div className="md:block py-2">
              <div className="text-indigo-600">
                <div className="flex flex-center">
                  <div>
                    <p className="text-sm text-gray-900">
                      {" "}
                      {referral.createdAt ? "Requested" : ""}
                    </p>
                  </div>
                  <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
                    {referral.createdAt ? format(referral.createdAt) : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="md:block">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={handleUpdateReferral}
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  Update
                </button>
                <button
                  onClick={handleDeleteReferral}
                  type="button"
                  className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  Delete
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {renderModal}
    </>
  );
}
