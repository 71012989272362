import React, { useEffect, useState } from "react";
import SignInMain from "./components/SignInMain";
export default function SignIn() {
  const TITLE = "FutureForte: Login into your account";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-1 md:gap-8">
        <SignInMain />
      </div>
    </div>
  );
}
