export const MAJORS = [
  { label: "Computer Science", value: "Computer Science" },
  { label: "Electrical Engineering", value: "Electrical Engineering" },
  { label: "Arts and Humanities", value: "Arts and Humanities" },
  { label: "Business", value: "Business" },
  { label: "Management", value: "Management" },
  { label: "Social Science", value: "Social Science" },
  { label: "Distributed", value: "Distributed" },
  { label: "Kanban", value: "Kanban" },
  { label: "Other", value: "Other" },
];
export const MENTORSHIP_SERVICES = [
  { label: "Resume Review", value: "resume-review" },
  { label: "Mock Interview", value: "mock-interview" },
  { label: "1:1 Mentorship", value: "1:1-mentorship" },
  { label: "Interview Prep&Tips", value: "interview-prep-tips" },
  { label: "Software Engineering", value: "Software Engineering" },
  { label: "Engineering", value: "Engineering" },
  { label: "Arts and science", value: "Arts and science" },
  {
    label: "Media Arts and Sciences",
    value: "Media Arts and Sciences",
  },
  {
    label: "Industrial Engineering",
    value: "Industrial Engineering",
  },
];

export const CAREER_GOALS = [
  { label: "Resume Review", value: "resume-review" },
  { label: "Mock Interview", value: "mock-interview" },
  { label: "1:1 Mentorship", value: "1:1-mentorship" },
  { label: "Interview Prep&Tips", value: "interview-prep-tips" },
  { label: "Software Engineering", value: "Software Engineering" },
  { label: "Engineering", value: "Engineering" },
  { label: "Arts and science", value: "Arts and science" },
  {
    label: "Media Arts and Sciences",
    value: "Media Arts and Sciences",
  },
  {
    label: "Industrial Engineering",
    value: "Industrial Engineering",
  },
];

export const JOB_TYPE = [
  { label: "Internship", value: "Internship" },
  { label: "Full-time", value: "Full-time" },
  { label: "Contract", value: "Contract" },
];
export const LOCATION_TYPE = [
  { label: "Remote", value: "Remote" },
  { label: "Onsite", value: "Onsite" },
  { label: "Hybrid", value: "Hybrid" },
];

export const ETHNICITY = [
  { label: "White", value: "White" },
  { label: "Black/African American", value: "Black/African American" },
  { label: "East Asian", value: "East Asian" },
  { label: "Hispanic/Latinx", value: "Hispanic/Latinx" },
  { label: "Middle Eastern", value: "Remote" },
  { label: "Southeast Asian", value: "Southeast Asian" },
  { label: "South Asian", value: "South Asian" },
  { label: "Native American/Alaskan", value: "Native American/Alaskan" },
  {
    label: "Native Hawaiian/Pacific Islander",
    value: "Native Hawaiian/Pacific Islander",
  },
];

export const SKILLS = [
  { label: "Frontend", value: "Frontend" },
  { label: "Backend", value: "Backend" },
  { label: "iOS Development", value: "iOS Development" },
  { label: "Data Science", value: "Data Science" },
  { label: "Machine Learning", value: "Machine Learning" },
  { label: "Web development", value: "Web development" },
  { label: "Embedded", value: "Embedded" },
  { label: "Android", value: "Android development" },
  { label: "Data Engineering", value: "Data Engineering" },
  { label: "Data Analytics", value: "Data Analytics" },
  {
    label: "Natural Language Processing",
    value: "Natural Language Processing",
  },
  { label: "Artificial Intelligence", value: "Artificial Intelligence" },
  { label: "DevOps", value: "DevOps" },
  { label: "Cloud computing", value: "Cloud computing" },
  { label: "Cloud architect", value: "Cloud architect" },
  { label: "Full-stack development", value: "Full-stack development" },
  { label: "Core-infra", value: "Core-infra" },
  { label: "Big data", value: "Big data" },
  { label: "Data mining", value: "Data mining" },
  { label: "Blockchain", value: "Blockchain" },
  { label: "Distributed system", value: "Distributed Systems" },
  { label: "Kanban", value: "Kanban" },
  { label: "Kaizen", value: "Kaizen" },
  { label: "Six Sigma", value: "Six Sigma" },
  { label: "PDCA", value: "PDCA" },
  { label: "Agile", value: "Agile" },
  { label: "Core-infra", value: "Core-infra" },
  { label: "root cause analysis", value: "root cause analysis" },

  { label: "Laboratory Qualification", value: "Laboratory Qualification" },
  { label: "Nano Device Technology", value: "Nano Device Technology" },
  { label: "Process Improvement", value: "Process Improvement" },
  { label: "Wireless Communications", value: "Wireless Communications" },
  { label: "Signal Processing", value: "Signal Processing" },
  { label: "Analog & Mixed Signals", value: "Analog & Mixed Signals" },
  { label: "Power Electronics", value: "Power Electronics" },
  { label: "Electrical Testing", value: "Electrical Testing" },

  { label: "Business Managment", value: "Business Managment" },
  {
    label: "Architecture and Construction",
    value: "Architecture and Construction",
  },
  { label: "Entrepreneurship", value: "Entrepreneurship" },
  { label: "Corporate Law", value: "Corporate law" },
  { label: "Sustainability", value: "Sustainability" },
  {
    label: "Aerospace Engineering",
    value: "Aerospace Engineering",
  },
  { label: "Agribusiness", value: "Agribusiness" },
  {
    label: "Art (Digital Technology), MFA",
    value: "Art (Digital Technology), MFA",
  },
  { label: "Biological Design", value: "Biological Design" },
  {
    label: "Biomedical Diagnostics",
    value: "Biomedical Diagnostics",
  },

  { label: "Business Analytics", value: "Business Analytics" },
  { label: "Chemical Engineering", value: "Chemical Engineering" },
  {
    label: "Civil, Environmental and Sustainable Engineering",
    value: "Civil, Environmental and Sustainable Engineering",
  },
  {
    label: "Computer Engineering (Computer Systems)",
    value: "Computer Engineering (Computer Systems)",
  },
  {
    label: "Computer Engineering (Electrical Engineering)",
    value: "Computer Engineering (Electrical Engineering)",
  },
  {
    label: "Computer Information Systems",
    value: "Computer Information Systems",
  },
  {
    label: "Computer Science (Big Data Systems)",
    value: "Computer Science (Big Data Systems)",
  },

  {
    label: "Computer Science (Cybersecurity)",
    value: "Computer Science (Cybersecurity)",
  },
  { label: "Computer Science", value: "Computer Science" },

  {
    label: "Construction Engineering ",
    value: "Construction Engineering ",
  },
  {
    label: "Construction Management and Technology",
    value: "Construction Management and Technology",
  },
  {
    label: "Data Science, Analytics and Engineering",
    value: "Data Science, Analytics and Engineering",
  },
  {
    label: "Electrical Engineering",
    value: "Electrical Engineering",
  },

  {
    label: "Engineering Education Systems and Design",
    value: "Engineering Education Systems and Design",
  },
  {
    label: "Environmental Life Sciences",
    value: "Environmental Life Sciences",
  },
  {
    label: "Environmental and Resource Management",
    value: "Environmental and Resource Management",
  },
  {
    label: "Exploration Systems Design ",
    value: "Exploration Systems Design ",
  },

  {
    label: "Geographic Information Science",
    value: "Geographic Information Science",
  },
  {
    label: "Global Development and Innovation  ",
    value: "Global Development and Innovation  ",
  },
  { label: "Global Logistics", value: "Global Logistics" },

  {
    label: "Global Management (Global Digital Transformation), MGM",
    value: "Global Management (Global Digital Transformation), MGM",
  },
  {
    label: "Graphic Information Technology",
    value: "Graphic Information Technology",
  },
  {
    label: "Health Care Simulation",
    value: "Health Care Simulation",
  },
  {
    label: "Human Systems Engineering (Aviation Human Factors)",
    value: "Human Systems Engineering (Aviation Human Factors)",
  },
  {
    label: "Human Systems Engineering (Health Systems)",
    value: "Human Systems Engineering (Health Systems)",
  },
  {
    label: "Human Systems Engineering (Intelligent Systems)",
    value: "Human Systems Engineering (Intelligent Systems)",
  },
  {
    label: "Human Systems Engineering (User Experience Research), MS",
    value: "Human Systems Engineering (User Experience Research), MS",
  },
  {
    label: "Human Systems Engineering",
    value: "Human Systems Engineering",
  },
  {
    label: "Human Systems Engineering",
    value: "Human Systems Engineering",
  },
  {
    label: "Industrial Engineering",
    value: "Industrial Engineering",
  },

  {
    label: "Information Systems Management",
    value: "Information Systems Management",
  },
  {
    label: "Information Technology",
    value: "Information Technology",
  },
  {
    label: "Innovation and Venture Development",
    value: "Innovation and Venture Development",
  },
  {
    label: "Lean Six Sigma Black Belt  ",
    value: "Lean Six Sigma Black Belt  ",
  },
  {
    label: "Manufacturing Engineering",
    value: "Manufacturing Engineering",
  },

  {
    label: "Master of Engineering, MEng",
    value: "Master of Engineering, MEng",
  },
  {
    label: "Materials Science and Engineering",
    value: "Materials Science and Engineering",
  },
  {
    label: "Mechanical Engineering",
    value: "Mechanical Engineering",
  },

  {
    label: "Media Arts and Sciences",
    value: "Media Arts and Sciences",
  },
  {
    label: "Modern Energy Production and Sustainable Use",
    value: "Modern Energy Production and Sustainable Use",
  },
  {
    label: "Molecular, Cellular, Tissue and Biomaterials Engineering  ",
    value: "Molecular, Cellular, Tissue and Biomaterials Engineering  ",
  },
  {
    label: "Neural Engineering  ",
    value: "Neural Engineering  ",
  },
  {
    label: "Nuclear Power Generation  ",
    value: "Nuclear Power Generation  ",
  },
  {
    label: "Public Interest Technology",
    value: "Public Interest Technology",
  },
  {
    label: "Public Policy (Science and Technology Policy), MPP",
    value: "Public Policy (Science and Technology Policy), MPP",
  },

  {
    label: "Robotics and Autonomous Systems ",
    value: "Robotics and Autonomous Systems ",
  },

  {
    label:
      "Robotics and Autonomous Systems (Mechanical and Aerospace Engineering)",
    value:
      "Robotics and Autonomous Systems (Mechanical and Aerospace Engineering)",
  },
  {
    label: "Robotics and Autonomous Systems , MS",
    value: "Robotics and Autonomous Systems , MS",
  },
  {
    label: "Semiconductor Processing  ",
    value: "Semiconductor Processing  ",
  },
  {
    label: "Sensor Signal and Information Processing  ",
    value: "Sensor Signal and Information Processing  ",
  },
  { label: "Software Engineering", value: "Software Engineering" },
  {
    label: "Supply Chain Management  ",
    value: "Supply Chain Management  ",
  },
  {
    label: "Supply Chain Management",
    value: "Supply Chain Management",
  },
  {
    label: "Sustainable Engineering ",
    value: "Sustainable Engineering ",
  },
  { label: "Systems Engineering", value: "Systems Engineering" },
  {
    label: "Technical Communication",
    value: "Technical Communication",
  },
  {
    label: "Technology (Aviation Management and Human Factors) ",
    value: "Technology (Aviation Management and Human Factors) ",
  },
  {
    label: "Technology (Management of Technology) ",
    value: "Technology (Management of Technology) ",
  },
  { label: "User Experience", value: "User Experience" },
  { label: "Analog Circuit Design", value: "Analog Circuit Design" },
  { label: "Digital Circuit Design", value: "Digital Circuit Design" },
  { label: "Mixed Signal Circuits", value: "Mixed Signal Circuits" },
  { label: "Embedded Systems", value: "Embedded Systems" },
  {
    label: "Embedded Firmware Development",
    value: "Embedded Firmware Development",
  },
  { label: "Microcontroller", value: "Microcontroller" },
  { label: "Oscilloscope Debugging", value: "Oscilloscope Debugging" },
  { label: "Prototyping", value: "Prototyping" },
  { label: "FPGA Development", value: "FPGA Development" },
  { label: "VLSI", value: "VLSI" },
  { label: "Logic Design", value: "Logic Design" },
  { label: "RISC-V", value: "RISC-V" },
  { label: "CISC", value: "CISC" },
  { label: "PCB Design", value: "PCB Design" },
  {
    label: "Soldering and Board Bring-up",
    value: "Soldering and Board Bring-up",
  },
  { label: "Power System Simulation", value: "Power System Simulation" },
  { label: "Motor Control", value: "Motor Control" },
  { label: "DC Motor Design", value: "DC Motor Design" },
  { label: "Synchronous Motor Design", value: "Synchronous Motor Design" },
  { label: "Rotating A.C. Machines", value: "Rotating A.C. Machines" },
  { label: "Power Electronics", value: "Power Electronics" },
  { label: "Converter Circuits", value: "Converter Circuits" },
  { label: "Converter Control", value: "Converter Control" },
  { label: "Inverters", value: "Inverters" },
  { label: "MOSFET", value: "MOSFET" },
  { label: "Magnetic Circuit Design", value: "Magnetic Circuit Design" },
  { label: "Circuit Simulation", value: "Circuit Simulation" },
  { label: "Control System Design", value: "Control System Design" },
  { label: "Signal Processing", value: "Signal Processing" },
  { label: "Digital Signal Processing", value: "Digital Signal Processing" },
  { label: "DSP Programming", value: "DSP Programming" },
  { label: "Wireless Circuits", value: "Wireless Circuits" },
  { label: "Electromagnetics", value: "Electromagnetics" },
  { label: "Antenna Design", value: "Antenna Design" },
  { label: "Wireless Communication", value: "Wireless Communication" },
  {
    label: "Optical Communication systems",
    value: "Optical Communication systems",
  },
  { label: "Optics", value: "Optics" },
  {
    label: "Semiconductor Device Manufacturing",
    value: "Semiconductor Device Manufacturing",
  },
  { label: "Material Science", value: "Material Science" },
  { label: "OPAMP Circuits", value: "OPAMP Circuits" },
  { label: "Microwave Circuits", value: "Microwave Circuits" },
  { label: "Amplifiers", value: "Amplifiers" },
  { label: "System Modelling", value: "System Modelling" },
  { label: "Cell Modelling", value: "Cell Modelling" },
  { label: "Electric Vehicle", value: "Electric Vehicle" },
  { label: "Instrumentation", value: "Instrumentation" },
  { label: "Testing and Validation", value: "Testing and Validation" },
];

export const TOOLS_AND_TECH = [
  { label: "reactJs", value: "ReactJS" },
  { label: "VueJS", value: "VueJS" },
  { label: "Php", value: "Php" },
  { label: "Java", value: "Java" },
  { label: "Python", value: "Python" },
  { label: "C++", value: "C++" },
  { label: "nodeJS", value: "NodeJS" },
  { label: "mongoDB", value: "MongoDB" },
  { label: "PostGreSQL", value: "PostGreSQL" },
  { label: "Elasticsearch", value: "Elasticsearch" },
  { label: "AWS", value: "AWS" },
  { label: "Google cloud platform", value: "Google Cloud Platform" },
  { label: "Microsoft Azure", value: "Microsoft Azure" },
  { label: "Docker", value: "Docker" },
  { label: "Kafka", value: "kafka" },
  { label: "C++", value: "C++" },
  { label: "Rust ", value: "Rust" },
  { label: "Angular JS", value: "Angular JS" },
  { label: "Lavarel", value: "Lavarel" },
  { label: "Django", value: "Django" },
  { label: "MySQL", value: "MySQL" },
  { label: "Hadoop", value: "Hadoop" },
  { label: "Salesforce Developer", value: "Salesforce Developer" },
  { label: "Tableu", value: "Tableu" },
  { label: "Tensorflow", value: "Tensorflow" },
  { label: "Apache scala", value: "Apache Scala" },
  { label: "Ajax", value: "Ajax" },
  { label: "ExpressJS ", value: "ExpressJS" },
  { label: "Pytorch", value: "Pytorch" },
  { label: "Flask", value: "Flask" },
  { label: "Sklearn", value: "Sklearn" },
  { label: "Shell Scripting", value: "Shell Scripting" },
  { label: "Verilog", value: "Verilog" },
  { label: "System Verilog", value: "System Verilog" },
  { label: "VHDL", value: "VHDL" },
  { label: "Assembly Progamming", value: "Assembly Progamming" },
  { label: "ANSYS", value: "ANSYS" },
  { label: "MATLAB", value: "MATLAB" },
  { label: "Simulink", value: "Simulink" },
  { label: "KiCad", value: "KiCad" },
  { label: "Altium Designer", value: "Altium Designer" },
  { label: "Eagle PCB", value: "Eagle PCB" },
  { label: "LTSpice", value: "LTSpice" },
];

export const REFER_CODES = [
  "shreeshiv",
  "steve",
  "yash",
  "iHelpedWithLogo",
  "GO-DEVILS",
  "FORKS-UP",
  "WILDCATS",
  "CODE-LEUGTN",
  "CODE-LUEGTN",
  "CODE-QSRYMM",
  "CODE-TPPKPU",
  "CODE-RCTXLQ",
  "CODE-JSIJPU",
  "CODE-PRECRT",
  "CODE-PVTQUX",
  "CODE-PHTRQR",
  "CODE-RPTWLI",
  "CODE-TEJIRU",
];

export const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const companyList = [
  {
    label: "FutureForte",
    value: "FutureForte",
    domain: "futureforte.co",
  },
  { label: "Amazon", value: "Amazon", domain: "amazon.com" },
  { label: "Meta", value: "Meta", domain: "fb.com" },
  { label: "Salesforce", value: "Salesforce", domain: "salesforce.com" },
  { label: "Apple", value: "Apple", domain: "apple.com" },

  { label: "Microsoft", value: "Microsoft", domain: "microsoft.com" },
  { label: "Google", value: "Google", domain: "google.com" },
  { label: "Tesla", value: "Tesla", domain: "tesla.com" },
  { label: "Nvidia", value: "Nvidia", domain: "nvidia.com" },
  { label: "AMD", value: "AMD", domain: "amd.com" },
  { label: "Adobe", value: "Adobe", domain: "adobe.com" },
  { label: "TikTok", value: "TikTok", domain: "tiktok.com" },

  { label: "Samsung", value: "Samsung", domain: "samsung.com" },
  { label: "Oracle", value: "Oracle", domain: "oracle.com" },
  { label: "Broadcom", value: "Broadcom", domain: "broadcom.com" },
  { label: "Alibaba", value: "Alibaba", domain: "alibaba.com" },
  { label: "Cisco", value: "Cisco", domain: "cisco.com" },
  { label: "Netflix", value: "Netflix", domain: "netflix.com" },
  { label: "SAP", value: "SAP", domain: "sap.com" },
  { label: "Intel", value: "Intel", domain: "intel.com" },
  { label: "Intuit", value: "Intuit", domain: "intuit.com" },
  { label: "Snowflake", value: "Snowflake", domain: "snowflake.com" },
  { label: "Shopify", value: "Shopify", domain: "shopify.com" },
  { label: "Vmware", value: "Vmware", domain: "vmware.com" },
  { label: "Barclays", value: "Barclays", domain: "barclays.com" },
  { label: "Atlassian", value: "Atlassian", domain: "atlassian.com" },
  { label: "Vmware", value: "Vmware", domain: "vmware.com" },
  { label: "Zipline", value: "Zipline", domain: "flyzipline.com" },
  { label: "Splunk", value: "Splunk", domain: "splunk.com" },

  {
    label: "Northwestern Mutual",
    value: "Northwestern Mutual",
    domain: "northwesternmutual.com",
  },

  {
    label: "Palo Alto network",
    value: "Palo Alto network",
    domain: "paloaltonetworks.com",
  },
  { label: "Fortinet", value: "Fortinet", domain: "fortinet.com" },
  { label: "Uber", value: "Uber", domain: "uber.com" },
  { label: "Airbnb", value: "Airbnb", domain: "airbnb.com" },
  { label: "Sony", value: "Sony", domain: "sony.com" },
  { label: "Booking.com", value: "Booking.com", domain: "booking.com" },
  { label: "Accenture", value: "Accenture", domain: "accenture.com" },
  { label: "Kroger", value: "Kroger", domain: "kroger.com" },
  { label: "FedEx", value: "FedEx", domain: "fedex.com" },
  { label: "The home depot", value: "The home depot", domain: "homedepot.com" },
  { label: "IBM", value: "IBM", domain: "ibm.com" },
  { label: "Paypal", value: "Paypal", domain: "paypal.com" },
  {
    label: "United Postal Services UPS",
    value: "United Postal Services UPS",
    domain: "ups.com",
  },
  { label: "Glassdoor", value: "Glassdoor", domain: "glassdoor.com" },
  { label: "Starbucks", value: "Starbucks", domain: "starbucks.com" },
  { label: "SpaceX", value: "SpaceX", domain: "spacex.com" },
  { label: "Jane Street", value: "Jane Street", domain: "janestreet.com" },

  { label: "UKG", value: "UKG", domain: "ukg.com" },
  { label: "Certik", value: "Certik", domain: "certik.com" },
  { label: "Midmark", value: "Midmark", domain: "midmark.com" },
  {
    label: "Danbury Mission Technologies",
    value: "Danbury Mission Technologies",
    domain: "dmtllc.org",
  },

  { label: "CVS Health", value: "CVS Health", domain: "cvshealth.com" },
  { label: "Earnst & Young", value: "Earnst & Young", domain: "ey.com" },
  {
    label: "PricewaterhouseCoopers PWC",
    value: "PricewaterhouseCoopers PWC",
    domain: "pwc.com",
  },
  { label: "KPMG", value: "KPMG", domain: "kpmg.com" },
  { label: "Citi bank", value: "Citi bank", domain: "citi.com" },
  {
    label: "McKinsey and Company",
    value: "McKinsey and Company",
    domain: "mckinsey.com",
  },
  { label: "BDO Global", value: "BDO Global", domain: "bdo.global" },
  { label: "Deloitte", value: "Deloitte", domain: "deloitte.com" },
  { label: "Discover", value: "Discover", domain: "discover.com" },
  { label: "Bain and Company", value: "Bain and Company", domain: "bain.com" },
  {
    label: "Boston Consulting Group BCG",
    value: "Boston Consulting Group BCG",
    domain: "bcg.com",
  },

  {
    label: "Publicis Sapient",
    value: "Publicis Sapient",
    domain: "publicissapient.com",
  },
  { label: "Ford", value: "Ford", domain: "ford.com" },
  { label: "General Motors GM", value: "General Motors GM", domain: "gm.com" },
  { label: "Toyota", value: "Toyota", domain: "toyota.com" },
  { label: "Porsche", value: "Porsche", domain: "porsche.com" },
  { label: "Mercedes Benz", value: "Mercedes Benz", domain: "mbusa.com" },
  { label: "BMW", value: "BMW", domain: "bmwusa.com" },
  { label: "Honda", value: "Honda", domain: "honda.com" },
  { label: "Chrysler", value: "Chrysler", domain: "chrysler.com" },
  { label: "Chevrolet", value: "Chevrolet", domain: "chevrolet.com" },
  { label: "Cadillac", value: "Cadillac", domain: "cadillac.com" },
  { label: "Dodge", value: "Dodge", domain: "dodge.com" },
  { label: "United Health", value: "United Health", domain: "uhc.com" },
  { label: "Exxon Mobil", value: "Exxon Mobil", domain: "exxonmobil.com" },
  {
    label: "Johnson and Johnson",
    value: "Johnson and Johnson",
    domain: "jnj.com",
  },
  { label: "Visa", value: "Visa", domain: "Visa.com" },
  { label: "Expedia", value: "Expedia", domain: "expedia.com" },
  { label: "Walmart", value: "Walmart", domain: "walmart.com" },
  { label: "Verkada", value: "Verkada", domain: "verkada.com" },
  { label: "Tenstorrent", value: "Tenstorrent", domain: "tenstorrent.com" },
  { label: "Kodiak Robotics", value: "Kodiak Robotics", domain: "kodiak.ai" },

  { label: "Sezzle", value: "Sezzle", domain: "sezzle.com" },
  { label: "Anyscale", value: "Anyscale", domain: "anyscale.com" },
  { label: "WealthSimple", value: "WealthSimple", domain: "wealthsimple.com" },

  {
    label: "Western Digital",
    value: "Western Digital",
    domain: "westerndigital.com",
  },

  { label: "D2L", value: "D2L", domain: "d2l.com" },
  { label: "TD", value: "TD", domain: "td.com" },

  {
    label: "JP Morgan and Chase",
    value: "JP Morgan and Chase",
    domain: "jpmorgan.com",
  },
  {
    label: "Goldman Sachs",
    value: "Goldman Sachs",
    domain: "goldmansachs.com",
  },
  { label: "Chevron", value: "Chevron", domain: "chenron.com" },
  {
    label: "Eli Lilly and Company",
    value: "Eli Lilly and Company",
    domain: "lilly.com",
  },
  { label: "Mastercard", value: "Mastercard", domain: "mastercard.us" },
  {
    label: "Bank of America",
    value: "Bank of America",
    domain: "bankofamerica.com",
  },
  {
    label: "American Express",
    value: "American Express",
    domain: "americanexpress.com",
  },
  { label: "AbbVie", value: "AbbVie", domain: "abbvie.com" },
  { label: "TCS", value: "TCS", domain: "tcs.com" },
  { label: "Infosys", value: "Infosys", domain: "infosys.com" },
  { label: "Nike", value: "Nike", domain: "nike.com" },
  { label: "Adidas", value: "Adidas", domain: "adidas.com" },
  { label: "Juniper Network", value: "Juniper Network", domain: "juniper.net" },
  { label: "Pfizer", value: "Pfizer", domain: "pfizer.com" },
  { label: "Merck", value: "Merck", domain: "merck.com" },
  {
    label: "Procter and Gamble",
    value: "Procter and Gamble",
    domain: "pg.com",
  },
  { label: "Coca-cola", value: "Coca-cola", domain: "coca-colacompany.com" },
  { label: "Roche", value: "Roche", domain: "roche.com" },
  { label: "Pepsico", value: "Pepsico", domain: "pepsico.com" },
  { label: "Costco", value: "Costco", domain: "costco.com" },
  { label: "McDonald", value: "McDonald", domain: "mcdonalds.com" },
  { label: "Wells Fargo", value: "Wells Fargo", domain: "wellsfargo.com" },
  {
    label: "The Walt Disney Company",
    value: "The Walt Disney Company",
    domain: "disney.com",
  },
  { label: "Redhat", value: "Redhat", domain: "redhat.com" },
  { label: "Verizon", value: "Verizon", domain: "verizon.com" },
  { label: "Texas Instruments", value: "Texas Instruments", domain: "ti.com" },
  { label: "Comcast", value: "Comcast", domain: "comcast.com" },
  { label: "Nutanix", value: "Nutanix", domain: "nutanix.com" },
  { label: "Rubrik", value: "Rubrik", domain: "rubrik.com" },
  { label: "InMobi", value: "InMobi", domain: "inmobi.com" },
  {
    label: "Morgan Stanley",
    value: "Morgan Stanley",
    domain: "morganstanley.com",
  },
  { label: "Amgen", value: "Amgen", domain: "amgen.com" },
  {
    label: "Deere and Company",
    value: "Deere and Company",
    domain: "deere.com",
  },
  { label: "HoneyWell", value: "HoneyWell", domain: "honeywell.com" },
  { label: "AT&T", value: "AT&T", domain: "att.com" },
  {
    label: "Lockheed Martin",
    value: "Lockheed Martin",
    domain: "lockheedmartin.com",
  },
  { label: "eBay", value: "eBay", domain: "ebay.com" },
  { label: "Nokia", value: "Nokia", domain: "nokia.com" },
  { label: "Veeva System", value: "Veeva System", domain: "veeva.com" },
  { label: "HP", value: "HP", domain: "hp.com" },
  { label: "Atlassian", value: "Atlassian", domain: "atlassian.com" },
  {
    label: "On Semiconductor",
    value: "On Semiconductor",
    domain: "onsemi.com",
  },
  { label: "Dell", value: "Dell", domain: "dell.com" },
  { label: "Electronic Arts", value: "Electronic Arts", domain: "ea.com" },
  { label: "Workday", value: "Workday", domain: "workday.com" },
  { label: "Block", value: "Block", domain: "block.xyz" },
  { label: "Arista Network", value: "Arista Network", domain: "arista.com" },
  { label: "Autodesk", value: "Autodesk", domain: "autodesk.com" },
  { label: "NXP Semiconductor", value: "NXP Semiconductor", domain: "nxp.com" },
  { label: "Cognizant", value: "Cognitzant", domain: "cognizant.com" },
  { label: "Capgemini", value: "Capgemini", domain: "capgemini.com" },
];

const schoolDomainList = [
  {
    label: "University of Arizona",
    domain: "arizona.edu",
    value: "University of Arizona",
  },
  {
    label: "Arizona State University",
    domain: "asu.edu",
    value: "Arizona State University",
  },
  {
    label: "University of Alaska",
    domain: "alaska.edu",
    value: "University of Alaska",
  },
  {
    label: "Alaska Christian College",
    domain: "alaskacc.edu",
    value: "Alaska Christian College",
  },
  {
    label: "Other",
    domain: ".edu",
    value: "Other",
  },
  {
    label: "National Park Community College",
    domain: "npcc.edu",
    value: "National Park Community College",
  },
  {
    label: "University of Alaska Fairbanks",
    domain: "uaf.edu",
    value: "University of Alaska Fairbanks",
  },
  {
    label: "University of Arkansas - Fort Smith",
    domain: "uafs.edu",
    value: "University of Arkansas - Fort Smith",
  },
  {
    label: "Alabama A&M University",
    domain: "aamu.edu",
    value: "Alabama A&M University",
  },
  {
    label: "Athens State University",
    domain: "athens.edu",
    value: "Athens State University",
  },
  {
    label: "Auburn University",
    domain: "auburn.edu",
    value: "Auburn University",
  },
  {
    label: "Auburn University at Montgomery",
    domain: "aum.edu",
    value: "Auburn University at Montgomery",
  },
  {
    label: "Birmingham-Southern College",
    domain: "bsc.edu",
    value: "Birmingham-Southern College",
  },
  {
    label: "Calhoun Community College",
    domain: "calhoun.edu",
    value: "Calhoun Community College",
  },
  {
    label: "Enterprise State Community College",
    domain: "escc.edu",
    value: "Enterprise State Community College",
  },
  {
    label: "Faulkner State Community College",
    domain: "faulknerstate.edu",
    value: "Faulkner State Community College",
  },
  {
    label: "Gadsden State Community College",
    domain: "gadsdenstate.edu",
    value: "Gadsden State Community College",
  },
  {
    label: "Huntingdon College",
    domain: "huntingdon.edu",
    value: "Huntingdon College",
  },
  {
    label: "Jacksonville State University",
    domain: "jsu.edu",
    value: "Jacksonville State University",
  },
  {
    label: "Lurleen B. Wallace Community College",
    domain: "lbwcc.edu",
    value: "Lurleen B. Wallace Community College",
  },
  {
    label: "University of Montevallo",
    domain: "montevallo.edu",
    value: "University of Montevallo",
  },
  {
    label: "Northeast Alabama Community College",
    domain: "nacc.edu",
    value: "Northeast Alabama Community College",
  },
  {
    label: "Northwest-Shoals Community College",
    domain: "nwscc.edu",
    value: "Northwest-Shoals Community College",
  },
  {
    label: "Oakwood University",
    domain: "oakwood.edu",
    value: "Oakwood University",
  },
  {
    label: "Samford University",
    domain: "samford.edu",
    value: "Samford University",
  },
  {
    label: "Southeastern Bible College",
    domain: "sebc.edu",
    value: "Southeastern Bible College",
  },
  {
    label: "University of South Alabama",
    domain: "southalabama.edu",
    value: "University of South Alabama",
  },
  {
    label: "Trenholm State Technical College",
    domain: "trenholmstate.edu",
    value: "Trenholm State Technical College",
  },
  {
    label: "Troy University",
    domain: "troy.edu",
    value: "Troy University",
  },
  {
    label: "Tuskegee University",
    domain: "tuskegee.edu",
    value: "Tuskegee University",
  },
  {
    label: "University of Alabama",
    domain: "ua.edu",
    value: "University of Alabama",
  },
  {
    label: "University of Alabama at Birmingham",
    domain: "uab.edu",
    value: "University of Alabama at Birmingham",
  },
  {
    label: "University of Alabama in Huntsville",
    domain: "uah.edu",
    value: "University of Alabama in Huntsville",
  },
  {
    label: "University of North Alabama",
    domain: "una.edu",
    value: "University of North Alabama",
  },
  {
    label: "Snead State Community College",
    domain: "snead.edu",
    value: "Snead State Community College",
  },
  {
    label: "Arkansas Northeastern College",
    domain: "anc.edu",
    value: "Arkansas Northeastern College",
  },
  {
    label: "Arkansas State University",
    domain: "astate.edu",
    value: "Arkansas State University",
  },
  {
    label: "Arkansas Tech University",
    domain: "atu.edu",
    value: "Arkansas Tech University",
  },
  {
    label: "Central Baptist College",
    domain: "cbc.edu",
    value: "Central Baptist College",
  },
  {
    label: "Harding University",
    domain: "harding.edu",
    value: "Harding University",
  },
  {
    label: "Hendrix College",
    domain: "hendrix.edu",
    value: "Hendrix College",
  },
  {
    label: "Henderson State University",
    domain: "hsu.edu",
    value: "Henderson State University",
  },
  {
    label: "John Brown University",
    domain: "jbu.edu",
    value: "John Brown University",
  },
  { label: "Lyon College", domain: "lyon.edu", value: "Lyon College" },
  {
    label: "Mid-South Community College",
    domain: "midsouthcc.edu",
    value: "Mid-South Community College",
  },
  {
    label: "North Arkansas College",
    domain: "northark.edu",
    value: "North Arkansas College",
  },
  {
    label: "Northwest Arkansas Community College",
    domain: "nwacc.edu",
    value: "Northwest Arkansas Community College",
  },
  {
    label: "Northwest Technical Institute",
    domain: "nwti.edu",
    value: "Northwest Technical Institute",
  },
  {
    label: "Ouachita Baptist University",
    domain: "obu.edu",
    value: "Ouachita Baptist University",
  },
  {
    label: "University of the Ozarks",
    domain: "ozarks.edu",
    value: "University of the Ozarks",
  },
  {
    label: "Phillips Community College U of A",
    domain: "pccua.edu",
    value: "Phillips Community College U of A",
  },
  {
    label: "Pulaski Technical College",
    domain: "pulaskitech.edu",
    value: "Pulaski Technical College",
  },
  {
    label: "Southern Arkansas University Magnolia",
    domain: "saumag.edu",
    value: "Southern Arkansas University Magnolia",
  },
  {
    label: "Southeast Arkansas College",
    domain: "seark.edu",
    value: "Southeast Arkansas College",
  },
  {
    label: "University of Arkansas Community College Batesville",
    domain: "uaccb.edu",
    value: "University of Arkansas Community College Batesville",
  },
  {
    label: "University of Arkansas at Little Rock",
    domain: "ualr.edu",
    value: "University of Arkansas at Little Rock",
  },
  {
    label: "University of Arkansas for Medical Sciences",
    domain: "uams.edu",
    value: "University of Arkansas for Medical Sciences",
  },
  {
    label: "University of Arkansas at Pine Bluff",
    domain: "uapb.edu",
    value: "University of Arkansas at Pine Bluff",
  },
  {
    label: "University of Arkansas",
    domain: "uark.edu",
    value: "University of Arkansas",
  },
  {
    label: "University of Central Arkansas",
    domain: "uca.edu",
    value: "University of Central Arkansas",
  },
  {
    label: "Williams Baptist College",
    domain: "wbcoll.edu",
    value: "Williams Baptist College",
  },
  {
    label: "Arizona Western College",
    domain: "azwestern.edu",
    value: "Arizona Western College",
  },
  {
    label: "Bryan University",
    domain: "bryanuniversity.edu",
    value: "Bryan University",
  },
  {
    label: "Central Arizona College",
    domain: "centralaz.edu",
    value: "Central Arizona College",
  },
  {
    label: "Glendale Community College",
    domain: "gccaz.edu",
    value: "Glendale Community College",
  },
  {
    label: "Grand Canyon University",
    domain: "gcu.edu",
    value: "Grand Canyon University",
  },
  {
    label: "Maricopa Community Colleges",
    domain: "maricopa.edu",
    value: "Maricopa Community Colleges",
  },
  {
    label: "Mesa Community College",
    domain: "mesacc.edu",
    value: "Mesa Community College",
  },
  {
    label: "Northern Arizona University",
    domain: "nau.edu",
    value: "Northern Arizona University",
  },
  {
    label: "Northland Pioneer College",
    domain: "npc.edu",
    value: "Northland Pioneer College",
  },
  {
    label: "University of Phoenix",
    domain: "phoenix.edu",
    value: "University of Phoenix",
  },
  {
    label: "Phoenix College",
    domain: "phoenixcollege.edu",
    value: "Phoenix College",
  },
  {
    label: "Pima Community College",
    domain: "pima.edu",
    value: "Pima Community College",
  },
  {
    label: "Prescott College",
    domain: "prescott.edu",
    value: "Prescott College",
  },
  {
    label: "Rio Salado College",
    domain: "riosalado.edu",
    value: "Rio Salado College",
  },
  {
    label: "South Mountain Community College",
    domain: "southmountaincc.edu",
    value: "South Mountain Community College",
  },
  {
    label: "University of Advancing Technology",
    domain: "uat.edu",
    value: "University of Advancing Technology",
  },
  {
    label: "Yavapai College",
    domain: "yc.edu",
    value: "Yavapai College",
  },
  {
    label: "Academy of Arts University",
    domain: "academyart.edu",
    value: "Academy of Arts University",
  },
  {
    label: "Adance Computing Institute",
    domain: "advancedcomputinginstitute.edu",
    value: "Adance Computing Institute",
  },
  {
    label: "Acupuncture & Integrative Medicine College",
    domain: "aimc.edu",
    value: "Acupuncture & Integrative Medicine College",
  },
  {
    label: "Alliant International University",
    domain: "alliant.edu",
    value: "Alliant International University",
  },
  {
    label: "Allied American University",
    domain: "allied.edu",
    value: "Allied American University",
  },
  {
    label: "Anaheim University",
    domain: "anaheim.edu",
    value: "Anaheim University",
  },
  {
    label: "Angeles College",
    domain: "angelescollege.edu",
    value: "Angeles College",
  },
  {
    label: "Antioch University Los Angeles",
    domain: "antiochla.edu",
    value: "Antioch University Los Angeles",
  },
  {
    label: "Argosy University",
    domain: "argosy.edu",
    value: "Argosy University",
  },
  {
    label: "Art Center College of Design",
    domain: "artcenter.edu",
    value: "Art Center College of Design",
  },
  {
    label: "The Art Institute",
    domain: "artinstitutes.edu",
    value: "The Art Institute",
  },
  {
    label: "Ashford University",
    domain: "ashford.edu",
    value: "Ashford University",
  },
  { label: "ATI College", domain: "ati.edu", value: "ATI College" },
  {
    label: "Antelope Valley College",
    domain: "avc.edu",
    value: "Antelope Valley College",
  },
  {
    label: "Bakersfield College",
    domain: "bakersfieldcollege.edu",
    value: "Bakersfield College",
  },
  {
    label: "Barstow Community College",
    domain: "barstow.edu",
    value: "Barstow Community College",
  },
  {
    label: "Berkeley University of California",
    domain: "berkeley.edu",
    value: "Berkeley University of California",
  },
  {
    label: "Berkeley City College",
    domain: "berkeleycitycollege.edu",
    value: "Berkeley City College",
  },
  {
    label: "Brandman University",
    domain: "brandman.edu",
    value: "Brandman University",
  },
  { label: "Butte College", domain: "butte.edu", value: "Butte College" },
  {
    label: "Cabrillo College",
    domain: "cabrillo.edu",
    value: "Cabrillo College",
  },
  {
    label: "California Institute of the Arts",
    domain: "calarts.edu",
    value: "California Institute of the Arts",
  },
  {
    label: "California Baptist University",
    domain: "calbaptist.edu",
    value: "California Baptist University",
  },
  {
    label: "California Lutheran University",
    domain: "callutheran.edu",
    value: "California Lutheran University",
  },
  {
    label: "California Miramar University",
    domain: "calmu.edu",
    value: "California Miramar University",
  },
  {
    label: "California Polytechnic State University",
    domain: "calpoly.edu",
    value: "California Polytechnic State University",
  },
  {
    label: "California State University",
    domain: "calstate.edu",
    value: "California State University",
  },
  {
    label: "California Institute of Technology",
    domain: "caltech.edu",
    value: "California Institute of Technology",
  },
  {
    label: "Canada College",
    domain: "canadacollege.edu",
    value: "Canada College",
  },
  {
    label: "College of the Canyons",
    domain: "canyons.edu",
    value: "College of the Canyons",
  },
  {
    label: "California College of the Arts",
    domain: "cca.edu",
    value: "California College of the Arts",
  },
  {
    label: "California Community Colleges Chanellor's Office",
    domain: "cccco.edu",
    value: "California Community Colleges Chanellor's Office",
  },
  {
    label: "City College of San Francisco",
    domain: "ccsf.edu",
    value: "City College of San Francisco",
  },
  {
    label: "Cerritos College",
    domain: "cerritos.edu",
    value: "Cerritos College",
  },
  {
    label: "Cerro Coso Community College",
    domain: "cerrocoso.edu",
    value: "Cerro Coso Community College",
  },
  {
    label: "Claremont Graduate University",
    domain: "cgu.edu",
    value: "Claremont Graduate University",
  },
  {
    label: "Chaffey College",
    domain: "chaffey.edu",
    value: "Chaffey College",
  },
  {
    label: "Chapman University",
    domain: "chapman.edu",
    value: "Chapman University",
  },
  {
    label: "California Institute of Integral Studies",
    domain: "ciis.edu",
    value: "California Institute of Integral Studies",
  },
  {
    label: "Citrus College",
    domain: "citruscollege.edu",
    value: "Citrus College",
  },
  {
    label: "The Claremont Colleges",
    domain: "claremont.edu",
    value: "The Claremont Colleges",
  },
  {
    label: "Claremont McKenna College",
    domain: "cmc.edu",
    value: "Claremont McKenna College",
  },
  {
    label: "Copper Mountain College",
    domain: "cmccd.edu",
    value: "Copper Mountain College",
  },
  {
    label: "Cogswell Polytechnical College",
    domain: "cogswell.edu",
    value: "Cogswell Polytechnical College",
  },
  {
    label: "College of San Mateo",
    domain: "collegeofsanmateo.edu",
    value: "College of San Mateo",
  },
  {
    label: "College of the Desert",
    domain: "collegeofthedesert.edu",
    value: "College of the Desert",
  },
  {
    label: "El Camino College Compton Center",
    domain: "compton.edu",
    value: "El Camino College Compton Center",
  },
  {
    label: "Contra Costa College",
    domain: "contracosta.edu",
    value: "Contra Costa College",
  },
  {
    label: "College of the Sequoias",
    domain: "cos.edu",
    value: "College of the Sequoias",
  },
  {
    label: "Crafton Hills College",
    domain: "craftonhills.edu",
    value: "Crafton Hills College",
  },
  {
    label: "California State University Bakersfield",
    domain: "csub.edu",
    value: "California State University Bakersfield",
  },
  {
    label: "California State University Channel Islands",
    domain: "csuci.edu",
    value: "California State University Channel Islands",
  },
  {
    label: "California State University Dominguez Hills",
    domain: "csudh.edu",
    value: "California State University Dominguez Hills",
  },
  {
    label: "California State University East Bay",
    domain: "csueastbay.edu",
    value: "California State University East Bay",
  },
  {
    label: "Fresno State",
    domain: "fresnostate.edu",
    value: "Fresno State",
  },
  {
    label: "California State University Long Beach",
    domain: "csulb.edu",
    value: "California State University Long Beach",
  },
  {
    label: "California State University Monterey Bay",
    domain: "csumb.edu",
    value: "California State University Monterey Bay",
  },
  {
    label: "California State University Northridge",
    domain: "csun.edu",
    value: "California State University Northridge",
  },
  {
    label: "California State Polytechnic University Pomona",
    domain: "csupomona.edu",
    value: "California State Polytechnic University Pomona",
  },
  {
    label: "California State University Sacramento",
    domain: "csus.edu",
    value: "California State University Sacramento",
  },
  {
    label: "California State University San Bernardino",
    domain: "csusb.edu",
    value: "California State University San Bernardino",
  },
  {
    label: "California State University San Marcos",
    domain: "csusm.edu",
    value: "California State University San Marcos",
  },
  {
    label: "California State University Stanislaus",
    domain: "csustan.edu",
    value: "California State University Stanislaus",
  },
  {
    label: "Cuesta College",
    domain: "cuesta.edu",
    value: "Cuesta College",
  },
  {
    label: "Cuyamaca College",
    domain: "cuyamaca.edu",
    value: "Cuyamaca College",
  },
  {
    label: "California Western School of Law San Diego",
    domain: "cwsl.edu",
    value: "California Western School of Law San Diego",
  },
  {
    label: "Cypress College",
    domain: "cypresscollege.edu",
    value: "Cypress College",
  },
  {
    label: "DeAnza College",
    domain: "deanza.edu",
    value: "DeAnza College",
  },
  {
    label: "San Joaquin Delta College",
    domain: "deltacollege.edu",
    value: "San Joaquin Delta College",
  },
  {
    label: "Dominican University of California",
    domain: "dominican.edu",
    value: "Dominican University of California",
  },
  {
    label: "Dominican School of Philosophy and Theology",
    domain: "dspt.edu",
    value: "Dominican School of Philosophy and Theology",
  },
  {
    label: "Diablo Valley College",
    domain: "dvc.edu",
    value: "Diablo Valley College",
  },
  {
    label: "East Los Angeles College",
    domain: "elac.edu",
    value: "East Los Angeles College",
  },
  {
    label: "El Camino College",
    domain: "elcamino.edu",
    value: "El Camino College",
  },
  {
    label: "Ex'pression College",
    domain: "expression.edu",
    value: "Ex'pression College",
  },
  {
    label: "Foothill-De Anza Community College District",
    domain: "fhda.edu",
    value: "Foothill-De Anza Community College District",
  },
  {
    label: "Fashion Institute of Design & Merchandising",
    domain: "fidm.edu",
    value: "Fashion Institute of Design & Merchandising",
  },
  {
    label: "Fielding Graduate University",
    domain: "fielding.edu",
    value: "Fielding Graduate University",
  },
  {
    label: "Foothill College",
    domain: "foothill.edu",
    value: "Foothill College",
  },
  {
    label: "Fresno Pacific University",
    domain: "fresno.edu",
    value: "Fresno Pacific University",
  },
  {
    label: "Fresno City College",
    domain: "fresnocitycollege.edu",
    value: "Fresno City College",
  },
  {
    label: "Frederick Taylor University",
    domain: "ftu.edu",
    value: "Frederick Taylor University",
  },
  {
    label: "Fullerton College",
    domain: "fullcoll.edu",
    value: "Fullerton College",
  },
  {
    label: "Fuller Theological Seminary",
    domain: "fuller.edu",
    value: "Fuller Theological Seminary",
  },
  {
    label: "California State University Fullerton",
    domain: "fullerton.edu",
    value: "California State University Fullerton",
  },
  {
    label: "Gavilan College",
    domain: "gavilan.edu",
    value: "Gavilan College",
  },
  {
    label: "Golden Gate Baptist Theological Seminary",
    domain: "ggbts.edu",
    value: "Golden Gate Baptist Theological Seminary",
  },
  {
    label: "Gnomon School of Visual Effects",
    domain: "gnomon.edu",
    value: "Gnomon School of Visual Effects",
  },
  {
    label: "Golden West College",
    domain: "goldenwestcollege.edu",
    value: "Golden West College",
  },
  {
    label: "Grossmont College",
    domain: "grossmont.edu",
    value: "Grossmont College",
  },
  {
    label: "Allan Hancock College",
    domain: "hancockcollege.edu",
    value: "Allan Hancock College",
  },
  {
    label: "Hartnell College",
    domain: "hartnell.edu",
    value: "Hartnell College",
  },
  {
    label: "Henley-Putnam University",
    domain: "henley-putnam.edu",
    value: "Henley-Putnam University",
  },
  {
    label: "Harvey Mudd College",
    domain: "hmc.edu",
    value: "Harvey Mudd College",
  },
  {
    label: "Holy Names University",
    domain: "hnu.edu",
    value: "Holy Names University",
  },
  {
    label: "Humboldt State University",
    domain: "humboldt.edu",
    value: "Humboldt State University",
  },
  {
    label: "Humphreys College",
    domain: "humphreys.edu",
    value: "Humphreys College",
  },
  {
    label: "Imperial Valley College",
    domain: "imperial.edu",
    value: "Imperial Valley College",
  },
  {
    label: "International Sports Sciences Association",
    domain: "issaonline.edu",
    value: "International Sports Sciences Association",
  },
  {
    label: "International Technological University",
    domain: "itu.edu",
    value: "International Technological University",
  },
  {
    label: "Irvine Valley College",
    domain: "ivc.edu",
    value: "Irvine Valley College",
  },
  {
    label: "William Jessup University",
    domain: "jessup.edu",
    value: "William Jessup University",
  },
  {
    label: "John F. Kennedy University",
    domain: "jfku.edu",
    value: "John F. Kennedy University",
  },
  {
    label: "Keck Graduate Institute",
    domain: "kgi.edu",
    value: "Keck Graduate Institute",
  },
  {
    label: "Mount Saint Mary's College",
    domain: "la.edu",
    value: "Mount Saint Mary's College",
  },

  {
    value: "C\u00e9gep de Saint-J\u00e9r\u00f4me",
    domain: "cstj.qc.ca",
    label: "C\u00e9gep de Saint-J\u00e9r\u00f4me",
  },
  {
    value: "Lambton College",
    domain: "lambtoncollege.ca",
    label: "Lambton College",
  },
  {
    value: "Lambton College",
    domain: "mylambton.ca",
    label: "Lambton College",
  },
  {
    value: "Acadia University",
    domain: "acadiau.ca",
    label: "Acadia University",
  },
  {
    value: "Algonquin College",
    domain: "algonquincollege.com",
    label: "Algonquin College",
  },
  {
    value: "Ashton College",
    domain: "ashtoncollege.com",
    label: "Ashton College",
  },
  {
    value: "Assumption University",
    domain: "assumptionu.ca",
    label: "Assumption University",
  },
  {
    value: "Athabasca University",
    domain: "athabascau.ca",
    label: "Athabasca University",
  },
  {
    value: "Augustana University College",
    domain: "augustana.ab.ca",
    label: "Augustana University College",
  },
  {
    value: "British Columbia Institute of Technology",
    domain: "bcit.ca",
    label: "British Columbia Institute of Technology",
  },
  {
    value: "British Columbia Open University",
    domain: "bcou.ca",
    label: "British Columbia Open University",
  },
  {
    value: "William and Catherine Booth College",
    domain: "boothcollege.ca",
    label: "William and Catherine Booth College",
  },
  {
    value: "Coll\u00e8ge Bor\u00e9al",
    domain: "borealc.on.ca",
    label: "Coll\u00e8ge Bor\u00e9al",
  },
  {
    value: "Bow Valley College",
    domain: "bowvalleycollege.ca",
    label: "Bow Valley College",
  },
  {
    value: "Brandon University",
    domain: "brandonu.ca",
    label: "Brandon University",
  },
  {
    value: "Brock University",
    domain: "brocku.ca",
    label: "Brock University",
  },
  {
    value: "DeVry Institute of Technology",
    domain: "cal.devry.ca",
    label: "DeVry Institute of Technology",
  },
  {
    value: "Camosun College",
    domain: "camosun.bc.ca",
    label: "Camosun College",
  },
  {
    value: "Capilano College",
    domain: "capcollege.bc.ca",
    label: "Capilano College",
  },
  {
    value: "University College of the Cariboo",
    domain: "cariboo.bc.ca",
    label: "University College of the Cariboo",
  },
  {
    value: "Carleton University",
    domain: "carleton.ca",
    label: "Carleton University",
  },
  {
    value: "Canadian College of Business & Computers",
    domain: "ccbc.ca",
    label: "Canadian College of Business & Computers",
  },
  {
    value: "Centennial College",
    domain: "centennialcollege.ca",
    label: "Centennial College",
  },
  {
    value: "Canadian Mennonite University",
    domain: "cmu.ca",
    label: "Canadian Mennonite University",
  },
  {
    value: "College of New Caledonia",
    domain: "cnc.bc.ca",
    label: "College of New Caledonia",
  },
  {
    value: "Columbia College",
    domain: "columbiacollege.ca",
    label: "Columbia College",
  },
  {
    value: "Concordia University College of Alberta",
    domain: "concordia.ab.ca",
    label: "Concordia University College of Alberta",
  },
  {
    value: "Concordia University",
    domain: "concordia.ca",
    label: "Concordia University",
  },
  {
    value: "Conestoga College",
    domain: "conestogac.on.ca",
    label: "Conestoga College",
  },
  {
    value: "College of the Rockies",
    domain: "cotr.bc.ca",
    label: "College of the Rockies",
  },
  {
    value: "University of Moncton, Shippagan",
    domain: "cus.ca",
    label: "University of Moncton, Shippagan",
  },
  {
    value: "University of Moncton, Edmundston",
    domain: "cuslm.ca",
    label: "University of Moncton, Edmundston",
  },
  {
    value: "Dalhousie University",
    domain: "dal.ca",
    label: "Dalhousie University",
  },
  {
    value: "Douglas College",
    domain: "douglas.bc.ca",
    label: "Douglas College",
  },
  {
    value: "Durham College",
    domain: "dcmail.ca",
    label: "Durham College",
  },
  {
    value: "Emily Carr Institute of Art + Design",
    domain: "eciad.bc.ca",
    label: "Emily Carr Institute of Art + Design",
  },
  {
    value:
      "\u00c9cole nationale d'administration publique, Universit\u00e9 du Qu\u00e9bec",
    domain: "enap.uquebec.ca",
    label:
      "\u00c9cole nationale d'administration publique, Universit\u00e9 du Qu\u00e9bec",
  },
  {
    value:
      "\u00c9cole de technologie sup\u00e9rieure, Universit\u00e9 du Qu\u00e9bec",
    domain: "etsmtl.ca",
    label:
      "\u00c9cole de technologie sup\u00e9rieure, Universit\u00e9 du Qu\u00e9bec",
  },
  {
    value: "First Nations University of Canada",
    domain: "firstnationsuniversity.ca",
    label: "First Nations University of Canada",
  },
  {
    value: "George Brown College",
    domain: "gbrownc.on.ca",
    label: "George Brown College",
  },
  {
    value: "\u00c9cole des Hautes \u00c9tudes Commerciales",
    domain: "hec.ca",
    label: "\u00c9cole des Hautes \u00c9tudes Commerciales",
  },
  {
    value: "Humber College",
    domain: "humber.ca",
    label: "Humber College",
  },
  {
    value: "Humber College",
    domain: "humbermail.ca",
    label: "Humber College",
  },
  {
    value: "Huron University College",
    domain: "huronuc.on.ca",
    label: "Huron University College",
  },
  {
    value: "Institut Armand-Frappier, Universit\u00e9 du Qu\u00e9bec",
    domain: "iaf.inrs.ca",
    label: "Institut Armand-Frappier, Universit\u00e9 du Qu\u00e9bec",
  },
  {
    value:
      "Institut National de la Recherche Scientifique, Universit\u00e9 du Qu\u00e9bec",
    domain: "inrs.uquebec.ca",
    label:
      "Institut National de la Recherche Scientifique, Universit\u00e9 du Qu\u00e9bec",
  },
  {
    value: "Kingston College",
    domain: "kingston.edu",
    label: "Kingston College",
  },
  {
    value: "King's University College",
    domain: "kingsu.ab.ca",
    label: "King's University College",
  },
  {
    value: "Kwantleen University College",
    domain: "kwantlen.bc.ca",
    label: "Kwantleen University College",
  },
  {
    value: "Lakehead University",
    domain: "lakeheadu.ca",
    label: "Lakehead University",
  },
  {
    value: "Langara College",
    domain: "langara.bc.ca",
    label: "Langara College",
  },
  {
    value: "Langara College",
    domain: "langara.ca",
    label: "Langara College",
  },
  {
    value: "Lansbridge University",
    domain: "lansbridge.com",
    label: "Lansbridge University",
  },
  {
    value: "Laurentian University of Sudbury",
    domain: "laurentian.ca",
    label: "Laurentian University of Sudbury",
  },
  {
    value: "MacEwan University",
    domain: "macewan.ca",
    label: "MacEwan University",
  },
  {
    value: "Malaspina University College",
    domain: "mala.bc.ca",
    label: "Malaspina University College",
  },
  {
    value: "McGill University",
    domain: "mcgill.ca",
    label: "McGill University",
  },
  {
    value: "McMaster University",
    domain: "mcmaster.ca",
    label: "McMaster University",
  },
  {
    value: "Mount Saint Vincent University",
    domain: "msvu.ca",
    label: "Mount Saint Vincent University",
  },
  {
    value: "Mount Allison University",
    domain: "mta.ca",
    label: "Mount Allison University",
  },
  {
    value: "Mount Royal University",
    domain: "mtroyal.ca",
    label: "Mount Royal University",
  },
  {
    value: "Northern Alberta Institute of Technology",
    domain: "nait.ca",
    label: "Northern Alberta Institute of Technology",
  },
  {
    value: "North Island College",
    domain: "nic.bc.ca",
    label: "North Island College",
  },
  {
    value: "Nipissing University",
    domain: "nipissingu.ca",
    label: "Nipissing University",
  },
  {
    value: "Northern Lights College",
    domain: "nlc.bc.ca",
    label: "Northern Lights College",
  },
  {
    value: "Nova Scotia Agricultural College",
    domain: "nsac.ns.ca",
    label: "Nova Scotia Agricultural College",
  },
  {
    value: "Nova Scotia College of Art and Design",
    domain: "nscad.ns.ca",
    label: "Nova Scotia College of Art and Design",
  },
  {
    value: "Nicola Valley Institute of Technology",
    domain: "nvit.bc.ca",
    label: "Nicola Valley Institute of Technology",
  },
  {
    value: "Ontario College of Art and Design",
    domain: "ocad.ca",
    label: "Ontario College of Art and Design",
  },
  {
    value: "Okanagan University College",
    domain: "okanagan.bc.ca",
    label: "Okanagan University College",
  },
  {
    value: "Dominican College of Philosophy and Theology",
    domain: "op.org",
    label: "Dominican College of Philosophy and Theology",
  },
  {
    value: "Pacific International College",
    domain: "picollege.ca",
    label: "Pacific International College",
  },
  {
    value:
      "\u00c9cole Polytechnique de Montr\u00e9al, Universit\u00e9 de Montr\u00e9al",
    domain: "polymtl.ca",
    label:
      "\u00c9cole Polytechnique de Montr\u00e9al, Universit\u00e9 de Montr\u00e9al",
  },
  {
    value: "Queen's University",
    domain: "queensu.ca",
    label: "Queen's University",
  },
  {
    value: "Quest University",
    domain: "questu.ca",
    label: "Quest University",
  },
  {
    value: "Redeemer College",
    domain: "redeemer.ca",
    label: "Redeemer College",
  },
  {
    value: "Regent College",
    domain: "regent-college.edu",
    label: "Regent College",
  },
  {
    value: "Royal Military College of Canada",
    domain: "rmc.ca",
    label: "Royal Military College of Canada",
  },
  {
    value: "Royal Roads University",
    domain: "royalroads.ca",
    label: "Royal Roads University",
  },
  {
    value: "Ryerson Polytechnic University",
    domain: "ryerson.ca",
    label: "Ryerson Polytechnic University",
  },
  {
    value: "Southern Alberta Institute of Technology",
    domain: "sait.ca",
    label: "Southern Alberta Institute of Technology",
  },
  {
    value: "Luther College",
    domain: "saskweb.com",
    label: "Luther College",
  },
  {
    value: "Sauder School of Business",
    domain: "sauder.ubc.ca",
    label: "Sauder School of Business",
  },
  {
    value: "University of Toronto, Scarborough",
    domain: "scar.utoronto.ca",
    label: "University of Toronto, Scarborough",
  },
  {
    value: "Selkirk College",
    domain: "selkirk.bc.ca",
    label: "Selkirk College",
  },
  {
    value: "Seneca College",
    domain: "senecacollege.ca",
    label: "Seneca College",
  },
  {
    value: "Seneca College",
    domain: "myseneca.ca",
    label: "Seneca College",
  },
  {
    value: "Simon Fraser University",
    domain: "sfu.ca",
    label: "Simon Fraser University",
  },
  {
    value: "Sheridan College",
    domain: "sheridancollege.ca",
    label: "Sheridan College",
  },
  {
    value: "Saskatchewan Indian Federated College",
    domain: "sifc.edu",
    label: "Saskatchewan Indian Federated College",
  },
  {
    value: "St. Clair College",
    domain: "stclairc.on.ca",
    label: "St. Clair College",
  },
  {
    value: "St. Clair College",
    domain: "stclairconnect.ca",
    label: "St. Clair College",
  },
  {
    value: "St. Clair College",
    domain: "myscc.ca",
    label: "St. Clair College",
  },
  {
    value: "St. Francis Xavier University",
    domain: "stfx.ca",
    label: "St. Francis Xavier University",
  },
  {
    value: "St. Mary's University",
    domain: "stmarys.ca",
    label: "St. Mary's University",
  },
  {
    value: "St. Mary's University",
    domain: "smu.ca",
    label: "St. Mary's University",
  },
  {
    value: "St. Thomas University",
    domain: "stthomasu.ca",
    label: "St. Thomas University",
  },
  {
    value: "T\u00e9l\u00e9-universit\u00e9, Universit\u00e9 du Qu\u00e9bec",
    domain: "teluq.uquebec.ca",
    label: "T\u00e9l\u00e9-universit\u00e9, Universit\u00e9 du Qu\u00e9bec",
  },
  {
    value: "Trent University",
    domain: "trentu.ca",
    label: "Trent University",
  },
  {
    value: "University of Trinity College",
    domain: "trinity.utoronto.ca",
    label: "University of Trinity College",
  },
  {
    value: "Thompson Rivers University",
    domain: "tru.ca",
    label: "Thompson Rivers University",
  },
  {
    value: "Trinity Western University",
    domain: "twu.ca",
    label: "Trinity Western University",
  },
  {
    value: "University of Alberta",
    domain: "ualberta.ca",
    label: "University of Alberta",
  },
  {
    value: "University of British Columbia",
    domain: "ubc.ca",
    label: "University of British Columbia",
  },
  {
    value: "Bishop's University",
    domain: "ubishops.ca",
    label: "Bishop's University",
  },
  {
    value: "University of Calgary",
    domain: "ucalgary.ca",
    label: "University of Calgary",
  },
  {
    value: "University College of Cape Breton",
    domain: "uccb.ns.ca",
    label: "University College of Cape Breton",
  },
  {
    value: "University College of the Fraser Valley",
    domain: "ucfv.bc.ca",
    label: "University College of the Fraser Valley",
  },
  {
    value: "Memorial University of Newfoundland",
    domain: "ucs.mun.ca",
    label: "Memorial University of Newfoundland",
  },
  {
    value: "University of King's College",
    domain: "ukings.ns.ca",
    label: "University of King's College",
  },
  {
    value: "Universit\u00e9 Laval",
    domain: "ulaval.ca",
    label: "Universit\u00e9 Laval",
  },
  {
    value: "University of Lethbridge",
    domain: "uleth.ca",
    label: "University of Lethbridge",
  },
  {
    value: "University of Manitoba",
    domain: "umanitoba.ca",
    label: "University of Manitoba",
  },
  {
    value: "University of Moncton",
    domain: "umoncton.ca",
    label: "University of Moncton",
  },
  {
    value: "Universit\u00e9 de Montr\u00e9al",
    domain: "umontreal.ca",
    label: "Universit\u00e9 de Montr\u00e9al",
  },
  {
    value: "University of New Brunswick",
    domain: "unb.ca",
    label: "University of New Brunswick",
  },
  {
    value: "University of Northern British Columbia",
    domain: "unbc.ca",
    label: "University of Northern British Columbia",
  },
  {
    value: "University of New Brunswick, Saint John",
    domain: "unbsj.ca",
    label: "University of New Brunswick, Saint John",
  },
  {
    value: "University Canada West",
    domain: "universitycanadawest.ca",
    label: "University Canada West",
  },
  {
    value: "University of Guelph",
    domain: "uoguelph.ca",
    label: "University of Guelph",
  },
  {
    value: "University of Ontario Institute of Technology",
    domain: "uoit.ca",
    label: "University of Ontario Institute of Technology",
  },
  {
    value: "University of Ottawa",
    domain: "uottawa.ca",
    label: "University of Ottawa",
  },
  {
    value: "University of Prince Edward Island",
    domain: "upei.ca",
    label: "University of Prince Edward Island",
  },
  {
    value: "Universit\u00e9 du Qu\u00e9bec \u00e0 Chicoutimi",
    domain: "uqac.ca",
    label: "Universit\u00e9 du Qu\u00e9bec \u00e0 Chicoutimi",
  },
  {
    value: "Universit\u00e9 du Qu\u00e9bec \u00e0 Montr\u00e9al",
    domain: "uqam.ca",
    label: "Universit\u00e9 du Qu\u00e9bec \u00e0 Montr\u00e9al",
  },
  {
    value: "Universit\u00e9 du Qu\u00e9bec \u00e0 Rimouski",
    domain: "uqar.uquebec.ca",
    label: "Universit\u00e9 du Qu\u00e9bec \u00e0 Rimouski",
  },
  {
    value: "Universit\u00e9 du Qu\u00e9bec en Abitibi-T\u00e9miscamingue",
    domain: "uqat.uquebec.ca",
    label: "Universit\u00e9 du Qu\u00e9bec en Abitibi-T\u00e9miscamingue",
  },
  {
    value: "Universit\u00e9 du Qu\u00e9bec en Outaouais",
    domain: "uqo.ca",
    label: "Universit\u00e9 du Qu\u00e9bec en Outaouais",
  },
  {
    value: "Universit\u00e9 du Qu\u00e9bec \u00e0 Trois-Rivi\u00e8res",
    domain: "uqtr.uquebec.ca",
    label: "Universit\u00e9 du Qu\u00e9bec \u00e0 Trois-Rivi\u00e8res",
  },
  {
    value: "University of Qu\u00e9bec",
    domain: "uquebec.ca",
    label: "University of Qu\u00e9bec",
  },
  {
    value: "Campion College",
    domain: "uregina.ca",
    label: "Campion College",
  },
  {
    value: "University of Regina",
    domain: "uregina.ca",
    label: "University of Regina",
  },
  {
    value: "University of Saskatchewan",
    domain: "usask.ca",
    label: "University of Saskatchewan",
  },
  {
    value: "Universit\u00e9 de Sherbrooke",
    domain: "usherb.ca",
    label: "Universit\u00e9 de Sherbrooke",
  },
  {
    value: "University of St. Jerome's College",
    domain: "usjc.uwaterloo.ca",
    label: "University of St. Jerome's College",
  },
  {
    value: "St. Anne University",
    domain: "ustanne.ednet.ns.ca",
    label: "St. Anne University",
  },
  {
    value: "University College of Saint-Boniface",
    domain: "ustboniface.mb.ca",
    label: "University College of Saint-Boniface",
  },
  {
    value: "St. Paul University",
    domain: "ustpaul.ca",
    label: "St. Paul University",
  },
  {
    value: "University of Sudbury",
    domain: "usudbury.com",
    label: "University of Sudbury",
  },
  {
    value: "University of Toronto, Mississauga",
    domain: "utm.utoronto.ca",
    label: "University of Toronto, Mississauga",
  },
  {
    value: "University of St. Michael's College",
    domain: "utoronto.ca",
    label: "University of St. Michael's College",
  },
  {
    value: "University of Toronto",
    domain: "utoronto.ca",
    label: "University of Toronto",
  },
  {
    value: "University of Victoria",
    domain: "uvic.ca",
    label: "University of Victoria",
  },
  {
    value: "University of Waterloo",
    domain: "uwaterloo.ca",
    label: "University of Waterloo",
  },
  {
    value: "University of Windsor",
    domain: "uwindsor.ca",
    label: "University of Windsor",
  },
  {
    value: "University of Winnipeg",
    domain: "uwinnipeg.ca",
    label: "University of Winnipeg",
  },
  {
    value: "Brescia University College",
    domain: "uwo.ca",
    label: "Brescia University College",
  },
  { value: "King's College", domain: "uwo.ca", label: "King's College" },
  {
    value: "University of Western Ontario",
    domain: "uwo.ca",
    label: "University of Western Ontario",
  },
  {
    value: "Vancouver Community College",
    domain: "vcc.ca",
    label: "Vancouver Community College",
  },
  {
    value: "Victoria University Toronto, University of Toronto",
    domain: "vicu.utoronto.ca",
    label: "Victoria University Toronto, University of Toronto",
  },
  {
    value: "Wilfrid Laurier University",
    domain: "wlu.ca",
    label: "Wilfrid Laurier University",
  },
  {
    value: "Wilfrid Laurier University",
    domain: "mylaurier.ca",
    label: "Wilfrid Laurier University",
  },
  {
    value: "York University",
    domain: "yorku.ca",
    label: "York University",
  },
  {
    value: "Yukon College",
    domain: "yukoncollege.yk.ca",
    label: "Yukon College",
  },
  {
    value: "Mohawk College",
    domain: "mohawkcollege.ca",
    label: "Mohawk College",
  },
  {
    value: "College of Bois-de-Boulogne",
    domain: "bdeb.qc.ca",
    label: "College of Bois-de-Boulogne",
  },
  {
    value: "Fanshawe College",
    domain: "fanshawec.ca",
    label: "Fanshawe College",
  },
  {
    value: "Fanshawe College",
    domain: "fanshaweonline.ca",
    label: "Fanshawe College",
  },
  {
    value: "Vanier College",
    domain: "vaniercollege.qc.ca",
    label: "Vanier College",
  },
  {
    value: "Ontario Tech University",
    domain: "ontariotechu.ca",
    label: "Ontario Tech University",
  },
  {
    value: "Ontario Tech University",
    domain: "ontariotechu.net",
    label: "Ontario Tech University",
  },
  {
    value: "Toronto Metropolitan University",
    domain: "torontomu.ca",
    label: "Toronto Metropolitan University",
  },
  {
    value: "Coll\u00e8ge Universel Gatineau",
    domain: "collegeuniversel.ca",
    label: "Coll\u00e8ge Universel Gatineau",
  },

  {
    label: "Los Angeles Community College District",
    domain: "laccd.edu",
    value: "Los Angeles Community College District",
  },
  {
    label: "Los Angeles City College",
    domain: "lacitycollege.edu",
    value: "Los Angeles City College",
  },
  {
    label: "Los Angeles Harbor College",
    domain: "lahc.edu",
    value: "Los Angeles Harbor College",
  },
  {
    label: "Los Angeles Mission College",
    domain: "lamission.edu",
    value: "Los Angeles Mission College",
  },
  { label: "Laney College", domain: "laney.edu", value: "Laney College" },
  {
    label: "Los Angeles Southwest College",
    domain: "lasc.edu",
    value: "Los Angeles Southwest College",
  },
  {
    label: "La Sierra University",
    domain: "lasierra.edu",
    value: "La Sierra University",
  },
  {
    label: "Las Positas College",
    domain: "laspositascollege.edu",
    value: "Las Positas College",
  },
  {
    label: "Los Angeles Trade-Tech Community College",
    domain: "lattc.edu",
    value: "Los Angeles Trade-Tech Community College",
  },
  {
    label: "Laurus College",
    domain: "lauruscollege.edu",
    value: "Laurus College",
  },
  {
    label: "Los Angeles Valley College",
    domain: "lavc.edu",
    value: "Los Angeles Valley College",
  },
  {
    label: "University of La Verne",
    domain: "laverne.edu",
    value: "University of La Verne",
  },
  {
    label: "UCLA School of Law",
    domain: "ucla.edu",
    value: "UCLA School of Law",
  },
  {
    label: "Long Beach City College",
    domain: "lbcc.edu",
    value: "Long Beach City College",
  },
  {
    label: "Life Chiropractic College West",
    domain: "lifewest.edu",
    value: "Life Chiropractic College West",
  },
  {
    label: "Loma Linda University",
    domain: "llu.edu",
    value: "Loma Linda University",
  },
  {
    label: "Loyola Marymount University",
    domain: "lmu.edu",
    value: "Loyola Marymount University",
  },
  {
    label: "Los Medanos College",
    domain: "losmedanos.edu",
    value: "Los Medanos College",
  },
  {
    label: "Los Rios Community College District",
    domain: "losrios.edu",
    value: "Los Rios Community College District",
  },
  {
    label: "Lake Tahoe Community College",
    domain: "ltcc.edu",
    value: "Lake Tahoe Community College",
  },
  {
    label: "College of Marin",
    domain: "marin.edu",
    value: "College of Marin",
  },
  {
    label: "Marymount California University",
    domain: "marymountcalifornia.edu",
    value: "Marymount California University",
  },
  {
    label: "The Master's College",
    domain: "masters.edu",
    value: "The Master's College",
  },
  {
    label: "Merced Community College",
    domain: "mccd.edu",
    value: "Merced Community College",
  },
  { label: "Mills College", domain: "mills.edu", value: "Mills College" },
  {
    label: "MiraCosta College",
    domain: "miracosta.edu",
    value: "MiraCosta College",
  },
  {
    label: "Modesto Junior College",
    domain: "mjc.edu",
    value: "Modesto Junior College",
  },
  {
    label: "Mount San Jacinto College",
    domain: "msjc.edu",
    value: "Mount San Jacinto College",
  },
  {
    label: "Mount San Antonio College",
    domain: "mtsac.edu",
    value: "Mount San Antonio College",
  },
  {
    label: "Napa Valley College",
    domain: "napavalley.edu",
    value: "Napa Valley College",
  },
  {
    label: "Notre Dame de Namur University",
    domain: "ndnu.edu",
    value: "Notre Dame de Namur University",
  },
  {
    label: "NewSchool of Architecture and Design",
    domain: "newschoolarch.edu",
    value: "NewSchool of Architecture and Design",
  },
  {
    label: "Norco College",
    domain: "norcocollege.edu",
    value: "Norco College",
  },
  {
    label: "Naval Postgraduate School",
    domain: "nps.edu",
    value: "Naval Postgraduate School",
  },
  {
    label: "Ohlone College",
    domain: "ohlone.edu",
    value: "Ohlone College",
  },
  {
    label: "Orange Coast College",
    domain: "orangecoastcollege.edu",
    value: "Orange Coast College",
  },
  {
    label: "Otis College of Art and Design",
    domain: "otis.edu",
    value: "Otis College of Art and Design",
  },
  {
    label: "Occidental College",
    domain: "oxy.edu",
    value: "Occidental College",
  },
  {
    label: "Pacific College of Oriental Medicine",
    domain: "pacificcollege.edu",
    value: "Pacific College of Oriental Medicine",
  },
  {
    label: "Palomar College",
    domain: "palomar.edu",
    value: "Palomar College",
  },
  {
    label: "Pasadena City College",
    domain: "pasadena.edu",
    value: "Pasadena City College",
  },
  {
    label: "Pepperdine University",
    domain: "pepperdine.edu",
    value: "Pepperdine University",
  },
  {
    label: "Peralta Community College District",
    domain: "peralta.edu",
    value: "Peralta Community College District",
  },
  {
    label: "Pierce College",
    domain: "piercecollege.edu",
    value: "Pierce College",
  },
  {
    label: "Pitzer College",
    domain: "pitzer.edu",
    value: "Pitzer College",
  },
  {
    label: "Pacific Lutheran Theological Seminary",
    domain: "plts.edu",
    value: "Pacific Lutheran Theological Seminary",
  },
  {
    label: "Point Loma Nazarene University",
    domain: "pointloma.edu",
    value: "Point Loma Nazarene University",
  },
  {
    label: "Pomona College",
    domain: "pomona.edu",
    value: "Pomona College",
  },
  {
    label: "Porterville College",
    domain: "portervillecollege.edu",
    value: "Porterville College",
  },
  {
    label: "Pacific School of Religion",
    domain: "psr.edu",
    value: "Pacific School of Religion",
  },
  {
    label: "Riverside City College",
    domain: "rcc.edu",
    value: "Riverside City College",
  },
  {
    label: "Riverside Community College District",
    domain: "rccd.edu",
    value: "Riverside Community College District",
  },
  {
    label: "University of Redlands",
    domain: "redlands.edu",
    value: "University of Redlands",
  },
  {
    label: "College of the Redwoods",
    domain: "redwoods.edu",
    value: "College of the Redwoods",
  },
  {
    label: "Rio Hondo College",
    domain: "riohondo.edu",
    value: "Rio Hondo College",
  },
  {
    label: "Rosemead College",
    domain: "rosemeadcollege.edu",
    value: "Rosemead College",
  },
  {
    label: "Santa Ana College",
    domain: "sac.edu",
    value: "Santa Ana College",
  },
  {
    label: "Saddleback College",
    domain: "saddleback.edu",
    value: "Saddleback College",
  },
  {
    label: "University of San Diego",
    domain: "sandiego.edu",
    value: "University of San Diego",
  },
  {
    label: "Santa Rosa Junior College",
    domain: "santarosa.edu",
    value: "Santa Rosa Junior College",
  },
  {
    label: "Santa Barbara City College",
    domain: "sbcc.edu",
    value: "Santa Barbara City College",
  },
  {
    label: "School of Continuing Education",
    domain: "sce.edu",
    value: "School of Continuing Education",
  },
  {
    label: "Scripps College",
    domain: "scrippscollege.edu",
    value: "Scripps College",
  },
  {
    label: "Santa Clara University",
    domain: "scu.edu",
    value: "Santa Clara University",
  },
  {
    label: "Southern California University of Health Sciences",
    domain: "scuhs.edu",
    value: "Southern California University of Health Sciences",
  },
  {
    label: "San Diego City College",
    domain: "sdcity.edu",
    value: "San Diego City College",
  },
  {
    label: "San Diego Mesa College",
    domain: "sdmesa.edu",
    value: "San Diego Mesa College",
  },
  {
    label: "San Diego State University",
    domain: "sdsu.edu",
    value: "San Diego State University",
  },
  {
    label: "San Francisco State University",
    domain: "sfsu.edu",
    value: "San Francisco State University",
  },
  {
    label: "Shasta College",
    domain: "shastacollege.edu",
    value: "Shasta College",
  },
  {
    label: "The Institute of Buddhist Studies",
    domain: "shin-ibs.edu",
    value: "The Institute of Buddhist Studies",
  },
  {
    label: "Sierra College",
    domain: "sierracollege.edu",
    value: "Sierra College",
  },
  {
    label: "Simpson University",
    domain: "simpsonu.edu",
    value: "Simpson University",
  },
  {
    label: "College of the Siskiyous",
    domain: "siskiyous.edu",
    value: "College of the Siskiyous",
  },
  {
    label: "San Jose State University",
    domain: "sjsu.edu",
    value: "San Jose State University",
  },
  {
    label: "Starr King School for the Ministry",
    domain: "sksm.edu",
    value: "Starr King School for the Ministry",
  },
  {
    label: "Skyline College",
    domain: "skylinecollege.edu",
    value: "Skyline College",
  },
  {
    label: "Santa Monica College",
    domain: "smc.edu",
    value: "Santa Monica College",
  },
  {
    label: "San Mateo County Community College",
    domain: "smccd.edu",
    value: "San Mateo County Community College",
  },
  {
    label: "Soka University of America",
    domain: "soka.edu",
    value: "Soka University of America",
  },
  {
    label: "Solano Community College",
    domain: "solano.edu",
    value: "Solano Community College",
  },
  {
    label: "Sonoma State University",
    domain: "sonoma.edu",
    value: "Sonoma State University",
  },
  {
    label: "Stanford University",
    domain: "stanford.edu",
    value: "Stanford University",
  },
  {
    label: "Saint Mary's College",
    domain: "stmarys-ca.edu",
    value: "Saint Mary's College",
  },
  {
    label: "Taft College",
    domain: "taftcollege.edu",
    value: "Taft College",
  },
  {
    label: "Touro University California",
    domain: "tu.edu",
    value: "Touro University California",
  },
  {
    label: "University of California Davis",
    domain: "ucdavis.edu",
    value: "University of California Davis",
  },
  {
    label: "University of California Hastings College of the Law",
    domain: "uchastings.edu",
    value: "University of California Hastings College of the Law",
  },
  {
    label: "University of California Irvine",
    domain: "uci.edu",
    value: "University of California Irvine",
  },
  {
    label: "University of California Los Angeles",
    domain: "ucla.edu",
    value: "University of California Los Angeles",
  },
  {
    label: "University of California Los Angeles Extension",
    domain: "uclaextension.edu",
    value: "University of California Los Angeles Extension",
  },
  {
    label: "University of California Merced",
    domain: "ucmerced.edu",
    value: "University of California Merced",
  },
  {
    label: "University of California",
    domain: "ucop.edu",
    value: "University of California",
  },
  {
    label: "University of California Press",
    domain: "ucpress.edu",
    value: "University of California Press",
  },
  {
    label: "University of California Riverside",
    domain: "ucr.edu",
    value: "University of California Riverside",
  },
  {
    label: "University of California Santa Barbara",
    domain: "ucsb.edu",
    value: "University of California Santa Barbara",
  },
  {
    label: "University of California Santa Cruz",
    domain: "ucsc.edu",
    value: "University of California Santa Cruz",
  },
  {
    label: "University of California Santa Cruz Silicon Valley Extension",
    domain: "ucsc-extension.edu",
    value: "University of California Santa Cruz Silicon Valley Extension",
  },
  {
    label: "University of California San Diego",
    domain: "ucsd.edu",
    value: "University of California San Diego",
  },
  {
    label: "University of California San Francisco",
    domain: "ucsf.edu",
    value: "University of California San Francisco",
  },
  {
    label: "University of the Pacific",
    domain: "uop.edu",
    value: "University of the Pacific",
  },
  {
    label: "University of Southern California",
    domain: "usc.edu",
    value: "University of Southern California",
  },
  {
    label: "University of San Francisco",
    domain: "usfca.edu",
    value: "University of San Francisco",
  },
  {
    label: "San Bernardino Valley College",
    domain: "valleycollege.edu",
    value: "San Bernardino Valley College",
  },
  {
    label: "Ventura College",
    domain: "venturacollege.edu",
    value: "Ventura College",
  },
  {
    label: "Victor Valley College",
    domain: "vvc.edu",
    value: "Victor Valley College",
  },
  {
    label: "West Coast Baptist College",
    domain: "wcbc.edu",
    value: "West Coast Baptist College",
  },
  {
    label: "Western University of Health Sciences",
    domain: "westernu.edu",
    value: "Western University of Health Sciences",
  },
  {
    label: "Westmont College",
    domain: "westmont.edu",
    value: "Westmont College",
  },
  {
    label: "West Valley College",
    domain: "westvalley.edu",
    value: "West Valley College",
  },
  {
    label: "West Hills Community College District",
    domain: "whccd.edu",
    value: "West Hills Community College District",
  },
  {
    label: "Whittier College",
    domain: "whittier.edu",
    value: "Whittier College",
  },
  {
    label: "West Los Angeles College",
    domain: "wlac.edu",
    value: "West Los Angeles College",
  },
  {
    label: "Yuba Community College District",
    domain: "yccd.edu",
    value: "Yuba Community College District",
  },
  {
    label: "Yosemite Community College District",
    domain: "yosemite.edu",
    value: "Yosemite Community College District",
  },
  {
    label: "Unitek College",
    domain: "unitekcollege.edu",
    value: "Unitek College",
  },
  {
    label: "Vanguard University",
    domain: "vanguard.edu",
    value: "Vanguard University",
  },
  {
    label: "Southwestern College",
    domain: "swcc.edu",
    value: "Southwestern College",
  },
  {
    label: "Monterey Institute of International Studies",
    domain: "miis.edu",
    value: "Monterey Institute of International Studies",
  },
  {
    label: "Adams State University",
    domain: "adams.edu",
    value: "Adams State University",
  },
  {
    label: "Auraria Higher Education Center",
    domain: "ahec.edu",
    value: "Auraria Higher Education Center",
  },
  {
    label: "Aims Community College",
    domain: "aims.edu",
    value: "Aims Community College",
  },
  {
    label: "Arapahoe Community College",
    domain: "arapahoe.edu",
    value: "Arapahoe Community College",
  },
  {
    label: "Community College of Aurora",
    domain: "ccaurora.edu",
    value: "Community College of Aurora",
  },
  {
    label: "Community College of Denver",
    domain: "ccd.edu",
    value: "Community College of Denver",
  },
  {
    label: "Colorado Heights University",
    domain: "chu.edu",
    value: "Colorado Heights University",
  },
  {
    label: "University of Colorado Boulder",
    domain: "colorado.edu",
    value: "University of Colorado Boulder",
  },
  {
    label: "Colorado College",
    domain: "coloradocollege.edu",
    value: "Colorado College",
  },
  {
    label: "Colorado Mesa University",
    domain: "coloradomesa.edu",
    value: "Colorado Mesa University",
  },
  {
    label: "Colorado State University",
    domain: "colostate.edu",
    value: "Colorado State University",
  },
  {
    label: "Colorado State University-Pueblo",
    domain: "colostate-pueblo.edu",
    value: "Colorado State University-Pueblo",
  },
  {
    label: "University of Denver",
    domain: "du.edu",
    value: "University of Denver",
  },
  {
    label: "Fort Lewis College",
    domain: "fortlewis.edu",
    value: "Fort Lewis College",
  },
  {
    label: "Front Range Community College",
    domain: "frontrange.edu",
    value: "Front Range Community College",
  },
  {
    label: "Jones International University",
    domain: "jiu.edu",
    value: "Jones International University",
  },
  {
    label: "Colorado School of Mines",
    domain: "mines.edu",
    value: "Colorado School of Mines",
  },
  {
    label: "Metropolitan State University of Denver",
    domain: "msudenver.edu",
    value: "Metropolitan State University of Denver",
  },
  {
    label: "Naropa University",
    domain: "naropa.edu",
    value: "Naropa University",
  },
  {
    label: "Pikes Peak Community College",
    domain: "ppcc.edu",
    value: "Pikes Peak Community College",
  },
  {
    label: "Pueblo Community College",
    domain: "pueblocc.edu",
    value: "Pueblo Community College",
  },
  {
    label: "Redstone College",
    domain: "redstone.edu",
    value: "Redstone College",
  },
  {
    label: "Regis University",
    domain: "regis.edu",
    value: "Regis University",
  },
  {
    label: "University of the Rockies",
    domain: "rockies.edu",
    value: "University of the Rockies",
  },
  {
    label: "Red Rocks Community College",
    domain: "rrcc.edu",
    value: "Red Rocks Community College",
  },
  {
    label: "William Howard Taft University",
    domain: "taft.edu",
    value: "William Howard Taft University",
  },
  {
    label: "University Corporation for Atmospheric Research",
    domain: "ucar.edu",
    value: "University Corporation for Atmospheric Research",
  },
  {
    label: "University of Colorado Colorado Springs",
    domain: "uccs.edu",
    value: "University of Colorado Colorado Springs",
  },
  {
    label: "University of Colorado Denver",
    domain: "ucdenver.edu",
    value: "University of Colorado Denver",
  },
  {
    label: "University of Northern Colorado",
    domain: "unco.edu",
    value: "University of Northern Colorado",
  },
  {
    label: "University of Colorado",
    domain: "cu.edu",
    value: "University of Colorado",
  },
  {
    label: "Western State Colorado University",
    domain: "western.edu",
    value: "Western State Colorado University",
  },
  {
    label: "Albertus Magnus College",
    domain: "albertus.edu",
    value: "Albertus Magnus College",
  },
  {
    label: "Asnuntuck Community College",
    domain: "asnuntuck.edu",
    value: "Asnuntuck Community College",
  },
  {
    label: "Connecticut College",
    domain: "conncoll.edu",
    value: "Connecticut College",
  },
  {
    label: "Eastern Connecticut State University",
    domain: "easternct.edu",
    value: "Eastern Connecticut State University",
  },
  {
    label: "Fairfield University",
    domain: "fairfield.edu",
    value: "Fairfield University",
  },
  {
    label: "Goodwin College",
    domain: "goodwin.edu",
    value: "Goodwin College",
  },
  {
    label: "University of Hartford",
    domain: "hartford.edu",
    value: "University of Hartford",
  },
  {
    label: "Hartford Seminary",
    domain: "hartsem.edu",
    value: "Hartford Seminary",
  },
  {
    label: "University of New Haven",
    domain: "newhaven.edu",
    value: "University of New Haven",
  },
  {
    label: "Quinebaug Valley Community College",
    domain: "qvcc.edu",
    value: "Quinebaug Valley Community College",
  },
  {
    label: "Sacred Heart University",
    domain: "sacredheart.edu",
    value: "Sacred Heart University",
  },
  {
    label: "Southern Connecticut State University",
    domain: "southernct.edu",
    value: "Southern Connecticut State University",
  },
  {
    label: "Three Rivers Community College",
    domain: "threerivers.edu",
    value: "Three Rivers Community College",
  },
  {
    label: "Trinity College",
    domain: "trincoll.edu",
    value: "Trinity College",
  },
  {
    label: "Tunxis Community College",
    domain: "tunxis.edu",
    value: "Tunxis Community College",
  },
  {
    label: "University of Connecticut Health Center",
    domain: "uchc.edu",
    value: "University of Connecticut Health Center",
  },
  {
    label: "University of Connecticut",
    domain: "uconn.edu",
    value: "University of Connecticut",
  },
  {
    label: "University of Saint Joseph Connecticut",
    domain: "usj.edu",
    value: "University of Saint Joseph Connecticut",
  },
  {
    label: "Western Connecticut State University",
    domain: "wcsu.edu",
    value: "Western Connecticut State University",
  },
  {
    label: "Wesleyan University",
    domain: "wesleyan.edu",
    value: "Wesleyan University",
  },
  {
    label: "Yale University",
    domain: "yale.edu",
    value: "Yale University",
  },
  {
    label: "American University",
    domain: "american.edu",
    value: "American University",
  },
  {
    label: "Carnegie Institution for Science",
    domain: "carnegiescience.edu",
    value: "Carnegie Institution for Science",
  },
  {
    label: "The Catholic University of America",
    domain: "cua.edu",
    value: "The Catholic University of America",
  },
  {
    label: "Gallaudet University",
    domain: "gallaudet.edu",
    value: "Gallaudet University",
  },
  {
    label: "Georgetown University",
    domain: "georgetown.edu",
    value: "Georgetown University",
  },
  {
    label: "The George Washington University",
    domain: "gwu.edu",
    value: "The George Washington University",
  },
  {
    label: "Howard University",
    domain: "howard.edu",
    value: "Howard University",
  },
  {
    label: "Johns Hopkins School of Advanced International Studies",
    domain: "sais-jhu.edu",
    value: "Johns Hopkins School of Advanced International Studies",
  },
  {
    label: "University of California Washington Center",
    domain: "ucdc.edu",
    value: "University of California Washington Center",
  },
  {
    label: "University of the District of Columbia",
    domain: "udc.edu",
    value: "University of the District of Columbia",
  },
  {
    label: "Delaware State University",
    domain: "desu.edu",
    value: "Delaware State University",
  },
  {
    label: "Delaware Technical Community College",
    domain: "dtcc.edu",
    value: "Delaware Technical Community College",
  },
  {
    label: "Goldey-Beacom College",
    domain: "gbc.edu",
    value: "Goldey-Beacom College",
  },
  {
    label: "University of Delaware",
    domain: "udel.edu",
    value: "University of Delaware",
  },
  {
    label: "Wesley College",
    domain: "wesley.edu",
    value: "Wesley College",
  },
  {
    label: "Wilmington University",
    domain: "wilmu.edu",
    value: "Wilmington University",
  },
  {
    label: "Carlos Albizu University",
    domain: "albizu.edu",
    value: "Carlos Albizu University",
  },
  {
    label: "Acupuncture Massage College",
    domain: "amcollege.edu",
    value: "Acupuncture Massage College",
  },
  {
    label: "Atlantic Institute of Oriental Medicine",
    domain: "atom.edu",
    value: "Atlantic Institute of Oriental Medicine",
  },
  {
    label: "Barry University",
    domain: "barry.edu",
    value: "Barry University",
  },
  {
    label: "Broward College",
    domain: "broward.edu",
    value: "Broward College",
  },
  {
    label: "College of Central Florida",
    domain: "cf.edu",
    value: "College of Central Florida",
  },
  {
    label: "Chipola College",
    domain: "chipola.edu",
    value: "Chipola College",
  },
  {
    label: "Bethune-Cookman University",
    domain: "cookman.edu",
    value: "Bethune-Cookman University",
  },
  {
    label: "Dade Medical College",
    domain: "dadmedical.edu",
    value: "Dade Medical College",
  },
  {
    label: "Daytona State College",
    domain: "daytonastate.edu",
    value: "Daytona State College",
  },
  {
    label: "Eckerd College",
    domain: "eckerd.edu",
    value: "Eckerd College",
  },
  {
    label: "Edison State College",
    domain: "edison.edu",
    value: "Edison State College",
  },
  {
    label: "Embry-Riddle Aeronautical University",
    domain: "erau.edu",
    value: "Embry-Riddle Aeronautical University",
  },
  {
    label: "Everglades University",
    domain: "evergladesuniversity.edu",
    value: "Everglades University",
  },
  {
    label: "Florida Atlantic University",
    domain: "fau.edu",
    value: "Florida Atlantic University",
  },
  {
    label: "Florida Gulf Coast University",
    domain: "fgcu.edu",
    value: "Florida Gulf Coast University",
  },
  {
    label: "Florida Institute of Technology",
    domain: "fit.edu",
    value: "Florida Institute of Technology",
  },
  {
    label: "Florida International University",
    domain: "fiu.edu",
    value: "Florida International University",
  },
  {
    label: "Flagler College",
    domain: "flagler.edu",
    value: "Flagler College",
  },
  {
    label: "Florida Southern College",
    domain: "flsouthern.edu",
    value: "Florida Southern College",
  },
  {
    label: "Florida National University",
    domain: "fnu.edu",
    value: "Florida National University",
  },
  {
    label: "Florida State College at Jacksonville",
    domain: "fscj.edu",
    value: "Florida State College at Jacksonville",
  },
  {
    label: "Florida State University",
    domain: "fsu.edu",
    value: "Florida State University",
  },
  {
    label: "Gulf Coast State College",
    domain: "gulfcoast.edu",
    value: "Gulf Coast State College",
  },
  {
    label: "Hillsborough Community College",
    domain: "hccfl.edu",
    value: "Hillsborough Community College",
  },
  {
    label: "Jacksonville University",
    domain: "ju.edu",
    value: "Jacksonville University",
  },
  {
    label: "Keiser University",
    domain: "keiseruniversity.edu",
    value: "Keiser University",
  },
  {
    label: "Loraines Academy Incorporated",
    domain: "lorainesacademy.edu",
    value: "Loraines Academy Incorporated",
  },
  {
    label: "Lake Sumter State College",
    domain: "lssc.edu",
    value: "Lake Sumter State College",
  },
  {
    label: "Lynn University",
    domain: "lynn.edu",
    value: "Lynn University",
  },
  {
    label: "Millennia Atlantic University",
    domain: "maufl.edu",
    value: "Millennia Atlantic University",
  },
  {
    label: "Miami Dade College",
    domain: "mdc.edu",
    value: "Miami Dade College",
  },
  {
    label: "University of Miami",
    domain: "miami.edu",
    value: "University of Miami",
  },
  {
    label: "Miami International University of Art and Design",
    domain: "mymiu.edu",
    value: "Miami International University of Art and Design",
  },
  {
    label: "New College of Florida",
    domain: "ncf.edu",
    value: "New College of Florida",
  },
  {
    label: "North Florida Community College",
    domain: "nfcc.edu",
    value: "North Florida Community College",
  },
  {
    label: "Nova Southeastern University",
    domain: "nova.edu",
    value: "Nova Southeastern University",
  },
  {
    label: "National University of Health Sciences",
    domain: "nuhs.edu",
    value: "National University of Health Sciences",
  },
  {
    label: "Northwest Florida State College",
    domain: "nwfsc.edu",
    value: "Northwest Florida State College",
  },
  {
    label: "Palm Beach State College",
    domain: "palmbeachstate.edu",
    value: "Palm Beach State College",
  },
  {
    label: "Palm Beach Atlantic University",
    domain: "pba.edu",
    value: "Palm Beach Atlantic University",
  },
  {
    label: "Pensacola State College",
    domain: "pensacolastate.edu",
    value: "Pensacola State College",
  },
  {
    label: "Pasco-Hernando State College",
    domain: "phsc.edu",
    value: "Pasco-Hernando State College",
  },
  {
    label: "Ringling College of Art and Design",
    domain: "ringling.edu",
    value: "Ringling College of Art and Design",
  },
  {
    label: "Rollins College",
    domain: "rollins.edu",
    value: "Rollins College",
  },
  {
    label: "Saint Leo University",
    domain: "saintleo.edu",
    value: "Saint Leo University",
  },
  {
    label: "State College of Florida",
    domain: "scf.edu",
    value: "State College of Florida",
  },
  {
    label: "Seminole State College of Florida",
    domain: "seminolestate.edu",
    value: "Seminole State College of Florida",
  },
  {
    label: "Santa Fe College",
    domain: "sfcollege.edu",
    value: "Santa Fe College",
  },
  {
    label: "South Florida State College",
    domain: "southflorida.edu",
    value: "South Florida State College",
  },
  {
    label: "Space Coast Health Institute",
    domain: "spacecoast.edu",
    value: "Space Coast Health Institute",
  },
  {
    label: "Saint Petersburg College",
    domain: "spcollege.edu",
    value: "Saint Petersburg College",
  },
  {
    label: "Stetson University",
    domain: "stetson.edu",
    value: "Stetson University",
  },
  {
    label: "Saint Thomas University",
    domain: "stu.edu",
    value: "Saint Thomas University",
  },
  {
    label: "Saint Vincent de Paul Regional Seminary",
    domain: "svdp.edu",
    value: "Saint Vincent de Paul Regional Seminary",
  },
  {
    label: "Traviss Career Center",
    domain: "traviss.edu",
    value: "Traviss Career Center",
  },
  {
    label: "University of Central Florida",
    domain: "ucf.edu",
    value: "University of Central Florida",
  },
  {
    label: "University of Florida",
    domain: "ufl.edu",
    value: "University of Florida",
  },
  {
    label: "University of North Florida",
    domain: "unf.edu",
    value: "University of North Florida",
  },
  {
    label: "University of South Florida",
    domain: "usf.edu",
    value: "University of South Florida",
  },
  {
    label: "University of South Florida Sarasota-Manatee",
    domain: "usfsm.edu",
    value: "University of South Florida Sarasota-Manatee",
  },
  {
    label: "University of South Florida Saint Petersburg",
    domain: "usfsp.edu",
    value: "University of South Florida Saint Petersburg",
  },
  {
    label: "University of Tampa",
    domain: "ut.edu",
    value: "University of Tampa",
  },
  {
    label: "University of West Florida",
    domain: "uwf.edu",
    value: "University of West Florida",
  },
  {
    label: "Valencia College",
    domain: "valenciacollege.edu",
    value: "Valencia College",
  },
  {
    label: "Warner University",
    domain: "warner.edu",
    value: "Warner University",
  },
  {
    label: "Webber International University",
    domain: "webber.edu",
    value: "Webber International University",
  },
  {
    label: "Agnes Scott College",
    domain: "agnesscott.edu",
    value: "Agnes Scott College",
  },
  {
    label: "Albany Technical College",
    domain: "albanytech.edu",
    value: "Albany Technical College",
  },
  {
    label: "Armstrong Atlantic State University",
    domain: "armstrong.edu",
    value: "Armstrong Atlantic State University",
  },
  {
    label: "Atlanta Technical College",
    domain: "atlantatech.edu",
    value: "Atlanta Technical College",
  },
  {
    label: "Atlanta Metropolitan State College",
    domain: "atlm.edu",
    value: "Atlanta Metropolitan State College",
  },
  { label: "Berry College", domain: "berry.edu", value: "Berry College" },
  {
    label: "Carver College",
    domain: "carver.edu",
    value: "Carver College",
  },
  {
    label: "Child Care Education Institute",
    domain: "cceionline.edu",
    value: "Child Care Education Institute",
  },
  {
    label: "Central Georgia Technical College",
    domain: "centralgatech.edu",
    value: "Central Georgia Technical College",
  },
  {
    label: "Clayton State University",
    domain: "clayton.edu",
    value: "Clayton State University",
  },
  {
    label: "Columbus State University",
    domain: "columbusstate.edu",
    value: "Columbus State University",
  },
  {
    label: "Columbus Technical College",
    domain: "columbustech.edu",
    value: "Columbus Technical College",
  },
  {
    label: "Covenant College",
    domain: "covenant.edu",
    value: "Covenant College",
  },
  {
    label: "Dalton State",
    domain: "daltonstate.edu",
    value: "Dalton State",
  },
  {
    label: "Darton State College",
    domain: "darton.edu",
    value: "Darton State College",
  },
  {
    label: "East Georgia State College",
    domain: "ega.edu",
    value: "East Georgia State College",
  },
  {
    label: "Emory University",
    domain: "emory.edu",
    value: "Emory University",
  },
  {
    label: "Georgia Institute of Technology",
    domain: "gatech.edu",
    value: "Georgia Institute of Technology",
  },
  {
    label: "Georgia College",
    domain: "gcsu.edu",
    value: "Georgia College",
  },
  {
    label: "Georgia Southern University",
    domain: "georgiasouthern.edu",
    value: "Georgia Southern University",
  },
  {
    label: "Georgia Gwinnett College",
    domain: "ggc.edu",
    value: "Georgia Gwinnett College",
  },
  {
    label: "Georgia Perimeter College",
    domain: "gpc.edu",
    value: "Georgia Perimeter College",
  },
  {
    label: "Georgia Regents University Augusta",
    domain: "gru.edu",
    value: "Georgia Regents University Augusta",
  },
  {
    label: "Georgia State University",
    domain: "gsu.edu",
    value: "Georgia State University",
  },
  {
    label: "Georgia South Western State University",
    domain: "gsw.edu",
    value: "Georgia South Western State University",
  },
  {
    label: "Interactive College of Technology",
    domain: "ict.edu",
    value: "Interactive College of Technology",
  },
  {
    label: "Atlanta's John Marshall Law School",
    domain: "johnmarshall.edu",
    value: "Atlanta's John Marshall Law School",
  },
  {
    label: "Kennesaw State University",
    domain: "kennesaw.edu",
    value: "Kennesaw State University",
  },
  {
    label: "Lanier Technical College",
    domain: "laniertech.edu",
    value: "Lanier Technical College",
  },
  {
    label: "Life University",
    domain: "life.edu",
    value: "Life University",
  },
  {
    label: "Mercer University",
    domain: "mercer.edu",
    value: "Mercer University",
  },
  {
    label: "Middle Georgia State College",
    domain: "mga.edu",
    value: "Middle Georgia State College",
  },
  {
    label: "Morehouse College",
    domain: "morehouse.edu",
    value: "Morehouse College",
  },
  {
    label: "Morehouse School of Medicine",
    domain: "msm.edu",
    value: "Morehouse School of Medicine",
  },
  {
    label: "North Georgia Technical College",
    domain: "northgatech.edu",
    value: "North Georgia Technical College",
  },
  {
    label: "Oglethorpe University",
    domain: "oglethorpe.edu",
    value: "Oglethorpe University",
  },
  {
    label: "Okefenokee Technical College",
    domain: "okefenokeetech.edu",
    value: "Okefenokee Technical College",
  },
  { label: "Paine College", domain: "paine.edu", value: "Paine College" },
  {
    label: "Pfeiffer University",
    domain: "pfeiffer.edu",
    value: "Pfeiffer University",
  },
  {
    label: "Piedmont College",
    domain: "piedmont.edu",
    value: "Piedmont College",
  },
  {
    label: "Reinhardt University",
    domain: "reinhardt.edu",
    value: "Reinhardt University",
  },
  {
    label: "Savannah State University",
    domain: "savannahstate.edu",
    value: "Savannah State University",
  },
  {
    label: "Savannah College of Art and Design",
    domain: "scad.edu",
    value: "Savannah College of Art and Design",
  },
  {
    label: "Shorter University",
    domain: "shorter.edu",
    value: "Shorter University",
  },
  {
    label: "Southeastern Technical College",
    domain: "southeasterntech.edu",
    value: "Southeastern Technical College",
  },
  {
    label: "Spelman College",
    domain: "spelman.edu",
    value: "Spelman College",
  },
  {
    label: "Southern Polytechnic State University",
    domain: "spsu.edu",
    value: "Southern Polytechnic State University",
  },
  {
    label: "Thomas University",
    domain: "thomasu.edu",
    value: "Thomas University",
  },
  {
    label: "University of Georgia",
    domain: "uga.edu",
    value: "University of Georgia",
  },
  {
    label: "University of North Georgia",
    domain: "ung.edu",
    value: "University of North Georgia",
  },
  {
    label: "University System of Georgia",
    domain: "usg.edu",
    value: "University System of Georgia",
  },
  {
    label: "Valdosta State University",
    domain: "valdosta.edu",
    value: "Valdosta State University",
  },
  {
    label: "University of West Georgia",
    domain: "westga.edu",
    value: "University of West Georgia",
  },
  {
    label: "Wiregrass Georgia Technical College",
    domain: "wiregrass.edu",
    value: "Wiregrass Georgia Technical College",
  },
  {
    label: "Brigham Young University Hawaii",
    domain: "byuh.edu",
    value: "Brigham Young University Hawaii",
  },
  {
    label: "Chaminade University",
    domain: "chaminade.edu",
    value: "Chaminade University",
  },
  {
    label: "University of Hawaii at Manoa",
    domain: "hawaii.edu",
    value: "University of Hawaii at Manoa",
  },
  {
    label: "Mid-Pacific Institute",
    domain: "midpac.edu",
    value: "Mid-Pacific Institute",
  },
  {
    label: "AIB College of Business",
    domain: "aib.edu",
    value: "AIB College of Business",
  },
  {
    label: "Central College",
    domain: "central.edu",
    value: "Central College",
  },
  { label: "Coe College", domain: "coe.edu", value: "Coe College" },
  {
    label: "Cornell College",
    domain: "cornellcollege.edu",
    value: "Cornell College",
  },
  {
    label: "Des Moines Area Community College",
    domain: "dmacc.edu",
    value: "Des Moines Area Community College",
  },
  { label: "Dordt College", domain: "dordt.edu", value: "Dordt College" },
  {
    label: "Drake University",
    domain: "drake.edu",
    value: "Drake University",
  },
  {
    label: "Divine Word College",
    domain: "dwci.edu",
    value: "Divine Word College",
  },
  {
    label: "Eastern Iowa Community Colleges",
    domain: "eicc.edu",
    value: "Eastern Iowa Community Colleges",
  },
  {
    label: "Grinnell College",
    domain: "grinnell.edu",
    value: "Grinnell College",
  },
  {
    label: "Hawkeye Community College",
    domain: "hawkeyecollege.edu",
    value: "Hawkeye Community College",
  },
  {
    label: "Iowa State University",
    domain: "iastate.edu",
    value: "Iowa State University",
  },
  {
    label: "Iowa Central Community College",
    domain: "iowacentral.edu",
    value: "Iowa Central Community College",
  },
  {
    label: "Iowa Wesleyan College",
    domain: "iwc.edu",
    value: "Iowa Wesleyan College",
  },
  {
    label: "Iowa Western Community College",
    domain: "iwcc.edu",
    value: "Iowa Western Community College",
  },
  {
    label: "Kaplan University",
    domain: "kaplanuniversity.edu",
    value: "Kaplan University",
  },
  {
    label: "Kirkwood Community College",
    domain: "kirkwood.edu",
    value: "Kirkwood Community College",
  },
  {
    label: "La' James College",
    domain: "lajames.edu",
    value: "La' James College",
  },
  { label: "Loras College", domain: "loras.edu", value: "Loras College" },
  {
    label: "Luther College",
    domain: "luther.edu",
    value: "Luther College",
  },
  {
    label: "Maharishi University of Management",
    domain: "mum.edu",
    value: "Maharishi University of Management",
  },
  {
    label: "Northeast Iowa Community College",
    domain: "nicc.edu",
    value: "Northeast Iowa Community College",
  },
  {
    label: "Palmer College of Chiropractic",
    domain: "palmer.edu",
    value: "Palmer College of Chiropractic",
  },
  {
    label: "Saint Ambrose University",
    domain: "sau.edu",
    value: "Saint Ambrose University",
  },
  {
    label: "Simpson College",
    domain: "simpson.edu",
    value: "Simpson College",
  },
  {
    label: "Southwestern Community College",
    domain: "swcciowa.edu",
    value: "Southwestern Community College",
  },
  {
    label: "Univeristy of Iowa",
    domain: "uiowa.edu",
    value: "Univeristy of Iowa",
  },
  {
    label: "Upper Iowa University",
    domain: "uiu.edu",
    value: "Upper Iowa University",
  },
  {
    label: "University of Northern Iowa",
    domain: "uni.edu",
    value: "University of Northern Iowa",
  },
  {
    label: "Wartburg College",
    domain: "wartburg.edu",
    value: "Wartburg College",
  },
  {
    label: "Waldorf College",
    domain: "waldorf.edu",
    value: "Waldorf College",
  },
  {
    label: "Boise State University",
    domain: "boisestate.edu",
    value: "Boise State University",
  },
  {
    label: "Brigham Young University Idaho",
    domain: "byui.edu",
    value: "Brigham Young University Idaho",
  },
  {
    label: "The College of Idaho",
    domain: "collegeofidaho.edu",
    value: "The College of Idaho",
  },
  {
    label: "College of Southern Idaho",
    domain: "csi.edu",
    value: "College of Southern Idaho",
  },
  {
    label: "Idaho State University",
    domain: "isu.edu",
    value: "Idaho State University",
  },
  {
    label: "University of Idaho",
    domain: "uidaho.edu",
    value: "University of Idaho",
  },
  {
    label: "Augustana College",
    domain: "augustana.edu",
    value: "Augustana College",
  },
  {
    label: "Aurora University",
    domain: "aurora.edu",
    value: "Aurora University",
  },
  {
    label: "Benedictine University",
    domain: "ben.edu",
    value: "Benedictine University",
  },
  {
    label: "Bradley University",
    domain: "bradley.edu",
    value: "Bradley University",
  },
  {
    label: "City Colleges of Chicago",
    domain: "ccc.edu",
    value: "City Colleges of Chicago",
  },
  {
    label: "The University of Chicago Booth School of Business",
    domain: "chicagobooth.edu",
    value: "The University of Chicago Booth School of Business",
  },
  {
    label: "College of Lake County",
    domain: "clcillinois.edu",
    value: "College of Lake County",
  },
  {
    label: "College of DuPage",
    domain: "cod.edu",
    value: "College of DuPage",
  },
  {
    label: "Columbia College Chicago",
    domain: "colum.edu",
    value: "Columbia College Chicago",
  },
  {
    label: "Danville Area Community College",
    domain: "dacc.edu",
    value: "Danville Area Community College",
  },
  {
    label: "DePaul University",
    domain: "depaul.edu",
    value: "DePaul University",
  },
  {
    label: "Dominican University",
    domain: "dom.edu",
    value: "Dominican University",
  },
  {
    label: "Eastern Illinois University",
    domain: "eiu.edu",
    value: "Eastern Illinois University",
  },
  {
    label: "Elmhurst College",
    domain: "elmhurst.edu",
    value: "Elmhurst College",
  },
  {
    label: "Governors State",
    domain: "govst.edu",
    value: "Governors State",
  },
  {
    label: "Greenville College",
    domain: "greenville.edu",
    value: "Greenville College",
  },
  {
    label: "The Hadley School for the Blind",
    domain: "hadley.edu",
    value: "The Hadley School for the Blind",
  },
  {
    label: "Harper College",
    domain: "harpercollege.edu",
    value: "Harper College",
  },
  {
    label: "Heartland Community College",
    domain: "heartland.edu",
    value: "Heartland Community College",
  },
  {
    label: "Illinois Central College",
    domain: "icc.edu",
    value: "Illinois Central College",
  },
  {
    label: "Illinois College of Optometry",
    domain: "ico.edu",
    value: "Illinois College of Optometry",
  },
  {
    label: "Illinois Institute of Technology",
    domain: "iit.edu",
    value: "Illinois Institute of Technology",
  },
  {
    label: "University of Illinois at Urbana-Champaign",
    domain: "illinois.edu",
    value: "University of Illinois at Urbana-Champaign",
  },
  {
    label: "Illinois State University",
    domain: "illinoisstate.edu",
    value: "Illinois State University",
  },
  {
    label: "Illinois Mathematics and Science Academy",
    domain: "imsa.edu",
    value: "Illinois Mathematics and Science Academy",
  },
  {
    label: "Illinois Valley Community College",
    domain: "ivcc.edu",
    value: "Illinois Valley Community College",
  },
  {
    label: "Illinois Wesleyan University",
    domain: "iwu.edu",
    value: "Illinois Wesleyan University",
  },
  {
    label: "John A. Logan College",
    domain: "jalc.edu",
    value: "John A. Logan College",
  },
  {
    label: "Joliet Junior College",
    domain: "jjc.edu",
    value: "Joliet Junior College",
  },
  {
    label: "The John Marshall Law School",
    domain: "jmls.edu",
    value: "The John Marshall Law School",
  },
  {
    label: "Judson University",
    domain: "judsonu.edu",
    value: "Judson University",
  },
  {
    label: "Kaskaskia College",
    domain: "kaskaskia.edu",
    value: "Kaskaskia College",
  },
  {
    label: "Kankakee Community College",
    domain: "kcc.edu",
    value: "Kankakee Community College",
  },
  {
    label: "IIT Chicago-Kent College of Law",
    domain: "iit.edu",
    value: "IIT Chicago-Kent College of Law",
  },
  {
    label: "Kishwaukee College",
    domain: "kishwaukeecollege.edu",
    value: "Kishwaukee College",
  },
  { label: "Knox College", domain: "knox.edu", value: "Knox College" },
  {
    label: "Lake Forest College",
    domain: "lakeforest.edu",
    value: "Lake Forest College",
  },
  {
    label: "Lakeview College of Nursing",
    domain: "lakeviewcol.edu",
    value: "Lakeview College of Nursing",
  },
  {
    label: "Lewis and Clark Community College",
    domain: "lc.edu",
    value: "Lewis and Clark Community College",
  },
  {
    label: "Lewis University",
    domain: "lewisu.edu",
    value: "Lewis University",
  },
  {
    label: "Lincoln Land Community College",
    domain: "llcc.edu",
    value: "Lincoln Land Community College",
  },
  {
    label: "Loyola University Chicago",
    domain: "luc.edu",
    value: "Loyola University Chicago",
  },
  {
    label: "Midwestern Career College",
    domain: "mccollege.edu",
    value: "Midwestern Career College",
  },
  {
    label: "McHenry County College",
    domain: "mchenry.edu",
    value: "McHenry County College",
  },
  {
    label: "McKendree University",
    domain: "mckendree.edu",
    value: "McKendree University",
  },
  {
    label: "Midwestern University",
    domain: "midwestern.edu",
    value: "Midwestern University",
  },
  {
    label: "Monmouth College",
    domain: "monmouthcollege.edu",
    value: "Monmouth College",
  },
  {
    label: "Moraine Valley Community College",
    domain: "morainevalley.edu",
    value: "Moraine Valley Community College",
  },
  {
    label: "Morton College",
    domain: "morton.edu",
    value: "Morton College",
  },
  {
    label: "Northeastern Illinois University",
    domain: "neiu.edu",
    value: "Northeastern Illinois University",
  },
  {
    label: "Northern Illinois University",
    domain: "niu.edu",
    value: "Northern Illinois University",
  },
  {
    label: "National Louis University",
    domain: "nl.edu",
    value: "National Louis University",
  },
  {
    label: "North Park University",
    domain: "northpark.edu",
    value: "North Park University",
  },
  {
    label: "Northwestern University",
    domain: "northwestern.edu",
    value: "Northwestern University",
  },
  {
    label: "Oakton Community College",
    domain: "oakton.edu",
    value: "Oakton Community College",
  },
  {
    label: "Olivet Nazarene University",
    domain: "olivet.edu",
    value: "Olivet Nazarene University",
  },
  {
    label: "Chicago ORT Technical Institute",
    domain: "ortchicagotech.edu",
    value: "Chicago ORT Technical Institute",
  },
  {
    label: "Parkland College",
    domain: "parkland.edu",
    value: "Parkland College",
  },
  {
    label: "Prairie State College",
    domain: "prairiestate.edu",
    value: "Prairie State College",
  },
  {
    label: "Principia College",
    domain: "principiacollege.edu",
    value: "Principia College",
  },
  {
    label: "Resurrection University",
    domain: "resu.edu",
    value: "Resurrection University",
  },
  {
    label: "Robert Morris University Illinois",
    domain: "robertmorris.edu",
    value: "Robert Morris University Illinois",
  },
  {
    label: "Rockford University",
    domain: "rockford.edu",
    value: "Rockford University",
  },
  {
    label: "Rock Valley College",
    domain: "rockvalleycollege.edu",
    value: "Rock Valley College",
  },
  {
    label: "Roosevelt University",
    domain: "roosevelt.edu",
    value: "Roosevelt University",
  },
  {
    label: "Rosalind Franklin University",
    domain: "rosalindfranklin.edu",
    value: "Rosalind Franklin University",
  },
  {
    label: "Saint Anthony College of Nursing",
    domain: "sacn.edu",
    value: "Saint Anthony College of Nursing",
  },
  {
    label: "School of the Art Institute of Chicago",
    domain: "saic.edu",
    value: "School of the Art Institute of Chicago",
  },
  {
    label: "Saint Francis Medical Center College of Nursing",
    domain: "sfmccon.edu",
    value: "Saint Francis Medical Center College of Nursing",
  },
  {
    label: "Shawnee Community College",
    domain: "shawneecc.edu",
    value: "Shawnee Community College",
  },
  {
    label: "Southeastern Illinois College",
    domain: "sic.edu",
    value: "Southeastern Illinois College",
  },
  {
    label: "Southern Illinois University",
    domain: "siu.edu",
    value: "Southern Illinois University",
  },
  {
    label: "Southern Illinois University Edwardsville",
    domain: "siue.edu",
    value: "Southern Illinois University Edwardsville",
  },
  {
    label: "Spoon River College",
    domain: "src.edu",
    value: "Spoon River College",
  },
  {
    label: "South Suburban College",
    domain: "ssc.edu",
    value: "South Suburban College",
  },
  {
    label: "University of Saint Francis",
    domain: "stfrancis.edu",
    value: "University of Saint Francis",
  },
  {
    label: "Southwestern Illinois College",
    domain: "swic.edu",
    value: "Southwestern Illinois College",
  },
  {
    label: "Saint Xavier University",
    domain: "scu.edu",
    value: "Saint Xavier University",
  },
  {
    label: "Trinity Christian College",
    domain: "trnty.edu",
    value: "Trinity Christian College",
  },
  {
    label: "University of Chicago",
    domain: "uchicago.edu",
    value: "University of Chicago",
  },
  {
    label: "University of Illinois at Chicago",
    domain: "uic.edu",
    value: "University of Illinois at Chicago",
  },
  {
    label: "University of Illinois",
    domain: "uillinois.edu",
    value: "University of Illinois",
  },
  {
    label: "University of Illinois Springfield",
    domain: "uis.edu",
    value: "University of Illinois Springfield",
  },
  {
    label: "Waubonsee Community College",
    domain: "waubonsee.edu",
    value: "Waubonsee Community College",
  },
  {
    label: "Wheaton College",
    domain: "wheaton.edu",
    value: "Wheaton College",
  },
  {
    label: "Western Illinois University",
    domain: "wiu.edu",
    value: "Western Illinois University",
  },
  {
    label: "Anabaptist Mennonite Biblical Seminary",
    domain: "ambs.edu",
    value: "Anabaptist Mennonite Biblical Seminary",
  },
  {
    label: "Anderson University",
    domain: "anderson.edu",
    value: "Anderson University",
  },
  {
    label: "Bethel College Indiana",
    domain: "bethelcollege.edu",
    value: "Bethel College Indiana",
  },
  {
    label: "Brown Mackie College",
    domain: "brownmackie.edu",
    value: "Brown Mackie College",
  },
  {
    label: "Ball State University",
    domain: "bsu.edu",
    value: "Ball State University",
  },
  {
    label: "Butler University",
    domain: "butler.edu",
    value: "Butler University",
  },
  {
    label: "DePauw University",
    domain: "depauw.edu",
    value: "DePauw University",
  },
  {
    label: "Earlham College",
    domain: "earlham.edu",
    value: "Earlham College",
  },
  {
    label: "University of Evansville",
    domain: "evansville.edu",
    value: "University of Evansville",
  },
  {
    label: "Goshen College",
    domain: "goshen.edu",
    value: "Goshen College",
  },
  {
    label: "Grace College and Seminary",
    domain: "grace.edu",
    value: "Grace College and Seminary",
  },
  {
    label: "Hanover College",
    domain: "hanover.edu",
    value: "Hanover College",
  },
  {
    label: "Harrison College",
    domain: "harrison.edu",
    value: "Harrison College",
  },
  {
    label: "Holy Cross College",
    domain: "hcc-nd.edu",
    value: "Holy Cross College",
  },
  {
    label: "Indiana State University",
    domain: "indstate.edu",
    value: "Indiana State University",
  },
  {
    label: "Indiana Wesleyan University",
    domain: "indwes.edu",
    value: "Indiana Wesleyan University",
  },
  {
    label: "Indiana University - Purdue University Fort Wayne",
    domain: "ipfw.edu",
    value: "Indiana University - Purdue University Fort Wayne",
  },
  {
    label: "Indiana University",
    domain: "iu.edu",
    value: "Indiana University",
  },
  {
    label: "Indiana University Bloomington",
    domain: "iub.edu",
    value: "Indiana University Bloomington",
  },
  {
    label: "Indiana University East",
    domain: "iue.edu",
    value: "Indiana University East",
  },
  {
    label: "Indiana University Northwest",
    domain: "iun.edu",
    value: "Indiana University Northwest",
  },
  {
    label: "Indiana University - Purdue University Indianapolis",
    domain: "iupui.edu",
    value: "Indiana University - Purdue University Indianapolis",
  },
  {
    label: "Indiana University Southeast",
    domain: "ius.edu",
    value: "Indiana University Southeast",
  },
  {
    label: "Indiana University South Bend",
    domain: "iusb.edu",
    value: "Indiana University South Bend",
  },
  {
    label: "Ivy Tech Community College",
    domain: "ivytech.edu",
    value: "Ivy Tech Community College",
  },
  {
    label: "Manchester University",
    domain: "manchester.edu",
    value: "Manchester University",
  },
  {
    label: "Marian University Indianapolis",
    domain: "marian.edu",
    value: "Marian University Indianapolis",
  },
  {
    label: "University of Notre Dame",
    domain: "nd.edu",
    value: "University of Notre Dame",
  },
  {
    label: "Oakland City University",
    domain: "oak.edu",
    value: "Oakland City University",
  },
  {
    label: "Purdue University North Central",
    domain: "pnc.edu",
    value: "Purdue University North Central",
  },
  {
    label: "Purdue University",
    domain: "purdue.edu",
    value: "Purdue University",
  },
  {
    label: "Purdue University Calumet",
    domain: "purduecal.edu",
    value: "Purdue University Calumet",
  },
  {
    label: "Rose-Hulman Institute of Technology",
    domain: "rose-hulman.edu",
    value: "Rose-Hulman Institute of Technology",
  },
  {
    label: "Trine University",
    domain: "trine.edu",
    value: "Trine University",
  },
  {
    label: "University of Indianapolis",
    domain: "uindy.edu",
    value: "University of Indianapolis",
  },
  {
    label: "University of Southern Indiana",
    domain: "usi.edu",
    value: "University of Southern Indiana",
  },
  {
    label: "Valparaiso University",
    domain: "valpo.edu",
    value: "Valparaiso University",
  },
  {
    label: "Wabash College",
    domain: "wabash.edu",
    value: "Wabash College",
  },
  {
    label: "Benedictine College",
    domain: "benedictine.edu",
    value: "Benedictine College",
  },
  {
    label: "Butler Community College",
    domain: "butlercc.edu",
    value: "Butler Community College",
  },
  {
    label: "Emporia State University",
    domain: "emporia.edu",
    value: "Emporia State University",
  },
  {
    label: "Fort Hays State University",
    domain: "fhsu.edu",
    value: "Fort Hays State University",
  },
  {
    label: "Flint Hills Technical College",
    domain: "fhtc.edu",
    value: "Flint Hills Technical College",
  },
  {
    label: "Fort Scott Community College",
    domain: "fortscott.edu",
    value: "Fort Scott Community College",
  },
  {
    label: "Garden City Community College",
    domain: "gcccks.edu",
    value: "Garden City Community College",
  },
  {
    label: "Hesston College",
    domain: "hesston.edu",
    value: "Hesston College",
  },
  {
    label: "Highland Community College",
    domain: "highlandcc.edu",
    value: "Highland Community College",
  },
  {
    label: "Johnson County Community College",
    domain: "jccc.edu",
    value: "Johnson County Community College",
  },
  {
    label: "Kansas City Kansas Community College",
    domain: "kckcc.edu",
    value: "Kansas City Kansas Community College",
  },
  {
    label: "Kansas State University",
    domain: "k-state.edu",
    value: "Kansas State University",
  },
  {
    label: "The University of Kansas",
    domain: "ku.edu",
    value: "The University of Kansas",
  },
  {
    label: "McPherson College",
    domain: "mcpherson.edu",
    value: "McPherson College",
  },
  {
    label: "Neosho County Community College",
    domain: "neosho.edu",
    value: "Neosho County Community College",
  },
  {
    label: "Newman University",
    domain: "newmanu.edu",
    value: "Newman University",
  },
  {
    label: "Pittsburg State University",
    domain: "pittstate.edu",
    value: "Pittsburg State University",
  },
  {
    label: "Saint Paul School of Theology and Ministry",
    domain: "spst.edu",
    value: "Saint Paul School of Theology and Ministry",
  },
  {
    label: "Sterling College",
    domain: "sterling.edu",
    value: "Sterling College",
  },
  {
    label: "University of Saint Mary",
    domain: "stmary.edu",
    value: "University of Saint Mary",
  },
  {
    label: "Washburn University",
    domain: "washburn.edu",
    value: "Washburn University",
  },
  {
    label: "Washburn University School of Law",
    domain: "washburnlaw.edu",
    value: "Washburn University School of Law",
  },
  {
    label: "Wichita State University",
    domain: "wichita.edu",
    value: "Wichita State University",
  },
  {
    label: "Asbury University",
    domain: "asbury.edu",
    value: "Asbury University",
  },
  {
    label: "Bellarmine University",
    domain: "bellarmine.edu",
    value: "Bellarmine University",
  },
  { label: "Berea College", domain: "berea.edu", value: "Berea College" },
  {
    label: "Centre College",
    domain: "centre.edu",
    value: "Centre College",
  },
  {
    label: "Eastern Kentucky University",
    domain: "eku.edu",
    value: "Eastern Kentucky University",
  },
  {
    label: "Frontier Nursing University",
    domain: "frontier.edu",
    value: "Frontier Nursing University",
  },
  {
    label: "Georgetown College",
    domain: "georgetowncollege.edu",
    value: "Georgetown College",
  },
  {
    label: "Kentucky Community and Technical College System",
    domain: "kctcs.edu",
    value: "Kentucky Community and Technical College System",
  },
  {
    label: "Kentucky Christian University",
    domain: "kcu.edu",
    value: "Kentucky Christian University",
  },
  {
    label: "Kentucky State University",
    domain: "kysu.edu",
    value: "Kentucky State University",
  },
  {
    label: "Lindsey Wilson College",
    domain: "lindsey.edu",
    value: "Lindsey Wilson College",
  },
  {
    label: "The University of Louisville",
    domain: "louisville.edu",
    value: "The University of Louisville",
  },
  {
    label: "Midway College",
    domain: "midway.edu",
    value: "Midway College",
  },
  {
    label: "Morehead State University",
    domain: "moreheadstate.edu",
    value: "Morehead State University",
  },
  {
    label: "Murray State University",
    domain: "murraystate.edu",
    value: "Murray State University",
  },
  {
    label: "Northern Kentucky University",
    domain: "nku.edu",
    value: "Northern Kentucky University",
  },
  {
    label: "The Southern Baptist Theological Seminary",
    domain: "sbts.edu",
    value: "The Southern Baptist Theological Seminary",
  },
  {
    label: "Saint Catharine College",
    domain: "sccky.edu",
    value: "Saint Catharine College",
  },
  {
    label: "Sullivan University",
    domain: "sullivan.edu",
    value: "Sullivan University",
  },
  {
    label: "University of the Cumberlands",
    domain: "ucumberlands.edu",
    value: "University of the Cumberlands",
  },
  {
    label: "Western Kentucky University",
    domain: "wku.edu",
    value: "Western Kentucky University",
  },
  {
    label: "Bossier Parish Community College",
    domain: "bpcc.edu",
    value: "Bossier Parish Community College",
  },
  {
    label: "Centenary College of Louisiana",
    domain: "centenary.edu",
    value: "Centenary College of Louisiana",
  },
  {
    label: "Louisiana Tech University",
    domain: "latech.edu",
    value: "Louisiana Tech University",
  },
  {
    label: "Louisiana Culinary Institute",
    domain: "lci.edu",
    value: "Louisiana Culinary Institute",
  },
  {
    label: "University of Louisiana Lafayette",
    domain: "louisiana.edu",
    value: "University of Louisiana Lafayette",
  },
  {
    label: "Loyola University New Orleans",
    domain: "loyno.edu",
    value: "Loyola University New Orleans",
  },
  {
    label: "Louisiana State University",
    domain: "lsu.edu",
    value: "Louisiana State University",
  },
  {
    label: "Louisiana State University - Eunice",
    domain: "lsue.edu",
    value: "Louisiana State University - Eunice",
  },
  {
    label: "LSU Health Sciences Center - New Orleans",
    domain: "lsuhsc.edu",
    value: "LSU Health Sciences Center - New Orleans",
  },
  {
    label: "Louisiana State University System",
    domain: "lsusystem.edu",
    value: "Louisiana State University System",
  },
  {
    label: "Nicholls State University",
    domain: "nicholls.edu",
    value: "Nicholls State University",
  },
  {
    label: "New Orleans Baptist Theological Seminary",
    domain: "nobts.edu",
    value: "New Orleans Baptist Theological Seminary",
  },
  {
    label: "Nunez Community College",
    domain: "nunez.edu",
    value: "Nunez Community College",
  },
  {
    label: "Southeastern Louisiana University",
    domain: "southeastern.edu",
    value: "Southeastern Louisiana University",
  },
  {
    label: "Southern University",
    domain: "subr.edu",
    value: "Southern University",
  },
  {
    label: "Tulane University",
    domain: "tulane.edu",
    value: "Tulane University",
  },
  {
    label: "University of Louisiana at Monroe",
    domain: "ulm.edu",
    value: "University of Louisiana at Monroe",
  },
  {
    label: "University of New Orleans",
    domain: "uno.edu",
    value: "University of New Orleans",
  },
  {
    label: "Xavier University of Louisiana",
    domain: "xula.edu",
    value: "Xavier University of Louisiana",
  },
  {
    label: "Amherst College",
    domain: "amherst.edu",
    value: "Amherst College",
  },
  {
    label: "Assumption College",
    domain: "assumption.edu",
    value: "Assumption College",
  },
  {
    label: "Babson College",
    domain: "babson.edu",
    value: "Babson College",
  },
  { label: "Boston College", domain: "bc.edu", value: "Boston College" },
  {
    label: "Bentley University",
    domain: "bentley.edu",
    value: "Bentley University",
  },
  {
    label: "Berklee College of Music",
    domain: "berklee.edu",
    value: "Berklee College of Music",
  },
  {
    label: "Berkshire Community College",
    domain: "berkshirecc.edu",
    value: "Berkshire Community College",
  },
  {
    label: "The Boston Conservatory",
    domain: "bostonconservatory.edu",
    value: "The Boston Conservatory",
  },
  {
    label: "Brandeis University",
    domain: "brandeis.edu",
    value: "Brandeis University",
  },
  {
    label: "Bridgewater State University",
    domain: "bridgew.edu",
    value: "Bridgewater State University",
  },
  {
    label: "Boston University",
    domain: "bu.edu",
    value: "Boston University",
  },
  {
    label: "Cambridge College",
    domain: "cambridgecollege.edu",
    value: "Cambridge College",
  },
  {
    label: "Clark University",
    domain: "clarku.edu",
    value: "Clark University",
  },
  { label: "Curry College", domain: "curry.edu", value: "Curry College" },
  { label: "Elms College", domain: "elms.edu", value: "Elms College" },
  {
    label: "Emerson College",
    domain: "emerson.edu",
    value: "Emerson College",
  },
  {
    label: "Emmanuel College",
    domain: "emmanuel.edu",
    value: "Emmanuel College",
  },
  {
    label: "Endicott College",
    domain: "endicott.edu",
    value: "Endicott College",
  },
  {
    label: "Fitchburg State University",
    domain: "fitchburgstate.edu",
    value: "Fitchburg State University",
  },
  {
    label: "Framingham State University",
    domain: "framingham.edu",
    value: "Framingham State University",
  },
  {
    label: "Gordon Conwell Theological Seminary",
    domain: "gordonconwell.edu",
    value: "Gordon Conwell Theological Seminary",
  },
  {
    label: "Gordon College",
    domain: "gordon.edu",
    value: "Gordon College",
  },
  {
    label: "Hampshire College",
    domain: "hampshire.edu",
    value: "Hampshire College",
  },
  {
    label: "Harvard University",
    domain: "harvard.edu",
    value: "Harvard University",
  },
  {
    label: "Harvard Business School",
    domain: "hbs.edu",
    value: "Harvard Business School",
  },
  {
    label: "College of the Holy Cross",
    domain: "holycross.edu",
    value: "College of the Holy Cross",
  },
  {
    label: "Lesley University",
    domain: "lesley.edu",
    value: "Lesley University",
  },
  {
    label: "Massachusetts Maritime Academy",
    domain: "maritime.edu",
    value: "Massachusetts Maritime Academy",
  },
  {
    label: "Massachusetts Department of Higher Education",
    domain: "mass.edu",
    value: "Massachusetts Department of Higher Education",
  },
  {
    label: "Massachusetts College of Art and Design",
    domain: "massart.edu",
    value: "Massachusetts College of Art and Design",
  },
  {
    label: "MassBay Community College",
    domain: "massbay.edu",
    value: "MassBay Community College",
  },
  {
    label: "Marine Biological Laboratory",
    domain: "mbl.edu",
    value: "Marine Biological Laboratory",
  },
  {
    label: "Merrimack Education Center",
    domain: "mec.edu",
    value: "Merrimack Education Center",
  },
  {
    label: "Merrimack College",
    domain: "merrimack.edu",
    value: "Merrimack College",
  },
  {
    label: "MGH Institute of Health Professions",
    domain: "mghihp.edu",
    value: "MGH Institute of Health Professions",
  },
  {
    label: "Milton Academy",
    domain: "milton.edu",
    value: "Milton Academy",
  },
  {
    label: "Massachusetts Institute of Technology",
    domain: "mit.edu",
    value: "Massachusetts Institute of Technology",
  },
  {
    label: "Montserrat College of Art",
    domain: "montserrat.edu",
    value: "Montserrat College of Art",
  },
  {
    label: "Mount Ida College",
    domain: "mountida.edu",
    value: "Mount Ida College",
  },
  {
    label: "Mount Holyoke College",
    domain: "mtholyoke.edu",
    value: "Mount Holyoke College",
  },
  {
    label: "Middlesex Community College",
    domain: "mxcc.edu",
    value: "Middlesex Community College",
  },
  {
    label: "Middlesex School",
    domain: "mxschool.edu",
    value: "Middlesex School",
  },
  {
    label: "North Bennet Street School",
    domain: "nbss.edu",
    value: "North Bennet Street School",
  },
  {
    label: "New England Law - Boston",
    domain: "nesl.edu",
    value: "New England Law - Boston",
  },
  {
    label: "Northeastern University",
    domain: "northeastern.edu",
    value: "Northeastern University",
  },
  {
    label: "Olin College of Engineering",
    domain: "olin.edu",
    value: "Olin College of Engineering",
  },
  {
    label: "Salem State University",
    domain: "salemstate.edu",
    value: "Salem State University",
  },
  {
    label: "Simmons College",
    domain: "simmons.edu",
    value: "Simmons College",
  },
  { label: "Smith College", domain: "smith.edu", value: "Smith College" },
  {
    label: "Spa Tech Institute",
    domain: "spatech.edu",
    value: "Spa Tech Institute",
  },
  {
    label: "Springfield College",
    domain: "springfieldcollege.edu",
    value: "Springfield College",
  },
  {
    label: "Springfield Technical Community College",
    domain: "stcc.edu",
    value: "Springfield Technical Community College",
  },
  {
    label: "Suffolk University",
    domain: "suffolk.edu",
    value: "Suffolk University",
  },
  {
    label: "Boston Architectural College",
    domain: "the-bac.edu",
    value: "Boston Architectural College",
  },
  {
    label: "Tufts University",
    domain: "tufts.edu",
    value: "Tufts University",
  },
  {
    label: "University of Massachusetts Amherst",
    domain: "umass.edu",
    value: "University of Massachusetts Amherst",
  },
  {
    label: "University of Massachusetts Dartmouth",
    domain: "umassd.edu",
    value: "University of Massachusetts Dartmouth",
  },
  {
    label: "University of Massachusetts Medical School",
    domain: "umassmed.edu",
    value: "University of Massachusetts Medical School",
  },
  {
    label: "University of Massachusetts",
    domain: "umassp.edu",
    value: "University of Massachusetts",
  },
  {
    label: "University of Massachusetts Boston",
    domain: "umb.edu",
    value: "University of Massachusetts Boston",
  },
  {
    label: "University of Massachusetts Lowell",
    domain: "uml.edu",
    value: "University of Massachusetts Lowell",
  },
  {
    label: "Wellesley College",
    domain: "wellesley.edu",
    value: "Wellesley College",
  },
  {
    label: "Wheaton College, Norton",
    domain: "wheatoncollege.edu",
    value: "Wheaton College, Norton",
  },
  {
    label: "Wheelock College",
    domain: "wheelock.edu",
    value: "Wheelock College",
  },
  {
    label: "Williams College",
    domain: "williams.edu",
    value: "Williams College",
  },
  {
    label: "Western New England University",
    domain: "wne.edu",
    value: "Western New England University",
  },
  {
    label: "Worcester State University",
    domain: "worcester.edu",
    value: "Worcester State University",
  },
  {
    label: "Worcester Polytechnic Institute",
    domain: "wpi.edu",
    value: "Worcester Polytechnic Institute",
  },
  {
    label: "Anne Arundel Community College",
    domain: "aacc.edu",
    value: "Anne Arundel Community College",
  },
  {
    label: "Baltimore City Community College",
    domain: "bccc.edu",
    value: "Baltimore City Community College",
  },
  {
    label: "Bowie State University",
    domain: "bowiestate.edu",
    value: "Bowie State University",
  },
  {
    label: "Carroll Community College",
    domain: "carrollcc.edu",
    value: "Carroll Community College",
  },
  {
    label: "The Community College of Baltimore County",
    domain: "ccbcmd.edu",
    value: "The Community College of Baltimore County",
  },
  {
    label: "Chesapeake College",
    domain: "chesapeake.edu",
    value: "Chesapeake College",
  },
  {
    label: "Coppin State University",
    domain: "coppin.edu",
    value: "Coppin State University",
  },
  {
    label: "College of Southern Maryland",
    domain: "csmd.edu",
    value: "College of Southern Maryland",
  },
  {
    label: "Frederick Community College",
    domain: "frederick.edu",
    value: "Frederick Community College",
  },
  {
    label: "Frostburg State University",
    domain: "frostburg.edu",
    value: "Frostburg State University",
  },
  {
    label: "Garrett College",
    domain: "garrettcollege.edu",
    value: "Garrett College",
  },
  {
    label: "Goucher College",
    domain: "goucher.edu",
    value: "Goucher College",
  },
  {
    label: "Hagerstown Community College",
    domain: "hagerstowncc.edu",
    value: "Hagerstown Community College",
  },
  {
    label: "Harford Community College",
    domain: "harford.edu",
    value: "Harford Community College",
  },
  { label: "Hood College", domain: "hood.edu", value: "Hood College" },
  {
    label: "Howard Community College",
    domain: "howardcc.edu",
    value: "Howard Community College",
  },
  {
    label: "Johns Hopkins Bloomberg School of Public Health",
    domain: "jhsph.edu",
    value: "Johns Hopkins Bloomberg School of Public Health",
  },
  {
    label: "Johns Hopkins University",
    domain: "jhu.edu",
    value: "Johns Hopkins University",
  },
  {
    label: "Loyola University Maryland",
    domain: "loyola.edu",
    value: "Loyola University Maryland",
  },
  {
    label: "Montgomery Blair High School",
    domain: "mbhs.edu",
    value: "Montgomery Blair High School",
  },
  {
    label: "McDaniel College",
    domain: "mcdaniel.edu",
    value: "McDaniel College",
  },
  {
    label: "Maryland Institute College of Art",
    domain: "mica.edu",
    value: "Maryland Institute College of Art",
  },
  {
    label: "Montgomery College",
    domain: "montgomerycollege.edu",
    value: "Montgomery College",
  },
  {
    label: "Mount Saint Mary's University",
    domain: "msmary.edu",
    value: "Mount Saint Mary's University",
  },
  {
    label: "Maryland University of Integrative Health",
    domain: "muih.edu",
    value: "Maryland University of Integrative Health",
  },
  {
    label: "Prince George's Community College",
    domain: "pgcc.edu",
    value: "Prince George's Community College",
  },
  {
    label: "Salisbury University",
    domain: "salisbury.edu",
    value: "Salisbury University",
  },
  {
    label: "Sojourner-Douglass College",
    domain: "sdc.edu",
    value: "Sojourner-Douglass College",
  },
  {
    label: "Saint Mary's College of Maryland",
    domain: "smcm.edu",
    value: "Saint Mary's College of Maryland",
  },
  {
    label: "Saint James School",
    domain: "stjames.edu",
    value: "Saint James School",
  },
  {
    label: "Towson University",
    domain: "towson.edu",
    value: "Towson University",
  },
  {
    label: "University of Baltimore",
    domain: "ubalt.edu",
    value: "University of Baltimore",
  },
  {
    label: "University of Maryland Baltimore County",
    domain: "umbc.edu",
    value: "University of Maryland Baltimore County",
  },
  {
    label: "University of Maryland",
    domain: "umd.edu",
    value: "University of Maryland",
  },
  {
    label: "University of Maryland University College",
    domain: "umuc.edu",
    value: "University of Maryland University College",
  },
  {
    label: "University System of Maryland",
    domain: "usmd.edu",
    value: "University System of Maryland",
  },
  {
    label: "United States Naval Academy",
    domain: "usna.edu",
    value: "United States Naval Academy",
  },
  {
    label: "Washington College",
    domain: "washcoll.edu",
    value: "Washington College",
  },
  {
    label: "Wor-Wic Community College",
    domain: "worwic.edu",
    value: "Wor-Wic Community College",
  },
  {
    label: "Saint John's College",
    domain: "sjc.edu",
    value: "Saint John's College",
  },
  { label: "Bates College", domain: "bates.edu", value: "Bates College" },
  {
    label: "Bowdoin College",
    domain: "bowdoin.edu",
    value: "Bowdoin College",
  },
  {
    label: "Central Maine Community College",
    domain: "cmcc.edu",
    value: "Central Maine Community College",
  },
  {
    label: "College of the Atlantic",
    domain: "coa.edu",
    value: "College of the Atlantic",
  },
  { label: "Colby College", domain: "colby.edu", value: "Colby College" },
  {
    label: "Husson University",
    domain: "husson.edu",
    value: "Husson University",
  },
  {
    label: "Maine Maritime Academy",
    domain: "mainemaritime.edu",
    value: "Maine Maritime Academy",
  },
  {
    label: "Northern Maine Community College",
    domain: "nmcc.edu",
    value: "Northern Maine Community College",
  },
  {
    label: "Saint Joseph's College",
    domain: "sjcme.edu",
    value: "Saint Joseph's College",
  },
  {
    label: "Southern Maine Community College",
    domain: "smccme.edu",
    value: "Southern Maine Community College",
  },
  {
    label: "University of Maine",
    domain: "umaine.edu",
    value: "University of Maine",
  },
  {
    label: "University of Maine Fort Kent",
    domain: "umfk.edu",
    value: "University of Maine Fort Kent",
  },
  {
    label: "University of Maine at Presque Isle",
    domain: "umpi.edu",
    value: "University of Maine at Presque Isle",
  },
  {
    label: "University of New England",
    domain: "une.edu",
    value: "University of New England",
  },
  { label: "Unity College", domain: "unity.edu", value: "Unity College" },
  {
    label: "York County Community College",
    domain: "yccc.edu",
    value: "York County Community College",
  },
  {
    label: "University of Main at Augusta",
    domain: "uma.edu",
    value: "University of Main at Augusta",
  },
  {
    label: "Adrian College",
    domain: "adrian.edu",
    value: "Adrian College",
  },
  {
    label: "Albion College",
    domain: "albion.edu",
    value: "Albion College",
  },
  { label: "Alma College", domain: "alma.edu", value: "Alma College" },
  {
    label: "Alpena Community College",
    domain: "alpenacc.edu",
    value: "Alpena Community College",
  },
  {
    label: "Andrews University",
    domain: "andrews.edu",
    value: "Andrews University",
  },
  {
    label: "Aquinas College",
    domain: "aquinas.edu",
    value: "Aquinas College",
  },
  { label: "Baker College", domain: "baker.edu", value: "Baker College" },
  {
    label: "Bay College",
    domain: "baycollege.edu",
    value: "Bay College",
  },
  {
    label: "Calvin College",
    domain: "calvin.edu",
    value: "Calvin College",
  },
  {
    label: "Central Michigan University",
    domain: "cmich.edu",
    value: "Central Michigan University",
  },
  {
    label: "College for Creative Studies",
    domain: "collegeforcreativestudies.edu",
    value: "College for Creative Studies",
  },
  {
    label: "Cornerstone University",
    domain: "cornerstone.edu",
    value: "Cornerstone University",
  },
  {
    label: "Davenport University",
    domain: "davenport.edu",
    value: "Davenport University",
  },
  { label: "Delta College", domain: "delta.edu", value: "Delta College" },
  {
    label: "Douglas J. Aveda Institute",
    domain: "douglasj.edu",
    value: "Douglas J. Aveda Institute",
  },
  {
    label: "Eastern Michigan University",
    domain: "emich.edu",
    value: "Eastern Michigan University",
  },
  {
    label: "Ferris State University",
    domain: "ferris.edu",
    value: "Ferris State University",
  },
  {
    label: "Finlandia University",
    domain: "finlandia.edu",
    value: "Finlandia University",
  },
  {
    label: "Grand Rapids Community College",
    domain: "grcc.edu",
    value: "Grand Rapids Community College",
  },
  {
    label: "Grand Valley State University",
    domain: "gvsu.edu",
    value: "Grand Valley State University",
  },
  {
    label: "Henry Ford College",
    domain: "hfcc.edu",
    value: "Henry Ford College",
  },
  {
    label: "Hillsdale College",
    domain: "hillsdale.edu",
    value: "Hillsdale College",
  },
  { label: "Hope College", domain: "hope.edu", value: "Hope College" },
  {
    label: "Jackson College",
    domain: "jccmi.edu",
    value: "Jackson College",
  },
  {
    label: "Kettering University",
    domain: "kettering.edu",
    value: "Kettering University",
  },
  {
    label: "Kalamazoo Valley Community College",
    domain: "kvcc.edu",
    value: "Kalamazoo Valley Community College",
  },
  {
    label: "Kalamazoo College",
    domain: "kzoo.edu",
    value: "Kalamazoo College",
  },
  {
    label: "Lake Michigan College",
    domain: "lakemichigancollege.edu",
    value: "Lake Michigan College",
  },
  {
    label: "Lansing Community College",
    domain: "lcc.edu",
    value: "Lansing Community College",
  },
  {
    label: "Lake Superior State University",
    domain: "lssu.edu",
    value: "Lake Superior State University",
  },
  {
    label: "Lawrence Technological University",
    domain: "ltu.edu",
    value: "Lawrence Technological University",
  },
  {
    label: "Macomb Community College",
    domain: "macomb.edu",
    value: "Macomb Community College",
  },
  {
    label: "Marygrove College",
    domain: "marygrove.edu",
    value: "Marygrove College",
  },
  {
    label: "Monroe County Community College",
    domain: "monroeccc.edu",
    value: "Monroe County Community College",
  },
  {
    label: "Michigan State University",
    domain: "msu.edu",
    value: "Michigan State University",
  },
  {
    label: "Michigan Technological University",
    domain: "mtu.edu",
    value: "Michigan Technological University",
  },
  {
    label: "Northwestern Michigan College",
    domain: "nmc.edu",
    value: "Northwestern Michigan College",
  },
  {
    label: "Northern Michigan University",
    domain: "nmu.edu",
    value: "Northern Michigan University",
  },
  {
    label: "Oakland Univeristy",
    domain: "oakland.edu",
    value: "Oakland Univeristy",
  },
  {
    label: "Oakland Community College",
    domain: "oaklandcc.edu",
    value: "Oakland Community College",
  },
  {
    label: "Saginaw Chippewa Tribal College",
    domain: "sagchip.edu",
    value: "Saginaw Chippewa Tribal College",
  },
  {
    label: "Saginaw Valley State University",
    domain: "svsu.edu",
    value: "Saginaw Valley State University",
  },
  {
    label: "University of Detroit Mercy",
    domain: "udmercy.edu",
    value: "University of Detroit Mercy",
  },
  {
    label: "University of Michigan-Flint",
    domain: "umflint.edu",
    value: "University of Michigan-Flint",
  },
  {
    label: "University of Michigan",
    domain: "umich.edu",
    value: "University of Michigan",
  },
  { label: "Wayne State", domain: "wayne.edu", value: "Wayne State" },
  {
    label: "Washtenaw Community College",
    domain: "wccnet.edu",
    value: "Washtenaw Community College",
  },
  {
    label: "Western Michigan University",
    domain: "wmich.edu",
    value: "Western Michigan University",
  },
  {
    label: "Alexandria Technical & Community College",
    domain: "alextech.edu",
    value: "Alexandria Technical & Community College",
  },
  {
    label: "Anoka-Ramsey Community College",
    domain: "anokaramsey.edu",
    value: "Anoka-Ramsey Community College",
  },
  {
    label: "Augsburg College",
    domain: "augsburg.edu",
    value: "Augsburg College",
  },
  {
    label: "Bemidji State University",
    domain: "bemidjistate.edu",
    value: "Bemidji State University",
  },
  {
    label: "Bethel University",
    domain: "bethel.edu",
    value: "Bethel University",
  },
  {
    label: "Carleton College",
    domain: "carleton.edu",
    value: "Carleton College",
  },
  {
    label: "Century College",
    domain: "century.edu",
    value: "Century College",
  },
  {
    label: "Central Lakes College",
    domain: "clcmn.edu",
    value: "Central Lakes College",
  },
  {
    label: "Concordia College",
    domain: "cord.edu",
    value: "Concordia College",
  },
  { label: "Crown College", domain: "crown.edu", value: "Crown College" },
  {
    label: "College of Saint Benedict, Saint John's University",
    domain: "csbsju.edu",
    value: "College of Saint Benedict, Saint John's University",
  },
  {
    label: "Concordia University Saint Paul",
    domain: "csp.edu",
    value: "Concordia University Saint Paul",
  },
  {
    label: "The College of Saint Scholastica",
    domain: "css.edu",
    value: "The College of Saint Scholastica",
  },
  {
    label: "Dakota County Technical College",
    domain: "dctc.edu",
    value: "Dakota County Technical College",
  },
  {
    label: "Gustavus Adolphus College",
    domain: "gustavus.edu",
    value: "Gustavus Adolphus College",
  },
  {
    label: "Globe University",
    domain: "globeuniversity.edu",
    value: "Globe University",
  },
  {
    label: "Hamline University",
    domain: "hamline.edu",
    value: "Hamline University",
  },
  {
    label: "Inver Hills Community College",
    domain: "inverhills.edu",
    value: "Inver Hills Community College",
  },
  {
    label: "Institute of Production and Recording",
    domain: "ipr.edu",
    value: "Institute of Production and Recording",
  },
  {
    label: "Leech Lake Tribal College",
    domain: "lltc.edu",
    value: "Leech Lake Tribal College",
  },
  {
    label: "Luther Seminary",
    domain: "luthersem.edu",
    value: "Luther Seminary",
  },
  {
    label: "Macalester College",
    domain: "macalester.edu",
    value: "Macalester College",
  },
  {
    label: "McNally Smith College of Music",
    domain: "mcnallysmith.edu",
    value: "McNally Smith College of Music",
  },
  {
    label: "Mesabi Range College",
    domain: "mesabirange.edu",
    value: "Mesabi Range College",
  },
  {
    label: "Metropolitan State University",
    domain: "metrostate.edu",
    value: "Metropolitan State University",
  },
  {
    label: "Minnesota State University Moorhead",
    domain: "mnstate.edu",
    value: "Minnesota State University Moorhead",
  },
  {
    label: "Minnesota State University Mankato",
    domain: "mnsu.edu",
    value: "Minnesota State University Mankato",
  },
  {
    label: "North Hennepin Community College",
    domain: "nhcc.edu",
    value: "North Hennepin Community College",
  },
  {
    label: "Normandale Community College",
    domain: "normandale.edu",
    value: "Normandale Community College",
  },
  {
    label: "Rasmussen College",
    domain: "rasmussen.edu",
    value: "Rasmussen College",
  },
  {
    label: "Ridgewater College",
    domain: "ridgewater.edu",
    value: "Ridgewater College",
  },
  {
    label: "Southwest Minnesota State University",
    domain: "smsu.edu",
    value: "Southwest Minnesota State University",
  },
  {
    label: "Saint Mary's University of Minnesota",
    domain: "smumn.edu",
    value: "Saint Mary's University of Minnesota",
  },
  {
    label: "South Central College",
    domain: "southcentral.edu",
    value: "South Central College",
  },
  {
    label: "Saint Cloud State University",
    domain: "stcloudstate.edu",
    value: "Saint Cloud State University",
  },
  {
    label: "Saint Olaf College",
    domain: "stolaf.edu",
    value: "Saint Olaf College",
  },
  {
    label: "University of Minnesota",
    domain: "umn.edu",
    value: "University of Minnesota",
  },
  {
    label: "United Theological Seminary of the Twin Cities",
    domain: "unitedseminary.edu",
    value: "United Theological Seminary of the Twin Cities",
  },
  {
    label: "University of Northwestern Saint Paul",
    domain: "unwsp.edu",
    value: "University of Northwestern Saint Paul",
  },
  {
    label: "Walden University",
    domain: "waldenu.edu",
    value: "Walden University",
  },
  {
    label: "Winona State Unviversity",
    domain: "winona.edu",
    value: "Winona State Unviversity",
  },
  {
    label: "Assemblies of God Theological Seminary",
    domain: "agts.edu",
    value: "Assemblies of God Theological Seminary",
  },
  {
    label: "Aquinas Institute of Theology",
    domain: "ai.edu",
    value: "Aquinas Institute of Theology",
  },
  {
    label: "A.T. Still University",
    domain: "atsu.edu",
    value: "A.T. Still University",
  },
  {
    label: "Avila University",
    domain: "avila.edu",
    value: "Avila University",
  },
  {
    label: "Columbia College",
    domain: "ccis.edu",
    value: "Columbia College",
  },
  {
    label: "Central Methodist University",
    domain: "centralmethodist.edu",
    value: "Central Methodist University",
  },
  {
    label: "Crowder College",
    domain: "crowder.edu",
    value: "Crowder College",
  },
  {
    label: "Culver-Stockton College",
    domain: "culver.edu",
    value: "Culver-Stockton College",
  },
  {
    label: "Drury University",
    domain: "drury.edu",
    value: "Drury University",
  },
  {
    label: "Evangel University",
    domain: "evangel.edu",
    value: "Evangel University",
  },
  {
    label: "Fontbonne University",
    domain: "fontbonne.edu",
    value: "Fontbonne University",
  },
  {
    label: "Hannibal-LaGrange",
    domain: "hlg.edu",
    value: "Hannibal-LaGrange",
  },
  {
    label: "Harris-Stowe State University",
    domain: "hssu.edu",
    value: "Harris-Stowe State University",
  },
  {
    label: "Jefferson College",
    domain: "jeffco.edu",
    value: "Jefferson College",
  },
  {
    label: "Kansas City University of Medicine and Biosciences",
    domain: "kcumb.edu",
    value: "Kansas City University of Medicine and Biosciences",
  },
  {
    label: "Lincoln University",
    domain: "lincolne.edu",
    value: "Lincoln University",
  },
  {
    label: "Lindenwood University",
    domain: "lindenwood.edu",
    value: "Lindenwood University",
  },
  {
    label: "Linn State Technical College",
    domain: "linnstate.edu",
    value: "Linn State Technical College",
  },
  {
    label: "Maryville University",
    domain: "maryville.edu",
    value: "Maryville University",
  },
  {
    label: "Metropolitan Community College",
    domain: "mcckc.edu",
    value: "Metropolitan Community College",
  },
  {
    label: "Mineral Area College",
    domain: "mineralarea.edu",
    value: "Mineral Area College",
  },
  {
    label: "Mizzou University of Missouri",
    domain: "missouri.edu",
    value: "Mizzou University of Missouri",
  },
  {
    label: "Missouri State University",
    domain: "missouristate.edu",
    value: "Missouri State University",
  },
  {
    label: "Missouri Valley College",
    domain: "moval.edu",
    value: "Missouri Valley College",
  },
  {
    label: "Missouri Southern State University",
    domain: "mssu.edu",
    value: "Missouri Southern State University",
  },
  {
    label: "Missouri University of Science and Technology",
    domain: "mst.edu",
    value: "Missouri University of Science and Technology",
  },
  {
    label: "North Central Missouri College",
    domain: "ncmissouri.edu",
    value: "North Central Missouri College",
  },
  {
    label: "Nazarene Theological Seminary",
    domain: "nts.edu",
    value: "Nazarene Theological Seminary",
  },
  {
    label: "Northwest Missouri State University",
    domain: "nwmissouri.edu",
    value: "Northwest Missouri State University",
  },
  {
    label: "Ozarks Technical Community College",
    domain: "otc.edu",
    value: "Ozarks Technical Community College",
  },
  {
    label: "Park University",
    domain: "park.edu",
    value: "Park University",
  },
  {
    label: "Rockhurst University",
    domain: "rockhurst.edu",
    value: "Rockhurst University",
  },
  {
    label: "Southwest Baptist University",
    domain: "sbuniv.edu",
    value: "Southwest Baptist University",
  },
  {
    label: "South Central Career Center",
    domain: "scccwp.edu",
    value: "South Central Career Center",
  },
  {
    label: "Southeast Missouri State University",
    domain: "semo.edu",
    value: "Southeast Missouri State University",
  },
  {
    label: "Saint Louis University",
    domain: "slu.edu",
    value: "Saint Louis University",
  },
  {
    label: "Saint Charles Community College",
    domain: "stchas.edu",
    value: "Saint Charles Community College",
  },
  {
    label: "Saint Louis Community College",
    domain: "stlcc.edu",
    value: "Saint Louis Community College",
  },
  {
    label: "Truman State University",
    domain: "truman.edu",
    value: "Truman State University",
  },
  {
    label: "University of Central Missouri",
    domain: "ucmo.edu",
    value: "University of Central Missouri",
  },
  {
    label: "University of Missouri Kansas City",
    domain: "umkc.edu",
    value: "University of Missouri Kansas City",
  },
  {
    label: "University of Missouri Saint Louis",
    domain: "umsl.edu",
    value: "University of Missouri Saint Louis",
  },
  {
    label: "University of Missouri System",
    domain: "umsystem.edu",
    value: "University of Missouri System",
  },
  {
    label: "Webster University",
    domain: "webster.edu",
    value: "Webster University",
  },
  {
    label: "Westminster College, Missouri",
    domain: "westminster-mo.edu",
    value: "Westminster College, Missouri",
  },
  {
    label: "Washington University in Saint Louis",
    domain: "wustl.edu",
    value: "Washington University in Saint Louis",
  },
  {
    label: "Alcorn State University",
    domain: "alcorn.edu",
    value: "Alcorn State University",
  },
  {
    label: "Belhaven University",
    domain: "belhaven.edu",
    value: "Belhaven University",
  },
  {
    label: "Delta State University",
    domain: "deltastate.edu",
    value: "Delta State University",
  },
  {
    label: "East Mississippi Community College",
    domain: "eastms.edu",
    value: "East Mississippi Community College",
  },
  {
    label: "Jackson State University",
    domain: "jsums.edu",
    value: "Jackson State University",
  },
  {
    label: "Mississippi Gulf Coast Community College",
    domain: "mgccc.edu",
    value: "Mississippi Gulf Coast Community College",
  },
  {
    label: "Mississippi State University",
    domain: "mssstate.edu",
    value: "Mississippi State University",
  },
  {
    label: "Northeast Mississippi Community College",
    domain: "nemcc.edu",
    value: "Northeast Mississippi Community College",
  },
  {
    label: "The University of Mississippi",
    domain: "olemiss.edu",
    value: "The University of Mississippi",
  },
  {
    label: "Pearl River Community College",
    domain: "prcc.edu",
    value: "Pearl River Community College",
  },
  {
    label: "Reformed Theological Seminary",
    domain: "rts.edu",
    value: "Reformed Theological Seminary",
  },
  {
    label: "University of Mississippi Medical Center",
    domain: "umc.edu",
    value: "University of Mississippi Medical Center",
  },
  {
    label: "University of Southern Mississippi",
    domain: "usm.edu",
    value: "University of Southern Mississippi",
  },
  {
    label: "William Carey University",
    domain: "wmcarey.edu",
    value: "William Carey University",
  },
  {
    label: "Itawamba Community College",
    domain: "iccms.edu",
    value: "Itawamba Community College",
  },
  {
    label: "Blackfeet Community College",
    domain: "bfcc.edu",
    value: "Blackfeet Community College",
  },
  {
    label: "Carroll College",
    domain: "carroll.edu",
    value: "Carroll College",
  },
  {
    label: "Fort Peck Community College",
    domain: "fpcc.edu",
    value: "Fort Peck Community College",
  },
  {
    label: "Flathead Valley Community College",
    domain: "fvcc.edu",
    value: "Flathead Valley Community College",
  },
  {
    label: "Miles Community College",
    domain: "milescc.edu",
    value: "Miles Community College",
  },
  {
    label: "Montana State University",
    domain: "montana.edu",
    value: "Montana State University",
  },
  {
    label: "Montana State University Billings",
    domain: "msubillings.edu",
    value: "Montana State University Billings",
  },
  {
    label: "Montana State University - Northern",
    domain: "msun.edu",
    value: "Montana State University - Northern",
  },
  {
    label: "Montana Tech of the University of Montana",
    domain: "mtech.edu",
    value: "Montana Tech of the University of Montana",
  },
  {
    label: "Montana University System",
    domain: "mus.edu",
    value: "Montana University System",
  },
  {
    label: "Rocky Mountain College",
    domain: "rocky.edu",
    value: "Rocky Mountain College",
  },
  {
    label: "University of Montana Helena College",
    domain: "umhelena.edu",
    value: "University of Montana Helena College",
  },
  {
    label: "University of Montana",
    domain: "umt.edu",
    value: "University of Montana",
  },
  {
    label: "University of Montana Western",
    domain: "umwestern.edu",
    value: "University of Montana Western",
  },
  {
    label: "Asheville-Buncombe Technical Community College",
    domain: "abtech.edu",
    value: "Asheville-Buncombe Technical Community College",
  },
  {
    label: "Alamance Community College",
    domain: "alamancecc.edu",
    value: "Alamance Community College",
  },
  {
    label: "Appalachian State University",
    domain: "appstate.edu",
    value: "Appalachian State University",
  },
  {
    label: "Bladen Community College",
    domain: "bladencc.edu",
    value: "Bladen Community College",
  },
  {
    label: "Body Therapy Institute",
    domain: "bti.edu",
    value: "Body Therapy Institute",
  },
  {
    label: "Campbell University",
    domain: "campbell.edu",
    value: "Campbell University",
  },
  {
    label: "Cape Fear Community College",
    domain: "cfcc.edu",
    value: "Cape Fear Community College",
  },
  {
    label: "Charlotte School of Law",
    domain: "charlottelaw.edu",
    value: "Charlotte School of Law",
  },
  {
    label: "Chowan University",
    domain: "chowan.edu",
    value: "Chowan University",
  },
  {
    label: "Central Piedmont Community College",
    domain: "cpcc.edu",
    value: "Central Piedmont Community College",
  },
  {
    label: "Craven Community College",
    domain: "cravencc.edu",
    value: "Craven Community College",
  },
  {
    label: "Davidson College",
    domain: "davidson.edu",
    value: "Davidson College",
  },
  {
    label: "Duke University",
    domain: "duke.edu",
    value: "Duke University",
  },
  {
    label: "Durham Technical Community College",
    domain: "durhamtech.edu",
    value: "Durham Technical Community College",
  },
  {
    label: "Elizabeth City State University",
    domain: "ecsu.edu",
    value: "Elizabeth City State University",
  },
  {
    label: "East Carolina University",
    domain: "ecu.edu",
    value: "East Carolina University",
  },
  {
    label: "Elon University",
    domain: "elon.edu",
    value: "Elon University",
  },
  {
    label: "Fayetteville Technical Community College",
    domain: "faytechcc.edu",
    value: "Fayetteville Technical Community College",
  },
  {
    label: "Forsyth Technical Community College",
    domain: "forsythtech.edu",
    value: "Forsyth Technical Community College",
  },
  {
    label: "Gardner-Webb University",
    domain: "gardner-webb.edu",
    value: "Gardner-Webb University",
  },
  {
    label: "Gaston College",
    domain: "gaston.edu",
    value: "Gaston College",
  },
  {
    label: "Guilford Technical Community College",
    domain: "gtcc.edu",
    value: "Guilford Technical Community College",
  },
  {
    label: "Heritage Bible College",
    domain: "heritagebiblecollege.edu",
    value: "Heritage Bible College",
  },
  {
    label: "High Point University",
    domain: "highpoint.edu",
    value: "High Point University",
  },
  {
    label: "Isothermal Community College",
    domain: "isothermal.edu",
    value: "Isothermal Community College",
  },
  {
    label: "Lenoir Community College",
    domain: "lenoircc.edu",
    value: "Lenoir Community College",
  },
  {
    label: "Lees McRae College",
    domain: "lmc.edu",
    value: "Lees McRae College",
  },
  {
    label: "Methodist University",
    domain: "methodist.edu",
    value: "Methodist University",
  },
  {
    label: "The University of Mount Olive",
    domain: "umo.edu",
    value: "The University of Mount Olive",
  },
  {
    label: "Montgomery Community College",
    domain: "montgomery.edu",
    value: "Montgomery Community College",
  },
  {
    label: "North Carolina Central University",
    domain: "nccu.edu",
    value: "North Carolina Central University",
  },
  {
    label: "North Carolina School of Science and Mathematics",
    domain: "ncssm.edu",
    value: "North Carolina School of Science and Mathematics",
  },
  {
    label: "North Carolina State University",
    domain: "ncsu.edu",
    value: "North Carolina State University",
  },
  {
    label: "North Carolina Wesleyan College",
    domain: "ncwc.edu",
    value: "North Carolina Wesleyan College",
  },
  {
    label: "New Life Theological Seminary",
    domain: "nlts.edu",
    value: "New Life Theological Seminary",
  },
  {
    label: "University of North Carolina",
    domain: "northcarolina.edu",
    value: "University of North Carolina",
  },
  {
    label: "Piedmont Community College",
    domain: "piedmontcc.edu",
    value: "Piedmont Community College",
  },
  {
    label: "Pitt Community College",
    domain: "pittcc.edu",
    value: "Pitt Community College",
  },
  {
    label: "Queens University of Charlotte",
    domain: "queens.edu",
    value: "Queens University of Charlotte",
  },
  {
    label: "Randolph Community College",
    domain: "randolph.edu",
    value: "Randolph Community College",
  },
  {
    label: "Roanoke-Chowan Community College",
    domain: "roanokechowan.edu",
    value: "Roanoke-Chowan Community College",
  },
  { label: "Salem College", domain: "salem.edu", value: "Salem College" },
  {
    label: "Sampson Community College",
    domain: "sampsoncc.edu",
    value: "Sampson Community College",
  },
  {
    label: "Sandhills Community College",
    domain: "sandhills.edu",
    value: "Sandhills Community College",
  },
  {
    label: "Saint Andrews University",
    domain: "sapc.edu",
    value: "Saint Andrews University",
  },
  {
    label: "Southeastern Community College",
    domain: "sccnc.edu",
    value: "Southeastern Community College",
  },
  {
    label: "Southeastern Baptist Theological Seminary",
    domain: "sebts.edu",
    value: "Southeastern Baptist Theological Seminary",
  },
  {
    label: "Shaw University",
    domain: "shawu.edu",
    value: "Shaw University",
  },
  {
    label: "South Piedmont Community College",
    domain: "spcc.edu",
    value: "South Piedmont Community College",
  },
  {
    label: "Stanly Community College",
    domain: "stanly.edu",
    value: "Stanly Community College",
  },
  {
    label: "Saint Augustine's University",
    domain: "st-aug.edu",
    value: "Saint Augustine's University",
  },
  {
    label: "University of North Carolina at Chapel Hill",
    domain: "unc.edu",
    value: "University of North Carolina at Chapel Hill",
  },
  {
    label: "University of North Carolina Asheville",
    domain: "unca.edu",
    value: "University of North Carolina Asheville",
  },
  {
    label: "University of North Carolina Charlotte",
    domain: "uncc.edu",
    value: "University of North Carolina Charlotte",
  },
  {
    label: "University of North Carolina Greensboro",
    domain: "uncg.edu",
    value: "University of North Carolina Greensboro",
  },
  {
    label: "University of North Carolina Pembroke",
    domain: "uncp.edu",
    value: "University of North Carolina Pembroke",
  },
  {
    label: "University of North Carolina School of the Arts",
    domain: "uncsa.edu",
    value: "University of North Carolina School of the Arts",
  },
  {
    label: "University of North Carolina Wilmington",
    domain: "uncw.edu",
    value: "University of North Carolina Wilmington",
  },
  {
    label: "Vance-Granville Community College",
    domain: "vgcc.edu",
    value: "Vance-Granville Community College",
  },
  {
    label: "Wake Technical Community College",
    domain: "waketech.edu",
    value: "Wake Technical Community College",
  },
  {
    label: "Warren Wilson College",
    domain: "warren-wilson.edu",
    value: "Warren Wilson College",
  },
  {
    label: "Western Carolina University",
    domain: "wcu.edu",
    value: "Western Carolina University",
  },
  {
    label: "Wake Forest University",
    domain: "wfu.edu",
    value: "Wake Forest University",
  },
  {
    label: "Western Piedmont Community College",
    domain: "wpcc.edu",
    value: "Western Piedmont Community College",
  },
  {
    label: "Winston-Salem State University",
    domain: "wssu.edu",
    value: "Winston-Salem State University",
  },
  {
    label: "Dakota College at Bottineau",
    domain: "dakotacollege.edu",
    value: "Dakota College at Bottineau",
  },
  {
    label: "Mayville State University",
    domain: "mayvillestate.edu",
    value: "Mayville State University",
  },
  {
    label: "Minot State University",
    domain: "minotstateu.edu",
    value: "Minot State University",
  },
  {
    label: "North Dakota State University",
    domain: "ndsu.edu",
    value: "North Dakota State University",
  },
  {
    label: "North Dakota University System",
    domain: "ndus.edu",
    value: "North Dakota University System",
  },
  {
    label: "Turtle Mountain Community College",
    domain: "tm.edu",
    value: "Turtle Mountain Community College",
  },
  {
    label: "University of North Dakota",
    domain: "und.edu",
    value: "University of North Dakota",
  },
  {
    label: "United Tribes Technical College",
    domain: "utcc.edu",
    value: "United Tribes Technical College",
  },
  {
    label: "Valley City State University",
    domain: "vcsu.edu",
    value: "Valley City State University",
  },
  {
    label: "Williston State College",
    domain: "willistonstate.edu",
    value: "Williston State College",
  },
  {
    label: "Bellevue University",
    domain: "bellevue.edu",
    value: "Bellevue University",
  },
  {
    label: "Bryan College of Health Sciences",
    domain: "bryanhealth.com",
    value: "Bryan College of Health Sciences",
  },
  {
    label: "Creighton University",
    domain: "creighton.edu",
    value: "Creighton University",
  },
  {
    label: "Chadron State College",
    domain: "csc.edu",
    value: "Chadron State College",
  },
  {
    label: "Concordia University Nebraska",
    domain: "cune.edu",
    value: "Concordia University Nebraska",
  },
  {
    label: "Joseph's College",
    domain: "josephscollege.edu",
    value: "Joseph's College",
  },
  {
    label: "Little Priest Tribal College",
    domain: "littlepriest.edu",
    value: "Little Priest Tribal College",
  },
  {
    label: "Mid-Plains Community College",
    domain: "mpcc.edu",
    value: "Mid-Plains Community College",
  },
  {
    label: "University of Nebraska",
    domain: "nebraska.edu",
    value: "University of Nebraska",
  },
  {
    label: "Northeast Community College",
    domain: "northeast.edu",
    value: "Northeast Community College",
  },
  {
    label: "University of Nebraska at Kearney",
    domain: "unk.edu",
    value: "University of Nebraska at Kearney",
  },
  {
    label: "University of Nebraska at Lincoln",
    domain: "unl.edu",
    value: "University of Nebraska at Lincoln",
  },
  {
    label: "University of Nebraska Medical Center",
    domain: "unmc.edu",
    value: "University of Nebraska Medical Center",
  },
  {
    label: "University of Nebraska Omaha",
    domain: "unomaha.edu",
    value: "University of Nebraska Omaha",
  },
  {
    label: "Wayne State College",
    domain: "wsc.edu",
    value: "Wayne State College",
  },
  {
    label: "Saint Anselm College",
    domain: "anselm.edu",
    value: "Saint Anselm College",
  },
  {
    label: "Antioch University New England",
    domain: "antiochne.edu",
    value: "Antioch University New England",
  },
  {
    label: "Community College System of New Hampshire",
    domain: "ccsnh.edu",
    value: "Community College System of New Hampshire",
  },
  {
    label: "Colby Sawyer College",
    domain: "colby-sawyer.edu",
    value: "Colby Sawyer College",
  },
  {
    label: "Dartmouth College",
    domain: "dartmouth.edu",
    value: "Dartmouth College",
  },
  {
    label: "Phillips Exeter Academy",
    domain: "exeter.edu",
    value: "Phillips Exeter Academy",
  },
  {
    label: "Granite State College",
    domain: "granite.edu",
    value: "Granite State College",
  },
  {
    label: "Keene State College",
    domain: "keene.edu",
    value: "Keene State College",
  },
  {
    label: "Manchester Community College",
    domain: "mccnh.edu",
    value: "Manchester Community College",
  },
  {
    label: "Mount Washington College",
    domain: "mountwashington.edu",
    value: "Mount Washington College",
  },
  {
    label: "Nashua Community College",
    domain: "nashuacc.edu",
    value: "Nashua Community College",
  },
  {
    label: "New England College",
    domain: "nec.edu",
    value: "New England College",
  },
  {
    label: "NHTI Concord's Community College",
    domain: "nhti.edu",
    value: "NHTI Concord's Community College",
  },
  {
    label: "Plymouth State University",
    domain: "plymouth.edu",
    value: "Plymouth State University",
  },
  {
    label: "Rivier University",
    domain: "rivier.edu",
    value: "Rivier University",
  },
  {
    label: "Southern New Hampshire University",
    domain: "snhu.edu",
    value: "Southern New Hampshire University",
  },
  {
    label: "University of New Hampshire",
    domain: "unh.edu",
    value: "University of New Hampshire",
  },
  {
    label: "White Mountains Community College",
    domain: "wmcc.edu",
    value: "White Mountains Community College",
  },
  {
    label: "Atlantic Cape Community College",
    domain: "atlantic.edu",
    value: "Atlantic Cape Community College",
  },
  {
    label: "Burlington County College",
    domain: "bcc.edu",
    value: "Burlington County College",
  },
  {
    label: "Bergen Community College",
    domain: "bergen.edu",
    value: "Bergen Community College",
  },
  {
    label: "Brookdale Community College",
    domain: "brookdalecc.edu",
    value: "Brookdale Community College",
  },
  {
    label: "Centenary College of New Jersey",
    domain: "centenarycollege.edu",
    value: "Centenary College of New Jersey",
  },
  {
    label: "Drew University",
    domain: "drew.edu",
    value: "Drew University",
  },
  {
    label: "Eastwick College",
    domain: "eastwickcollege.edu",
    value: "Eastwick College",
  },
  {
    label: "Fairleigh Dickinson University",
    domain: "fdu.edu",
    value: "Fairleigh Dickinson University",
  },
  {
    label: "Felician College",
    domain: "felician.edu",
    value: "Felician College",
  },
  {
    label: "Fortis Institute",
    domain: "fortis.edu",
    value: "Fortis Institute",
  },
  {
    label: "Georgian Court University",
    domain: "georgian.edu",
    value: "Georgian Court University",
  },
  {
    label: "Kean University",
    domain: "kean.edu",
    value: "Kean University",
  },
  {
    label: "Mercer County Community College",
    domain: "mccc.edu",
    value: "Mercer County Community College",
  },
  {
    label: "Middlesex County College",
    domain: "middlesexcc.edu",
    value: "Middlesex County College",
  },
  {
    label: "Monmouth University",
    domain: "monmouth.edu",
    value: "Monmouth University",
  },
  {
    label: "Montclair State University",
    domain: "montclair.edu",
    value: "Montclair State University",
  },
  {
    label: "New Jersey City University",
    domain: "njcu.edu",
    value: "New Jersey City University",
  },
  {
    label: "New Jersey Institute of Technology",
    domain: "njit.edu",
    value: "New Jersey Institute of Technology",
  },
  {
    label: "Ocean County College",
    domain: "ocean.edu",
    value: "Ocean County College",
  },
  {
    label: "Princeton University",
    domain: "princeton.edu",
    value: "Princeton University",
  },
  {
    label: "Princeton Theological Seminary",
    domain: "ptsem.edu",
    value: "Princeton Theological Seminary",
  },
  {
    label: "Ramapo College of New Jersey",
    domain: "ramapo.edu",
    value: "Ramapo College of New Jersey",
  },
  {
    label: "Raritan Valley Community College",
    domain: "raritanval.edu",
    value: "Raritan Valley Community College",
  },
  {
    label: "Rider University",
    domain: "rider.edu",
    value: "Rider University",
  },
  {
    label: "Rowan University",
    domain: "rowan.edu",
    value: "Rowan University",
  },
  {
    label: "Rutgers University",
    domain: "rutgers.edu",
    value: "Rutgers University",
  },
  {
    label: "Seton Hall University",
    domain: "shu.edu",
    value: "Seton Hall University",
  },
  {
    label: "Stenotech Career Institute",
    domain: "stenotech.edu",
    value: "Stenotech Career Institute",
  },
  {
    label: "Stevens Institute of Technology",
    domain: "stevens.edu",
    value: "Stevens Institute of Technology",
  },
  {
    label: "Stockton College",
    domain: "stockton.edu",
    value: "Stockton College",
  },
  {
    label: "Sussex County Community College",
    domain: "sussex.edu",
    value: "Sussex County Community College",
  },
  {
    label: "The College of New Jersey",
    domain: "tcnj.edu",
    value: "The College of New Jersey",
  },
  {
    label: "Thomas Edison State College",
    domain: "tesc.edu",
    value: "Thomas Edison State College",
  },
  {
    label: "William Paterson University",
    domain: "wpunj.edu",
    value: "William Paterson University",
  },
  {
    label: "County College of Morris",
    domain: "ccm.edu",
    value: "County College of Morris",
  },
  {
    label: "Camden County College",
    domain: "camdencc.edu",
    value: "Camden County College",
  },
  {
    label: "Passaic County Community College",
    domain: "pccc.edu",
    value: "Passaic County Community College",
  },
  {
    label: "Ross University",
    domain: "rossu.edu",
    value: "Ross University",
  },
  {
    label: "Central New Mexico",
    domain: "cnm.edu",
    value: "Central New Mexico",
  },
  {
    label: "Eastern New Mexico University",
    domain: "enmu.edu",
    value: "Eastern New Mexico University",
  },
  {
    label: "New Mexico Highlands University",
    domain: "nmhu.edu",
    value: "New Mexico Highlands University",
  },
  {
    label: "New Mexico State University",
    domain: "nmsu.edu",
    value: "New Mexico State University",
  },
  {
    label: "New Mexico State University Alamogordo",
    domain: "nmsua.edu",
    value: "New Mexico State University Alamogordo",
  },
  {
    label: "New Mexico Institute of Mining and Technology",
    domain: "nmt.edu",
    value: "New Mexico Institute of Mining and Technology",
  },
  {
    label: "Northern New Mexico College",
    domain: "nnmc.edu",
    value: "Northern New Mexico College",
  },
  {
    label: "San Juan College",
    domain: "sanjuancollege.edu",
    value: "San Juan College",
  },
  {
    label: "Univeristy of New Mexico",
    domain: "unm.edu",
    value: "Univeristy of New Mexico",
  },
  {
    label: "University of the Southwest",
    domain: "usw.edu",
    value: "University of the Southwest",
  },
  {
    label: "College of Southern Nevada",
    domain: "csn.edu",
    value: "College of Southern Nevada",
  },
  {
    label: "Great Basin College",
    domain: "gbcnv.edu",
    value: "Great Basin College",
  },
  {
    label: "Pima Medical Institute",
    domain: "pmi.edu",
    value: "Pima Medical Institute",
  },
  {
    label: "Sierra Nevada College",
    domain: "sierranevada.edu",
    value: "Sierra Nevada College",
  },
  {
    label: "Truckee Meadows Community College",
    domain: "tmcc.edu",
    value: "Truckee Meadows Community College",
  },
  {
    label: "University of Nevada Las Vegas",
    domain: "unlv.edu",
    value: "University of Nevada Las Vegas",
  },
  {
    label: "University of Nevada Reno",
    domain: "unr.edu",
    value: "University of Nevada Reno",
  },
  {
    label: "Western Nevada College",
    domain: "wnc.edu",
    value: "Western Nevada College",
  },
  {
    label: "Adelphi University",
    domain: "adelphi.edu",
    value: "Adelphi University",
  },
  {
    label: "University at Albany",
    domain: "albany.edu",
    value: "University at Albany",
  },
  {
    label: "Alfred University",
    domain: "alfred.edu",
    value: "Alfred University",
  },
  {
    label: "The American Musical and Dramatic Academy",
    domain: "amda.edu",
    value: "The American Musical and Dramatic Academy",
  },
  { label: "ASA College", domain: "asa.edu", value: "ASA College" },
  { label: "Bard College", domain: "bard.edu", value: "Bard College" },
  {
    label: "Barnard College",
    domain: "barnard.edu",
    value: "Barnard College",
  },
  {
    label: "Binghamton University",
    domain: "binghamton.edu",
    value: "Binghamton University",
  },
  {
    label: "The College at Brockport",
    domain: "brockport.edu",
    value: "The College at Brockport",
  },
  {
    label: "University at Buffalo",
    domain: "buffalo.edu",
    value: "University at Buffalo",
  },
  {
    label: "Buffalo State University",
    domain: "buffalostate.edu",
    value: "Buffalo State University",
  },
  {
    label: "Cazenovia College",
    domain: "cazenovia.edu",
    value: "Cazenovia College",
  },
  {
    label: "Clarkson University",
    domain: "clarkson.edu",
    value: "Clarkson University",
  },
  {
    label: "Clinton Community College",
    domain: "clinton.edu",
    value: "Clinton Community College",
  },
  {
    label: "The College of New Rochelle",
    domain: "cnr.edu",
    value: "The College of New Rochelle",
  },
  {
    label: "Cobleskill University",
    domain: "cobleskill.edu",
    value: "Cobleskill University",
  },
  {
    label: "Colgate University",
    domain: "colgate.edu",
    value: "Colgate University",
  },
  {
    label: "Columbia University in the City of New York",
    domain: "columbia.edu",
    value: "Columbia University in the City of New York",
  },
  {
    label: "Concordia College New York",
    domain: "concordia-ny.edu",
    value: "Concordia College New York",
  },
  {
    label: "The Cooper Union",
    domain: "cooper.edu",
    value: "The Cooper Union",
  },
  {
    label: "Cornell University",
    domain: "cornell.edu",
    value: "Cornell University",
  },
  {
    label: "Cortland College",
    domain: "cortland.edu",
    value: "Cortland College",
  },
  {
    label: "The City University of New York",
    domain: "cuny.edu",
    value: "The City University of New York",
  },
  {
    label: "The College of Westchester",
    domain: "cw.edu",
    value: "The College of Westchester",
  },
  {
    label: "Daemen College",
    domain: "daemen.edu",
    value: "Daemen College",
  },
  {
    label: "Dominican College",
    domain: "dc.edu",
    value: "Dominican College",
  },
  {
    label: "Delhi State University of New York",
    domain: "delhi.edu",
    value: "Delhi State University of New York",
  },
  {
    label: "Dowling College",
    domain: "dowling.edu",
    value: "Dowling College",
  },
  {
    label: "D'Youville College",
    domain: "dyc.edu",
    value: "D'Youville College",
  },
  {
    label: "Erie Community College State University of New York",
    domain: "ecc.edu",
    value: "Erie Community College State University of New York",
  },
  {
    label: "Elim Bible Institute",
    domain: "elim.edu",
    value: "Elim Bible Institute",
  },
  {
    label: "Elmira College",
    domain: "elmira.edu",
    value: "Elmira College",
  },
  {
    label: "Empire State College",
    domain: "esc.edu",
    value: "Empire State College",
  },
  {
    label: "College of Environmental Science and Forestry",
    domain: "esf.edu",
    value: "College of Environmental Science and Forestry",
  },
  {
    label: "Farmingdale State College",
    domain: "farmingdale.edu",
    value: "Farmingdale State College",
  },
  {
    label: "Fashion Institute of Technology",
    domain: "fitnyc.edu",
    value: "Fashion Institute of Technology",
  },
  {
    label: "Finger Lakes Community College",
    domain: "flcc.edu",
    value: "Finger Lakes Community College",
  },
  {
    label: "Fordham University",
    domain: "fordham.edu",
    value: "Fordham University",
  },
  {
    label: "Fredonia University",
    domain: "fredonia.edu",
    value: "Fredonia University",
  },
  {
    label: "Five Towns College",
    domain: "ftc.edu",
    value: "Five Towns College",
  },
  {
    label: "Geneseo University",
    domain: "geneseo.edu",
    value: "Geneseo University",
  },
  {
    label: "Globe Institute of Technology",
    domain: "globe.edu",
    value: "Globe Institute of Technology",
  },
  {
    label: "The General Theological Seminary",
    domain: "gts.edu",
    value: "The General Theological Seminary",
  },
  {
    label: "Hamilton College",
    domain: "hamilton.edu",
    value: "Hamilton College",
  },
  {
    label: "Hartwick College",
    domain: "hartwick.edu",
    value: "Hartwick College",
  },
  {
    label: "Herkimer College",
    domain: "herkimer.edu",
    value: "Herkimer College",
  },
  {
    label: "Hofstra University",
    domain: "hofstra.edu",
    value: "Hofstra University",
  },
  {
    label: "Hudson Valley Community College",
    domain: "hvcc.edu",
    value: "Hudson Valley Community College",
  },
  {
    label: "Hobart and William Smith Colleges",
    domain: "hws.edu",
    value: "Hobart and William Smith Colleges",
  },
  {
    label: "Institute of Design and Construction",
    domain: "idc.edu",
    value: "Institute of Design and Construction",
  },
  { label: "Iona College", domain: "iona.edu", value: "Iona College" },
  {
    label: "Ithaca College",
    domain: "ithaca.edu",
    value: "Ithaca College",
  },
  {
    label: "The Jewish Theological Seminary",
    domain: "jtsa.edu",
    value: "The Jewish Theological Seminary",
  },
  {
    label: "The Juilliard School",
    domain: "juilliard.edu",
    value: "The Juilliard School",
  },
  { label: "Keuka College", domain: "keuka.edu", value: "Keuka College" },
  {
    label: "Lehman College",
    domain: "lehman.edu",
    value: "Lehman College",
  },
  { label: "Le Moyne", domain: "lemoyne.edu", value: "Le Moyne" },
  {
    label: "Lim College",
    domain: "limcollege.edu",
    value: "Lim College",
  },
  {
    label: "Long Island University",
    domain: "liu.edu",
    value: "Long Island University",
  },
  {
    label: "Manhattan College",
    domain: "manhattan.edu",
    value: "Manhattan College",
  },
  {
    label: "Marist College",
    domain: "marist.edu",
    value: "Marist College",
  },
  {
    label: "Metropolitan College of New York",
    domain: "mcny.edu",
    value: "Metropolitan College of New York",
  },
  { label: "Mercy College", domain: "mercy.edu", value: "Mercy College" },
  {
    label: "Marymount Manhattan College",
    domain: "mmm.edu",
    value: "Marymount Manhattan College",
  },
  {
    label: "Monroe Community College",
    domain: "monroecc.edu",
    value: "Monroe Community College",
  },
  {
    label: "Morrisville State College",
    domain: "morrisville.edu",
    value: "Morrisville State College",
  },
  {
    label: "Mount Saint Marry College",
    domain: "msmc.edu",
    value: "Mount Saint Marry College",
  },
  {
    label: "Icahn School of Medicine at Mount Sinai",
    domain: "mssm.edu",
    value: "Icahn School of Medicine at Mount Sinai",
  },
  {
    label: "Manhattanville College",
    domain: "mville.edu",
    value: "Manhattanville College",
  },
  {
    label: "Nazareth College",
    domain: "naz.edu",
    value: "Nazareth College",
  },
  {
    label: "Nassau Community College",
    domain: "ncc.edu",
    value: "Nassau Community College",
  },
  { label: "New Paltz", domain: "newpaltz.edu", value: "New Paltz" },
  {
    label: "The New School",
    domain: "newschool.edu",
    value: "The New School",
  },
  {
    label: "Niagara University",
    domain: "niagara.edu",
    value: "Niagara University",
  },
  { label: "Nyack College", domain: "nyack.edu", value: "Nyack College" },
  {
    label: "New York College of Health Professions",
    domain: "nycollege.edu",
    value: "New York College of Health Professions",
  },
  {
    label: "New York Film Academy",
    domain: "nyfa.edu",
    value: "New York Film Academy",
  },
  {
    label: "New York Institute of Technology",
    domain: "nyit.edu",
    value: "New York Institute of Technology",
  },
  {
    label: "New York University",
    domain: "nyu.edu",
    value: "New York University",
  },
  {
    label: "State University of New York at Oneonta",
    domain: "oneonta.edu",
    value: "State University of New York at Oneonta",
  },
  {
    label: "State University of New York at Oswego",
    domain: "oswego.edu",
    value: "State University of New York at Oswego",
  },
  {
    label: "Pace University",
    domain: "pace.edu",
    value: "Pace University",
  },
  {
    label: "Paul Smith's College",
    domain: "paulsmiths.edu",
    value: "Paul Smith's College",
  },
  {
    label: "State University of New York at Plattsburgh",
    domain: "plattsburgh.edu",
    value: "State University of New York at Plattsburgh",
  },
  {
    label: "State University of New York at Potsdam",
    domain: "potsdam.edu",
    value: "State University of New York at Potsdam",
  },
  {
    label: "Pratt Institute",
    domain: "pratt.edu",
    value: "Pratt Institute",
  },
  {
    label: "Purchase College",
    domain: "purchase.edu",
    value: "Purchase College",
  },
  {
    label: "Rochester Institute of Technology",
    domain: "rit.edu",
    value: "Rochester Institute of Technology",
  },
  {
    label: "Roberts Wesleyan College",
    domain: "roberts.edu",
    value: "Roberts Wesleyan College",
  },
  {
    label: "University of Rochester",
    domain: "rochester.edu",
    value: "University of Rochester",
  },
  {
    label: "Rensselaer Polytechnic Institute",
    domain: "rpi.edu",
    value: "Rensselaer Polytechnic Institute",
  },
  {
    label: "The Sages Colleges",
    domain: "sage.edu",
    value: "The Sages Colleges",
  },
  {
    label: "Saint Bonaventure University",
    domain: "sbu.edu",
    value: "Saint Bonaventure University",
  },
  {
    label: "The New York Conservatory for Dramatic Arts",
    domain: "sft.edu",
    value: "The New York Conservatory for Dramatic Arts",
  },
  { label: "Siena College", domain: "siena.edu", value: "Siena College" },
  {
    label: "Saint John Fisher College",
    domain: "sjfc.edu",
    value: "Saint John Fisher College",
  },
  {
    label: "Skidmore College",
    domain: "skidmore.edu",
    value: "Skidmore College",
  },
  {
    label: "Sarah Lawrence College",
    domain: "slc.edu",
    value: "Sarah Lawrence College",
  },
  {
    label: "Saint Bernard's School of Theology and Ministry",
    domain: "stbernards.edu",
    value: "Saint Bernard's School of Theology and Ministry",
  },
  {
    label: "Saint John's University",
    domain: "stjohns.edu",
    value: "Saint John's University",
  },
  {
    label: "Saint Lawrence University",
    domain: "stlawu.edu",
    value: "Saint Lawrence University",
  },
  {
    label: "Stony Brook University",
    domain: "stonybrook.edu",
    value: "Stony Brook University",
  },
  {
    label: "Stony Brook Medicine",
    domain: "stonybrookmedicine.edu",
    value: "Stony Brook Medicine",
  },
  {
    label: "The College of Saint Rose",
    domain: "strose.edu",
    value: "The College of Saint Rose",
  },
  {
    label: "State University of New York",
    domain: "suny.edu",
    value: "State University of New York",
  },
  {
    label: "State University of New York at Adirondack",
    domain: "sunyacc.edu",
    value: "State University of New York at Adirondack",
  },
  {
    label: "State University of New Institute of Technology",
    domain: "sunyit.edu",
    value: "State University of New Institute of Technology",
  },
  {
    label: "Jamestown Community College",
    domain: "sunyjcc.edu",
    value: "Jamestown Community College",
  },
  {
    label: "Orange County Community College",
    domain: "sunyorange.edu",
    value: "Orange County Community College",
  },
  {
    label: "Rockland Community College",
    domain: "sunyrockland.edu",
    value: "Rockland Community College",
  },
  {
    label: "Suffolk County Community College",
    domain: "sunysuffolk.edu",
    value: "Suffolk County Community College",
  },
  {
    label: "Westchester Community College",
    domain: "sunywcc.edu",
    value: "Westchester Community College",
  },
  {
    label: "Syracuse University",
    domain: "syr.edu",
    value: "Syracuse University",
  },
  {
    label: "Tompkins Cortland Community College",
    domain: "tc3.edu",
    value: "Tompkins Cortland Community College",
  },
  {
    label: "The Touro College and University System",
    domain: "touro.edu",
    value: "The Touro College and University System",
  },
  {
    label: "Touro College Jacob D. Fuchsberg Law Center",
    domain: "tourolaw.edu",
    value: "Touro College Jacob D. Fuchsberg Law Center",
  },
  { label: "Union College", domain: "union.edu", value: "Union College" },
  {
    label: "Upstate Medical University",
    domain: "upstate.edu",
    value: "Upstate Medical University",
  },
  {
    label: "United States Military Academy West Point",
    domain: "usma.edu",
    value: "United States Military Academy West Point",
  },
  { label: "Utica College", domain: "utica.edu", value: "Utica College" },
  {
    label: "Vassar College",
    domain: "vassar.edu",
    value: "Vassar College",
  },
  {
    label: "Wagner College",
    domain: "wagner.edu",
    value: "Wagner College",
  },
  { label: "Wells College", domain: "wells.edu", value: "Wells College" },
  {
    label: "Yeshiva University",
    domain: "yu.edu",
    value: "Yeshiva University",
  },
  {
    label: "Canton State University of New York",
    domain: "canton.edu",
    value: "Canton State University of New York",
  },
  {
    label: "Excelsior College",
    domain: "excelsior.edu",
    value: "Excelsior College",
  },
  {
    label: "Culinary Institute of America",
    domain: "ciachef.edu",
    value: "Culinary Institute of America",
  },
  {
    label: "Ashland University",
    domain: "ashland.edu",
    value: "Ashland University",
  },
  {
    label: "Aultman College of Nursing and Health Sciences",
    domain: "aultmancollege.edu",
    value: "Aultman College of Nursing and Health Sciences",
  },
  {
    label: "Belmont College",
    domain: "belmontcollege.edu",
    value: "Belmont College",
  },
  {
    label: "Bowling Green State University",
    domain: "bgsu.edu",
    value: "Bowling Green State University",
  },
  {
    label: "Baldwin Wallace University",
    domain: "bw.edu",
    value: "Baldwin Wallace University",
  },
  {
    label: "Capital University",
    domain: "capital.edu",
    value: "Capital University",
  },
  {
    label: "Case Western Reserve University",
    domain: "case.edu",
    value: "Case Western Reserve University",
  },
  {
    label: "Cincinnati College of Mortuary Science",
    domain: "ccms.edu",
    value: "Cincinnati College of Mortuary Science",
  },
  {
    label: "Cedarville University",
    domain: "cedarville.edu",
    value: "Cedarville University",
  },
  {
    label: "Central State University",
    domain: "centralstate.edu",
    value: "Central State University",
  },
  {
    label: "Cincinnati State Technical and Community College",
    domain: "cincinnatistate.edu",
    value: "Cincinnati State Technical and Community College",
  },
  {
    label: "Central Ohio Technical College",
    domain: "cotc.edu",
    value: "Central Ohio Technical College",
  },
  {
    label: "Columbus State Community College",
    domain: "cscc.edu",
    value: "Columbus State Community College",
  },
  {
    label: "Cleveland State University",
    domain: "csuohio.edu",
    value: "Cleveland State University",
  },
  {
    label: "Western Reserve University",
    domain: "cwru.edu",
    value: "Western Reserve University",
  },
  {
    label: "Defiance College",
    domain: "defiance.edu",
    value: "Defiance College",
  },
  {
    label: "Denison University",
    domain: "denison.edu",
    value: "Denison University",
  },
  {
    label: "The University of Findlay",
    domain: "findlay.edu",
    value: "The University of Findlay",
  },
  {
    label: "Franklin University",
    domain: "franklin.edu",
    value: "Franklin University",
  },
  {
    label: "Heidelberg University",
    domain: "heidelberg.edu",
    value: "Heidelberg University",
  },
  { label: "Hiram College", domain: "hiram.edu", value: "Hiram College" },
  {
    label: "Hocking College",
    domain: "hocking.edu",
    value: "Hocking College",
  },
  {
    label: "John Carroll University",
    domain: "jcu.edu",
    value: "John Carroll University",
  },
  {
    label: "Kettering College",
    domain: "kc.edu",
    value: "Kettering College",
  },
  {
    label: "Kent State University",
    domain: "kent.edu",
    value: "Kent State University",
  },
  {
    label: "Kenyon College",
    domain: "kenyon.edu",
    value: "Kenyon College",
  },
  {
    label: "Lakeland Community College",
    domain: "lakelandcc.edu",
    value: "Lakeland Community College",
  },
  {
    label: "Lima Central Catholic High School",
    domain: "lcchs.edu",
    value: "Lima Central Catholic High School",
  },
  {
    label: "Lorain County Community College",
    domain: "lorainccc.edu",
    value: "Lorain County Community College",
  },
  {
    label: "Malone University",
    domain: "malone.edu",
    value: "Malone University",
  },
  {
    label: "Marietta College",
    domain: "marietta.edu",
    value: "Marietta College",
  },
  {
    label: "Miami University",
    domain: "miamioh.edu",
    value: "Miami University",
  },
  {
    label: "University of Mount Union",
    domain: "mountunion.edu",
    value: "University of Mount Union",
  },
  {
    label: "Mount Saint Joseph University",
    domain: "msj.edu",
    value: "Mount Saint Joseph University",
  },
  {
    label: "Muskingum University",
    domain: "muskingum.edu",
    value: "Muskingum University",
  },
  {
    label: "Mount Vernon Nazarene University",
    domain: "mvnu.edu",
    value: "Mount Vernon Nazarene University",
  },
  {
    label: "North Central State College",
    domain: "ncstatecollege.edu",
    value: "North Central State College",
  },
  {
    label: "Oberlin College and Conservatory",
    domain: "oberlin.edu",
    value: "Oberlin College and Conservatory",
  },
  {
    label: "Ohio University",
    domain: "ohio.edu",
    value: "Ohio University",
  },
  {
    label: "Ohio Dominican University",
    domain: "ohiodominican.edu",
    value: "Ohio Dominican University",
  },
  {
    label: "Ohio State University",
    domain: "osu.edu",
    value: "Ohio State University",
  },
  {
    label: "Ohio Northern University",
    domain: "onu.edu",
    value: "Ohio Northern University",
  },
  {
    label: "Otterbein University",
    domain: "otterbein.edu",
    value: "Otterbein University",
  },
  {
    label: "Owens Community College",
    domain: "owens.edu",
    value: "Owens Community College",
  },
  {
    label: "Ohio Wesleyan University",
    domain: "owu.edu",
    value: "Ohio Wesleyan University",
  },
  {
    label: "University of Rio Grande",
    domain: "rio.edu",
    value: "University of Rio Grande",
  },
  {
    label: "Shawnee State University",
    domain: "shawnee.edu",
    value: "Shawnee State University",
  },
  {
    label: "Sinclair Community College",
    domain: "sinclair.edu",
    value: "Sinclair Community College",
  },
  {
    label: "Tiffin University",
    domain: "tiffin.edu",
    value: "Tiffin University",
  },
  {
    label: "Cuyahoga Community College",
    domain: "tri-c.edu",
    value: "Cuyahoga Community College",
  },
  {
    label: "The University of Akron",
    domain: "uakron.edu",
    value: "The University of Akron",
  },
  {
    label: "University of Cincinnati",
    domain: "uc.edu",
    value: "University of Cincinnati",
  },
  {
    label: "University of Cincinnati Blue Ash",
    domain: "ucblueash.edu",
    value: "University of Cincinnati Blue Ash",
  },
  {
    label: "University of Cincinnati Clermont",
    domain: "ucclermont.edu",
    value: "University of Cincinnati Clermont",
  },
  {
    label: "University of Dayton",
    domain: "udayton.edu",
    value: "University of Dayton",
  },
  {
    label: "Urbana University",
    domain: "urbana.edu",
    value: "Urbana University",
  },
  {
    label: "Ursuline College",
    domain: "ursuline.edu",
    value: "Ursuline College",
  },
  {
    label: "University of Toledo",
    domain: "utoledo.edu",
    value: "University of Toledo",
  },
  {
    label: "Walsh University",
    domain: "walsh.edu",
    value: "Walsh University",
  },
  {
    label: "Wilmington College",
    domain: "wilmington.edu",
    value: "Wilmington College",
  },
  {
    label: "Wittenberg University",
    domain: "wittenberg.edu",
    value: "Wittenberg University",
  },
  {
    label: "The College of Wooster",
    domain: "wooster.edu",
    value: "The College of Wooster",
  },
  {
    label: "Wright State University",
    domain: "wright.edu",
    value: "Wright State University",
  },
  {
    label: "Xavier University",
    domain: "xavier.edu",
    value: "Xavier University",
  },
  {
    label: "Youngstown State University",
    domain: "ysu.edu",
    value: "Youngstown State University",
  },
  {
    label: "Cameron University",
    domain: "cameron.edu",
    value: "Cameron University",
  },
  {
    label: "Connors State College",
    domain: "connorsstate.edu",
    value: "Connors State College",
  },
  {
    label: "East Central University",
    domain: "ecok.edu",
    value: "East Central University",
  },
  {
    label: "Langston University",
    domain: "langston.edu",
    value: "Langston University",
  },
  {
    label: "Mid-America Christian University",
    domain: "macu.edu",
    value: "Mid-America Christian University",
  },
  {
    label: "Meridian Technology Center",
    domain: "meridiantech.edu",
    value: "Meridian Technology Center",
  },
  {
    label: "Northeastern State University",
    domain: "nsuok.edu",
    value: "Northeastern State University",
  },
  {
    label: "Northwestern Oklahoma State University",
    domain: "nwosu.edu",
    value: "Northwestern Oklahoma State University",
  },
  {
    label: "Oklahoma Christian University",
    domain: "oc.edu",
    value: "Oklahoma Christian University",
  },
  {
    label: "Oklahoma City Community College",
    domain: "occc.edu",
    value: "Oklahoma City Community College",
  },
  {
    label: "Oklahoma City University",
    domain: "okcu.edu",
    value: "Oklahoma City University",
  },
  {
    label: "Oral Roberts University",
    domain: "oru.edu",
    value: "Oral Roberts University",
  },
  {
    label: "Oklahoma State University Institue of Technology",
    domain: "osuit.edu",
    value: "Oklahoma State University Institue of Technology",
  },
  {
    label: "Oklahoma State University",
    domain: "osuokc.edu",
    value: "Oklahoma State University",
  },
  {
    label: "The University of Oklahoma",
    domain: "ou.edu",
    value: "The University of Oklahoma",
  },
  {
    label: "The University of Oklahoma Health Sciences Center",
    domain: "ouhsc.edu",
    value: "The University of Oklahoma Health Sciences Center",
  },
  {
    label: "Rose State College",
    domain: "rose.edu",
    value: "Rose State College",
  },
  {
    label: "Rogers State University",
    domain: "rsu.edu",
    value: "Rogers State University",
  },
  {
    label: "Southeastern Oklahoma State University",
    domain: "se.edu",
    value: "Southeastern Oklahoma State University",
  },
  {
    label: "Southwestern Christian University",
    domain: "swcu.edu",
    value: "Southwestern Christian University",
  },
  {
    label: "Southwestern Oklahoma State Univeristy",
    domain: "swosu.edu",
    value: "Southwestern Oklahoma State Univeristy",
  },
  {
    label: "Tulsa Community College",
    domain: "tulsacc.edu",
    value: "Tulsa Community College",
  },
  {
    label: "University of Central Oklahoma",
    domain: "uco.edu",
    value: "University of Central Oklahoma",
  },
  {
    label: "University of Science and Arts of Oklahoma",
    domain: "usao.edu",
    value: "University of Science and Arts of Oklahoma",
  },
  {
    label: "University of Tulsa",
    domain: "utulsa.edu",
    value: "University of Tulsa",
  },
  {
    label: "Western Oklahoma State College",
    domain: "wosc.edu",
    value: "Western Oklahoma State College",
  },
  {
    label: "Blue Mountain Community College",
    domain: "bluecc.edu",
    value: "Blue Mountain Community College",
  },
  {
    label: "Clatsop Community College",
    domain: "clatsopcc.edu",
    value: "Clatsop Community College",
  },
  {
    label: "Central Oregon Community College",
    domain: "cocc.edu",
    value: "Central Oregon Community College",
  },
  {
    label: "Columbia School of English",
    domain: "cs.edu",
    value: "Columbia School of English",
  },
  {
    label: "Concordia University Portland Oregon",
    domain: "cu-portland.edu",
    value: "Concordia University Portland Oregon",
  },
  {
    label: "Eastern Oregon University",
    domain: "eou.edu",
    value: "Eastern Oregon University",
  },
  {
    label: "George Fox University",
    domain: "georgefox.edu",
    value: "George Fox University",
  },
  {
    label: "Lane Community College",
    domain: "lanecc.edu",
    value: "Lane Community College",
  },
  {
    label: "Lewis and Clark",
    domain: "lclark.edu",
    value: "Lewis and Clark",
  },
  {
    label: "Linfield College",
    domain: "linfield.edu",
    value: "Linfield College",
  },
  {
    label: "Linn-Benton Community College",
    domain: "linnbenton.edu",
    value: "Linn-Benton Community College",
  },
  {
    label: "Marylhurst University",
    domain: "marylhurst.edu",
    value: "Marylhurst University",
  },
  {
    label: "National College of Natural Medicine",
    domain: "ncnm.edu",
    value: "National College of Natural Medicine",
  },
  {
    label: "Oregon Health and Science University",
    domain: "ohsu.edu",
    value: "Oregon Health and Science University",
  },
  {
    label: "Oregon State University",
    domain: "oregonstate.edu",
    value: "Oregon State University",
  },
  {
    label: "Pacific University Oregon",
    domain: "pacificu.edu",
    value: "Pacific University Oregon",
  },
  {
    label: "Portland Community College",
    domain: "pcc.edu",
    value: "Portland Community College",
  },
  {
    label: "Portland State University",
    domain: "pdx.edu",
    value: "Portland State University",
  },
  { label: "Reed College", domain: "reed.edu", value: "Reed College" },
  {
    label: "Rogue Community College",
    domain: "roguecc.edu",
    value: "Rogue Community College",
  },
  {
    label: "Southwestern Oregon Community College",
    domain: "socc.edu",
    value: "Southwestern Oregon Community College",
  },
  {
    label: "Southern Oregon University",
    domain: "sou.edu",
    value: "Southern Oregon University",
  },
  {
    label: "University of Oregon",
    domain: "uoregon.edu",
    value: "University of Oregon",
  },
  {
    label: "University of Portland",
    domain: "up.edu",
    value: "University of Portland",
  },
  {
    label: "Warner Pacific College",
    domain: "warnerpacific.edu",
    value: "Warner Pacific College",
  },
  {
    label: "Willamette University",
    domain: "willamette.edu",
    value: "Willamette University",
  },
  {
    label: "Western Oregon University",
    domain: "wou.edu",
    value: "Western Oregon University",
  },
  {
    label: "Allegheny College",
    domain: "allegheny.edu",
    value: "Allegheny College",
  },
  {
    label: "Alvernia University",
    domain: "alvernia.edu",
    value: "Alvernia University",
  },
  {
    label: "Arcadia University",
    domain: "arcadia.edu",
    value: "Arcadia University",
  },
  {
    label: "Baptist Bible College & Seminary",
    domain: "bbc.edu",
    value: "Baptist Bible College & Seminary",
  },
  {
    label: "Butler County Community College",
    domain: "bc3.edu",
    value: "Butler County Community College",
  },
  {
    label: "Bloomsburg University",
    domain: "bloomu.edu",
    value: "Bloomsburg University",
  },
  {
    label: "Bryn Mawr College",
    domain: "brynmawr.edu",
    value: "Bryn Mawr College",
  },
  {
    label: "Bucknell University",
    domain: "bucknell.edu",
    value: "Bucknell University",
  },
  {
    label: "Bucks County Community College",
    domain: "bucks.edu",
    value: "Bucks County Community College",
  },
  {
    label: "California University of Pennsylvania",
    domain: "calu.edu",
    value: "California University of Pennsylvania",
  },
  {
    label: "Carlow University",
    domain: "carlow.edu",
    value: "Carlow University",
  },
  {
    label: "Community College of Allegheny County",
    domain: "ccac.edu",
    value: "Community College of Allegheny County",
  },
  {
    label: "Community College of Philadelphia",
    domain: "ccp.edu",
    value: "Community College of Philadelphia",
  },
  {
    label: "Cedar Crest College",
    domain: "cedarcrest.edu",
    value: "Cedar Crest College",
  },
  {
    label: "Chatham University",
    domain: "chatham.edu",
    value: "Chatham University",
  },
  {
    label: "Chestnut Hill College",
    domain: "chc.edu",
    value: "Chestnut Hill College",
  },
  {
    label: "Clarion University",
    domain: "clarion.edu",
    value: "Clarion University",
  },
  {
    label: "Carnegie Mellon University",
    domain: "cmu.edu",
    value: "Carnegie Mellon University",
  },
  {
    label: "Delaware County Community College",
    domain: "dccc.edu",
    value: "Delaware County Community College",
  },
  {
    label: "Delaware Valley College",
    domain: "delval.edu",
    value: "Delaware Valley College",
  },
  {
    label: "Dickinson College",
    domain: "dickinson.edu",
    value: "Dickinson College",
  },
  {
    label: "Drexel University",
    domain: "drexel.edu",
    value: "Drexel University",
  },
  {
    label: "Drexel University College of Medicine",
    domain: "drexelmed.edu",
    value: "Drexel University College of Medicine",
  },
  {
    label: "Duquesne University",
    domain: "duq.edu",
    value: "Duquesne University",
  },
  {
    label: "Eastern University",
    domain: "eastern.edu",
    value: "Eastern University",
  },
  {
    label: "Edinboro University",
    domain: "edinboro.edu",
    value: "Edinboro University",
  },
  {
    label: "Elizabethtown College",
    domain: "etown.edu",
    value: "Elizabethtown College",
  },
  {
    label: "Evangelical Seminary",
    domain: "evangelical.edu",
    value: "Evangelical Seminary",
  },
  {
    label: "Franklin & Marshall College",
    domain: "fandm.edu",
    value: "Franklin & Marshall College",
  },
  {
    label: "Saint Francis University",
    domain: "francis.edu",
    value: "Saint Francis University",
  },
  {
    label: "Grove City College",
    domain: "gcc.edu",
    value: "Grove City College",
  },
  {
    label: "Geneva College",
    domain: "geneva.edu",
    value: "Geneva College",
  },
  {
    label: "Gettysburg College",
    domain: "gettysburg.edu",
    value: "Gettysburg College",
  },
  {
    label: "Harrisburg Area Community College",
    domain: "hacc.edu",
    value: "Harrisburg Area Community College",
  },
  {
    label: "Haverford College",
    domain: "haverford.edu",
    value: "Haverford College",
  },
  {
    label: "Holy Family University",
    domain: "holyfamily.edu",
    value: "Holy Family University",
  },
  {
    label: "Indiana University of Pennsylvania",
    domain: "iup.edu",
    value: "Indiana University of Pennsylvania",
  },
  {
    label: "Thomas Jefferson University",
    domain: "jefferson.edu",
    value: "Thomas Jefferson University",
  },
  {
    label: "Juniata College",
    domain: "juniata.edu",
    value: "Juniata College",
  },
  {
    label: "Keystone College",
    domain: "keystone.edu",
    value: "Keystone College",
  },
  {
    label: "King's College",
    domain: "kings.edu",
    value: "King's College",
  },
  {
    label: "Kutztown University",
    domain: "kutztown.edu",
    value: "Kutztown University",
  },
  {
    label: "Lackawanna College",
    domain: "lackawanna.edu",
    value: "Lackawanna College",
  },
  {
    label: "Lafayette College",
    domain: "lafayette.edu",
    value: "Lafayette College",
  },
  {
    label: "Lancaster County Career and Technology Center",
    domain: "lancasterctc.edu",
    value: "Lancaster County Career and Technology Center",
  },
  {
    label: "Lancaster Theological Seminary",
    domain: "lancasterseminary.edu",
    value: "Lancaster Theological Seminary",
  },
  {
    label: "La Roche College",
    domain: "laroche.edu",
    value: "La Roche College",
  },
  {
    label: "La Salle University",
    domain: "lasalle.edu",
    value: "La Salle University",
  },
  {
    label: "Lehigh Carbon Community College",
    domain: "lccc.edu",
    value: "Lehigh Carbon Community College",
  },
  {
    label: "Lake Erie College of Osteopathic Medicine",
    domain: "lecom.edu",
    value: "Lake Erie College of Osteopathic Medicine",
  },
  {
    label: "Lehigh University",
    domain: "lehigh.edu",
    value: "Lehigh University",
  },
  {
    label: "Lock Haven University",
    domain: "lhup.edu",
    value: "Lock Haven University",
  },
  {
    label: "The Lincoln University",
    domain: "lincoln.edu",
    value: "The Lincoln University",
  },
  {
    label: "Lansdale School of Business",
    domain: "lsb.edu",
    value: "Lansdale School of Business",
  },
  {
    label: "Lebanon Valley College",
    domain: "lvc.edu",
    value: "Lebanon Valley College",
  },
  {
    label: "Lycoming College",
    domain: "lycoming.edu",
    value: "Lycoming College",
  },
  { label: "Manor College", domain: "manor.edu", value: "Manor College" },
  {
    label: "Mansfield University",
    domain: "mansfield.edu",
    value: "Mansfield University",
  },
  {
    label: "Marywood University",
    domain: "marywood.edu",
    value: "Marywood University",
  },
  {
    label: "Montgomery County Community College",
    domain: "mc3.edu",
    value: "Montgomery County Community College",
  },
  {
    label: "Mercyhurst University",
    domain: "mercyhurst.edu",
    value: "Mercyhurst University",
  },
  {
    label: "Messiah College",
    domain: "messiah.edu",
    value: "Messiah College",
  },
  {
    label: "Millersville University",
    domain: "millersville.edu",
    value: "Millersville University",
  },
  {
    label: "Misericordia University",
    domain: "misericordia.edu",
    value: "Misericordia University",
  },
  {
    label: "Moravian College",
    domain: "moravian.edu",
    value: "Moravian College",
  },
  {
    label: "Mount Aloysius College",
    domain: "mtaloy.edu",
    value: "Mount Aloysius College",
  },
  {
    label: "Muhlenberg College",
    domain: "muhlenberg.edu",
    value: "Muhlenberg College",
  },
  {
    label: "Neumann University",
    domain: "neumann.edu",
    value: "Neumann University",
  },
  {
    label: "Northampton Community College",
    domain: "northampton.edu",
    value: "Northampton Community College",
  },
  {
    label: "Pennsylvania Gunsmith School",
    domain: "pagunsmith.edu",
    value: "Pennsylvania Gunsmith School",
  },
  {
    label: "Philadelphia College of Osteopathic Medicine",
    domain: "pcom.edu",
    value: "Philadelphia College of Osteopathic Medicine",
  },
  {
    label: "Pennsylvania College of Technology",
    domain: "pct.edu",
    value: "Pennsylvania College of Technology",
  },
  {
    label: "Philadelphia University",
    domain: "philau.edu",
    value: "Philadelphia University",
  },
  {
    label: "University of Pittsburgh",
    domain: "pitt.edu",
    value: "University of Pittsburgh",
  },
  {
    label: "Penn State University",
    domain: "psu.edu",
    value: "Penn State University",
  },
  {
    label: "Reading Area Community College",
    domain: "racc.edu",
    value: "Reading Area Community College",
  },
  {
    label: "Robert Morris University",
    domain: "rmu.edu",
    value: "Robert Morris University",
  },
  {
    label: "Saint Vincent Seminary",
    domain: "saintvincentseminary.edu",
    value: "Saint Vincent Seminary",
  },
  {
    label: "Salus University",
    domain: "salus.edu",
    value: "Salus University",
  },
  {
    label: "The University of Scranton",
    domain: "scranton.edu",
    value: "The University of Scranton",
  },
  {
    label: "Shippensburg University",
    domain: "ship.edu",
    value: "Shippensburg University",
  },
  {
    label: "Saint Joseph's University",
    domain: "sju.edu",
    value: "Saint Joseph's University",
  },
  {
    label: "Slippery Rock University",
    domain: "sru.edu",
    value: "Slippery Rock University",
  },
  {
    label: "Saint Vincent College",
    domain: "stvincent.edu",
    value: "Saint Vincent College",
  },
  {
    label: "Susquehanna University",
    domain: "susqu.edu",
    value: "Susquehanna University",
  },
  {
    label: "Swarthmore College",
    domain: "swarthmor.edu",
    value: "Swarthmore College",
  },
  {
    label: "Temple University",
    domain: "temple.edu",
    value: "Temple University",
  },
  {
    label: "The American College of Financial Services",
    domain: "theamericancollege.edu",
    value: "The American College of Financial Services",
  },
  { label: "Thiel College", domain: "thiel.edu", value: "Thiel College" },
  {
    label: "University of the Arts",
    domain: "uarts.edu",
    value: "University of the Arts",
  },
  {
    label: "University of Pennsylvania",
    domain: "upenn.edu",
    value: "University of Pennsylvania",
  },
  {
    label: "University of the Sciences in Philadelphia",
    domain: "usciences.edu",
    value: "University of the Sciences in Philadelphia",
  },
  {
    label: "Valley Forge Christian College",
    domain: "vfcc.edu",
    value: "Valley Forge Christian College",
  },
  {
    label: "Villanova University",
    domain: "villanova.edu",
    value: "Villanova University",
  },
  {
    label: "Washington and Jefferson College",
    domain: "washjeff.edu",
    value: "Washington and Jefferson College",
  },
  {
    label: "Westmoreland County Community College",
    domain: "wccc.edu",
    value: "Westmoreland County Community College",
  },
  {
    label: "West Chester University",
    domain: "wcupa.edu",
    value: "West Chester University",
  },
  {
    label: "Westminster College",
    domain: "westminster.edu",
    value: "Westminster College",
  },
  {
    label: "Widener University",
    domain: "widener.edu",
    value: "Widener University",
  },
  {
    label: "Wilkes University",
    domain: "wilkes.edu",
    value: "Wilkes University",
  },
  {
    label: "Wilson College",
    domain: "wilson.edu",
    value: "Wilson College",
  },
  {
    label: "York College of Pennsylvania",
    domain: "ycp.edu",
    value: "York College of Pennsylvania",
  },
  {
    label: "Pennsylvania Academy of the Fine Arts",
    domain: "pafa.edu",
    value: "Pennsylvania Academy of the Fine Arts",
  },
  {
    label: "East Stroudsburg University",
    domain: "esu.edu",
    value: "East Stroudsburg University",
  },
  {
    label: "Ursinus College",
    domain: "ursinus.edu",
    value: "Ursinus College",
  },
  {
    label: "DeSales University",
    domain: "desales.edu",
    value: "DeSales University",
  },
  {
    label: "Lancaster Bible College",
    domain: "lbc.edu",
    value: "Lancaster Bible College",
  },
  {
    label: "Cheyney University of Pennsylvania",
    domain: "cheyney.edu",
    value: "Cheyney University of Pennsylvania",
  },
  {
    label: "Brown University",
    domain: "brown.edu",
    value: "Brown University",
  },
  {
    label: "Bryant University",
    domain: "bryant.edu",
    value: "Bryant University",
  },
  {
    label: "Community College of Rhode Island",
    domain: "ccri.edu",
    value: "Community College of Rhode Island",
  },
  {
    label: "Johnson and Wales University",
    domain: "jwu.edu",
    value: "Johnson and Wales University",
  },
  {
    label: "New England Institute of Technology",
    domain: "neit.edu",
    value: "New England Institute of Technology",
  },
  {
    label: "Providence College",
    domain: "providence.edu",
    value: "Providence College",
  },
  {
    label: "The University of Rhode Island",
    domain: "uri.edu",
    value: "The University of Rhode Island",
  },
  {
    label: "Rhode Island College",
    domain: "ric.edu",
    value: "Rhode Island College",
  },
  {
    label: "Rhose Island School of Design",
    domain: "risd.edu",
    value: "Rhose Island School of Design",
  },
  {
    label: "Roger Williams University",
    domain: "rwu.edu",
    value: "Roger Williams University",
  },
  {
    label: "University of Rhode Island",
    domain: "uri.edu",
    value: "University of Rhode Island",
  },
  {
    label: "Centura College",
    domain: "centura.edu",
    value: "Centura College",
  },
  {
    label: "The Citadel Military College of South Carolina",
    domain: "citadel.edu",
    value: "The Citadel Military College of South Carolina",
  },
  {
    label: "Columbia International University",
    domain: "ciu.edu",
    value: "Columbia International University",
  },
  {
    label: "Clemson University",
    domain: "clemson.edu",
    value: "Clemson University",
  },
  {
    label: "Coastal Carolina University",
    domain: "coastal.edu",
    value: "Coastal Carolina University",
  },
  {
    label: "College of Charleston",
    domain: "cofc.edu",
    value: "College of Charleston",
  },
  { label: "Coker College", domain: "coker.edu", value: "Coker College" },
  {
    label: "Columbia College South Carolina",
    domain: "columbiasc.edu",
    value: "Columbia College South Carolina",
  },
  {
    label: "Florence-Darlington Technical College",
    domain: "fdtc.edu",
    value: "Florence-Darlington Technical College",
  },
  {
    label: "Francis Marion University",
    domain: "fmarion.edu",
    value: "Francis Marion University",
  },
  {
    label: "Forrest College",
    domain: "forrestcollege.edu",
    value: "Forrest College",
  },
  {
    label: "Furman University",
    domain: "furman.edu",
    value: "Furman University",
  },
  {
    label: "Lander University",
    domain: "lander.edu",
    value: "Lander University",
  },
  {
    label: "Limestone College",
    domain: "limestone.edu",
    value: "Limestone College",
  },
  {
    label: "Midlands Technical College",
    domain: "midlandstech.edu",
    value: "Midlands Technical College",
  },
  {
    label: "Medical University of South Carolina",
    domain: "musc.edu",
    value: "Medical University of South Carolina",
  },
  {
    label: "Newberry College",
    domain: "newberry.edu",
    value: "Newberry College",
  },
  {
    label: "North Greenville University",
    domain: "ngu.edu",
    value: "North Greenville University",
  },
  {
    label: "Orangeburg-Calhoun Technical College",
    domain: "octech.edu",
    value: "Orangeburg-Calhoun Technical College",
  },
  {
    label: "Presbyterian College",
    domain: "presby.edu",
    value: "Presbyterian College",
  },
  {
    label: "University of South Carolina",
    domain: "sc.edu",
    value: "University of South Carolina",
  },
  {
    label: "South Carolina State University",
    domain: "scsu.edu",
    value: "South Carolina State University",
  },
  {
    label: "Sinte Gleska University",
    domain: "sintegleska.edu",
    value: "Sinte Gleska University",
  },
  {
    label: "Spartanburg Methodist College",
    domain: "smcsc.edu",
    value: "Spartanburg Methodist College",
  },
  {
    label: "TriCounty Technical College",
    domain: "tctc.edu",
    value: "TriCounty Technical College",
  },
  {
    label: "University of South Carolina Aiken",
    domain: "usca.edu",
    value: "University of South Carolina Aiken",
  },
  {
    label: "University of South Carolina Beaufort",
    domain: "uscb.edu",
    value: "University of South Carolina Beaufort",
  },
  {
    label: "University of South Carolina Upstate",
    domain: "uscupstate.edu",
    value: "University of South Carolina Upstate",
  },
  {
    label: "Winthrop University",
    domain: "winthrop.edu",
    value: "Winthrop University",
  },
  {
    label: "Wofford College",
    domain: "wofford.edu",
    value: "Wofford College",
  },
  {
    label: "York Technical College",
    domain: "yorktech.edu",
    value: "York Technical College",
  },
  {
    label: "Bob Jones University",
    domain: "bju.edu",
    value: "Bob Jones University",
  },
  {
    label: "Black Hills State University",
    domain: "bhsu.edu",
    value: "Black Hills State University",
  },
  {
    label: "Dakota State University",
    domain: "dsu.edu",
    value: "Dakota State University",
  },
  {
    label: "Dakota Wesleyan University",
    domain: "dwu.edu",
    value: "Dakota Wesleyan University",
  },
  {
    label: "Mount Marty College",
    domain: "mtmc.edu",
    value: "Mount Marty College",
  },
  {
    label: "Northern State University",
    domain: "northern.edu",
    value: "Northern State University",
  },
  {
    label: "South Dakota School of Mines and Technology",
    domain: "sdsmt.edu",
    value: "South Dakota School of Mines and Technology",
  },
  {
    label: "South Dakota State University",
    domain: "sdstate.edu",
    value: "South Dakota State University",
  },
  {
    label: "University of South Dakota",
    domain: "usd.edu",
    value: "University of South Dakota",
  },
  {
    label: "University of Sioux Falls",
    domain: "usioxfalls.edu",
    value: "University of Sioux Falls",
  },
  {
    label: "Western Dakota Technical Institute",
    domain: "wdt.edu",
    value: "Western Dakota Technical Institute",
  },
  {
    label: "Austin Peay State University",
    domain: "apsu.edu",
    value: "Austin Peay State University",
  },
  {
    label: "Belmont University",
    domain: "belmont.edu",
    value: "Belmont University",
  },
  { label: "Bryan College", domain: "bryan.edu", value: "Bryan College" },
  {
    label: "Christian Brothers University",
    domain: "cbu.edu",
    value: "Christian Brothers University",
  },
  {
    label: "Carson-Newman University",
    domain: "cn.edu",
    value: "Carson-Newman University",
  },
  {
    label: "Columbia State Community College",
    domain: "columbiastate.edu",
    value: "Columbia State Community College",
  },
  {
    label: "Cumberland University",
    domain: "cumberland.edu",
    value: "Cumberland University",
  },
  {
    label: "DeVry University",
    domain: "devry.edu",
    value: "DeVry University",
  },
  {
    label: "East Tennessee State University",
    domain: "etsu.edu",
    value: "East Tennessee State University",
  },
  {
    label: "Freed-Hardeman University",
    domain: "fhu.edu",
    value: "Freed-Hardeman University",
  },
  {
    label: "Fisk University",
    domain: "fisk.edu",
    value: "Fisk University",
  },
  {
    label: "Huntington College of Health Sciences",
    domain: "hchs.edu",
    value: "Huntington College of Health Sciences",
  },
  {
    label: "Hiwassee College",
    domain: "hiwassee.edu",
    value: "Hiwassee College",
  },
  {
    label: "Johnson University",
    domain: "johnsonu.edu",
    value: "Johnson University",
  },
  {
    label: "Jackson State Community College",
    domain: "jscc.edu",
    value: "Jackson State Community College",
  },
  {
    label: "Lee University",
    domain: "leeuniversity.edu",
    value: "Lee University",
  },
  {
    label: "Lincoln Memorial University",
    domain: "lmunet.edu",
    value: "Lincoln Memorial University",
  },
  {
    label: "The LeMoyne-Owen College",
    domain: "loc.edu",
    value: "The LeMoyne-Owen College",
  },
  {
    label: "The University of Memphis",
    domain: "memphis.edu",
    value: "The University of Memphis",
  },
  {
    label: "Milligan College",
    domain: "milligan.edu",
    value: "Milligan College",
  },
  {
    label: "Middle Tennessee State University",
    domain: "mtsu.edu",
    value: "Middle Tennessee State University",
  },
  {
    label: "Nashville State Community College",
    domain: "nscc.edu",
    value: "Nashville State Community College",
  },
  {
    label: "Pellissippi State Community College",
    domain: "pstcc.edu",
    value: "Pellissippi State Community College",
  },
  {
    label: "Rhodes College",
    domain: "rhodes.edu",
    value: "Rhodes College",
  },
  {
    label: "Roane State Community College",
    domain: "roanestate.edu",
    value: "Roane State Community College",
  },
  {
    label: "Southern College of Optometry",
    domain: "sco.edu",
    value: "Southern College of Optometry",
  },
  {
    label: "Sewanee The University of the South",
    domain: "sewanee.edu",
    value: "Sewanee The University of the South",
  },
  {
    label: "Southern Adventist University",
    domain: "southern.edu",
    value: "Southern Adventist University",
  },
  {
    label: "The University of Tennessee",
    domain: "tennessee.edu",
    value: "The University of Tennessee",
  },
  {
    label: "Southwest Tennessee Community College",
    domain: "tn.edu",
    value: "Southwest Tennessee Community College",
  },
  {
    label: "Tennessee State University",
    domain: "tnstate.edu",
    value: "Tennessee State University",
  },
  {
    label: "Tennessee Technological University",
    domain: "tntech.edu",
    value: "Tennessee Technological University",
  },
  {
    label: "Tennessee Wesleyan College",
    domain: "twcnet.edu",
    value: "Tennessee Wesleyan College",
  },
  {
    label: "University of Tennessee Chattanooga",
    domain: "utc.edu",
    value: "University of Tennessee Chattanooga",
  },
  {
    label: "University of Tennessee Health Science Center",
    domain: "uthsc.edu",
    value: "University of Tennessee Health Science Center",
  },
  {
    label: "University of Tennessee Knoxville",
    domain: "utk.edu",
    value: "University of Tennessee Knoxville",
  },
  {
    label: "University of Tennessee Martin",
    domain: "utm.edu",
    value: "University of Tennessee Martin",
  },
  {
    label: "University of Tennessee Space Institute",
    domain: "utsi.edu",
    value: "University of Tennessee Space Institute",
  },
  {
    label: "Union University",
    domain: "uu.edu",
    value: "Union University",
  },
  {
    label: "Vanderbilt University",
    domain: "vanderbilt.edu",
    value: "Vanderbilt University",
  },
  {
    label: "Volunteer State Community College",
    domain: "volstate.edu",
    value: "Volunteer State Community College",
  },
  {
    label: "King University",
    domain: "king.edu",
    value: "King University",
  },
  {
    label: "Abilene Christian University",
    domain: "acu.edu",
    value: "Abilene Christian University",
  },
  {
    label: "Alamo Colleges",
    domain: "alamo.edu",
    value: "Alamo Colleges",
  },
  {
    label: "Alvin Community College",
    domain: "alvincollege.edu",
    value: "Alvin Community College",
  },
  {
    label: "Angelo State University",
    domain: "angelo.edu",
    value: "Angelo State University",
  },
  {
    label: "Austin Community College District",
    domain: "austincc.edu",
    value: "Austin Community College District",
  },
  {
    label: "Austin College",
    domain: "austincollege.edu",
    value: "Austin College",
  },
  {
    label: "Austin Graduate School of Theology",
    domain: "austingrad.edu",
    value: "Austin Graduate School of Theology",
  },
  {
    label: "Baylor University",
    domain: "baylor.edu",
    value: "Baylor University",
  },
  { label: "Blinn College", domain: "blinn.edu", value: "Blinn College" },
  {
    label: "Brazosport College",
    domain: "brazosport.edu",
    value: "Brazosport College",
  },
  {
    label: "Brookhaven College",
    domain: "brookhavencollege.edu",
    value: "Brookhaven College",
  },
  {
    label: "Baptist Health System School of Health Professions",
    domain: "bshp.edu",
    value: "Baptist Health System School of Health Professions",
  },
  {
    label: "Career Point College",
    domain: "careerpointcollege.edu",
    value: "Career Point College",
  },
  {
    label: "Cedar Valley College",
    domain: "cedarvalleycollege.edu",
    value: "Cedar Valley College",
  },
  {
    label: "Coastal Bend College",
    domain: "coastalbend.edu",
    value: "Coastal Bend College",
  },
  {
    label: "College of the Mainland",
    domain: "com.edu",
    value: "College of the Mainland",
  },
  {
    label: "Concordia University Texas",
    domain: "concordia.edu",
    value: "Concordia University Texas",
  },
  {
    label: "Central Texas College",
    domain: "ctcd.edu",
    value: "Central Texas College",
  },
  {
    label: "Culinary Institute LeNotre",
    domain: "culinaryinstitute.edu",
    value: "Culinary Institute LeNotre",
  },
  {
    label: "Dallas Baptist University",
    domain: "dbu.edu",
    value: "Dallas Baptist University",
  },
  {
    label: "Del Mar College",
    domain: "delmar.edu",
    value: "Del Mar College",
  },
  {
    label: "Dallas Nursing Institute",
    domain: "dni.edu",
    value: "Dallas Nursing Institute",
  },
  {
    label: "El Paso Community College",
    domain: "epcc.edu",
    value: "El Paso Community College",
  },
  {
    label: "East Texas Baptist University",
    domain: "etbu.edu",
    value: "East Texas Baptist University",
  },
  {
    label: "Galen College of Nursing",
    domain: "galencollege.edu",
    value: "Galen College of Nursing",
  },
  {
    label: "Galveston College",
    domain: "gc.edu",
    value: "Galveston College",
  },
  {
    label: "Grayson College",
    domain: "grayson.edu",
    value: "Grayson College",
  },
  {
    label: "Houston Baptist University",
    domain: "hbu.edu",
    value: "Houston Baptist University",
  },
  {
    label: "Houston Community College",
    domain: "hccs.edu",
    value: "Houston Community College",
  },
  {
    label: "Hill College",
    domain: "hillcollege.edu",
    value: "Hill College",
  },
  {
    label: "Howard College",
    domain: "howardcollege.edu",
    value: "Howard College",
  },
  {
    label: "Howard Payne University",
    domain: "hputx.edu",
    value: "Howard Payne University",
  },
  {
    label: "Lamar University",
    domain: "lamar.edu",
    value: "Lamar University",
  },
  {
    label: "Lamar State College - Port Arthur",
    domain: "lamarpa.edu",
    value: "Lamar State College - Port Arthur",
  },
  {
    label: "Lubbock Christian University",
    domain: "lcu.edu",
    value: "Lubbock Christian University",
  },
  {
    label: "LeTourneau University",
    domain: "letu.edu",
    value: "LeTourneau University",
  },
  {
    label: "Lamar Institute of Technology",
    domain: "lit.edu",
    value: "Lamar Institute of Technology",
  },
  {
    label: "Lone Star College System",
    domain: "lonestar.edu",
    value: "Lone Star College System",
  },
  {
    label: "Lamar State College - Orange",
    domain: "lsco.edu",
    value: "Lamar State College - Orange",
  },
  {
    label: "McLennan Community College",
    domain: "mclennan.edu",
    value: "McLennan Community College",
  },
  {
    label: "McMurry University",
    domain: "mcm.edu",
    value: "McMurry University",
  },
  {
    label: "Midland College",
    domain: "midland.edu",
    value: "Midland College",
  },
  {
    label: "Midwestern State University",
    domain: "mwsu.edu",
    value: "Midwestern State University",
  },
  {
    label: "National American University",
    domain: "national.edu",
    value: "National American University",
  },
  {
    label: "North Central Texas College",
    domain: "nctc.edu",
    value: "North Central Texas College",
  },
  {
    label: "North American University",
    domain: "northamerican.edu",
    value: "North American University",
  },
  {
    label: "North Lake College",
    domain: "northlakecollege.edu",
    value: "North Lake College",
  },
  {
    label: "Odessa College",
    domain: "odessa.edu",
    value: "Odessa College",
  },
  {
    label: "Ogle School",
    domain: "ogleschool.edu",
    value: "Ogle School",
  },
  {
    label: "Our Lady of the Lake University",
    domain: "ollusa.edu",
    value: "Our Lady of the Lake University",
  },
  {
    label: "Panola College",
    domain: "panola.edu",
    value: "Panola College",
  },
  {
    label: "Patty Hands Shelton School of Nursing",
    domain: "phssn.edu",
    value: "Patty Hands Shelton School of Nursing",
  },
  {
    label: "Prairie View A&M University",
    domain: "pvamu.edu",
    value: "Prairie View A&M University",
  },
  {
    label: "Rice University",
    domain: "rice.edu",
    value: "Rice University",
  },
  {
    label: "Richland College",
    domain: "richlandcollege.edu",
    value: "Richland College",
  },
  {
    label: "San Jacinto College",
    domain: "sanjac.edu",
    value: "San Jacinto College",
  },
  {
    label: "Schreiner University",
    domain: "schreiner.edu",
    value: "Schreiner University",
  },
  {
    label: "Stephen F. Austin State University",
    domain: "sfasu.edu",
    value: "Stephen F. Austin State University",
  },
  {
    label: "Sam Houston State University",
    domain: "shsu.edu",
    value: "Sam Houston State University",
  },
  {
    label: "Southern Methodist University",
    domain: "smu.edu",
    value: "Southern Methodist University",
  },
  {
    label: "South Plains College",
    domain: "southplainscollege.edu",
    value: "South Plains College",
  },
  {
    label: "South Texas College",
    domain: "southtexascollege.htp",
    value: "South Texas College",
  },
  {
    label: "Southwestern University",
    domain: "southwestern.edu",
    value: "Southwestern University",
  },
  {
    label: "Seminary of the Southwest",
    domain: "ssw.edu",
    value: "Seminary of the Southwest",
  },
  {
    label: "South Texas College of Law",
    domain: "stcl.edu",
    value: "South Texas College of Law",
  },
  {
    label: "Saint Edward's University",
    domain: "stedwards.edu",
    value: "Saint Edward's University",
  },
  {
    label: "Saint Mary's University",
    domain: "stmarytx.edu",
    value: "Saint Mary's University",
  },
  {
    label: "University of Saint Thomas",
    domain: "stthom.edu",
    value: "University of Saint Thomas",
  },
  {
    label: "Texas A&M Health Science Center",
    domain: "tamhsc.edu",
    value: "Texas A&M Health Science Center",
  },
  {
    label: "Texas A&M International University",
    domain: "tamiu.edu",
    value: "Texas A&M International University",
  },
  {
    label: "Texas A&M University",
    domain: "tamu.edu",
    value: "Texas A&M University",
  },
  {
    label: "Texas A&M University Commerce",
    domain: "tamuc.edu",
    value: "Texas A&M University Commerce",
  },
  {
    label: "Texas A&M University Corpus Christi",
    domain: "tamucc.edu",
    value: "Texas A&M University Corpus Christi",
  },
  {
    label: "Texas A&M University Kingsville",
    domain: "tamuk.edu",
    value: "Texas A&M University Kingsville",
  },
  {
    label: "Texas A&M University Texarkana",
    domain: "tamut.edu",
    value: "Texas A&M University Texarkana",
  },
  {
    label: "Tarleton State University",
    domain: "tarleton.edu",
    value: "Tarleton State University",
  },
  {
    label: "Texas Christian University",
    domain: "tcu.edu",
    value: "Texas Christian University",
  },
  {
    label: "Temple College",
    domain: "templejc.edu",
    value: "Temple College",
  },
  {
    label: "Tyler Junior College",
    domain: "tjc.edu",
    value: "Tyler Junior College",
  },
  {
    label: "Trinity University",
    domain: "trinity.edu",
    value: "Trinity University",
  },
  {
    label: "Texas State Technical College",
    domain: "tstc.edu",
    value: "Texas State Technical College",
  },
  {
    label: "Texas Southern University",
    domain: "tsu.edu",
    value: "Texas Southern University",
  },
  {
    label: "Texas Tech University",
    domain: "ttu.edu",
    value: "Texas Tech University",
  },
  {
    label: "Texas Tech University Health Sciences Center",
    domain: "ttuhsc.edu",
    value: "Texas Tech University Health Sciences Center",
  },
  {
    label: "Texas Woman's University",
    domain: "twu.edu",
    value: "Texas Woman's University",
  },
  {
    label: "Texas Chiropractic College",
    domain: "txchiro.edu",
    value: "Texas Chiropractic College",
  },
  {
    label: "Texas State University",
    domain: "txstate.edu",
    value: "Texas State University",
  },
  {
    label: "University of Dallas",
    domain: "udallas.edu",
    value: "University of Dallas",
  },
  {
    label: "Universty of Houston",
    domain: "uh.edu",
    value: "Universty of Houston",
  },
  {
    label: "University of Houston Clear Lake",
    domain: "uhcl.edu",
    value: "University of Houston Clear Lake",
  },
  {
    label: "University of Houston Downtown",
    domain: "uhd.edu",
    value: "University of Houston Downtown",
  },
  {
    label: "University of the Incarnate Word",
    domain: "uiw.edu",
    value: "University of the Incarnate Word",
  },
  {
    label: "University of Mary Hardin-Baylor",
    domain: "umhb.edu",
    value: "University of Mary Hardin-Baylor",
  },
  {
    label: "University of North Texas",
    domain: "unt.edu",
    value: "University of North Texas",
  },
  {
    label: "University of North Texas Health Science Center",
    domain: "unthsc.edu",
    value: "University of North Texas Health Science Center",
  },
  {
    label: "University of Texas Arlington",
    domain: "uta.edu",
    value: "University of Texas Arlington",
  },
  {
    label: "University of Texas Brownsville",
    domain: "utb.edu",
    value: "University of Texas Brownsville",
  },
  {
    label: "University of Texas Dallas",
    domain: "utdallas.edu",
    value: "University of Texas Dallas",
  },
  {
    label: "University of Texas at El Paso",
    domain: "utep.edu",
    value: "University of Texas at El Paso",
  },
  {
    label: "University of Texas at Austin",
    domain: "utexas.edu",
    value: "University of Texas at Austin",
  },
  {
    label: "University of Texas Health Science Center Houston",
    domain: "uth.edu",
    value: "University of Texas Health Science Center Houston",
  },
  {
    label: "University of Texas Health Science Center San Antonio",
    domain: "uthscsa.edu",
    value: "University of Texas Health Science Center San Antonio",
  },
  {
    label: "University of Texas Medical Branch at Galveston",
    domain: "utmb.edu",
    value: "University of Texas Medical Branch at Galveston",
  },
  {
    label: "University of Texas Pan American",
    domain: "utpa.edu",
    value: "University of Texas Pan American",
  },
  {
    label: "University of Texas of the Permian Basin",
    domain: "utpb.edu",
    value: "University of Texas of the Permian Basin",
  },
  {
    label: "University of Texas at San Antonio",
    domain: "utsa.edu",
    value: "University of Texas at San Antonio",
  },
  {
    label: "University of Texas Southwestern Medical Center",
    domain: "utsouthwestern.edu",
    value: "University of Texas Southwestern Medical Center",
  },
  {
    label: "University of Texas at Tyler",
    domain: "uttyler.edu",
    value: "University of Texas at Tyler",
  },
  {
    label: "Valley Grande Institute",
    domain: "vgi.edu",
    value: "Valley Grande Institute",
  },
  {
    label: "Victoria College",
    domain: "victoriacollege.edu",
    value: "Victoria College",
  },
  {
    label: "Wayland Baptist University",
    domain: "wbu.edu",
    value: "Wayland Baptist University",
  },
  {
    label: "Weatherford College",
    domain: "wc.edu",
    value: "Weatherford College",
  },
  {
    label: "West Texas A&M University",
    domain: "wtamu.edu",
    value: "West Texas A&M University",
  },
  {
    label: "University of the Virgin Islands",
    domain: "uvi.edu",
    value: "University of the Virgin Islands",
  },
  {
    label: "Brigham Young University",
    domain: "byu.edu",
    value: "Brigham Young University",
  },
  {
    label: "Dixie State University",
    domain: "dixie.edu",
    value: "Dixie State University",
  },
  {
    label: "Salt Lake Community College",
    domain: "slcc.edu",
    value: "Salt Lake Community College",
  },
  { label: "Snow College", domain: "snow.edu", value: "Snow College" },
  {
    label: "Southern Utah University",
    domain: "suu.edu",
    value: "Southern Utah University",
  },
  {
    label: "Utah State University",
    domain: "usu.edu",
    value: "Utah State University",
  },
  {
    label: "University of Utah",
    domain: "utah.edu",
    value: "University of Utah",
  },
  {
    label: "Utah Valley University",
    domain: "uvu.edu",
    value: "Utah Valley University",
  },
  {
    label: "Weber State University",
    domain: "weber.edu",
    value: "Weber State University",
  },
  {
    label: "Western Governors University",
    domain: "www.wgu.edu/",
    value: "Western Governors University",
  },
  {
    label: "Averett University",
    domain: "averett.edu",
    value: "Averett University",
  },
  {
    label: "Christendom College",
    domain: "christendom.edu",
    value: "Christendom College",
  },
  {
    label: "Christopher Newport University",
    domain: "cnu.edu",
    value: "Christopher Newport University",
  },
  {
    label: "Dabney S. Lancaster Community College",
    domain: "dslcc.edu",
    value: "Dabney S. Lancaster Community College",
  },
  {
    label: "Eastern Mennonite University",
    domain: "emu.edu",
    value: "Eastern Mennonite University",
  },
  {
    label: "Eastern Virginia Medical School",
    domain: "evms.edu",
    value: "Eastern Virginia Medical School",
  },
  {
    label: "Ferrum College",
    domain: "ferrum.edu",
    value: "Ferrum College",
  },
  {
    label: "Germanna Community College",
    domain: "germanna.edu",
    value: "Germanna Community College",
  },
  {
    label: "George Mason University",
    domain: "gmu.edu",
    value: "George Mason University",
  },
  {
    label: "Hampden-Sydney College",
    domain: "hsc.edu",
    value: "Hampden-Sydney College",
  },
  {
    label: "The Institute for the Psychological Sciences",
    domain: "ipsciences.edu",
    value: "The Institute for the Psychological Sciences",
  },
  {
    label: "James Madison University",
    domain: "jmu.edu",
    value: "James Madison University",
  },
  {
    label: "Liberty University",
    domain: "liberty.edu",
    value: "Liberty University",
  },
  {
    label: "Longwood University",
    domain: "longwood.edu",
    value: "Longwood University",
  },
  {
    label: "Marymount University",
    domain: "marymount.edu",
    value: "Marymount University",
  },
  {
    label: "New River Community College",
    domain: "nr.edu",
    value: "New River Community College",
  },
  {
    label: "Norfolk State University",
    domain: "nsu.edu",
    value: "Norfolk State University",
  },
  {
    label: "Northern Virginia Community College",
    domain: "nvcc.edu",
    value: "Northern Virginia Community College",
  },
  {
    label: "Old Dominion University",
    domain: "odu.edu",
    value: "Old Dominion University",
  },
  {
    label: "Radford University",
    domain: "radford.edu",
    value: "Radford University",
  },
  {
    label: "Randolph College",
    domain: "randolphcollege.edu",
    value: "Randolph College",
  },
  {
    label: "Rappahannock Community College",
    domain: "rappahannock.edu",
    value: "Rappahannock Community College",
  },
  {
    label: "Regent University",
    domain: "regent.edu",
    value: "Regent University",
  },
  {
    label: "Reynolds Community College",
    domain: "reynolds.edu",
    value: "Reynolds Community College",
  },
  {
    label: "University of Richmond",
    domain: "richmond.edu",
    value: "University of Richmond",
  },
  {
    label: "Randolph-Macon College",
    domain: "rmc.edu",
    value: "Randolph-Macon College",
  },
  {
    label: "Roanoke College",
    domain: "roanoke.edu",
    value: "Roanoke College",
  },
  {
    label: "Sentara College of Health Sciences",
    domain: "sentara.edu",
    value: "Sentara College of Health Sciences",
  },
  {
    label: "Stratford University",
    domain: "stratford.edu",
    value: "Stratford University",
  },
  {
    label: "Shenandoah University",
    domain: "su.edu",
    value: "Shenandoah University",
  },
  {
    label: "Southern Virgina University",
    domain: "svu.edu",
    value: "Southern Virgina University",
  },
  {
    label: "Southwest Virgina Community College",
    domain: "sw.edu",
    value: "Southwest Virgina Community College",
  },
  {
    label: "Tidewater Community College",
    domain: "tcc.edu",
    value: "Tidewater Community College",
  },
  {
    label: "Thomas Nelson Community College",
    domain: "tncc.edu",
    value: "Thomas Nelson Community College",
  },
  {
    label: "University of Mary Washington",
    domain: "umw.edu",
    value: "University of Mary Washington",
  },
  {
    label: "University of Virginia's College at Wise",
    domain: "uvawise.edu",
    value: "University of Virginia's College at Wise",
  },
  {
    label: "Virginia's Community Colleges",
    domain: "vccs.edu",
    value: "Virginia's Community Colleges",
  },
  {
    label: "Virginia Commonwealth University",
    domain: "vcu.edu",
    value: "Virginia Commonwealth University",
  },
  {
    label: "Virgina Highlands Community College",
    domain: "vhcc.edu",
    value: "Virgina Highlands Community College",
  },
  {
    label: "Virginia Institute of Marine Science",
    domain: "vims.edu",
    value: "Virginia Institute of Marine Science",
  },
  {
    label: "University of Virginia",
    domain: "virginia.edu",
    value: "University of Virginia",
  },
  {
    label: "Virginia Western Community College",
    domain: "virginiawestern.edu",
    value: "Virginia Western Community College",
  },
  {
    label: "Virginia International University",
    domain: "viu.edu",
    value: "Virginia International University",
  },
  {
    label: "Virginia State University",
    domain: "vsu.edu",
    value: "Virginia State University",
  },
  {
    label: "Virginia Polytechnic Institute and State University",
    domain: "vt.edu",
    value: "Virginia Polytechnic Institute and State University",
  },
  {
    label: "Virginia Union University",
    domain: "vuu.edu",
    value: "Virginia Union University",
  },
  {
    label: "Virginia Wesleyan College",
    domain: "vwc.edu",
    value: "Virginia Wesleyan College",
  },
  {
    label: "Washington and Lee University",
    domain: "wlu.edu",
    value: "Washington and Lee University",
  },
  {
    label: "The College of William and Mary",
    domain: "wm.edu",
    value: "The College of William and Mary",
  },
  {
    label: "Castleton State College of Vermont",
    domain: "castleton.edu",
    value: "Castleton State College of Vermont",
  },
  {
    label: "Champlain College",
    domain: "champlain.edu",
    value: "Champlain College",
  },
  {
    label: "Green Mountain College",
    domain: "greenmtn.edu",
    value: "Green Mountain College",
  },
  {
    label: "Johnson State College",
    domain: "jsc.edu",
    value: "Johnson State College",
  },
  {
    label: "Middlebury College",
    domain: "middlebury.edu",
    value: "Middlebury College",
  },
  {
    label: "New England Culinary Institute",
    domain: "neci.edu",
    value: "New England Culinary Institute",
  },
  {
    label: "Norwich University",
    domain: "norwich.edu",
    value: "Norwich University",
  },
  {
    label: "School for International Training",
    domain: "sit.edu",
    value: "School for International Training",
  },
  {
    label: "Saint Michael's College",
    domain: "smcvt.edu",
    value: "Saint Michael's College",
  },
  {
    label: "University of Vermont",
    domain: "uvm.edu",
    value: "University of Vermont",
  },
  {
    label: "Vermont Law School",
    domain: "vermontlaw.edu",
    value: "Vermont Law School",
  },
  {
    label: "Vermont Technical College",
    domain: "vtc.edu",
    value: "Vermont Technical College",
  },
  {
    label: "Bennington College",
    domain: "bennington.edu",
    value: "Bennington College",
  },
  {
    label: "Bellevue College",
    domain: "bellevuecollege.edu",
    value: "Bellevue College",
  },
  {
    label: "Big Bend Community College",
    domain: "bigbend.edu",
    value: "Big Bend Community College",
  },
  {
    label: "Cascadia Community College",
    domain: "cascadia.edu",
    value: "Cascadia Community College",
  },
  {
    label: "Centralia College",
    domain: "centralia.edu",
    value: "Centralia College",
  },
  {
    label: "Charter College",
    domain: "chartercollege.edu",
    value: "Charter College",
  },
  { label: "Clark College", domain: "clark.edu", value: "Clark College" },
  {
    label: "Central Washington University",
    domain: "cwu.edu",
    value: "Central Washington University",
  },
  {
    label: "DigiPen Institute of Technology",
    domain: "digipen.edu",
    value: "DigiPen Institute of Technology",
  },
  {
    label: "Edmonds Community College",
    domain: "edcc.edu",
    value: "Edmonds Community College",
  },
  {
    label: "Everett Community College",
    domain: "everettcc.edu",
    value: "Everett Community College",
  },
  {
    label: "The Evergreen State College",
    domain: "evergreen.edu",
    value: "The Evergreen State College",
  },
  {
    label: "Eastern Washington University",
    domain: "ewu.edu",
    value: "Eastern Washington University",
  },
  {
    label: "Faith Evangelical College and Seminary",
    domain: "faithseminary.edu",
    value: "Faith Evangelical College and Seminary",
  },
  {
    label: "Gonzaga University",
    domain: "gonzaga.edu",
    value: "Gonzaga University",
  },
  {
    label: "Green River Community College",
    domain: "greenriver.edu",
    value: "Green River Community College",
  },
  {
    label: "Highline College",
    domain: "highline.edu",
    value: "Highline College",
  },
  {
    label: "Mukogawa Fort Wright Institute",
    domain: "mfwi.edu",
    value: "Mukogawa Fort Wright Institute",
  },
  {
    label: "North Seattle College",
    domain: "northseattle.edu",
    value: "North Seattle College",
  },
  {
    label: "Olympic College",
    domain: "olympic.edu",
    value: "Olympic College",
  },
  {
    label: "Peninsula College",
    domain: "pencol.edu",
    value: "Peninsula College",
  },
  {
    label: "Perry Technical Institute",
    domain: "perrytech.edu",
    value: "Perry Technical Institute",
  },
  {
    label: "Pacific Lutheran University",
    domain: "plu.edu",
    value: "Pacific Lutheran University",
  },
  {
    label: "Renton Technical College",
    domain: "rtc.edu",
    value: "Renton Technical College",
  },
  {
    label: "Seattle Central College",
    domain: "seattlecentral.edu",
    value: "Seattle Central College",
  },
  {
    label: "Seattle University",
    domain: "seattleu.edu",
    value: "Seattle University",
  },
  {
    label: "Shoreline Community College",
    domain: "shoreline.edu",
    value: "Shoreline Community College",
  },
  {
    label: "Seattle Institute of Oriental Medicine",
    domain: "siom.edu",
    value: "Seattle Institute of Oriental Medicine",
  },
  {
    label: "Skagit Valley College",
    domain: "skagit.edu",
    value: "Skagit Valley College",
  },
  {
    label: "South Seattle College",
    domain: "southseattle.edu",
    value: "South Seattle College",
  },
  {
    label: "Spokane Falls Community College",
    domain: "spokanefalls.edu",
    value: "Spokane Falls Community College",
  },
  {
    label: "Seattle Pacific University",
    domain: "spu.edu",
    value: "Seattle Pacific University",
  },
  {
    label: "Saint Martin's University",
    domain: "stmartin.edu",
    value: "Saint Martin's University",
  },
  {
    label: "University of Puget Sound",
    domain: "pugetsound.edu",
    value: "University of Puget Sound",
  },
  {
    label: "University of Washington",
    domain: "uw.edu",
    value: "University of Washington",
  },
  {
    label: "University of Washington Bothell",
    domain: "uwb.edu",
    value: "University of Washington Bothell",
  },
  {
    label: "Walla Walla University",
    domain: "wallawalla.edu",
    value: "Walla Walla University",
  },
  {
    label: "Whitman College",
    domain: "whitman.edu",
    value: "Whitman College",
  },
  {
    label: "Whitworth University",
    domain: "whitworth.edu",
    value: "Whitworth University",
  },
  {
    label: "Washington State University",
    domain: "wsu.edu",
    value: "Washington State University",
  },
  {
    label: "Wenatchee Valley College",
    domain: "wvc.edu",
    value: "Wenatchee Valley College",
  },
  {
    label: "Walla Walla Community College",
    domain: "wwcc.edu",
    value: "Walla Walla Community College",
  },
  {
    label: "Western Washington University",
    domain: "wwu.edu",
    value: "Western Washington University",
  },
  {
    label: "Yakima Valley Community College",
    domain: "yvcc.edu",
    value: "Yakima Valley Community College",
  },
  {
    label: "Alverno College",
    domain: "alverno.edu",
    value: "Alverno College",
  },
  {
    label: "Carroll University",
    domain: "carrollu.edu",
    value: "Carroll University",
  },
  {
    label: "Carthage College",
    domain: "carthage.edu",
    value: "Carthage College",
  },
  {
    label: "Chippewa Valley Technical College",
    domain: "cvtc.edu",
    value: "Chippewa Valley Technical College",
  },
  {
    label: "Edgewood College",
    domain: "edgewood.edu",
    value: "Edgewood College",
  },
  {
    label: "Gateway Technical College",
    domain: "gtc.edu",
    value: "Gateway Technical College",
  },
  {
    label: "The Institute of Beauty and Wellness",
    domain: "ibw.edu",
    value: "The Institute of Beauty and Wellness",
  },
  {
    label: "Lawrence University",
    domain: "lawrence.edu",
    value: "Lawrence University",
  },
  {
    label: "Madison Area Technical College",
    domain: "madisoncollege.edu",
    value: "Madison Area Technical College",
  },
  {
    label: "Marquette University",
    domain: "marquette.edu",
    value: "Marquette University",
  },
  {
    label: "Milwaukee Area Technical College",
    domain: "matc.edu",
    value: "Milwaukee Area Technical College",
  },
  {
    label: "Medical College of Wisconsin",
    domain: "mcw.edu",
    value: "Medical College of Wisconsin",
  },
  {
    label: "Milwaukee Institute of Art and Design",
    domain: "miad.edu",
    value: "Milwaukee Institute of Art and Design",
  },
  {
    label: "Moraine Park Technical College",
    domain: "morainepark.edu",
    value: "Moraine Park Technical College",
  },
  {
    label: "Milwaukee School of Engineering",
    domain: "msoe.edu",
    value: "Milwaukee School of Engineering",
  },
  {
    label: "Northland College",
    domain: "northland.edu",
    value: "Northland College",
  },
  {
    label: "Northcentral Technical College",
    domain: "ntc.edu",
    value: "Northcentral Technical College",
  },
  {
    label: "Northeast Wisconsin Technical College",
    domain: "nwtc.edu",
    value: "Northeast Wisconsin Technical College",
  },
  {
    label: "Silver Lake College of the Holy Family",
    domain: "sl.edu",
    value: "Silver Lake College of the Holy Family",
  },
  {
    label: "Saint Norbert College",
    domain: "snc.edu",
    value: "Saint Norbert College",
  },
  {
    label: "Cardinal Stritch University",
    domain: "stritch.edu",
    value: "Cardinal Stritch University",
  },
  {
    label: "University of Wisconsin Colleges",
    domain: "uwc.edu",
    value: "University of Wisconsin Colleges",
  },
  {
    label: "University of Wisconsin Eau Claire",
    domain: "uwec.edu",
    value: "University of Wisconsin Eau Claire",
  },
  {
    label: "University of Wisconsin Extension",
    domain: "uwex.edu",
    value: "University of Wisconsin Extension",
  },
  {
    label: "University of Wisconsin Green Bay",
    domain: "uwgb.edu",
    value: "University of Wisconsin Green Bay",
  },
  {
    label: "University of Wisconsin La Crosse",
    domain: "uwlax.edu",
    value: "University of Wisconsin La Crosse",
  },
  {
    label: "University of Wisconsin Milwaukee",
    domain: "uwm.edu",
    value: "University of Wisconsin Milwaukee",
  },
  {
    label: "University of Wisconsin Oshkosh",
    domain: "uwosh.edu",
    value: "University of Wisconsin Oshkosh",
  },
  {
    label: "University of Wisconsin Platteville",
    domain: "uwplatt.edu",
    value: "University of Wisconsin Platteville",
  },
  {
    label: "University of Wisconsin River Falls",
    domain: "uwrf.edu",
    value: "University of Wisconsin River Falls",
  },
  {
    label: "University of Wisconsin Stevens Point",
    domain: "uwsp.edu",
    value: "University of Wisconsin Stevens Point",
  },
  {
    label: "University of Wisconsin Superior",
    domain: "uwsuper.edu",
    value: "University of Wisconsin Superior",
  },
  {
    label: "University of Wisconsin Whitewater",
    domain: "uww.edu",
    value: "University of Wisconsin Whitewater",
  },
  {
    label: "Viterbo University",
    domain: "viterbo.edu",
    value: "Viterbo University",
  },
  {
    label: "Waukesha County Technical College",
    domain: "wctc.edu",
    value: "Waukesha County Technical College",
  },
  {
    label: "University of Wisconsin, Madison",
    domain: "wisc.edu",
    value: "University of Wisconsin, Madison",
  },
  {
    label: "Wisconsin Indianhead Technical College",
    domain: "witc.edu",
    value: "Wisconsin Indianhead Technical College",
  },
  {
    label: "Wisconsin Lutheran College",
    domain: "wlc.edu",
    value: "Wisconsin Lutheran College",
  },
  {
    label: "Wisconsin Technical College System",
    domain: "wtcsystem.edu",
    value: "Wisconsin Technical College System",
  },
  {
    label: "Western Technical College",
    domain: "westerntc.edu",
    value: "Western Technical College",
  },
  {
    label: "American Public University System",
    domain: "apus.edu",
    value: "American Public University System",
  },
  {
    label: "Concord University",
    domain: "concord.edu",
    value: "Concord University",
  },
  {
    label: "Eastern West Virgina Community and Technical College",
    domain: "easternwv.edu",
    value: "Eastern West Virgina Community and Technical College",
  },
  {
    label: "Fairmont State University",
    domain: "fairmontstate.edu",
    value: "Fairmont State University",
  },
  {
    label: "Marshall University",
    domain: "marshall.edu",
    value: "Marshall University",
  },
  {
    label: "Ohio Valley University",
    domain: "ovu.edu",
    value: "Ohio Valley University",
  },
  {
    label: "Pierpont Community and Technical College",
    domain: "pierpont.edu",
    value: "Pierpont Community and Technical College",
  },
  {
    label: "Shepherd University",
    domain: "shepherd.edu",
    value: "Shepherd University",
  },
  {
    label: "University of Charleston",
    domain: "ucwv.edu",
    value: "University of Charleston",
  },
  {
    label: "West Liberty University",
    domain: "westliberty.edu",
    value: "West Liberty University",
  },
  {
    label: "Wheeling Jesuit University",
    domain: "wju.edu",
    value: "Wheeling Jesuit University",
  },
  {
    label: "West Virginia Northern Community College",
    domain: "wvncc.edu",
    value: "West Virginia Northern Community College",
  },
  {
    label: "West Virginia State University",
    domain: "wvstateu.edu",
    value: "West Virginia State University",
  },
  {
    label: "West Virginia University",
    domain: "wvu.edu",
    value: "West Virginia University",
  },
  {
    label: "West Virginia Wesleyan College",
    domain: "wvwc.edu",
    value: "West Virginia Wesleyan College",
  },
  {
    label: "Casper College",
    domain: "caspercollege.edu",
    value: "Casper College",
  },
  {
    label: "Central Wyoming College",
    domain: "cwc.edu",
    value: "Central Wyoming College",
  },
  {
    label: "National Outdoor Leadership School",
    domain: "nols.edu",
    value: "National Outdoor Leadership School",
  },
  {
    label: "Northwest College",
    domain: "northwestcollege.edu",
    value: "Northwest College",
  },
  {
    label: "Northern Wyoming Community College District",
    domain: "sheridan.edu",
    value: "Northern Wyoming Community College District",
  },
  {
    label: "University of Wyoming",
    domain: "uwyo.edu",
    value: "University of Wyoming",
  },
];

export { schoolDomainList, companyList };
