import { useEffect } from "react";
import MainNavbar from "../../common/components/MainNavbar";
// import JobTableMain from "./JobsTableMain";

export default function JobBoard() {
  const TITLE = "Live Job Board by FutureForte.";
  useEffect(() => {
    document.title = TITLE;
  }, []);

  var html_dom = `<iframe class="airtable-embed" src="https://airtable.com/embed/shrVNfpV6NbQx175a?backgroundColor=purple&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="1000" style="background: transparent; border: 1px solid #ccc;"></iframe>`;

  return (
    <>
      <MainNavbar />
      <div className="flex flex-col py-4 px-4">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900 text-center">
          Job Board
        </h1>
        <div className="pt-4" dangerouslySetInnerHTML={{ __html: html_dom }} />
      </div>
      {/* <JobTableMain /> */}
    </>
  );
}
