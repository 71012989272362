import { CognitoUserPool } from "amazon-cognito-identity-js";
import { Amplify, Auth, Hub } from "aws-amplify";

export const empPoolConfig = {
  UserPoolId: "us-west-2_GFqLxVeni",
  ClientId: "4j34s8qm8bpdi685qs58r8k637",
};
export const studentPoolConfig = {
  UserPoolId: "us-west-2_EVA3oDfPn",
  ClientId: "bhi6ecbus0t0c4o8e3e5ehv9d",
};

export const empCognitoUserPool = new CognitoUserPool(empPoolConfig);
export const studentCognitoUserPool = new CognitoUserPool(studentPoolConfig);

export const studentUserPoolConfig = {
  Auth: {
    oauth: {
      domain: "onlyreferrals-student.auth.us-west-2.amazoncognito.com/",
      scope: ["email", "profile", "openid"],
      redirectSignIn: "https://www.futureforte.co/onboarding/",
      redirectSignOut: "https://www.futureforte.co/",
      clientId: "bhi6ecbus0t0c4o8e3e5ehv9d",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },

  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_EVA3oDfPn",
  aws_user_pools_web_client_id: "bhi6ecbus0t0c4o8e3e5ehv9d",
};

export const employeeUserPoolConfig = {
  Auth: {
    oauth: {
      domain: "onlyreferrals-emp.auth.us-west-2.amazoncognito.com",
      scope: ["email", "profile", "openid"],
      redirectSignIn: "https://www.futureforte.co/onboarding/",
      redirectSignOut: "https://www.futureforte.co/",
      clientId: "4j34s8qm8bpdi685qs58r8k637",
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },

  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_GFqLxVeni",
  aws_user_pools_web_client_id: "4j34s8qm8bpdi685qs58r8k637",
};
