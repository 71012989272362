import { useAuth } from "../../../../context/cognitoUser";
import { useEffect, useState } from "react";

import AXIOS_CLIENT from "../../../../utils/api_client";
import JobPostForm from "./components/JobPostForm";
import { REC_DASHBOARD_NAV } from "../../../../constants/constants";
import AlertBanner from "../../../../common/components/AlertBanner";

export default function PostJobMain({ email, rid, setCurrentNav }) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);

  useEffect(() => {
    if (rid) {
      AXIOS_CLIENT.get(`rec/user/${rid}`)
        .then((response) => {
          const goTOProfile = () => {
            alert("Please complete your profile then you can request for job");
            setCurrentNav(REC_DASHBOARD_NAV.PROFILE);
          };
          if (response.data.data.length === 0) {
            goTOProfile();
          }
          if (
            response.data.data.length > 0 &&
            !response.data.data[0].firstName
          ) {
            goTOProfile();
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage("Error while loading !");
          setIsError(true);
        });
    }
  }, [rid]);

  return (
    <>
      <div className="bg-white lg:min-w-0 lg:flex-1">
        <JobPostForm
          email={email}
          rid={rid}
          setIsDanger={setIsDanger}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
        />
        <AlertBanner
          errorMessage={errorMessage}
          isDanger={isDanger}
          setIsError={setIsError}
          isError={isError}
        />
      </div>
    </>
  );
}
