export const BASE_URL_API_GATEWAY =
  // Prod API Gateway
  "https://api.futureforte.co/";

// Local host us-101
// "http://localhost:5001/";

// Local host flask
// "http://127.0.0.1:5000/";

export const USER_TYPE = {
  EMP: "EMP",
  STUDENT: "STUDENT",
  RECRUITER: "RECRUITER",
  NO_USER: "NO_USER",
};

export const AUTH_TOKEN_KEY = "jwtToken";

export const MAX_REFERRAL_REQUEST = 20;

export const EMP_DASHBOARD_NAV = {
  DASHBOARD: "Dashboard",
  EXPLORE: "Refer Candidates",
  HISTORY: "History",
  MESSAGES: "Messages",
  PROFILE: "Profile",
  SETTINGS: "Settings",
  LOGOUT: "Sign out",
};

export const STUDENT_DASHBOARD_NAV = {
  HOME: "Home",
  EXPLORE: "Explore",
  HISTORY: "History",
  MESSAGES: "Messages",
  PROFILE: "Profile",
  SETTINGS: "Settings",
  LOGOUT: "Sign out",
};

export const REC_DASHBOARD_NAV = {
  DASHBOARD: "Dashboard",
  JOB_POST: "Explore",
  HISTORY: "History",
  MESSAGES: "Messages",
  PROFILE: "Profile",
  SETTINGS: "Settings",
  LOGOUT: "Sign out",
};

export const AuthStatus = {
  LOADING: "Loading",
  SIGNED_IN: "SignedIn",
  SIGNED_OUT: "SignednOut",
};
