import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import AXIOS_CLIENT from "../../../utils/api_client";
import AlertBanner from "../../../common/components/AlertBanner";

export default function ContactSection() {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // setIsProcessing(true);

    const data = new FormData(e.currentTarget);
    const fullName = data.get("fullName");
    const email = data.get("email");
    const message = data.get("message");

    const contactUsObject = {
      fullName: fullName,
      email: email,
      message: message,
    };

    AXIOS_CLIENT.post(`api/util/contact-us`, contactUsObject)
      .then((res) => {
        setIsDanger(false);
        setIsError(true);
        setErrorMessage("Your feedback is added!");
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setIsProcessing(false);
        setErrorMessage(error.toString());
      });
  };

  const html_dom = `<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script><iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrMxxEGTj8Yg7nHk?backgroundColor=purple" frameborder="0" onmousewheel="" width="100%" height="749" style="background: transparent; border: 1px solid #ccc;"></iframe>`;

  const renderAirtableForm = (
    <div className="flex flex-col py-4 px-4">
      <div className="pt-4" dangerouslySetInnerHTML={{ __html: html_dom }} />
    </div>
  );

  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Get in touch
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              If you have suggestions, Let's have a chat- coffee on me! We
              really appreciate your feedback.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Postal Address</dt>
                <dd>
                  <p> OnlyReferrals Inc.</p>
                  <p>Tempe 85281</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+1 (000) 000-0000</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">contact@futureforte.co</span>
                </dd>
              </div>
            </dl>
            {/* <p className="mt-6 text-base text-gray-500">
              Looking for careers?{" "}
              <a
                target="_blank"
                href="https://angel.co/l/2xXhuf"
                className="font-medium text-gray-700 underline"
              >
                View all job openings
              </a>
              .
            </p> */}
          </div>
        </div>

        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">
            {/* <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6">
              <div>
                <label htmlFor="fullName" className="sr-only">
                  Full name
                </label>
                <input
                  type="text"
                  name="fullName"
                  id="fullName"
                  required
                  autoComplete="name"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Full name"
                />
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Email"
                />
              </div>

              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={6}
                  required
                  className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Message"
                  defaultValue={""}
                />
              </div>
              <div>
                <button
                  disabled={isProcessing}
                  type="submit"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
              <AlertBanner
                errorMessage={errorMessage}
                isDanger={isDanger}
                setIsError={setIsError}
                isError={isError}
              />
            </form> */}
            {renderAirtableForm}
          </div>
        </div>
      </div>
    </div>
  );
}
