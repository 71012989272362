import { useState } from "react";
import {
  companyList,
  JOB_TYPE,
  LOCATION_TYPE,
  SKILLS,
  TOOLS_AND_TECH,
} from "../../../../../common/consts/Consts";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import Select from "react-select";

export default function JobPostForm({
  email,
  rid,
  setIsDanger,
  setIsError,
  setErrorMessage,
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  const [companyDomain, setCompanyDomain] = useState();
  const [companyName, setCompanyName] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [jobDesc, setJobDesc] = useState();
  const [skills, setSkills] = useState();
  const [tools, setTools] = useState();
  const [salary, setSalary] = useState();
  const [location, setLocation] = useState();
  const [locationType, setLocationType] = useState();
  const [jobType, setJobType] = useState();

  function renderList(arr) {
    return arr ? (
      <ul role="list" className=" divide-gray-200">
        {arr.map((item) => (
          <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
            {item.value}
          </span>
        ))}
      </ul>
    ) : (
      <></>
    );
  }

  const onSubmitJob = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      rid: rid,
      email: email,
      companyName: companyName,
      companyDomain: companyDomain,
      locationType: locationType,
      jobType: jobType,
      jobTitle: jobTitle,
      jobDesc: jobDesc,
      skills: skills,
      tools: tools,
    };

    AXIOS_CLIENT.post(`rec/api/job`, userObject)
      .then((res) => {
        setIsDanger(false);
        setErrorMessage("Referral request successfully submitted");
        setIsError(true);
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage("Erroe while submitting request!");
        setIsError(true);
        setIsProcessing(false);
      });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <form className="space-y-8" onSubmit={onSubmitJob}>
        {/* Job Title */}
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="jobTitle"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Job title *
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              type="text"
              onChange={(e) => {
                setJobTitle(e.target.value);
              }}
              value={jobTitle}
              name="jobTitle"
              id="jobTitle"
              required
              autoComplete="jobTitle"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>
        {/* JobDesc */}
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="jobDesc"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Job description *
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <textarea
              id="jobDesc"
              maxLength={800}
              onChange={(e) => {
                setJobDesc(e.target.value);
              }}
              value={jobDesc}
              name="jobDesc"
              rows={4}
              required={true}
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Job Type */}
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Job type
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-96">
                <Select
                  defaultValue={[]}
                  name="colors"
                  options={JOB_TYPE}
                  classNamePrefix="select"
                  onChange={(val) => {
                    setJobType(val.label);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Location Type */}
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Location type
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-96">
                <Select
                  defaultValue={[]}
                  name="colors"
                  options={LOCATION_TYPE}
                  classNamePrefix="select"
                  onChange={(val) => {
                    setLocationType(val.label);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Location  */}
        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
          >
            Location
          </label>
          <div className="mt-1 sm:col-span-2 sm:mt-0">
            <input
              onChange={(e) => {
                setLocation(e.target.value);
              }}
              value={location}
              type="text"
              name="location"
              id="location"
              autoComplete="location"
              className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
            />
          </div>
        </div>

        {/* SKILLS */}
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Skills
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-96">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="colors"
                  options={SKILLS}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(val) => {
                    setSkills(val);
                  }}
                />
              </div>
            </div>
            {renderList(skills)}
          </div>
        </div>

        {/* Tools */}
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Tools and technologies
              </label>
              <div className="mt-1 sm:col-span-2 sm:mt-0 w-96">
                <Select
                  defaultValue={[]}
                  isMulti
                  name="colors"
                  options={TOOLS_AND_TECH}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(val) => {
                    setTools(val);
                  }}
                />
              </div>
            </div>
            {renderList(tools)}
          </div>
        </div>

        <div className="py-5">
          <div className="flex justify-center">
            <button
              type="submit"
              className={
                isProcessing
                  ? classNames(
                      "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                    )
                  : classNames(
                      "ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    )
              }
              onSubmit={onSubmitJob}
            >
              Submit Job
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
