import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../../../context/cognitoUser";
import AXIOS_CLIENT from "../../../../utils/api_client";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PortfolioFormRow from "./components/PortfolioFormRow";
import Select from "react-select";
import {
  SKILLS,
  TOOLS_AND_TECH,
  schoolDomainList,
} from "../../../../common/consts/Consts";

import { Switch } from "@headlessui/react";
import AlertBanner from "../../../../common/components/AlertBanner";
import { UserCircleIcon } from "@heroicons/react/20/solid";

export default function JobProfileForm({ sid, email }) {
  const [school, setSchool] = useState();
  const [userName, setUserName] = useState("");
  const [schoolDomain, setSchoolDomain] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [about, setAbout] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  // const [resume, setResume] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [fileName, setFileName] = useState();
  const [s3bucketLink, sets3bucketLink] = useState();
  const [studentInfo, setStudentInfo] = useState();
  const [tools, setTools] = useState();
  const [skills, setSkills] = useState(null);
  const [linkedInUrl, setLinkedInUrl] = useState();
  const [githubUrl, setGithubUrl] = useState();
  const [portfolioUrl, setPortfolioUrl] = useState();
  const [otherUrls, setOtherUrls] = useState();
  const [workExs, setWorkExs] = useState();
  const [educations, setEducations] = useState();
  // const [studentMetaInfo, setStudentMetaInfo] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const scrollRef = useRef();
  // const [openEqualOpp, setOpenEqualOpp] = useState(false);
  const [userInfo1, setUserInfo1] = useState();
  const openToWorkOptions = ["Yes", "No"];
  const roleLevelsOptions = ["Internship", "New-grad", "Mid-senior", "Senior"];
  const [openToWork, setOpenToWork] = useState();
  const [roleLevels, setRoleLevels] = useState();
  const [ethnicity, setEthnicity] = useState();
  const [gender, setGender] = useState("");
  const genders = ["Male", "Female", "Other"];
  const [profilePicUrl, setProfilePicUrl] = useState();
  const [profilePicThumnail, setProfilePicThumnail] = useState();

  const [isVisaSponsorship, setVisaSponsorship] = useState();
  const [isDisability, setDisability] = useState();
  const [isVeteran, setVeteran] = useState();
  const [isLgbtq, setLgbtq] = useState();
  const [isImgUploading, setIsImgUploading] = useState(false);
  const [isResumeUploading, setIsResumeUploading] = useState(false);

  useEffect(() => {
    if (email) setUserName(email.slice(0, email.indexOf("@")));
  }, [email]);

  useEffect(() => {
    if (sid)
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            if (userInfo) setUserInfo1(userInfo);
            setStudentInfo(userInfo);
            if (userInfo.firstName) setFirstName(userInfo.firstName);
            if (userInfo.contact) setContactInfo(userInfo.contact);
            if (userInfo.about) setAbout(userInfo.about);
            if (userInfo.lastName) setLastName(userInfo.lastName);
            if (userInfo.resumeLink && !s3bucketLink)
              sets3bucketLink(userInfo.resumeLink);
            if (userInfo.skills) {
              setSkills(userInfo.skills);
            }
            if (userInfo.school) setSchool(userInfo.school);
            if (userInfo.schoolDomain) setSchoolDomain(userInfo.schoolDomain);
            if (userInfo.tools) setTools(userInfo.tools);
            if (userInfo.linkedInUrl) setLinkedInUrl(userInfo.linkedInUrl);
            if (userInfo.githubUrl) setGithubUrl(userInfo.githubUrl);
            if (userInfo.portfolioUrl) setPortfolioUrl(userInfo.portfolioUrl);
            if (userInfo.otherUrls) setOtherUrls(userInfo.otherUrls);
            if (userInfo.workExs) setWorkExs(userInfo.workExs);
            if (userInfo.educations) setEducations(userInfo.educations);
            if (userInfo.openToWork) setOpenToWork(userInfo.openToWork);
            if (userInfo.roleLevels) setRoleLevels(userInfo.roleLevels);
            if (userInfo.gender) setGender(userInfo.gender);
            if (userInfo.ethnicity) setEthnicity(userInfo.ethnicity);
            if (userInfo.isVisaSponsorship)
              setVisaSponsorship(userInfo.isVisaSponsorship);
            if (userInfo.isDisability) setDisability(userInfo.isDisability);
            if (userInfo.isVeteran) setVeteran(userInfo.isVeteran);
            if (userInfo.isLgbtq) setLgbtq(userInfo.isLgbtq);
            if (userInfo.profilePicUrl)
              setProfilePicUrl(userInfo.profilePicUrl);
            if (userInfo.profilePicThumnail)
              setProfilePicThumnail(userInfo.profilePicThumnail);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage("Error while getting informaiton", error.toString());
          setIsError(true);
        });
  }, [sid]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (errorMessage && isError)
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [errorMessage, isError]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (linkedInUrl && !linkedInUrl.toLowerCase().includes("linkedin.com")) {
        setLinkedInUrl("");
        setErrorMessage("Enter correct LinkedIn url");
        setIsError(true);
        setIsDanger(true);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [linkedInUrl]);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (githubUrl && !githubUrl.toLowerCase().includes("github.com")) {
        setGithubUrl("");
        setErrorMessage("Enter correct Github url");
        setIsError(true);
        setIsDanger(true);
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [githubUrl]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    if (schoolDomain == null) {
      alert("You have to select school");
      setIsProcessing(false);
      return;
    }

    const userObject = {
      sid: sid,
      email: email,
      userName: userName,
      school: school,
      schoolDomain: schoolDomain,
      firstName: firstName,
      lastName: lastName,
      about: about,
      contact: contactInfo,
      location: "Tempe",
      resumeLink: s3bucketLink,
      tools: tools,
      skills: skills,
      linkedInUrl: linkedInUrl,
      githubUrl: githubUrl,
      portfolioUrl: portfolioUrl,
      otherUrls: otherUrls,
      workExs: workExs,
      educations: educations,
      openToWork: openToWork,
      roleLevels: roleLevels,
      gender: gender,
      ethnicity: ethnicity,
      isVisaSponsorship: isVisaSponsorship,
      isDisability: isDisability,
      isVeteran: isVeteran,
      isLgbtq: isLgbtq,
      profilePicUrl: profilePicUrl,
      profilePicThumnail: profilePicThumnail,
    };
    const config = {
      headers: {
        username: sid,
      },
    };

    AXIOS_CLIENT.put(`student/user/${sid}`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setIsError(true);
        setErrorMessage("Your data is successfully updated!");
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setIsProcessing(false);
        setErrorMessage(error.toString());
      });
  };

  const resumeUploadHandler = (event) => {
    event.preventDefault();
    setIsResumeUploading(true);
    if (event.target.files[0]) {
      setIsProcessing(true);
      setSelectedFile(event.target.files[0]);
      setFileName(event.target.files[0].name);

      const data = new FormData();
      // If file selected
      data.append(
        "profileImage",
        event.target.files[0],
        event.target.files[0].name
      );
      AXIOS_CLIENT.post("api/util/resume-upload", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
        .then((response) => {
          if (response?.status && 200 === response?.status) {
            // If file size is larger than expected.
            if (response.data.error) {
              if ("LIMIT_FILE_SIZE" === response.data.error.code) {
                setIsDanger(true);
                setErrorMessage("Max size: 2MB");
                setIsError(true);
              } else {
                // If ot the given file typen
                setIsDanger(true);
                setErrorMessage(response.data.error.toString());
                setIsError(true);
              }
            } else {
              // Success
              sets3bucketLink(response.data.location);
              setIsDanger(false);
              setErrorMessage("Resume updated!");
              setIsError(true);
            }
          }
          setIsProcessing(false);
        })
        .catch((error) => {
          // If another error
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
          setIsProcessing(false);
        });
      setIsResumeUploading(false);
    } else {
      // if file not selected throw error
      setIsDanger(true);
      setErrorMessage("Please upload file");
      setIsError(true);
      setIsProcessing(false);
      setIsResumeUploading(false);
    }
  };

  const profileImageUploader = (event) => {
    event.preventDefault();
    setIsImgUploading(true);
    if (event.target.files[0]) {
      setIsProcessing(true);
      setSelectedFile(event.target.files[0]);

      const data = new FormData();
      // If file selected
      data.append("profile_image", event.target.files[0]);
      AXIOS_CLIENT.post("/api/upload/profile-image", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
        .then((response) => {
          if (response?.status && 200 === response?.status) {
            if (response.data.error) {
              setIsDanger(true);
              setErrorMessage(response.data.error.toString());
              setIsError(true);
            } else {
              // Success
              setProfilePicThumnail(response.data.profilePicThumnail);
              setProfilePicUrl(response.data.profilePicUrl);
              setIsDanger(false);
              setErrorMessage("Profile image updated!");
              setIsError(true);
            }
          }
          setIsProcessing(false);
          setIsImgUploading(false);
        })
        .catch((error) => {
          // If another error
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
          setIsProcessing(false);
          setIsImgUploading(false);
        });
    } else {
      // if file not selected throw error
      setIsDanger(true);
      setErrorMessage("Please upload file");
      setIsError(true);
      setIsProcessing(false);
      setIsImgUploading(false);
    }
  };

  const fileCancelHandler = (event) => {
    setSelectedFile(null);
    setFileName(null);
    setIsProcessing(false);
  };

  return (
    <>
      <form
        className="space-y-8 divide-y divide-gray-200"
        onSubmit={onSubmitForm}
      >
        {/* <ProfilePictureJob /> */}
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6  sm:space-y-5 sm:pt-4">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Enter your personal information
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              {/* Profile pic */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <div>
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Photo
                  </label>
                </div>
                <div className="mt-2 flex items-center gap-x-3">
                  {profilePicThumnail ? (
                    <img
                      className="inline-block h-24 w-24 rounded-full"
                      src={profilePicThumnail}
                      alt=""
                    />
                  ) : (
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                  )}
                  <label
                    htmlFor="imageUpload"
                    className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>{isImgUploading ? "Uploading..." : "Change"}</span>
                    <input
                      accept="image/png, image/gif, image/jpeg"
                      onChange={profileImageUploader}
                      id="imageUpload"
                      name="imageUpload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                </div>
              </div>

              {/* username */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="text-gray-900">Email</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <span>{email}</span>
                </div>
              </div>
              {/*University Name */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  University Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {school ? (
                    <div className="mb-2">
                      <p>{school}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Select
                    options={schoolDomainList}
                    onChange={(val) => {
                      setSchool(val.label);
                      setSchoolDomain(val.domain);
                    }}
                    placeholder="Select school"
                  />
                </div>
              </div>

              {/* First Name  */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="text-gray-900">First Name *</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    type="text"
                    name="firstName"
                    id="firstName"
                    required
                    autoComplete="firstName"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>
              {/* Last Name */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="text-gray-900">Last Name *</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    name="lastName"
                    id="lastName"
                    required
                    autoComplete="lastName"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>

              {/* Contact  details */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="text-gray-900">Mobile Number</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <PhoneInput
                    country={"us"}
                    value={contactInfo}
                    onChange={setContactInfo}
                  />
                </div>
              </div>
              {/* About */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label className="text-gray-900">About *</label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <textarea
                    id="about"
                    maxLength={800}
                    onChange={(e) => {
                      setAbout(e.target.value);
                    }}
                    value={about}
                    name="about"
                    rows={4}
                    required={true}
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Write a few sentences about yourself in third person. So
                    your referee can directly use those :)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6 sm:space-y-5">
          {/* Resume */}
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Resume
            </label>
            <div className="mt-1 sm:col-span-2 sm:mt-0">
              <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600 pb-4">
                    <label
                      htmlFor="fileUpload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>
                        {isResumeUploading ? "Uploading" : "Upload new resume"}
                      </span>
                      <input
                        accept="application/pdf"
                        onChange={resumeUploadHandler}
                        id="fileUpload"
                        name="fileUpload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                    <p className="pl-1">
                      {fileName !== "" ? fileName : "or drag and drop"}
                    </p>
                  </div>
                  {s3bucketLink ? (
                    <a href={s3bucketLink} target="_blank">
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Download Resume
                      </button>
                    </a>
                  ) : (
                    <></>
                  )}

                  <p className="text-xs text-gray-500">
                    PDF or Doc file up to 10MB
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Additional Information (optional)
              </h3>
            </div>

            <label
              htmlFor="firstName"
              className="block text-sm font-medium sm:mt-px sm:pt-1"
            >
              Portfolio
            </label>
            <div className="space-y-6 sm:space-y-5">
              {/* LinkedIn  */}
              <PortfolioFormRow
                title="LinkedIn"
                value={linkedInUrl}
                setValue={setLinkedInUrl}
                placeholder="https://www.linkedin.com/company/onlyreferrals/"
              />
              {/* Github  */}
              <PortfolioFormRow
                title="Github"
                value={githubUrl}
                setValue={setGithubUrl}
                placeholder="https://github.com/tufanProtocol"
              />
              {/* Portfolio  */}
              <PortfolioFormRow
                title="Portfolio"
                value={portfolioUrl}
                setValue={setPortfolioUrl}
                placeholder="https://xyz.github.io/portfolio-website/"
              />
              {/* Other  */}
              <PortfolioFormRow
                title="Other"
                value={otherUrls}
                setValue={setOtherUrls}
                placeholder="Any other link that you want to show"
              />
            </div>

            {/* skills */}
            <div className="sm:grid sm:grid-cols-3  sm:items-start sm:gap-2  ">
              <div className="flex flex-col">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium my-0 sm:mt-px sm:pt-1 py-0"
                >
                  Skills
                </label>
                <p className="text-xs text-gray-500 py-2 px-2">
                  You can add upto 7 skills.
                </p>
              </div>
              <Select
                value={skills}
                isMulti
                name="colors"
                options={SKILLS}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={setSkills}
              />
            </div>
            {/* Tools */}
            <div className="sm:grid sm:grid-cols-3  sm:items-start sm:gap-2  ">
              <div className="flex flex-col">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium my-0 sm:mt-px sm:pt-1 py-0"
                >
                  Tools and Tech-stacks
                </label>
                <p className="text-xs text-gray-500 py-2 px-2">
                  You can add upto 7 tools.
                </p>
              </div>
              <Select
                value={tools}
                isMulti
                name="colors"
                options={TOOLS_AND_TECH}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={setTools}
              />
            </div>
          </div>
        </div>

        {/* Work experience */}
        {/* <div className="pt-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Work experience (optional)
            </h3>
            <WorkExperienceForm
              sid={sid}
              workExs={workExs}
              setWorkExs={setWorkExs}
            />
          </div>
        </div> */}

        {/* Job preferences */}
        <div className="pt-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Job Preference (optional)
            </h3>
            {/* Open to work */}
            <div className="pt-4">
              <label className="text-gray-900">
                Are you actively looking for new opportunity?
              </label>

              <fieldset className="mt-2">
                <legend className="sr-only">Looking for jobs</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {openToWorkOptions.map((temp1) => (
                    <div key={temp1} className="flex items-center">
                      <input
                        id={temp1}
                        name="openToWork"
                        type="radio"
                        onClick={(event) => {
                          setOpenToWork(event.target.value);
                        }}
                        value={temp1}
                        checked={temp1 === openToWork}
                        // checked={temp1 === gender}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor={temp1}
                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                        {temp1}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>

            {/* Role levels */}
            <div className="pt-4">
              <label className="text-gray-900">
                What level of role are you looking for?
              </label>

              <fieldset className="mt-2">
                <legend className="sr-only">Looking for jobs</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {roleLevelsOptions.map((temp1) => (
                    <div key={temp1} className="flex items-center">
                      <input
                        id={temp1}
                        name="roleLevels"
                        type="radio"
                        onClick={(event) => {
                          setRoleLevels(event.target.value);
                        }}
                        value={temp1}
                        checked={temp1 === roleLevels}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor={temp1}
                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                        {temp1}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        {/* Equal employment */}
        <div className="pt-5">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Equal Employment (optional)
            </h3>
            {/* Ethnicity */}
            <div className="pt-4">
              <label className="text-gray-900">Ethnicity</label>
              <select
                id="ethnicity"
                name="ethnicity"
                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                defaultValue={ethnicity}
                onChange={(event) => {
                  setEthnicity(event.target.value);
                }}
              >
                <option>White</option>
                <option>Black/African American</option>
                <option>East Asian</option>
                <option>Hispanic/Latinx</option>
                <option>Southeast Asian</option>
                <option>South Asian</option>
                <option>Native American/Alaskan</option>
                <option>Native Hawaiian/Pacific Islander</option>
              </select>
            </div>

            {/* Gender */}
            <div className="pt-4">
              <label className="text-gray-900">Gender</label>

              <fieldset className="mt-2">
                <legend className="sr-only">Gender</legend>
                <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                  {genders.map((temp1) => (
                    <div key={temp1} className="flex items-center">
                      <input
                        id={temp1}
                        name="gender"
                        type="radio"
                        onClick={(event) => {
                          setGender(event.target.value);
                        }}
                        value={temp1}
                        // defaultChecked={temp1 === gender}
                        checked={temp1 === gender}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor={temp1}
                        className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                      >
                        {temp1}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
            </div>

            {/* Visa sponsorship */}
            <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2">
              <label className="text-gray-900">
                Do you need visa sponsorship?
              </label>
              <div className="px-8">
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={isVisaSponsorship}
                    onChange={setVisaSponsorship}
                    className={classNames(
                      isVisaSponsorship ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isVisaSponsorship ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>

            {/* Disability */}

            <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2">
              <label className="text-gray-900">
                Do you have physical disability?
              </label>
              <div className="px-8">
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={isDisability}
                    onChange={setDisability}
                    className={classNames(
                      isDisability ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isDisability ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>

            {/* Veteran */}

            <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2">
              <label className="text-gray-900">Are you veteran?</label>
              <div className="px-8">
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={isVeteran}
                    onChange={setVeteran}
                    className={classNames(
                      isVeteran ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isVeteran ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>

            {/* LGBTQs */}
            <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2">
              <label className="text-gray-900">
                Do you identify as LGBTQ+?
              </label>
              <div className="px-8">
                <Switch.Group as="div" className="flex items-center">
                  <Switch
                    checked={isLgbtq}
                    onChange={setLgbtq}
                    className={classNames(
                      isLgbtq ? "bg-indigo-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        isLgbtq ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </Switch.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Save and cancel */}
        <div className="pt-5">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={fileCancelHandler}
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isProcessing}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
        <div ref={scrollRef}>
          <AlertBanner
            errorMessage={errorMessage}
            isDanger={isDanger}
            setIsError={setIsError}
            isError={isError}
          />
        </div>
      </form>
    </>
  );
}
