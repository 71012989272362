import { useEffect } from "react";
import MainNavbar from "../../common/components/MainNavbar";
import CompanyValues from "./components/aboutUsContent";
import CompanyMissionComponent from "./components/companyMissionComponent";

export default function AboutUsMain() {
  const TITLE = "FutureForte: Democratize career guidance for college students";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <>
      <MainNavbar />
      <CompanyMissionComponent />
      <CompanyValues />
    </>
  );
}
