import { CheckIcon } from "@heroicons/react/20/solid";
import { useState } from "react";

const steps = [
  {
    name: "Create profile",
    description:
      "Create profile, upload resume and add some required informations",
    href: "#",
    status: "complete",
  },
  {
    name: "Reqeust referrals",
    description:
      "Request for job referrals. You can add three different job ids or link.",
    href: "#",
    status: "current",
  },
  {
    name: "Shared with employee",
    description:
      "One you reqeust your profile will be visible to employee of those companies on our platform",
    href: "#",
    status: "upcoming",
  },
  {
    name: "On acceptance",
    description:
      'If employees like your profile and willing to help you with job referrals then they will accpt your request. You will be notified in an email and you can also see on your "Matches" tab',
    href: "#",
    status: "upcoming",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HowItWorksJR() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div class="mx-auto max-w-lg pt-4">
        <div class="divide-y divide-gray-100">
          <details class="group" isOpen>
            <summary class="flex cursor-pointer list-none items-center justify-between py-4 text-lg font-medium text-secondary-900">
              How it works?
              <div class="text-secondary-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="block h-5 w-5 transition-all duration-300 group-open:rotate-180"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </div>
            </summary>

            <nav aria-label="Progress">
              <ol role="list" className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                  <li
                    key={step.name}
                    className={classNames(
                      stepIdx !== steps.length - 1 ? "pb-10" : "",
                      "relative"
                    )}
                  >
                    {step.status === "complete" ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                            aria-hidden="true"
                          />
                        ) : null}
                        <a
                          href={step.href}
                          className="group relative flex items-start"
                        >
                          <span className="flex h-9 items-center">
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                              <CheckIcon
                                className="h-5 w-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </a>
                      </>
                    ) : step.status === "current" ? (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <a
                          href={step.href}
                          className="group relative flex items-start"
                          aria-current="step"
                        >
                          <span
                            className="flex h-9 items-center"
                            aria-hidden="true"
                          >
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                              <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-indigo-600">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </a>
                      </>
                    ) : (
                      <>
                        {stepIdx !== steps.length - 1 ? (
                          <div
                            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                            aria-hidden="true"
                          />
                        ) : null}
                        <a
                          href={step.href}
                          className="group relative flex items-start"
                        >
                          <span
                            className="flex h-9 items-center"
                            aria-hidden="true"
                          >
                            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                              <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                            </span>
                          </span>
                          <span className="ml-4 flex min-w-0 flex-col">
                            <span className="text-sm font-medium text-gray-500">
                              {step.name}
                            </span>
                            <span className="text-sm text-gray-500">
                              {step.description}
                            </span>
                          </span>
                        </a>
                      </>
                    )}
                  </li>
                ))}
              </ol>
            </nav>
          </details>
        </div>
      </div>
    </>
  );
}
