import { useEffect, useState } from "react";
import StudentHistoryRow from "./components/StudentHistroyRow";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import TipBanner from "../../../../../common/components/TipBanner";
import LoadingPulse from "../../../../../common/components/LoadingPulse";
import AlertBanner from "../../../../../common/components/AlertBanner";

export default function StudentReferralHistory({ sid, email }) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [referrals, setReferrals] = useState();
  const [tipDisplay, setTipDisplay] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const config = {
    headers: {
      username: sid,
    },
  };

  useEffect(() => {
    if (sid) {
      AXIOS_CLIENT.get(`student/api/referral/accepted_referral/${sid}`, config)
        .then((response) => {
          if (response.data.data.length > 0) {
            setReferrals(response.data.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage("Error while loading information!");
          setIsError(true);
          setIsLoading(false);
        });
    }
  }, [sid]);

  return isLoading ? (
    <LoadingPulse />
  ) : (
    <div className="overflow-hidden bg-white shadow sm:rounded-md mt-2">
      <p className="m-2 text-sm text-gray-500">List of referral requests.</p>{" "}
      <TipBanner
        title="If you have successfully received a referral,
        please confirm it so that your total count of open referrals will
        update, and you will be able to request more referrals."
        tipDisplay={tipDisplay}
        setTipDisplay={setTipDisplay}
      />
      {referrals ? (
        <ul role="list" className="divide-y divide-gray-200">
          {referrals.map((referral) => (
            <StudentHistoryRow
              referral={referral}
              sid={sid}
              setIsError={setIsError}
              setIsDanger={setIsDanger}
              setErrorMessage={setErrorMessage}
            />
          ))}
        </ul>
      ) : (
        <p className="mt-4 ml-4">
          No matches as of now. Please keep requesting for a referrals.
        </p>
      )}
      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </div>
  );
}
