import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

const months = [
  {
    name: "January",
    days: [
      { date: "2021-12-27" },
      { date: "2021-12-28" },
      { date: "2021-12-29" },
      { date: "2021-12-30" },
      { date: "2021-12-31" },
      { date: "2022-01-01", isCurrentMonth: true },
      { date: "2022-01-02", isCurrentMonth: true },
      { date: "2022-01-03", isCurrentMonth: true },
      { date: "2022-01-04", isCurrentMonth: true },
      { date: "2022-01-05", isCurrentMonth: true },
      { date: "2022-01-06", isCurrentMonth: true },
      { date: "2022-01-07", isCurrentMonth: true },
      { date: "2022-01-08", isCurrentMonth: true },
      { date: "2022-01-09", isCurrentMonth: true },
      { date: "2022-01-10", isCurrentMonth: true },
      { date: "2022-01-11", isCurrentMonth: true },
      { date: "2022-01-12", isCurrentMonth: true, isToday: true },
      { date: "2022-01-13", isCurrentMonth: true },
      { date: "2022-01-14", isCurrentMonth: true },
      { date: "2022-01-15", isCurrentMonth: true },
      { date: "2022-01-16", isCurrentMonth: true },
      { date: "2022-01-17", isCurrentMonth: true },
      { date: "2022-01-18", isCurrentMonth: true },
      { date: "2022-01-19", isCurrentMonth: true },
      { date: "2022-01-20", isCurrentMonth: true },
      { date: "2022-01-21", isCurrentMonth: true },
      { date: "2022-01-22", isCurrentMonth: true },
      { date: "2022-01-23", isCurrentMonth: true },
      { date: "2022-01-24", isCurrentMonth: true },
      { date: "2022-01-25", isCurrentMonth: true },
      { date: "2022-01-26", isCurrentMonth: true },
      { date: "2022-01-27", isCurrentMonth: true },
      { date: "2022-01-28", isCurrentMonth: true },
      { date: "2022-01-29", isCurrentMonth: true },
      { date: "2022-01-30", isCurrentMonth: true },
      { date: "2022-01-31", isCurrentMonth: true },
      { date: "2022-02-01" },
      { date: "2022-02-02" },
      { date: "2022-02-03" },
      { date: "2022-02-04" },
      { date: "2022-02-05" },
      { date: "2022-02-06" },
    ],
  },
];
<li className="py-4 sm:flex">
  <time dateTime="2022-01-19" className="w-28 flex-none"></time>
  <p className="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">
    View house with real estate agent
  </p>
  <p className="flex-none sm:ml-6">
    <time dateTime="2022-01-13T14:30">2:30 PM</time> -{" "}
    <time dateTime="2022-01-13T16:30">4:30 PM</time>
  </p>
</li>;
const availability = [
  {
    time: "2:00 pm to 2:30 pm",
    date: "12th January",
    isSelected: true,
  },
  {
    time: "2:30 pm to 3:00 pm",
    date: "12th January",
    isSelected: false,
  },
  {
    time: "3:00 pm to 3:30 pm",
    date: "12th January",
    isSelected: false,
  },
  {
    time: "3:30 pm to 4:00 pm",
    date: "12th January",
    isSelected: false,
  },
  {
    time: "5:00 pm to 5:30 pm",
    date: "12th January",
    isSelected: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MeetingModal() {
  return (
    <div>
      <div className="relative grid grid-cols-1 gap-x-14 md:grid-cols-1">
        <button
          type="button"
          className="absolute -left-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="absolute -right-1.5 -top-1 flex items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {months.map((month, monthIdx) => (
          <section
            key={monthIdx}
            className={classNames(
              monthIdx === months.length - 1 && "hidden md:block",
              "text-center"
            )}
          >
            <h2 className="text-sm font-semibold text-gray-900">
              {month.name}
            </h2>
            <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
              <div>M</div>
              <div>T</div>
              <div>W</div>
              <div>T</div>
              <div>F</div>
              <div>S</div>
              <div>S</div>
            </div>
            <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
              {month.days.map((day, dayIdx) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    day.isCurrentMonth
                      ? "bg-white text-gray-900"
                      : "bg-gray-50 text-gray-400",
                    dayIdx === 0 && "rounded-tl-lg",
                    dayIdx === 6 && "rounded-tr-lg",
                    dayIdx === month.days.length - 7 && "rounded-bl-lg",
                    dayIdx === month.days.length - 1 && "rounded-br-lg",
                    "relative py-1.5 hover:bg-gray-100 focus:z-10"
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.isToday && "bg-indigo-600 font-semibold text-white",
                      "mx-auto flex h-7 w-7 items-center justify-center rounded-full"
                    )}
                  >
                    {day.date.split("-").pop().replace(/^0/, "")}
                  </time>
                </button>
              ))}
            </div>
          </section>
        ))}
      </div>
      <section className="mt-12">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Availability
        </h2>
        <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
          {availability.map((item) => (
            <li className="py-4 sm:flex">
              <time dateTime="2022-01-19" className="w-28 flex-none">
                {item.date}
              </time>
              <p className="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">
                {item.time}
              </p>
              <button
                type="button"
                className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {item.isSelected ? "Selected" : "Select"}
              </button>
            </li>
          ))}

          {/* <li className="py-4 sm:flex">
            <time dateTime="2022-01-20" className="w-28 flex-none">
              2:00 pm to 2:30 pm
            </time>
            <p className="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">
              Meeting with bank manager
            </p>
            <p className="flex-none sm:ml-6">All day</p>
          </li>
          <li className="py-4 sm:flex">
            <time dateTime="2022-01-18" className="w-28 flex-none">
              2:00 pm to 2:30 pm
            </time>
            <p className="mt-2 flex-auto font-semibold text-gray-900 sm:mt-0">
              Sign paperwork at lawyers
            </p>
            <p className="flex-none sm:ml-6">
              <time dateTime="2022-01-17T10:00">10:00 AM</time> -{" "}
              <time dateTime="2022-01-17T10:15">10:15 AM</time>
            </p>
          </li> */}
        </ol>
      </section>
    </div>
  );
}
