/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {
  ArrowRightOnRectangleIcon,
  FolderMinusIcon,
  QueueListIcon,
} from "@heroicons/react/20/solid";
import { useState } from "react";
import { Link } from "react-router-dom";
import StudentCGRequest from "./tabs/apply/StudentCGRequest";
import StudentCGHistory from "./tabs/history/StudentCGHistory";

const tabs = [
  {
    name: "Request",
    href: "#",
    icon: ArrowRightOnRectangleIcon,
    current: true,
  },
  { name: "History", href: "#", icon: QueueListIcon, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StudentCGMain({ sid, email }) {
  const [currentTab, setCurrentTab] = useState("Request");

  function renderMainComponent() {
    switch (currentTab) {
      case "Request":
        return <StudentCGRequest sid={sid} email={email} />;
        break;
      case "History":
        return <StudentCGHistory sid={sid} email={email} />;
        break;
    }
  }
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(event) => {
            setCurrentTab(event.target.value);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                onClick={() => {
                  setCurrentTab(tab.name);
                }}
                className={classNames(
                  tab.name === currentTab
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.name === currentTab
                      ? "text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5"
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
      <main>{renderMainComponent()}</main>
    </div>
  );
}
