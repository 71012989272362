import { useEffect, useState } from "react";

import StudentProfileRow from "./components/StudentProfileRow";
import AXIOS_CLIENT from "../../../../../../../utils/api_client";
import AlertBanner from "../../../../../../../common/components/AlertBanner";
import LoadingPulse from "../../../../../../../common/components/LoadingPulse";
import StudentProfileOverlay from "./components/StudentProfileOverlay";

export default function EmpCGExplore({ eid, email, empInfo }) {
  const [students, setStudents] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [curIndex, setCurIndex] = useState();
  const [curStudent, setCurStudent] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (empInfo)
      AXIOS_CLIENT.get(`emp/api/referral/get_students_cg`, {
        params: { schoolDomain: empInfo.schoolDomain },
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            setStudents(response.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
        });
  }, [empInfo]);

  const removeElement = (index) => {
    setStudents((students) => {
      let left = students.slice(0, index);
      let right = students.slice(index + 1);
      return [...left, ...right];
    });
  };

  const mainContent = students ? (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {students.map((student, index) => (
          <StudentProfileRow
            eid={eid}
            student={student}
            index={index}
            setCurIndex={setCurIndex}
            setIsOpen={setIsOpen}
            setCurStudent={setCurStudent}
            removeElement={removeElement}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            setIsDanger={setIsDanger}
            empInfo={empInfo}
          />
        ))}
      </ul>
    </div>
  ) : (
    <>Cannot find any stuents who are seeking for career guidance.</>
  );

  return isLoading ? (
    <LoadingPulse />
  ) : (
    <div className="pt-4">
      {mainContent}

      <StudentProfileOverlay
        isOpen={isOpen}
        student={curStudent}
        setIsOpen={setIsOpen}
        eid={eid}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsDanger={setIsDanger}
        removeElement={removeElement}
        index={curIndex}
        isAction={true}
        empInfo={empInfo}
      />

      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </div>
  );
}
