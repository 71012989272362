import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../context/cognitoUser";
import AlertBanner from "../../../../common/components/AlertBanner";
import { Auth } from "aws-amplify";

export default function SettingsMainRec({ email, rid, setCurrentNav }) {
  const { setUser, setUserSession } = useAuth();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);

  const handUpdatePassword = (event) => {
    event.preventDefault();
    window.open("/forgot-password", "_blank");
  };

  const handleDeleteAccount = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const password = data.get("curPassword");

    try {
      const result = await Auth.deleteUser();
      setUser(null);
      setUserSession(null);
      localStorage.removeItem("isAuth");
      localStorage.removeItem("accessToken");
      navigate("/");
    } catch (error) {
      setIsError(true);
      setIsDanger(true);
      setErrorMessage(error.toString());
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col  py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6  text-3xl font-bold tracking-tight text-gray-900">
            Change password
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handUpdatePassword}>
              <div>
                <button
                  type="submit"
                  onClick={() => window.open("/forgot-password", "_blank")}
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Forgot Password
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6  text-3xl font-bold tracking-tight text-gray-900">
            Delete Account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleDeleteAccount}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Enter current password
                </label>
                <div className="mt-1">
                  <input
                    id="curPassword"
                    name="curPassword"
                    type="password"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </>
  );
}
