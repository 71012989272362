import { useEffect, useState } from "react";
import EmpHistoryRow from "./components/EmpHistoryRow";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import LoadingPulse from "../../../../../common/components/LoadingPulse";
import AlertBanner from "../../../../../common/components/AlertBanner";
import StudentProfileRead from "../../components/StudentProfileRead";

export default function HistoryMain({ eid, email }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [curStudent, setCurStudent] = useState();
  const [referrals, setReferrals] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("_");
  const [isDanger, setIsDanger] = useState(false);

  const config = {
    headers: {
      username: eid,
    },
  };

  useEffect(() => {
    if (eid)
      AXIOS_CLIENT.get(`emp/api/referral/accepted_referral/${eid}`, config)
        .then((response) => {
          if (response.data.data.length > 0) {
            setReferrals(response.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
          setIsLoading(false);
        });
  }, [eid]);

  const mainContent = referrals ? (
    referrals.map((referral, index) => (
      <EmpHistoryRow
        referral={referral}
        setCurStudent={setCurStudent}
        setIsOpen={setIsOpen}
      />
    ))
  ) : (
    <p className="mt-4 ml-4">
      {" "}
      You haven't accepted any referral request as of now
    </p>
  );

  return isLoading ? (
    <LoadingPulse />
  ) : (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {mainContent}
      </ul>
      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
      <StudentProfileRead
        student={curStudent}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
}
