import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../../../context/cognitoUser";
import { MAX_REFERRAL_REQUEST } from "../../../constants/constants";
import { Navigate, useNavigate } from "react-router-dom";
import AlertBanner from "../../../common/components/AlertBanner";
import AXIOS_CLIENT from "../../../utils/api_client";

export default function ReqeustReferralModal({
  opp,
  openModal,
  setOpenModal,
  setErrorMessage,
  setIsDanger,
  setIsError,
  sid,
  email,
  studentInfo,
  totalOpenReferral,
  setTotalOpenReferral,
}) {
  var jobIds = [];
  const [jobId1, setJobId1] = useState(opp.link);
  const [jobId2, setJobId2] = useState();
  const [jobId3, setJobId3] = useState();
  const [isProcessing, setIsProcessing] = useState(false);

  const [companyDomain, setCompanyDomain] = useState(opp.companyDomain);
  const [companyName, setCompanyName] = useState(opp.company);
  const [schoolDomain, setSchoolDomain] = useState();
  const [isAlert, setIsAlert] = useState(false);

  const navigate = useNavigate();

  function handleJobId1(e) {
    setJobId1(e.target.value);
  }

  function handleJobId2(e) {
    setJobId2(e.target.value);
  }
  function handleJobId3(e) {
    setJobId3(e.target.value);
  }

  useEffect(() => {
    if (sid) {
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length === 0) {
            if (!isAlert) {
              setIsAlert(true);
              alert(
                "Please complete your profile then you can request for job referrals"
              );
              navigate("/student/dashboard/profile");
            }
          }
        })
        .catch((error) => {
          setIsDanger(true);
          if (!isAlert) {
            setIsAlert(true);
            alert(
              "Please complete your profile then you can request for job referrals"
            );
            navigate("/student/dashboard/profile");
          }
          setErrorMessage("Error! Please refresh page.");
          setIsError(true);
        });
    }
  }, [sid]);

  const onRequestReferral = (e) => {
    e.preventDefault();
    setIsProcessing(true);
    if (!companyDomain) {
      setIsDanger(true);
      setErrorMessage("Please select company");
      setIsError(true);
      setIsProcessing(false);
      return;
    }
    if (jobId1) jobIds.push(jobId1);
    if (jobId2) jobIds.push(jobId2);
    if (jobId3) jobIds.push(jobId3);
    if (jobIds.length === 0) {
      setIsDanger(true);
      setErrorMessage("You should add atleast one Job IDs or URL");
      setIsError(true);
      setIsProcessing(false);
      return;
    }
    if (totalOpenReferral > MAX_REFERRAL_REQUEST - 1) {
      setIsDanger(true);
      setErrorMessage(
        `You can request upto ${MAX_REFERRAL_REQUEST} referrals! Please wait until other open referral request closed or delete earlier requests.`
      );
      setIsError(true);
      setIsProcessing(false);
      return;
    }

    const userObject = {
      sid: sid,
      email: email,
      schoolDomain: schoolDomain,
      companyName: companyName,
      companyDomain: companyDomain,
      referralsAccepted: false,
      referralsCompleted: false,
      jobIds: jobIds,
      student: studentInfo._id,
    };

    const config = {
      headers: {
        username: sid,
      },
    };

    AXIOS_CLIENT.post(`student/api/referral`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setErrorMessage("Referral request successfully submitted");
        setIsError(true);
        setIsProcessing(false);
        setJobId1("");
        setJobId2("");
        setJobId3("");
        setTotalOpenReferral(totalOpenReferral + 1);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage("Error while submitting request!");
        setIsError(true);
        setIsProcessing(false);
      });

    setOpenModal(false);
  };

  return (
    <>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpenModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpenModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900 py-4"
                      >
                        Job Ids for {opp.company}
                      </Dialog.Title>
                      <div className="mt-6 sm:col-span-2 sm:mt-0 w-96">
                        <input
                          onChange={handleJobId1}
                          value={jobId1}
                          type="text"
                          name="jobId1"
                          id="jobId1"
                          className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          placeholder="Example:  1229900 or https://www.zon.jobs/2301277"
                        />
                        <input
                          type="text"
                          name="jobId2"
                          value={jobId2}
                          onChange={handleJobId2}
                          id="jobId2"
                          className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="Example:  1229900 or https://www.zon.jobs/2301277"
                        />
                        <input
                          type="text"
                          name="jobId3"
                          id="jobId3"
                          value={jobId3}
                          onChange={handleJobId3}
                          className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          placeholder="Example:  1229900 or https://www.zon.jobs/2301277"
                        />
                        <p className="mt-2 text-sm text-gray-500">
                          You can add only three job IDs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={onRequestReferral}
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpenModal(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
