import {
  ChevronRightIcon,
  EnvelopeIcon,
  PhoneIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { format } from "timeago.js";
import { ClockIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import AXIOS_CLIENT from "../../../../../../../../utils/api_client";

export default function StudentProfileRow({
  eid,
  student,
  index,
  setCurIndex,
  setIsOpen,
  setCurStudent,
  setIsError,
  setErrorMessage,
  setIsDanger,
  removeElement,
  empInfo,
}) {
  const sid = student.sid;
  const [isProcessing, setIsProcessing] = useState(false);

  const renderStudentCG = (
    <>
      <span>Seeking help for:</span>
      <ul role="list" className="divide-gray-200">
        {student.cgResumeReview ? (
          <li key="resume-review">
            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              Resume Review
            </p>
          </li>
        ) : (
          <></>
        )}
        {student.cg1Mentorship ? (
          <li key="1:1 Mentorship">
            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              1:1 Mentorship
            </p>
          </li>
        ) : (
          <></>
        )}
        {student.cgMockInterview ? (
          <li key="Mock Interview">
            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              Mock Interview
            </p>
          </li>
        ) : (
          <></>
        )}
        {student.cgInterviewPrep ? (
          <li key="Interview Prep&Tips-review">
            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              Interview Prep&Tips
            </p>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </>
  );

  const onAcceptCGC = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      sid: student?.sid,
      eid: eid,
      student: student?._id,
      emp: empInfo?._id,
    };
    const config = {
      headers: {
        username: eid,
      },
    };

    AXIOS_CLIENT.put(`emp/api/referral/accept_cgc`, userObject, config)
      .then((res) => {
        removeElement(index);
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setErrorMessage("Error while processing your request");
        setIsProcessing(false);
      });
  };
  return (
    <li key={student.email}>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
            <div>
              <p className="truncate text-sm font-medium text-indigo-600">
                {student.firstName ?? ""}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">{student.email}</span>
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {student?.contact ? (
                  <PhoneIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                <span className="truncate">
                  {student?.contact ? student?.contact : ""}
                </span>
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {student.updatedAt ? (
                  <ClockIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                <span className="">
                  {student.updatedAt ? format(student.updatedAt) : ""}
                </span>
              </p>
            </div>

            <div className="hidden md:block">
              <div className="flex flex-row items-center gap-x-1">
                {student.linkedInUrl ? (
                  <a
                    target="_blank"
                    key="LinkedIn"
                    href={student.linkedInUrl}
                    className="text-blue-gray-400 hover:text-blue-gray-500"
                  >
                    <svg
                      fill="currentColor"
                      viewBox="0 0 16 16"
                      id="IconChangeColor"
                      className=" h-6 w-6"
                    >
                      {" "}
                      <path
                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                        id="mainIconPathAttribute"
                        fill="#737373"
                      ></path>{" "}
                    </svg>
                  </a>
                ) : (
                  <></>
                )}

                {student.resumeLink ? (
                  <a
                    type="button"
                    href={student?.resumeLink}
                    target="_blank"
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500
                          "
                  >
                    Resume
                    <PaperClipIcon
                      className="ml-3 -mr-1 h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="hidden md:block">{renderStudentCG}</div>

            <div className="hidden md:block">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  onClick={onAcceptCGC}
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Connect
                </button>
              </span>
            </div>
          </div>
        </div>
        <div>
          <button
            disabled={isProcessing}
            onClick={() => {
              setCurIndex(index);
              setIsOpen(true);
              setCurStudent(student);
            }}
          >
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </li>
  );
}
