import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import AlertBanner from "../../../common/components/AlertBanner";

export default function FloatingAlert({
  setIsError,
  errorMessage,
  isDanger,
  isError,
}) {
  return (
    <>
      <Transition.Root show={isError} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setIsError}>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-bottom sm:pb-4">
              <Dialog.Panel className="relative transform overflow-hidden bg-white text-left transition-all sm:w-full sm:max-w-lg">
                <AlertBanner
                  errorMessage={errorMessage}
                  isDanger={isDanger}
                  setIsError={setIsError}
                  isError={isError}
                />
              </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
