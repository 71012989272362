import { useEffect } from "react";
import { useState } from "react";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import JobUpdateModal from "./JobUpdateModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function JobsListRow({
  index,
  job,
  setIsError,
  setErrorMessage,
  setIsDanger,
  removeJobs,
}) {
  const rid = job.rid;
  const [openModal, setOpenModal] = useState(false);

  const handleDeleteReferral = (event) => {
    event.preventDefault();

    // AXIOS_CLIENT.delete(`rec/api/job/${job._id}`, {
    //   data: job,
    // })
    //   .then((res) => {})
    //   .catch((error) => {
    //     setErrorMessage("Error while deleting job. Please try again!");
    //   });

    removeJobs(index);
  };

  const handleUpdateReferral = (event) => {
    setOpenModal(true);
  };

  const renderModal = openModal ? (
    <JobUpdateModal
      job={job}
      openModal={openModal}
      setOpenModal={setOpenModal}
    />
  ) : (
    <></>
  );

  return (
    <>
      <div className="flex items-center px-4 py-2 sm:px-6">
        <div className="flex min-w-0 flex-wrap items-center">
          <div className="min-w-0 flex-1 px-4 grid grid-cols-2 md:grid md:grid-cols-4  md:gap-4">
            <div className="flex flex-center">
              <div className="h-10 w-10 flex-shrink-0">
                <h1 className="text-indigo-600">{job.jobTitle ?? ""}</h1>
              </div>
            </div>
            <div>
              <p className="text-sm text-gray-900">Type </p>
              <h3 className="text-sm">{job.jobType ?? ""}</h3>
            </div>
            <div>
              <p className="text-sm text-gray-900">Total #applicants </p>
              <h3 className="text-sm">{job.applicants.length ?? ""}</h3>
            </div>
            <div className="md:block">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={handleUpdateReferral}
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  Update
                </button>
                <button
                  onClick={handleDeleteReferral}
                  type="button"
                  className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  Delete
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      {renderModal}
    </>
  );
}
