import { useEffect } from "react";
import MainNavbar from "../../common/components/MainNavbar";
import BlogHeader from "./components/blogHeader";

export default function BlogMain() {
  const TITLE =
    "Find out all learning materials that you need. Let's get you job-referrals";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <>
      <MainNavbar />
      <BlogHeader />
    </>
  );
}
