import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import JobTable from "./components/JobTable";
import { FALL_INTERNSHIP_LIST } from "../../constants/all_opps";

const sortOptions = [
  { name: "Most Popular", href: "#", current: true },
  { name: "Newest", href: "#", current: false },
  { name: "In trend", href: "#", current: false },
];

const filters = [
  {
    id: "opportunityType",
    isOpen: true,
    name: "Type",
    options: [
      { value: "internship", label: "Internship", checked: true },
      { value: "full-time", label: "Full-time", checked: true },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function JobTableMain({
  sid,
  email,
  studentInfo,

  curOpp,
  totalOpenReferral,
  setTotalOpenReferral,
}) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [isInternFilter, setIsInternFilter] = useState(true);
  const [isFullTime, setIsFullTime] = useState(true);
  const [filterTags, setFilterTags] = useState([
    { value: "Software Engineer", label: "Software Engineer", checked: true },
    { value: "Internship", label: "Internship", checked: true },
    { value: "Product Manager", label: "Product Manager", checked: true },
    { value: "New Grad", label: "New Grad", checked: true },
  ]);
  const [filterChange, setFilterChange] = useState(false);

  useEffect(() => {
    for (let i = 0; i < filterTags.length; i++) {}
  }, [filterChange]);

  return (
    <>
      <div className="bg-white">
        <div>
          {/* Mobile filter dialog */}
          {/* <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        Filters
                      </h2>
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    Filters
                    <form className="mt-4 border-t border-gray-200">
                      <h3 className="sr-only">Categories</h3>

                      {filters.map((section) => (
                        <Disclosure
                          as="div"
                          key={section.id}
                          className="border-t border-gray-200 px-4 py-6"
                        >
                          {({ open }) => (
                            <>
                              <h3 className="-mx-2 -my-3 flow-root">
                                <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                  <span className="font-medium text-gray-900">
                                    {section.name}
                                  </span>
                                  <span className="ml-6 flex items-center">
                                    {open ? (
                                      <MinusIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <PlusIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </span>
                                </Disclosure.Button>
                              </h3>
                              <Disclosure.Panel className="pt-6">
                                <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                    <div
                                      key={option.value}
                                      className="flex items-center"
                                    >
                                      <input
                                        id={`filter-mobile-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        defaultChecked={option.checked}
                                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      />
                                      <label
                                        htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                        className="ml-3 min-w-0 flex-1 text-gray-500"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root> */}

          <main className="mx-auto px-4 sm:px-6 lg:px-8">
            <section aria-labelledby="products-heading" className="pt-6 pb-24">
              <h2 id="products-heading" className="sr-only">
                Products
              </h2>

              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                {/* <form className="hidden lg:block">
                  <h3 className="sr-only">Categories</h3>

                  <Disclosure
                    as="div"
                    key="typeFilter"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Type
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            <div
                              key="intern-filter"
                              className="flex items-center"
                            >
                              <input
                                id={`filter-typeFilter-inter-filter`}
                                name="typeFilter"
                                defaultValue="Internship"
                                type="checkbox"
                                defaultChecked={isInternFilter}
                                onChange={() => {
                                  setIsInternFilter(!isInternFilter);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-typeFilter-intern`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                Internship
                              </label>
                            </div>

                            <div key="full-time" className="flex items-center">
                              <input
                                id={`filter-typeFilter-full-time-filter`}
                                name="typeFilter"
                                defaultValue="Full-time"
                                type="checkbox"
                                defaultChecked={isFullTime}
                                onChange={() => {
                                  setIsFullTime(!isFullTime);
                                }}
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-typeFilter-full-time`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                Full-time
                              </label>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> 

                  
                  <Disclosure
                    as="div"
                    key="typeFilter"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Tags
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-4">
                            {filterTags.map((tag, optionIdx) => (
                              <div
                                key={tag.value}
                                className="flex items-center"
                              >
                                <input
                                  onClick={() => {
                                    setFilterTags((filterTags) => {
                                      var curFilter = filterTags;
                                      if (optionIdx < filterTags.length) {
                                        curFilter[optionIdx].checked =
                                          !filterTags[optionIdx].checked;
                                      }
                                      return curFilter;
                                    });
                                    setFilterChange(!filterChange);
                                  }}
                                  id={`filter-tags-${optionIdx}`}
                                  name={`tags[]`}
                                  defaultValue={tag.value}
                                  type="checkbox"
                                  defaultChecked={tag.checked}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-tags-${optionIdx}`}
                                  className="ml-3 text-sm text-gray-600"
                                >
                                  {tag.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </form> */}

                {/* Product grid */}
                <div className="lg:col-span-4 w-full">
                  {/* Replace with your content */}

                  <JobTable
                    ALL_OPP={curOpp}
                    sid={sid}
                    email={email}
                    studentInfo={studentInfo}
                    totalOpenReferral={totalOpenReferral}
                    setTotalOpenReferral={setTotalOpenReferral}
                  />
                  {/* <div className="h-96 rounded-lg border-4 border-dashed border-gray-200 lg:h-full" /> */}
                  {/* /End replace */}
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
