import { useEffect, useState, useRef } from "react";

import {
  companyList,
  schoolDomainList,
} from "../../../../common/consts/Consts";
import AXIOS_CLIENT from "../../../../utils/api_client";
import AlertBanner from "../../../../common/components/AlertBanner";
import Select from "react-select";
import { UserCircleIcon } from "@heroicons/react/20/solid";

export default function EmpProfileForm({ eid, email }) {
  const [isImgUploading, setIsImgUploading] = useState(false);
  const [userName, setUserName] = useState();
  const [school, setSchool] = useState();
  const [schoolDomain, setSchoolDomain] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyDomain, setCompanyDomain] = useState();
  const [companyEmail, setCompanyEmail] = useState();
  const [isCompanyVerified, setIsCompanyVerified] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("_");
  const [isDanger, setIsDanger] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSentProcessing, setEmailSentProcessing] = useState(false);
  const scrollRef = useRef();
  const [isOpenToMentorship, setIsOpenToMentorship] = useState(false);
  const [topmateLink, setTopmateLink] = useState();
  const [calendlyLink, setCalendlyLink] = useState();
  const [profilePicUrl, setProfilePicUrl] = useState();
  const [profilePicThumnail, setProfilePicThumnail] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const [empInfo, setEmpInfo] = useState();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [errorMessage]);

  useEffect(() => {
    if (eid)
      AXIOS_CLIENT.get(`emp/user/${eid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            setEmpInfo(response.data.data[0]);
            if (userInfo.school) setSchool(userInfo.school);
            if (userInfo.schoolDomain) setSchoolDomain(userInfo.schoolDomain);

            if (userInfo.email) {
              const tempEmail = userInfo.email;
              setUserName(tempEmail.slice(0, tempEmail.indexOf("@")));
            }
            if (userInfo.firstName) setFirstName(userInfo.firstName);
            if (userInfo.lastName) setLastName(userInfo.lastName);
            if (userInfo.companyName) setCompanyName(userInfo.companyName);
            if (userInfo.companyEmail) setCompanyEmail(userInfo.companyEmail);
            if (userInfo.isOpenToMentorship)
              setIsOpenToMentorship(userInfo.isOpenToMentorship);
            if (userInfo.calendlyLink) setCalendlyLink(userInfo.calendlyLink);
            if (userInfo.topmateLink) setTopmateLink(userInfo.topmateLink);
            if (userInfo.isCompanyVerified)
              setIsCompanyVerified(userInfo.isCompanyVerified);
            if (userInfo.companyDomain)
              setCompanyDomain(userInfo.companyDomain);
            if (userInfo.profilePicUrl)
              setProfilePicUrl(userInfo.profilePicUrl);
            if (userInfo.profilePicThumnail)
              setProfilePicThumnail(userInfo.profilePicThumnail);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
        });
  }, [eid]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const curDomain = companyEmail.substring(companyEmail.lastIndexOf("@") + 1);

    // if (companyDomain !== curDomain) {
    //   setIsDanger(true);
    //   setErrorMessage(
    //     `Both company domain and Email address does not match. Please select again! `
    //   );
    //   setIsError(true);
    //   setIsProcessing(false);
    //   return;
    // }

    if (!companyDomain) {
      setIsDanger(true);
      setErrorMessage(`You must select company`);
      setIsError(true);
      setIsProcessing(false);
      return;
    }

    const userObject = {
      eid: eid,
      email: email, // school email
      userName: userName,
      school: school,
      schoolDomain: schoolDomain,
      firstName: firstName,
      lastName: lastName,
      companyDomain: companyDomain,
      companyEmail: companyEmail,
      companyName: companyName,
      isOpenToMentorship: isOpenToMentorship,
      topmateLink: topmateLink,
      calendlyLink: calendlyLink,
      profilePicUrl: profilePicUrl,
      profilePicThumnail: profilePicThumnail,
      // isCompanyVerified: isCompanyVerified, For MVP there is no need of user verification
      isCompanyVerified: true,
    };
    const config = {
      headers: {
        username: eid,
      },
    };

    AXIOS_CLIENT.put(`emp/user/${eid}`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setErrorMessage(res.data.toString());
        setIsError(true);
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
        setIsProcessing(false);
      });
  };
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleSendEmail = (e) => {
    e.preventDefault();
    setEmailSentProcessing(true);

    const curDomain = companyEmail.substring(companyEmail.lastIndexOf("@") + 1);
    if (companyDomain !== curDomain) {
      setIsDanger(true);
      setErrorMessage(
        `Both company domain and Email address does not match. Please select again! `
      );
      setIsError(true);
      setEmailSentProcessing(false);
      return;
    }

    AXIOS_CLIENT.post(`api/util/send-email`, null, {
      params: { email: companyEmail, eid: eid },
    })
      .then((res) => {
        setIsDanger(false);
        setErrorMessage(res.data.toString());
        setIsError(true);
        setEmailSent(true);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
        setEmailSent(true);
      });
  };

  const singleFileChangedHandler = (event) => {
    setIsImgUploading(true);
    if (event.target.files[0]) {
      setIsProcessing(true);
      setSelectedFile(event.target.files[0]);

      const data = new FormData();
      // If file selected
      data.append("profile_image", event.target.files[0]);
      AXIOS_CLIENT.post("/api/upload/profile-image", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
        .then((response) => {
          if (response?.status && 200 === response?.status) {
            if (response.data.error) {
              setIsDanger(true);
              setErrorMessage(response.data.error.toString());
              setIsError(true);
            } else {
              // Success
              setProfilePicThumnail(response.data.profilePicThumnail);
              setProfilePicUrl(response.data.profilePicUrl);
              setIsDanger(false);
              setErrorMessage("Profile image updated!");
              setIsError(true);
            }
          }
          setIsProcessing(false);
          setIsImgUploading(false);
        })
        .catch((error) => {
          // If another error
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
          setIsProcessing(false);
          setIsImgUploading(false);
        });
    } else {
      // if file not selected throw error
      setIsDanger(true);
      setErrorMessage("Please upload file");
      setIsError(true);
      setIsProcessing(false);
      setIsImgUploading(false);
    }
  };

  const fileCancelHandler = (event) => {
    setSelectedFile(null);
    setIsProcessing(false);
  };

  return (
    <>
      <form
        className="space-y-4 divide-y divide-gray-200"
        onSubmit={onSubmitForm}
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Enter your personal information
              </p>
            </div>
            <div className="space-y-6 sm:space-y-5">
              {/* Profile pic */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <div>
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Photo
                  </label>
                </div>
                <div className="mt-2 flex items-center gap-x-3">
                  {profilePicThumnail ? (
                    <img
                      className="inline-block h-24 w-24 rounded-full"
                      src={profilePicThumnail}
                      alt=""
                    />
                  ) : (
                    <UserCircleIcon
                      className="h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                  )}
                  <label
                    htmlFor="fileUpload"
                    className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>{isImgUploading ? "Uploading..." : "Change"}</span>
                    <input
                      accept="image/png, image/gif, image/jpeg"
                      onChange={singleFileChangedHandler}
                      id="fileUpload"
                      name="fileUpload"
                      type="file"
                      className="sr-only"
                    />
                  </label>
                </div>
              </div>

              {/* username */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Username
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <span>{userName}</span>
                </div>
              </div>

              {/*University Name */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  University Name
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {school ? (
                    <div className="mb-2">
                      <p>{school}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                  <Select
                    options={schoolDomainList}
                    onChange={(val) => {
                      setSchool(val.label);
                      setSchoolDomain(val.domain);
                    }}
                    placeholder="Select school"
                  />
                </div>
              </div>

              {/* email */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address
                </label>
                <span>{email}</span>
              </div>

              {/* First Name  */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  First name *
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    value={firstName}
                    type="text"
                    name="firstName"
                    id="firstName"
                    autoComplete="firstName"
                    required
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>
              {/* Last Name */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Last name *
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    value={lastName}
                    name="lastName"
                    id="lastName"
                    autoComplete="lastName"
                    required
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                  />
                </div>
              </div>

              {/* Compnay Name  */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="companyName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Company name *
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  {companyName ? (
                    <div className="mb-2">
                      <p>{companyName}</p>
                    </div>
                  ) : (
                    <></>
                  )}

                  <Select
                    options={companyList}
                    onChange={(val) => {
                      setCompanyName(val.label);
                      setCompanyDomain(val.domain);
                    }}
                    placeholder="Select Company"
                  />
                </div>
              </div>
              {/* Compnay Email  */}
              {/* <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Company email (optional)
                </label>
                <div className="mt-1 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-row">
                    <input
                      onChange={(e) => {
                        setCompanyEmail(e.target.value);
                        setIsCompanyVerified(false);
                      }}
                      value={companyEmail}
                      type="text"
                      name="companyEmail"
                      id="companyEmail"
                      autoComplete="companyEmail"
                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                    />
                    <div className="ml-4">
                      {isCompanyVerified ? (
                        <span class="inline-flex items-center p-1 mr-2 text-sm font-semibold text-indigo-800 bg-indigo-100 rounded-full dark:bg-indigo-700 dark:text-gray-300">
                          <svg
                            aria-hidden="true"
                            class="w-3 h-3"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                          <span class="sr-only">Icon description</span>
                        </span>
                      ) : (
                        <p className="text-sm text-gray-500">
                          Email not verified. Verify then you can explore
                          job-seekers
                        </p>
                      )}
                    </div>
                  </div>
                  <p className="mt-1 max-w-2xl text-xs text-gray-700">
                    This email won't be visible to anyone. It is for
                    verification only.
                  </p>
                  <div className="flex flex-row">
                    {isCompanyVerified ? (
                      <></>
                    ) : (
                      <>
                        <p>Verify </p>
                        <button
                          onClick={handleSendEmail}
                          className="font-medium text-indigo-600 hover:text-indigo-500 ml-4"
                        >
                          {emailSent ? "Resend email" : "Send Email"}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-center">
            <button
              disabled={isProcessing}
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Cancel
            </button>
            <button
              disabled={isProcessing}
              type="submit"
              className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Save
            </button>
          </div>
        </div>
        <div ref={scrollRef}>
          <AlertBanner
            errorMessage={errorMessage}
            isDanger={isDanger}
            setIsError={setIsError}
            isError={isError}
          />
        </div>
      </form>
    </>
  );
}
