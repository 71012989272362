import { Route, Routes } from "react-router-dom";
import TechConferences from "../ListOfConferences/TechConferences";
import JobBoard from "../JobBoard/JobBoard";
import Fall23Internship from "./jobboard/fall23/Fall23Internship";
import { useAuth } from "../../context/cognitoUser";
import { useState } from "react";
import { useEffect } from "react";
import AXIOS_CLIENT from "../../utils/api_client";
import Summer24Internship from "./jobboard/summer24/Summer24Internship";

export default function LearnMain() {
  const {
    globalUserType,
    setGlobalUserType,
    user,
    setUser,
    userSession,
    setUserSession,
  } = useAuth();

  const [sid, setSid] = useState();
  const [email, setEmail] = useState();
  const [studentInfo, setStudentInfo] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [totalOpenReferral, setTotalOpenReferral] = useState(0);

  useEffect(() => {
    if (user) setSid(user.username);
  }, [user]);

  useEffect(() => {
    if (userSession) {
      setEmail(userSession.idToken.payload.email);
    }
  }, [userSession]);

  useEffect(() => {
    if (sid) {
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            setStudentInfo(userInfo);

            if (userInfo.numberOfOpenRequest != null)
              setTotalOpenReferral(userInfo.numberOfOpenRequest);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage("Error! Please refresh page.");
          setIsError(true);
        });
    }
  }, [sid]);

  return (
    <Routes>
      <Route path="tech-conferences" element={<TechConferences />}></Route>
      <Route path="job-board" element={<JobBoard />}></Route>
      <Route
        path="fall-23-internship-jobboard"
        element={
          <Fall23Internship
            sid={sid}
            email={email}
            studentInfo={studentInfo}
            totalOpenReferral={totalOpenReferral}
            setTotalOpenReferral={setTotalOpenReferral}
          />
        }
      ></Route>

      <Route
        path="summer-24-internship-jobboard"
        element={
          <Summer24Internship
            sid={sid}
            email={email}
            studentInfo={studentInfo}
            totalOpenReferral={totalOpenReferral}
            setTotalOpenReferral={setTotalOpenReferral}
          />
        }
      ></Route>
    </Routes>
  );
}
