import { useEffect, useState } from "react";

import AXIOS_CLIENT from "../../../../../../../utils/api_client";
import AlertBanner from "../../../../../../../common/components/AlertBanner";
import LoadingPulse from "../../../../../../../common/components/LoadingPulse";
import StudentProfileOverlay from "../explore/components/StudentProfileOverlay";
import CGHistoryRow from "./components/CGHistoryRow";

export default function EmpCGExplore({ eid, email }) {
  const [students, setStudents] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSid, setSelectedSid] = useState();
  const [curIndex, setCurIndex] = useState();
  const [curStudent, setCurStudent] = useState();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    AXIOS_CLIENT.get(`emp/api/referral/get_accepted_cgc`, {
      params: { eid: eid },
    })
      .then((response) => {
        if (response.data.data.length > 0) {
          setStudents(response.data.data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
      });
  }, []);

  const removeElement = (index) => {
    setStudents((students) => {
      let left = students.slice(0, index);
      let right = students.slice(index + 1);
      return [...left, ...right];
    });
  };

  const mainContent = students ? (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {students.map((student, index) => (
          <CGHistoryRow
            student={student}
            index={index}
            setCurIndex={setCurIndex}
            setIsOpen={setIsOpen}
            setCurStudent={setCurStudent}
          />
        ))}
      </ul>
    </div>
  ) : (
    <>You haven't accepted any request as of now. </>
  );

  return isLoading ? (
    <LoadingPulse />
  ) : (
    <div className="pt-4">
      {mainContent}

      <StudentProfileOverlay
        isOpen={isOpen}
        student={curStudent}
        setIsOpen={setIsOpen}
        eid={eid}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsDanger={setIsDanger}
        removeElement={removeElement}
        index={curIndex}
        isAction={false}
      />

      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </div>
  );
}
