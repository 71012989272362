import { PhotoIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import RenderFeedback from "./RenderFeedback";
import AXIOS_CLIENT from "../../../../utils/api_client";
import axios from "axios";
import AlertBanner from "../../../../common/components/AlertBanner";
import LoadingPulse from "../../../../common/components/LoadingPulse";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ResumeReviewForm() {
  const [resumeFeedback, setResumeFeedback] = useState();
  const [rfbHeadline, setRfbHeadline] = useState();
  const [rfbPoints, setRfbPoints] = useState();
  const [file, setFile] = useState();
  const [fileName, setFilename] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);

  function handleFile(event) {
    event.preventDefault();
    setFile(event.target.files[0]);
    setFilename(event.target.files[0].name);
  }

  function getResumeFeedback(event) {
    event.preventDefault();
    setIsProcessing(true);

    const data = new FormData();
    data.append("file", file);
    data.append("filename", fileName);

    axios
      .post("https://api2.futureforte.co/api/services/resume-feedback", data, {
        // AXIOS_CLIENT.post("api/services/resume-feedback", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((response) => {
        setIsProcessing(false);
        if (response.data.rfbHeadline)
          setRfbHeadline(response.data.rfbHeadline);
        if (response.data.rfbPoints) setRfbPoints(response.data.rfbPoints);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
        setIsProcessing(false);
      });
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <form onSubmit={getResumeFeedback}>
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />

                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>{fileName ? fileName : "Upload a resume"}</span>
                        <input
                          required
                          accept="application/pdf"
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleFile}
                        />
                      </label>
                      <p className="pl-1">
                        {fileName ? "" : "or drag and drop"}
                      </p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PDF, up to 1MB
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-center gap-x-6">
              <button
                type="submit"
                disabled={isProcessing}
                className={classNames(
                  isProcessing
                    ? "px-3 py-2 bg-indigo-400 h-max w-max rounded-lg text-white font-bold hover:bg-indigo-300 hover:cursor-not-allowed"
                    : "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                )}
              >
                {isProcessing ? "Processing..." : "Get resume feedback"}
              </button>
            </div>
          </form>

          {isProcessing ? (
            <LoadingPulse />
          ) : (
            <RenderFeedback rfbHeadline={rfbHeadline} rfbPoints={rfbPoints} />
          )}
        </div>
      </div>
      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </>
  );
}
