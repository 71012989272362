import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../context/cognitoUser";
import { studentCognitoUserPool } from "../../../../configs/cognito_user_pool";

import { USER_TYPE } from "../../../../constants/constants";
import ShowAndHidePassword from "../../../../common/components/ShowAndHidePassword";
import AlertBanner from "../../../../common/components/AlertBanner";
import { Auth } from "aws-amplify";

export default function SignInRecMain() {
  const {
    globalUserType,
    setGlobalUserType,
    user,
    setUser,
    userSession,
    setUserSession,
  } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [email, setEmail] = useState();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    try {
      const user = await Auth.signIn(email, passwordInput);
      await setUser(user);
      await getSession().then((result) => {
        setUserSession(result);
        window.localStorage.setItem(
          "accessToken",
          `${result.accessToken.jwtToken}`
        );
      });
      setIsProcessing(false);
      localStorage.setItem("isAuth", true);

      navigate("/hire/dashboard");
    } catch (error) {
      localStorage.removeItem("isAuth");

      setIsProcessing(false);
      if (error.name === "UserNotConfirmedException") {
        navigate("/confirm-email", {
          state: { email: email },
        });
      } else {
        setErrorMessage(error.message);
        setIsError(true);
        setIsDanger(true);
      }
    }
  };
  function getSession() {
    return Auth.currentSession()
      .then((userData) => userData)
      .catch(() => {
        setUserSession(null);
      });
  }

  useEffect(() => {
    if (user) navigate("/hire/dashboard");
  }, [user]);

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-2 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <a href="/">
            <img
              className="mx-auto h-12 w-auto"
              src="/logo512.png"
              alt="FutureForte: Democratize career guidance for college students "
            />
          </a>

          <h2 className=" md:block mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Login
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <ShowAndHidePassword
                  passwordInput={passwordInput}
                  setPasswordInput={setPasswordInput}
                />
              </div>

              <div className="flex-col items-center justify-between">
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password
                  </a>
                </div>
                <div className="text-sm">
                  <a
                    href="/hire/signup"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Don't have account? Sign Up
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isProcessing}
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign In
                </button>
              </div>
              <AlertBanner
                errorMessage={errorMessage}
                isDanger={isDanger}
                setIsError={setIsError}
                isError={isError}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
