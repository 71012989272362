import MainNavbar from "../../common/components/MainNavbar";
import ContactSection from "./components/ContactSection";

export default function ContactUsMain() {
  return (
    <>
      <MainNavbar />
      <ContactSection />
    </>
  );
}
