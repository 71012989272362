import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import ReqeustReferralModal from "./RequestReferralModal";
import AlertBanner from "../../../common/components/AlertBanner";
import FloatingAlert from "./FloatingAlert";

export default function JobTable({
  ALL_OPP,
  sid,
  email,
  studentInfo,
  totalOpenReferral,
  setTotalOpenReferral,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [curOpp, setCurOpp] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);

  const searchFilter = () => {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("search-filter");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");

    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  };

  const renderModal = openModal ? (
    <ReqeustReferralModal
      opp={curOpp}
      openModal={openModal}
      setOpenModal={setOpenModal}
      setErrorMessage={setErrorMessage}
      setIsDanger={setIsDanger}
      setIsError={setIsError}
      sid={sid}
      email={email}
      studentInfo={studentInfo}
      totalOpenReferral={totalOpenReferral}
      setTotalOpenReferral={setTotalOpenReferral}
    />
  ) : (
    <></>
  );

  return (
    <>
      <div className="flex flex-1 ml-10">
        <form className="flex w-full md:ml-0">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <div className="relative w-full text-gray-400 focus-within:text-gray-600">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
              <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            <input
              id="search-filter"
              onKeyUp={searchFilter}
              className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
              placeholder="Search by Company"
              type="search"
              name="search"
            />
          </div>
        </form>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table
                  className="min-w-full divide-y divide-gray-300"
                  id="myTable"
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Company Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Role/ Link
                      </th>
                      <th
                        scope="col"
                        className="truncate px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Reqeust Referral
                      </th>
                      <th
                        scope="col"
                        className="truncate px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Tags
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Location
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {ALL_OPP.map((job, jobIdx) => (
                      <tr
                        key={job.email}
                        className={jobIdx % 2 === 0 ? undefined : "bg-gray-50"}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="flex flex-center">
                            <div className="h-10 w-10 flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={`https://logo.clearbit.com/${job.companyDomain}`}
                                alt=""
                              />
                            </div>
                            <div className="pl-1 pt-2">{job.company}</div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-4 py-4 text-sm text-gray-500">
                          <a
                            href={job.link}
                            target="_blank"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            {job.type ? job.type.toUpperCase() : "Full-time"}
                            {/* <span className="sr-only">{job.link}</span> */}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <button
                            onClick={() => {
                              setCurOpp(job);
                              setOpenModal(true);
                            }}
                            target="_blank"
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            Request Referral
                            <span className="sr-only">, {job.link}</span>
                          </button>
                        </td>
                        <td className="truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {job.tags ? (
                            <ul role="list" className=" divide-gray-200">
                              {job.tags.map((item) => (
                                <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
                                  {item}
                                </span>
                              ))}
                            </ul>
                          ) : (
                            <></>
                          )}
                        </td>

                        <td className="truncate whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {job.location ? job.location : "United States"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {renderModal}

      <FloatingAlert
        setIsError={setIsError}
        errorMessage={errorMessage}
        isDanger={isDanger}
        isError={isError}
      />
    </>
  );
}
