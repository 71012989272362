import "./messenger.css";
import { useContext, useEffect, useRef, useState } from "react";
// import { io } from "socket.io-client";

import Conversation from "./components/conversations/Conversation";
import Message from "./components/message/Message";
import ChatOnline from "./components/chatOnline/ChatOnline";
import AXIOS_CLIENT from "../../../../utils/api_client";
import { USER_TYPE } from "../../../../constants/constants";
import { useAuth } from "../../../../context/cognitoUser";


export default function Messenger() {
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  // const socket = useRef();
  const {
    globalUserType,
    user
  } = useAuth();

  const [uid, setUid]  = useState();

  useEffect(()=>{
     if (user && user.username) setUid(user.username);
  }, [ user]);


  const scrollRef = useRef();

  // useEffect(() => {
  //   socket.current = io("ws://localhost:8900");
  //   socket.current.on("getMessage", (data) => {
  //     setArrivalMessage({
  //       sender: data.senderId,
  //       text: data.text,
  //       createdAt: Date.now(),
  //     });
  //   });
  // }, []);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  // useEffect(() => {
  //   socket.current.emit("addUser", uid);
  //   socket.current.on("getUsers", (users) => {
  //     setOnlineUsers(
  //       //  user.followings.filter((f) => users.some((u) => u.userId === f))
  //       []
  //     );
  //   });
  // }, [loggedInStudentUser]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        if(globalUserType == USER_TYPE.EMP){
          const res = await AXIOS_CLIENT.get(`/api/conversations/emp/${uid}`);
          setConversations(res.data);
        }
      else{
        const res = await AXIOS_CLIENT.get(`/api/conversations/student/${uid}`);
        setConversations(res.data);
      }
      } catch (err) {
      }
    };
    if(uid) getConversations();
  }, [uid]);

  useEffect(()=>{
    if(conversations.length > 0){
      setCurrentChat(conversations[0]);
    }
  }, [conversations]);

  useEffect(() => {
    const getMessages = async () => {
      try {
        if(globalUserType == USER_TYPE.EMP){
          const res = await AXIOS_CLIENT.get(`/api/messages/emp/${currentChat?._id}`);
          setMessages(res.data);
        }
        else{
          const res = await AXIOS_CLIENT.get(`/api/messages/student/${currentChat?._id}`);
          setMessages(res.data);
        }
       
      } catch (err) {
      }
    };
    if(currentChat) getMessages();
  }, [currentChat]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const message = {
      sender: uid,
      text: newMessage,
      conversationId: currentChat._id,
    };

    const receiverId = currentChat.members.find(
      (member) => member !== uid
    );

    // socket.current.emit("sendMessage", {
    //   senderId: uid,
    //   receiverId,
    //   text: newMessage,
    // });

    try {
      if(globalUserType === USER_TYPE.EMP){
        const res = await AXIOS_CLIENT.post(`api/messages/emp`, message);
        setMessages([...messages, res.data]);
        setNewMessage("");
      }
      else{
        const res = await AXIOS_CLIENT.post(`api/messages/student`, message);
        setMessages([...messages, res.data]);
        setNewMessage("");
      }
    
    } catch (err) {
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <>

      <div className="messenger">
        
        <div className="chatMenu">
          <div className="chatMenuWrapper">
            {/* <input placeholder="Search for friends" className="chatMenuInput" /> */}
            {conversations.map((c) => (
              <div onClick={() => setCurrentChat(c)}>
                <Conversation currentChat = {currentChat} conversation={c} currentUser={user} globalUserType= {globalUserType}/>
              </div>
            ))}
          </div>
        </div>
        
        <div className="chatBox">
          <div className="chatBoxWrapper">
            {currentChat ? (
              <>
                <div className="chatBoxTop">
                  {messages.map((m) => (
                    <div ref={scrollRef}>
                      <Message message={m} own={m.sender === uid} />
                    </div>
                  ))}
                </div>
                <div className="chatBoxBottom">
                  <textarea
                    // className="chatMessageInput"
                    className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                    placeholder="write something..."
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                  ></textarea>
                  <button 
                className="mx-5 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleSubmit}>
                    Send
                  </button>
                </div>
              </>
            ) : (
              <div className="mt-4 h-96 rounded-lg border-4 border-dashed border-gray-200" />
            )}
          </div>
        </div>
        {/* <div className="chatOnline">
          <div className="chatOnlineWrapper">
            <ChatOnline
              onlineUsers={onlineUsers}
              currentId={sid}
              setCurrentChat={setCurrentChat}
            />
          </div>
        </div> */}
      </div>
    </>
  );
}
