import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  employeeUserPoolConfig,
  studentUserPoolConfig,
} from "../../../configs/cognito_user_pool";
import { useAuth } from "../../../context/cognitoUser";
import { USER_TYPE } from "../../../constants/constants";
import ShowAndHidePassword from "../../../common/components/ShowAndHidePassword";
import AlertBanner from "../../../common/components/AlertBanner";
import { Amplify, Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

export default function SignInMain() {
  const {
    globalUserType,
    setGlobalUserType,
    user,
    setUser,
    userSession,
    setUserSession,
  } = useAuth();
  const { state } = useLocation();

  const [isProcessing, setIsProcessing] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [userType, setUserType] = useState();
  const [email, setEmail] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (userType) {
      localStorage.setItem("userType", userType);
      setGlobalUserType(userType);
      if (userType == USER_TYPE.STUDENT) {
        Amplify.configure(studentUserPoolConfig);
      } else if (userType === USER_TYPE.EMP) {
        Amplify.configure(employeeUserPoolConfig);
      } else {
        Amplify.configure(studentUserPoolConfig);
      }
    }
  }, [userType]);

  function getSession() {
    return Auth.currentSession()
      .then((userData) => userData)
      .catch(() => {
        setUserSession(null);
      });
  }

  const federedSignIn = () => {
    if (state?.path) {
      localStorage.setItem("lastLocationForFederatedSignIn", state?.path);
    } else {
      localStorage.removeItem("lastLocationForFederatedSignIn");
    }
    if (!userType) {
      setIsProcessing(false);
      setErrorMessage(
        "Please select user type! Are you looking for student or alumni?"
      );
      setIsError(true);
      setIsDanger(true);
      return;
    }

    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!userType) {
      setIsProcessing(false);
      setErrorMessage(
        "Please select user type! Are you looking for student or alumni?"
      );
      setIsError(true);
      setIsDanger(true);
      return;
    }

    try {
      const user = await Auth.signIn(email, passwordInput);
      await setUser(user);
      await getSession().then((result) => {
        setUserSession(result);
        window.localStorage.setItem(
          "accessToken",
          `${result.accessToken.jwtToken}`
        );
      });
      setIsProcessing(false);
      localStorage.setItem("isAuth", true);

      if (userType === USER_TYPE.EMP) {
        navigate("/employee/dashboard");
      } else {
        navigate(state?.path || "/student/dashboard");
      }
    } catch (error) {
      localStorage.removeItem("isAuth");
      localStorage.removeItem("accessToken");
      setIsProcessing(false);
      if (error.name === "UserNotConfirmedException") {
        navigate("/confirm-email", {
          state: { email: email },
        });
      } else {
        setErrorMessage(error.message);
        setIsError(true);
        setIsDanger(true);
      }
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     if (globalUserType === USER_TYPE.EMP) {
  //       navigate("/employee/dashboard");
  //     } else if (globalUserType === USER_TYPE.STUDENT) {
  //       navigate("/student/dashboard");
  //     } else {
  //       navigate("/student/dashboard");
  //     }
  //   }
  // }, [user]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-2 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <a href="/">
            <img
              className="mx-auto h-12 w-auto"
              src="/logo512.png"
              alt="FutureForte: Democratize career guidance for college students "
            />
          </a>

          <h2 className=" md:block mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Login
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" method="POST" onSubmit={handleSubmit}>
              <div className="flex flex-row justify-center">
                <button
                  type="button"
                  onClick={() => {
                    setUserType(USER_TYPE.EMP);
                  }}
                  className={classNames(
                    userType === USER_TYPE.EMP
                      ? "mx-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      : "mx-4 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  )}
                >
                  Employee
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setUserType(USER_TYPE.STUDENT);
                  }}
                  className={classNames(
                    userType === USER_TYPE.STUDENT
                      ? "inline-flex items-center rounded-md border border-transparent bg-indigo-600 mx-4 px-4 py-2 text-sm font-medium text-white shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      : "inline-flex items-center rounded-md border border-transparent mx-4 px-4 py-2 text-sm font-medium shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  )}
                >
                  Student
                </button>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <ShowAndHidePassword
                  passwordInput={passwordInput}
                  setPasswordInput={setPasswordInput}
                />
              </div>

              <div className="flex-col items-center justify-between">
                <div className="text-sm">
                  <a
                    href="/forgot-password"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot password
                  </a>
                </div>
                <div className="text-sm">
                  <a
                    href="/signup"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Don't have account? Sign Up
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={isProcessing}
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign In
                </button>
              </div>
            </form>
            {/* Google signin */}
            {/* <div className="mt-10">
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-3 text-gray-900">
                    Or continue with
                  </span>
                </div>
              </div>

              <div className="mt-6 mx-4 grid grid-cols-1">
                <button
                  onClick={federedSignIn}
                  className="flex justify-center w-full rounded bg-white py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  <svg width="23px" height="23px" viewBox="0 0 32 32">
                    <path
                      d="M23.75,16A7.7446,7.7446,0,0,1,8.7177,18.6259L4.2849,22.1721A13.244,13.244,0,0,0,29.25,16"
                      fill="#00ac47"
                    />
                    <path
                      d="M23.75,16a7.7387,7.7387,0,0,1-3.2516,6.2987l4.3824,3.5059A13.2042,13.2042,0,0,0,29.25,16"
                      fill="#4285f4"
                    />
                    <path
                      d="M8.25,16a7.698,7.698,0,0,1,.4677-2.6259L4.2849,9.8279a13.177,13.177,0,0,0,0,12.3442l4.4328-3.5462A7.698,7.698,0,0,1,8.25,16Z"
                      fill="#ffba00"
                    />
                    <path
                      d="M16,8.25a7.699,7.699,0,0,1,4.558,1.4958l4.06-3.7893A13.2152,13.2152,0,0,0,4.2849,9.8279l4.4328,3.5462A7.756,7.756,0,0,1,16,8.25Z"
                      fill="#ea4435"
                    />
                    <path
                      d="M29.25,15v1L27,19.5H16.5V14H28.25A1,1,0,0,1,29.25,15Z"
                      fill="#4285f4"
                    />
                  </svg>

                  <span className="font-semibold leading-6 pl-2 pr-2">
                    Google
                  </span>
                </button>
              </div>
            </div> */}
          </div>
          <AlertBanner
            errorMessage={errorMessage}
            isDanger={isDanger}
            setIsError={setIsError}
            isError={isError}
          />
        </div>
      </div>
    </>
  );
}
