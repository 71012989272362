export default function LinkAndTextRow({ label, value }) {
  return value ? (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="ml-4 flex-shrink-0">
          <a
            target="_blank"
            href={value}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            {label}
          </a>
        </div>
      </dd>
    </div>
  ) : (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <div className="ml-4 flex-shrink-0">
          <a
            target="_blank"
            href={value}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            -
          </a>
        </div>
      </dd>
    </div>
  );
}
