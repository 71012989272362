import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  CloudArrowUpIcon,
  ComputerDesktopIcon,
  SparklesIcon,
  TrophyIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import CollegeLogoCloud from "../MainPage/components/CollegesLogoCloud";
import Registration from "./registration/Registration";
import { useAuth } from "../../context/cognitoUser";
import { useNavigate } from "react-router-dom";
import AXIOS_CLIENT from "../../utils/api_client";

const navigation = [
  { name: "Company", href: "/about-us" },
  { name: "Job Board", href: "/job-board" },
  { name: "Our Team", href: "/our-team" },
  { name: "Find Talent", href: "/hire" },
  { name: "Dashboard", href: "/dashboard" },
];
const features = [
  {
    name: "Register",
    description:
      "Register for the coding contest on the FutureForte website. The registration FREE and always will be.",
    icon: CloudArrowUpIcon,
  },
  {
    name: "Participate",
    description:
      "Students participate in the coding contest by accessing the coding platform provided by FutureForte. They will receive instructions on the coding challenge and can start working on it.",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Top Performers",
    description:
      "FutureForte will announce the top performers in the coding contest. ",
    icon: TrophyIcon,
  },
  {
    name: "Refers",
    description:
      "FutureForte share results with potential employers on our platform and refers candidates to employers for their job requirements .",
    icon: SparklesIcon,
  },
];

const faqs = [
  {
    id: 1,
    question: "What are prizes?",
    answer:
      "Top 10 candidates win Amazon Echo Dot. In order to eligible for prizes, you muster register for on FutureForte as this contest for it's users.",
  },
  {
    id: 2,
    question: "How many problems will be there in contest",
    answer: "There will 5 problems in the contest. You can solve in any order.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ContestMain() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { loggedInStudentUser, studentSession } = useAuth();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [isRegisterForContest1, setIsRegisteredForContest1] = useState(false);

  const [sid, setSid] = useState();
  useEffect(() => {
    if (loggedInStudentUser && loggedInStudentUser.username)
      setSid(loggedInStudentUser.username);
  }, [loggedInStudentUser]);

  useEffect(() => {
    if (sid)
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            if (userInfo.isRegisterForContest1)
              setIsRegisteredForContest1(userInfo.isRegisterForContest1);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage("Error while getting informaiton", error.toString());
          setIsError(true);
        });
  }, [sid]);

  return (
    <div className="bg-white">
      {/* Header */}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          className="flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">FutureForte</span>
              <img className="h-8 w-10" src="/logo512.png" alt="" />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a
              href="#"
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">FutureForte</span>
                <img className="h-8 w-10" src="/logo512.png" alt="" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="isolate">
        {/* Hero section */}
        <div className="relative pt-14">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl text-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                  Code Quest 2.0
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  Are you looking for a job or internship? Code quest is the
                  best opportunity to showcase your programming skills. We
                  connect students with potential employers.
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  {isRegisterForContest1 ? (
                    <button
                      disabled={isProcessing}
                      className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      You have registered!
                    </button>
                  ) : (
                    <Registration
                      sid={sid}
                      isRegisterForContest1={isRegisterForContest1}
                      setIsRegisteredForContest1={setIsRegisteredForContest1}
                    />
                  )}
                </div>
              </div>
              <div className="mt-16 flow-root sm:mt-24">
                <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                  <img
                    src="/assets/contest/3.png"
                    alt="App screenshot"
                    width={2432}
                    height={1442}
                    className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div>

        {/* Registration */}
        <div className="relative pt-14"></div>

        {/* <div className="relative pt-14">
          <Registration />
        </div> */}

        {/* Feature section */}
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-16 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              All you have to do is participate in coding contest
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Register for FREE
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        {/* Logo cloud */}
        <CollegeLogoCloud />

        {/* FAQs */}
        <div className="mx-auto max-w-2xl divide-y divide-gray-900/10 px-6 pb-8 sm:pb-24 sm:pt-12 lg:max-w-7xl lg:px-8 lg:pb-32">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2>
          <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <div
                key={faq.id}
                className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8"
              >
                <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">
                  {faq.question}
                </dt>
                <dd className="mt-4 lg:col-span-7 lg:mt-0">
                  <p className="text-base leading-7 text-gray-600">
                    {faq.answer}
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </main>
    </div>
  );
}
