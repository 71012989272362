import {
  BoltIcon,
  GlobeAltIcon,
  SparklesIcon,
  StarIcon,
  ForwardIcon,
  RocketLaunchIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";

const features = [
  {
    name: "Bias for Action",
    description:
      "Impatient with action and patient with results. Many decisions and actions are reversible and do not need extensive study. We value calculated risk taking.",
    icon: ForwardIcon,
  },
  {
    name: "Customer Obsession",
    description: "Obsess with customer like Amazon",
    icon: StarIcon,
  },
  {
    name: "Move Fast",
    description:
      "Move fast like Meta. Move fast helps us to build and learn faster than anyone else. ",
    icon: RocketLaunchIcon,
  },
  {
    name: "Build Awesome Things",
    description:
      "We build great product like Apple. Build awesome things pushes us to ship things that are not just good, but also awe-inspiring. Like Apple products or Porsche 911.",
    icon: SparklesIcon,
  },
  {
    name: "Hire the Best",
    description:
      "We hire the best of the best so that they can tell us what we need to do. We do not hire people to tell them what to do. We only hire candidates when we are confident that they excel in a particular field or context, beyond our level.",
    icon: TrophyIcon,
  },
];

export default function CompanyValues() {
  return (
    <div className="overflow-hidden bg-gray-50">
      <div className="relative mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
        <svg
          className="absolute top-0 left-full -translate-x-1/2 -translate-y-3/4 transform lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={784}
            fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
          />
        </svg>

        <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div className="lg:col-span-1">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our values.
            </h2>
          </div>
          <dl className="mt-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0 lg:col-span-2 lg:mt-0">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex h-12 w-12 items-center justify-center rounded-md bg-indigo-500 text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg font-medium leading-6 text-gray-900">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
