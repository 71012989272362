import { Switch } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AXIOS_CLIENT from "../../../../../../utils/api_client";
import AlertBanner from "../../../../../../common/components/AlertBanner";
import HowItWorksCG from "./components/HowItWorksCG";
import { CAREER_GOALS } from "../../../../../../common/consts/Consts";
import Select from "react-select";

export default function StudentCGRequest({ sid, email }) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const navigate = useNavigate();
  const [cgResumeReview, setCGResumeReview] = useState();
  const [cgMockInterview, setCGMockInterview] = useState();
  const [cg1Mentorship, setCG1Mentorship] = useState();
  const [cgInterviewPrep, setCGInterviewPrep] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [careerGoals, setCareerGoals] = useState(null);

  useEffect(() => {
    if (sid) {
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length === 0) {
            if (!isAlert) {
              setIsAlert(true);
              alert(
                "Please complete your profile then you can request for career guidance"
              );
              navigate("/student/dashboard/profile");
            }
          } else {
            const userInfo = response.data.data[0];
            if (userInfo.cg1Mentorship)
              setCG1Mentorship(userInfo.cg1Mentorship);
            if (userInfo.cgInterviewPrep)
              setCGInterviewPrep(userInfo.cgInterviewPrep);
            if (userInfo.cgMockInterview)
              setCGMockInterview(userInfo.cgMockInterview);
            if (userInfo.cgResumeReview)
              setCGResumeReview(userInfo.cgResumeReview);
            if (userInfo.careerGoals) setCareerGoals(userInfo.careerGoals);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          if (!isAlert) {
            setIsAlert(true);
            alert(
              "Please complete your profile then you can request for career guidance"
            );
            navigate("/student/dashboard/profile");
          }
          setErrorMessage("Error! Please refresh page.");
          setIsError(true);
        });
    }
  }, [sid]);

  const onSubmitForm = (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      sid: sid,
      cgResumeReview: cgResumeReview,
      cg1Mentorship: cg1Mentorship,
      cgMockInterview: cgMockInterview,
      cgInterviewPrep: cgInterviewPrep,
      careerGoals: careerGoals,
    };
    const config = {
      headers: {
        username: sid,
      },
    };

    AXIOS_CLIENT.put(`student/user/${sid}`, userObject, config)
      .then((res) => {
        setIsDanger(false);
        setIsError(true);
        setErrorMessage("Your data is successfully updated!");
        setIsProcessing(false);
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setIsProcessing(false);
        setErrorMessage(error.toString());
      });
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div className="bg-white lg:min-w-0 lg:flex-1">
        <div className="pr-6 pt-4 pb-4 ">
          <div className="flex items-center">
            <h1 className="flex-1 text-lg font-medium">
              Request Career Guidance
            </h1>
          </div>
        </div>
        <form onSubmit={onSubmitForm}>
          <div className="sm:grid sm:grid-cols-3  sm:items-start sm:gap-2  ">
            <div className="flex flex-col">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium my-0 sm:mt-px sm:pt-1 py-0"
              >
                Career Goals
              </label>
              <p className="text-xs text-gray-500 py-2 px-2">
                Outline your career goals. What helps you are looking from
                alumni.
              </p>
            </div>
            <Select
              value={careerGoals}
              isMulti
              name="colors"
              options={CAREER_GOALS}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={setCareerGoals}
            />
          </div>

          <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2  ">
            <label className="text-gray-900">
              Are you looking for Resume Review?
            </label>
            <div className="px-8">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={cgResumeReview}
                  onChange={setCGResumeReview}
                  className={classNames(
                    cgResumeReview ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      cgResumeReview ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>

          <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2  ">
            <label className="text-gray-900">
              Are you looking for Interview Prep&Tips?
            </label>
            <div className="px-8">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={cgInterviewPrep}
                  onChange={setCGInterviewPrep}
                  className={classNames(
                    cgInterviewPrep ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      cgInterviewPrep ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>
          <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2  ">
            <label className="text-gray-900">
              Are you looking for Mock Interview?
            </label>
            <div className="px-8">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={cgMockInterview}
                  onChange={setCGMockInterview}
                  className={classNames(
                    cgMockInterview ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      cgMockInterview ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>

          <div className="pt-4 sm:grid sm:grid-cols-2  sm:items-start sm:gap-2  ">
            <label className="text-gray-900">
              Are you looking for 1:1 Mentorship?
            </label>
            <div className="px-8">
              <Switch.Group as="div" className="flex items-center">
                <Switch
                  checked={cg1Mentorship}
                  onChange={setCG1Mentorship}
                  className={classNames(
                    cg1Mentorship ? "bg-indigo-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      cg1Mentorship ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
          </div>

          {/* Save and cancel */}
          <div className="pt-8">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={() => {
                  setIsProcessing(false);
                }}
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isProcessing}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Save
              </button>
            </div>
          </div>
        </form>
        <HowItWorksCG />

        <AlertBanner
          errorMessage={errorMessage}
          isDanger={isDanger}
          setIsError={setIsError}
          isError={isError}
        />
      </div>
    </>
  );
}
