import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/cognitoUser";
import { USER_TYPE } from "../../constants/constants";

import { Auth } from "aws-amplify";

import TextPair from "./Components/TextPair";
import AlertBanner from "../../common/components/AlertBanner";
import { Hub } from "aws-amplify";

export default function EmailVerification() {
  const [isError, setIsError] = useState(false);
  const [isDanger, setIsDanger] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const props = useLocation();

  const { globalUserType, setUser } = useAuth();

  function listenToAutoSignInEvent() {
    Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      if (event === "autoSignIn") {
        const user = payload.data;
        setUser(user);
        if (globalUserType) {
          if (globalUserType === USER_TYPE.STUDENT) {
            navigate("/student/dashboard");
          } else if (globalUserType === USER_TYPE.EMP) {
            navigate("/employee/dashboard");
          } else if (globalUserType === USER_TYPE.RECRUITER) {
            navigate("/hire/dashboard");
          }
        }
      } else if (event === "autoSignIn_failure") {
        navigate("/signin");
      }
    });
  }

  useEffect(() => {
    setEmail(props.state?.email);
  }, [props]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    if (!email) {
      navigate("/signup");
      setIsProcessing(false);
      return;
    }
    if (!globalUserType) {
      navigate("/signup");
      return;
    }
    const data = new FormData(event.currentTarget);
    const code = data.get("code");

    if (globalUserType && email) {
      try {
        await Auth.confirmSignUp(email, code);
        setIsProcessing(false);
        alert("Successfully email verified! Please signin for next steps");
        if (globalUserType === USER_TYPE.STUDENT) {
          navigate("/student/dashboard");
        } else if (globalUserType === USER_TYPE.EMP) {
          navigate("/employee/dashboard");
        } else if (globalUserType === USER_TYPE.RECRUITER) {
          navigate("/hire/dashboard");
        }
      } catch (error) {
        setIsProcessing(false);
        setErrorMessage("Error while confirming your email!. Please try again");
        setIsError(true);
      }
    }
  };

  const resendVerificationCode = async () => {
    if (!email) {
      navigate("/signup");
      return;
    }
    if (!globalUserType) {
      navigate("/signup");
      return;
    }
    if (globalUserType && email) {
      try {
        await Auth.resendSignUp(email);
        setIsDanger(false);
        setIsError(true);
        setErrorMessage("Code is successfully sent to your email");
      } catch (err) {
        setIsDanger(true);
        setIsError(true);
        setErrorMessage(
          "Error while sending code. Please contact us for more details."
        );
      }
    }
  };

  const renderEmail = email ? <TextPair label="Email" value={email} /> : <></>;

  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <a href="/">
              <img
                className="mx-auto h-12 w-auto"
                src="/logo512.png"
                alt="FutureForte: Democratize career guidance for college students "
              />
            </a>
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              Confirm your email to continue
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {renderEmail}
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <input
                  id="email-address"
                  name="code"
                  type="text"
                  className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Enter code sent to your email"
                />
              </div>
            </div>

            <div>
              <div className="flex flex-col float-left text-sm mb-2">
                <button
                  type="button"
                  onClick={resendVerificationCode}
                  disabled={isProcessing}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Resend Code
                </button>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/signin");
                  }}
                  disabled={isProcessing}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Go to Sign In
                </button>
              </div>
              <button
                type="submit"
                disabled={isProcessing}
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3"></span>
                Submit
              </button>
            </div>
            <AlertBanner
              errorMessage={errorMessage}
              isDanger={isDanger}
              setIsError={setIsError}
              isError={isError}
            />
          </form>
        </div>
      </div>
    </>
  );
}
