import { EnvelopeIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import AXIOS_CLIENT from "../../../../../../utils/api_client";

export default function StudentHistoryRow({
  referral,
  sid,
  setIsError,
  setIsDanger,
  setErrorMessage,
}) {
  const emp = referral?.emp;
  const [referralConfirmed, setReferralConfiemd] = useState(
    referral.referralsCompleted ?? false
  );
  const [isProcessing, setIsProcessing] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const config = {
    headers: {
      username: sid,
    },
  };

  function updateReferralCount() {
    setIsProcessing(true);
    try {
      AXIOS_CLIENT.put(
        `student/api/referral/confirm_referral/${referral._id}`,
        referral,
        config
      )
        .then((res) => {
          setIsDanger(false);
          setIsError(true);
          setErrorMessage(
            "Referral successfully confirmed! Your total open referral count is successfully updated. Now you can request for more job referrals"
          );
          setIsProcessing(false);
        })
        .catch((err) => {
          setIsDanger(true);
          setIsError(true);
          setErrorMessage("Error while processing");
          setIsProcessing(false);
        });
    } catch (err) {
      setIsProcessing(false);
      setIsDanger(true);
      setIsError(true);
      setErrorMessage("Error while processing");
    }
  }
  const renderJobIds = referral?.jobIds ? (
    <ul role="list" className="divide-gray-200">
      {referral.jobIds.map((item) => (
        <li key={item}>
          <div className="flex flex-row">
            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              {item}
            </p>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

  return (
    <li key={referral.email}>
      <div className="flex items-center px-4 py-2 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 px-4 grid grid-cols-2 md:grid md:grid-cols-4 md:gap-4">
            <div>
              <p className="text-sm text-gray-900">Referee: </p>
              <p className="truncate text-sm font-medium text-indigo-600">
                {emp.firstName ?? ""}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span>Email: {emp?.email}</span>
              </p>
            </div>
            <div className="flex flex-center">
              <div className="h-10 w-10 flex-shrink-0">
                <img
                  className="h-10 w-10 rounded-full"
                  src={`https://logo.clearbit.com/${referral.companyDomain}`}
                  alt=""
                />
              </div>
              <div className="text-indigo-600 pl-2 pt-2">
                {referral.companyName ?? ""}
              </div>
            </div>
            <div className=" md:block">
              <div>
                <p className="text-sm text-gray-900">Job Ids: </p>
                {renderJobIds}
              </div>
            </div>
            <div className=" md:block">
              <div>
                <Switch.Group as="div" className="flex flex-row items-center">
                  {referralConfirmed ? (
                    <></>
                  ) : (
                    <Switch
                      data-te-toggle="tooltip"
                      title=" If you get an email for successful referral then pls confirm
                    it will udpate your total open referral count and allow you
                    request more."
                      checked={referralConfirmed}
                      onChange={() => {
                        if (
                          referralConfirmed === false &&
                          isProcessing === false
                        ) {
                          setReferralConfiemd(true);
                          updateReferralCount();
                        }
                      }}
                      className={classNames(
                        referralConfirmed ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          referralConfirmed ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                  )}
                  <Switch.Label as="span" className="ml-3 text-sm">
                    <span className="font-medium text-gray-900">
                      {referralConfirmed ? "Closed" : "Confirm"}
                    </span>
                  </Switch.Label>
                </Switch.Group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
