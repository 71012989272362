import axios from "axios";
import { BASE_URL_API_GATEWAY } from "../constants/constants";
import { Auth } from "aws-amplify";

const AXIOS_CLIENT = axios.create({
  baseURL: BASE_URL_API_GATEWAY,
});
// headers: {
//   "Access-Control-Allow-Origin": "*", // Required for CORS support to work
//   "Access-Control-Allow-Credentials": true, // Required for cookies, authorization headers with HTTPS
// },

AXIOS_CLIENT.interceptors.request.use(function (config) {
  const authKey = localStorage.getItem("accessToken");

  if (authKey) {
    config.headers["Authorization"] = authKey;
  }
  return config;
});

function getSeesion() {
  return Auth.currentSession()
    .then((userData) => userData)
    .catch(() => {});
}

AXIOS_CLIENT.interceptors.response.use(
  function (response) {
    return response;
  },
  function (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      getSeesion().then((userData) => {
        if (userData) {
          window.localStorage.setItem(
            "accessToken",
            `${userData.accessToken.jwtToken}`
          );
          console.log("Testing");
        }
        console.log(userData);
      });
      window.location.reload(false);

      return Promise.reject(err);
    }

    return Promise.reject(err);
  }
);

export default AXIOS_CLIENT;
