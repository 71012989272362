import { useState } from "react";

function ShowAndHidePassword({ passwordInput, setPasswordInput }) {
  const [showPass, setShowPass] = useState(false);
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    setShowPass(!showPass);
  };
  return (
    <div className="relative mt-1">
      <input
        type={showPass ? "text" : "password"}
        onChange={handlePasswordChange}
        value={passwordInput}
        name="password"
        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      />
      <div className="absolute inset-y-0 right-0 flex items-center px-2">
        <button className="btn btn-outline-primary" onClick={togglePassword}>
          <label
            className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer js-password-label"
            htmlFor="toggle"
          >
            {showPass ? "hide" : "show"}
          </label>
        </button>
      </div>
    </div>
  );
}
export default ShowAndHidePassword;
