import { useEffect, useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function RenderFeedback({ rfbHeadline, rfbPoints }) {
  return rfbHeadline ? (
    <div className="border shadow rounded-md p-8 mt-4 bg-neutral-50">
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Here's feedback for your resume
      </h1>
      <p className="text-xl leading-8 text-gray-700 mt-2">{rfbHeadline}</p>
      <ul role="list" className="mt-8 space-y-8 text-gray-600">
        {rfbPoints ? (
          rfbPoints.map((text) => (
            <li className="flex gap-x-3">
              <CheckCircleIcon
                className="mt-1 h-5 w-5 flex-none text-indigo-600"
                aria-hidden="true"
              />

              <span>{text}</span>
            </li>
          ))
        ) : (
          <></>
        )}
      </ul>
    </div>
  ) : (
    <></>
  );
}
