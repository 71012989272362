import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import LinkAndTextRow from "../../../../../referral/explore/components/LinkAndTextRow";
import RenderListStudentOverlay from "../../../../../referral/explore/components/RenderListStudentOverlay";
import AXIOS_CLIENT from "../../../../../../../../utils/api_client";

export default function StudentProfileOverlay({
  isOpen,
  student,
  setIsOpen,
  eid,
  setIsError,
  setErrorMessage,
  setIsDanger,
  removeElement,
  index,
  isAction,
  empInfo,
}) {
  const [isProcessing, setIsProcessing] = useState(false);

  const navigate = useNavigate();

  const onAcceptCGC = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = {
      sid: student?.sid,
      eid: eid,
      student: student?._id,
      emp: empInfo?._id,
    };
    const config = {
      headers: {
        username: eid,
      },
    };

    await AXIOS_CLIENT.put(`emp/api/referral/accept_cgc`, userObject, config)
      .then((res) => {
        removeElement(index);
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setErrorMessage("Error while processing your request");
        setIsProcessing(false);
      });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-500"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200 sm:duration-500"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Student Information
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setIsOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {/* Replace with your content */}
                        {/* <div
                          className="h-full border-2 border-dashed border-gray-200"
                          aria-hidden="true"
                        /> */}
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Applicant Information
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                              Personal details and application.
                            </p>
                          </div>
                          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Full name
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {student?.firstName ?? ""}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Email address
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {student?.email ?? ""}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Contact
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {student?.contact ?? ""}
                                </dd>
                              </div>

                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  About
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {student?.about}
                                </dd>
                              </div>
                              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">
                                  Attachments
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  <ul
                                    role="list"
                                    className="divide-y divide-gray-200 rounded-md border border-gray-200"
                                  >
                                    <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                                      <div className="flex w-0 flex-1 items-center">
                                        <PaperClipIcon
                                          className="h-5 w-5 flex-shrink-0 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-2 w-0 flex-1 truncate">
                                          Resume
                                        </span>
                                      </div>
                                      <div className="ml-4 flex-shrink-0">
                                        {student?.resumeLink ? (
                                          <a
                                            href={student?.resumeLink}
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                          >
                                            Download
                                          </a>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </li>
                                  </ul>
                                </dd>
                              </div>
                            </dl>
                          </div>
                        </div>
                        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                          <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">
                              Other Information
                            </h3>
                          </div>
                          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <LinkAndTextRow
                                label="LinkedIn"
                                value={student?.linkedInUrl}
                              />
                              <LinkAndTextRow
                                label="Github"
                                value={student?.githubUrl}
                              />
                              <LinkAndTextRow
                                label="Portfolio"
                                value={student?.portfolioUrl}
                              />
                              <RenderListStudentOverlay
                                arr={student?.skills}
                                label="Skills"
                              />
                              <RenderListStudentOverlay
                                arr={student?.tools}
                                label="Tools and Tech-stacks"
                              />
                            </dl>
                          </div>
                        </div>

                        {/* /End replace */}
                      </div>
                    </div>
                    {isAction ? (
                      <div className="flex flex-shrink-0 justify-center px-4 py-4">
                        <button
                          type="submit"
                          onClick={onAcceptCGC}
                          disabled={isProcessing}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Connect
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
