import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

export default function CopyCliboard({ text }) {
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {}, [isClicked]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsClicked(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [isClicked]);

  return (
    <div className="flex flex-col">
      <button
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(text);
          setIsClicked(true);
        }}
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      >
        {" "}
        <ClipboardDocumentIcon className="h-5 w-5" aria-hidden="true" />
      </button>
      {isClicked ? <p>copied</p> : <></>}
    </div>
  );
}
