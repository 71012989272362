import { useEffect } from "react";
import MainNavbar from "../../common/components/MainNavbar";
import CompanyMissionComponent from "../about_us/components/companyMissionComponent";
import CompanyValues from "../about_us/components/aboutUsContent";
import ForUniHeroSection from "./components/ForUniHeroSection";
import PricingComponent from "../pricing/components/PricingComponent";

export default function ForUniversity() {
  const html_dom = `<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script><iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrQaTvtPVq0BbWFW?backgroundColor=purple" frameborder="0" onmousewheel="" width="100%" height="1040" style="background: transparent; border: 1px solid #ccc;"></iframe>`;
  const renderAirtableForm = (
    <div dangerouslySetInnerHTML={{ __html: html_dom }} />
  );
  const TITLE = "FutureForte: Democratize career guidance for college students";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <>
      <MainNavbar />
      <ForUniHeroSection />
      <PricingComponent />
    </>
  );
}
