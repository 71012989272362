import MainPageHeader from "./components/MainPageHeader";
import Testimonials from "./components/Testimonials";
import FaqSection from "./components/FaqSection";
import CollegeLogoCloud from "./components/CollegesLogoCloud";
import ComapanyLogoCloud from "./components/CompanyLogoCloud";

export default function MainPage() {
  return (
    <>
      <MainPageHeader />
      <Testimonials />
      <CollegeLogoCloud />
      <ComapanyLogoCloud />
      <FaqSection />
    </>
  );
}
