import { useNavigate } from "react-router-dom";
import ShowAndHidePassword from "../../../common/components/ShowAndHidePassword";
import { USER_TYPE } from "../../../constants/constants";
import { useEffect, useState } from "react";
import { Amplify, Auth } from "aws-amplify";
import {
  employeeUserPoolConfig,
  studentUserPoolConfig,
} from "../../../configs/cognito_user_pool";

import AlertBanner from "../../../common/components/AlertBanner";
import { useAuth } from "../../../context/cognitoUser";
export default function ForgotPasswordMain() {
  const [isProcessing, setIsProcessing] = useState(false);
  const { setGlobalUserType } = useAuth();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [code, setCode] = useState();
  const [email, setEmail] = useState();
  const [isSent, setIsSent] = useState(false);
  const [userType, setUserType] = useState(USER_TYPE.STUDENT);
  const [passwordInput, setPasswordInput] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("userType", userType);
    setGlobalUserType(userType);
    if (userType == USER_TYPE.STUDENT) {
      Amplify.configure(studentUserPoolConfig);
    } else if (userType === USER_TYPE.EMP) {
      Amplify.configure(employeeUserPoolConfig);
    } else {
      Amplify.configure(studentUserPoolConfig);
    }
  }, [userType]);

  const handleForgotPassword = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (email && code) {
      Auth.forgotPasswordSubmit(email, code, passwordInput)
        .then((data) => {
          alert("Your password is successfully updated");

          navigate("/signin");
        })
        .catch((err) => {
          setErrorMessage(err.toString());
          setIsError(true);
          setIsDanger(true);
        });
    }

    setIsProcessing(false);
  };

  const handleSendCode = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (email) {
      Auth.forgotPassword(email)
        .then((data) => {
          setIsSent(true);
          setIsProcessing(false);
          setIsError(true);
          setIsDanger(false);
          setErrorMessage("Verification code is successfully sent!");
        })
        .catch((err) => {
          setIsSent(false);
          setIsProcessing(false);
          setIsError(true);
          setErrorMessage("Error while sending code. Please try it again");
        });
    }
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const getEmailForm = (
    <form
      className="space-y-2"
      action="#"
      method="POST"
      onSubmit={handleSendCode}
    >
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Enter Email address
        </label>
        <div className="mt-1">
          <input
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm">
          {isSent ? (
            <button
              onClick={handleSendCode}
              disabled={isProcessing}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              Resend Code
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div>
        {isSent ? (
          <></>
        ) : (
          <button
            onClick={handleSendCode}
            disabled={isProcessing}
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            {isProcessing ? (
              <svg class="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                Processing
              </svg>
            ) : (
              "Send code"
            )}
          </button>
        )}
      </div>
    </form>
  );

  const setPasswordForm = (
    <form className="space-y-6" onSubmit={handleForgotPassword}>
      <div>
        <label
          htmlFor="code"
          className="block text-sm font-medium text-gray-700"
        >
          Enter Code sent to your email
        </label>
        <div className="mt-1">
          <input
            onChange={(e) => {
              setCode(e.target.value);
            }}
            id="code"
            name="code"
            type="text"
            required
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium text-gray-700"
        >
          Enter new password
        </label>
        <ShowAndHidePassword
          passwordInput={passwordInput}
          setPasswordInput={setPasswordInput}
        />
      </div>

      <div>
        <button
          type="submit"
          disabled={isProcessing}
          className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          {isProcessing ? (
            <svg
              class="animate-spin h-5 w-5 mr-3 ..."
              viewBox="0 0 24 24"
            ></svg>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );

  //  className=

  const renderUserSelection = (
    <div className="flex flex-row justify-center mb-4">
      <button
        type="button"
        onClick={() => {
          setUserType(USER_TYPE.EMP);
        }}
        className={classNames(
          userType === USER_TYPE.EMP
            ? "mx-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            : "mx-4 inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        )}
      >
        Employee
      </button>
      <button
        type="button"
        onClick={() => {
          setUserType(USER_TYPE.STUDENT);
        }}
        className={classNames(
          userType === USER_TYPE.STUDENT
            ? "inline-flex items-center rounded-md border border-transparent bg-indigo-600 mx-4 px-4 py-2 text-sm font-medium text-white shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            : "inline-flex items-center rounded-md border border-transparent mx-4 px-4 py-2 text-sm font-medium shadow-sm  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        )}
      >
        Student
      </button>
    </div>
  );

  const renderEmail = <p>{email}</p>;
  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="/logo512.png"
            alt="FutureForte: Democratize career guidance for college students "
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Forgot password
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {renderUserSelection}
            {getEmailForm}

            {isSent ? setPasswordForm : <></>}
            <div className="my-2">
              <a
                href="/signin"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Sign In
              </a>
            </div>
            <AlertBanner
              errorMessage={errorMessage}
              isDanger={isDanger}
              setIsError={setIsError}
              isError={isError}
            />
          </div>
        </div>
      </div>
    </>
  );
}
