export default function CollegeLogoCloud() {
  return (
    <section className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            We’re always in good company
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Students from following universities are using our platform.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-7xl pt-8 px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/asu.edu"
            alt="College"
            width={158}
            height={48}
          />

          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/mit.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/purdue.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/stanford.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/ufl.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/northwestern.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/nyu.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/utexas.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/yale.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/tufts.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/wm.edu"
            alt="College"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/drexel.edu"
            alt="College"
            width={158}
            height={48}
          />
        </div>
        <div className="mt-16 flex justify-center">
          <p className="relative rounded-full px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/10 hover:ring-gray-900/20">
            <span className="hidden md:inline">
              FutureForte is used by 5000+ students across North America.
            </span>
          </p>
        </div>
      </div>
    </section>
  );
}
