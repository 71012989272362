export default function CompanyMissionComponent() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          {/* <h2 className="text-lg font-semibold text-indigo-600">Our Mission</h2> */}
          <p className="mt-1 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            Our Mission
          </p>
          <p className="mx-auto mt-5 max-w-xl text-xl text-gray-500">
            We help students with career guidance and jobs by connecting them
            with alumni.
          </p>
        </div>
      </div>
    </div>
  );
}
