import {
  ChevronRightIcon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/20/solid";
import { format } from "timeago.js";
import { ClockIcon } from "@heroicons/react/24/outline";
import CopyCliboard from "../../../../../../common/components/CopyClipboard";

export default function EmpHistoryRow({ referral, setCurStudent, setIsOpen }) {
  const studentInfo = referral.student;
  const renderJobIds = referral?.jobIds ? (
    <ul role="list" className="divide-gray-200">
      {referral.jobIds.map((item) => (
        <li key={item}>
          <div className="flex flex-row">
            <CopyCliboard text={item} />

            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              {item}
            </p>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

  const handleOpenOverlay = () => {
    setIsOpen(true);
    setCurStudent(referral?.student);
  };

  return (
    <li key={referral.email}>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
            <div>
              <p className="truncate text-sm font-medium text-indigo-600">
                {studentInfo?.firstName ?? ""}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">{studentInfo?.email ?? ""}</span>
              </p>

              <p className="mt-2 flex items-center text-sm text-gray-500">
                {studentInfo?.contact ? (
                  <PhoneIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                <span className="truncate">
                  {studentInfo?.contact ? studentInfo?.contact : ""}
                </span>
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <p className="text-sm text-gray-900">Job Ids: </p>
                {renderJobIds}
              </div>
            </div>
            <div className="hidden md:block">
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {referral.updatedAt ? (
                  <ClockIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                <span className="">
                  {referral.updatedAt ? format(referral.updatedAt) : ""}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div>
          <button onClick={handleOpenOverlay}>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </li>
  );
}
