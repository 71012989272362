import ReferralRequestForm from "./components/ReferralRequestForm";
import { useEffect, useState } from "react";

import { MAX_REFERRAL_REQUEST } from "../../../../../constants/constants";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import AlertBanner from "../../../../../common/components/AlertBanner";
import { useNavigate } from "react-router-dom";
import HowItWorksJR from "./components/HowItWorksJR";

export default function ApplyReferral({
  totalOpenReferral,
  setTotalOpenReferral,
  sid,
  email,
  studentInfo,
}) {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sid) {
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length === 0) {
            if (!isAlert) {
              setIsAlert(true);
              alert(
                "Please complete your profile then you can request for job referrals"
              );
              navigate("/student/dashboard/profile");
            }
          }
        })
        .catch((error) => {
          setIsDanger(true);
          if (!isAlert) {
            setIsAlert(true);
            alert(
              "Please complete your profile then you can request for job referrals"
            );
            navigate("/student/dashboard/profile");
          }
          setErrorMessage("Error! Please refresh page.");
          setIsError(true);
        });
    }
  }, [sid]);

  return (
    <>
      <div className="bg-white lg:min-w-0 lg:flex-1">
        <div className="flex flex-row">
          <label>Total Open Referral Request Left:</label>
          <p className="text-indigo-600 px-2 font-medium">
            {MAX_REFERRAL_REQUEST - (totalOpenReferral ? totalOpenReferral : 0)}
          </p>
        </div>
        <div className="pr-6 pt-4 pb-4 ">
          <div className="flex items-center">
            <h1 className="flex-1 text-lg font-medium">
              Request Job Referrals
            </h1>
          </div>
        </div>

        {/* <ul className="divide-y divide-gray-200 border-b border-gray-200"> */}
        <ReferralRequestForm
          email={email}
          sid={sid}
          student={studentInfo}
          setIsDanger={setIsDanger}
          setIsError={setIsError}
          setErrorMessage={setErrorMessage}
          totalOpenReferral={totalOpenReferral}
          setTotalOpenReferral={setTotalOpenReferral}
        />
        <HowItWorksJR />
        <AlertBanner
          errorMessage={errorMessage}
          isDanger={isDanger}
          setIsError={setIsError}
          isError={isError}
        />
      </div>
    </>
  );
}
