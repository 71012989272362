export default function ComapanyLogoCloud() {
  return (
    <section className="overflow-hidden bg-gray-50 py-12 md:py-20 lg:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Alumni on our platform work at the following companies!
          </h1>
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
            Alumni on our platform work at the following companies!
          </p> */}
        </div>
      </div>
      <div className="mx-auto max-w-7xl pt-8 px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/amazon.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/google.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/cisco.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/intel.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/tiktok.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/uber.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/stripe.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/meta.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/netflix.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src="https://logo.clearbit.com/microsoft.com"
            alt="FutureForte helps students with job referrals"
            width={158}
            height={48}
          />
        </div>
      </div>
    </section>
  );
}
