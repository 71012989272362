import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState, useEffect } from "react";

export default function InMakingBanner(props) {
  const [isDisplay, setIsDisplay] = useState(() => {
    const localData = localStorage.getItem("inMakingBanner");
    return localData === "false" ? false : true;
  });

  //   function handleCloseBanner()
  return isDisplay ? (
    <div className="fixed inset-x-0 bottom-0 pb-2 sm:pb-5">
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="rounded-lg bg-indigo-600 p-2 shadow-lg sm:p-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-indigo-800 p-2">
                <MegaphoneIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">
                  We are changing our name from OnlyReferrals to FutureForte
                  &#128293;
                </span>
                <span className="hidden md:inline">
                  We are changing our name from OnlyReferrals to FutureForte
                  &#128293;
                </span>
              </p>
              <a
                target="_blank"
                href="https://www.futureforte.co"
                className="font-bold text-white underline"
              >
                Our new domain <span aria-hidden="true"> &rarr;</span>
              </a>

              {/* <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">
                  Join our WhatsApp community for trending jobs. &#128293;
                </span>
                <span className="hidden md:inline">
                  Join our WhatsApp community for trending jobs. &#128293;
                </span>
              </p> */}

              {/* <a
                target="_blank"
                href="https://chat.whatsapp.com/EePx1vnurLpC0mA0xBCpZl"
                className="font-bold text-white underline"
              >
                WhatsApp Group <span aria-hidden="true"> &rarr;</span>
              </a> */}
            </div>
            {/* <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <a
                href="#"
                className="flex items-center justify-center rounded-md border border-transparent 
                bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
              >
                &#128293; Trending JOBS &#128293;
              </a>
            </div> */}
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                onClick={() => {
                  setIsDisplay(false);
                  window.localStorage.setItem("inMakingBanner", "false");
                }}
                className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
