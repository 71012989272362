import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowsRightLeftIcon,
  Bars3Icon,
  Cog6ToothIcon,
  HandRaisedIcon,
  HeartIcon,
  HomeIcon,
  InboxIcon,
  PaperAirplaneIcon,
  PencilIcon,
  PowerIcon,
  SparklesIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import StudentHomeMain from "./home/StudentHomeMain";
import StudentSettingsMain from "./settings/StudentSettingsMain";
import { useAuth } from "../../../context/cognitoUser";
import ApplyReferral from "./referral/apply/ApplyReferral";
import AXIOS_CLIENT from "../../../utils/api_client";
import OpenRequests from "./referral/requests/OpenRequests";
import StudentReferralHistory from "./referral/history/StudentReferralHistory";
import Messenger from "./messenger/Messenger";
import JobProfileForm from "./profile/JobProfileForm";
import { Auth } from "aws-amplify";
import MentorProfiles from "../../mentorship/components/MentorProfiles";
import InMaking from "../../../common/in_making/InMaking";
import ResumeReviewForm from "../../services/resumeReview/components/ResumeReviewForm";
import CoverLetterGenForm from "../../services/coverLetterGen/components/CoverLetterGenForm";
import StudentCGMain from "./career_guidance/StudentCGMain";
import StudentCGMentor from "./cg/mentors/StudentCGMentor";
import {
  CalendarDaysIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/20/solid";
import StudentCGBooking from "./cg/bookings/StudentCGBooking";
import ClassifiedUni from "../../../common/in_making/ClassifiedUni";
import StudentJRMain from "./referral/StudentJRMain";

const navigation = [
  { name: "Home", href: "home", icon: HomeIcon, current: true },
  { name: "Messages", href: "messages", icon: InboxIcon, current: false },
  { name: "Profile", href: "profile", icon: UsersIcon, current: false },
  { name: "Settings", href: "settings", icon: Cog6ToothIcon, current: false },
];

// const referrals = [
//   { name: "Apply", href: "apply", icon: PaperAirplaneIcon, current: false },
//   { name: "Requests", href: "requests", icon: HandRaisedIcon, current: false },
//   { name: "Matches", href: "matches", icon: HeartIcon, current: false },
// ];

const services = [
  {
    name: "ResumeGPT ✨",
    href: "resume-gpt",
    icon: SparklesIcon,
    current: false,
  },
  {
    name: "CoverLetterGen 🖊",
    href: "cover-letter",
    icon: PencilIcon,
    current: false,
  },
  {
    name: "Job Referrals",
    href: "job-referrals",
    icon: ArrowsRightLeftIcon,
    current: false,
  },
];

const mentorship = [
  {
    name: "Request",
    href: "mentorship",
    icon: PaperAirplaneIcon,
    current: false,
  },
  { name: "Mentors", href: "mentors", icon: UsersIcon, current: false },
  {
    name: "Bookings",
    href: "booking",
    icon: PhoneArrowUpRightIcon,
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StudentDashboardMain() {
  const {
    globalUserType,
    setGlobalUserType,
    user,
    setUser,
    userSession,
    setUserSession,
  } = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentNav, setCurrentNav] = useState("home");
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [sid, setSid] = useState();
  const [email, setEmail] = useState();
  const [studentInfo, setStudentInfo] = useState();
  const navigate = useNavigate();
  const [totalOpenReferral, setTotalOpenReferral] = useState();
  const pathname = window.location.pathname;
  const [currentWindow, setCurrentWindow] = useState("Home");

  useEffect(() => {
    if (user) {
      setSid(user.username);
      if (user.attributes.email) setEmail(user.attributes.email);
    }
  }, [user]);

  // useEffect(() => {
  //   if (userSession) {
  //     setEmail(userSession.idToken.payload.email);
  //   }
  // }, [userSession]);

  useEffect(() => {
    if (sid) {
      AXIOS_CLIENT.get(`student/user/${sid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            setStudentInfo(userInfo);

            if (userInfo.numberOfOpenRequest != null)
              setTotalOpenReferral(userInfo.numberOfOpenRequest);
          }
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage("Error! Please refresh page.");
          setIsError(true);
        });
    }
  }, [sid]);

  useEffect(() => {
    if (pathname === "/student/dashboard") navigate("/student/dashboard/home");
    const currentService = pathname.split("/").pop();
    setCurrentNav(currentService);
    switch (currentService) {
      case "home":
        setCurrentWindow("Home");
        break;

      case "messages":
        setCurrentWindow("Messages");
        break;
      case "profile":
        setCurrentWindow("Profile");
        break;
      case "settings":
        setCurrentWindow("Settings");
        break;
      case "mentors":
        setCurrentWindow("Mentors");
        break;
      case "resume-gpt":
        setCurrentWindow("ResumeGPT");
        break;
      case "cover-letter":
        setCurrentWindow("CoverLetterGen");
        break;

      case "booking":
        setCurrentWindow("Bookings");
        break;
      case "calender":
        setCurrentWindow("Calender");
        break;
      case "job-referrals":
        setCurrentWindow("Job Referrals");
        break;
      default:
        setCurrentWindow("");
    }
  }, [pathname]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component for mobile display */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <a href="/">
                        <img
                          className="h-8 w-auto sm:h-10"
                          src="/logo512.png"
                          alt="FutureForte: Democratize career guidance for college students "
                        />
                      </a>
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.href === currentNav
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.href === currentNav
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>

                        {/* Career guidance */}
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Career Guidance
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {mentorship.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.href === currentNav
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.href === currentNav
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">{item.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>

                        {/* Services */}
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Services
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {services.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.href === currentNav
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.href === currentNav
                                        ? "text-indigo-600"
                                        : "text-gray-400 group-hover:text-indigo-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">{item.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>

                        <li className="-mx-2 my-4 mt-auto">
                          <button
                            onClick={() => {
                              async function signOut() {
                                try {
                                  await Auth.signOut();
                                  setUser(null);
                                  localStorage.removeItem("userType");
                                  localStorage.removeItem("isAuth");
                                  localStorage.removeItem("accessToken");
                                  navigate("/");
                                } catch (error) {
                                  console.log("error signing out: ", error);
                                }
                              }
                              signOut();
                            }}
                            className={classNames(
                              "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                            )}
                          >
                            <PowerIcon
                              className={classNames(
                                "text-gray-400 group-hover:text-indigo-600",
                                "h-6 w-6 shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            Sign Out
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <a href="/">
                <img
                  className="h-8 w-auto sm:h-10"
                  src="/logo512.png"
                  alt="FutureForte: Democratize career guidance for college students "
                />
              </a>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.href === currentNav
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href === currentNav
                                ? "text-indigo-600"
                                : "text-gray-400 group-hover:text-indigo-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                {/* Career guidance */}
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Career Guidance
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {mentorship.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.href === currentNav
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href === currentNav
                                ? "text-indigo-600"
                                : "text-gray-400 group-hover:text-indigo-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* Services */}
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    Services
                  </div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {services.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.href === currentNav
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.href === currentNav
                                ? "text-indigo-600"
                                : "text-gray-400 group-hover:text-indigo-600",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          <span className="truncate">{item.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="-mx-2 my-4 mt-auto">
                  <button
                    onClick={() => {
                      async function signOut() {
                        try {
                          await Auth.signOut();
                          setUser(null);
                          localStorage.removeItem("userType");
                          localStorage.removeItem("isAuth");
                          localStorage.removeItem("accessToken");
                          navigate("/");
                        } catch (error) {
                          console.log("error signing out: ", error);
                        }
                      }
                      signOut();
                    }}
                    className={classNames(
                      "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                    )}
                  >
                    <PowerIcon
                      className={classNames(
                        "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    Sign Out
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* Static sidebar for mobile */}
        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            {currentWindow}
          </div>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            {/* Your content */}
            <Routes>
              <Route index element={<StudentHomeMain />} />

              <Route path="home" element={<StudentHomeMain />} />
              <Route
                path="settings"
                element={
                  <StudentSettingsMain
                    sid={sid}
                    email={email}
                    globalUserType={globalUserType}
                    setGlobalUserType={setGlobalUserType}
                    user={user}
                    setUser={setUser}
                    userSession={userSession}
                    setUserSession={setUserSession}
                  />
                }
              />

              <Route
                path="settings"
                element={
                  <StudentSettingsMain
                    sid={sid}
                    email={email}
                    globalUserType={globalUserType}
                    setGlobalUserType={setGlobalUserType}
                    user={user}
                    setUser={setUser}
                    userSession={userSession}
                    setUserSession={setUserSession}
                  />
                }
              />
              <Route
                path="profile"
                element={
                  <JobProfileForm
                    sid={sid}
                    email={email}
                    globalUserType={globalUserType}
                    setGlobalUserType={setGlobalUserType}
                    user={user}
                    setUser={setUser}
                    userSession={userSession}
                    setUserSession={setUserSession}
                  />
                }
              />
              <Route
                path="mentorship"
                element={
                  // <InMaking />
                  // <ClassifiedUni />
                  <StudentCGMain sid={sid} email={email} />
                }
              />

              <Route
                path="job-referrals"
                element={
                  <StudentJRMain
                    sid={sid}
                    email={email}
                    user={user}
                    setUser={setUser}
                    userSession={userSession}
                    setUserSession={setUserSession}
                    totalOpenReferral={totalOpenReferral}
                    setTotalOpenReferral={setTotalOpenReferral}
                    studentInfo={studentInfo}
                  />
                }
              />

              <Route
                path="mentors"
                element={
                  // <InMaking />
                  <ClassifiedUni />
                  // <StudentCGMentor />
                  // <MentorProfiles />
                }
              />

              <Route
                path="booking"
                element={
                  <ClassifiedUni />
                  // <StudentCGBooking />
                  // <MentorProfiles />
                }
              />
              <Route path="resume-gpt" element={<ResumeReviewForm />} />
              <Route path="cover-letter" element={<CoverLetterGenForm />} />
              <Route path="messages" element={<Messenger />} />
            </Routes>
          </div>
        </main>
      </div>
    </>
  );
}
