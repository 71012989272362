import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import StaticSearchBarCGExplore from "./components/StaticSearchBarCGExplore";

const people = [
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Leslie Alexander",
    email: "leslie.alexander@futu.com",
    role: "Co-Founder / CEO",
    telephone: "+1-202-555-0170",
    title: "AIC tax consultant",

    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Michael Foster",
    email: "michael.foster@futureforte.co",
    role: "Software Engineer",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
    telephone: "+1-202-555-0170",
    title: "Google",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Dries Vincent",
    email: "dries.vincent@futureforte.co",
    role: "Business Relations",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: null,
    telephone: "+1-202-555-0170",
    title: "Facebook",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Lindsay Walton",
    email: "lindsay.walton@futureforte.co",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
    telephone: "+1-202-555-0170",
    title: "Facebook",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Courtney Henry",
    email: "courtney.henry@futureforte.co",
    role: "Designer",
    imageUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
    telephone: "+1-202-555-0170",
    title: "Microsoft",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Tom Cook",
    email: "tom.cook@futureforte.co",
    role: "Director of Product",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: null,
    telephone: "+1-202-555-0170",
    title: "Microsoft",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Leslie Alexander",
    email: "leslie.alexander@futu.com",
    role: "Co-Founder / CEO",
    telephone: "+1-202-555-0170",
    title: "IBM",

    imageUrl:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Michael Foster",
    email: "michael.foster@futureforte.co",
    role: "Software Engineer",
    imageUrl:
      "https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
    telephone: "+1-202-555-0170",
    title: "IBM",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Dries Vincent",
    email: "dries.vincent@futureforte.co",
    role: "Business Relations",
    imageUrl:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: null,
    telephone: "+1-202-555-0170",
    title: "Facebook",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Lindsay Walton",
    email: "lindsay.walton@futureforte.co",
    role: "Front-end Developer",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
    telephone: "+1-202-555-0170",
    title: "Intel",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Courtney Henry",
    email: "courtney.henry@futureforte.co",
    role: "Designer",
    imageUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: "3h ago",
    lastSeenDateTime: "2023-01-23T13:23Z",
    telephone: "+1-202-555-0170",
    title: "Google",
  },
  {
    title: "Front-end Developer",
    department: "Optimization",
    hours: "10",
    name: "Tom Cook",
    email: "tom.cook@futureforte.co",
    role: "Director of Product",
    imageUrl:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    href: "#",
    lastSeen: null,
    telephone: "+1-202-555-0170",
    title: "Google",
  },

  // More people...
];

export default function StudentCGExplore() {
  return (
    <div className="p-4">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <StaticSearchBarCGExplore />
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <ul
                role="list"
                className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
              >
                {people.map((person) => (
                  <li
                    key={person.email}
                    className="col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white text-center shadow"
                  >
                    <div className="flex flex-1 flex-col p-8">
                      <img
                        className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                        src={person.imageUrl}
                        alt=""
                      />
                      <h3 className="mt-6 text-sm font-medium text-gray-900">
                        {person.name}
                      </h3>
                      <dl className="mt-1 flex flex-grow flex-col justify-between">
                        <dt className="sr-only">Title</dt>
                        <dd className="text-sm text-gray-500">
                          {person.title}
                        </dd>
                        <dt className="sr-only">Role</dt>
                        <dd className="mt-3">
                          <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {person.role}
                          </span>
                        </dd>
                      </dl>
                    </div>
                    <div>
                      <div className="-mt-px flex divide-x divide-gray-200">
                        <div className="flex w-0 flex-1">
                          <a
                            href={`mailto:${person.email}`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <EnvelopeIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            Message
                          </a>
                        </div>
                        {/* <div className="-ml-px flex w-0 flex-1">
                          <a
                            href={`tel:${person.telephone}`}
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <PhoneIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            Call
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
