import { Navigate } from "react-router-dom";
import { useAuth } from "../context/cognitoUser";

const PrivateRouteRec = ({ children }) => {
  const { loggedInStudentUser } = useAuth();

  if (!loggedInStudentUser) {
    return <Navigate to="/hire/signin" replace />;
  }

  return children;
};
export default PrivateRouteRec;
