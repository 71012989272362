import { useEffect } from "react";
import TeamContent from "./components/TeamContent";
import JoinOurTeam from "../openRoles/components/JoinOurTeam";
import MainNavbar from "../../common/components/MainNavbar";

export default function TeamMain() {
  const TITLE = "Leadership team at FutureForte.";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <>
      <MainNavbar />
      <TeamContent />
      <JoinOurTeam />
    </>
  );
}
