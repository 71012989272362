export const SUMMER_INTERNSHIP_LIST = [
  {
    company: "Goldman Sachs",
    companyDomain: "goldmansachs.com",
    link: "https://www.goldmansachs.com/careers/students/programs/americas/summer-analyst-program.html",
    location: '"Multiple, USA"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "Summer 2024 Analyst",
  },
  {
    company: "Optiver",
    companyDomain: "optiver.com",
    link: "https://optiver.com/working-at-optiver/career-opportunities/6497784002",
    location: '"Chicago, IL and Austin, TX"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Optiver",
    companyDomain: "optiver.com",
    link: "https://optiver.com/working-at-optiver/career-opportunities/6614387002",
    location: '"Chicago, IL and Austin, TX"',
    role: "Quant",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Blackrock",
    companyDomain: "blackrock.comm",
    link: "https://blackrock.tal.net/vx/lang-en-GB/mobile-0/brand-3/xf-232eb66ac89a/candidate/so/pm/1/pl/1/opp/7894-Summer-Internship-Program-Americas/en-GB",
    location: "",
    role: "",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Walmart",
    companyDomain: "walmart.com",
    link: "https://careers.walmart.com/us/jobs/WD1391200-2024-summer-intern-software-engineer-ii-bentonville-ar",
    location: '"Bentonville, AR\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "No visa sponsorship available",
  },
  {
    company: "Marotta",
    companyDomain: "marotta.com",
    link: "https://marotta.com/job-openings/?gnk=job&gni=8a7883ac879c5eca0187ef4d715d4fd8&lang=en",
    location: '"Parsippany, NJ\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "US citizenship required",
  },
  {
    company: "Lumen Technologies",
    companyDomain: "lumen.com",
    link: "https://jobs.lumen.com/global/en/job/324980/Intern-Summer-2024-Program-Submit-Interest",
    location: '"Remote, USA\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "No visa sponsorship available",
  },
  {
    company: "Palantir",
    companyDomain: "palantir.com",
    link: "https://www.palantir.com/careers/students/path//",
    location: '"New York, NY or Washington, DC\t"',
    role: "Path intern",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "For BS",
  },
  {
    company: "Mercedes-Benz Research & Development North America",
    companyDomain: "mbrdna.com",
    link: "https://jobs.lever.co/MBRDNA/59ae463c-5d10-4bb6-9dfd-4e26c7d84a69",
    location: '"Sunnyvale, CA\t"',
    role: "Data Products Intern",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Protivity",
    companyDomain: "protivity.com",
    link: "https://roberthalf.wd1.myworkdayjobs.com/en-US/ProtivitiNA/job/PHOENIX/Phoenix-Technology-Consulting-Intern---2024_JR-248209-2?Location_Country=bc33aa3152ec42d4995f4791a106ed09&Location_Region_State_Province=c7b20b0d4bc04711a00900569e9afabd",
    location: '"Phoenix, AZ\t"',
    role: "Technology Consulting",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "No visa sponsorship available",
  },
  {
    company: "Bank of America",
    companyDomain: "bankofamerica.com",
    link: "https://bankcampuscareers.tal.net/vx/lang-en-GB/mobile-0/brand-4/xf-91c0e92d74a1/candidate/so/pm/1/pl/1/opp/10165-Global-Technology-Summer-Analyst-Program-2024/en-GB",
    location: "Multiple US Locations",
    role: "Global Technology Summer Analyst",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "D.E. Shaw",
    companyDomain: "deshaw.com",
    link: "https://www.deshaw.com/careers/software-developer-intern-new-york-summer-2024-4803",
    location: '"New York, NY\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "D.E. Shaw",
    companyDomain: "deshaw.com",
    link: "https://www.deshaw.com/careers/systems-technical-program-manager-intern-new-york-summer-2024-4786",
    location: '"New York, NY\t"',
    role: "TPM",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "D.E. Shaw",
    companyDomain: "deshaw.com",
    link: "https://www.deshaw.com/careers/systems-administrator-intern-new-york-summer-2024-47822",
    location: '"New York, NY\t"',
    role: "Administator",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "D.E. Shaw",
    companyDomain: "deshaw.com",
    link: "https://www.deshaw.com/careers/systems-technologist-intern-new-york-summer-2024-4785",
    location: '"New York, NY\t"',
    role: "System technologist",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Capstone Investment",
    companyDomain: "capstoneco.com",
    link: "https://www.capstoneco.com/careers/2024-summer-internship-software-engineer-nyc/?gh_jid=6784275002",
    location: '"New York, NY\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "MosaicML",
    companyDomain: "mosaicml.com",
    link: "https://boards.greenhouse.io/mosaicml/jobs/4133756004",
    location: '"SF and San Diego, CA, and NYC, NY"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "US citizenship required",
  },
  {
    company: "MosaicML",
    companyDomain: "mosaicml.com",
    link: "https://boards.greenhouse.io/mosaicml/jobs/41704540044",
    location: '"SF and San Diego, CA, and NYC, NY"',
    role: "Research Scientist",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "US citizenship required",
  },
  {
    company: "Certik",
    companyDomain: "certik.com",
    link: "https://jobs.lever.co/certik/2e33570a-f495-44ef-9d7d-a0c5a7fd8190",
    location: "Multiple US Locations",
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Certik",
    companyDomain: "mosaicml.com",
    link: "https://jobs.lever.co/certik/095fdcff-99e8-408d-bb8a-e638e44d0b400",
    location: "Multiple US Locations",
    role: "Platform Engineering",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Certik",
    companyDomain: "certik.com",
    link: "https://jobs.lever.co/certik/ca67aab6-9b8b-4c2f-ad80-ff5855292f488",
    location: "Multiple US Locations",
    role: "Full Stack",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Neuralink",
    companyDomain: "neuralink.com",
    link: "https://boards.greenhouse.io/neuralink/jobs/5285389003",
    location: '"Fremont, CA"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Neuralink",
    companyDomain: "neuralink.com",
    link: "https://boards.greenhouse.io/neuralink/jobs/5552197003",
    location: '"Austin, TX"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Apple",
    companyDomain: "apple.com",
    link: "https://jobs.apple.com/en-us/details/200480063/software-engineering-internshipss",
    location: "Multiple US Locations",
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Apple",
    companyDomain: "apple.com",
    link: "https://jobs.apple.com/en-us/details/200480066/machine-learning-ai-internshipss",
    location: "Multiple US Locations",
    role: "AI/ML",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Apple",
    companyDomain: "apple.com",
    link: "https://jobs.apple.com/en-us/details/200480064/engineering-program-management-internships",
    location: "Multiple US Locations",
    role: "TPM",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
  {
    company: "Epic",
    companyDomain: "epic.com",
    link: "https://epic.avature.net/Careers/FolderDetail/Software-Developer-Intern---Summer-2024/23429",
    location: '"Madison, WI\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "No visa sponsorship available",
  },
  {
    company: "AQR Capital Management",
    companyDomain: "aqr.com",
    link: "https://careers.aqr.com/jobs/university-open-positions/greenwich-ct/2024-summer-internship-express-interest/4478927",
    location: '"Greenwich, CT\t"',
    role: "SWE",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    Desc: "",
  },
];

export const FALL_INTERNSHIP_LIST = [
  {
    company: "Google",
    companyDomain: "google.com",
    link: "https://careers.google.com/jobs/results/133257255987356358-research-intern-phd-fall-2023/",
    role: "Research Intern, PhD",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },

  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/vehicle-firmware-embedded-systems-engineering-internship-fall-2023-168106?",
    role: "SWE",
    location: "In-person",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/full-stack-engineering-internship-vehicle-software-fall-2023-173443?source=LinkedIn",
    role: "Full-stack Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    comapnyDomain: "spacex.com",
    link: "https://boards.greenhouse.io/spacex/jobs/6675035002?gh_jid=6675035002",
    role: "SWE",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Amazon Robotics",
    companyDomain: "amazon.com",
    link: "https://www.amazon.jobs/en/jobs/2345448/amazon-robotics-front-end-engineer-fee-co-op-fall-2023",
    role: "Front-end engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Microsoft",
    companyDomain: "microsoft.com",
    link: "https://jobs.careers.microsoft.com/global/en/job/1492332/Research-Intern-Security-and-Cryptography",
    role: "Research Intern - Security and Cryptography",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Microsoft",
    companyDomain: "microsoft.com",
    link: "https://jobs.careers.microsoft.com/global/en/job/1510058/Research-Intern-Microsoft-Audience-Network",
    role: "Research Intern - Microsoft Audience Network",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "TikTok",
    companyDomain: "tiktok.com",
    link: "https://jobs.bytedance.com/en/position/7213104205459376444/detail?spread=BSPP2KS",
    role: "Research Intern (Multimedia Streaming) 2023 Summer/Fall (PHD)",
    location:
      "https://jobs.bytedance.com/en/position/7213104205459376444/detail?spread=BSPP2KS",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5606587003&",
    role: "Applied Aerodynamics",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    link: "https://boards.greenhouse.io/spacex/jobs/6675011002?gh_jid=6675011002&",
    role: "Business Operations",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    link: "https://jobs.jobvite.com/splunk-careers/job/oGmgnfwp?",
    role: "Software Engineer - Backend/Fullstack",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/video-games-internship-vehicle-software-fall-2023--173695?source=LinkedIn",
    role: "Video Games Internship, Vehicle Software (Fall 2023)",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/backend-engineering-internship-vehicle-software-fall-2023-173434",
    role: "Backend Engineering Internship",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/video-games-internship-vehicle-software-fall-2023--173695",
    role: "Video Games Internship",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/audio-systems-internship-vehicle-software-fall-2023-175663",
    role: "Audio Systems Internship",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/software-data-platforms-engineering-internship-fall-2023-173686",
    role: "Software Data Platforms",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/vehicle-software-algorithm-engineering-internship-fall-2023-173110",
    role: "Vehicle Software Algorithm",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/job/software-qa-test-engineering-internship-applications-engineering-fall-2023-177682",
    role: "Software QA & Test Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Amazon",
    companyDomain: "amazon.com",
    link: "https://www.amazon.jobs/en/search?base_query=Fall+2023&loc_query=&latitude=&longitude=&loc_group_id=&invalid_location=false&country=&city=&region=&county=",
    role: "Multiple",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Microsoft",
    companyDomain: "microsoft.com",
    link: "https://jobs.careers.microsoft.com/global/en/search?q=fall%20internship&lc=United%20States&p=Research%2C%20Applied%2C%20%26%20Data%20Sciences&exp=Students%20and%20graduates&et=Internship&l=en_us&pg=1&pgSz=20&o=Relevance&flt=true",
    role: "Multiple",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    link: "https://www.tesla.com/careers/search/?query=Fall%20internship&site=US",
    role: "Multiple",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Northwestern Mutual",
    companyDomain: "northwesternmutual.com",
    link: "https://northwesternmutual.wd5.myworkdayjobs.com/corporate-careers/job/Milwaukee-WI-Corporate/Enterprise-Compliance-Internship_JR-38310?",
    role: "Enterprise Compliance",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Akuna Capital",
    companyDomain: "akunacapital.com",
    link: "https://akunacapital.com/job-details?gh_jid=5030489&",
    role: "Compliance",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Intel",
    companyDomain: "intel.com",
    link: "https://intel.wd1.myworkdayjobs.com/en-us/external/job/Canada-Toronto/Software-IP-Engineer---PEY-Intern_JR0243648?",
    role: "Software IP Engineer \u2013 PEY",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    link: "https://jobs.jobvite.com/splunk-careers/job/o3munfw0?nl=1&nl=1&fr=false&",
    role: "Performance Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    link: "https://jobs.jobvite.com/splunk-careers/job/oYmgnfwH?nl=1&nl=1&fr=false&",
    role: "Software Engineer - Frontend",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    link: "https://boards.greenhouse.io/spacex/jobs/6675035002?gh_jid=6675035002&",
    role: "Software Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    link: "https://boards.greenhouse.io/spacex/jobs/6675029002?gh_jid=6675029002&",
    role: "Engineering ",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Johnson & Johnson",
    comapnyDomain: "jnj.com",
    link: "https://jnjc.taleo.net/careersection/4/jobdetail.ftl?job=2206082625W&lang=en",
    role: "System Engineering Intern",
    location: "Multiple",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5606501003&",
    role: "Systems Modeling",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Danbury Mission Technologies",
    companyDomain: "dmtllc.org",
    link: "https://recruiting.ultipro.com/DAN1006DMT/JobBoard/0a1c026e-04e8-4606-8220-15f06f331421/OpportunityDetail?opportunityId=da3c15f6-13e5-40e2-9dfa-728fe842c775",
    role: "Embedded Software Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Midmark",
    companyDomain: "midmark.com",
    link: "https://hcor.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/job/2154?utm_medium=jobshare",
    role: "IT co-op",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Certik",
    companyDomain: "certik.com",
    link: "https://jobs.lever.co/certik/cc4bc2b7-ee87-43be-81c9-09c8b0411a7e",
    role: "SWE",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "UKG",
    companyDomain: "ukg.com",
    link: "https://careers.ukg.com/careers/ApplicationMethods?jobId=44121",
    role: "SWE",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Jane Street",
    companyDomain: "janestreet.com",
    link: "https://www.janestreet.com/join-jane-street/position/6483148002/",
    role: "SWE",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    link: "https://jobs.cisco.com/jobs/SearchJobs/fall?listFilterMode=1",
    role: "SWE",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Lockheed Martin",
    companyDomain: "lockheedmartin.com",
    link: "https://www.lockheedmartinjobs.com/job/-/-/694/45728491488",
    role: "Firmware Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Snowflake",
    companyDomain: "snowflake.com",
    link: "https://careers.snowflake.com/us/en/job/SNCOUS6699893002EXTERNALENUS/Software-Engineer-Intern-Infrastructure-Automation-Fall-2023?utm_source=linkedin&utm_medium=phenom-feeds&gh_src=ed5543a62",
    role: "SWE",
    location: "Multiple",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "WealthSimple",
    companyDomain: "wealthsimple.com",
    link: "https://jobs.lever.co/wealthsimple/daacc715-972c-46ca-b489-31c2bb528192/apply",
    role: "SWE",
    location: "Toronto",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Autodesk",
    comapnyDomain: "autodesk.com",
    link: "https://autodesk.wd1.myworkdayjobs.com/en-US/Ext/job/Intern--Software-Engineer--Fall-2023-_23WD67856-1",
    role: "SWE",
    location: "Toronto",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "TD",
    comapnyDomain: "td.com",
    link: "https://sjobs.brassring.com/TGnewUI/Search/home/HomeWithPreLoad?PageType=JobDetails&partnerid=25404&siteid=5813&jobid=3111581&codes=UE4#jobDetails=3111581_5813",
    role: null,
    location: "Toronto",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "D2L",
    companyDomain: "d2l.com",
    link: "https://www.d2l.com/careers/jobs/product-support-analyst-fall-2023-co-op/5077953/",
    role: "Product Support Analyst",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Western Digital",
    companyDomain: "westerndigital.com",
    link: "https://jobs.smartrecruiters.com/WesternDigital/743999910364988?",
    role: "Software Engineering (Firmware) Intern",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "D2L",
    companyDomain: "d2l.com",
    link: "https://www.d2l.com/careers/jobs/software-developer-fall-2023-co-op/5033625/",
    role: "Software Developer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Kodiak Robotics",
    companyDomain: "kodiak.ai",
    link: "https://jobs.lever.co/kodiak/34ed52b0-6cf5-4cd6-aef6-af42ff89f261/apply?",
    role: "Intern, Perception",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Sezzle",
    companyDomain: "sezzle.com",
    link: "https://boards.greenhouse.io/sezzle/jobs/5307670003?",
    role: "Sales Development",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5606483003&",
    role: "Mechanical Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Anyscale",
    companyDomain: "anyscale.com",
    link: "https://jobs.lever.co/anyscale/78a003a6-221a-4414-bf95-7c734cbfc4d9/apply?",
    role: "Software Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Tenstorrent",
    companyDomain: "tenstorrent.com",
    link: "https://jobs.lever.co/tenstorrent/b78cec91-2595-444f-b268-79fbdb5c8414/apply?",
    role: "CPU RTL",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Brand Knew",
    companyDomain: "brand-knew.com",
    link: "https://jobs.lever.co/brand-knew/eb2ef433-779e-4180-a158-b3faf8ba4fd2/apply?",
    role: "Growth Marketing",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5615035003&",
    role: "Reliability Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    link: "https://jobs.lever.co/verkada/ca071837-f877-4211-9903-0a4ef5ed4cc5/apply?",
    role: "Hardware Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5083847/apply/?gh_jid=5083847&",
    role: "Optical Engineering ",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5080213/apply/?gh_jid=5080213&",
    role: "Settings Optimization Development",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Motional",
    link: "https://motional.com/open-positions?gh_jid=5539878003#/5539878003&",
    role: "Quantitative Human Computer Interaction Research",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5617157003&",
    role: "Electrical Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5071433/apply/?gh_jid=5071433&",
    role: "Supply Chain Planning",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Kodiak Robotics",
    link: "https://jobs.lever.co/kodiak/94924685-a697-4873-87d8-c3976a14ec05/apply?",
    role: "Motion Planning and Decision Making",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Form Energy",
    link: "https://jobs.lever.co/formenergy/cacc19b6-d66d-449b-a545-4902413ff148/apply?",
    role: "Mechanical Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5077094/apply/?gh_jid=5077094&",
    role: "Desktop Software",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Form Energy",
    link: "https://jobs.lever.co/formenergy/1a2d0f11-4fec-48e4-bfcf-71cca880ec3c/apply?",
    role: "Materials Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Fanatics",
    link: "https://jobs.lever.co/fanatics/ec107c9d-c80a-40d6-b5e2-255bf4f0994f/apply?",
    role: "Customer Relationship Marketing \u2013 Strategy",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Brand Knew",
    companyDomain: "brand-knew.com",
    link: "https://jobs.lever.co/brand-knew/8a462b4e-f088-4740-8bbc-cfbeb8193a46/apply?",
    role: "Graphic Design",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "D2L",
    companyDomain: "d2l.com",
    link: "https://www.d2l.com/careers/jobs/test-developer-fall-2023-co-op/5045836/",
    role: "Test Developer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Wayfair",
    link: "https://www.wayfair.com/careers/job/interior-design-intern-summer-fall-2023/6716244002/apply?",
    role: "Interior Design",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Fanatics",
    link: "https://jobs.lever.co/fanatics/214a9bb5-6cc4-4730-9343-8d0731081e93/apply?",
    role: "Customer Relationship Marketing \u2013 Operations",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5606625003&",
    role: "Talent & Recruiting",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5058929/apply/?",
    role: "Hardware Test Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Jamf",
    link: "https://www.jamf.com/about/careers/jobs/?gh_jid=4896942004&gh_jid=4896942004",
    role: "Enablement",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "The Allen Institute for AI",
    link: "https://boards.greenhouse.io/thealleninstitute/jobs/2171324?",
    role: "Research",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    link: "https://jobs.jobvite.com/splunk-careers/job/oFiqnfwu?nl=1&nl=1&fr=false",
    role: "Software Engineer - Frontend",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5003410/apply/?gh_jid=5003410&",
    role: "Global Sourcing",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Kodiak Robotics",
    link: "https://jobs.lever.co/kodiak/06184a72-742e-49c8-a3db-a450974fce55/apply?",
    role: "Systems Engineer - Verification & Validation",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    link: "https://jobs.lever.co/verkada/ea7cdef8-457e-4da0-a65a-d7d8aefa3e5e/apply?",
    role: "Product Design Mechanical Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Domeyard",
    link: "https://boards.greenhouse.io/domeyard/jobs/1079185?",
    role: "Software Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5076982/apply/?gh_jid=5076982&",
    role: "Settings Optimization Validation",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Brand Knew",
    companyDomain: "brand-knew.com",
    link: "https://jobs.lever.co/brand-knew/bae9a1ec-10a4-48fc-94a5-1b45d964d8f4/apply?",
    role: "Marketing",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5089201/apply/?gh_jid=5089201&",
    role: "R&D Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Applied Materials",
    link: "https://amat.wd1.myworkdayjobs.com/External/job/Santa-ClaraCA/Material-Handler-Intern--Summer-2023-_R2313652-1?",
    role: "Material Handler",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Locus Robotics",
    link: "https://jobs.smartrecruiters.com/LocusRobotics/743999907705317?",
    role: "Accounting",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5609477003&",
    role: "Quality/Manufacturing Operations Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Astra",
    link: "https://jobs.lever.co/astra/50d3a0df-2210-46e7-8f8f-d5241b220ecb/apply?",
    role: "Mechanical Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    link: "https://jobs.lever.co/verkada/1f7eac1a-8f38-4b2e-a63e-d48afd5e13b1/apply?",
    role: "Supply Chain",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/4970267/apply/?gh_jid=4970267&",
    role: "Legal",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Minted",
    link: "https://jobs.lever.co/minted/18b566ba-2d80-412f-ab70-1c7ec3275e66/apply?",
    role: "Merchandising Assistant",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5610967003&",
    role: "Systems Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Squarepoint Capital",
    link: "https://boards.greenhouse.io/embed/job_app?token=4781381&",
    role: "Software Developer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5606609003&",
    role: "Embedded Systems Software",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Kodiak Robotics",
    link: "https://jobs.lever.co/kodiak/fdb2f7e4-e9b0-4d8a-8c6c-23f91399e2b2/apply?",
    role: "Product Management",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Samsara",
    link: "https://boards.greenhouse.io/samsara/jobs/4522239?gh_jid=4522239&",
    role: "Software Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/4994766/apply/?gh_jid=4994766&",
    role: "Systems Integration",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Bank of Montreal (BMO)",
    link: "https://bmo.wd3.myworkdayjobs.com/External/job/Calgary-AB-CAN/BMO-Capital-Markets-Fall-2023-Investment-Banking-Analyst-Co-op--Energy-Transition-Group_R230009016?",
    role: "Investment Banking Analyst",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Kodiak Robotics",
    link: "https://jobs.lever.co/kodiak/3e67f1e9-242a-4af0-8b2a-4bb05c5de92c/apply?",
    role: "Video Visualization Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    link: "https://jobs.lever.co/verkada/9e807c51-4768-4c03-98c6-528bbc84367d/apply?",
    role: "Backend Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Hudl",
    link: "https://boards.greenhouse.io/hudl/jobs/5083873?",
    role: "Corporate Communications",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    link: "https://jobs.jobvite.com/splunk-careers/job/orRnnfwM?nl=1&nl=1&fr=false&",
    role: "Customer Reliability Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Windfall",
    link: "https://jobs.lever.co/windfalldata/35c7ffa8-5162-4c37-ae45-43bc8a795b32/apply?",
    role: "Product Operations Analyst",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Square",
    link: "https://jobs.smartrecruiters.com/Square/743999910394303?",
    role: "Phd Machine Learning Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Skydio",
    link: "https://boards.greenhouse.io/skydio/jobs/5639641003?",
    role: "Embedded Software Engineer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Waabi",
    link: "https://jobs.lever.co/waabi/ad3c77da-5607-4c4f-8784-8407478cbace/apply?",
    role: "Research",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Enveda Biosciences",
    link: "https://jobs.lever.co/envedabio/6619fb8e-9f09-4b70-8b19-3e5d9313a7bc/apply?",
    role: "Social Media",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    link: "https://legacy.flyzipline.com/job?gh_jid=5606583003&",
    role: "Guidance \u2013 Navigation & Controls",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "The Allen Institute for AI",
    link: "https://boards.greenhouse.io/thealleninstitute/jobs/2172007?",
    role: "Research",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Astra",
    link: "https://jobs.lever.co/astra/c227b197-92cf-4b11-bd0f-bf71f8b991c9/apply?",
    role: "Electrical Engineering ",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Major League Baseball",
    link: "https://www.mlb.com/careers/opportunities?gh_jid=4988065&",
    role: "Arizona Fall League",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Avala AI",
    link: "https://jobs.ashbyhq.com/avala/047c26ac-bdb0-429b-b7d4-92ea7ed92600/application?",
    role: "Product Designer",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Inversion Space",
    link: "https://boards.greenhouse.io/inversionspace/jobs/4095458005?",
    role: "Engineering",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Kodiak Robotics",
    link: "https://jobs.lever.co/kodiak/379c54fd-8369-4a1d-970a-bb4b323fa7f3/apply?",
    role: "Simulation",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Formlabs",
    companyDomain: "formlabs.com",
    link: "https://careers.formlabs.com/job/5070317/apply/?gh_jid=5070317&",
    role: "Algorithms Software",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Honeywell",
    companyDomain: "honeywell.com",
    link: "https://careers.honeywell.com/us/en/job/HONEUSREQ369247EXTERNALENUS/Future-IT-Leaders-Summer-2023-Internship?utm_source=linkedin&utm_medium=phenom-feeds",
    role: "IT",
    location: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },

  {
    company: "Garmin",
    companyDomain: "garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/10469?lang=en-us",
    location: "Chandler, AZ",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Garmin",
    companyDomain: "garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/10499?lang=en-us",
    location: "Olathe, Kansas",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "State Farm",
    companyDomain: "statefarm.com/",
    link: "https://jobs.statefarm.com/main/jobs/30702?lang=en-us",
    location: "Phoenix, AZ",
    role: "MAGNet",
    Desc: "Online MS Analytics",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "State Farm",
    companyDomain: "statefarm.com/",
    link: "https://jobs.statefarm.com/main/jobs/32594?lang=en-us",
    location: "Athens, Georgia",
    role: "MAGNet",
    Desc: "Online MS Analytics",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "State Farm",
    companyDomain: "statefarm.com/",
    link: "https://jobs.statefarm.com/main/jobs/32593?lang=en-us",
    location: "Athens, Georgia",
    role: "MAGNet",
    Desc: "Online MS Analytics",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "National Security Agency",
    companyDomain: "geebo.com/",
    link: "https://peoria-az.geebo.com/jobs-online/view/id/858123591-software-engineer-student-program-/",
    location: "Peoria, AZ",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Intel",
    companyDomain: "intel.com/",
    link: "https://jobs.intel.com/en/job/san-jose/engineer-intern/41147/49704030064",
    location: "San Jose, California",
    role: "Engineer Intern",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Intel",
    companyDomain: "intel.com/",
    link: "https://jobs.intel.com/en/job/santa-clara/software-engineer-graduate-intern/41147/50668259456",
    location: "Santa Clara, California",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com/",
    link: "https://www.splunk.com/en_us/careers/jobs/machine-learning-engineer-intern-27754.html",
    location: "NC, United States",
    role: "ML intern",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com/",
    link: "https://www.splunk.com/en_us/careers/jobs/software-engineer-intern-backendfullstack-27691.html",
    location: "United States",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Dana International",
    companyDomain: "dana.com/",
    link: "https://jobs.dana.com/job/2023-Fall-Software-Engineering-Intern-Novi%2C-MI/1010332400/",
    location: "MI, US",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Dana International",
    companyDomain: "dana.com/",
    link: "https://jobs.dana.com/job/ENG_Co-OpInternTrainee/994457200/",
    location: "IN, US",
    role: "ENG",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Dana International",
    companyDomain: "dana.com/",
    link: "https://jobs.dana.com/job/Test-Lab-Intern/1033424800/",
    location: "IN, US",
    role: "Test Lab",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "O'Reilly Auto Parts",
    companyDomain: "oreillyauto.com",
    link: "https://corporate.oreillyauto.com/onlineapplication/careerpage/jobDetails/540107",
    location: "Springfield, MO",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Huntington Ingalls Industries",
    companyDomain: "huntingtoningalls.dejobs.org/",
    link: "https://huntingtoningalls.dejobs.org/augusta-ga/software-engineer-skillbridge-intern-15187/09C7DE09CB5B4849AF1326AFFFD5C235/job/",
    location: "Augusta, Georgia",
    role: "SWE",
    Desc: "Skillbridge Intern",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Huntington Ingalls Industries",
    companyDomain: "huntingtoningalls.dejobs.org/",
    link: "https://huntingtoningalls.dejobs.org/fairfax-va/it-infrastructure-and-operations-intern-fairfax-13701/606680846BD74DA3A65671D2756BD40B/job/",
    location: "Fairfax, Virginia",
    role: "IT",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Huntington Ingalls Industries",
    companyDomain: "huntingtoningalls.dejobs.org/",
    link: "https://huntingtoningalls.dejobs.org/syracuse-ny/fall-2023-engineering-co-opintern/B557DD99ABB34AC2B71C0C94979C9F76/job/",
    location: "Syracuse, New York",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Huntington Ingalls Industries",
    companyDomain: "huntingtoningalls.dejobs.org",
    link: "https://huntingtoningalls.dejobs.org/san-diego-ca/systems-engineer-1-3-skillbridge-intern-multiple-locations-12505/A1BA28DC638944FE850F18AECBD740DD/job/",
    location: "San Diego, California",
    role: "Systems",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Huntington Ingalls Industries",
    companyDomain: "huntingtoningalls.dejobs.org",
    link: "https://huntingtoningalls.dejobs.org/norfolk-va/network-engineer-1-3-skillbridge-intern-8372/F0FBCD2A49EA4FC6870102049F9B58C9/job/",
    location: "Norfolk, Virginia",
    role: "Network",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/bristol/software-engineer-intern-d-clifton-nj/4832/50294781728",
    location: "BRISTOL, PENNSYLVANIA",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/united-states/software-engineer-new-grad-remote/4832/50345341872",
    location: "Remote",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/united-states/viasat-software-engineer-new-grad-carlsbad-ca/4832/49881146240",
    location: "Carlsbad, CA",
    role: "SWE",
    Desc: "ViaSat",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/melbourne/software-engineer-intern-d-senior-melbourne-fl/4832/50294807136",
    location: "Melbourne, FL",
    role: "SWE D",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/fort-wayne/software-engineer-intern-d-ft-wayne-in/4832/50294789904",
    location: "Ft Wayne, IN",
    role: "SWE D",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com/",
    link: "https://careers.l3harris.com/job/rochester/software-engineer-intern-d-rochester-ny/4832/50055443376",
    location: "Rochester, NY",
    role: "SWE D",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/clifton/software-engineering-intern-d-clifton-nj/4832/50294823744",
    location: "Clifton, NJ",
    role: "SWE D",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/palm-bay/software-engineer-intern-c-palm-bay-fl/4832/50254687936",
    location: "Palm Bay, FL",
    role: "SWE C",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/chantilly/software-engineering-intern-d-chantilly-va/4832/50842399040",
    location: "Chantilly,VA",
    role: "SWE D",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "L3HARRIS",
    companyDomain: "l3harris.com",
    link: "https://careers.l3harris.com/job/lynchburg/software-engineer-intern-d-lynchburg-va/4832/49699195248",
    location: "Lynchburg, VA",
    role: "SWE D",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Parker",
    companyDomain: "parker.com",
    link: "https://parkercareers.ttcportals.com/jobs/12182356-embedded-software-engineer-intern",
    location: "ELK GROVE VILLAGE, IL",
    role: "SWE",
    Desc: "Embedded",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Parker",
    companyDomain: "parker.com",
    link: "https://parkercareers.ttcportals.com/jobs/11044159-iot-engineering-intern-fall-2023",
    location: "WICKLIFFE, OH",
    role: "IOT",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Chevron",
    companyDomain: "chevron.com",
    link: "https://careers.chevron.com/job/houston/2023-2024-information-technology-software-engineering-intern/38138/50613174464",
    location: "Houston, Texas",
    role: "IT",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Oracle",
    companyDomain: "oracle.com",
    link: "https://careers.oracle.com/jobs/#en/sites/jobsearch/job/203718",
    location: "Santa Clara, CA",
    role: "SWE",
    Desc: "OVIP",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Booz Allen Hamilton",
    companyDomain: "boozallen.com",
    link: "https://careers.boozallen.com/jobs/JobDetail/McLean-University-Software-Engineer-Intern-R0173736/80842",
    location: "McLean, Virginia",
    role: "SWE",
    Desc: "University",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "Booz Allen Hamilton",
    companyDomain: "boozallen.com",
    link: "https://careers.boozallen.com/talent/JobDetail/Salt-Lake-City-Software-Engineer-Intern-R0174119/81219",
    location: "Salt Lake City , Utah",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "HII's Mission Technologies division",
    companyDomain: "hii-tsd.com",
    link: "https://jobs.hii-tsd.com/job/Software-Engineer-1-4-%28SkillBridge-Intern%29-8380/929322400/",
    location: "Hanover, MD",
    role: "SWE",
    Desc: "SkillBridge",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
  {
    company: "CSC Corptax",
    companyDomain: "builtinchicago.org",
    link: "https://www.builtinchicago.org/job/internship/part-time-fall-software-engineer-intern/218720",
    location: "Chicago, IL",
    role: "SWE",
    Desc: null,
    type: "internship",
    tags: ["Software Engineer", "Internship"],
  },
];

export const ALL_OPP = [
  {
    company: "Akuna Capital",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/akunacapital.com",
    link: "https://akunacapital.com/careers?experience=junior&department=development#careers",
    location: "Chicago",
    refine_job_desc: " \tVarious Junior Developer Positions ",
  },
  {
    company: "Palantir",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/palantir/c34b424e-caf2-455a-b104-ae1096ccca29",
    location: "Denver, CO",
    refine_job_desc: " Software Engineer, New Grad ",
  },
  {
    company: "Sofi",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.sofi.com",
    link: "https://www.sofi.com/careers/",
    location: "NYC; Seattle; SF; Murrah, UT; Remote;...",
    refine_job_desc:
      ' Several "New Grad, Software Engineer" roles in "Engineering". Some of them may need to start in Jan 23 / ASAP ',
  },
  {
    company: "Egen Solutions",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/egensolutions/b8241d47-ab6c-456c-823d-1a6bd59e402f",
    location: "Naperville, IL",
    refine_job_desc: " Software Engineer, University Graduates, 2023 ",
  },
  {
    company: "Jane Street",
    companyDomain: "janestreet.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.janestreet.com",
    link: "https://www.janestreet.com/join-jane-street/position/6214578002/",
    location: "NYC",
    refine_job_desc: " Software Engineer, New Grad ",
  },
  {
    company: "Goldman Sachs",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.goldmansachs.com",
    link: "https://www.goldmansachs.com/careers/students/programs/americas/new-analyst-program.html",
    location: "US",
    refine_job_desc: " 2023 New Analyst Program ",
  },
  {
    company: "D. E. Shaw",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.deshaw.com",
    link: "https://www.deshaw.com/careers/choose-your-path",
    location: "NYC, London",
    refine_job_desc: " Various tech roles ",
  },
  {
    company: "Oracle",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/eeho.fa.us2.oraclecloud.com",
    link: "https://eeho.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions?location=United%20States&locationId=300000000149325&selectedFlexFieldsFacets=%22AttributeChar16%7CCampusSoftware%22",
    location: "US",
    refine_job_desc: " Software Engineer 2023 Grads ",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Software-Engineer-I-Full-Time-United-States/1380884?source=LinkedIn",
    location: "US multiple",
    refine_job_desc:
      " [**No Visa Sponsorship**](https://jobs.cisco.com/jobs/ProjectDetail/Software-Engineer-I-Full-Time-United-States/1380884?source=LinkedIn#:~:text=Able%20to%20legally%20live%20and%20work%20in%20the%20country%20for%20which%20you%E2%80%99re%20applying%2C%20without%20visa%20support%20or%20sponsorship%C2%A0); Software Engineer I (Full Time) United States ",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Software-Engineer-I-Full-Time-United-States/1380884?source=LinkedIn#:~:text=Able%20to%20legally%20live%20and%20work%20in%20the%20country%20for%20which%20you%E2%80%99re%20applying%2C%20without%20visa%20support%20or%20sponsorship%C2%A0",
    location: "US multiple",
    refine_job_desc:
      " [**No Visa Sponsorship**](https://jobs.cisco.com/jobs/ProjectDetail/Software-Engineer-I-Full-Time-United-States/1380884?source=LinkedIn#:~:text=Able%20to%20legally%20live%20and%20work%20in%20the%20country%20for%20which%20you%E2%80%99re%20applying%2C%20without%20visa%20support%20or%20sponsorship%C2%A0); Software Engineer I (Full Time) United States ",
  },
  {
    company: "SIG",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.sig.com",
    link: "https://careers.sig.com/job/SUSQA004Y6229",
    location: "Bala Cynwyd, PA",
    refine_job_desc: " Software Developer--Campus 2023 Start ",
  },
  {
    company: "Qualcomm",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/qualcomm.wd5.myworkdayjobs.com",
    link: "https://qualcomm.wd5.myworkdayjobs.com/External/job/San-Diego-USA/Software-Engineer--University-Grad-_3043053?source=APPLICANT_SOURCE-6-2",
    location: "San Diego, CA",
    refine_job_desc: " Software Engineer - New Grad",
  },
  {
    company: "Five Rings",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/fiverings.avature.net",
    link: "https://fiverings.avature.net/careers",
    location: "New York, NY",
    refine_job_desc: " Software Developer--Campus 2023 Start ",
  },
  {
    company: "WeRide.ai",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/weride/97063737-808b-4078-b1d8-8ffcef30635a",
    location: "San Jose, CA",
    refine_job_desc: " Software Engineer (New Grad) ",
  },
  {
    company: "MathWorks",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.mathworks.com",
    link: "https://www.mathworks.com/company/jobs/opportunities/16217-multiple-openings-engineering-development-group-u-s",
    location: "Natick, MA",
    refine_job_desc: " Multiple Openings - Engineering Development Group ",
  },
  {
    company: "Vanta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/vanta/jobs/5745472002",
    location: "New York, San Francisco",
    refine_job_desc: " Software Engineer, New Grad ",
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/verkada/b0946e83-4174-4ecb-9873-6d0030ca1023",
    location: "San Mateo, CA",
    refine_job_desc: "Backend",
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/verkada/b0946e83-4174-4ecb-9873-6d0030ca1023",
    location: "San Mateo, CA",
    refine_job_desc: "Backend",
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/verkada/dfcab784-b82e-4bcc-9949-3cff41a5084e",
    location: "San Mateo, CA",
    refine_job_desc: "Backend",
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/verkada/47c2d4d8-ee90-4809-8084-dabc2e93b321?lever-source=LinkedIn",
    location: "San Mateo, CA",
    refine_job_desc: "Backend",
  },
  {
    company: "Old Mission",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.oldmissioncapital.com",
    link: "https://www.oldmissioncapital.com/careers/?gh_jid=5193176003",
    location: "Multiple locations",
    refine_job_desc:
      " College Graduates - Full-Time - Junior Software Engineer ",
  },
  {
    company: "Aquatic",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/aquaticcapitalmanagement",
    location: "Chicago, IL or Remote",
    refine_job_desc: " Junior Developer and Quant positions\n",
  },
  {
    company: "Forward",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/goforward/073d1961-b3fd-4515-a7a4-a34a2eedb74a",
    location: "San Francisco, CA",
    refine_job_desc: " Software Engineer - New Grad Full Stack)",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Software-Engineer-I-Full-Time-United-States/1380884?source=coderQuad",
    location: "San Jose, CA",
    refine_job_desc: " Software Engineer I (Full Time) \n",
  },
  {
    company: "Capstone Investment Advisors",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.capstoneco.com",
    link: "https://www.capstoneco.com/careers/2023-technology-graduate-rotation-program-nyc/?gh_jid=6223901002",
    location: "NYC, London",
    refine_job_desc:
      " 2023 Technology Graduate Rotation Program. For the role in London, look up the job title on their website. ",
  },
  {
    company: "PEAK6",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.peak6.com",
    link: "https://careers.peak6.com/jobs/technology-management/chicago-il-new-york-ny-austin-tx/software-engineer-campus-entry-level/4449643?gh_jid=4449643#/",
    location: "NYC & more",
    refine_job_desc:
      " Software Engineer - Campus Entry-Level. The same application is used for all locations. ",
  },
  {
    company: "Bloomberg",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.bloomberg.com",
    link: "https://careers.bloomberg.com/job/detail/105010",
    location: "New York, NY",
    refine_job_desc: " 2023 Software Engineer\n",
  },
  {
    company: "Two Sigma",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.twosigma.com",
    link: "https://careers.twosigma.com/careers/SearchJobs/campus%20hire",
    location: "NYC, Houston(TX), and London",
    refine_job_desc: " Software Engineer, Quantitative Researcher ",
  },
  {
    company: "Pure Storage",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/purestorage/jobs/4462848",
    location: "Mountain View, CA",
    refine_job_desc: " Software Engineer Grad (for Spring/Summer 2023) ",
  },
  {
    company: "Jump Trading",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.jumptrading.com",
    link: "https://www.jumptrading.com/careers/4457617/?gh_jid=4457617",
    location: "Chicago",
    refine_job_desc: " Campus \\",
  },
  {
    company: "EAB",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/recruit.hirebridge.com",
    link: "https://recruit.hirebridge.com/v3/Jobs/JobDetails.aspx?cid=7856&jid=581704&locvalue=1036",
    location: "Remote & more",
    refine_job_desc:
      " Associate Software Engineer (Full Time Starting Summer 2023) ",
  },
  {
    company: "Quantcast",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/quantcast/052ae051-4aad-4c51-af01-8f74c1756c57",
    location: "San Francisco",
    refine_job_desc: " Software Engineer - New College Graduate ",
  },
  {
    company: "Flatiron Health",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/flatiron.com",
    link: "https://flatiron.com/careers/open-positions/4454680",
    location: "New York, NY",
    refine_job_desc: " Software Engineer (2023 New Graduate)\n",
  },
  {
    company: "American Express",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/aexp.eightfold.ai",
    link: "https://aexp.eightfold.ai/careers?query=Campus&location=United%20States",
    location: "[Sunrise, FL]() / [Phoenix, AZ]()",
    refine_job_desc:
      " **No Visa Sponsorship** SWE, Information Security, DS, PM... Check the website for more locations and positions. ",
  },
  {
    company: "American Express",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/aexp.eightfold.ai",
    link: "https://aexp.eightfold.ai/careers?query=Campus&location=United%20States&pid=12787262&domain=aexp.com",
    location: "[Sunrise, FL]() / [Phoenix, AZ]()",
    refine_job_desc:
      " **No Visa Sponsorship** SWE, Information Security, DS, PM... Check the website for more locations and positions. ",
  },
  {
    company: "American Express",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/aexp.eightfold.ai",
    link: "https://aexp.eightfold.ai/careers?query=Campus&location=United%20States&pid=12787218&domain=aexp.com",
    location: "[Sunrise, FL]() / [Phoenix, AZ]()",
    refine_job_desc:
      " **No Visa Sponsorship** SWE, Information Security, DS, PM... Check the website for more locations and positions. ",
  },
  {
    company: "SambaNova Systems",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/sambanovasystems/jobs/4589084004",
    location: "Palo Alto, CA",
    refine_job_desc: " NCG - Software Engineer ",
  },
  {
    company: "NetApp",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/netapp.eightfold.ai",
    link: "https://netapp.eightfold.ai/careers?pid=12956090&domain=netapp.com&utm",
    location: "San Jose, CA & more",
    refine_job_desc:
      " NetApp Emerging Talent - Software Engineer. For the same role in other locations, look up the job title on their website. ",
  },
  {
    company: "Bank of America",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/campus.bankofamerica.com",
    link: "https://campus.bankofamerica.com/careers/global_technology_analyst_program__2023.html",
    location: "NYC & more",
    refine_job_desc: " Global Technology Analyst Program - 2023 ",
  },
  {
    company: "Gordian Software",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.ashbyhq.com",
    link: "https://jobs.ashbyhq.com/GordianSoftware/80539622-fb89-4806-9d1f-b319d100fedd/application",
    location: "Bellevue, WA & remote",
    refine_job_desc: " Software Engineer (New College Graduate) ",
  },
  {
    company: "Neuralink",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/neuralink/jobs/5285569003",
    location: "Fremont, CA & more",
    refine_job_desc: " Software Engineer (Fresh Grad 2023) ",
  },
  {
    company: "Mueller Water Products",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/muellerwaterproducts.wd5.myworkdayjobs.com",
    link: "https://muellerwaterproducts.wd5.myworkdayjobs.com/en-US/Mueller/job/Atlanta-GA/XMLNAME-2023-Software-Engineer-Development-Program_R3169",
    location: "Atlanta, GA",
    refine_job_desc: " 2023 Software Engineer Development Program ",
  },
  {
    company: "Cat Digital",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/cat.wd5.myworkdayjobs.com",
    link: "https://cat.wd5.myworkdayjobs.com/en-US/CaterpillarCareers/job/XMLNAME-2023-Software-Engineering-Recent-Grad-Positions---Cat-Digital_R0000153666",
    location: "Chicago & more",
    refine_job_desc: " 2023 Software Engineering Recent Grad ",
  },
  {
    company: "Thomson Reuters",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.thomsonreuters.com",
    link: "https://jobs.thomsonreuters.com/job/16553660/software-engineer-new-grad-2023-eagan-mn/",
    location: "Eagan, MN",
    refine_job_desc: " Software Engineer - New Grad - 2023 ",
  },
  {
    company: "JPMorgan Chase",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jpmc.fa.oraclecloud.com",
    link: "https://jpmc.fa.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1001/job/210321358",
    location: "US",
    refine_job_desc:
      " **No Visa Sponsorship** Software Engineer. Check their website for more locations and positions. ",
  },
  {
    company: "Silicon Labs",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/silabs/job/oOjJkfwU",
    location: "Austin, TX",
    refine_job_desc: " Software Engineer - New College Graduate ",
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/spacex/jobs/6324788002?gh_jid=6324788002&gh_src=130ac1d82us",
    location: "USA",
    refine_job_desc:
      " New Graduate, Software Engineer. Requires US Citizenship ",
  },
  {
    company: "Whatnot",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/whatnot/jobs/4309462004",
    location: "US Remote",
    refine_job_desc: " Software Engineer - New Grad ",
  },
  {
    company: "Zscaler",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.smartrecruiters.com",
    link: "https://jobs.smartrecruiters.com/Zscaler/743999839821324-cloud-operations-engineer-junior-new-grad-san-jose-ca",
    location: "San Jose, CA",
    refine_job_desc:
      " **US Citizens Only** Cloud Operations Engineer (Junior/New Grad) ",
  },
  {
    company: "Viasat",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.viasat.com",
    link: "https://careers.viasat.com/careers/SearchJobs/software?5924=%5B136579%2C136575%2C71960%2C136260%2C3676758%2C957887%2C447%2C136263%2C136276%2C136273%2C3565878%2C861477%2C861476%2C2141510%2C136264%2C450%2C451%2C4113995%2C438%2C136265%2C282724%2C136266%2C457%2C955417%2C445%2C955416%2C136267%2C453%2C429%2C1041212%2C3928212%2C136268%2C1703352%2C861478%2C2072344%2C796317%2C282732%2C4113999%2C796323%2C4110574%2C4110573%2C796319%2C796320%2C136280%2C136269%2C443%2C431%2C440%2C696735%2C136270%2C282733%2C2675930%2C3876358%2C136574%2C2675931%2C136277%2C136576%2C1815203%2C3695097%2C3708561%2C1703695%2C282719%2C1703694%2C436%2C136258%2C3243974%2C282722%2C430%2C437%2C71963%2C4113978%2C796325%2C136271%2C136573%2C2065633%2C136272%2C47975%2C2072343%2C861483%2C1815182%2C282720%5D&5924_format=4692&5925=%5B421%5D&5925_format=4693&5926=543&5926_format=4694&listFilterMode=1",
    location: "US multiple",
    refine_job_desc:
      " Software Engineer Early Career. LOTS of them. *NOTE* Some requires US Citizenship or Permanent Resident ",
  },
  {
    company: "Dell Technologies",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/dell.wd1.myworkdayjobs.com",
    link: "https://dell.wd1.myworkdayjobs.com/ExternalNonPublic/job/Round-Rock-Texas-United-States/Software-Engineer_R206821",
    location: "US Remote",
    refine_job_desc: " Software Engineer ",
  },
  {
    company: "Bank of America",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/campus.bankofamerica.com",
    link: "https://campus.bankofamerica.com/careers/global_technology_analyst_program__2023.html#:~:text=pursuing%20a%20BA/BS%20degree",
    location: "USA",
    refine_job_desc: " Software Engineer (New Grad)",
  },
  {
    company: "Duolingo",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/duolingo/jobs/6302219002",
    location: "Pittsburgh, PA",
    refine_job_desc: "Software Engineer I, New Graduate",
  },
  {
    company: "Duolingo",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/duolingo/jobs/6302219002",
    location: "Pittsburgh, PA",
    refine_job_desc: "Software Engineer I, New Graduate",
  },
  {
    company: "Duolingo",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/duolingo/jobs/6444746002",
    location: "Pittsburgh, PA",
    refine_job_desc: "Software Engineer I, New Graduate",
  },
  {
    company: "Duolingo",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/duolingo/jobs/6351942002",
    location: "Pittsburgh, PA",
    refine_job_desc: "Software Engineer I, New Graduate",
  },
  {
    company: "Delta Airlines",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/delta.avature.net",
    link: "https://delta.avature.net/careers/SearchJobs/associate%20software%20engineer",
    location: "Atlanta",
    refine_job_desc: " Associate SWE ",
  },
  {
    company: "RVO Health",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.redventures.com",
    link: "https://www.redventures.com/careers/apply/4518163",
    location: "Charlotte, NC",
    refine_job_desc: " 2023 Software Engineer ",
  },
  {
    company: "Vecna",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/vecnahealthcare.com",
    link: "https://vecnahealthcare.com/careers/?gnk=job&gni=8a7885a882ae53b00182e5e79a5c0889&gns=LinkedIn%2BLimited",
    location: "Burlington, MA",
    refine_job_desc: " New Grad - Software Engineer ",
  },
  {
    company: "ByteDance",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.bytedance.com",
    link: "https://jobs.bytedance.com/en/position/7138210801609591077/detail?spread=BSPP2KS",
    location: "Mountain View, CA",
    refine_job_desc:
      " Software Engineer, University Graduate (Lark)- 2023 Start (BS/MS) ",
  },
  {
    company: "Deutsche Bank",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/db.recsolu.com",
    link: "https://db.recsolu.com/external/requisitions/BdxMLqPU47fab0oSR7kz-g",
    location: "NYC & more",
    refine_job_desc:
      " Deutsche Bank Analyst Program - Technology, Data and Innovation - New York - 2023. For the same role in other locations, google the job title. ",
  },
  {
    company: "FactSet",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/factset.wd1.myworkdayjobs.com",
    link: "https://factset.wd1.myworkdayjobs.com/FactSetCareers/job/New-York-NY-USA/Software-Engineer---Americas-Campus--July-2023-_R18011",
    location: "NYC & more",
    refine_job_desc:
      " Software Engineer - Americas Campus (July 2023). Use the same application for all locations. ",
  },
  {
    company: "BlackRock",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.blackrock.com",
    link: "https://careers.blackrock.com/early-careers/americas/?2022-2023",
    location: "USA",
    refine_job_desc: " Full-Time Analyst Program ",
  },
  {
    company: "Paramount",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.viacomcbs.com",
    link: "https://careers.viacomcbs.com/job/Los-Angeles-Software-Engineering-Development-Program-%28Start-Date-January-June-2023%29-CA-90069/930477600/?feedId=340900&utm_source=LinkedInJobPostings&utm_campaign=ViacomCBS_LinkedIn",
    location: "Los Angeles, New York, San Francisco",
    refine_job_desc:
      " Software Engineering Development Program (Start Date: January / June 2023)",
  },
  {
    company: "Flexport",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/flexport",
    location: "Bellevue, Chicago, San Francisco",
    refine_job_desc: "Masters",
  },
  {
    company: "Flexport",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/flexport/jobs/3878424",
    location: "Bellevue, Chicago, San Francisco",
    refine_job_desc: "Masters",
  },
  {
    company: "Flexport",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/flexport/jobs/4499827",
    location: "Bellevue, Chicago, San Francisco",
    refine_job_desc: "Masters",
  },
  {
    company: "Flexport",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/flexport/jobs/4515031",
    location: "Bellevue, Chicago, San Francisco",
    refine_job_desc: "Masters",
  },
  {
    company: "Flexport",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/flexport/jobs/4515144",
    location: "Bellevue, Chicago, San Francisco",
    refine_job_desc: "Masters",
  },
  {
    company: "Visa",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/usa.visa.com",
    link: "https://usa.visa.com/en_us/jobs/REF52473N",
    location: "Austin, TX",
    refine_job_desc:
      " Software Engineer - Undergrad New College Grad - Multiple Locations - 2023 ",
  },
  {
    company: "Appian Corporation",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/appian/7d45c0ae/?tl=de206e19",
    location: "McLean, VA",
    refine_job_desc: " Technology Strategy Engineer ",
  },
  {
    company: "Arm",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/earlycareers-arm.icims.com",
    link: "https://earlycareers-arm.icims.com/jobs/6215/graduate-rotation-engineer---cpu/job?mode=submit_apply",
    location: "Austin, TX",
    refine_job_desc: " Graduate Rotation Engineer - CPU ",
  },
  {
    company: "Netsmart",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/ntst.wd1.myworkdayjobs.com",
    link: "https://ntst.wd1.myworkdayjobs.com/Careers/job/Overland-Park-KS/Software-Engineer-May-Grad-2023--Campus-_R010688?source=LinkedIn",
    location: "Overland Park, KS",
    refine_job_desc:
      " **No Visa Sponsorship** Software Engineer May Grad 2023 ",
  },
  {
    company: "TikTok",
    companyDomain: "tiktok.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.tiktok.com",
    link: "https://careers.tiktok.com/position?keywords=&category=&location=CT_203%2CCT_75%2CCT_222%2CCT_104%2CCT_223%2CCT_221%2CCT_247%2CCT_157%2CCT_243%2CCT_114%2CCT_94&project=&type=2&job_hot_flag=&current=1&limit=10&functionCategory=",
    location: "",
    refine_job_desc: " SWE, MLE, DS, SRE, and Researcher ",
  },
  {
    company: "Point72",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.point72.com",
    link: "https://careers.point72.com/CSJobDetail?jobName=2023-cubist-quant-academy-developers&jobCode=CSS-0010116",
    location: "NYC",
    refine_job_desc: " 2023 Cubist Quant Academy--Developers ",
  },
  {
    company: "Cadence Design Systems",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/cadence.wd1.myworkdayjobs.com",
    link: "https://cadence.wd1.myworkdayjobs.com/External_Careers/job/SAN-JOSE/Software-Engineer-for-Mixed-Signal-Simulation-and-Verification--New-College-Graduate-_R40004-1",
    location: "San Jose, CA",
    refine_job_desc: "Software Engineer (New College Graduate)",
  },
  {
    company: "Cadence Design Systems",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/cadence.wd1.myworkdayjobs.com",
    link: "https://cadence.wd1.myworkdayjobs.com/External_Careers/job/SAN-JOSE/Software-Engineer--New-College-Graduate-_R40844-1",
    location: "San Jose, CA",
    refine_job_desc: "Software Engineer (New College Graduate)",
  },
  {
    company: "Atlassian",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.atlassian.com",
    link: "https://www.atlassian.com/company/careers/students",
    location: "Fully Remote, MTV",
    refine_job_desc: "SWE",
  },
  {
    company: "Atlassian",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.atlassian.com",
    link: "https://www.atlassian.com/company/careers/detail/977df53a-539d-4b6c-9fbf-0167d8ffd83b",
    location: "Fully Remote, MTV",
    refine_job_desc: "SWE",
  },
  {
    company: "Atlassian",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.atlassian.com",
    link: "https://www.atlassian.com/company/careers/detail/c8c64071-c8be-402c-8ee7-147cfc2d5f93",
    location: "Fully Remote, MTV",
    refine_job_desc: "SWE",
  },
  {
    company: "Atlassian",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.atlassian.com",
    link: "https://www.atlassian.com/company/careers/detail/3dd03324-aff9-4e59-b3c6-8516c530f868",
    location: "Fully Remote, MTV",
    refine_job_desc: "SWE",
  },
  {
    company: "Nutanix",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/nutanix.eightfold.ai",
    link: "https://nutanix.eightfold.ai/careers?pid=13264357&domain=nutanix.com",
    location: "San Jose, CA (Hybrid)",
    refine_job_desc: " Software Engineering - New Grad ",
  },
  {
    company: "Sentar",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/sentar.hrmdirect.com",
    link: "https://sentar.hrmdirect.com/employment/job-opening.php?req=2180283&&#job",
    location: "Huntsville, AL",
    refine_job_desc:
      " **Need Active DoD Security Clearance at the SAP/SCI level or above**  Junior Software Developer ",
  },
  {
    company: "M&T Bank",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/mtb.wd5.myworkdayjobs.com",
    link: "https://mtb.wd5.myworkdayjobs.com/Campus/job/Technology-Development-Program_R39785",
    location: "Buffalo, NY",
    refine_job_desc: " **No Visa Sponsorship** Technology Development Program ",
  },
  {
    company: "Citi",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.citi.com",
    link: "https://jobs.citi.com/job/new-york/icg-technology-full-time-analyst-software-development-new-york-city-north-america-2023/287/34889411712",
    location: "",
    refine_job_desc:
      " **No Visa Sponsorship** ICG Technology, Full Time Analyst, Software Development (Check their website for more roles and more locations) ",
  },
  {
    company: "United Airlines",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.united.com",
    link: "https://careers.united.com/job/16486849/associate-early-career-digital-leadership-program-campus-2023-hires-new-grads-chicago/",
    location: "Chicago,IL",
    refine_job_desc:
      " **No Visa Sponsorship** Associate \u2013 Early Career Digital Leadership Program ",
  },
  {
    company: "Sentry",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/sentry/jobs/4349409?s=LinkedIn&source=LinkedIn",
    location: "San Francisco, CA",
    refine_job_desc: " Software Engineer, New Grad (2023) ",
  },
  {
    company: "DISCO",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.csdisco.com",
    link: "https://www.csdisco.com/careers/software-engineer-associate",
    location: "Austin, TX",
    refine_job_desc: " Software Engineer Associate ",
  },
  {
    company: "ZipRecruiter",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/ziprecruiter/jobs/4546753",
    location: "Santa Monica, CA or US Remote",
    refine_job_desc: " Software Engineer - New Grad ",
  },
  {
    company: "Singularity Data",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/apply.workable.com",
    link: "https://apply.workable.com/risingwave-labs/j/02769A5861/",
    location: "Santa Clara",
    refine_job_desc: " Software Engineer (New Grad)-Cloud Database ",
  },
  {
    company: "HPE",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.hpe.com",
    link: "https://careers.hpe.com/us/en/hpe-interns-graduates",
    location: "",
    refine_job_desc: " SDE, DS, PM, MLE ... ",
  },
  {
    company: "Northrop Grumman",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.northropgrumman.com",
    link: "https://www.northropgrumman.com/jobs/?country=United%20States%20of%20America&category=Engineering&search=2023%20Associate%20Software%20Engineer",
    location: "US",
    refine_job_desc:
      " **Be able to obtain a U.S. Government security clearance (U.S. citizenship is a pre-requisite)** 2023 Associate Software Engineer ",
  },
  {
    company: "Fidelity",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.fidelity.com",
    link: "https://jobs.fidelity.com/job-details/16491200/2022-2023-undergraduates-leap-full-stack-engineer/",
    location: "",
    refine_job_desc:
      " **No Visa Sponsorship** Undergraduates Full Stack Engineer ",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/engineering/software-engineer-devices-platform-new-grad-december-2022-4450143/?gh_src=7j0um41",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/engineering/software-engineer-macos-new-grad-december-2022-4563838/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/engineering/software-engineer-azure-integrations-new-grad-december-2022-4353520/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/engineering/software-engineer-customer-identity-cloud-new-grad-mayjune-2023-4579647/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/engineering/software-engineer-in-test-new-grad-mayjune-2023-4650432/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/engineering/software-engineer-engineering-services-new-grad-dec-2022-4624884/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Okta",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.okta.com",
    link: "https://www.okta.com/company/careers/product/competitive-intelligence-new-grad-customer-identity-cloud-4620106/",
    location: "US Remote",
    refine_job_desc: "New Grad Software Engineer, Devices Platform (Dec 2022)",
  },
  {
    company: "Mastercard",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/mastercard.wd1.myworkdayjobs.com",
    link: "https://mastercard.wd1.myworkdayjobs.com/Campus/job/OFallon-Missouri/Software-Engineer_R-174382",
    location: "Multiple Locations",
    refine_job_desc: " Software Engineer I ",
  },
  {
    company: "Walmart",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.walmart.com",
    link: "https://careers.walmart.com/us/jobs/WD1241701-usa-2023-full-time-sams-club-software-engineer-ii",
    location: "BENTONVILLE, AR",
    refine_job_desc:
      " (**No Visa Sponsorship available**) Sam's Club Software Engineer II ",
  },
  {
    company: "Draftkings",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/draftkings.wd1.myworkdayjobs.com",
    link: "https://draftkings.wd1.myworkdayjobs.com/en-US/Campus_Career_Portal/",
    location: "Boston, MA",
    refine_job_desc: " SWE, SRE, and Data Analyst ",
  },
  {
    company: "BAE System",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.baesystems.com",
    link: "https://jobs.baesystems.com/global/en/search-results?keywords=2023%20Entry%20Level%20Software%20Engineer&s=1",
    location: "US",
    refine_job_desc:
      " **Requires US Citizenship Status** 2023 Entry Level Software Engineer ",
  },
  {
    company: " Nextdoor ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/about.nextdoor.com",
    link: "https://about.nextdoor.com/careers-list/?gh_jid=4571489",
    location: "San Francisco, CA / US Remote",
    refine_job_desc: "SF",
  },
  {
    company: " Nextdoor ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/about.nextdoor.com",
    link: "https://about.nextdoor.com/careers-list/?gh_jid=4571500",
    location: "San Francisco, CA / US Remote",
    refine_job_desc: "SF",
  },
  {
    company: " Nextdoor ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/about.nextdoor.com",
    link: "https://about.nextdoor.com/careers-list/?gh_jid=4328986",
    location: "San Francisco, CA / US Remote",
    refine_job_desc: "SF",
  },
  {
    company: "PayPal",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/wd1.myworkdaysite.com",
    link: "https://wd1.myworkdaysite.com/recruiting/paypal/jobs/job/San-Jose-California-United-States-of-America/Software-Engineer--Recent-Graduate_R0092213-1?Codes=W-LINKEDIN",
    location: "US multiple",
    refine_job_desc: " Software Engineer- Recent Graduate ",
  },
  {
    company: "ADP",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/tech.adp.com",
    link: "https://tech.adp.com/job-search-results/?keyword=grad",
    location: "",
    refine_job_desc:
      " GPT Development Program \u2013 Associate Application Developer ",
  },
  {
    company: "Chewy",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.chewy.com",
    link: "https://careers.chewy.com/us/en/job/4521685/Software-Engineer-1-2023-Campus-Recruiting",
    location: "",
    refine_job_desc:
      " **Current permanent U.S. work authorization required** Software Engineer 1 \u2013 2023 Campus Recruiting ",
  },
  {
    company: "H&R Block",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/sjobs.brassring.com",
    link: "https://sjobs.brassring.com/TGnewUI/Search/Home/Home?partnerid=25515&siteid=5710#home",
    location: "Kansas City",
    refine_job_desc:
      ' Search "Associate Software Engineer" for the roles, it needs ssn(*) ',
  },
  {
    company: "Samsara",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.samsara.com",
    link: "https://www.samsara.com/company/careers/roles?departments=Early%20Career",
    location: "Remote - US",
    refine_job_desc: "~~SWE~~",
  },
  {
    company: "Samsara",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/samsara/jobs/4475858?gh_jid=4475858",
    location: "Remote - US",
    refine_job_desc: "~~SWE~~",
  },
  {
    company: "Samsara",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/samsara/jobs/4475594?gh_jid=4475594",
    location: "Remote - US",
    refine_job_desc: "~~SWE~~",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/59640365-448f-49c3-bce3-8b83e0570197",
    location: "Costa Mesa, CA",
    refine_job_desc: "Robotics Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/e9eecced-536a-460a-8145-5c9fcdb84a8b",
    location: "Costa Mesa, CA",
    refine_job_desc: "Robotics Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/13240f81-0325-41a7-b268-e9f464805bc8",
    location: "Costa Mesa, CA",
    refine_job_desc: "Robotics Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/698c6c0b-9097-4028-bad3-44aaaaa07d0e",
    location: "Costa Mesa, CA",
    refine_job_desc: "Robotics Software Engineer",
  },
  {
    company: "IXL",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/ie.ixl.com",
    link: "https://ie.ixl.com/company/careers?gh_jid=6304481002",
    location: "",
    refine_job_desc: " Software Developer, New Grad ",
  },
  {
    company: "IXL",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/ie.ixl.com",
    link: "https://ie.ixl.com/company/careers?gh_jid=6304481002",
    location: "",
    refine_job_desc: " Software Developer, New Grad ",
  },
  {
    company: "IXL",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/ie.ixl.com",
    link: "https://ie.ixl.com/company/careers?gh_jid=6304459002",
    location: "",
    refine_job_desc: " Software Developer, New Grad ",
  },
  {
    company: "IXL",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/ie.ixl.com",
    link: "https://ie.ixl.com/company/careers?gh_jid=6304303002",
    location: "",
    refine_job_desc: " Software Developer, New Grad ",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/59640365-448f-49c3-bce3-8b83e0570197",
    location: "Costa Mesa, CA and Atlanta, GA",
    refine_job_desc: "Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/59640365-448f-49c3-bce3-8b83e0570197",
    location: "Costa Mesa, CA and Atlanta, GA",
    refine_job_desc: "Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/b0a84037-8a50-4584-8637-ec3f9389bf91",
    location: "Costa Mesa, CA and Atlanta, GA",
    refine_job_desc: "Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/e9eecced-536a-460a-8145-5c9fcdb84a8b",
    location: "Costa Mesa, CA and Atlanta, GA",
    refine_job_desc: "Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/13240f81-0325-41a7-b268-e9f464805bc8",
    location: "Costa Mesa, CA and Atlanta, GA",
    refine_job_desc: "Software Engineer",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/698c6c0b-9097-4028-bad3-44aaaaa07d0e",
    location: "Costa Mesa, CA and Atlanta, GA",
    refine_job_desc: "Software Engineer",
  },
  {
    company: "Vanguard",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.vanguardjobs.com",
    link: "https://www.vanguardjobs.com/job-search-results/?category=Technology&level[]=Student%20%26%20Recent%20Graduates",
    location: "US",
    refine_job_desc:
      " **No Visa Sponsorship** several IT, application engineers, and security roles ",
  },
  {
    company: "Apple",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.apple.com",
    link: "https://jobs.apple.com/en-us/details/200430385/engineer-evolve-rotation-program?team=SFTWR",
    location: "Seattle, Austin, and NYC",
    refine_job_desc:
      ' Engineer, Evolve Rotation Program. Search "Engineer, Evolve Rotation Program" on their website for more locations ',
  },
  {
    company: "Intel",
    companyDomain: "intel.com",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.intel.com",
    link: "https://jobs.intel.com/job/phoenix/college-graduate-computer-science-bs-ms-phd/41147/35292770992",
    location: "",
    refine_job_desc: " College Graduate - Computer Science (BS/MS/PHD) ",
  },
  {
    company: "TripActions",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/tripactions.com",
    link: "https://tripactions.com/job-openings/4488671?gh_jid=4488671&gh_src=b13de4e21",
    location: "Palo Alto, CA",
    refine_job_desc: " Software Engineering Internship/ New College Grad ",
  },
  {
    company: "ExodusPoint Capital",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/exoduspoint/",
    location: "NYC, London",
    refine_job_desc:
      " Graduate Python Developer, Infrastructure Graduate Analyst, Technology Developer \u2013 Rotational Program, and Junior Trading Engineer. Some may need Master/Phd degree. ",
  },
  {
    company: "Awardco",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.award.co",
    link: "https://www.award.co/careers",
    location: "Pleasant Grove, Utah",
    refine_job_desc: "Back-End SWE (C# and .NET Framework)",
  },
  {
    company: "Awardco",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.award.co",
    link: "https://www.award.co/position?gh_jid=4118198004",
    location: "Pleasant Grove, Utah",
    refine_job_desc: "Back-End SWE (C# and .NET Framework)",
  },
  {
    company: "Awardco",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/award.co",
    link: "https://award.co/position?gh_jid=4664842004",
    location: "Pleasant Grove, Utah",
    refine_job_desc: "Back-End SWE (C# and .NET Framework)",
  },
  {
    company: "Expedia",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.expediagroup.com",
    link: "https://careers.expediagroup.com/jobs/?keyword=early+careers&&filter[category]=Technology&filter[country]=United+States",
    location: "Seattle,Austin,Chicago",
    refine_job_desc: " Multiple Roles DS,SDE,Machine Learning\n",
  },
  {
    company: "eBay",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/ebay/58692682/",
    location: "",
    refine_job_desc: "Software Engineer, Recent College Graduate",
  },
  {
    company: "Decide",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/decide/jobs/4080847005",
    location: "Austin, TX Remote",
    refine_job_desc: " Software Engineer, Integration Development ",
  },
  {
    company: "Cloudflare",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/cloudflare/jobs/4557684",
    location: "Multiple locations",
    refine_job_desc: " Software Engineer - New Grad\n",
  },
  {
    company: "Forbes",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/forbes/jobs/4659385004",
    location: "Jersey City, NJ",
    refine_job_desc: "Junior Software Engineer, Systems",
  },
  {
    company: "Forbes",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/forbes/jobs/4659385004",
    location: "Jersey City, NJ",
    refine_job_desc: "Junior Software Engineer, Systems",
  },
  {
    company: "NXP",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/nxp.wd3.myworkdayjobs.com",
    link: "https://nxp.wd3.myworkdayjobs.com/careers/job/Austin-Oakhill-Office/Entry-Level-Software-Engineers---December-2022-and-May-2023-Graduates_R-10043733?source=web_handshake",
    location: "",
    refine_job_desc:
      " Entry Level Software Engineers - December 2022 and May 2023 Graduates ",
  },
  {
    company: "Voleon",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/voleon/a059b894-b468-4fb1-a86f-36fb63afe3a5",
    location: "BERKELEY, CA OR AUSTIN, TX",
    refine_job_desc: " Software Engineer - University Hire 2023 ",
  },
  {
    company: "Observe",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/observeinc/0df1b2ef-0118-4cc1-b219-f11bf06faceb",
    location: "San Mateo",
    refine_job_desc:
      " [Software Engineer: Frontend, New Grad](https://jobs.lever.co/observeinc/0df1b2ef-0118-4cc1-b219-f11bf06faceb);<br> [Software Engineer: Core Platform](https://jobs.lever.co/observeinc/0b61025a-cf38-4893-bacc-e12990e616df) ",
  },
  {
    company: "Veeva",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/veeva/67720ee3-fa21-49cc-83f0-5bd60e972e85",
    location: "",
    refine_job_desc:
      ' **Work visa sponsorship is available for Masters/PhDs** Search "Associate Software Engineer" on https://jobs.lever.co/veeva/ for roles opened to Bachelors and in other locations ',
  },
  {
    company: "Interactive Brokers",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/ibkr/jobs/6378233002",
    location: "North America",
    refine_job_desc:
      " Multiple locations. Role matched to your qualifications. ",
  },
  {
    company: "Norfolk Southern",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.nscorp.com",
    link: "https://jobs.nscorp.com/job/Atlanta-Associate-Developer-Trainee-2023-GA-30308/945994000/",
    location: "Atlanta",
    refine_job_desc: "Associate Developer Trainee 2023",
  },
  {
    company: "Norfolk Southern",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.nscorp.com",
    link: "https://jobs.nscorp.com/job/Atlanta-Associate-Developer-Trainee-2023-GA-30308/945994000/",
    location: "Atlanta",
    refine_job_desc: "Associate Developer Trainee 2023",
  },
  {
    company: "Norfolk Southern",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.nscorp.com",
    link: "https://jobs.nscorp.com/job/Atlanta-JavJ2EE-Application-Developer-Trainee-2023-GA-30308/946001500/",
    location: "Atlanta",
    refine_job_desc: "Associate Developer Trainee 2023",
  },
  {
    company: "Northern Trust",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.northerntrust.com",
    link: "https://careers.northerntrust.com/jobs/new-grad-technology-chicago-33462",
    location: "[Chicago]() and [except Chicago]()",
    refine_job_desc: "New Grad Technology - Chicago",
  },
  {
    company: "Northern Trust",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.northerntrust.com",
    link: "https://careers.northerntrust.com/jobs/new-grad-technology-chicago-33462",
    location: "[Chicago]() and [except Chicago]()",
    refine_job_desc: "New Grad Technology - Chicago",
  },
  {
    company: "Northern Trust",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.northerntrust.com",
    link: "https://careers.northerntrust.com/jobs/early-career-technology-all-us-locations-except-chicago-29161",
    location: "[Chicago]() and [except Chicago]()",
    refine_job_desc: "New Grad Technology - Chicago",
  },
  {
    company: "Northern Trust",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.northerntrust.com",
    link: "https://careers.northerntrust.com/jobs/new-grad-technology-chicago-33462",
    location: "[Chicago]() and [except Chicago]()",
    refine_job_desc: "New Grad Technology - Chicago",
  },
  {
    company: "Northern Trust",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.northerntrust.com",
    link: "https://careers.northerntrust.com/jobs/early-career-technology-all-us-locations-except-chicago-29161",
    location: "[Chicago]() and [except Chicago]()",
    refine_job_desc: "New Grad Technology - Chicago",
  },
  {
    company: "Nightfall AI",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/nightfall/d9edaa15-300f-48b0-9481-556ad995cd00",
    location: "San Francisco",
    refine_job_desc: "New Grad Full Stack Engineer",
  },
  {
    company: "Nightfall AI",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/nightfall/d9edaa15-300f-48b0-9481-556ad995cd00",
    location: "San Francisco",
    refine_job_desc: "New Grad Full Stack Engineer",
  },
  {
    company: "Nightfall AI",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/nightfall/80cdcd6e-1d19-41c5-9eeb-ee1b3d4d5a56",
    location: "San Francisco",
    refine_job_desc: "New Grad Full Stack Engineer",
  },
  {
    company: "Constant Contact",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.constantcontact.com",
    link: "https://careers.constantcontact.com/global/en/job/4605737/Software-Engineering-Development-Program-United-States",
    location: "Waltham",
    refine_job_desc:
      " Software Engineering Development Program (United States) ",
  },
  {
    company: " Stytch ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.ashbyhq.com",
    link: "https://jobs.ashbyhq.com/stytch/b4ee9734-3657-4393-8eca-269ae179d7eb?utm_source=35JPEzvE9E",
    location: "San Francisco",
    refine_job_desc: "Software Engineer - New Grad 2023",
  },
  {
    company: "Sprout Social",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/sproutsocial.com",
    link: "https://sproutsocial.com/careers/open-positions/?gh_jid=4503546&gh_src=4d2fd2d61us#/4503546",
    location: "Remote US",
    refine_job_desc: " Associate Software Engineer - New Grad ",
  },
  {
    company: "C3.ai",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/c3.ai",
    link: "https://c3.ai/job-description/?gh_jid=6345004002",
    location: "Redwood City, CA",
    refine_job_desc: " Software Engineer - Early Professional (2023) ",
  },
  {
    company: "Cleanlab.ai",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/cleanlab.ai",
    link: "https://cleanlab.ai/careers/",
    location: "Remote",
    refine_job_desc:
      " **Full-time positions start summer 2023** Backend Engineer, Frontend Engineer, Full-Stack Engineer, Infrastructure Engineer, ML Engineer, and Research Scientist ",
  },
  {
    company: "SAP",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.sap.com",
    link: "https://jobs.sap.com/job/Palo-Alto-SOFTWARE-ENGINEERING-SAP-Next-Talent-Rotational-Program-%282023-Grads%29-CA-94304/865869201/",
    location: "Palo Alto, CA",
    refine_job_desc:
      " **Requirement of not needing permanent US work authorization (now nor in the future) to be considered, there is no visa sponsorship available at this time.** SOFTWARE ENGINEERING: SAP Next Talent - Rotational Program (2023 Grads) ",
  },
  {
    company: "Charles Schwab",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.schwabjobs.com",
    link: "https://jobs.schwabjobs.com/job/westlake/associate-software-development-and-engineering/33727/39220254208",
    location: "",
    refine_job_desc:
      " **A graduation date of December 2022 or earlier and ability to start full time with the program on January 23rd** Associate - Software Development & Engineering ",
  },
  {
    company: " Radical Semiconductors ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/simplify.jobs",
    link: "https://simplify.jobs/p/4695e0d6-77c6-453c-ab8c-239abb28a450/Full-Stack-Product-Engineer-New-Grad",
    location: "Los Angeles, CA",
    refine_job_desc: "Full-Stack Product Engineer (New Grad)",
  },
  {
    company: " Affirm ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/affirm/jobs/5249942003?t=54db7ac73us",
    location: "Remote(US)",
    refine_job_desc: "Software Engineer, New Grad 2023",
  },
  {
    company: "2K (Visual Concepts)",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/visualconcepts/jobs/5293521003",
    location: "Austin, TX",
    refine_job_desc:
      " **This is a great opportunity ideally starting in January 2023.** Associate Software Engineer (Please check their websites for other locations and roles, but can't confirm if they are ng roles.) ",
  },
  {
    company: "Blend360",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.smartrecruiters.com",
    link: "https://careers.smartrecruiters.com/Blend360?search=new%20grad",
    location: "Denver, CO; Columbia, MD",
    refine_job_desc: " Data Engineer and Data Scientist ",
  },
  {
    company: "Tektronix",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/careers.fortive.com",
    link: "https://careers.fortive.com/job/FORTUSTEK011301/2023-Engineering-Rotation-Program-Participant?utm_source=linkedin&src=SNS-10043&utm_medium=phenom-feeds&src=SNS-10043",
    location: "Beaverton, Oregon",
    refine_job_desc: " 2023 Engineering Rotation Program ",
  },
  {
    company: " Gartner ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/jobs.gartner.com",
    link: "https://jobs.gartner.com/jobs/job/70128-associate-software-engineer-launch-rotational-program-2023-grads/",
    location: "Stamford, CT; Irving, TX",
    refine_job_desc:
      "Associate Software Engineer (LAUNCH Rotational Program - 2023 Grads)",
  },
  {
    company: "Washington University School of Medicine",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/medicine.wustl.edu",
    link: "https://medicine.wustl.edu",
    location: "[St. Louis]()/Remote",
    refine_job_desc:
      "Application Developer - WashU IT Research Infrastructure Services",
  },
  {
    company: "Washington University School of Medicine",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/goo.gl",
    link: "https://goo.gl/maps/6K2ZDhTL8D8Rz12AA",
    location: "[St. Louis]()/Remote",
    refine_job_desc:
      "Application Developer - WashU IT Research Infrastructure Services",
  },
  {
    company: "Washington University School of Medicine",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/wustl.wd1.myworkdayjobs.com",
    link: "https://wustl.wd1.myworkdayjobs.com/External/job/Washington-University-Medical-Campus/Application-Developer-Opportunities---WUIT---Research-Application-Engineering_JR66760",
    location: "[St. Louis]()/Remote",
    refine_job_desc:
      "Application Developer - WashU IT Research Infrastructure Services",
  },
  {
    company: " Grant Street Group ",
    role: "Software Engineer",
    type: "full-time",
    tags: ["Software Engineer", "New Grad"],
    logo: "https://logo.clearbit.com/www.grantstreet.com",
    link: "https://www.grantstreet.com/careers/career_opportunities/entry-level-software-developer-remote-11-21-2022/",
    location: "Remote",
    refine_job_desc: "Entry Level Software Developer",
  },

  {
    company: "Akuna Capital",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/akunacapital.com",
    link: "https://akunacapital.com/careers?&experience=intern&search_term=#careers",
    location: "Chicago, IL; Austin, TX; Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Akuna Capital",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/akunacapital.com",
    link: "https://akunacapital.com/job-details?gh_jid=4247108",
    location: "Chicago, IL; Austin, TX; Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Apple",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.apple.com",
    link: "https://www.apple.com/careers/us/",
    location: "Cupertino, CA",
    refine_job_desc: "",
  },
  {
    company: "Apple",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.apple.com",
    link: "https://jobs.apple.com/en-us/details/200389054/software-engineering-internship?team=SDNT",
    location: "Cupertino, CA",
    refine_job_desc: "",
  },
  {
    company: "Apple",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.apple.com",
    link: "https://jobs.apple.com/en-us/details/200389042/hardware-technology-internship?team=STDNT",
    location: "Cupertino, CA",
    refine_job_desc: "",
  },
  {
    company: "D. E. Shaw & Co.",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.deshaw.com",
    link: "https://www.deshaw.com/careers/internships",
    location: "New York City, NY",
    refine_job_desc: "",
  },
  {
    company: "Goldman Sachs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.goldmansachs.com",
    link: "https://www.goldmansachs.com/careers/students/programs/americas/summer-analyst-program.html",
    location: "Global",
    refine_job_desc: "",
  },
  {
    company: "Cisco (Meraki)",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Full-Stack-Software-Engineer-Intern-Summer-2023-Meraki/1368018?source=Cisco+Jobs+Career+Site&tags=CDC+SnNG+interview-tips",
    location: "San Francisco",
    refine_job_desc: "",
  },
  {
    company: "Amazon",
    companyDomain: "amazon.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.amazon.jobs",
    link: "https://www.amazon.jobs/en/jobs/2110678/software-development-engineer-internship-2023-us",
    location: "Multiple Locations in United States",
    refine_job_desc: "",
  },
  {
    company: "Amazon",
    companyDomain: "amazon.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.amazon.jobs",
    link: "https://www.amazon.jobs/en/jobs/2141830/data-engineer-internship-2023-us",
    location: "Multiple Locations in United States",
    refine_job_desc: "",
  },
  {
    company: "Amazon",
    companyDomain: "amazon.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.amazon.jobs",
    link: "https://www.amazon.jobs/en/jobs/2141835/front-end-engineer-internship-2023-us",
    location: "Multiple Locations in United States",
    refine_job_desc: "",
  },
  {
    company: "BlackRock",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/blackrock.tal.net",
    link: "https://blackrock.tal.net/vx/lang-en-GB/mobile-0/brand-3/xf-ab3ba52a6a61/wid-1/candidate/so/pm/1/pl/1/opp/6075-Summer-Internship-Program-Americas/en-GB",
    location: "Multiple Locations in United States",
    refine_job_desc: "",
  },
  {
    company: "Two Sigma",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.twosigma.com",
    link: "https://careers.twosigma.com/careers/JobDetail/New-York-New-York-United-States-Quantitative-Researcher-Internship/10972",
    location: "New York, NY",
    refine_job_desc: "",
  },
  {
    company: "Two Sigma",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.twosigma.com",
    link: "https://careers.twosigma.com/careers/JobDetail?jobId=11002",
    location: "New York, NY",
    refine_job_desc: "",
  },
  {
    company: "Citadel",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.citadel.com",
    link: "https://www.citadel.com/careers/open-opportunities/students/internships/",
    location: "Chicago, New York",
    refine_job_desc: "",
  },
  {
    company: "Citadel",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.citadel.com",
    link: "https://www.citadel.com/careers/details/software-engineer-intern-us/",
    location: "Chicago, New York",
    refine_job_desc: "",
  },
  {
    company: "Citadel",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.citadel.com",
    link: "https://www.citadel.com/careers/details/quantitative-research-analyst-intern-us-2/",
    location: "Chicago, New York",
    refine_job_desc: "",
  },
  {
    company: "Hewlett Packard Enterprise",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.hpe.com",
    link: "https://careers.hpe.com/us/en/search-results?keywords=software%20intern",
    location: "Multiple Locations in United States",
    refine_job_desc: "",
  },
  {
    company: "Oracle",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/eeho.fa.us2.oraclecloud.com",
    link: "https://eeho.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions?keyword=software+engineer+intern",
    location: "United States",
    refine_job_desc: "",
  },
  {
    company: "Freddie Mac",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.freddiemac.com",
    link: "https://careers.freddiemac.com/us/en/job/JR7788/Multifamily-Software-Developer-Intern-Hybrid-or-Remote-Work-Schedule",
    location: "McLean, VA (Remote Available)",
    refine_job_desc: "",
  },
  {
    company: "Databento",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/databento/jobs/4374815?",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Verkada",
    companyDomain: "verkada.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/verkada?department=Engineering#:~:text=SOFTWARE%20%2D%20CORE%20COMMAND-,SOFTWARE%20%2D%20EARLY%20CAREERS,-APPLY",
    location: "San Mateo, CA",
    refine_job_desc: "",
  },
  {
    company: "Arrowstreet Capital",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/arrowstreetcapital.wd5.myworkdayjobs.com",
    link: "https://arrowstreetcapital.wd5.myworkdayjobs.com/en-US/Arrowstreet/job/Boston/Quantitative-Researcher-Intern--Summer-2023_R700",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Sensata",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/sensata.wd1.myworkdayjobs.com",
    link: "https://sensata.wd1.myworkdayjobs.com/en-US/Sensata-Careers/job/Attleboro-Massachusetts/Software-Engineer-Intern---Summer-2023_IRC86996",
    location: "Attleboro, MA",
    refine_job_desc: "",
  },
  {
    company: "JP Morgan",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jpmc.fa.oraclecloud.com",
    link: "https://jpmc.fa.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1001/job/210321355",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Uber",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/university-uber.icims.com",
    link: "https://university-uber.icims.com/jobs/search?ss=1&searchKeyword=intern&searchRelation=keyword_all",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Uber",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/university-uber.icims.com",
    link: "https://university-uber.icims.com/jobs/119292/2023-uberstar-internship-program---data-science/job",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Uber",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/university-uber.icims.com",
    link: "https://university-uber.icims.com/jobs/119325/uberstar-program-manager%2c-%28strategy-%26-operations%2c-engineering%29/job",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Hudson River Trading",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.hudsonrivertrading.com",
    link: "https://www.hudsonrivertrading.com/careers/?_4118765=Internship",
    location: "New York, Austin, Chicago",
    refine_job_desc: "",
  },
  {
    company: "DRW",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/drw.com",
    link: "https://drw.com/work-at-drw/job/quantitative-research-intern-2301002/",
    location:
      "Chicago, IL; Greenwich, CT; Houston, TX; Austin, TX; New York, NY",
    refine_job_desc: "",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Software-Engineer-I-Intern-United-States/1380727",
    location: "Multiple Location in United States",
    refine_job_desc: "",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Data-Analyst-Intern-United-States/1380904?source=Cisco+Jobs+Career+Site&tags=CDC+Keyword+Search+students-and-new-graduates",
    location: "Multiple Location in United States",
    refine_job_desc: "",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Data-Scientist-Intern-United-States/1381149?source=Cisco+Jobs+Career+Site&tags=CDC+Keyword+Search+students-and-new-graduates",
    location: "Multiple Location in United States",
    refine_job_desc: "",
  },
  {
    company: "L3Harris Technologies",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.l3harris.com",
    link: "https://careers.l3harris.com/job/-/-/4832/33828250528?src=SNS-10240",
    location: "Mason, OH",
    refine_job_desc: "",
  },
  {
    company: "Vanguard",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.vanguardjobs.com",
    link: "https://www.vanguardjobs.com/job-search-results/?keyword=intern&category=Technology",
    location: "Malvern, PA, Charlotte, NC",
    refine_job_desc: "",
  },
  {
    company: "The Vendry",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/vendry/911401c3-9f06-4719-bc26-e3ac34207e67",
    location: "New York, NY",
    refine_job_desc: "",
  },
  {
    company: "Audible",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.amazon.jobs",
    link: "https://www.amazon.jobs/en/jobs/2125073/audible-software-development-engineer-internship",
    location: "Newark, NJ",
    refine_job_desc: "",
  },
  {
    company: "Ericsson",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.ericsson.com",
    link: "https://jobs.ericsson.com/job/Santa-Clara-Computer-Science-&-Data-Science-Internship-%282023%29-Cali/751881302/",
    location: "Santa Clara, CA",
    refine_job_desc: "",
  },
  {
    company: "Twitch",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.twitch.tv",
    link: "https://www.twitch.tv/jobs/careers/6469166002/",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Belvedere Trading",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/belvederetrading/5098c277-d2a6-41eb-ab9c-b80ded219154",
    location: "Chicago, IL",
    refine_job_desc: "",
  },
  {
    company: "Jump Trading",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.jumptrading.com",
    link: "https://www.jumptrading.com/careers/?locations=Chicago+Austin+New-York+New-Jersey+Remote+Cranberry-Township&titleSearch=campus+intern",
    location: "Chicago, IL",
    refine_job_desc: "",
  },
  {
    company: "Flatiron Health",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/flatiron.com",
    link: "https://flatiron.com/careers/open-positions/4454681",
    location: "NYC",
    refine_job_desc: "",
  },
  {
    company: "Flatiron Health",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/flatiron.com",
    link: "https://flatiron.com/careers/open-positions/4454681",
    location: "NYC",
    refine_job_desc: "",
  },
  {
    company: "F5",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ffive.wd5.myworkdayjobs.com",
    link: "https://ffive.wd5.myworkdayjobs.com/en-US/f5jobs/job/Seattle/Summer-2023-Internship---Software-Engineering_RP1026625",
    location: "Seattle, Boulder (Louisville), Boston, San Jose",
    refine_job_desc: "",
  },
  {
    company: "Lyft",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.lyft.com",
    link: "https://www.lyft.com/careers/early-talent?search=intern",
    location: "Multiple Locations in the US",
    refine_job_desc: "",
  },
  {
    company: "Nasdaq",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/nasdaq.wd1.myworkdayjobs.com",
    link: "https://nasdaq.wd1.myworkdayjobs.com/en-US/US_External_Career_Site/job/Software-Engineer-ing-Intern---US---Canada-2023-Internship_R0011592",
    location: "Multiple (USA and Canada)",
    refine_job_desc: "",
  },
  {
    company: "Viasat",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.viasat.com",
    link: "https://careers.viasat.com/careers/FolderDetail?folderId=11400",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "NetApp",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/netapp.eightfold.ai",
    link: "https://netapp.eightfold.ai/careers?seniority=Intern&pid=12955970&domain=netapp.com&triggerGoButton=false",
    location: "Multiple (USA)",
    refine_job_desc: "",
  },
  {
    company: "Epic Games",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.epicgames.com",
    link: "https://www.epicgames.com/site/en-US/careers/jobs?type=Intern&department=Engineering",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Mathworks",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.mathworks.com",
    link: "https://www.mathworks.com/company/jobs/opportunities/25610-multiple-openings-engineering-development-group-internship?utm_campaign=google_jobs_apply&utm_source=google_jobs_apply&utm_medium=organic",
    location: "Natick, MA",
    refine_job_desc: "",
  },
  {
    company: "Keysight Technologies",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.keysight.com",
    link: "https://jobs.keysight.com/go/Students/3065700/?q=&q2=&alertId=&title=software&location=US&shifttype=intern&department=",
    location: "Santa Rosa, CA",
    refine_job_desc: "",
  },
  {
    company: "Taboola",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.taboola.com",
    link: "https://www.taboola.com/careers/job/frontend-engineer-intern?gh_jid=4498767",
    location: "LA, CA",
    refine_job_desc: "",
  },
  {
    company: "Arrowstreet Capital",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/arrowstreetcapital.wd5.myworkdayjobs.com",
    link: "https://arrowstreetcapital.wd5.myworkdayjobs.com/en-US/Arrowstreet?q=intern",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Riot Games",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.riotgames.com",
    link: "https://www.riotgames.com/en/work-with-us/jobs#search=intern",
    location: "Multiple Locations (CA, MO)",
    refine_job_desc: "",
  },
  {
    company: "Riot Games",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.riotgames.com",
    link: "https://www.riotgames.com/en/j/4526877",
    location: "Multiple Locations (CA, MO)",
    refine_job_desc: "",
  },
  {
    company: "Riot Games",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.riotgames.com",
    link: "https://www.riotgames.com/en/j/4526870",
    location: "Multiple Locations (CA, MO)",
    refine_job_desc: "",
  },
  {
    company: "Riot Games",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.riotgames.com",
    link: "https://www.riotgames.com/en/work-with-us/job/4494239/product-manager-intern-mba-los-angeles-usa",
    location: "Multiple Locations (CA, MO)",
    refine_job_desc: "",
  },
  {
    company: "CGI",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/cgi.njoyn.com",
    link: "https://cgi.njoyn.com/cgi/xweb/xweb.asp?clid=21001&page=jobdetails&jobid=J0822-2628&BRID=990720&SBDID=943",
    location: "New Jersey, New Brunswick",
    refine_job_desc: "",
  },
  {
    company: "TikTok",
    companyDomain: "tiktok.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.tiktok.com",
    link: "https://careers.tiktok.com/position?keywords=software&category=&location=CT_243%2CCT_94%2CCT_157%2CCT_114%2CCT_104%2CCT_221%2CCT_222%2CCT_233%2CCT_75%2CCT_203%2CCT_1103347%2CCT_223%2CCT_1000001%2CCT_100762%2CCT_247&project=7129361493955135774&type=3&job_hot_flag=&current=1&limit=10&functionCategory=",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "ByteDance",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.bytedance.com",
    link: "https://jobs.bytedance.com/en/position/7138261141784611103/detail?spread=BSPP2KS",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "TD Ameritrade",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/sjobs.brassring.com",
    link: "https://sjobs.brassring.com/TGnewUI/Search/Home/Home?partnerid=25404&siteid=5601#jobDetails=3060632_5601",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Relativity Space",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/relativity/jobs/6363660002?gh_jid=6363660002",
    location: "Long Beach, CA",
    refine_job_desc: "",
  },
  {
    company: "Pitchbook",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/pitchbookdata/jobs/4024646006",
    location: "Seattle, Washington",
    refine_job_desc: "",
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/spacex/jobs/6366187002?gh_jid=6366187002",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Samsara",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/samsara/jobs/4522234?gh_jid=4522234",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Garmin",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs?tags3=Intern&page=1",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Garmin",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/9345?lang=en-us",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Garmin",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/9267?lang=en-us",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Garmin",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/9266?lang=en-us",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Garmin",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/9219?lang=en-us",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Garmin",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.garmin.com",
    link: "https://careers.garmin.com/careers-home/jobs/9243?lang=en-us",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "T.Rowe Price",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/troweprice.wd5.myworkdayjobs.com",
    link: "https://troweprice.wd5.myworkdayjobs.com/en-US/TRowePrice/job/Owings-Mills-MD/XMLNAME-2023-Global-Technology-Software-Engineering-Internship-Program_62478?source=Indeed",
    location: "Owings Mills, MD and Baltimore, MD",
    refine_job_desc: "",
  },
  {
    company: "The Home Depot",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.homedepot.com",
    link: "https://careers.homedepot.com/job-search-results/?keyword=intern",
    location: "Atlanta, GA",
    refine_job_desc: "",
  },
  {
    company: "Adobe",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.adobe.com",
    link: "https://careers.adobe.com/us/en/job/R131734/2023-Intern-Machine-Learning-Engineer-Intern",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Lowe's",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/talent.lowes.com",
    link: "https://talent.lowes.com/us/en/job/2411616BR/Software-Engineer-Undergraduate-Intern",
    location: "Charlotte, NC",
    refine_job_desc: "",
  },
  {
    company: "Collins Aerospace",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.rtx.com",
    link: "https://careers.rtx.com/global/en/job/01553158",
    location: "Cedar Rapids, Iowa",
    refine_job_desc: "",
  },
  {
    company: "AeroVironment",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/avav.wd1.myworkdayjobs.com",
    link: "https://avav.wd1.myworkdayjobs.com/en-US/avav?q=software%20engineer%20intern",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Allegion",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/allegion.wd5.myworkdayjobs.com",
    link: "https://allegion.wd5.myworkdayjobs.com/careers/job/Carmel-IN/Summer-Intern---Software-Engineer_JR23390-1",
    location: "Carmel, IN",
    refine_job_desc: "",
  },
  {
    company: "ABL",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.builtinla.com",
    link: "https://www.builtinla.com/job/internship/engineering-intern-summer-2023/124823?utm_campaign=google_jobs_apply&utm_source=google_jobs_apply&utm_medium=organic",
    location: "El Segundo, CA",
    refine_job_desc: "",
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/splunk/job/opeZkfwG",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/splunk/job/oUTZkfwQ",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Splunk",
    companyDomain: "splunk.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/splunk/job/opeZkfwG",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Cloudflare",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/cloudflare/jobs/4553743?gh_jid=4553743",
    location:
      "Austin, TX; or Champaign, IL; or Kirkland, WA; or San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "2K",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/2k/jobs/5303107003",
    location: "Novato, CA",
    refine_job_desc: "",
  },
  {
    company: "Lacework",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.lacework.com",
    link: "https://www.lacework.com/job-openings/?gh_jid=4646011004",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Emerson Automation Solutions",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/hdjq.fa.us2.oraclecloud.com",
    link: "https://hdjq.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/job/22023193",
    location: "Round Rock, TX",
    refine_job_desc: "",
  },
  {
    company: "Avanade",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.avanade.com",
    link: "https://careers.avanade.com/jobsenus/JobDetail/Intern-Software-Developer-Summer-2023/56206",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Silicon Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/silabs/search?q=&l=&c=University%20Recruiting",
    location: "Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "Silicon Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/silabs/job/oZgJkfw2",
    location: "Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "Silicon Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/silabs/job/oV6LkfwQ",
    location: "Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "ASML",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.asml.com",
    link: "https://www.asml.com/en/careers/internships?page=1&facets=vacancycountry%253dUS&sortBy=date",
    location: "Wilton, CT; San Jose, CA;",
    refine_job_desc: "",
  },
  {
    company: "ASML",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.asml.com",
    link: "https://www.asml.com/en/careers/find-your-job/5/3/0/software-test-engineer-intern-req53028",
    location: "Wilton, CT; San Jose, CA;",
    refine_job_desc: "",
  },
  {
    company: "ASML",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.asml.com",
    link: "https://www.asml.com/en/careers/find-your-job/5/2/5/devops-intern-req52528?ppc=JBP-181",
    location: "Wilton, CT; San Jose, CA;",
    refine_job_desc: "",
  },
  {
    company: "SingleStore",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/singlestore/jobs/4557044",
    location: "Seattle, WA; or Raleigh, NC",
    refine_job_desc: "",
  },
  {
    company: "SingleStore",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/singlestore/jobs/4557044",
    location: "Seattle, WA; or Raleigh, NC",
    refine_job_desc: "",
  },
  {
    company: "SingleStore",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/singlestore/jobs/4557045",
    location: "Seattle, WA; or Raleigh, NC",
    refine_job_desc: "",
  },
  {
    company: "Domeyard LP",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/domeyard/jobs/1079185",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/",
    location: "Costa Mesa, CA",
    refine_job_desc: "",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/d15840b6-081b-4d4c-95ed-008d681b4f7f",
    location: "Costa Mesa, CA",
    refine_job_desc: "",
  },
  {
    company: "Anduril",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anduril/c6d4fc93-2be9-4018-8160-4f490d11328c",
    location: "Costa Mesa, CA",
    refine_job_desc: "",
  },
  {
    company: "Wolverine",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/wolve/4a6fd0be-6bb7-4bbb-9dd5-1416d6a5f5b7",
    location: "Chicago, IL",
    refine_job_desc: "",
  },
  {
    company: "WillowTree",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/willowtree/jobs/6320082002",
    location: "Charlottesville, VA; Columbus, OH; Durham, NC",
    refine_job_desc: "",
  },
  {
    company: "TradeWeb",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ecnf.fa.us2.oraclecloud.com",
    link: "https://ecnf.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX/requisitions/preview/300493?src=JB-10200",
    location: "Jersey City, NJ",
    refine_job_desc: "",
  },
  {
    company: "TradeWeb",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ecnf.fa.us2.oraclecloud.com",
    link: "https://ecnf.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX/job/300492/?utm_medium=jobshare",
    location: "Jersey City, NJ",
    refine_job_desc: "",
  },
  {
    company: "TradeWeb",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ecnf.fa.us2.oraclecloud.com",
    link: "https://ecnf.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX/job/300493/?utm_medium=jobshare",
    location: "Jersey City, NJ",
    refine_job_desc: "",
  },
  {
    company: "TradeWeb",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ecnf.fa.us2.oraclecloud.com",
    link: "https://ecnf.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX/job/300490/?utm_medium=jobshare",
    location: "Jersey City, NJ",
    refine_job_desc: "",
  },
  {
    company: "Cruise",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/getcruise.com",
    link: "https://getcruise.com/careers/jobs/?search=intern",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Proofpoint",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/proofpoint.wd5.myworkdayjobs.com",
    link: "https://proofpoint.wd5.myworkdayjobs.com/en-US/ProofpointCareers/job/Draper-UT/Software-Engineer-Summer-Intern---Undergrad_R8448",
    location: "Draper, UT",
    refine_job_desc: "",
  },
  {
    company: "Dimensional Careers",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/dimensional.wd5.myworkdayjobs.com",
    link: "https://dimensional.wd5.myworkdayjobs.com/en-US/DFA_Campus/job/Austin/Internship-in-Technology---Software-Engineer--Undergraduate---Master-s-_2022-6883",
    location: "Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "Sierra Nevada Corporation",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/snc.wd1.myworkdayjobs.com",
    link: "https://snc.wd1.myworkdayjobs.com/en-US/SNC_External_Career_Site/job/Englewood-CO/Software-Engineer-Intern--Summer-2023-_R0018868",
    location: "Englewood, CO; or Huntsville, AL; or Hagerstown, MD",
    refine_job_desc: "",
  },
  {
    company: "United",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.united.com",
    link: "https://careers.united.com/us/en/job/WHQ00020911/Intern-Digital-Technology-Summer-2023",
    location: "Chicago, IL",
    refine_job_desc: "",
  },
  {
    company: " Booz Allen ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.boozallen.com",
    link: "https://careers.boozallen.com/jobs/JobDetail/Charleston-Software-Developer-Intern-R0145621/62161?source=JB-16500",
    location: "Charleston, South Carolina or Annapolis Junction, Maryland",
    refine_job_desc: "",
  },
  {
    company: " Booz Allen ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.boozallen.com",
    link: "https://careers.boozallen.com/jobs/JobDetail/Annapolis-Junction-University-Software-Engineer-Intern-R0151116/65593?source=JB-16500",
    location: "Charleston, South Carolina or Annapolis Junction, Maryland",
    refine_job_desc: "",
  },
  {
    company: "C3.ai",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/c3.ai",
    link: "https://c3.ai/job-description/?gh_jid=6289026002",
    location: "Redwood City, CA",
    refine_job_desc: "",
  },
  {
    company: "Roku",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.weareroku.com",
    link: "https://www.weareroku.com/jobs/search?page=1&query=&country_codes%5B%5D=US&string_field_1%5B%5D=Internships",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Roku",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.weareroku.com",
    link: "https://www.weareroku.com/jobs/software-engineer-intern-cloud-san-jose-california-united-states",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Roku",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.weareroku.com",
    link: "https://www.weareroku.com/jobs/software-engineer-intern-web-san-jose-california-united-states-cabd1cba-0fd6-4dfa-979b-6781a4615cea",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Roku",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.weareroku.com",
    link: "https://www.weareroku.com/jobs/software-engineer-intern-embedded-san-jose-california-united-states-80f80523-3868-45dd-a784-9177fc95447b",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Roku",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.weareroku.com",
    link: "https://www.weareroku.com/jobs/software-engineer-intern-machine-learning-san-jose-california-united-states",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Honeywell",
    companyDomain: "honeywell.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.honeywell.com",
    link: "https://careers.honeywell.com/us/en/job/req364416/Computer-Software-Engineer-Computer-Science-Summer-2023-Intern-Co-Op",
    location: "Multiple Locations in US",
    refine_job_desc: "",
  },
  {
    company: "Parsons Corporation",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/parsons.wd5.myworkdayjobs.com",
    link: "https://parsons.wd5.myworkdayjobs.com/en-US/Search/job/US---CO-Colorado-Springs/Software-Engineer-Intern---Space-Systems--Summer-2023-_R132568",
    location: "Colorado Springs, CO",
    refine_job_desc: "",
  },
  {
    company: "Fifth Third Bank",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/fifththird.wd5.myworkdayjobs.com",
    link: "https://fifththird.wd5.myworkdayjobs.com/en-US/53careers/job/Cincinnati-OH/Summer-2023-Data-Science---Engineering-Intern_R35671",
    location: "Cincinnati, OH",
    refine_job_desc: "",
  },
  {
    company: " Schonfeld ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/schonfeld/jobs/4510170",
    location: "New York, NY; or Miami, FL",
    refine_job_desc: "",
  },
  {
    company: " Schonfeld ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/schonfeld/jobs/4510454",
    location: "New York, NY; or Miami, FL",
    refine_job_desc: "",
  },
  {
    company: "UiPath",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-uipath.icims.com",
    link: "https://careers-uipath.icims.com/jobs/search?ss=1&searchRelation=keyword_all&searchPositionType=8710",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "UiPath",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-uipath.icims.com",
    link: "https://careers-uipath.icims.com/jobs/4797/summer-2023-intern%3a-software-engineer/job?in_iframe=1",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "UiPath",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-uipath.icims.com",
    link: "https://careers-uipath.icims.com/jobs/4794/summer-2023-intern%3a-software-engineer/job?in_iframe=1",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "UiPath",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-uipath.icims.com",
    link: "https://careers-uipath.icims.com/jobs/4787/summer-2023-intern%3a-software-engineer/job?in_iframe=1",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Qualcomm",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/qualcomm.wd5.myworkdayjobs.com",
    link: "https://qualcomm.wd5.myworkdayjobs.com/en-US/External/job/San-Diego-USA/Software-Engineering-Internship---Summer-2023_3042986",
    location:
      "San Diego, CA; or Boulder, CO; or Boxborough, MA; or Santa Clara, CA; or Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "84.51\u00b0",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/8451university",
    location: "Cincinnati, Chicago",
    refine_job_desc: "",
  },
  {
    company: "Appian",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/Appian/66c4ef95/",
    location: "McLean, VA",
    refine_job_desc: "",
  },
  {
    company: "Ginkgo Bioworks",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/Ginkgo_Bioworks/a3363831/",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Huntington Ingalls Industries",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.huntingtoningalls.com",
    link: "https://careers.huntingtoningalls.com/job/Newport-News-IT-INTERN-Virg/931374600/",
    location: "Norfolk, VA",
    refine_job_desc: "",
  },
  {
    company: "Huntington Ingalls Industries",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.huntingtoningalls.com",
    link: "https://careers.huntingtoningalls.com/job/Newport-News-ENGRG-INTERN-Virg/931373000/",
    location: "Norfolk, VA",
    refine_job_desc: "",
  },
  {
    company: "Nutanix",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/nutanix.eightfold.ai",
    link: "https://nutanix.eightfold.ai/careers?query=software%20intern&pid=13280687&domain=nutanix.com",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Paylocity",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.paylocity.com",
    link: "https://www.paylocity.com/careers/career-opportunities/early-career/?jobId=14173/",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Paylocity",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.paylocity.com",
    link: "https://www.paylocity.com/careers/career-opportunities/early-career/?jobId=14171",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Snowflake",
    companyDomain: "snowflake.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.snowflake.com",
    link: "https://careers.snowflake.com/us/en/job/6397516002?gh_jid=6397516002",
    location: "San Mateo, CA",
    refine_job_desc: "",
  },
  {
    company: "Trimble",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/trimblecareers.trimble.com",
    link: "https://trimblecareers.trimble.com/careers/job?domain=trimble.com&query=Software%20Engineering%20Intern&pid=171812012551",
    location: "Westminster, CO, Portland, OR, Knoxville, TN, Sunrise, FL",
    refine_job_desc: "",
  },
  {
    company: "Unity",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/Unity/3d2aa3d4/",
    location:
      "San Francisco, CA, Austin, TX, Bellevue, WA, Framingham, MA, Pittsburgh, PA",
    refine_job_desc: "",
  },
  {
    company: "Unity",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/Unity/3d2aa3d4/",
    location:
      "San Francisco, CA, Austin, TX, Bellevue, WA, Framingham, MA, Pittsburgh, PA",
    refine_job_desc: "",
  },
  {
    company: "Zoox",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/zoox",
    location: "Foster City, CA",
    refine_job_desc: "",
  },
  {
    company: "Zoox",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/zoox/73ba40c9-48bd-4f34-9eb5-b56d41a74a7d",
    location: "Foster City, CA",
    refine_job_desc: "",
  },
  {
    company: "Zoox",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/zoox/c84006b4-0e77-4362-8cfe-5f8b82b8b347",
    location: "Foster City, CA",
    refine_job_desc: "",
  },
  {
    company: "Autodesk",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/autodesk.wd1.myworkdayjobs.com",
    link: "https://autodesk.wd1.myworkdayjobs.com/Ext/job/California-USA---Remote/Intern--Software-Engineer_22WD64165-1?src=JB-10065",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "IDEMIA",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/uscareers-idemia.icims.com",
    link: "https://uscareers-idemia.icims.com/jobs/5382/software-development-internship/job",
    location: "Anaheim, CA",
    refine_job_desc: "",
  },
  {
    company: "TripActions",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/tripactions.com",
    link: "https://tripactions.com/job-openings/4488671?gh_jid=4488671&gh_src=b13de4e21",
    location: "Palo Alto, CA",
    refine_job_desc: "",
  },
  {
    company: "Reliable Robotics",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/reliable/90367300-93c0-4b0f-8893-13ebd4af326d",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "Ancestry",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.ancestry.com",
    link: "https://careers.ancestry.com/jobs/search/internships-search-page",
    location: "Lehi, UT",
    refine_job_desc: "",
  },
  {
    company: "Ancestry",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.ancestry.com",
    link: "https://careers.ancestry.com/jobs/android-engineer-summer-intern-hybrid-remote-office-based-lehi-utah-united-states",
    location: "Lehi, UT",
    refine_job_desc: "",
  },
  {
    company: "Ancestry",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.ancestry.com",
    link: "https://careers.ancestry.com/jobs/ios-engineer-summer-intern-hybrid-remote-office-based-lehi-utah-united-states",
    location: "Lehi, UT",
    refine_job_desc: "",
  },
  {
    company: "Ancestry",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.ancestry.com",
    link: "https://careers.ancestry.com/jobs/data-science-cv-nlp-summer-intern-hybrid-remote-office-based-lehi-utah-united-states",
    location: "Lehi, UT",
    refine_job_desc: "",
  },
  {
    company: "Raytheon",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.rtx.com",
    link: "https://careers.rtx.com/global/en/job/RAYTGLOBAL01567022EXTERNALENGLOBAL/UP-2023-Software-Engineer-Internships",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Textron",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/textron.taleo.net",
    link: "https://textron.taleo.net/careersection/textron/jobdetail.ftl?job=1115787",
    location: "Huntsville, AL",
    refine_job_desc: "",
  },
  {
    company: "Whatnot",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/whatnot/jobs/4604514004",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Advanced Micro Devices(AMD)",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.amd.com",
    link: "https://jobs.amd.com/job/Austin-Summer-2023-Advanced-Technology-Validation-Co-OpIntern-Texa/937443400/",
    location: "Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "Raven Industries",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.ravenind.com",
    link: "https://jobs.ravenind.com/job/Sioux-Falls-Software-Engineer-Intern-%28Summer%29-SD-57104-5931/934151400",
    location: "Sioux Falls, SD",
    refine_job_desc: "",
  },
  {
    company: "Jacobs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.jacobs.com",
    link: "https://careers.jacobs.com/job-search-results/?job_type[]=Internship",
    location: "Hudson, NH",
    refine_job_desc: "",
  },
  {
    company: "Jacobs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.jacobs.com",
    link: "https://careers.jacobs.com/job/15934465/software-intern-co-op-hudson-nh/",
    location: "Hudson, NH",
    refine_job_desc: "",
  },
  {
    company: "Netflix",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.netflix.com",
    link: "https://jobs.netflix.com/search?team=Internship",
    location: "Los Gatos, CA",
    refine_job_desc: "",
  },
  {
    company: "Netflix",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.netflix.com",
    link: "https://jobs.netflix.com/jobs/234866639",
    location: "Los Gatos, CA",
    refine_job_desc: "",
  },
  {
    company: "Netflix",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.netflix.com",
    link: "https://jobs.netflix.com/jobs/232791459",
    location: "Los Gatos, CA",
    refine_job_desc: "",
  },
  {
    company: "Netflix",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.netflix.com",
    link: "https://jobs.netflix.com/jobs/234882269",
    location: "Los Gatos, CA",
    refine_job_desc: "",
  },
  {
    company: "Netflix",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.netflix.com",
    link: "https://jobs.netflix.com/jobs/237559138",
    location: "Los Gatos, CA",
    refine_job_desc: "",
  },
  {
    company: "Alteryx",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/alteryx/8c645069/?from_page=company_branded_page&",
    location: "Irvine, CA; or Remote",
    refine_job_desc: "",
  },
  {
    company: "Awardco",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.award.co",
    link: "https://www.award.co/careers",
    location: "Pleasant Grove, Utah",
    refine_job_desc: "",
  },
  {
    company: "Awardco",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/award.co",
    link: "https://award.co/position?gh_jid=4323618004",
    location: "Pleasant Grove, Utah",
    refine_job_desc: "",
  },
  {
    company: "ZipRecruiter",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.ziprecruiter.com",
    link: "https://www.ziprecruiter.com/careers",
    location: "Santa Monica, CA",
    refine_job_desc: "",
  },
  {
    company: "ZipRecruiter",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.ziprecruiter.com",
    link: "https://www.ziprecruiter.com/c/ZipRecruiter/Job/Data-Scientist-Machine-Learning-Engineer-Intern/-in-Santa-Monica,CA?jid=734d6c38e2b352bc",
    location: "Santa Monica, CA",
    refine_job_desc: "",
  },
  {
    company: "ZipRecruiter",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.ziprecruiter.com",
    link: "https://www.ziprecruiter.com/c/ZipRecruiter/Job/Software-Engineer-Intern/-in-Santa-Monica,CA?jid=f331da90344f3100",
    location: "Santa Monica, CA",
    refine_job_desc: "",
  },
  {
    company: "CarMax",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.carmax.com",
    link: "https://careers.carmax.com/us/en/job/JR-102006/Technology-Intern-Richmond-VA-Dallas-TX",
    location: "Richmond, VA, Dallas, TX",
    refine_job_desc: "",
  },
  {
    company: "McKesson",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/mckesson.wd3.myworkdayjobs.com",
    link: "https://mckesson.wd3.myworkdayjobs.com/en-US/Acquisition_campus/details/Software-Engineer-Intern_JR0073489",
    location: "Atlanta, GA",
    refine_job_desc: "",
  },
  {
    company: "Walmart",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.walmart.com",
    link: "https://careers.walmart.com/us/jobs/WD1363964-2023-summer-intern-software-engineer-ii",
    location: "Bentonville, AR",
    refine_job_desc: "",
  },
  {
    company: "Walmart",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.walmart.com",
    link: "https://careers.walmart.com/us/jobs/WD1363964-2023-summer-intern-software-engineer-ii",
    location: "Bentonville, AR",
    refine_job_desc: "",
  },
  {
    company: " Skydio ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/skydio/jobs/4713863003",
    location: "San Mateo, CA",
    refine_job_desc: "",
  },
  {
    company: " Skydio ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/skydio/jobs/4734527003",
    location: "San Mateo, CA",
    refine_job_desc: "",
  },
  {
    company: " Skydio ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/skydio/jobs/4734539003",
    location: "San Mateo, CA",
    refine_job_desc: "",
  },
  {
    company: "Cadence",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/cadence.wd1.myworkdayjobs.com",
    link: "https://cadence.wd1.myworkdayjobs.com/Univ_Careers/2/refreshFacet/318c8bb6f553100021d223d9780d30be",
    location: "Varies",
    refine_job_desc: "",
  },
  {
    company: "Arista",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.arista.com",
    link: "https://www.arista.com/en/careers/university/engineering-internship",
    location: "Multiple Locations in US",
    refine_job_desc: "",
  },
  {
    company: "Restaurant Brands International",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.rbi.com",
    link: "https://careers.rbi.com/global/en/job/6262468002/2023-Technical-Development-Program-Summer-Internship-TDP-Software-Engineering-Toronto",
    location: "Toronto",
    refine_job_desc: "",
  },
  {
    company: "Electronic Arts (EA)",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ea.gr8people.com",
    link: "https://ea.gr8people.com/jobs/175733/software-engineer-intern-summer-2023",
    location: "Multiple Locations in US",
    refine_job_desc: "",
  },
  {
    company: " Northwestern Mutual ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.northwesternmutual.com",
    link: "https://careers.northwesternmutual.com/corporate-careers/job/software-engineering-internship-summer-2023-milwaukee-wi-corporate-jr-35891",
    location: "Milwaukee, WI; or Franklin, WI; or New York City, NY",
    refine_job_desc: "",
  },
  {
    company: " Northwestern Mutual ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.northwesternmutual.com",
    link: "https://careers.northwesternmutual.com/corporate-careers/job/data-science-analytics-internship-summer-2023-milwaukee-wi-corporate-jr-35887/",
    location: "Milwaukee, WI; or Franklin, WI; or New York City, NY",
    refine_job_desc: "",
  },
  {
    company: "Excelitas",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.excelitas.com",
    link: "https://www.excelitas.com/job/2022-10/software-engineering-intern",
    location: "Billerica, MA",
    refine_job_desc: "",
  },
  {
    company: "Climate",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/careers/the-climate-corporation/job/otfWkfwI?__jvst=Job%20Board&__jvsd=LinkedIn",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "klue",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/klue/b88e45c3-c067-410b-a7d3-ca23142b9bef",
    location: "Vancouver, BC",
    refine_job_desc: "",
  },
  {
    company: "LINK",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-linkindustrialfields.icims.com",
    link: "https://careers-linkindustrialfields.icims.com/jobs/search?ss=1&searchPositionType=18701",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "LINK",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-linkindustrialfields.icims.com",
    link: "https://careers-linkindustrialfields.icims.com/jobs/4450/data-analyst-intern-2023/job",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "LINK",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-linkindustrialfields.icims.com",
    link: "https://careers-linkindustrialfields.icims.com/jobs/4459/computer-engineering-summer-intern-2023/job",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "LINK",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-linkindustrialfields.icims.com",
    link: "https://careers-linkindustrialfields.icims.com/jobs/4602/data-science-intern%2c-research-%26-analytics-2023/job",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Lennox International",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/uscareers-lennox.icims.com",
    link: "https://uscareers-lennox.icims.com/jobs/34333/software-engineer-intern---summer-2023/job",
    location: "Carrollton, TX",
    refine_job_desc: "",
  },
  {
    company: " Delta ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/delta.avature.net",
    link: "https://delta.avature.net/careers/JobDetail/Intern-Software-Engineering-Summer-2023/17376",
    location: "Atlanta, GA, Minneapolis St. Paul, MN",
    refine_job_desc: "",
  },
  {
    company: " Delta ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/delta.avature.net",
    link: "https://delta.avature.net/careers/JobDetail/Intern-IT-Operations-Research-Data-Science-Summer-2023/17381",
    location: "Atlanta, GA, Minneapolis St. Paul, MN",
    refine_job_desc: "",
  },
  {
    company: " Delta ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/delta.avature.net",
    link: "https://delta.avature.net/careers/JobDetail/Intern-Revenue-Technology-Data-Analytics-Summer-2023/17650",
    location: "Atlanta, GA, Minneapolis St. Paul, MN",
    refine_job_desc: "",
  },
  {
    company: "Wisk",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/wisk/4d2f1a07-8f6e-4243-ad2d-ebea17e390a9",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: " DISCO ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.csdisco.com",
    link: "https://www.csdisco.com/careers/software-engineer-intern-c",
    location: "New York City, NY; or Austin, TX",
    refine_job_desc: "",
  },
  {
    company: " DISCO ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.csdisco.com",
    link: "https://www.csdisco.com/careers/software-engineer-intern-6",
    location: "New York City, NY; or Austin, TX",
    refine_job_desc: "",
  },
  {
    company: "Snackpass",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/snackpasspt/jobs/4658696004",
    location: "San Francisco, CA / Remote",
    refine_job_desc: "",
  },
  {
    company: "Tesla",
    companyDomain: "tesla.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.tesla.com",
    link: "https://www.tesla.com/careers/search/job/software-engineering-internship-summer-2023-153316",
    location: "Palo Alto, California",
    refine_job_desc: "",
  },
  {
    company: " Spectrum ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.spectrum.com",
    link: "https://jobs.spectrum.com/job/greenwood-village/2023-summer-intern-software-engineer/4673/37073255520",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: " Spectrum ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.spectrum.com",
    link: "https://jobs.spectrum.com/job/charlotte/2023-summer-intern-information-technology/4673/36113961104",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "OpenText",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.opentext.com",
    link: "https://careers.opentext.com/job/Richmond-Hill-Internship-Localization-QA-Specialist-ON/563481917/?feedId=319217&utm_source=LinkedInJobPostings&source=LinkedIn?utm_source=LinkedInJobPostings&utm_campaign=Linked",
    location: "Ontario, Canada",
    refine_job_desc: "",
  },
  {
    company: "Omnicell",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/phg.tbe.taleo.net",
    link: "https://phg.tbe.taleo.net/phg03/ats/careers/v2/viewRequisition?org=OMNICELL&cws=40&rid=16762&source=LinkedIn&src=LinkedIn&gns=LinkedIn",
    location: "Cranberry Township, PA",
    refine_job_desc: "",
  },
  {
    company: "Patreon",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/patreon/jobs/4606412",
    location: "New York City, NY or San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Credit Karma",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/creditkarma/jobs/4431522003",
    location: "Charlotte, NC",
    refine_job_desc: "",
  },
  {
    company: " Robinhood ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/robinhood/e93c5366?from_page=company_branded_page&",
    location: "Remote, USA",
    refine_job_desc: "",
  },
  {
    company: " Robinhood ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/robinhood/55eaa246?from_page=company_branded_page&",
    location: "Remote, USA",
    refine_job_desc: "",
  },
  {
    company: "Bain & Company",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.bain.com",
    link: "https://careers.bain.com/jobs/FolderDetail?folderId=67698",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: " Ambi Robotics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/ambirobotics/849d7ae7-67b8-471c-ae1a-ef9beedff270",
    location: "Berkeley, CA",
    refine_job_desc: "",
  },
  {
    company: " Ambi Robotics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/ambirobotics/948c66a2-2886-49bf-a9fa-2d1fad7ad678",
    location: "Berkeley, CA",
    refine_job_desc: "",
  },
  {
    company: " Ambi Robotics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/ambirobotics/2480fb89-7575-46cb-80d9-3c7a4faaf8f6",
    location: "Berkeley, CA",
    refine_job_desc: "",
  },
  {
    company: " Ambi Robotics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/ambirobotics/0051f9b6-c9c4-48fc-a50d-de303e696fe2",
    location: "Berkeley, CA",
    refine_job_desc: "",
  },
  {
    company: " Ambi Robotics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/ambirobotics/cfebc000-96e7-4c85-8b9a-47a9b23329c9",
    location: "Berkeley, CA",
    refine_job_desc: "",
  },
  {
    company: " Ambi Robotics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/ambirobotics/7e2fb44b-b935-4eec-9f7e-cad8acdd1f1d",
    location: "Berkeley, CA",
    refine_job_desc: "",
  },
  {
    company: "Tandem Diabetes Care",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/recruiting2.ultipro.com",
    link: "https://recruiting2.ultipro.com/TAN1005TDCI/JobBoard/7c56fc7c-1edd-48ec-939c-10225b1f3df1/OpportunityDetail?opportunityId=ce8b18a0-62f9-45ef-93ef-1dffeaebe12b",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: " Aurora ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/aurora.tech",
    link: "https://aurora.tech/jobs/software-engineering-intern-back-end-c-6428595002",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: " Aurora ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/aurora.tech",
    link: "https://aurora.tech/jobs/software-engineering-intern-back-end-python-6427680002",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "Cargill",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.cargill.com",
    link: "https://careers.cargill.com/job/-/-/23251/34538432160",
    location: "Hopkins, MN; or Paul-Bloomington, MN",
    refine_job_desc: "",
  },
  {
    company: "Entergy",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.entergy.com",
    link: "https://jobs.entergy.com/job/New-Orleans-Student%2C-Intern-Software-Engineer-%28Summer-2023%29-Loui/945880900/",
    location: "New Orleans, LA",
    refine_job_desc: "",
  },
  {
    company: "Prometric",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/us63.dayforcehcm.com",
    link: "https://us63.dayforcehcm.com/CandidatePortal/en-US/prometric/Posting/View/34348",
    location: "Baltimore, MD",
    refine_job_desc: "",
  },
  {
    company: "Wayfair",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.wayfair.com",
    link: "https://www.wayfair.com/careers/jobs?countryIds=1&selectedJobTypeIds=8%2C5&gh_src=",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Wayfair",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.wayfair.com",
    link: "https://www.wayfair.com/careers/job/software-engineering-internship---summer-----/6359307002?gh_src=",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Wayfair",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.wayfair.com",
    link: "https://www.wayfair.com/careers/job/data-science---machine-learning-intern---summer-----/6373711002?gh_src=",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Moderna",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/modernatx.eightfold.ai",
    link: "https://modernatx.eightfold.ai/careers/?query=Software%20Engineer%20Intern&seniority=Intern",
    location: "Multiple Locations (MA)",
    refine_job_desc: "",
  },
  {
    company: "Veritas",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/veritas.wd1.myworkdayjobs.com",
    link: "https://veritas.wd1.myworkdayjobs.com/careers/job/Roseville-MN/Software-Engineer-Intern--Summer-2023_60440",
    location: "Roseville, MN; or Remote",
    refine_job_desc: "",
  },
  {
    company: "BlueHalo",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-bluehalo.icims.com",
    link: "https://careers-bluehalo.icims.com/jobs/3603/software-engineering-intern/job",
    location: "Albuquerque, NM",
    refine_job_desc: "",
  },
  {
    company: "Elevance Health",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/elevancehealth.wd1.myworkdayjobs.com",
    link: "https://elevancehealth.wd1.myworkdayjobs.com/en-US/ANT/job/Engineering-Intern-Undergraduate-Level---Summer-2023_JR27264",
    location: "Norfolk, VA",
    refine_job_desc: "",
  },
  {
    company: "Certik",
    companyDomain: "certik.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/certik/d4bdf6fa-f885-476a-9b4f-538b874e2d36",
    location: "Multiple Locations, USA",
    refine_job_desc: "",
  },
  {
    company: "Comcast",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.comcast.com",
    link: "https://jobs.comcast.com/jobs/description/regular?external_or_internal=External&job_id=R352221",
    location: "Philadelphia, PA",
    refine_job_desc: "",
  },
  {
    company: "Nordson",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/nordsonhcm.wd5.myworkdayjobs.com",
    link: "https://nordsonhcm.wd5.myworkdayjobs.com/nordsoncareers/job/USA---Rhode-Island---East-Providence/Software-Engineering-Intern_REQ31856",
    location: "Rhode Island, USA",
    refine_job_desc: "",
  },
  {
    company: "General Dynamics Mission Systems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-gdms.icims.com",
    link: "https://careers-gdms.icims.com/jobs/57649/job",
    location: "Dedham, MA",
    refine_job_desc: "",
  },
  {
    company: "Verizon",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.verizon.com",
    link: "https://jobs.verizon.com/jobs/10895964-software-development-summer-2023-internship",
    location: "Irving, TX, United States",
    refine_job_desc: "",
  },
  {
    company: "Bose",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boseallaboutme.wd1.myworkdayjobs.com",
    link: "https://boseallaboutme.wd1.myworkdayjobs.com/en-US/Bose_Careers/job/United-States-Work-from-Home/Data-Science-Intern_R23849",
    location: "Remote, United States",
    refine_job_desc: "",
  },
  {
    company: "Alcon",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/alcon.wd5.myworkdayjobs.com",
    link: "https://alcon.wd5.myworkdayjobs.com/careers_alcon/job/Lake-Forest-California/Summer-2023-Intern---Software-Engineer_R-2022-19194",
    location: "Lake Forest, CA",
    refine_job_desc: "",
  },
  {
    company: "23andMe",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/23andme.com",
    link: "https://23andme.com/careers/jobs/6426717002/",
    location: "Remote, US",
    refine_job_desc: "",
  },
  {
    company: "Brain Technologies",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/braintechnologies/jobs/4677960004?gh_src=0c72714b4us",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Coda",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/coda/jobs/5096307002",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Bandwidth",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/bandwidth/jobs/4618588",
    location: "Rochester, NY",
    refine_job_desc: "",
  },
  {
    company: "UKG",
    companyDomain: "ukg.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.ukg.com",
    link: "https://careers.ukg.com/careers/JobDetail/Software-Engineering-Internship-Spring-Summer-2023/41891",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "MediaTek",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.mediatek.com",
    link: "https://careers.mediatek.com/eREC/JobSearch/JobDetail/MUS120210917004?langKey=en-US",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Red Hat",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/us-redhat.icims.com",
    link: "https://us-redhat.icims.com/jobs/97296/software-engineering-internship/job?hub=7",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Lexmark",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.lexmark.com",
    link: "https://www.lexmark.com/en_us/careers/job-description.143572.html",
    location: "Boulder, CO",
    refine_job_desc: "",
  },
  {
    company: "Levi, Ray & Shoup",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lrs.com",
    link: "https://jobs.lrs.com/job/details/25154",
    location: "Hartford, CT",
    refine_job_desc: "",
  },
  {
    company: "Tu Simple",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/tusimpleinternship",
    location: "San Diego, California, Remote",
    refine_job_desc: "",
  },
  {
    company: "Yahoo",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ouryahoo.wd5.myworkdayjobs.com",
    link: "https://ouryahoo.wd5.myworkdayjobs.com/en-US/careers?q=software&locations=429c19fc6ff810015940b81d49a00000&jobFamilyGroup=91f14896cbbe0142bf25083fc74637b2",
    location: "Remote (US)",
    refine_job_desc: "",
  },
  {
    company: "Ampere Computing",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/amperecomputing.com",
    link: "https://amperecomputing.com/apply/?p=job%2FoZvJkfwh",
    location: "Santa Clara, CA",
    refine_job_desc: "",
  },
  {
    company: "Leidos",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.leidos.com",
    link: "https://careers.leidos.com/jobs/10999482-software-engineer-intern",
    location: "Charlottesville, VA",
    refine_job_desc: "",
  },
  {
    company: "NimbleRx",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/nimblerx/b00faff0-37f7-4707-ac14-b86db266b9f9",
    location: "Remote, US",
    refine_job_desc: "",
  },
  {
    company: "WW",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ww.wd1.myworkdayjobs.com",
    link: "https://ww.wd1.myworkdayjobs.com/careers/job/New-York/XMLNAME-2023-Software-Engineering---Marketing-Internship_R220000002956",
    location: "Remote, US; New York, US",
    refine_job_desc: "",
  },
  {
    company: "Simplify",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/simplify.jobs",
    link: "https://simplify.jobs/p/75bc6274-c19d-441b-8cf8-6f06e0799ad4/Software-Engineer-Intern",
    location: "SF, Remote, US",
    refine_job_desc: "",
  },
  {
    company: "PlayStation",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.playstation.com",
    link: "https://www.playstation.com/en-us/corporate/playstation-careers/",
    location: "Multiple",
    refine_job_desc: "",
  },
  {
    company: "Reddit",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/reddit/1da01017/?from_page=company_branded_page",
    location: "SF, NY, Remote",
    refine_job_desc: "",
  },
  {
    company: "Johns Hopkins APL",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.jhuapl.edu",
    link: "https://careers.jhuapl.edu/jobs?keywords=software%20intern&sortBy=relevance&page=1",
    location: "Laurel, MD; Remote, US",
    refine_job_desc: "",
  },
  {
    company: "Nelnet",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/nelnet.wd1.myworkdayjobs.com",
    link: "https://nelnet.wd1.myworkdayjobs.com/en-US/MyNelnet/job/Remote/Intern---Software-Engineer---Java_R15687",
    location: "Remote, US",
    refine_job_desc: "",
  },
  {
    company: "McKinsey & Company",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.mckinsey.com",
    link: "https://www.mckinsey.com/careers/search-jobs?query=software%20engineer%20intern",
    location: "New York City, NY; or San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "McKinsey & Company",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.mckinsey.com",
    link: "https://www.mckinsey.com/careers/search-jobs/jobs/dataengineeringintern-quantumblack-universitystudents-58600",
    location: "New York City, NY; or San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "McKinsey & Company",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.mckinsey.com",
    link: "https://www.mckinsey.com/careers/search-jobs/jobs/softwareengineeringintern-gcianalytics-59688",
    location: "New York City, NY; or San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Color",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/color/93e1b4d8-7822-41bc-9d83-f8333cd5230f",
    location: "Remote, USA",
    refine_job_desc: "",
  },
  {
    company: "Tealium",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/tealium/01b097dc-a904-448d-8cfc-91a372e2156c",
    location: "Remote, USA",
    refine_job_desc: "",
  },
  {
    company: "Samsung",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/samsungresearchamericainternship/",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "Samsung",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/samsungresearchamericainternship/jobs/5922339002",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "Samsung",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/samsungresearchamericainternship/jobs/5868447002",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "Shield AI",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/shieldai",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "BMW Group",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.bmwgroup.jobs",
    link: "https://www.bmwgroup.jobs/us/en/opportunities/student.html",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "BP",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.bp.com",
    link: "https://www.bp.com/en/global/corporate/careers/jobs-at-bp/Data-Engineering-Intern-139997BR.html",
    location: "Houston, TX",
    refine_job_desc: "",
  },
  {
    company: "Schlumberger",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.slb.com",
    link: "https://careers.slb.com/fojoblist/it-intern",
    location: "Houston, TX or Menlo Park, CA",
    refine_job_desc: "",
  },
  {
    company: "BAE Systems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.baesystems.com",
    link: "https://jobs.baesystems.com/global/en/leap",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "BAE Systems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.baesystems.com",
    link: "https://jobs.baesystems.com/global/en/job/86199BR/Software-Engineering-Intern",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "BAE Systems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.baesystems.com",
    link: "https://jobs.baesystems.com/global/en/job/86198BR/Systems-Engineering-Intern",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "BAE Systems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.baesystems.com",
    link: "https://jobs.baesystems.com/global/en/job/86078BR/Virtual-Reality-Engineering-Intern-Summer-2023",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "FactSet",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/factset.wd1.myworkdayjobs.com",
    link: "https://factset.wd1.myworkdayjobs.com/en-US/FactSetCareers/job/Software-Engineer-Intern---Americas-Campus--Summer-2023-_R18012",
    location: "Multiple Location",
    refine_job_desc: "",
  },
  {
    company: "PTC",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ptc.eightfold.ai",
    link: "https://ptc.eightfold.ai/careers?query=Software%20Intern&pid=137453256339&domain=ptc.com&triggerGoButton=false",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "SeekOut",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/seekout.com",
    link: "https://seekout.com/careers-list?gh_jid=5371260003",
    location: "Bellevue, WA",
    refine_job_desc: "",
  },
  {
    company: "Nuna Healthcare",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/nuna/jobs/4612944",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Vertica",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.microfocus.com",
    link: "https://jobs.microfocus.com/global/en/search-results?keywords=intern",
    location: "Provo, UT or Cambridge, MA",
    refine_job_desc: "",
  },
  {
    company: "Vertica",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.microfocus.com",
    link: "https://jobs.microfocus.com/global/en/job/7027957/Systems-Software-Engineer-Intern-at-Vertica",
    location: "Provo, UT or Cambridge, MA",
    refine_job_desc: "",
  },
  {
    company: "Vertica",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.microfocus.com",
    link: "https://jobs.microfocus.com/global/en/job/7027820/Quality-Assurance-Test-Intern-for-Summer-2023-Vertica",
    location: "Provo, UT or Cambridge, MA",
    refine_job_desc: "",
  },
  {
    company: "Mark43",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/mark43.com",
    link: "https://mark43.com/careers/north-america/#job-openings",
    location: "Toronto or US remote",
    refine_job_desc: "",
  },
  {
    company: "Mark43",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/mark43.com",
    link: "https://mark43.com/list-job/?gh_jid=4653495",
    location: "Toronto or US remote",
    refine_job_desc: "",
  },
  {
    company: "Mark43",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/mark43.com",
    link: "https://mark43.com/list-job/?gh_jid=4657411",
    location: "Toronto or US remote",
    refine_job_desc: "",
  },
  {
    company: "Fanatics",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/fanatics",
    location: "Boulder, San Mateo, Tampa, Jacksonville",
    refine_job_desc: "",
  },
  {
    company: "Fanatics",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/fanatics/573afdf6-8a23-48a0-8682-b721277f1410",
    location: "Boulder, San Mateo, Tampa, Jacksonville",
    refine_job_desc: "",
  },
  {
    company: "Fanatics",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/fanatics/902a3c59-d4ad-46c7-b6ba-277b6ab1bada",
    location: "Boulder, San Mateo, Tampa, Jacksonville",
    refine_job_desc: "",
  },
  {
    company: "Fanatics",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/fanatics/2098bd04-743c-4715-b95a-02bed755c69d",
    location: "Boulder, San Mateo, Tampa, Jacksonville",
    refine_job_desc: "",
  },
  {
    company: "Fanatics",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/fanatics/94ecad65-b4fc-48b8-ba1b-b3d7c2f8a9c3",
    location: "Boulder, San Mateo, Tampa, Jacksonville",
    refine_job_desc: "",
  },
  {
    company: "Chime",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.chime.com",
    link: "https://careers.chime.com/c/engineering-jobs",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Chime",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.chime.com",
    link: "https://careers.chime.com/job/6451721002/Engineering-Services-Intern",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Chime",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.chime.com",
    link: "https://careers.chime.com/job/6450390002/Risk-Support-Engineering-Intern",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Chime",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.chime.com",
    link: "https://careers.chime.com/job/6451725002/Engineering-Product-Management-Intern",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Lucid Motors",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/lucidmotors/",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "SiriusXM",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.siriusxm.com",
    link: "https://careers.siriusxm.com/earlycareer/jobs?keywords=intern&sortBy=relevance&page=1",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "SiriusXM",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.siriusxm.com",
    link: "https://careers.siriusxm.com/earlycareer/jobs/13805?lang=en-us",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "SiriusXM",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.siriusxm.com",
    link: "https://careers.siriusxm.com/earlycareer/jobs/13807?lang=en-us",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "SiriusXM",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.siriusxm.com",
    link: "https://careers.siriusxm.com/earlycareer/jobs/13801?lang=en-us",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "Gilead Sciences",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/gilead.wd1.myworkdayjobs.com",
    link: "https://gilead.wd1.myworkdayjobs.com/en-US/gileadcareers/job/Intern---Software-Development_R0031490",
    location: "Foster City, CA",
    refine_job_desc: "",
  },
  {
    company: "Productiv",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/productiv/jobs/5377780003",
    location: "Palo Alto, CA or Bellevue, WA",
    refine_job_desc: "",
  },
  {
    company: "Juniper Networks",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.juniper.net",
    link: "https://careers.juniper.net/",
    location: "Westford, MA or Sunnyvale, CA",
    refine_job_desc: "",
  },
  {
    company: "Juniper Networks",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.juniper.net",
    link: "https://careers.juniper.net/#/jobdescription?jid=1007266",
    location: "Westford, MA or Sunnyvale, CA",
    refine_job_desc: "",
  },
  {
    company: "Micron Technology",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/micron.eightfold.ai",
    link: "https://micron.eightfold.ai/careers/search?query=Internship",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "Michaels",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/michaels.wd5.myworkdayjobs.com",
    link: "https://michaels.wd5.myworkdayjobs.com/en-US/External/job/Michaels-2023-Summer-Internship---Technology_R00192212",
    location: "Irving, TX",
    refine_job_desc: "",
  },
  {
    company: "XCOM Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/xcomlabs.bamboohr.com",
    link: "https://xcomlabs.bamboohr.com/jobs/",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: "XCOM Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/xcomlabs.bamboohr.com",
    link: "https://xcomlabs.bamboohr.com/jobs/view.php?id=298&source=bamboohr",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: "XCOM Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/xcomlabs.bamboohr.com",
    link: "https://xcomlabs.bamboohr.com/jobs/view.php?id=300&source=bamboohr",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: "XCOM Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/xcomlabs.bamboohr.com",
    link: "https://xcomlabs.bamboohr.com/jobs/view.php?id=302&source=bamboohr",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: "XCOM Labs",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/xcomlabs.bamboohr.com",
    link: "https://xcomlabs.bamboohr.com/jobs/view.php?id=301&source=bamboohr",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: "Moody's",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.moodys.com",
    link: "https://careers.moodys.com/job/16818557/2023-engineering-technology-summer-internship-us-charlotte-nc",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Pimco",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/pimco.wd1.myworkdayjobs.com",
    link: "https://pimco.wd1.myworkdayjobs.com/en-US/pimco-careers/job/Austin-TX-USA/XMLNAME-2023-SIP-Technology-Analyst--Software-Engineering_R101897?ref=levels.fyi&workerSubType=6e192a3f175201c5a610087a5209cf10",
    location: "Austin, TX or Newport Beach, CA",
    refine_job_desc: "",
  },
  {
    company: "Boeing",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.boeing.com",
    link: "https://jobs.boeing.com/job/hood-river/intern-software-engineering/185/37781184528",
    location: "Hood River, Oregon",
    refine_job_desc: "",
  },
  {
    company: "Nokia",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/aluperf.referrals.selectminds.com",
    link: "https://aluperf.referrals.selectminds.com/jobs/search/36227665",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "Braze",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.braze.com",
    link: "https://www.braze.com/",
    location: "New York",
    refine_job_desc: "",
  },
  {
    company: "Integral Ad Science",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ats.comparably.com",
    link: "https://ats.comparably.com/api/v1/gh/integraladscience/jobs/4637063?gh_jid=4637063&gh_src=6e382d8d1us",
    location: "Chicago IL or New York, NY",
    refine_job_desc: "",
  },
  {
    company: " Atlassian ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/atlassian/8598139f-e53c-4353-ad2a-3f9d32e969c2",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: " Atlassian ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.atlassian.com",
    link: "https://www.atlassian.com/company/careers/detail/db725d3d-e4c1-4dea-953c-ee1681cde1dd",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "Atomic",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/atomic/92ea05f7-450e-452d-92ab-291bad609004",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: " New Relic ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/newrelic.careers",
    link: "https://newrelic.careers/careers/JobDetail/Software-Engineer-Intern-CodeStream/3053",
    location: "Portland, OR",
    refine_job_desc: "",
  },
  {
    company: " New Relic ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/newrelic.careers",
    link: "https://newrelic.careers/careers/JobDetail/Software-Engineer-Intern-Telemetry-Data-Platform/3048",
    location: "Portland, OR",
    refine_job_desc: "",
  },
  {
    company: "EnergySage Inc.",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/energysage.hrmdirect.com",
    link: "https://energysage.hrmdirect.com/employment/job-opening.php?req=2211289&&&jbsrc=1023",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "Zanbato",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/zanbato/jobs/4485669",
    location: "Mountain View, CA",
    refine_job_desc: "",
  },
  {
    company: "SandboxAQ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.sandboxaq.com",
    link: "https://www.sandboxaq.com/careers-list?gh_jid=4601309004",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "PepsiCo",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.pepsicojobs.com",
    link: "https://www.pepsicojobs.com/main/jobs/143600?lang=en-us&previousLocale=en-US",
    location: "Purchase, NY or Plano, TX",
    refine_job_desc: "",
  },
  {
    company: " Enable Medicine ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/enablemedicine.pinpointhq.com",
    link: "https://enablemedicine.pinpointhq.com/en/jobs/75390",
    location: "San Francisco, CA or New York City, NY",
    refine_job_desc: "",
  },
  {
    company: " Enable Medicine ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/enablemedicine.pinpointhq.com",
    link: "https://enablemedicine.pinpointhq.com/en/jobs/75389",
    location: "San Francisco, CA or New York City, NY",
    refine_job_desc: "",
  },
  {
    company: " Enable Medicine ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/enablemedicine.pinpointhq.com",
    link: "https://enablemedicine.pinpointhq.com/en/jobs/75391",
    location: "San Francisco, CA or New York City, NY",
    refine_job_desc: "",
  },
  {
    company: " Enable Medicine ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/enablemedicine.pinpointhq.com",
    link: "https://enablemedicine.pinpointhq.com/en/jobs/75388",
    location: "San Francisco, CA or New York City, NY",
    refine_job_desc: "",
  },
  {
    company: "DriveTime",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/drivetime.wd1.myworkdayjobs.com",
    link: "https://drivetime.wd1.myworkdayjobs.com/en-US/DriveTime/job/Data-Science-Intern_R2269",
    location: "Tempe, AZ or Dallas, TX",
    refine_job_desc: "",
  },
  {
    company: "Rubrik",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    link: "https://app.ripplematch.com/job/rubrik/4779bc75/",
    location: "Palo Alto, CA",
    refine_job_desc: "",
  },
  {
    company: "Wizards of the Coast",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/wizardsofthecoast/jobs/6495507002",
    location: "Renton or Bellevue, WA",
    refine_job_desc: "",
  },
  {
    company: " Abarca Health ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.abarcahealth.com",
    link: "https://careers.abarcahealth.com/details/?gh_jid=5316850003&jid=5316850003",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: " Abarca Health ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.abarcahealth.com",
    link: "https://careers.abarcahealth.com/details/?gh_jid=5317277003&jid=5317277003",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: " Abarca Health ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.abarcahealth.com",
    link: "https://careers.abarcahealth.com/details/?gh_jid=5317186003&jid=5317186003",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: " Abarca Health ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.abarcahealth.com",
    link: "https://careers.abarcahealth.com/details/?gh_jid=5317018003&jid=5317018003",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Bungie",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/bungie/jobs/4663668",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Acumen",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/acumenllc/e4e5fe92-848d-43b3-b6e8-c07c2a35fe61",
    location: "Burlingame/Los Angeles, CA or Washington, DC",
    refine_job_desc: "",
  },
  {
    company: " RVO Health ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/rvohealth/jobs/4108444005",
    location: "Charlotte, NC",
    refine_job_desc: "",
  },
  {
    company: " RVO Health ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/rvohealth/jobs/4109265005",
    location: "Charlotte, NC",
    refine_job_desc: "",
  },
  {
    company: "Intuitive",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.intuitive.com",
    link: "https://careers.intuitive.com/us/en/search-results?m=3&keywords=intern",
    location: "Sunnyvale, CA (on-site)",
    refine_job_desc: "",
  },
  {
    company: "RTI",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/rti/jobs/4719368",
    location: "Sunnyvale, CA",
    refine_job_desc: "",
  },
  {
    company: "Pegasystems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.pega.com",
    link: "https://www.pega.com/about/careers/job-listings?f[0]=category%3A24376",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "ULINE",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/uline.wd1.myworkdayjobs.com",
    link: "https://uline.wd1.myworkdayjobs.com/en-US/Uline_Careers/job/Pleasant-Prairie-WI/Software-Developer-Internship---Summer-2023_R224467",
    location: "Pleasant Prairie, WI",
    refine_job_desc: "",
  },
  {
    company: " Ball Aerospace ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.ball.com",
    link: "https://jobs.ball.com/aerospace/job/Fairborn-Technical-Intern-Software-Engineering-OH-45432/943178800/",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: " Ball Aerospace ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.ball.com",
    link: "https://jobs.ball.com/aerospace/job/Broomfield-Technical-Intern-Data-Scientist-CO-80020/934235400/",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: " Ball Aerospace ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.ball.com",
    link: "https://jobs.ball.com/aerospace/job/Broomfield-Technical-Intern-Data-Engineer-CO-80020/946621300/",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: " Ball Aerospace ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.ball.com",
    link: "https://jobs.ball.com/aerospace/job/Boulder-Technical-Intern-Machine-LearningDeep-Learning-CO-80301/962541800/",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: "Hugging Face",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/apply.workable.com",
    link: "https://apply.workable.com/huggingface/j/350457122B/",
    location: "New York, NY or Remote",
    refine_job_desc: "",
  },
  {
    company: "Mercedes-Benz",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.mercedes-benz.com",
    link: "https://jobs.mercedes-benz.com/job/369916/data-scientist-intern.html",
    location: "Farmington Hills, MI",
    refine_job_desc: "",
  },
  {
    company: "Momentum",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/mwrecruiting-openhire.silkroad.com",
    link: "https://mwrecruiting-openhire.silkroad.com/epostings/index.cfm?fuseaction=app.jobinfo&jobid=15359&source=ONLINE&JobOwner=1030463&company_id=30094&version=11&byBusinessUnit=NULL&bycountry=0&bystate=0&byRegion=&bylocation=&keywords=&byCat=&proximityCountry=",
    location: "NYC, St. Louis, Chicago, or Atlanta",
    refine_job_desc: "",
  },
  {
    company: "GitLab",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/gitlab/jobs/6069423002",
    location: "remote",
    refine_job_desc: "",
  },
  {
    company: "Chamberlain Group",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/chamberlain.wd1.myworkdayjobs.com",
    link: "https://chamberlain.wd1.myworkdayjobs.com/en-US/Chamberlain_Group?workerSubType=a1d19ddc127d10332f2265ab00be635b",
    location: "Oak Brook, IL",
    refine_job_desc: "",
  },
  {
    company: "Rockwell Automation",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/rockwellautomation.wd1.myworkdayjobs.com",
    link: "https://rockwellautomation.wd1.myworkdayjobs.com/en-US/External_Rockwell_Automation/job/Intern--Information-Technology_R22-8470-2?Codes=ILINK",
    location: "Milwaukee, WI",
    refine_job_desc: "",
  },
  {
    company: "Commvault",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.commvault.com",
    link: "https://careers.commvault.com/us/en/search-results?keywords=intern",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Expensify",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/we.are.expensify.com",
    link: "https://we.are.expensify.com/full-stack-engineering-intern",
    location: "Remote",
    refine_job_desc: "",
  },
  {
    company: "Corsair",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/edix.fa.us2.oraclecloud.com",
    link: "https://edix.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions?keyword=intern",
    location: "Milpitas, CA",
    refine_job_desc: "",
  },
  {
    company: "Corsair",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/edix.fa.us2.oraclecloud.com",
    link: "https://edix.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/job/7111?utm_medium=jobshare",
    location: "Milpitas, CA",
    refine_job_desc: "",
  },
  {
    company: "Johnson Controls",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jci.wd5.myworkdayjobs.com",
    link: "https://jci.wd5.myworkdayjobs.com/JCI/job/Fishers-Indiana-United-States-of-America/Software-Engineering-Intern_WD30159525",
    location: "Fishers, IN",
    refine_job_desc: "",
  },
  {
    company: "Takeda",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.takedajobs.com",
    link: "https://www.takedajobs.com/job/-/-/1113/41541695120",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "D.R. Horton",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/drhorton.taleo.net",
    link: "https://drhorton.taleo.net/careersection/campus/jobdetail.ftl?job=2205844&lang=en",
    location: "Arlington, TX",
    refine_job_desc: "",
  },
  {
    company: "Navy Federal Credit Union",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/nfcucareers.ttcportals.com",
    link: "https://nfcucareers.ttcportals.com/jobs/10865239-summer-intern-data-science",
    location: "Vienna, VA",
    refine_job_desc: "",
  },
  {
    company: "Western Digital (WD)",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.smartrecruiters.com",
    link: "https://careers.smartrecruiters.com/WesternDigital/?search=intern",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Western Digital (WD)",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.smartrecruiters.com",
    link: "https://jobs.smartrecruiters.com/WesternDigital/743999857498066-system-design-verification-engineering-intern",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Intel",
    companyDomain: "intel.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/intel.wd1.myworkdayjobs.com",
    link: "https://intel.wd1.myworkdayjobs.com/en-US/External?q=software%20intern",
    location: "Various",
    refine_job_desc: "",
  },
  {
    company: "Harry's",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/harrys/jobs/4798058",
    location: "New York, NY",
    refine_job_desc: "",
  },
  {
    company: "Smartsheet",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.smartsheet.com",
    link: "https://www.smartsheet.com/careers-list?location=&department=Internships+-+Summer+2023",
    location: "Bellevue, WA",
    refine_job_desc: "",
  },
  {
    company: "Smartsheet",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.smartsheet.com",
    link: "https://www.smartsheet.com/careers/position/4764695/security-automation-intern-security-engineering-team",
    location: "Bellevue, WA",
    refine_job_desc: "",
  },
  {
    company: "MyFitnessPal",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/myfitnesspal",
    location: "Remote, US",
    refine_job_desc: "",
  },
  {
    company: "MyFitnessPal",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/myfitnesspal/jobs/4744056",
    location: "Remote, US",
    refine_job_desc: "",
  },
  {
    company: "Stryker",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.stryker.com",
    link: "https://careers.stryker.com/job-search-results/?keyword=software&location=USA&country=US&radius=25&job_type=Yes",
    location: "Varies",
    refine_job_desc: "",
  },
  {
    company: "Siemens",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.siemens.com",
    link: "https://jobs.siemens.com/disw/jobs?page=1&experienceLevels=Student%20(Not%20Yet%20Graduated)&location=united%20states&stretch=0&stretchUnit=MILES&categories=Internal%20Services",
    location: "Varies",
    refine_job_desc: "",
  },
  {
    company: "Discord",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/discord.com",
    link: "https://discord.com/careers",
    location: "San Francisco, CA or Remote",
    refine_job_desc: "",
  },
  {
    company: "Pentair",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/pentair.wd5.myworkdayjobs.com",
    link: "https://pentair.wd5.myworkdayjobs.com/en-US/Pentair_Careers/job/Internship---Computer-Science-Engineering-Leadership-Development-Program---Summer-2023_R14517",
    location: "Multiple Locations",
    refine_job_desc: "",
  },
  {
    company: " General Atomics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.ga-careers.com",
    link: "https://www.ga-careers.com/job/boulder/space-software-development-intern/499/41040384016",
    location: "Boulder, CO",
    refine_job_desc: "",
  },
  {
    company: " General Atomics ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.ga-careers.com",
    link: "https://www.ga-careers.com/job/herndon/software-engineering-intern/499/40645672416",
    location: "Boulder, CO",
    refine_job_desc: "",
  },
  {
    company: "Seismic",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/seismic.com",
    link: "https://seismic.com/careers/job-detail/?gh_jid=4765666004",
    location: "San Diego, CA",
    refine_job_desc: "",
  },
  {
    company: " Slalom Build ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.slalombuild.com",
    link: "https://jobs.slalombuild.com/apex/CandidatePortalBuild?nostate=1#/post/a0h1R00000DE06wQAD",
    location: "Atlanta, GA or Chicago, IL",
    refine_job_desc: "",
  },
  {
    company: " Slalom Build ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.slalombuild.com",
    link: "https://jobs.slalombuild.com/apex/CandidatePortalBuild?nostate=1#/post/a0h1R00000DE07LQAT",
    location: "Atlanta, GA or Chicago, IL",
    refine_job_desc: "",
  },
  {
    company: "Spotify",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/spotify/d40b915f-2df6-4f28-822f-34bfaddc4c2b?lever-source=LinkedInJobs",
    location: "Remote (Americas)",
    refine_job_desc: "",
  },
  {
    company: "A10 Networks",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/osv-a10networks.wd5.myworkdayjobs.com",
    link: "https://osv-a10networks.wd5.myworkdayjobs.com/en-US/A10CareerSite?jobFamilyGroup=0baf5922437e10337ea79335bfa1a52c",
    location: "San Jose, CA",
    refine_job_desc: "",
  },
  {
    company: "Odoo, Inc",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/www.odoo.com",
    link: "https://www.odoo.com/es_ES/jobs/detail/software-engineer-intern-summer-2023-buffalo-ny-1113?utm_campaign=Job+Campaign+%5B291%5D&utm_medium=Website+%5B1%5D&utm_source=handshake+%5B644%5D",
    location: "Buffalo, NY",
    refine_job_desc: "",
  },
  {
    company: "Cognizant",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.cognizant.com",
    link: "https://careers.cognizant.com/us/en/job/COGNGLOBAL40711/Undergraduate-Technology-Intern",
    location: "Atlanta, GA; Dallas, TX; Denver, CO or Phoenix, AZ",
    refine_job_desc: "",
  },
  {
    company: "SAIC",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.saic.com",
    link: "https://jobs.saic.com/jobs/11896556-software-engineer-intern",
    location: "El Segundo, CA",
    refine_job_desc: "",
  },
  {
    company: "Unisys",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.unisys.com",
    link: "https://jobs.unisys.com/us/en/job/REQ543355/Software-Engineer-Intern",
    location: "Remote, USA",
    refine_job_desc: "",
  },
  {
    company: "Ultra-Sonobuoy Systems",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ultra.wd3.myworkdayjobs.com",
    link: "https://ultra.wd3.myworkdayjobs.com/ultra-careers/job/Victor-NY-United-States/Software-Engineering-Intern_REQ-5201-1",
    location: "Victor, NY",
    refine_job_desc: "",
  },
  {
    company: "PWC",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.us.pwc.com",
    link: "https://jobs.us.pwc.com/job/-/-/932/43383605072",
    location: "Multiple Locations & Remote",
    refine_job_desc: "",
  },
  {
    company: "Peraton",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers-peraton.icims.com",
    link: "https://careers-peraton.icims.com/jobs/130171/summer-2023-cyber-software-engineering-intern--landover%2c-md/job",
    location: "Landover, MD",
    refine_job_desc: "",
  },
  {
    company: "Cowbell",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/cowbellcyber/jobs/5454673003",
    location: "New York, NY (Remote)",
    refine_job_desc: "",
  },
  {
    company: "Formerly Known As",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.smartrecruiters.com",
    link: "https://jobs.smartrecruiters.com/PublicisGroupe/743999882893812-associate-front-enddeveloperinternsource=Linkedin&utm_source=Simplify",
    location: "New York, NY (Onsite)",
    refine_job_desc: "",
  },
  {
    company: "Co:here",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/cohere/",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "Co:here",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/cohere/ed985a33-206e-425a-8c68-fdbdcc34b8bb",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: "Co:here",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/cohere/c856d444-54bf-4a70-94b1-756769de0292",
    location: "Multiple locations",
    refine_job_desc: "",
  },
  {
    company: " Clear ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/clear/jobs/4782043",
    location: "NYC, Austin",
    refine_job_desc: "",
  },
  {
    company: " Clear ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/clear/jobs/4782148",
    location: "NYC, Austin",
    refine_job_desc: "",
  },
  {
    company: "Nasuni",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.jobvite.com",
    link: "https://jobs.jobvite.com/nasuni/job/oWbVlfw7",
    location: "Boston, MA",
    refine_job_desc: "",
  },
  {
    company: "US News",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/recruiting.myapps.paychex.com",
    link: "https://recruiting.myapps.paychex.com/appone/MainInfoReq.asp?R_ID=5245479&B_ID=91&fid=1&Adid=&SearchScreenID=8768&CountryID=3&LanguageID=2",
    location: "Washington, DC",
    refine_job_desc: "",
  },
  {
    company: "Carbon",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/ats.comparably.com",
    link: "https://ats.comparably.com/api/v1/lvr/carbon/02cf6a7e-d13f-47d1-823d-6c4a8bb0f2ed",
    location: "Redwood City, CA",
    refine_job_desc: "",
  },
  {
    company: "Ellington Management Group",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/phh.tbe.taleo.net",
    link: "https://phh.tbe.taleo.net/phh03/ats/careers/v2/viewRequisition?org=ELLINGTONMGMTGRP&cws=37&rid=123",
    location: "Old Greenwich, CT",
    refine_job_desc: "",
  },
  {
    company: "Societe Generale",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/careers.societegenerale.com",
    link: "https://careers.societegenerale.com/en/job-offers/internship-it-full-stack-development-230002JI-en",
    location: "NYC",
    refine_job_desc: "",
  },
  {
    company: "Dow Jones",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/dowjones.wd1.myworkdayjobs.com",
    link: "https://dowjones.wd1.myworkdayjobs.com/Dow_Jones_Career/job/Rockville/Summer-2023-Internship---Software-Development--OPIS_Job_Req_36422",
    location: "Rockville, MD",
    refine_job_desc: "",
  },
  {
    company: "Findhelp",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/findhelp/b03d210f-3d73-4d94-ad21-e04534250b4d",
    location: "Austin, TX",
    refine_job_desc: "",
  },
  {
    company: " Ramp ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/ramp/jobs/4820594002",
    location: "New York, Miami, Remote",
    refine_job_desc: "",
  },
  {
    company: " Ramp ",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/ramp/jobs/5083064002",
    location: "New York, Miami, Remote",
    refine_job_desc: "",
  },
  {
    company: "Anyscale",
    companyDomain: "anyscale.com",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/jobs.lever.co",
    link: "https://jobs.lever.co/anyscale/78a003a6-221a-4414-bf95-7c734cbfc4d9",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },
  {
    company: "Humane",
    role: "Software Engineer",
    type: "internship",
    tags: ["Software Engineer", "Internship"],
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    link: "https://boards.greenhouse.io/humane/jobs/4788711004",
    location: "San Francisco, CA",
    refine_job_desc: "",
  },

  {
    company: "Adobe",
    logo: "https://logo.clearbit.com/careers.adobe.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://careers.adobe.com/us/en/job/R131578/2023-Intern-Product-Marketing-Manager",
  },
  {
    company: "Ancestry",
    logo: "https://logo.clearbit.com/careers.ancestry.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://careers.ancestry.com/jobs/search/internships-search-page",
  },
  {
    company: "Atlassian",
    logo: "https://logo.clearbit.com/www.atlassian.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://www.atlassian.com/company/careers/detail/c2878062-e8b1-4513-8a96-6ce37ff62151",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Product-Manager-MBA-Intern-United-States/1380434",
  },
  {
    company: "Disney",
    logo: "https://logo.clearbit.com/b''",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: null,
  },
  {
    company: "Duolingo",
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://boards.greenhouse.io/duolingo/jobs/6443972002",
  },
  {
    company: "Ebay",
    logo: "https://logo.clearbit.com/app.ripplematch.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://app.ripplematch.com/job/ebay/dc5a6b42/",
  },
  {
    company: "Electronic Arts (EA)",
    logo: "https://logo.clearbit.com/ea.gr8people.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://ea.gr8people.com/jobs/175941/product-manager-intern-summer-2023",
  },
  {
    company: "Google",
    companyDomain: "google.com",
    logo: "https://logo.clearbit.com/careers.google.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Closed for 2023",
    link: "https://careers.google.com/jobs/results/?distance=50&hl=en_US&jex=ENTRY_LEVEL&jlo=en_US&q=associate%20product%20manager",
  },
  {
    company: "Intuit",
    logo: "https://logo.clearbit.com/jobs.intuit.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.intuit.com/job/mountain-view/product-manager-intern/27595/35430898512?utm_source=copy&utm_medium=social_post&utm_campaign=Intuit_social",
  },
  {
    company: "Lenovo",
    logo: "https://logo.clearbit.com/jobs.lenovo.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.lenovo.com/en_US/careers/JobDetail/Product-Line-Manager-Value-Added-Services/25848#",
  },
  {
    company: "LinkedIn",
    logo: "https://logo.clearbit.com/www.linkedin.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://www.linkedin.com/jobs/view/3264555280/?alternateChannel=search&refId=kkkHjzpYdJfz0H9sKBU5FA%3D%3D&trackingId=DPdD1sB7NfLTd3odzfjWKQ%3D%3D",
  },
  {
    company: "Mastercard",
    logo: "https://logo.clearbit.com/careers.mastercard.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://careers.mastercard.com/us/en/job/R-173773/Summer-2023-Intern-Product-Engineering-Product-Management",
  },
  {
    company: "Microsoft",
    companyDomain: "microsoft.com",
    logo: "https://logo.clearbit.com/careers.microsoft.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://careers.microsoft.com/students/us/en/job/1375725/Product-Management-Intern-Opportunities-for-University-Students",
  },
  {
    company: "Oracle",
    logo: "https://logo.clearbit.com/eeho.fa.us2.oraclecloud.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://eeho.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/en/sites/CX_1/requisitions/preview/181023/?location=United+States&locationId=300000000149325&selectedFlexFieldsFacets=%22AttributeChar16%7CInternPM%22",
  },
  {
    company: "PayPal",
    logo: "https://logo.clearbit.com/paypal.eightfold.ai",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://paypal.eightfold.ai/careers/job?domain=paypal.com&query=Product%20Management&location=United%20States&pid=274892238639&sort_by=relevance",
  },
  {
    company: "Reddit",
    logo: "https://logo.clearbit.com/www.linkedin.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://www.linkedin.com/jobs/view/3466809183/",
  },
  {
    company: "Redfin",
    logo: "https://logo.clearbit.com/redfin.wd1.myworkdayjobs.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://redfin.wd1.myworkdayjobs.com/redfin_careers_ET/job/WA---Seattle/Internship-Hiring---Tech-Opportunities-for-Candidates-Engaged-with-Redfin-Sponsored-Organizations_49559",
  },
  {
    company: "Roblox",
    logo: "https://logo.clearbit.com/jobs.roblox.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.roblox.com/careers?query=product%20manager%20intern&pid=137452383317&domain=roblox.com&triggerGoButton=false",
  },
  {
    company: "Salesforce",
    logo: "https://logo.clearbit.com/salesforce.wd1.myworkdayjobs.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://salesforce.wd1.myworkdayjobs.com/en-US/Futureforce_Internships/job/Summer-2023-Intern---Technical-Program-Manager--Government-Cloud-_JR165624-2?d=cta-summer-view-sjb-1",
  },
  {
    company: "Spotify",
    logo: "https://logo.clearbit.com/www.lifeatspotify.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://www.lifeatspotify.com/jobs/summer-internship-product-marketing-mba-music-mission-us",
  },
  {
    company: "Tableau",
    logo: "https://logo.clearbit.com/salesforce.wd1.myworkdayjobs.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://salesforce.wd1.myworkdayjobs.com/en-US/Tableau/job/Washington---Seattle/Summer-2021-Intern---Product-Manager---Tableau_JR72480?source=PittCSC",
  },
  {
    company: "TikTok",
    companyDomain: "tiktok.com",
    logo: "https://logo.clearbit.com/careers.tiktok.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://careers.tiktok.com/position/7138250503720618247/detail",
  },
  {
    company: "Walmart",
    logo: "https://logo.clearbit.com/careers.walmart.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Not Yet Open",
    link: "https://careers.walmart.com/us/jobs/WD1223968-usa-2023-summer-intern-sams-club-product-manager-mba-internship",
  },
  {
    company: "Workday",
    logo: "https://logo.clearbit.com/workday.wd5.myworkdayjobs.com",
    location: "",
    type: "internship",
    role: "Product Manager",
    tags: ["Product Manager", "Internship"],
    CurrentStatus: "Open",
    link: "https://workday.wd5.myworkdayjobs.com/en-US/Workday_Early_Career/job/USA-CO-Boulder/Machine-Learning-Product-Manager-Intern_JR-74893?q=product%20manager&Location_Country=bc33aa3152ec42d4995f4791a106ed09",
  },
  {
    company: "Adobe",
    logo: "https://logo.clearbit.com/careers.adobe.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://careers.adobe.com/us/en",
  },
  {
    company: "Apple",
    logo: "https://logo.clearbit.com/jobs.apple.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.apple.com/en-us/details/200318153/product-manager-services",
  },
  {
    company: "Asana",
    logo: "https://logo.clearbit.com/asana.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: "https://asana.com/jobs/university-recruiting",
  },
  {
    company: "Atlassian",
    logo: "https://logo.clearbit.com/www.atlassian.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Open",
    link: "https://www.atlassian.com/company/careers/detail/c2878062-e8b1-4513-8a96-6ce37ff62151",
  },
  {
    company: "Blackrock",
    logo: "https://logo.clearbit.com/careers.blackrock.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Paused",
    link: "https://careers.blackrock.com/early-careers/",
  },
  {
    company: "C3.ai",
    logo: "https://logo.clearbit.com/c3.ai",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Paused",
    link: "https://c3.ai/job-description/?gh_jid=5670521002https://c3.ai/careers/",
  },
  {
    company: "CapitalOne",
    logo: "https://logo.clearbit.com/www.capitalonecareers.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.capitalonecareers.com/full-time-programs",
  },
  {
    company: "Cisco",
    companyDomain: "cisco.com",
    logo: "https://logo.clearbit.com/jobs.cisco.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Open",
    link: "https://jobs.cisco.com/jobs/ProjectDetail/Product-Manager-MBA-Full-Time-United-States/1380425",
  },
  {
    company: "Citrix",
    logo: "https://logo.clearbit.com/jobs.citrix.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Paused",
    link: "https://jobs.citrix.com/jobs/associate-product-manager-prague-czechia-remote-poland-estonia",
  },
  {
    company: "Coinbase",
    logo: "https://logo.clearbit.com/www.coinbase.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Paused",
    link: "https://www.coinbase.com/careers/positions/2300617",
  },
  {
    company: "DoorDash",
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://boards.greenhouse.io/doordash/jobs/3551261?gh_jid=3551261",
  },
  {
    company: "Dropbox",
    logo: "https://logo.clearbit.com/b''",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Paused",
    link: null,
  },
  {
    company: "Duolingo",
    logo: "https://logo.clearbit.com/boards.greenhouse.io",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://boards.greenhouse.io/duolingo/jobs/6333786002",
  },
  {
    company: "Ebay",
    logo: "https://logo.clearbit.com/jobs.ebayinc.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.ebayinc.com/us/en/job/R0056809/Product-Manager-Search",
  },
  {
    company: "Electronic Arts (EA)",
    logo: "https://logo.clearbit.com/ea.gr8people.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://ea.gr8people.com/jobs/172658/associate-product-manager-apex-legends?utm_campaign=google_jobs_apply&utm_source=google_jobs_apply&utm_medium=organic",
  },
  {
    company: "Expedia Group",
    logo: "https://logo.clearbit.com/careers.expediagroup.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://careers.expediagroup.com/jobs/?keyword=internship&",
  },
  {
    company: "Facebook",
    logo: "https://logo.clearbit.com/fbrpms.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "http://fbrpms.com/",
  },
  {
    company: "Google",
    companyDomain: "google.com",
    logo: "https://logo.clearbit.com/careers.google.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://careers.google.com/programs/apm/",
  },
  {
    company: "IBM",
    logo: "https://logo.clearbit.com/careers.ibm.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Open",
    link: "https://careers.ibm.com/job/17521086/product-manager-cybersecurity-remote/?codes=WEB_SEARCH_NA",
  },
  {
    company: "IBM",
    logo: "https://logo.clearbit.com/careers.ibm.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Open",
    link: "https://careers.ibm.com/job/17734208/product-manager-apprentice-san-jose-ca/?codes=WEB_SEARCH_NA",
  },
  {
    company: "Indeed",
    logo: "https://logo.clearbit.com/www.indeed.jobs",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.indeed.jobs/apm-rotation-program/",
  },
  {
    company: "Instacart",
    logo: "https://logo.clearbit.com/instacart.careers",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: "https://instacart.careers/apm/",
  },
  {
    company: "Intuit",
    logo: "https://logo.clearbit.com/jobs.intuit.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.intuit.com/search-jobs?orgIds=27595&acm=68351&ascf=[%7B%22key%22:%22ALL%22,%22value%22:%22%22%7D]",
  },
  {
    company: "IXL Learning",
    logo: "https://logo.clearbit.com/www.ixl.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Open",
    link: "https://www.ixl.com/company/careers?gh_jid=6558380002",
  },
  {
    company: "IXL Learning",
    logo: "https://logo.clearbit.com/www.ixl.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Open",
    link: "https://www.ixl.com/company/careers?gh_jid=5569713002",
  },
  {
    company: "KPCB Fellows",
    logo: "https://logo.clearbit.com/fellows.kleinerperkins.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Closed for 2023",
    link: "https://fellows.kleinerperkins.com/",
  },
  {
    company: "Lenovo",
    logo: "https://logo.clearbit.com/jobs.lenovo.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://jobs.lenovo.com/en_US/careers/JobDetail/Jr-Technical-Product-Manager/48411",
  },
  {
    company: "Lenovo",
    logo: "https://logo.clearbit.com/jobs.lenovo.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://jobs.lenovo.com/en_US/careers/JobDetail/Jr-Product-Manager/44575",
  },
  {
    company: "LinkedIn",
    logo: "https://logo.clearbit.com/careers.linkedin.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://careers.linkedin.com/apm",
  },
  {
    company: "Lyft",
    logo: "https://logo.clearbit.com/www.lyft.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.lyft.com/careers/apm",
  },
  {
    company: "Mastercard",
    logo: "https://logo.clearbit.com/careers.mastercard.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Open",
    link: "https://careers.mastercard.com/us/en/job/R-174690/Associate-Product-Specialist-Program-Management-Office",
  },
  {
    company: "Masterclass",
    logo: "https://logo.clearbit.com/www.linkedin.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.linkedin.com/jobs/view/associate-product-manager-growth-at-masterclass-3351153572/",
  },
  {
    company: "Microsoft",
    companyDomain: "microsoft.com",
    logo: "https://logo.clearbit.com/careers.microsoft.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://careers.microsoft.com/students/us/en/us-full-time-results",
  },
  {
    company: "Miro",
    logo: "https://logo.clearbit.com/miro.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://miro.com/careers/vacancy/6418353002/",
  },
  {
    company: "Mozilla",
    logo: "https://logo.clearbit.com/www.mozilla.org",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.mozilla.org/en-US/careers/",
  },
  {
    company: "New York Times",
    logo: "https://logo.clearbit.com/nytimes.wd5.myworkdayjobs.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://nytimes.wd5.myworkdayjobs.com/en-US/NYT/job/New-York-NY/Associate-Product-Manager_REQ-012991-1",
  },
  {
    company: "Oracle",
    logo: "https://logo.clearbit.com/b''",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: null,
  },
  {
    company: "PayPal",
    logo: "https://logo.clearbit.com/careers.pypl.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: "https://careers.pypl.com/university-hiring/default.aspx",
  },
  {
    company: "PayPal",
    logo: "https://logo.clearbit.com/paypal.eightfold.ai",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://paypal.eightfold.ai/careers?query=globaluniversitygraduateproductmanager&domain=paypal.com",
  },
  {
    company: "Reddit",
    logo: "https://logo.clearbit.com/b''",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: null,
  },
  {
    company: "Redfin",
    logo: "https://logo.clearbit.com/www.redfin.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://www.redfin.com/careers/departments/emerging-talent",
  },
  {
    company: "Salesforce",
    logo: "https://logo.clearbit.com/salesforce.wd1.myworkdayjobs.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://salesforce.wd1.myworkdayjobs.com/en-US/External_Career_Site/job/Summer-2023-Intern---Associate-Product-Manager--APM-_JR154732-1",
  },
  {
    company: "Schmidt Futures",
    logo: "https://logo.clearbit.com/www.schmidtfutures.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Open",
    link: "https://www.schmidtfutures.com/our-work/associate-product-managers/",
  },
  {
    company: "Shopify",
    logo: "https://logo.clearbit.com/apm.shopify.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://apm.shopify.com",
  },
  {
    company: "Spotify",
    logo: "https://logo.clearbit.com/www.lifeatspotify.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.lifeatspotify.com/students",
  },
  {
    company: "Square",
    logo: "https://logo.clearbit.com/www.smartrecruiters.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Open",
    link: "https://www.smartrecruiters.com/Square/743999881423432",
  },
  {
    company: "TikTok",
    companyDomain: "tiktok.com",
    logo: "https://logo.clearbit.com/careers.tiktok.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://careers.tiktok.com/position/7029440865349912839/detail",
  },
  {
    company: "TikTok",
    companyDomain: "tiktok.com",
    logo: "https://logo.clearbit.com/careers.tiktok.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://careers.tiktok.com/position/7145349534330226980/detail",
  },
  {
    company: "Twitter",
    logo: "https://logo.clearbit.com/twitterapm.splashthat.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: "https://twitterapm.splashthat.com/",
  },
  {
    company: "Uber",
    logo: "https://logo.clearbit.com/www.uber.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.uber.com/us/en/careers/apm/",
  },
  {
    company: "Visa",
    logo: "https://logo.clearbit.com/usa.visa.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://usa.visa.com/careers/job-details.jobid.743999691375737.deptid.1146742.html",
  },
  {
    company: "Walmart",
    logo: "https://logo.clearbit.com/careers.walmart.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: "https://careers.walmart.com/student",
  },
  {
    company: "Walmart",
    logo: "https://logo.clearbit.com/careers.walmart.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Open",
    link: "https://careers.walmart.com/us/jobs/WD1116701-2023-full-time-campus-product-specialist",
  },
  {
    company: "Workday",
    logo: "https://logo.clearbit.com/workday.wd5.myworkdayjobs.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Not Yet Open",
    link: "https://workday.wd5.myworkdayjobs.com/en-US/Workday_University_Careers/job/USA-CA-Pleasanton/Technical-Product-Manager--Rotational-Program_JR-37838-1",
  },
  {
    company: "Workday",
    logo: "https://logo.clearbit.com/workday.wd5.myworkdayjobs.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "New Grad"],
    Role_Level: "Entry level",
    CurrentStatus: "Not Yet Open",
    link: "https://workday.wd5.myworkdayjobs.com/en-US/Workday_Early_Career/job/USA-CA-Pleasanton/Product-Manager--New-University-Graduate_JR-74222?q=product%20management",
  },
  {
    company: "Yahoo",
    logo: "https://logo.clearbit.com/yahooapms.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://yahooapms.com/",
  },
  {
    company: "Yelp",
    logo: "https://logo.clearbit.com/b''",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Discontinued",
    link: null,
  },
  {
    company: "Zynga",
    logo: "https://logo.clearbit.com/www.zynga.com",
    location: "",
    type: "full-time",
    role: "Product Manager",
    tags: ["Product Manager", "Mid-senior Role"],
    Role_Level: "APM",
    CurrentStatus: "Closed for 2023",
    link: "https://www.zynga.com/jobs/university/",
  },

  {
    company: "10x Banking",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/10xbanking.com",
    locations: "London, United Kingdom; Sydney, Australia; Remote",
    role: "Business Analysts, Software Engineer, Data Engineer",
    link: "https://www.10xbanking.com/job-vacancies",
  },
  {
    company: "2U",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/2U.com",
    locations: "Remote",
    role: "Software Engineer, Data Engineer",
    link: "https://2u.com/careers/jobs/6411490002/",
  },
  {
    company: "7Eleven",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/xxIcNxP.png",
    locations: "Bangalore, India",
    role: "Software Engineer",
    link: "https://careers.7-eleven.com/",
  },
  {
    company: "Accenture",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Accenture.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Manager, Business Analyst, Data Scientist, Consulting Manager, Customer Service",
    link: "https://www.accenture.com/us-en/careers",
  },
  {
    company: "Accertify",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/XRZBXMv.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://aexp.eightfold.ai/careers?domain=aexp.com&query=Accertify&location=United%20States&pid=14288306",
  },
  {
    company: "Accesa & RaRo",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/accesa.eu",
    locations: "Romania; Remote",
    role: "Software Engineer, UX/UI Designer",
    link: "https://careers.accesa.eu/",
  },
  {
    company: "Accruent",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/accruent.com",
    locations: "Remote US",
    role: "Product Manager, Finance / Accounting, Marketing, Technical Consultant, Security Engineer",
    link: "https://www.accruent.com/about-us/careers#careers",
    comments: "",
    geos: "",
  },
  {
    company: "Aclima",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/aclima.io",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.aclima.io/careers",
  },
  {
    company: "Action IQ",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/actioniq.com",
    locations: "New York, NY; Remote",
    role: "Software Engineer, Sales, Marketing",
    link: "https://www.actioniq.com/carreers",
  },
  {
    company: "ActiveVideo",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/activevideo.com",
    locations: "Hilversum, Netherlands",
    role: "Software Engineer",
    link: "https://www.activevideo.com/our-careers#positions",
  },
  {
    company: "Addepar",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/addepar.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://grnh.se/c585de212us ",
    comments: "",
    geos: "",
  },
  {
    company: "Adeva",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/adevait.com",
    locations: "Remote",
    role: "Software Engineer, DevOps Engineer, Senior Data Engineer",
    link: "https://adevait.com/careers/job-openings#open-position",
  },
  {
    company: "Adevinta",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/20of0xR.png",
    locations:
      "Amsterdam, Netherlands; Berlin, Germany; Barcelona, Spain; Milan, Italy",
    role: "Software Engineer",
    link: "https://www.adevinta.com/careers",
  },
  {
    company: "Adform",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/ngnJSQN.png",
    locations: "Lithuania; Poland; India",
    role: "Software Engineer, Software Engineering Manager",
    link: "http://join.adform.com/ ",
  },
  {
    company: "ADP",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/adp.com",
    locations: "Alpharetta, GA; Roseland, NJ; Los Angeles, CA",
    role: "Software Engineer, Product Manager, Product Designer, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://jobs.adp.com",
    comments: "ruchir.ghildiyal@adp.com",
    geos: "",
  },
  {
    company: "Adverity",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Adverity.com",
    locations: "Remote Europe; Vienna, Austria; Katowice, Poland",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.adverity.com/careers",
  },
  {
    company: "Adyen",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/adyen.com",
    locations:
      "San Francisco, CA; New York, NY; Amsterdam, Netherlands; Madrid, Spain; Sydney, Austalia; Sao Paulo, Brazil",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Data Engineer, Product Designer, Sales",
    link: "https://careers.adyen.com/vacancies",
  },
  {
    company: "Adzuna",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/3eVBJah.png",
    locations: "Remote",
    role: "Infrastructure Engineer, Perl Developer",
    link: "https://www.adzuna.co.uk/jobs/search?q=Adzuna&loc=86383&ac_what=1",
  },
  {
    company: "AeroVect",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/aerovect.com",
    locations: "San Francisco, CA",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://aerovect.com/#/careers",
  },
  {
    company: "Aescape",
    lastChecked: "11-Nov-2022",
    logo: "https://imgur.com/qqnB1MF.png",
    locations: "New York, NY; Remote",
    role: "Software Engineer, Hardware Engineer, Site Reliability / DevOps, Marketing",
    link: "https://www.aescape.co/",
  },
  {
    company: "Aetion",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/Q8DWjpU.png",
    locations: "Remote US",
    role: "Software Engineer, Data Engineer",
    link: "https://grnh.se/06c750d91us",
  },
  {
    company: "Affine",
    lastChecked: "17-Nov-2022",
    logo: "https://logo.clearbit.com/affine.ai",
    locations: "Santa Clara, CA",
    role: "Data Scientist",
    link: "https://affine.skillate.com/",
  },
  {
    company: "Aidence",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/aidence.com",
    locations: "Netherlands; United Kingdom; Remote",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.aidence.com/join-the-team/",
  },
  {
    company: "Airbase",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Airbase.com",
    locations: "Remote",
    role: "Software Engineer, Marketing, Product Manager, Product Designer, Sales",
    link: "https://grnh.se/0844c1174us",
  },
  {
    company: "Airbnb",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/ScCyDVb.png",
    locations: "Remote US",
    role: "Data Engineer, Data Analyst",
    link: "https://careers.airbnb.com/",
  },
  {
    company: "Airbyte",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/airbyte.io",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager, Product Manager",
    link: "https://airbyte.com/careers",
  },
  {
    company: "Airtasker",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/undefined.png",
    locations: "Portugal",
    role: "Software Engineer",
    link: "https://www.airtasker.com/careers/ ",
  },
  {
    company: "Aiven",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/bzisFSn.png",
    locations: "Europe",
    role: "Software Engineer",
    link: "https://aiven.io/careers/job",
  },
  {
    company: "Aiven.io",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/aiven.io",
    locations: "Europe; Canada; United States; Australia; New Zealand",
    role: "Software Engineer",
    link: "https://aiven.io/careers/",
  },
  {
    company: "Akeneo",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Akeneo.com",
    locations: "France",
    role: "Data Analyst, Data Engineer, Software Engineer, Engineering Manager",
    link: "https://careers.akeneo.com/jobs",
  },
  {
    company: "Albert Heijn",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/cSNFqDf.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://careers.aholddelhaize.nl/vacature/senior-machine-learning-engineer-6131/?utm_source=linkedin",
  },
  {
    company: "Alert Innovation",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/alertinnovation.com",
    locations: "Boston, MA; Remote",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Operations, Mechanical Engineer, Technical Writer",
    link: "https://boards.greenhouse.io/alertinnovation",
  },
  {
    company: "Allo-Media",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/allo-media.net",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://uh.live/backend-job",
  },
  {
    company: "Alma",
    lastChecked: "24-Jan-2023",
    logo: "https://logo.clearbit.com/helloalma.com",
    locations: "United Sttes; Remote",
    role: "Software Engineer, Human Resources, Sales, Marketing, Operations",
    link: "https://boards.greenhouse.io/alma",
  },
  {
    company: "AlsoEnergy",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/alsoenergy.com",
    locations: "Boulder, CO; Longmont, CO",
    role: "Software Engineer, Solutions Architect",
    link: "https://home.alsoenergy.com/careers/",
  },
  {
    company: "Alterdomus",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/alterdomus.com",
    locations: "United States, Canada",
    role: "Software Engineer",
    link: "https://careers.alterdomus.com/",
  },
  {
    company: "Altium",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Altium.com",
    locations: "Remote US; China; Poland; Serbia; Japan",
    role: "Data Engineer, Software Engineer",
    link: "https://careers.altium.com/",
  },
  {
    company: "Alto",
    lastChecked: "11-Jan-2023",
    logo: "https://logo.clearbit.com/ridealto.com",
    locations: "Dallas, TX",
    role: "Software Engineer, Product Manager, Data Scientist, Operations",
    link: "https://www.linkedin.com/company/ridealto/jobs/",
  },
  {
    company: "Amazon",
    companyDomain: "amazon.com",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/amazon.com",
    locations:
      "Remote Italy; Luxembourg; Remote UK; Dalian, China; Gdansk, Poland; Austin, TX; Seattle, WA; Tempe, AZ",
    role: "Software Engineer, Software Engineering Manager, Knowledge Management",
    link: "https://www.amazon.jobs/en/",
  },
  {
    company: "Amboss",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/amboss.com",
    locations: "Berlin, Germany; Remote EU",
    role: "Software Engineer, Product Manager",
    link: "https://careers.amboss.com/jobs/",
  },
  {
    company: "American National University ",
    lastChecked: "13-Jan-2023",
    logo: "https://logo.clearbit.com/an.edu",
    locations: "Remote",
    role: "Software Engineering Manager, Product Manager, Product Designer",
    link: "",
    comments: "Jmusgrove@an.edu",
  },
  {
    company: "Amplitude",
    lastChecked: "13-Dec-2022",
    logo: "https://logo.clearbit.com/amplitude.com",
    locations: "San Francisco, CA",
    role: "Software Engineer, Product Designer, Marketing",
    link: "https://amplitude.com/careers",
    comments: "",
    geos: "37.786146,-122.398676",
  },
  {
    company: "Anaconda",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/anaconda.com",
    locations: "United States; United Kingdom; Canada; Germany",
    role: "Software Engineer",
    link: "https://www.anaconda.com/careers",
  },
  {
    company: "Anello Photonics",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/anellophotonics.com",
    locations: "Santa Clara, CA",
    role: "Software Engineer, Hardware Engineer",
    link: "https://boards.greenhouse.io/anellophotonics",
  },
  {
    company: "Anyfin",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/ZZEUzvS.png",
    locations: "Stockholm, Sweden",
    role: "Site Reliability / DevOps",
    link: "https://career.anyfin.com/jobs/2130635-devops-engineer ",
  },
  {
    company: "Apixio",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/apixio.com",
    locations: "San Mateo, CA; San Diego, CA",
    role: "Software Engineer, Software Engineering Manager, Data Scientist",
    link: "https://www.apixio.com/careers/",
  },
  {
    company: "Apollo.io",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/vjwaJn3.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Designer, Finance / Accounting, Marketing",
    link: "https://boards.greenhouse.io/apolloio",
  },
  {
    company: "ApolloMed",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/apollomed.net",
    locations: "Remote US",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.apollomed.net/careers",
  },
  {
    company: "Appfolio",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/appfolio.com",
    locations: "Santa Barbara, CA; San Diego, CA; Remote",
    role: "Software Engineer, Product Manager",
    link: "https://www.appfolioinc.com/careers",
  },
  {
    company: "Apple",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/apple.com",
    locations: "Santa Clara Valley, CA; San Diego, CA",
    role: "Software Engineer, Hardware Engineer",
    link: "https://www.apple.com/careers/",
  },
  {
    company: "Applied Systems",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/appliedsystems.com",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Finance / Accounting, Sales, Marketing, Customer Success",
    link: "https://www1.appliedsystems.com/en-us/about-us/jobs",
    comments: "",
    geos: "",
  },
  {
    company: "AppMap",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/wikCYCG.png",
    locations: "United States - East Coast",
    role: "Software Engineer Product Manager",
    link: "https://angel.co/company/appmap/jobs",
  },
  {
    company: "Apricot Solar",
    lastChecked: "06-Jan-2023",
    logo: "https://imgur.com/7mLsR9m.png",
    locations: "United States",
    role: "Human Resources, Recruiter, Sales, Marketing, Partnerships, Solar Consultant, Advisors",
    link: "https://apricotsolar.com/about/",
    comments: "bontosolar@gmail.com ",
  },
  {
    company: "Aptiv",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/aptiv.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Manager, Cloud Architect, Cybersecurity",
    link: "Aptiv.com/careers",
  },
  {
    company: "Arch Systems",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/h6fonns.png",
    locations: "Remote US; Remote Europe",
    role: "Software Engineer",
    link: "https://archsys.io/jobs/ ",
  },
  {
    company: "Archie",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/witharchie.com",
    locations: "Latin America; United States",
    role: "Software Engineer",
    link: "https://careers.witharchie.com/fullstack-software-engineer",
  },
  {
    company: "Archive Resale",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/archiveresale.co",
    locations: "Los Angeles, CA; New York, NY; San Fransisco, CA; Remote",
    role: "Software Engineer, Data Engineer, Data Analyst",
    link: "https://jobs.lever.co/Archive",
  },
  {
    company: "Arista Networks",
    lastChecked: "09-Dec-2022",
    logo: "https://logo.clearbit.com/arista.com",
    locations: "Global",
    role: "Software Engineer, Hardware Engineer, Site Reliability / DevOps",
    link: "https://www.arista.com/en/careers",
  },
  {
    company: "Around",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/MGbdp5f.png",
    locations: "Remote",
    role: "Media Client Engineer, Cloud Engineer, Software Engineer",
    link: "https://boards.greenhouse.io/around",
  },
  {
    company: "Artefact",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/tszLJxj.png",
    locations: "Germany",
    role: "Data Engineer, Data Scientist, Analytics Engineer, Cloud Architecht",
    link: "https://www.linkedin.com/company/artefact-global/jobs ",
  },
  {
    company: "Artly Coffee",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/bluehill.coffee",
    locations: "Seattle, WA",
    role: "Software Engineer, Hardware Engineer, Computer Vision Scientist",
    link: "https://www.linkedin.com/company/artly-coffee/",
  },
  {
    company: "Artsy",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/artsy.net",
    locations: "Berlin, Germany; London, United Kingdom",
    role: "Data Engineer",
    link: "https://www.artsy.net/job/2427612",
  },
  {
    company: "Ascend.io",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/ascend.io",
    locations: "Remote; San Francisco, CA",
    role: "Software Engineer, Sales",
    link: "https://www.ascend.io/company/careers/",
  },
  {
    company: "Ashby",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/k6ydbQA.png",
    locations: "North America; Europe",
    role: "Software Engineer",
    link: "https://www.ashbyhq.com/careers ",
  },
  {
    company: "ASML",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/asml.com",
    locations: "Netherlands; Germany; San Diego, CA; San Jose, CA; Connecticut",
    role: "Software Engineer, Data Scientist, ML Engineer, Data Engineer, Engineering Manager",
    link: "https://www.asml.com/en/careers/job-categories",
  },
  {
    company: "Atlassian",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/atlassian.com",
    locations: "United States; Australia; India; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Program Manager, Data Scientist, Finance / Accounting, Marketing",
    link: "https://www.atlassian.com/company/careers/all-jobs",
  },
  {
    company: "Attest",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/askattest.com",
    locations: "London, United Kingdom",
    role: "Engineering Manager",
    link: "https://www.askattest.com/careers/openings",
  },
  {
    company: "Atticus",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/atticus.com",
    locations: "Los Angeles, CA; Phoenix, AZ; Remote",
    role: "Product Manager, Finance / Accounting, Marketing, Operations",
    link: "https://www.atticus.com/careers",
  },
  {
    company: "Auction Technology Group",
    lastChecked: "20-Dec-2022",
    logo: "https://logo.clearbit.com/proxibid.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.auctiontechnologygroup.com/careers/",
  },
  {
    company: "AudienceView",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/dppEiRy.png",
    locations: "Canada; Chile",
    role: "Director of Engineering, Software Engineer",
    link: "https://www.linkedin.com/company/audienceview/jobs/",
  },
  {
    company: "Aumni",
    lastChecked: "08-Dec-2022",
    logo: "https://logo.clearbit.com/aumni.fund",
    locations: "Remote; Cottonwood Heights, UT",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.linkedin.com/in/jakesorce",
  },
  {
    company: "Autodesk",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/autodesk.com",
    locations: "Portland, OR; Atlanta, GA; Remote",
    role: "Product Designer",
    link: "https://autodesk.wd1.myworkdayjobs.com/Ext",
  },
  {
    company: "AutoScout24",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/AutoScout24.com",
    locations: "Berlin, Germany; Munich, Germany",
    role: "Product Manager, UX Designer, Software Engineer, Sales",
    link: "https://boards.greenhouse.io/autoscout24/jobs/5346421003",
  },
  {
    company: "Avegen",
    lastChecked: "13-Nov-2022",
    logo: "https://logo.clearbit.com/avegenhealth.com",
    locations: "Pune, India",
    role: "Software Engineer, Site Reliability / DevOps, Data Scientist, Product Designer, Human Resources",
    link: "https://aipl.kekahire.com/",
  },
  {
    company: "Aware",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/awarehq.com",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Data Scientist, Product Designer, Marketing, Operations",
    link: "https://join.awarehq.com/",
  },
  {
    company: "Axel",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/axel.dev",
    locations: "San Francisco, CA; New York, NY",
    role: "Founding Backend Engineer, Founding Blockchain Engineer, Founding Frontend Engineer",
    link: "https://axel.dev/careers#roles",
  },
  {
    company: "Axle Logistics",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/axlelogistics.com",
    locations: "Knoxville, TN; Chattanooga, TN",
    role: "Finance / Accounting, Sales, Operations",
    link: "https://axlelogistics.com/careers/",
  },
  {
    company: "Axon",
    lastChecked: "25-Jan-2023",
    logo: "https://logo.clearbit.com/axon.com",
    locations: "Seattle, WA; Scottsdale, AZ; United Kingdom; Remote",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Product Manager, Product Designer, Sales, Marketing, Operations, Events",
    link: "https://grnh.se/59bb64b63us",
    comments: "",
    geos: "",
  },
  {
    company: "Axoni",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Axoni.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://axoni.com/careers/",
  },
  {
    company: "AXS",
    lastChecked: "06-Jan-2023",
    logo: "https://logo.clearbit.com/axs.com",
    locations: "Los Angeles, CA; Cleveland, OH; Denver, CO",
    role: "Software Engineer, Site Reliability / DevOps, Marketing, Operations",
    link: "https://solutions.axs.com/careers/#joblist",
    comments: "",
    geos: "",
  },
  {
    company: "Axxess",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/WELF2Em.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.axxess.com/careers/",
  },
  {
    company: "Babbel",
    lastChecked: "17-Nov-2022",
    logo: "https://logo.clearbit.com/babbel.com",
    locations: "New York, NY",
    role: "Sales, Marketing, Growth Lead, Growth Marketing Manager, CRM Manager",
    link: "https://www.babbeljobs.com/#/",
  },
  {
    company: "Banner",
    lastChecked: "23-Jan-2023",
    logo: "https://logo.clearbit.com/withbanner.com",
    locations: "Remote",
    role: "Product Manager",
    link: "https://www.ycombinator.com/companies/banner/jobs/MeC2JUn-senior-product-manager",
  },
  {
    company: "Baraka",
    lastChecked: "28-Nov-2022",
    logo: "https://i.imgur.com/kMKCfbW.png",
    locations: "Dubai; Remote",
    role: "Software Engineer",
    link: "https://getbaraka.com/en/careers",
  },
  {
    company: "Barclays",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/DaowJR5.png",
    locations: "United States; United Kingdom; India",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://home.barclays/careers/",
  },
  {
    company: "Batesville Casket",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/gyeaheF.png",
    locations: "Batesville, INIRemote",
    role: "Finance / Accounting, Sales, Operations, Drivers",
    link: "https://careers.hillenbrand.com/batesville/",
  },
  {
    company: "Bayer",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/oeT6O7z.png",
    locations: "Berlin, Germany; Leverkusen, Germany; Warsaw, Poland",
    role: "Software Engineer, Data Engineer, Data Scientist",
    link: "https://career.bayer.com/en/career",
  },
  {
    company: "BCG Digital Ventures",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/BCGDV.com",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://bcgdv.com/join-us/bcgdv-careers/",
  },
  {
    company: "Beamery",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/beamery.com",
    locations: "London, United Kingdom; Berlin, Germany",
    role: "Software Engineer, Engineering Manager",
    link: "https://careers.beamery.com/",
  },
  {
    company: "BEN Group",
    lastChecked: "09-Dec-2022",
    logo: "https://logo.clearbit.com/bengroup.com",
    locations: "Los Angeles,CA; New York, NY; Provo, UT; London, UK; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Sales, Marketing, Operations",
    link: "https://ben.productplacement.com/careers/",
  },
  {
    company: "Benevity",
    lastChecked: "22-Nov-2022",
    logo: "https://i.imgur.com/2f8YaAs.png",
    locations: "Remote Canada; Barcelona, Spain",
    role: "Software Engineer, Site Reliability Engineer",
    link: "https://www.linkedin.com/company/benevity/jobs/",
  },
  {
    company: "BESTSELLER",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/bestseller.com",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer, Development Manager, Product Owner",
    link: "https://jobs.bestseller.com",
  },
  {
    company: "BetFanatics",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/wJu0IoH.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Operations",
    link: "https://jobs.lever.co/fanatics?department=Fanatics%20Betting%20%26%20Gaming",
  },
  {
    company: "BetterUp",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/betterup.com",
    locations:
      "Remote US; Canada; Mexico; London, United Kingdom; Amsterdam, Netherlands",
    role: "Software Engineer, Data Engineer, Engineering Manger",
    link: "https://www.betterup.com/about-us/careers",
  },
  {
    company: "Beyond",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/beyondpricing.com",
    locations: "Latin America; Canada",
    role: "Engineering Manager, Software Engineer, Senior DevOps Engineer",
    link: "https://www.beyondpricing.com/about/careers",
  },
  {
    company: "BigData Republic",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/wxXnEYr.png",
    locations: "Netherlands",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.bigdatarepublic.nl/join-us/ ",
  },
  {
    company: "Bill.com",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/5oiW6Dh.png",
    locations: "San Jose, CA; Houston, TX; Draper, UT",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://www.bill.com/about-us/jobs ",
  },
  {
    company: "Billie",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Billie.io",
    locations: "Berlin, Germany; Remote EMEA",
    role: "Engineering Manager, Software Engineer, Compliance Manager",
    link: "https://www.billie.io/en/jobs",
  },
  {
    company: "Bilue",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/tKiYXOk.png",
    locations:
      "Metro Manilla, Philippines; Sydney, Australia; Melbourne, Australia",
    role: "Software Engineer",
    link: "https://www.linkedin.com/company/bilue/",
  },
  {
    company: "Bird Buddy",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/undefined.png",
    locations: "Europe; United Kingdom",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://mybirdbuddy.com/pages/careers",
  },
  {
    company: "Bitly",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/bit.ly",
    locations: "San Francisco, CA; New York, NY; Remote; Denver, CO",
    role: "Software Engineer, Site Reliability / DevOps, Product Designer, Finance / Accounting, Human Resources, Recruiter, Marketing, Operations",
    link: "https://bitly.com/pages/careers",
  },
  {
    company: "Bitpanda",
    lastChecked: "21-Nov-2022",
    logo: "https://i.imgur.com/tu8vEwP.png",
    locations: "Vienna, Austria",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.bitpanda.com/en/career",
  },
  {
    company: "Bitrise",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Bitrise.io",
    locations: "Remote EU",
    role: "Engineering Manager",
    link: "https://jobs.lever.co/bitrise/4c460d48-17bb-4f20-a36d-b2b9ef90fdab",
  },
  {
    company: "Bizcuit",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/Hq6OK6I.png",
    locations: "Utrecht, Netherlands",
    role: "Software Engineer, QA Engineer, Software Engineering Manager",
    link: "https://www.bizcuit.nl/jobs/ ",
  },
  {
    company: "BlaBlaCar",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/blablacar.com",
    locations: "Paris, France; Remote France",
    role: "Software Engineer, Data Engineer, Security Engineer",
    link: "https://jobs.lever.co/blablacar?lever-via=ziozcUUhX3",
  },
  {
    company: "BlackBerry ",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/blackberry.com",
    locations: "Cary, NC; Canada",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps",
    link: "https://www.blackberry.com/us/en/company/careers",
  },
  {
    company: "BlackRock",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/blackrock.com",
    locations: "San Francisco, CA; Atlanta, GA",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://blackrock.wd1.myworkdayjobs.com/en-US/BlackRock_Professional",
  },
  {
    company: "Blend360",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/blend360.com",
    locations: "Denver, CO; Columbia, MD; New York, NY; Remote",
    role: "Data Scientist, Sales, Data Engineer|Business Intelligence",
    link: "https://www.blend360.com/data-science-solutions/careers",
  },
  {
    company: "Blendle",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/TS5FRfq.png",
    locations: "Europe",
    role: "Software Engineer, Editor",
    link: "https://www.linkedin.com/jobs/blendle-jobs-worldwide/?currentJobId=3375651943",
  },
  {
    company: "Blockworks",
    lastChecked: "09-Jan-2023",
    logo: "https://logo.clearbit.com/blockworks.co",
    locations: "Remote; New York, NY",
    role: "Software Engineer, Data Scientist",
    link: "https://angel.co/company/blockworks-group/jobs",
  },
  {
    company: "Bloom & Wild",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/bloomandwild.com",
    locations: "Remote UK",
    role: "Engineering Manager, Software Engineer",
    link: "https://jobs.lever.co/bloomon",
  },
  {
    company: "Bluegreen Vacations",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/bluegreenvacations.com",
    locations: "Boca Raton, FL",
    role: "Finance / Accounting, Human Resources, Sales, Marketing, Operations, Payroll",
    link: "https://jobs.bluegreenvacations.com/",
  },
  {
    company: "Bluesky",
    lastChecked: "18-Nov-2022",
    logo: "https://i.imgur.com/6x46HVm.png",
    locations: "Remote",
    role: "Software Engineer, DevOps Engineer",
    link: "https://jobs.ashbyhq.com/Bluesky",
  },
  {
    company: "BMO",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/bmo.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://jobs.bmo.com/ca/en",
  },
  {
    company: "BMW Group",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/SxjFfz0.png",
    locations: "Germany",
    role: "Data Engineer, Data Scientist",
    link: "https://www.bmwgroup.com/en/careers.html",
  },
  {
    company: "Bobsled",
    lastChecked: "11-Nov-2022",
    logo: "https://imgur.com/uvxIPoz.png",
    locations: "Europe; US East Coast; South America; Remote",
    role: "Software Engineering Manager, Software Engineer",
    link: "https://www.bobsled.co/careers",
  },
  {
    company: "Bondora",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Bondora.com",
    locations: "Tallinn, Estonia; Tartu, Estonia",
    role: "Software Engineer, Engineering Manager, Machine Learning Engineer",
    link: "https://boards.eu.greenhouse.io/bondora/jobs/4080609101",
  },
  {
    company: "Booking.com",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/8UJXxtZ.png",
    locations: "Amsterdam, Netherlsnds; London, UK; Manchester, UK; India",
    role: "Software Engineer, Software Engineering Manager, Data Engineer, Product Manager",
    link: "https://jobs.booking.com/careers",
  },
  {
    company: "Bounce",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/usebounce.com ",
    locations: "Lisbon, Portugal",
    role: "Product Manager, Product Designer",
    link: "https://usebounce.com/careers",
  },
  {
    company: "Box",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/box.com",
    locations:
      "Remote US; San Francisco, CA; New York, NY; Chicago, IL; Austin, TX",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Sales, Marketing, Security, IT Systems, Compliance, Customer Success",
    link: "https://careers.box.com/us/en/",
  },
  {
    company: "Boxed",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/boxed.com",
    locations: "New York, NY; Remote",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/boxedwholesale",
  },
  {
    company: "Brad's Deals",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/bradsdeals.com",
    locations: "Chicago, IL; Remote",
    role: "Software Engineer, Product Manager, Product Designer, Recruiter, Sales, Marketing",
    link: "https://www.bradsdeals.com/about-us/careers#positions",
  },
  {
    company: "Brainly",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Brainly.com",
    locations: "Remote Poland; Remote Spain",
    role: "Software Engineer",
    link: "https://careers.brainly.com/open-positions",
  },
  {
    company: "Branch",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/branchapp.com",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Marketing, Operations",
    link: "https://boards.greenhouse.io/branch",
  },
  {
    company: "Bree",
    lastChecked: "13-Jan-2023",
    logo: "https://logo.clearbit.com/trybree.com",
    locations: "Vancouver, Canada; San Francisco, CA; Remote",
    role: "Software Engineer",
    link: "",
    comments: "adam@trybree.com",
  },
  {
    company: "Breeze",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/RHXdbVM.png",
    locations: "Tel Aviv, Israel; London, UK",
    role: "Software Engineer",
    link: "https://www.breezeai.com/contact-us",
  },
  {
    company: "Breezeway.io",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Breezeway.io",
    locations: "Remote",
    role: "Product Designer, Software Engineer",
    link: "https://www.breezeway.io/careers",
  },
  {
    company: "Brightflag",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/brightflag.com",
    locations: "Dublin; Ireland",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/brightflag/jobs/6323673002",
  },
  {
    company: "Brooklyn Investment Group",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/TKRVvS2.png",
    locations: "New York, NY; Remote",
    role: "Software Engineer",
    link: "https://jobs.lever.co/bkln ",
  },
  {
    company: "BukuWarung",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/bukuwarung.com",
    locations: "Indonesia",
    role: "Product Designer, Sales, Operations",
    link: "https://jobs.lever.co/bukuwarung",
  },
  {
    company: "Bumble",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/bumble.com",
    locations: "Remote UK; Remote Spain",
    role: "Software Engineer, Data Scientist, Data Analyst, Product Manager",
    link: "https://www.linkedin.com/company/bumble/jobs/",
  },
  {
    company: "Bumpa",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/BkaEVpD.png",
    locations: "Remote",
    role: "Software Engineering Manager",
    link: "https://getbumpa.com/explore/career",
  },
  {
    company: "Busbud",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/WzvqqLY.png",
    locations: "Noth America; Western Europe",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.busbud.com/en-ca/careers",
  },
  {
    company: "BuzzFeed",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/BuzzFeed.com",
    locations: "Remote US; Remote UK",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/buzzfeed/jobs/4312435?gh_jid=4312435",
  },
  {
    company: "Bynder",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Bynder.com",
    locations: "Remote US",
    role: "Data Engineers, Software Engineer",
    link: "https://careers.bynder.com/",
  },
  {
    company: "ByteDance",
    lastChecked: "28-Dec-2022",
    logo: "https://logo.clearbit.com/bytedance.com",
    locations:
      "Los Angeles, CA; San Francisco, CA; Austin, TX; New York, NY; Seattle, WA; Singapore; London, UK",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Sales, Marketing, Operations",
    link: "https://job.toutiao.com/s/jP1KNfy",
    comments: "",
    geos: "",
  },
  {
    company: "C3.ai",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/c3.ai",
    locations:
      "Redwood City, CA; Tysons, VA; London, UK; Sydney, Australia; Guadalajara, Mexico; New York City, NY",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Sales",
    link: "https://c3.ai/careers/",
    comments: "matt.lafleur@c3.ai",
  },
  {
    company: "Cabify",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/tIG2UYR.png",
    locations: "Remote",
    role: "Software Engineering Manager, Software Engineer, Head of Engineering, Data Engineer, Site Reliability / DevOps",
    link: "https://cabify.careers/en/jobs?search=&department=Engineering",
  },
  {
    company: "Caden",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/caden.io",
    locations: "New York, NY",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Product Designer, Sales, Operations",
    link: "https://jobs.caden.io",
  },
  {
    company: "Calendly",
    lastChecked: "07-Dec-2022",
    logo: "https://logo.clearbit.com/calendly.com",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Marketing",
    link: "https://careers.calendly.com/jobs/",
  },
  {
    company: "Canonical",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/9mPiV94.png",
    locations: "Worldwide; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Product Manager, Finance / Accounting, Sales",
    link: "https://boards.greenhouse.io/canonical?gh_src=5d948ffe1us",
  },
  {
    company: "Canva",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/canva.com",
    locations: "Australia; New Zealand",
    role: "Software Engineer",
    link: "canva.com/careers",
  },
  {
    company: "Cardo AI",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/cardoai.com",
    locations: "Europe; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Operations, Data Engineer",
    link: "https://cardoai.com/talent/",
  },
  {
    company: "Careem",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/careem.com",
    locations: "Dubai; Germany",
    role: "Product Manager, Software Engineer, Data Scientist",
    link: "https://jobs.careem.com/?division=Engineering",
  },
  {
    company: "Cargomatic",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Cargomatic.com",
    locations:
      "San Francisco, CA; Los Angeles, CA; Long Beach, CA; New York, NY",
    role: "Product Manager, Software Engineer, Data Scientist, Product Marketing Lead, Data Analytics",
    link: "https://cargomaticinc.applytojob.com/apply",
  },
  {
    company: "Caribou Wealth",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/caribouwealth.com",
    locations: "Canada; United States",
    role: "Software Engineer",
    link: "https://caribouwealth.notion.site/Careers-at-Caribou-357783b6007f4d15bae8c7b9651cebf5",
  },
  {
    company: "Casavo",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/AJIaQy1.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Data Engineer, Data Scientist",
    link: "https://www.linkedin.com/jobs/search/?currentJobId=3326735144&f_C=27092849&geoId=92000000",
  },
  {
    company: "Cash App",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Cash.App",
    locations: "Remote UK",
    role: "Software Engineering Manager",
    link: "https://jobs.smartrecruiters.com/Square/743999850378764-software-engineering-manager-global-europe-",
  },
  {
    company: "Catawiki",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/https:/www.catawiki.com/",
    locations:
      "Netherlands; France; Germany; Spain; United Kingdom; Belgium; Italy",
    role: "Software Engineer",
    link: "https://catawiki.careers/vacancies?o=0&n=10&of=47810&f=194#vacancy-overview",
  },
  {
    company: "CAVU",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/cgFbjKq.png",
    locations: "United Kingdom",
    role: "Software Engineer",
    link: "https://careers.magairports.com/search/?createNewAlert=false&q=&locationsearch= ",
  },
  {
    company: "CBC/Radio-Canada",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/cbc.radio-canada.ca",
    locations: "Toronto, Canada",
    role: "Software Engineer",
    link: "https://cbc.taleo.net/careersection/2/jobdetail.ftl?job=TOR08744&tz=GMT-05%3A00&tzname=America%2FToronto",
  },
  {
    company: "Celonis",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/celonis.com",
    locations:
      "Remote; New York, NY; Los Angeles, CA; Munich, Germany; Madrid, Spain; London, United Kingdom",
    role: "Software Engineer, Product Manager, Business Analyst",
    link: "https://www.celonis.com/careers/jobs/",
  },
  {
    company: "Cerebras",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/xHy9lyO.png",
    locations: "Sunnyvale, CA; Toronto, Canada; San Diego, CA",
    role: "Research Scientist, Software Engineer, Product Manager, Marketing",
    link: "https://www.cerebras.net/careers/department/?department_id=all",
  },
  {
    company: "Chainalysis",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/chainalysis.com",
    locations:
      "London, United Kingdom; New York, NY; San Francisco, CA; Denmark; Germany; Spain; Portugal",
    role: "Engineering Manager, Software Engineer, Data Engineer, Data Scientist",
    link: "https://www.chainalysis.com/careers/",
  },
  {
    company: "ChannelEngine",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/UL9h7aB.png",
    locations: "Netherlands; Singapore; Toronto, Canada",
    role: "Software Engineer",
    link: "https://jobs.channelengine.com/",
  },
  {
    company: "Chargebee",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/chargebee.com",
    locations: "Remote US",
    role: "Site Reliability / DevOps, Software Engineer",
    link: "https://careers.chargebee.com/jobs",
  },
  {
    company: "ChargePoint",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/a6xDQSp.png",
    locations: "Campbell, CA; Europe; India",
    role: "Software Engineer",
    link: "https://www.chargepoint.com/about/opportunities ",
  },
  {
    company: "Chartbeat",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/4JEEXzL.png",
    locations: "Remote",
    role: "Data Engineer, Site Reliability / DevOps, Software Engineering Manager",
    link: "https://chartbeat.com/careers/ ",
  },
  {
    company: "Chattermill",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Chattermill.com",
    locations: "London, United Kingdom; Remote Europe",
    role: "Engineering Manager, Software Engineer, Product Manager",
    link: "https://chattermill.com/careers/",
  },
  {
    company: "Checkout.com",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/P3zHv1X.png",
    locations: "United Kingdom; Portugal; Lithuania",
    role: "Software Engineering Manager, Software Engineer, Data Engineer, Data Scientist",
    link: "https://www.checkout.com/careers?team=Technology ",
  },
  {
    company: "Checkr",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/checkr.com",
    locations: "Remote US; Remote Canada",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Designer, Finance / Accounting, Human Resources, Sales, Marketing",
    link: "https://checkr.com/company/careers/open-careers",
  },
  {
    company: "Chick-fil-A",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/VZkJrMZ.png",
    locations: "Atlanta, GA",
    role: "Software Engineering Manager, Enterprise Architect, Cybersecurity Engineer, ML Engineer, Software Engineer",
    link: "https://careers-chickfila.icims.com/jobs",
  },
  {
    company: "Chronograph",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/chronograph.pe",
    locations: "Brooklyn, NY",
    role: "Data Analyst, Technical Recruiter, Product Manager, Software Engineer",
    link: "https://apply.workable.com/chronograph/#jobs",
  },
  {
    company: "Chronosphere",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/chronosphere.io",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Finance / Accounting, Human Resources, Sales",
    link: "https://chronosphere.io/careers/?cn-reloaded=1#open-roles",
  },
  {
    company: "Cinesite",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/cinesite.com",
    locations: "London, United Kingdom; Montreal, Canada; Vancouver, Canada",
    role: "Pipeline Developer, Software Engineer",
    link: "https://www.cinesite.com/job-vacancies/",
  },
  {
    company: "Circl Technologies",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/hZrFtAR.png",
    locations: "Remote Europe",
    role: "Software Engineer, Data Scientist, ML Engineer, Operarions Support Partner, Product Designer, Site Reliability / DevOps",
    link: "https://www.circl.solutions/careers ",
  },
  {
    company: "Cision",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/BBN6Z0q.png",
    locations:
      "Budapest, Hungary; Paris, France; Frankfurt, Germany; Shanghai, China; United States; Remote",
    role: "Software Engineer",
    link: "https://www.cision.com/careers/ ",
  },
  {
    company: "Citizen",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/O0tsC39.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/citizen",
  },
  {
    company: "CKSource",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/nVB632X.png",
    locations: "Poland",
    role: "Software Engineer, Engineering Manager, Product Designer, Tech Content Writer",
    link: "https://cksource.com/careers ",
  },
  {
    company: "Clari",
    lastChecked: "08-Feb-2023",
    logo: "https://logo.clearbit.com/clari.com",
    locations: "India",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Senior Test Engineer",
    link: "https://jobs.lever.co/clari/",
    comments: "",
    geos: "",
  },
  {
    company: "Clarisights",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/clarisights.com",
    locations: "Bengaluru, India",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://careers.clarisights.com/",
  },
  {
    company: "Clarity Technologies",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/youublA.png",
    locations: "Remote; Australia; New Zealand",
    role: "Software Engineer",
    link: "",
    comments: "barry@getclarity.co.nz",
  },
  {
    company: "CLEAR",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/clearme.com",
    locations: "New York, NY; Austin, TX",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Sales, Marketing",
    link: "https://boards.greenhouse.io/clear",
  },
  {
    company: "Cleo",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/zQqzRJ7.png",
    locations: "London, UK; Remote UK",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://web.meetcleo.com/careers",
  },
  {
    company: "ClickHouse",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/SpYUCIJ.png",
    locations: "EMEA; United States",
    role: "Software Engineer, Data Engineer",
    link: "https://clickhouse.com/company/careers",
  },
  {
    company: "CloseFactor",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/closefactor.com",
    locations: "Remote US; Canada",
    role: "Software Engineer, Sales",
    link: "https://closefactor.com/company/careers/",
  },
  {
    company: "Clover Health",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/fxIXXe6.png",
    locations: "Remote US; Remote - Canada; Mexico,; Hong Kong, China",
    role: "Finance / Accounting, Product Analyst, Data Engineer, Data Scientist, ML Engineer, Product Designer, Software Engineering Manager, Software Engineer, Site Reliability / DevOps, Product Manager",
    link: "https://www.cloverhealth.com/en/about-us/current-openings",
  },
  {
    company: "CluePoints",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/cluepoints.com",
    locations: "Belgium",
    role: "Software Engineer, Data Analyst",
    link: "https://odoo.cluepoints.com/jobs",
  },
  {
    company: "Coalition",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/coalitioninc.com",
    locations: "United States; Canada,; United Kingdom",
    role: "Software Engineer, Engineering Manager, Technical Program Manager",
    link: "https://www.coalitioninc.com/careers",
  },
  {
    company: "Cocoon",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/meetcocoon.com",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Recruiter, Marketing, Customer Success",
    link: "https://www.meetcocoon.com/jobs",
  },
  {
    company: "Code Climate",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/codeclimate.com",
    locations: "New York, NY",
    role: "Software Engineer, Data Scientist",
    link: "https://codeclimate.com/careers",
  },
  {
    company: "CodeParva Technologies",
    lastChecked: "10-Nov-2022",
    logo: "https://imgur.com/mfSG6A0.png",
    locations: "Bangalore, India",
    role: "Software Engineer, Software Engineering Manager, Recruiter",
    link: "https://cutshort.io/company/codeparva-technologies-pvt-ltd-t0cDhxZW",
  },
  {
    company: "Codeproof Technologies",
    lastChecked: "14-Dec-2022",
    logo: "https://logo.clearbit.com/codeproof.com",
    locations: "Remote",
    role: "Software Engineer, Marketing",
    link: "https://www.codeproof.com/",
    comments: "jobs@codeproof.com",
  },
  {
    company: "Codewise",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/sRGBv38.png",
    locations: "Krakow, Poland; Remote - Poland",
    role: "Software Engineer",
    link: "https://codewise.com/careers/",
  },
  {
    company: "Codility",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/DWTHHj2.png",
    locations: "Berlin, Germany; London, Uk; Warsaw, Poland",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps",
    link: "https://www.codility.com/about-us/#careers",
  },
  {
    company: "Coditation Systems",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/coditation.com",
    locations: "India",
    role: "Data Scientist, Data Analyst, Software Engineer",
    link: "https://coditation.kekahire.com",
  },
  {
    company: "Coefficient.io",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/coefficient.io",
    locations: "Remote; San Francisco, CA; Bangalore, India; Hyderabad, India",
    role: "Software Engineer, Product Manager, Sales, Marketing",
    link: "https://coefficient.io/about#careers",
  },
  {
    company: "CoinDCX",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/coindcx.com",
    locations: "Remote",
    role: "Software Engineer, Product Designer, Business Analyst",
    link: "https://careers.coindcx.com/",
  },
  {
    company: "CollabSoft",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/collabsoft.net",
    locations: "The Hague, Netherlands",
    role: "Software Engineer",
    link: "https://jobs.collabsoft.net",
  },
  {
    company: "College Board",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/collegeboard.org",
    locations: "Remote US",
    role: "Software Engineer, Cloud Security Engineers",
    link: "https://careers.collegeboard.org/",
  },
  {
    company: "Column Tax",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/columntax.com",
    locations: "San Francisco, CA; New York, NY; Remote",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://jobs.columntax.com/",
  },
  {
    company: "Comcast",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/comcast.com",
    locations: "Philadelphia, PA; Washington, DC; Centennial, CO; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Product Designer, Sales, Operations",
    link: "https://comcast.jibeapply.com/main/jobs",
  },
  {
    company: "Commercetools",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/commercetools.com",
    locations: "Remote LATAM; Remote US",
    role: "Software Engineer",
    link: "https://commercetools.com/careers",
  },
  {
    company: "CommonLit",
    lastChecked: "12-Nov-2022",
    logo: "https://logo.clearbit.com/commonlit.org",
    locations: "Washington, DC; Remote US",
    role: "Software Engineer",
    link: "https://jobs.lever.co/commonlit",
  },
  {
    company: "Compass",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/compass.com",
    locations: "India",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://www.compass.com/careers/",
  },
  {
    company: "ComplyAdvantage",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/ComplyAdvantage.com",
    locations: "London, United Kingdom; Cluj-Napoca, Romania; Singapore",
    role: "Software Engineer, Data/ML Engineer, Engineering Manager",
    link: "https://complyadvantage.com/careers/jobs/",
  },
  {
    company: "Composer",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/cjmq8Qx.png",
    locations: "United States; Canada",
    role: "Software Engineer, UX Developer",
    link: "https://www.notion.so/investcomposer/Careers-at-Composer-c075d377c74741c5ac69db98ea7bc6b0",
  },
  {
    company: "Concentric by Ginkgo",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/ginkgobioworks.com",
    locations: "Remote US",
    role: "Data Engineer",
    link: "https://www.ginkgobioworks.com/careers/",
  },
  {
    company: "Concord Labs",
    lastChecked: "23-Nov-2022",
    logo: "https://i.imgur.com/7pYuHRS.png",
    locations: "Remote US",
    role: "Software Engineer, Lead Product Designer",
    link: "https://boards.greenhouse.io/concordlabs",
  },
  {
    company: "Confluent",
    lastChecked: "09-Jan-2023",
    logo: "https://logo.clearbit.com/confluent.io",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Sales, Marketing",
    link: "https://careers.confluent.io/",
    comments: "",
    geos: "",
  },
  {
    company: "connectRN",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/connectrn.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.connectrn.com/careers",
  },
  {
    company: "Container Solutions",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/q4doe2J.png",
    locations:
      "London, Uk; Berlin, Germany; Montreal, Canada; Toronto, Canada; Amsterdam, Netherlands",
    role: "Software Engineer, Software Engineering Manager, Cloud Architect",
    link: "https://www.container-solutions.com/careers",
  },
  {
    company: "Contentsquare",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Contentsquare.com",
    locations: "France; Germany; Spain",
    role: "Software Engineer",
    link: "https://contentsquare.com/jobs/",
  },
  {
    company: "Cord",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/cord.com",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://cord.com/jobs/",
  },
  {
    company: "Cortex ",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/cortex.io",
    locations: "Remote",
    role: "Software Engineer, Sales, Marketing, Operations",
    link: "https://www.cortex.io/careers",
  },
  {
    company: "Couchbase",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/couchbase.com",
    locations: "United States; United Kingdom; Canada; India",
    role: "Software Engineer, Product Manager, Security Engineer",
    link: "https://www.couchbase.com/careers/open-positions",
  },
  {
    company: "Craft",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/SpHnMD0.png",
    locations: "Hungary; Remote - Europe",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.craft.do/careers",
  },
  {
    company: "Cribl",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/QIUs15k.png",
    locations: "United States; Canada",
    role: "Software Engineer, Site Reliability / DevOps, Operations, Customer Support, Sales",
    link: "https://cribl.io/jobs/",
  },
  {
    company: "Crisp",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/jQnJ92B.png",
    locations: "Amsterdam, Netherlands; Barcelona, Spain",
    role: "Software Engineer, Data Scientist",
    link: "https://crisp.jobs/",
  },
  {
    company: "Criteo",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/criteo.com",
    locations: "Remote EU; France; Barcelona, Spain; Ann Arbour, MI",
    role: "Software Engineer, Site Reliability / DevOps, Program Manager, Engineering Manager",
    link: "https://careers.criteo.com/working-in-R&D",
  },
  {
    company: "Cruise",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/getcruise.com",
    locations: "San Francisco, CA; Seattle, WA; Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://getcruise.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Cruise Automation",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/getcruise.com",
    locations: "San Francisco, CA; Seattle, WA; Remote",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://getcruise.com/careers/jobs/",
  },
  {
    company: "Culture Amp",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/cultureamp.com",
    locations: "Australia; New Zealand; U.S West Coast",
    role: "Software Engineer",
    link: "https://www.cultureamp.com/company/careers#open-roles",
  },
  {
    company: "Curative",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/curative.com",
    locations: "Remote",
    role: "Site Reliability / DevOps, Product Manager, Product Designer",
    link: "https://app.trinethire.com/companies/130621-curative-inc/jobs",
  },
  {
    company: "Cure",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/HI70pwq.png",
    locations: "Berlin, Germany",
    role: "Software Engineer, Data Engineer, QA Engineer",
    link: "https://curegroupgmbh.recruitee.com/",
  },
  {
    company: "Current Health",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/currenthealth.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.currenthealth.com/careers/",
  },
  {
    company: "Cutover",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/cutover.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.cutover.com/careers",
  },
  {
    company: "CVS Health",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/wZmcVEW.png",
    locations: "United States; Remote",
    role: "Software Engineer, Product Manager, Data Scientist, Product Designer",
    link: "https://jobs.cvshealth.com/",
    comments: "",
    geos: "",
  },
  {
    company: "Cyberhaven",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/UXlOCs7.png",
    locations: "Europe",
    role: "Software Engineer",
    link: "https://www.cyberhaven.com/join-us/?ashby_jid=2c70c461-7bd8-4544-a0fe-ffbd4a723179",
  },
  {
    company: "Cyperus Group",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/cyperusgroup.com",
    locations: "New York, NY; Jersey City, NJ; Nashville, TN; Remote",
    role: "Finance / Accounting",
    link: "https://www.linkedin.com/company/cyperus-group-inc/jobs/",
  },
  {
    company: "Daedalus",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/jJfc0gK.png",
    locations: "Remote US; Remote Europe",
    role: "Software Engineer",
    link: "https://jobs.lever.co/daedalus ",
  },
  {
    company: "Dare",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/dare.global",
    locations: "London, United Kingdom",
    role: "Software Engineer, Product Manager",
    link: "https://careers.dare.global",
  },
  {
    company: "Databricks",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/databricks.com",
    locations:
      "London, United Kingdom; New York, NY; Bengaluru, India; San Francisco, CA; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Security Engineer",
    link: "https://www.databricks.com/company/careers/open-positions",
  },
  {
    company: "DataGuard",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/N9lrbx8.png",
    locations: "Munich, Germany; Berlin, Germany; London, UK",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.dataguard.de/karriere",
  },
  {
    company: "Datapao",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/datapao.com",
    locations: "Central Europe",
    role: "Data Engineer",
    link: "http://datapao.com/careers/",
  },
  {
    company: "DataRobot",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/DataRobot.com",
    locations:
      "Remote Denmark; Remote Germany; Remote Ireland; Remote Ukraine; Remote Poland",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.datarobot.com/careers/?department=engineering",
  },
  {
    company: "De Bijenkorf",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/ppe5uQv.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer, Architect, Site Reliability / DevOps",
    link: "https://werkenbijdebijenkorf.nl/digitalproduct-technology",
  },
  {
    company: "Decathlon",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/cIs6xYo.png",
    locations: "Worldwide",
    role: "Software Engineer, Data Engineer, Data Analyst",
    link: "https://joinus.decathlon.fr/fr/annonces ",
  },
  {
    company: "DeepL",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/pRCIeVm.png",
    locations: "Germany; Netherlands; United Kingdom; Remote Poland",
    role: "Software Engineer, Data Engineers, Product Manager",
    link: "https://jobs.deepl.com/",
  },
  {
    company: "Deliveroo",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/deliveroo.com",
    locations: "India; United Kingdom",
    role: "Software Engineer, Data Analyst, Engineering Manager",
    link: "https://careers.deliveroo.co.uk/en?query=&team=technology&country=all&remote=true#filter-careers",
  },
  {
    company: "Dept Holding",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/IyNtBdL.png",
    locations: "Amsterdam, Netherlands; United States; Germany; United Kingdom",
    role: "Software Engineering Manager",
    link: "https://www.deptagency.com/nl-nl/careers/",
  },
  {
    company: "Descript",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/descript.com",
    locations: "United States; Canada",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Marketing",
    link: "https://www.descript.com/careers",
  },
  {
    company: "Deutsche Bahn",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/DeutscheBahn.com",
    locations: "Germany",
    role: "Software Engineer",
    link: "https://db.jobs/en-en",
  },
  {
    company: "DevOn",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/toN3lnT.png",
    locations: "Netherlands; India; Remote",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://careers.devon.global/vacancies/",
  },
  {
    company: "DFINITY",
    lastChecked: "07-Dec-2022",
    logo: "https://logo.clearbit.com/dfinity.org",
    locations: "San Francisco, CA; Zurich, Switzerland; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Designer, Finance / Accounting, Operations, Developer Relations Engineer",
    link: "https://grnh.se/ceaae0dc2us",
  },
  {
    company: "DHL",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/dpdhl.com",
    locations:
      "Berlin, Germany; Bonn, Germany; Remote Germany; Prague, Czech Republic",
    role: "Software Engineer, Data Scientist, Data/ML Engineer",
    link: "https://careers.dhl.com/global/en/datascience",
  },
  {
    company: "DHL",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/dhl.com",
    locations: "Utrecht, Netherlands",
    role: "Software Engineer, DevOps Engineer, Technical Product Manager",
    link: "https://careers.dhl.com/",
  },
  {
    company: "Digital Envoy",
    lastChecked: "09-Dec-2022",
    logo: "https://imgur.com/QK1lMP3.png",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Sales",
    link: "https://digitalenvoy.applytojob.com/apply",
  },
  {
    company: "digital.ai",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/6kY2dRn.png",
    locations: "United States; Bengaluru, India; Chennai, India",
    role: "Security Engineer, Solutions Architect, Software Engineer",
    link: "https://digital.ai/why-digital-ai/careers/",
  },
  {
    company: "DigitalService Bund",
    lastChecked: "25-Nov-2022",
    logo: "https://imgur.com/mFvgFhx",
    locations: "Berlin, Germany",
    role: "Software Engineer, Solution Architect",
    link: "https://digitalservice.bund.de/en/career",
  },
  {
    company: "Diligent Robotics",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/diligentrobots.com",
    locations: "Austin, TX",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Sales, Operations",
    link: "https://boards.greenhouse.io/diligentrobotics",
  },
  {
    company: "DinMo",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/J6wapuu.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.linkedin.com/company/dinmo-io/",
  },
  {
    company: "Dish Network",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/dish.com",
    locations: "Bangalore, India; Denver, CO",
    role: "Data Scientist, Data Engineer, Data Analyst",
    link: "https://careers.dish.com/",
    comments: "syedmisbah.ahmed@dish.com",
  },
  {
    company: "Divvy Homes",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/divvyhomes.com",
    locations: "Remote",
    role: "Software Engineering Manager, Site Reliability / DevOps, Product Designer, Marketing, Operations",
    link: "https://jobs.lever.co/divvyhomes",
  },
  {
    company: "Divvy Homes ",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/divvyhomes.com",
    locations: "Remote",
    role: "Software Engineering Manager, Site Reliability / DevOps",
    link: "https://jobs.lever.co/divvyhomes",
  },
  {
    company: "Docplanner",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Docplanner.com",
    locations: "Europe",
    role: "Engineering Managers, Software Engineer, Data Engineers",
    link: "https://docplanner.tech/careers",
  },
  {
    company: "Doctolib",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/b7y0SXR.png",
    locations: "Paris, France; Nantes, France; Berlin, Germany; Milan, Italy",
    role: "Software Engineer, Software Engineering Manager, Data Analyst, Data Scientist, Product Manager",
    link: "https://careers.doctolib.com/",
  },
  {
    company: "DoorDash",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/doordash.com",
    locations:
      "San Francisco, CA; Seattle, WA; New York, NY; Los Angeles, CA; Austin, TX; Atlanta, GA; Chicago, IL; Pittsburgh, PA; Washington, D.C.; Toronto, ON; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://careers.doordash.com/",
  },
  {
    company: "DoubleDown Interactive",
    lastChecked: "11-Nov-2022",
    logo: "https://imgur.com/jRGvjBU.png",
    locations: "Seattle, WA",
    role: "Product Manager, Finance / Accounting, Marketing, Customer Support & Relations",
    link: "https://www.doubledowninteractive.com/jobs/",
  },
  {
    company: "Doyensec",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/doyensec.com",
    locations: "United States",
    role: "Application Security Engineer",
    link: "https://www.careers-page.com/doyensec-llc",
  },
  {
    company: "DraftKings",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/DraftKings.com",
    locations:
      "Dublin, Ireland; London, United Kingdom; Sofia, Bulgaria; Plovdiv, Bulgaria; Tel Aviv, Israel",
    role: "Software Engineer, Engineering Manager, Data Scientist",
    link: "https://careers.draftkings.com/jobs/",
  },
  {
    company: "Dremio",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/lk3NGAg.png",
    locations: "United States; India; remote",
    role: "Software Engineer, Software Engineering Manager, Marketing",
    link: "https://www.dremio.com/careers/job-postings/",
  },
  {
    company: "DuckDuckGo",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/K25avy0.png",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Designer, Product Manager",
    link: "https://duckduckgo.com/hiring",
  },
  {
    company: "Duo Security",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/duosecurity.com",
    locations: "Remote; Ann Arbor, MI",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Customer Experience",
    link: "https://jobs.cisco.com/jobs/SearchJobs/%22Duo%20Security%22",
  },
  {
    company: "Duolingo",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/duolingo.com",
    locations:
      "Pittsburgh, PA; New York, NY; Seattle, WA; Beijing, China; Berlin, Germany",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Marketing, Operations",
    link: "https://careers.duolingo.com/",
  },
  {
    company: "Earnest",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/earnest.com",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager, Product Designer",
    link: "https://www.earnest.com/careers",
  },
  {
    company: "EasyBroker",
    lastChecked: "22-Nov-2022",
    logo: "https://i.imgur.com/DYPVDI9.png",
    locations: "Latin America",
    role: "Software Engineer, Engineering Manager",
    link: "https://ebwantsyou.com/",
  },
  {
    company: "eBay",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/a5xgoGy.png",
    locations: "Amsterdam, Netherlands",
    role: "Data Engineer",
    link: "https://www.linkedin.com/company/ebay/jobs",
  },
  {
    company: "Ebury",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/7Q2eYbq.png",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Software Engineering Manager",
    link: "https://careersdv.wpengine.com/jobs/",
  },
  {
    company: "ECP",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/FQOtQPu.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://apply.workable.com/ecp-123/j/B3BDBFCE3A/",
  },
  {
    company: "Efficy",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Efficy.com",
    locations: "France; Spain; Sweden",
    role: "Software Engineer, Project Manager",
    link: "https://jobs.efficy.com/",
  },
  {
    company: "EGYM",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/egym.de",
    locations: "Germany",
    role: "Software Engineer",
    link: "https://egym.jobs.personio.de/",
  },
  {
    company: "Einride",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/einride.tech",
    locations: "Stockholm, Sweden; Gothenburg, Sweden; Austin, TX",
    role: "Software Engineer, Data Engineer, Data Scientist, Engineering Manager",
    link: "https://www.einride.tech/careers/",
  },
  {
    company: "Elaborate",
    lastChecked: "20-Jan-2023",
    logo: "https://logo.clearbit.com/elaborate.com",
    locations: "New York, NY; Remote",
    role: "Software Engineer, Product Designer",
    link: "https://angel.co/company/weareelaborate/",
  },
  {
    company: "Elastic.co",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/elastic.co",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://www.elastic.co/about/careers/",
  },
  {
    company: "Electronic Arts",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/ea.com",
    locations: "United States, Canada",
    role: "Software Engineer, Engineering Manager, Product Manager",
    link: "https://ea.gr8people.com/jobs",
  },
  {
    company: "ELEMENT Insurance AG",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/element.in",
    locations: "Berlin, Germany",
    role: "Engineering Manager, Software Engineer",
    link: "https://elementinsuranceag.recruitee.com/",
  },
  {
    company: "Elroy Air",
    lastChecked: "14-Dec-2022",
    logo: "https://logo.clearbit.com/elroyair.com",
    locations: "San Francisco, CA",
    role: "Software Engineer, Operations, Program Manager",
    link: "elroyair.com/careers",
  },
  {
    company: "Elsevier",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/elsevier.com",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://relx.wd3.myworkdayjobs.com/en-US/ElsevierJobs?jobFamilyGroup=8c5fc5940f0b01ba8d012114be0035b9&locations=8c5fc5940f0b011f44029399b200b119",
  },
  {
    company: "Emis Group",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/diYxNqH.png",
    locations: "London, United Kingdom; Leeds, United Kingdom; Chennai, India",
    role: "Product Manager, Software Engineer",
    link: "https://emis.current-vacancies.com/Website/Search?txtKeyword=Emis",
  },
  {
    company: "Emnify",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Emnify.com",
    locations: "Berlin, Germany; Remote EMEA",
    role: "Software Engineer",
    link: "https://www.emnify.com/careers",
  },
  {
    company: "Empower Finance",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/empower.me",
    locations: "Remote Canada; Remote United Kingdom; Remote Australia",
    role: "Software Engineer, Data Scientist",
    link: "https://jobs.lever.co/empower.me",
  },
  {
    company: "Eneba",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/mkbqlzm.png",
    locations: "Europe",
    role: "Data Analyst, Data Engineer, Software Engineering Manager, Software Engineer",
    link: "https://jobs.eu.lever.co/eneba",
  },
  {
    company: "Energi People",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/N1PnktP.png",
    locations: "London, UK",
    role: "Data Engineer",
    link: "https://energipeople.com/jobs/lead-data-engineer-job-london-33988/",
  },
  {
    company: "Energy Exemplar",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/x7j9ozb.png",
    locations:
      "Pune, India; Bangalore, India; Salt Lake City, UT; Adelaide, Australia",
    role: "Software Engineer, Product Manager, Supportm, Sales, Data Analyst, Finance / Accounting",
    link: "https://www.energyexemplar.com/careers/",
  },
  {
    company: "Entain",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/entaincareers.com",
    locations:
      "Vienna, Austria; Sofia, Bulgaria; London, United Kingdom; Las Vegas, NV; Rome, Italy; Berlin, Germany; Malta",
    role: "Software Engineer, Product Manager, DevOps Engineer",
    link: "https://www.entaincareers.com/en/jobs/?Keywords=&IndustryID=34&IndustryID=26&VacancyTypeID=&Postcode=&radius=40&LocationID=&LocationID=#results",
  },
  {
    company: "Entrata",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/entrata.com",
    locations: "Salt Lake City, UT; Dallas, TX; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Finance / Accounting, Human Resources, Sales, Marketing, Operations, Legal",
    link: "https://jobs.lever.co/entrata",
  },
  {
    company: "Envoy",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/oNV9Ox6.png",
    locations: "San Francisco, CA; New York, NY",
    role: "Software Engineer, Software Engineering Manager, Data Analyst, Product Manager, Product Designer",
    link: "https://envoy.com/jobs/",
  },
  {
    company: "EOG Resources",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/eogresources.com",
    locations: "Houston,TX",
    role: "Software Engineer, Product Manager",
    link: "https://jobs.smartsearchonline.com/eogresources/jobs/process_jobsearch.asp#",
    comments: "Austin_Sharp@eogresources.com",
  },
  {
    company: "EPAM",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/epam.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.epam.com/careers",
  },
  {
    company: "Epidemic Sound",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/epidemicsound.com",
    locations: "Stockholm, Sweden",
    role: "Software Engineer, Data Engineer",
    link: "https://epidemic-sound.teamtailor.com/jobs",
  },
  {
    company: "EQS Group",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/bJHbsd8.png",
    locations: "Software Engineer",
    role: "Software Engineer, DevOps Engineer",
    link: "https://eqs-group.jobs.personio.de/?language=en#",
  },
  {
    company: "Equinix",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/eu.equinix.com",
    locations:
      "Los Angeles, CA; Redwood, CA; Frisco, TX; Toronto, Canada; Europe",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://careers.equinix.com/jobs/search?page=1&query=&category_uids%5B%5D=b12c3ddd7fea7906d611effddf307c3e&country_codes%5B%5D=US",
  },
  {
    company: "Ericsson ",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/ericsson.com",
    locations: "Remote; United States; Global",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://jobs.ericsson.com",
  },
  {
    company: "eSamudaay",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/esamudaay.com",
    locations: "Remote India",
    role: "Software Engineer",
    link: "https://angel.co/company/esamudaay/jobs/889441-senior-backend-engineer-platform",
  },
  {
    company: "ESL FACEIT Group",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/eslfaceitgroup.com",
    locations:
      "Sweden; Poland; Germany; Malaysia; Singapore; Denmark; Thailand; United States",
    role: "Software Engineer, Engineering Manager",
    link: "https://eslfaceitgroup.com/career/",
  },
  {
    company: "Event Store",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/gIQUwGQ.png",
    locations: "Global",
    role: "Community Manger, Software Engineer",
    link: "https://www.eventstore.com/careers ",
  },
  {
    company: "Everest",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/SrWpJ64.png",
    locations: "Brazil; London, UK; Heidelberg, Germany",
    role: "Software Engineer",
    link: "https://www.linkedin.com/company/everest-systems-inc/",
  },
  {
    company: "Everli",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/everli.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://jobs.lever.co/everli?department=Research%20%26%20Development&team=Engineering",
  },
  {
    company: "Eversports",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/eversportsmanager.com",
    locations: "Vienna, Austria",
    role: "Software Engineer",
    link: "https://www.eversportsmanager.com/de/jobs/?jobId=AaYwxv_u0Egc",
  },
  {
    company: "Evisort",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/evisort.com",
    locations: "San Francisco, CA; Remote",
    role: "Product Designer",
    link: "https://jobs.lever.co/evisort-2",
    comments: "",
    geos: "",
  },
  {
    company: "Exclaimer",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Exclaimer.com",
    locations: "United Kingdom; Portugal; Spain; Poland; Czech Republic",
    role: "Software Engineer",
    link: "https://exclaimer.pinpointhq.com/",
  },
  {
    company: "Exos",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/teamexos.com",
    locations: "Remote US; Remote Canada",
    role: "Software Engineer, Data Engineer, Data Analyst",
    link: "https://www.teamexos.com/career/#workday",
  },
  {
    company: "Exscientia",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/exscientia.ai",
    locations: "Remote",
    role: "Software Engineer, Data Scientist",
    link: "https://www.exscientia.ai/careers",
  },
  {
    company: "Extra",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Extra.app",
    locations: "Remote US",
    role: "Principal DevOps Engineer, Software Engineer, Financial Data, Technical Project Manager, Director of Product Management",
    link: "https://extra.app/careers",
  },
  {
    company: "FairCom",
    lastChecked: "16-Dec-2022",
    logo: "https://logo.clearbit.com/faircom.com",
    locations: "Salt Lake City, UT; Columbia, MO",
    role: "Sales, Operations",
    link: "faircom.com/company/careers",
  },
  {
    company: "Fampay",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/WX6z34k.png",
    locations: "Bangaluru, India",
    role: "Software Engineer",
    link: "https://jobs.lever.co/fampay?lever-via=pwvynrZz_j",
  },
  {
    company: "FanDuel",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/SglRcN7.png",
    locations: "United States; United Kingdom; Remote",
    role: "Software Engineer, Security, Data Analyst, Product Manager, Quality Engineer, Site Reliability / DevOps",
    link: "https://www.fanduel.com/careers/",
  },
  {
    company: "Fareharbor",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/fareharbor.com",
    locations: "Amsterdam, Netherlands; Denver, CO",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Product Designer, Operations, Legal",
    link: "https://grnh.se/d73b84292us",
  },
  {
    company: "Fazz",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/fazz.com",
    locations: "Indonesia; Malaysia; Singapore; Vietnam; Taiwan; India",
    role: "Software Engineer, Engineering Manager",
    link: "https://jobs.lever.co/fazzfinancial?department=Technology",
  },
  {
    company: "Feedzai",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/tv5YWoa.png",
    locations:
      "Portugal; London, UK; Hong Kong, China; Sydney, Australia; Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://careers.feedzai.com/",
  },
  {
    company: "Felt",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/Rmv1WtG.png",
    locations: "United States; Remote",
    role: "Software Engineering Manager",
    link: "https://felt.com/careers",
  },
  {
    company: "Fever",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/zBKRWEb.png",
    locations: "Spain; Remote",
    role: "Software Engineering Manager, Software Engineer, Data Scientist, Data Engineer",
    link: "https://careers.feverup.com/ ",
  },
  {
    company: "Figma",
    lastChecked: "18-Nov-2022",
    logo: "https://i.imgur.com/bgbXLgf.png",
    locations: "San Francisco, CA; New York, NY; Remote",
    role: "Software Engineer, Product Manager, Product Designer, Data Scientist, Sales, Marketing, Recruiter",
    link: "https://www.figma.com/careers/#job-openings",
  },
  {
    company: "FindHotel",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/MNdyH3U.png",
    locations: "Europe; Middle East; Brazil; Argentina; Turkey; Africa",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://boards.eu.greenhouse.io/findhotel/jobs/4004576101?gh_jid=4004576101%20https://boards.eu.greenhouse.io/findhotel/jobs/4042501101?gh_jid=4042501101%20https://boards.eu.greenhouse.io/findhotel/jobs/4004552101?gh_jid=4004552101%20https://boards.eu.greenhouse.io/findhotel/jobs/4096337101?gh_jid=4096337101",
  },
  {
    company: "Findigs",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/findings.co",
    locations: "New York, NY",
    role: "Data Engineer, Engineer Manager, Software Engineer",
    link: "https://jobs.lever.co/findigs",
  },
  {
    company: "Fivetran",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Fivetran.com",
    locations: "Ireland; Netherlands; Serbia; Canada",
    role: "Software Engineer, Program Manager, Engineering Manager",
    link: "https://www.fivetran.com/careers",
  },
  {
    company: "Flawless",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/TzzHEL0.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.flawlessai.com/careers",
  },
  {
    company: "Flexciton",
    lastChecked: "28-Nov-2022",
    logo: "https://i.imgur.com/ev8d0AU.png",
    locations: "London, United Kingdom",
    role: "Engineering Manager, Software Engineer",
    link: "https://careers.flexciton.com/",
  },
  {
    company: "Flexport",
    lastChecked: "23-Jan-2023",
    logo: "https://logo.clearbit.com/flexport.com",
    locations: "Chicago, IL; San Francisco, CA; Seattle, WA",
    role: "Software Engineer, Software Engineering Manager, Data Scientist",
    link: "https://www.flexport.com/company/careers",
    comments: "mlipman@flexport.com",
  },
  {
    company: "Flexport",
    lastChecked: "20-Dec-2022",
    logo: "https://logo.clearbit.com/flexport.com",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.flexport.com/careers/jobs/?department=Engineering&location=Amsterdam",
    comments: "",
    geos: "",
  },
  {
    company: "Flink",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/mNtqX1z.png",
    locations: "Berlin, Germany; Amsterdam, Netherlands; Paris, France",
    role: "Software Engineer",
    link: "https://careers.smartrecruiters.com/Flink3/joinus?search=engineer",
  },
  {
    company: "Flix",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/flix.careers",
    locations: "Munich, Germany; Berlin, Germany",
    role: "Software Engineer",
    link: "https://flix.careers/",
  },
  {
    company: "Flo Health",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/dQZSQGp.png",
    locations: "Amsterdam, Netherlands",
    role: "Engineering Manager",
    link: "https://flo.health/careers-listing",
  },
  {
    company: "Fly.io",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/Fly.io",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://fly.io/jobs/",
  },
  {
    company: "FLYR labs",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/lJPuaWQ.png",
    locations: "Amsterdam, Netherlands; Krakow, Poland",
    role: "Software Engineer, Technical Product Owner, Data Scientist, Delivery Technical Lead",
    link: "https://jobs.lever.co/flyrlabs",
  },
  {
    company: "Form Energy",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/formenergy.com",
    locations: "Berkeley, CA; Pittsburgh, PA; Boston, MA",
    role: "Software Engineer, Hardware Engineer, Human Resources, Recruiter, Operations",
    link: "https://jobs.lever.co/formenergy",
  },
  {
    company: "Formitable",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/M9nZpnw.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer, QA Engineer, Product Manager",
    link: "https://jobs.formitable.com/ ",
  },
  {
    company: "Forward",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/goforward.com",
    locations: "San Francisco, CA",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://goforward.com/jobs#CareersOpeningsSection",
  },
  {
    company: "Founda Health",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/foundahealth.com",
    locations: "Remote; Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://www.foundahealth.com/careers",
  },
  {
    company: "Free Now",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/free-now.com",
    locations: "Hamburg, Germany; Berlin, Germany; Barcelona, Spain; Remote EU",
    role: "Software Engineer, Data Engineer, Director of Engineering",
    link: "https://www.free-now.com/de/career/jobs/",
  },
  {
    company: "Fresco",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/frescocooks.com",
    locations: "Dublin, Ireland; Madrid, Spain; Remote Europe",
    role: "Software Engineer",
    link: "https://frescocooks.com/careers",
  },
  {
    company: "Funding Circle",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/fundingcircle.com",
    locations: "London, United Kingdom; Remote United Kingdom",
    role: "Software Engineer, Data Engineer, Engineering Manager",
    link: "https://careers.fundingcircle.com/",
  },
  {
    company: "Fundrise",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/fundrise.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://jobs.lever.co/fundrise?lever-via=ax-O4i47_2",
  },
  {
    company: "G2",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/g2.com",
    locations: "Chicago, IL; Remote",
    role: "Software Engineer, Sales, Marketing",
    link: "https://company.g2.com/careers/open-positions",
  },
  {
    company: "Galileo health",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/NIU602i.png",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://galileo.io/careers",
  },
  {
    company: "Gamanza",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/k4dIyzH.png",
    locations: "Ljubljana, Slovenia; Koper, Slovenia; San Jose, Costa Rica",
    role: "Software Engineer",
    link: "https://gamanza.com/career-in-igaming/ ",
  },
  {
    company: "Gameberry Labs",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/y8WVrg6.png",
    locations: "Bangalore, India",
    role: "Software Engineer",
    link: "https://gameberrylabs.com/jobs ",
  },
  {
    company: "GAN Integrity",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/a86PcQq.png",
    locations: "Copenhagen, Denmark",
    role: "Tech Lead",
    link: "https://ganintegrity.bamboohr.com/jobs/ ",
  },
  {
    company: "Garden",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/TC9rthe.png",
    locations: "Berlin, Germany; Remote Germany; Remote US",
    role: "Software Engineer",
    link: "https://garden.io/careers",
  },
  {
    company: "Garner Health",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/getgarner.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Data Scientist, Sales",
    link: "https://www.getgarner.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Gentrack",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/gentrack.com",
    locations: "Remote India; United Kingdom; Australia; New Zealand",
    role: "Software Engineer",
    link: "https://gentrack.com/careers/#roles",
  },
  {
    company: "GetGo Technologies",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/SdNoDhm.png",
    locations: "Singapore",
    role: "Software Engineer, Quality Assurance Engineer, Data Scientist",
    link: "https://getgo.sg/careers/",
  },
  {
    company: "GetYourGuide",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/getyourguide.com",
    locations: "Berlin, Germany; Zürich, Switzerland",
    role: "Software Engineer",
    link: "https://careers.getyourguide.com/",
  },
  {
    company: "giffgaff",
    lastChecked: "30-Jan-2023",
    logo: "https://imgur.com/LklsDR8.png",
    locations: "Remote UK; London, UK",
    role: "Software Engineer, Software Engineering Manager, Data Scientist, Software Engineering Lead",
    link: "https://www.giffgaff.io/jobs",
  },
  {
    company: "GitLab",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/GitLab.com",
    locations: "Remote",
    role: "Software Engineer, Data Analyst, Engineering Manager",
    link: "https://about.gitlab.com/jobs/all-jobs/",
  },
  {
    company: "Glass",
    lastChecked: "08-Feb-2023",
    logo: "https://imgur.com/4a1Bh5W.png",
    locations: "Atlanta, GA; Chicago, IL",
    role: "Software Engineer, Product Manager, Research Directors / Managers",
    link: "https://apply.workable.com/glass-research/",
  },
  {
    company: "Glean Technologies",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/glean.com",
    locations: "Palo Alto, CA; Bengaluru, India; Remote",
    role: "Software Engineer, User Researcher, QA Engineer, Solutions Engineer, Sales Operations Manager",
    link: "https://boards.greenhouse.io/gleanwork",
  },
  {
    company: "Glia",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/NItOyxo.png",
    locations: "Estonia; Canada; Remote Europe",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://www.glia.com/jobs?gh_src=89ca91c24us",
  },
  {
    company: "Glovo",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Glovo.com",
    locations: "Barcelona, Spain; Madrid, Spain",
    role: "Software Engineer, Engineering Manager, Data Engineer, Data Scientist, Product Manager, UX Designer",
    link: "https://jobs.glovoapp.com/",
  },
  {
    company: "Glowforge",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/glowforge.com",
    locations: "Seattle, WA; Remote",
    role: "Software Engineer, Product Manager, Recruiter, Sales, Marketing",
    link: "https://glowforge.com/jobs",
  },
  {
    company: "Go City",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/gocity.com",
    locations: "London, United Kingdom",
    role: "Data Engineering Manager, Platform Engineer, Software Engineer",
    link: "https://apply.workable.com/go-city/",
  },
  {
    company: "GOAT Group",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/VteZoqx.png",
    locations: "Remote; Los Angeles, CA",
    role: "ML Engineer, Software Engineer, Data Engineer, Data Analyst",
    link: "https://boards.greenhouse.io/goatgroup",
  },
  {
    company: "GoCardless",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/LghvY3X.png",
    locations: "London, UK",
    role: "Software Engineer, Product Manager",
    link: "https://boards.greenhouse.io/gocardless ",
  },
  {
    company: "Golden Charter",
    lastChecked: "18-Nov-2022",
    logo: "https://i.imgur.com/pMHFASu.png",
    locations: "United Kingdom",
    role: "Software Engineer",
    link: "https://www.linkedin.com/company/golden-charter-ltd/jobs/",
  },
  {
    company: "Google",
    companyDomain: "google.com",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/google.com",
    locations: "San Francisco, CA; New York, NY; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Sales, Operations",
    link: "https://careers.google.com/",
  },
  {
    company: "GotPhoto",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/nd4h2VD.png",
    locations: "Germany; Spain; Portugal; Netherlands",
    role: "Software Engineer",
    link: "https://www.fotograf.de/jobs/ ",
  },
  {
    company: "goUrban",
    lastChecked: "21-Nov-2022",
    logo: "https://i.imgur.com/KpbvDIv.png",
    locations: "Vienna, Austria; Novi Sad, Servia; Banja Luka, Bosnia",
    role: "Software Engineer, Site Reliability / DevOps, Data Engineer",
    link: "https://gourban.co/career.html",
  },
  {
    company: "GrayMatter Robotics",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/graymatter-robotics.com",
    locations: "Los Angeles, CA",
    role: "Software Engineer, Hardware Engineer, Lead Robotics Software Engineer, Robotics Application Engineer, Robotics Deployment Manager",
    link: "https://boards.greenhouse.io/graymatterrobotics",
  },
  {
    company: "GrayQuest",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/wjUidkB.png",
    locations: "Mumbai, India",
    role: "Software Engineer, QA Engineer, Product Designer, Product Manager, Human Resources",
    link: "https://www.grayquest.com/contact ",
  },
  {
    company: "GROPYUS",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/GROPYUS.com",
    locations: "Germany",
    role: "Data Engineers, Software Engineer, Network Engineers",
    link: "https://www.linkedin.com/company/gropyus/jobs/",
  },
  {
    company: "Grow Therapy",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/QEeIFrE.png",
    locations: "New York, NY; Remote",
    role: "Software Engineer, Engineering Manager, Data Scientist, Senior Product Designer, Staff Visual Designer",
    link: "https://growtherapy.com/careers/",
  },
  {
    company: "Groww",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/groww.in",
    locations: "Bengaluru, India",
    role: "Software Engineer",
    link: "https://groww.in/careers",
  },
  {
    company: "Gruntwork",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/gruntwork.io",
    locations: "Remote US; Remote EU",
    role: "Software Engineer, Engineering Manager",
    link: "https://gruntwork.io/careers#open-positions",
  },
  {
    company: "GSK",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/gsk.com",
    locations: "San Francisco, CA; Seattle, WA; London, UK",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist",
    link: "https://www.gsk.ai/working-with-us/",
  },
  {
    company: "Guidewheel",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/guidewheel.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://angel.co/recruit/jobs/757252",
  },
  {
    company: "Hakuna",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/LGdaMVP.png",
    locations: "Remote; Berlin, Germany",
    role: "Software Engineer",
    link: "https://hakuna.jobs.personio.de/ ",
  },
  {
    company: "Happening",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/RuKY855.png",
    locations: "EMEA",
    role: "Software Engineer",
    link: "https://www.happening.xyz/careers",
  },
  {
    company: "Happiest Minds",
    lastChecked: "24-Jan-2023",
    logo: "https://imgur.com/QWTffhY.png",
    locations: "Austin,TX; Remote",
    role: "Software Engineer, Data Scientist",
    link: "https://careers.happiestminds.com",
  },
  {
    company: "Harness.io",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/harness.io",
    locations: "Mountain View, CA",
    role: "Product Manager",
    link: "https://www.harness.io/company/careers",
    comments: "pranay.kaikini@harness.io",
    geos: "",
  },
  {
    company: "Harver",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/harver.com",
    locations: "Netherlands",
    role: "Software Engineer, Data Engineer, Technical Product Manager",
    link: "https://harver.com/careers/",
  },
  {
    company: "Harvest",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/getharvest.com",
    locations: "Remote",
    role: "Data Engineer",
    link: "https://apply.workable.com/harvest/j/05163D82AA/",
  },
  {
    company: "HashiCorp",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/hashicorp.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Director, UX, Designer, Product Manager, Infrastructure Engineer, Security Engineer",
    link: "https://www.hashicorp.com/careers?ajs_aid=043bea08-ae4a-44ae-889e-c4c8348a4ce9",
  },
  {
    company: "Hedgehog Lab",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/hedgehoglab.com",
    locations: "United Kingdom; Bulgaria; Western Europe.",
    role: "Software Engineer",
    link: "https://hedgehoglab.com/about/careers",
  },
  {
    company: "HelloFresh",
    lastChecked: "20-Jan-2023",
    logo: "https://logo.clearbit.com/hellofresh.com",
    locations: "Berlin, Germany; Toronto, Canada; New York, NY",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Marketing, Operations",
    link: "https://careers.hellofresh.com/global/en/home",
    comments: "",
    geos: "",
  },
  {
    company: "HG Insights",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/hginsights.com",
    locations: "Santa Barbara, CA; Remote",
    role: "Big Data & DevOps",
    link: "https://hginsights.com/about/hg-insights-careers/",
  },
  {
    company: "Hightouch",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/hightouch.com",
    locations: "San Francisco, CA; New York, NY; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Finance / Accounting, Sales, Marketing",
    link: "https://hightouch.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Hike",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/lpSjlPq.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://hikeapp.notion.site/Work-at-Hike-e3b4884f45164bad8f67c10dae555ffd",
  },
  {
    company: "Hillenbrand",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/hillenbrand.com",
    locations: "Batesville, INIRemote",
    role: "Site Reliability / DevOps, Finance / Accounting, Human Resources, Operations, Global Compensation",
    link: "https://careers.hillenbrand.com/hillenbrand",
  },
  {
    company: "Hinge",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/2sDCNhP.png",
    locations: "New York, NY",
    role: "Software Engineer, Product Manager, Product Designer, Data Scientist",
    link: "https://hinge.co/careers/ ",
  },
  {
    company: "Hinge Health",
    lastChecked: "23-Jan-2023",
    logo: "https://logo.clearbit.com/hingehealth.com",
    locations: "United States; Canada; Latam",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Product Designer",
    link: "https://www.hingehealth.com/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "Hippo Insurance",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/hippo.com",
    locations: "Palo Alto, CA; Oakland, CA; Remote",
    role: "Engineering Manager",
    link: "https://boards.greenhouse.io/hippo70/jobs/6421048002?gh_jid=6421048002",
  },
  {
    company: "HIVED",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/KqbvoGs.png",
    locations: "United Kingdom; Europe",
    role: "Software Engineer",
    link: "https://apply.workable.com/hived/ ",
  },
  {
    company: "HockeyStack",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/hockeystack.com",
    locations: "San Francisco, CA",
    role: "Software Engineer",
    link: "https://www.linkedin.com/in/ardabulut-627211",
  },
  {
    company: "Hologic",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/hologic.com",
    locations: "San Diego, CA",
    role: "Software Engineer, Test Engineer",
    link: "https://careers.hologic.com/en/search",
  },
  {
    company: "Homebase",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/joinhomebase.com",
    locations:
      "San Francisco, CA; Los Angeles, CA; Houston, TX; Toronto, Canada; Atlanta, GA; Denver, CO; Vancouver, Canada",
    role: "Software Engineer, Engineering Manager",
    link: "joinhomebase.com/careers",
  },
  {
    company: "Homepoint",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/homepointfinancial.com",
    locations: "Remote US",
    role: "Data Engineer, Software Engineer",
    link: "https://us63.dayforcehcm.com/CandidatePortal/en-US/homepoint",
  },
  {
    company: "Horizon Quantum Computing",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/horizonquantum.com",
    locations: "Singapore; Ireland",
    role: "Software Engineer, Product Manager, Director of Engineering, Scientist",
    link: "https://horizonquantum.applytojob.com",
  },
  {
    company: "Hotjar",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/Vl4LQCu.png",
    locations: "Remote EMEA",
    role: "Software Engineer, Data Analyst, Sales, Product Manager",
    link: "https://www.hotjar.com/careers/",
  },
  {
    company: "HousingAnywhere",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/housinganywhere.com",
    locations: "Rotterdam, Netherlands; Remote",
    role: "Software Engineer, Product Owner, Engineering Manager",
    link: "https://housinganywhere.com/careers",
  },
  {
    company: "Huawei",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Huawei.com",
    locations: "Toronto, Canada",
    role: "Software Engineer",
    link: "https://jobs.smartrecruiters.com/HuaweiTechnologiesCanadaCoLtd/743999862348501-senior-engineer-of-cloud-native-application-platform-microservices",
  },
  {
    company: "Hudson River Trading",
    lastChecked: "07-Dec-2022",
    logo: "https://logo.clearbit.com/hudson-trading.com",
    locations: "New York, NY; Austin, TX; Chicago, IL; London, UK",
    role: "Software Engineer, Software Engineering Manager, Data Scientist, Algo Strategy Developer",
    link: "https://www.hudsonrivertrading.com/careers/",
  },
  {
    company: "Hum Capital",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/humcapital.com",
    locations: "United States; Remote",
    role: "Software Engineer, DevOps Engineer, Security Engineer, Engineering Manager, Data Scientist",
    link: "https://humcapital.com/careers/",
  },
  {
    company: "Humaans",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/sCd67TS.png",
    locations: "London, UK",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobs.ashbyhq.com/humaans",
  },
  {
    company: "Humanising Autonomy",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/humanisingautonomy.com",
    locations: "United Kingdom",
    role: "Software Engineer",
    link: "https://humanisingautonomy.com/join-us/",
  },
  {
    company: "Huntress",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/huntresslabs.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Marketing, Software Architect",
    link: "https://boards.greenhouse.io/huntress",
  },
  {
    company: "Hyper Hippo Entertainment ",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/hyperhippo.com",
    locations: "Remote Canada",
    role: "Software Engineer, Product Manager, Product Designer, Senior QA Manager",
    link: "https://hyperhippo.com/careers/",
  },
  {
    company: "Hyperframe",
    lastChecked: "06-Dec-2022",
    logo: "https://i.imgur.com/lol6B37.png",
    locations: "Berkeley, CA; Hybrid",
    role: "Software Engineer",
    link: "https://www.linkedin.com/jobs/view/3347829797/?refId=Q8K3LfQBvPiwvc%2FF8iQqWw%3D%3D&trackingId=ylyVItAqw0XsHUqjLsN3aQ%3D%3D",
  },
  {
    company: "Hyperproof",
    lastChecked: "06-Jan-2023",
    logo: "https://imgur.com/zF7B4ZI.png",
    locations: "Remote",
    role: "Site Reliability / DevOps, Sales, Operations",
    link: "https://hyperproof.io/working-at-hyperproof/",
  },
  {
    company: "IBM",
    lastChecked: "30-Jan-2023",
    logo: "https://i.imgur.com/GIFrpIZ.png",
    locations: "United States; Remote",
    role: "Software Engineer",
    link: "https://www.ibm.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Iglu",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/iglu.net",
    locations: "Thailand; Vietnam",
    role: "Software Engineer",
    link: "https://iglu.net/jobs/",
  },
  {
    company: "IHG Hotels & Resorts",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/ihg.com",
    locations: "Atlanta, GA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://careers.ihg.com/en/",
  },
  {
    company: "in-House",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/in-House.com",
    locations: "Remote",
    role: "Engineering Manager",
    link: "https://in-house.com/es/join-us",
    comments:
      "Reach out to https://www.linkedin.com/in/matthew-blais-b68472121/",
  },
  {
    company: "Included Health",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/includedhealth.com",
    locations: "San Francisco, CA; New York, NY; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager",
    link: "https://includedhealth.com/careers/tech-jobs/",
    comments: "",
    geos: "",
  },
  {
    company: "Inductive Automation",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/oXopUu6.png",
    locations: "United States",
    role: "Software Engineer, Software Engineering Manager, Cloud Architect",
    link: "https://inductiveautomation.com/about/careers",
  },
  {
    company: "Infigage",
    lastChecked: "01-Dec-2022",
    logo: "https://i.imgur.com/vT2TXJO.png",
    locations: "Iselin, NJ",
    role: "Software Engineer, Software Engineering Manager, Product Designer, Project Manager with Asset Management",
    link: "",
    comments: "priyank.patel@infigage.com",
  },
  {
    company: "Infinitelambda",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/6uMHD0V.png",
    locations: "Remote; United Kingdom; Bulgaria; Romania",
    role: "Software Engineer, Data Engineer",
    link: "https://infinitelambda.com/careers/",
  },
  {
    company: "ING",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/ing.com",
    locations: "Worldwide",
    role: "Site Reliability / DevOps, Data Analyst, Data Engineer",
    link: "https://www.ing.jobs/Global/Careers.htm",
  },
  {
    company: "InstaDeep",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/4cOYTNK.png",
    locations:
      "San Francisco, CA; Boston, MA; London, UK; Paris, France; Berlin, Germany; Cape Town, South Africa; Tunis, Tunisia",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.instadeep.com/about-us/careers/ ",
  },
  {
    company: "Instapro Group",
    lastChecked: "22-Nov-2022",
    logo: "https://i.imgur.com/qLIc5Ue.png",
    locations: "United Kingdom; Netherlands; Germany; France",
    role: "Software Engineer",
    link: "https://careers.instapro.group/open-positions",
  },
  {
    company: "Instructure",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/RvVRKPi.png",
    locations: "Hungary",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.instructure.com/careers/budapest",
  },
  {
    company: "IntelliSense.io",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/IntelliSense.io",
    locations: "United Kingdom; Ireland; Spain; Chile; Australia; Germany",
    role: "Engineering Manager, Software Engineer, Data Engineer, Data Scientist",
    link: "https://apply.workable.com/intellisense-io/#jobs",
  },
  {
    company: "Intuit",
    lastChecked: "17-Nov-2022",
    logo: "https://logo.clearbit.com/intuit.com",
    locations: "Mountain View, CA; San Diego, CA; Remote US",
    role: "Product Designer, UX Researcher",
    link: "https://jobs.intuit.com/search-jobs/",
  },
  {
    company: "Inventive",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/madeinventive.com",
    locations: "United States, Canada",
    role: "Software Engineer, UX Designer",
    link: "https://madeinventive.rippling-ats.com/job/362763/-founding-engineer",
  },
  {
    company: "Issuu",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Issuu.com",
    locations: "Portugal; Copenhagen, Denmark; Berlin, Germany",
    role: "Software Engineer, Product Managers, UX Designer",
    link: "https://issuu.com/careers",
  },
  {
    company: "ITClass",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/Hw3xFyE.png",
    locations: "Remote CET",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://it-class.eu/current-opportunities/",
  },
  {
    company: "iTMethods",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/itmethods.com",
    locations: "Remote EST",
    role: "Cloud Engineer, Cloud Architect",
    link: "https://www.itmethods.com/careers-itmethods/",
  },
  {
    company: "IVU Traffic Technologies AG",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/ivu.com",
    locations: "Germany",
    role: "Software Engineer",
    link: "https://www.ivu.com/jobs",
  },
  {
    company: "J.B. Hunt",
    lastChecked: "30-Dec-2022",
    logo: "https://logo.clearbit.com/jbhunt.com",
    locations: "Remote US; Lowell, AR",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://careers.jbhunt.com/jobs?JbCat=Engineering+and+Technology",
    comments: "",
    geos: "",
  },
  {
    company: "Jack Henry",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/jackhenry.avature.net",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://jackhenry.avature.net/careers",
  },
  {
    company: "Jamf",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/tsRF7MK.png",
    locations:
      "United States; United Kingdom; Netherlands; Czech Republic; Poland; Japan; Australia",
    role: "Software Engineer, Software Engineering Manager, Product Owner",
    link: "https://www.jamf.com/about/careers/",
  },
  {
    company: "JetBrains",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/jetbrains.com",
    locations: "Netherlands; Germany; Cyprus; Armenia; Serbia; Czech Republic",
    role: "Software Engineer, Product Manager, Project Manager",
    link: "https://www.jetbrains.com/careers/jobs/",
  },
  {
    company: "JLG Consulting",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/jlg.ro",
    locations: "Bucharest, Romania",
    role: "Software Engineer",
    link: "https://www.linkedin.com/jobs/view/3348211346",
  },
  {
    company: "John Deere",
    lastChecked: "16-Dec-2022",
    logo: "https://logo.clearbit.com/johndeere.com",
    locations: "Urbandale, IA; Remote",
    role: "Software Engineer",
    link: "https://jobs.deere.com/eightfold/job/Urbandale-Senior-Cloud-Engineer-%28Remote%29-Iowa-50322/970101100/",
    comments: "",
    geos: "",
  },
  {
    company: "JOIN",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/Lu53ijz.png",
    locations: "Berlin, Germany; Poznan, Poland",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://join.com/companies/join?categoryIds%5B0%5D=170 ",
  },
  {
    company: "Julian Social",
    lastChecked: "11-Nov-2022",
    logo: "https://imgur.com/rrQbKS1.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://juliansocial.com/careers",
  },
  {
    company: "Jumbo",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/jumbo.com",
    locations: "Veghel, Netherlands",
    role: "Engineering Manager, Software Engineer, Mobile Test Automation Engineers, QA Engineer, Data Engineer, Cloud Architect, UX Designer",
    link: "jobs.jumbo.com",
  },
  {
    company: "Jupiter Intelligence",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/jupiterintel.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://jobs.lever.co/jupiterintel/a1eada8e-d35a-4d0c-8084-b86c444c48a5?lever-source=LinkedInJobs",
  },
  {
    company: "Just Eat Take​away",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/YEyMiCY.png",
    locations:
      "Berlin, Germany; London, UK; Bristol, UK; Amsterdam, Netherlands; Enschede, Netherlands",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://careers.justeattakeaway.com/global/en/search-results ",
  },
  {
    company: "JustWatch",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/JustWatch.com",
    locations:
      "Remote; Berlin, Germany; Munich, Germany; London, United Kingdom",
    role: "Software Engineer, Solutions Architect, Head of Product",
    link: "https://www.justwatch.com/us/talent",
  },
  {
    company: "Kaizen Gaming",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/kaizengaming.com",
    locations: "Greece; Bulgaria; Romania; Czech Republic; Portugal; Canada",
    role: "Software Engineer, Product Designer",
    link: "https://kaizengaming.com/open-positions/",
  },
  {
    company: "Kalepa",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/kalepa.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Manger",
    link: "https://kalepa.com/about/careers",
  },
  {
    company: "Kalibrate",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/JG6htz2.png",
    locations: "United States; United Kingdom",
    role: "Software Engineer, Test Engineer",
    link: "https://kalibrate.com/about/careers/ ",
  },
  {
    company: "Kano",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/04XgaaK.png",
    locations: "Remote",
    role: "Software Engineering Manager, Data Analyst, Software Engineer",
    link: "https://www.kanoapps.com/careers ",
  },
  {
    company: "Kantox",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Kantox.com",
    locations: "Barcelona, Spain",
    role: "Software Engineer",
    link: "https://www.kantox.com/en/careers/",
  },
  {
    company: "Karat",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/karat.com",
    locations: "Seattle, WA; Remote",
    role: "Software Engineer, Product Manager, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://boards.greenhouse.io/karat",
  },
  {
    company: "Karma",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Karma.life",
    locations: "Remote",
    role: "Engineering Manager, Software Engineer",
    link: "https://careers.karma.life/jobs",
  },
  {
    company: "Kasada",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/kasada.io",
    locations: "Remote Australia; Remote US",
    role: "Product Manager, Software Engineer, Security Engineer",
    link: "https://jobs.lever.co/kasada",
  },
  {
    company: "Kate Farms",
    lastChecked: "11-Nov-2022",
    logo: "https://imgur.com/f51DnCz.png",
    locations: "Remote",
    role: "Site Reliability / DevOps, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://apply.workable.com/kate-farms/",
  },
  {
    company: "Kaufland e-commerce",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/fCKDxen.png",
    locations: "Remote Europe",
    role: "Software Engineer, Data Engineer, Site Reliability / DevOps",
    link: "https://kaufland-ecommerce.com/en/career/jobs/",
  },
  {
    company: "Kennametal",
    lastChecked: "20-Nov-2022",
    logo: "https://imgur.com/cslmM7e.png",
    locations: "Bangalore, India",
    role: "Finance / Accounting",
    link: "https://jobs.kennametal.com/",
  },
  {
    company: "Khan Academy",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/khanacademy.org",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.khanacademy.org/careers#openings",
  },
  {
    company: "Kikoff",
    lastChecked: "25-Jan-2023",
    logo: "https://logo.clearbit.com/kikoff.com",
    locations: "San Francisco, CA",
    role: "Software Engineer, Product Manager, Product Designer, Human Resources, Recruiter",
    link: "https://jobs.lever.co/kikoff",
  },
  {
    company: "Kilo Health",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/kilo.health",
    locations:
      "Remote Europe; Lithuania; Amsterdam, Netherlands; Berlin, Germany; Ukraine",
    role: "Software Engineer, Engineering Manager",
    link: "https://kilo.health/careers/",
  },
  {
    company: "Kindred Group",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/3fnVGV0.png",
    locations: "Stockholm, Sweden",
    role: "Engineering Manager, Engineering Team Lead, Software Engineer",
    link: "https://careers.kindredgroup.com/ ",
  },
  {
    company: "King.com",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/King.com",
    locations:
      "London, United Kingdom; Stockholm, Sweden; Barcelona, Spain; Dublin, Ireland; Malmö, Sweden; Berlin, Germany",
    role: "Software Engineer, Data Scientist, Data Engineer, Engineering Manager, Data Analyst, Product Manager",
    link: "https://careers.king.com/",
  },
  {
    company: "Kinsta",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Kinsta.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://kinsta.com/careers/",
  },
  {
    company: "Kipsi",
    lastChecked: "11-Nov-2022",
    logo: "https://imgur.com/1IiFTtW.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/applytokipsi/jobs/4605034004",
  },
  {
    company: "Klarity",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/tryklarity.com",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer",
    link: "https://www.tryklarity.com/careers",
  },
  {
    company: "Klarity Health",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/klarityadhd.com",
    locations: "San Francisco, CA; Los Angeles, CA; Toronto, Canada",
    role: "Software Engineering Manager, Product Manager, Product Designer, Human Resources, Marketing, Operations",
    link: "https://career.helloklarity.com/",
  },
  {
    company: "Klaus",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Klausapp.com",
    locations: "Remote",
    role: "Software Engineer, QA Engineer",
    link: "https://www.klausapp.com/jobs/",
  },
  {
    company: "Knowde",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/YZsw7Fn.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/knowde/jobs/5208611003",
  },
  {
    company: "Knowledge Futures Group",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/41F0uCb.png",
    locations: "Remote",
    role: "Software Engineering Manager, Data Engineer",
    link: "https://www.knowledgefutures.org/jobs",
  },
  {
    company: "Koala Health",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/ve05zSa.png",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobs.lever.co/koalahealth",
  },
  {
    company: "Konica Minolta",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/kmbs.Konicaminolta.us",
    locations: "Remote; San Francisco, CA; New York, NY",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://careers.konicaminoltaus.com/",
  },
  {
    company: "KPV Lab",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/cZJP1K7.png",
    locations: "Edinburgh, United Kingdom",
    role: "Software Engineer",
    link: "https://kpv-lab.co.uk/jobs/",
  },
  {
    company: "Kraft Heinz",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/kraftheinz.com",
    locations: "Chicago, IL; Remote",
    role: "Software Engineer, Software Engineering Manager, Data Scientist, Product Designer, Finance / Accounting",
    link: "https://careers.kraftheinz.com/careers/SearchJobs",
  },
  {
    company: "KreditBee",
    lastChecked: "30-Nov-2022",
    logo: "https://logo.clearbit.com/kreditbee.in ",
    locations: "India",
    role: "Software Engineer, Software Engineering Manager, Data Scientist",
    link: "https://www.kreditbee.in/careers",
  },
  {
    company: "Kryterion",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/jrfabuK.png",
    locations:
      "Phoenix, AZ; Remote US; Cape Town, South Africa; Cebu, Philippines; Davao, Philippines",
    role: "Software Engineer",
    link: "https://www.kryterion.com/careers/ ",
  },
  {
    company: "Kubecost",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/kubecost.com",
    locations: "Remote",
    role: "Software Engineer, Sales, Marketing, Technical Success",
    link: "https://jobs.kubecost.com",
  },
  {
    company: "KUNGFU.AI",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/kungfu.ai",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.kungfu.ai/careers",
  },
  {
    company: "Kwest",
    lastChecked: "21-Nov-2022",
    logo: "https://i.imgur.com/N45rCES.png",
    locations: "Berlin, Germany",
    role: "Software Engineer",
    link: "https://kwest.notion.site/48d0f947b25b485383acd19287400583?v=5b2f3b1ebade49909dd0a1ea799d5f11",
  },
  {
    company: "Kymeta",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/lUuwfnP.png",
    locations: "Redmond, WA; Remote",
    role: "Software Engineer",
    link: "https://www.kymetacorp.com/",
  },
  {
    company: "LabX Media Group",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/labx.com",
    locations: "Remote; Canada; United States; United Kingdom",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://labx.bamboohr.com/careers",
  },
  {
    company: "Laserfiche",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/HRtn9MY.png",
    locations: "Remote; United States; Toronto, Canada",
    role: "Software Engineer",
    link: "https://www.laserfiche.com/careers/ ",
  },
  {
    company: "LaundryMate",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/laundrymate.in",
    locations: "Bangalore, India",
    role: "Software Engineer, Software Engineering Manager, Finance / Accounting, Marketing",
    link: "https://www.linkedin.com/company/laundrymateindia/jobs/",
    comments: "careers@laundrymate.in",
  },
  {
    company: "LavelBox",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/labelbox.com",
    locations: "Remote US",
    role: "Product Designer, Software Engineer, Community Manager, Technical Account Manager",
    link: "https://jobs.lever.co/labelbox",
  },
  {
    company: "Leadfeeder",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/LUVMcsK.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://apply.workable.com/leadfeeder/j/F7590E7546/?utm_campaign=56d73ba390&utm_medium=eps&utm_source=link",
  },
  {
    company: "Leaseweb",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Leaseweb.com",
    locations:
      "Amsterdam, Netherlands; Lisbon, Portugal; Bengaluru, India; Montreal, Canada",
    role: "Software Engineer, Technical Product Owner",
    link: "https://www.leaseweb.com/career/departments/product-engineering",
  },
  {
    company: "Ledgy",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Ledgy.com",
    locations:
      "London, United Kingdom; Berlin, Germany; Zurich, Switzerland; Remote Europe",
    role: "Software Engineer, Product Manager",
    link: "https://ledgy.com/careers/",
  },
  {
    company: "Legal & General",
    lastChecked: "12-Nov-2022",
    logo: "https://logo.clearbit.com/lgra.com",
    locations: "Stamford, CT",
    role: "Finance / Accounting, Investment Management",
    link: "https://careers-lgamerica.icims.com/jobs/intro",
  },
  {
    company: "LEGO",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/lego.com",
    locations: "London, United Kingdom; Billund, Denmark; Copenhagen, Denmark",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.lego.com/en-gb/careers/",
  },
  {
    company: "Lendable",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/lendable.co.uk",
    locations: "London, UK; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Data Scientist, Operations, Data Engineer",
    link: "https://careers.lendable.com/?utm_source=levelsfyi",
    comments: "",
    geos: "",
  },
  {
    company: "Lepaya",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/1pG7s7F.png",
    locations: "Amsterdam, Netherlands; Remote Germany; Sweden; United Kingdom",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://lepaya.com/en/about/careers/",
  },
  {
    company: "Level",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/ahnKf86.png",
    locations: "Remote US; Remote Canada",
    role: "Software Engineer, Data Scientist, Product Manager",
    link: "https://level.com/jobs",
  },
  {
    company: "LexisNexis Reed Tech",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/lexisnexis.com",
    locations: "Remote",
    role: "ServiceNow Developer, Systems Engineer",
    link: "https://relx.wd3.myworkdayjobs.com/ReedExhibitions",
    comments: "",
    geos: "",
  },
  {
    company: "Liberty Global",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/libertyglobal.com",
    locations: "London, UK; Reading, UK; Bradford, UK; Amsterdam, Netherlands",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Human Resources, Recruiter, Marketing, Operations",
    link: "https://www.libertyglobal.com/people/join-us/",
  },
  {
    company: "Lightyear",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/LSb4mLH.png",
    locations: "London, United Kingdom; Tallinn, Estonia",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://apply.workable.com/lightyear/",
  },
  {
    company: "Lilac Solutions, Inc",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/lilacsolutions.com",
    locations: "Oakland, CA",
    role: "Recruiter",
    link: "https://boards.greenhouse.io/lilacsolutionsinc/jobs/4507120004",
  },
  {
    company: "LinkGraph",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/linkgraph.io",
    locations: "Remote",
    role: "Sales, Marketing, Operations",
    link: "linkgraph.io/careers",
  },
  {
    company: "Liveflow",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/liveflow.io",
    locations: "New York, NY; Lisbon, Portugal; São Paulo, Brazil; London, UK",
    role: "Software Engineer, Sales, Marketing",
    link: "https://careers.liveflow.io/",
  },
  {
    company: "Loom",
    lastChecked: "30-Nov-2022",
    logo: "https://logo.clearbit.com/loom.com",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Finance / Accounting, Sales, Marketing, Operations, Data",
    link: "https://www.loom.com/careers#positions",
  },
  {
    company: "Loominate.app",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Loominate.app",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://angel.co/l/2y17rZ",
  },
  {
    company: "Loop",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/2JxPGKC.png",
    locations: "San Francisco, CA; Chicago, IL",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://boards.greenhouse.io/loop",
  },
  {
    company: "Lowe's",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/lowes.com",
    locations: "Charlotte, NC; Remote EST",
    role: "Data Engineer, Data Scientist, Software Engineer",
    link: "https://talent.lowes.com/us/en",
  },
  {
    company: "LoyaltyLion",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/loyaltylion.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Manager",
    link: "https://loyaltylion.com/careers",
  },
  {
    company: "LucaNet",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/lucanet.com",
    locations: "Miami, FL; EMEA; APAC; LATAM; Remote",
    role: "Software Engineer, Product Manager, Finance / Accounting, Human Resources, Sales, Marketing, Operations, Consulting",
    link: "https://jobs.lucanet.com",
  },
  {
    company: "Luminary",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/kk7C7W9.png",
    locations: "New York, NY",
    role: "Software Engineer",
    link: "https://www.withluminary.com/careers",
  },
  {
    company: "Luminary Platforms",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/withluminary.com",
    locations: "New York, NY; San Francisco, CA; Remote US",
    role: "Software Engineer",
    link: "https://www.withluminary.com",
  },
  {
    company: "Lummo",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/7bh7hJv.png",
    locations: "Remote; India; Indonesia",
    role: "Software Engineer",
    link: "https://lummo.hire.trakstar.com/",
  },
  {
    company: "Lunar Energy",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/fxtO2tT.png",
    locations: "Remote; London, UK",
    role: "Software Engineer, Platform Security Engineer, Software Engineering Lead",
    link: "https://www.lunarenergy.com/careers ",
  },
  {
    company: "Maca",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/Oy4YUCO.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://angel.co/company/maca-7/jobs/2444176-software-engineer-remote?utm_campaign=startup_share&utm_content=startup_share_module&utm_medium=social&utm_term=maca-7",
  },
  {
    company: "MacroFab",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/macrofab.com",
    locations: "Houston, TX; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing",
    link: "https://macrofab.applytojob.com/apply",
    comments: "",
    geos: "",
  },
  {
    company: "Mail&Media",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/HzUGM95.png",
    locations: "Remote Germany; Karlsruhe, Germany; Munich, Germany",
    role: "Engineering Manager, Software Engineer, Data Engineer",
    link: "https://www.mail-and-media.com/en/jobs/job-search.html",
  },
  {
    company: "MailOnline",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/lWS5GEW.png",
    locations: "London, UK",
    role: "Software Engineer",
    link: "https://www.linkedin.com/jobs/view/3324077243",
  },
  {
    company: "Major League Baseball",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/mlb.com",
    locations: "New York, NY",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.mlb.com/careers/opportunities?gh_jid=4574050",
  },
  {
    company: "MALT",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/malt.com",
    locations: "France; Spain; Germany; United Kingdom; Belgium; Netherlands",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://careers.malt.com/careers.html",
  },
  {
    company: "Mambu",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/mambu.com",
    locations:
      "Vilnius, Lithuania; Lasi, Romania; Bucharest, Romania; Amsterdam, Netherlands",
    role: "Engineering Director, Senior Engineering Managers, Technical Leads, Senior Software Engineer, Service Reliability Engineer, Senior DevOps Engineer, Principal Security Architect",
    link: "https://careers-mambu.icims.com/jobs/intro?hashed=-625943632",
  },
  {
    company: "ManpowerGroup",
    lastChecked: "07-Feb-2023",
    logo: "https://logo.clearbit.com/manpowergroup.com",
    locations: "North America",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://www.manpowergroup.com/en/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Marks & Spencer",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/FPApoeh.png",
    locations: "London, UK",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobs.marksandspencer.com/#",
  },
  {
    company: "Marqeta",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/marqeta.com",
    locations: "Remote US; Remote UK",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps",
    link: "https://www.marqeta.com/company/careers/all-jobs",
    comments: "",
    geos: "",
  },
  {
    company: "Mars",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/mars.com",
    locations: "United States; United Kingdom",
    role: "Data Engineer, Software Engineer, Product Manager, Data Scientist",
    link: "https://mars.wd3.myworkdayjobs.com/en-US/External?jobFamilyGroup=f7694590cd500194a30483ee200d590b",
  },
  {
    company: "MarsDevs",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/marsdevs.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.marsdevs.com/careers",
  },
  {
    company: "Mastercard",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/mastercard.com",
    locations: "San Francisco, CA; New York, NY; St. Louis, MO; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Data Analysi",
    link: "https://careers.mastercard.com/us/en",
  },
  {
    company: "Matchesfashion",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/ndABuAY.png",
    locations: "London, UK; Remote UK",
    role: "Software Engineer, Product Designer, Head of Tech",
    link: "https://apply.workable.com/matchesfashion/",
  },
  {
    company: "Materialize",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/materialize.com",
    locations: "New York, NY; Remote",
    role: "Engineering Manager, Software Engineer, Cloud Security Engineer, Technical Writer, Marketing, UX Designer",
    link: "https://boards.greenhouse.io/materialize",
  },
  {
    company: "Maven Clinic",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/tL5mD2g.png",
    locations: "United States",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://www.mavenclinic.com/careers",
  },
  {
    company: "Mavenoid",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/mavenoid.com",
    locations: "Remote; New York, NY",
    role: "Software Engineer",
    link: "https://www.mavenoid.com/en/careers/",
  },
  {
    company: "Mavrck",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/mavrck.co",
    locations: "Boston, MA; Denver, CO; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager",
    link: "https://www.mavrck.co/careers/",
  },
  {
    company: "Mbanq",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/mbanq.com",
    locations: "United States",
    role: "Software Engineer",
    link: "https://www.mbanq.com/careers/",
  },
  {
    company: "Medallia",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/medallia.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://jobs.medallia.com/",
  },
  {
    company: "MedCrypt",
    lastChecked: "12-Dec-2022",
    logo: "https://logo.clearbit.com/medcrypt.co",
    locations: "United States; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Operations",
    link: "",
    comments: "talent@medcrypt.co",
  },
  {
    company: "Medidata Solutions",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/3GjElSz.png",
    locations:
      "Singapore; United States; United Kingdom; India; Japan; South Korea",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Recruiter, Sales, Operations",
    link: "https://www.medidata.com/en/careers/job-search/",
  },
  {
    company: "Megagon Labs",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/gebC9rY.png",
    locations: "Mountain View, CA",
    role: "Software Engineer, Research Scientist",
    link: "https://megagon.ai/jobs",
  },
  {
    company: "Memrise",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Memrise.com",
    locations: "Remote UK",
    role: "Software Engineer",
    link: "https://apply.workable.com/memrise/",
  },
  {
    company: "Mentava",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/jVufgAz.png",
    locations: "Remote",
    role: "Founding Engineer",
    link: "https://www.mentava.com/jobs?ashby_jid=9b64a18a-bf83-4e26-a1ec-68625a1d8e83",
  },
  {
    company: "Mercari Japan",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/mcgZqTJ.png",
    locations: "Japan",
    role: "Engineering Manager, Technical Product Manager",
    link: "https://careers.mercari.com/search-jobs/?s=engineering",
  },
  {
    company: "Mercedes-Benz",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/Jsto3p6.png",
    locations:
      "Sunnyvale, CA; Long Beach, CA; Farmington Hills, MI; Seattle, WA",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Operations",
    link: "https://jobs.lever.co/MBRDNA",
    comments: "",
    geos: "",
  },
  {
    company: "Merit America",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/meritamerica.org",
    locations: "Remote",
    role: "Software Engineer, Finance / Accounting, Human Resources, Sales, Operations",
    link: "https://meritamerica.org/join-our-team/#job-opps",
  },
  {
    company: "Metabase",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/MT0YqXX.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Data Analyst, Data Architect, Product Manager, Success Engineer, Product Advocate",
    link: "https://www.metabase.com/jobs",
  },
  {
    company: "Metalynx",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/meta-lynx.com",
    locations: "Hybrid United Kingdom; Remote",
    role: "Software Engineer, Machine Learning Researcher",
    link: "https://www.meta-lynx.com/careers",
  },
  {
    company: "MetaMap",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/metamap.com",
    locations: "Bangalore, India",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist",
    link: "https://boards.greenhouse.io/metamap",
  },
  {
    company: "Metaschool",
    lastChecked: "06-Feb-2023",
    logo: "https://imgur.com/wTqatKo.png",
    locations: "Remote",
    role: "Software Engineer, Finance / Accounting, Recruiter, Sales, Marketing, Operations",
    link: "https://metaschool.so/careers",
  },
  {
    company: "Metrea Simulations",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/metrea.aero",
    locations: "Remote Europe; Stockholm, Sweden; Warsaw, Poland",
    role: "Software Engineer",
    link: "https://jobs.lever.co/Metrea",
  },
  {
    company: "Mettle",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/HU7nVrS.png",
    locations: "United Kingdom",
    role: "Software Engineer",
    link: "https://jobs.mettle.co.uk/",
  },
  {
    company: "Michaels Companies",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/michaels.com",
    locations: "Irving, TX",
    role: "Data Scientist, Software Engineer, Machine Learning Engineer",
    link: "https://michaels.wd5.myworkdayjobs.com/External",
  },
  {
    company: "Microblink",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/microblink.com",
    locations: "New York, NY",
    role: "Software Engineer, Product Manager",
    link: "https://microblink.com/careers/open-positions/",
  },
  {
    company: "Midjourney",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/midjourney.breezy.hr",
    locations: "Remote",
    role: "Researcher, Data Engineer, Software Engineer",
    link: "https://midjourney.breezy.hr",
  },
  {
    company: "Milacron",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/g6xfBxD.png",
    locations: "Batavia, OH",
    role: "Software Engineering Manager, Product Manager, Operations, Process Engineering, Manufacturing Engineering, Supply Chain Analyst",
    link: "https://careers.hillenbrand.com/milacron/",
  },
  {
    company: "MIMIO.ai",
    lastChecked: "08-Feb-2023",
    logo: "https://imgur.com/fcGfWug.png",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer",
    link: "https://www.linkedin.com/company/mimio-ai/jobs",
  },
  {
    company: "Mindera",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/5qpotmJ.png",
    locations: "Western Europe",
    role: "QA Engineer, Tech Lead",
    link: "https://mindera.com/careers",
  },
  {
    company: "Miro",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/miro.com",
    locations: "Netherlands; Berlin, Germany",
    role: "Engineering Manager, Software Engineer",
    link: "https://miro.com/careers/",
  },
  {
    company: "Mission Control",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/missioncontrolspaceservices.com",
    locations: "Ottawa, Canada",
    role: "Software Engineer",
    link: "https://www.missioncontrolspaceservices.com/about/careers/",
  },
  {
    company: "Mix",
    lastChecked: "23-Jan-2023",
    logo: "https://logo.clearbit.com/mix.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/mix",
  },
  {
    company: "Mnemonic",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/9BG670F.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://jobs.ashbyhq.com/mnemonic",
  },
  {
    company: "Mobica",
    lastChecked: "09-Jan-2023",
    logo: "https://logo.clearbit.com/mobica.com",
    locations: "Remote; Global; Seattle, WA",
    role: "Software Engineer, Product Manager",
    link: "https://mobica.com/join-the-team",
  },
  {
    company: "MobiLab Solutions",
    lastChecked: "09-Jan-2023",
    logo: "https://logo.clearbit.com/mobilabsolutions.com",
    locations: "Cologne, Germany",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Recruiter, Marketing, Data Engineer, Cloud Architect, Business Analyst, Project Manager",
    link: "https://mobilabsolutions.com/jobs/#openpositions",
  },
  {
    company: "Mobile Helix",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/MobileHelix.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://mobilehelix.com/careers/",
  },
  {
    company: "MobileCoin",
    lastChecked: "01-Feb-2023",
    logo: "https://logo.clearbit.com/mobilecoin.com",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Marketing, Flutter Engineer",
    link: "https://mobilecoin.com/careers/open-positions/",
  },
  {
    company: "Modash",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/5RxTJvC.png",
    locations: "Remote; Tallinn, Estonia",
    role: "Software Engineer, Product Designer, Account Executive",
    link: "https://www.modash.io/careers#open-positions",
  },
  {
    company: "MoEngage",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/moengage.com",
    locations:
      "Bengaluru, India; Jakarta, Indonesia; Ho Chi Minh City, Vietnam",
    role: "Software Engineer, Engineering Manager, Data Scientist, Solutions Engineer",
    link: "https://moengage.darwinbox.in/ms/candidate/careers",
  },
  {
    company: "Mold Masters",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/IVoDRkd.png",
    locations: "Georgetown, ON",
    role: "Hardware Engineer, Product Manager, Operations, Product Engineers, Hourly Production, Process Technicians",
    link: "https://careers.hillenbrand.com/moldmasters/",
  },
  {
    company: "Molex",
    lastChecked: "20-Dec-2022",
    logo: "https://logo.clearbit.com/molex.com",
    locations: "Fremont, CA",
    role: "Hardware Engineer",
    link: "https://jobs.kochcareers.com/jobs/10778994-hardware-engineer-oplink-a-molex-company",
    comments: "",
    geos: "",
  },
  {
    company: "Mollie",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/xPMzO4J.png",
    locations: "Amsterdam, Netherlands; Lisbon, Portugal",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobs.mollie.com/en/jobs/",
  },
  {
    company: "Moloco",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/moloco.com",
    locations:
      "Redwood City, CA; San Francisco, CA; Seoul, South Korea; London, UK; Seattle, WA",
    role: "Software Engineer, Product Manager, Human Resources, Sales, Marketing, Machine Learning",
    link: "https://www.moloco.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "monday.com",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/monday.com",
    locations: "London, UK; Tel Aviv, Israel; Warsaw, Poland",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps",
    link: "https://monday.com/careers",
  },
  {
    company: "MongoDB",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/mongodb.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://www.mongodb.com/careers",
  },
  {
    company: "Monzo",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/IJTGeSh.png",
    locations: "United Kingdom; United States",
    role: "Software Engineer",
    link: "https://monzo.com/careers/",
  },
  {
    company: "Moo",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/moo.com",
    locations: "United Kingdom; Remote GMT",
    role: "Software Engineer",
    link: "https://www.moo.com/uk/about/jobs",
  },
  {
    company: "MoonPay",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/WXnooSu.png",
    locations: "Remote US; Remote Europe",
    role: "Software Engineer",
    link: "https://www.moonpay.com/careers ",
  },
  {
    company: "Moov",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/moov.io",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://moov.io/careers/",
  },
  {
    company: "Morgan Stanley",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/morganstanley.com",
    locations: "United States",
    role: "Software Engineer",
    link: "https://www.morganstanley.com/people?cid=ppc-71700000088240657:700000002375621:58700007479781925:p67225342683&gclsrc=aw.ds&gclid=CjwKCAiAvK2bBhB8EiwAZUbP1CbSIV7MnJgJ0QBfnEA-Muz_Jvmbpdb8OlmObl9OM1GdmBcAyxGNPBoCr-8QAvD_BwE",
  },
  {
    company: "Moss",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/3b1GRv5.png",
    locations:
      "Berlin, Germany; Warsaw, Poland; Amsterdam, Netherlands; Tallinn, Estonia",
    role: "Software Engineering Manager",
    link: "https://boards.eu.greenhouse.io/moss",
  },
  {
    company: "Moss Adams",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/mossadams.com",
    locations:
      "Seattle, WA; Portland, OR; Bay Area, CA; Los Angeles, CA; Phoenix, AZ; Dallas, TX; Houston, TX; Denver, CO",
    role: "Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://www.mossadams.com/careers",
  },
  {
    company: "Mozilla",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/u2wnVzX.png",
    locations:
      "Remote US; Remote UK; Remote Canada; Remote France; Remote Germany",
    role: "Data Engineer, Site Reliability / DevOps, Software Engineering Manager, Data Scientist",
    link: "https://www.mozilla.org/careers/listings/ ",
  },
  {
    company: "MRI Software",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/mrisoftware.com",
    locations: "London, UK; Remote; Cape Town, South Africa",
    role: "Software Engineer, Human Resources, Sales",
    link: "https://www.mrisoftware.com/about/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Mukuru",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/fjJ3D6h.png",
    locations: "South Africa",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.linkedin.com/mwlite/search/jobs?pageNumber=0&worldwideSearch=true&company=Mukuru&f_C=9318059",
  },
  {
    company: "Multiply",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/withmultiply.com",
    locations: "San Francisco, CA",
    role: "Product Designer",
    link: "https://www.withmultiply.com/careers/product-designer",
  },
  {
    company: "Multiverse",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/multiverse.io",
    locations: "New York, NY; London, UK",
    role: "Product Designer",
    link: "https://www.multiverse.io/en-GB/careers",
  },
  {
    company: "Mutinex",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/uH5Gf20.png",
    locations: "Australia",
    role: "Software Engineer",
    link: "https://www.linkedin.com/jobs/view/3348774762/",
  },
  {
    company: "N26",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/EigALAj.png",
    locations:
      "Barcelona, Spain; Vienna, Austria; Berlin, Germany; Amsterdam, Netherlands; Milan, Italy; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Engineer, Business Analyst, Marketing, Opeations",
    link: "https://n26.com/en/careers",
  },
  {
    company: "nanameue",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/Ohx6Ztm.png",
    locations: "Tokyo, Japan",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Finance / Accounting, Marketing",
    link: "https://nanameue.jp/career",
  },
  {
    company: "Nanotronics",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Nanotronics.co",
    locations: "New York, NY",
    role: "Software Engineer",
    link: "https://nanotronics.co/careers/",
  },
  {
    company: "Nansen",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/0v0c3D3.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://boards.greenhouse.io/nansen",
  },
  {
    company: "Napo",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/OZF9dD8.png",
    locations: "London, UK",
    role: "Software Engineer, Site Reliability / DevOps, Data Scientist",
    link: "https://careers.napo.pet/",
  },
  {
    company: "Nationale Nederlanden",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/nn-group.com",
    locations: "Netherlands",
    role: "Software Engineer, Data Analyst, Data Engineer, Business Analyst, Solutions Architect",
    link: "https://nn-careers.com/nl/en",
  },
  {
    company: "Nav Technologies",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/nav.com",
    locations: "Draper, UT; Remote",
    role: "Software Engineer, Software Engineering Manager, Data Scientist, Sales",
    link: "https://www.nav.com/company/careers",
  },
  {
    company: "NBC Universal",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/nbcuniversal.com",
    locations: "New York, NY; Fort Worth, TX; Seattle, WA; Remote",
    role: "Software Engineer, Product Designer, Sales, Marketing, Operations",
    link: "https://www.nbcunicareers.com/find-a-job?aoi=7641#jobs_search-react-main-wrapper",
  },
  {
    company: "NearSt",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/near.st",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://app.beapplied.com/apply/otvc1mhqwk",
  },
  {
    company: "Nectar Technologies",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/nectar.buzz",
    locations: "Montreal, Canada; Remote Canada",
    role: "Software Engineer, Product Designer",
    link: "https://www.nectar.buzz/en/careers",
  },
  {
    company: "Need",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/getneed.com",
    locations: "Santa Monica, CA",
    role: "Software Engineer, Operations",
    link: "https://www.getneed.com",
    comments: "careers@getneed.com",
  },
  {
    company: "NetDocuments",
    lastChecked: "07-Dec-2022",
    logo: "https://logo.clearbit.com/netdocuments.com",
    locations: "Utah; Remote",
    role: "Site Reliability / DevOps, Product Manager, Finance / Accounting, Sales, Operations, Professional Services, Technical Support, Customer Success",
    link: "https://jobs.jobvite.com/netdocuments",
  },
  {
    company: "Netguru",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/netguru.com",
    locations: "Poland",
    role: "Software Engineer",
    link: "https://www.netguru.com/career",
  },
  {
    company: "Netlify",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/netlify.com",
    locations: "Remote US",
    role: "Marketing, Solutions Engineer, Accounting",
    link: "https://www.netlify.com/careers/#perfect-job",
  },
  {
    company: "Netskope",
    lastChecked: "02-Feb-2023",
    logo: "https://logo.clearbit.com/netskope.com",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.netskope.com/company/careers/open-positions",
    comments: "",
    geos: "",
  },
  {
    company: "New York Mets",
    lastChecked: "17-Nov-2022",
    logo: "https://imgur.com/yhVsiDm.png",
    locations: "New York, NY; Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://sterlingmets.wd5.myworkdayjobs.com/en-US/Mets",
  },
  {
    company: "New10",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/new10.com",
    locations: "Amsterdam, Netherlands",
    role: "Data Engineer",
    link: "https://apply.workable.com/new10/j/49BBFA158D/",
  },
  {
    company: "Next Lotto",
    lastChecked: "02-Feb-2023",
    logo: "https://logo.clearbit.com/nextlotto.de",
    locations: "Remote Germany; Berlin, Germany; Konstanz, Germany",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Marketing",
    link: "https://www.nextlotto.de/#jobs",
    comments: "jobs@nextlotto.de",
  },
  {
    company: "Nference Lab",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/449Ythk.png",
    locations: "Bengaluru, India",
    role: "Software Engineer, Data Scientist, Data Engineer",
    link: "https://www.linkedin.com/jobs/view/3334295501/?capColoOverride=true",
  },
  {
    company: "ngrok",
    lastChecked: "15-Nov-2022",
    logo: "https://imgur.com/shxi4Fc.png",
    locations: "Remote US",
    role: "Software Engineer, Product Manager, Product Designer, Sales, Marketing",
    link: "https://ngrok.com/careers",
  },
  {
    company: "NIO",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/nio.com",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://www.linkedin.com/jobs/view/3319493631/?capColoOverride=true",
  },
  {
    company: "Normalyze",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/normalyze.ai",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Sales, Customer Success",
    link: "https://jobs.lever.co/normalyze",
  },
  {
    company: "Normative.io",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/FfhQ0Hz.png",
    locations: "Remote Europe",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://careers.normative.io/",
  },
  {
    company: "Nortal",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/jkeuQ8H.png",
    locations: "Remote; North America; EMEA; Ukraine; Saudi Arabia; Oman",
    role: "Software Engineer, Product Manager, Project Manager",
    link: "https://nortal.com/careers/ ",
  },
  {
    company: "Nortal ",
    lastChecked: "08-Dec-2022",
    logo: "https://logo.clearbit.com/nortal.com",
    locations:
      "Estonia; Germany; Finland; Oman; Lithuania; Serbia; Ukraine; Poland; United States; Canada; United Kingdom; Remote",
    role: "Software Engineer, Product Manager, Data Scientist, Product Designer, Finance / Accounting",
    link: "https://nortal.com/careers/",
  },
  {
    company: "Nory",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/pxlZgaD.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Product Designer",
    link: "https://nory.ai/careers/",
  },
  {
    company: "Notable",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/notablehealth.com ",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "notablehealth.com/careers",
  },
  {
    company: "Nubank",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/nubank.com.br",
    locations: "Brazil; Argentina; Colombia; México; United States",
    role: "Data Engineer, Software Engineer",
    link: "https://boards.greenhouse.io/nubank/jobs/2569175",
  },
  {
    company: "Nutanix",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/nutanix.com",
    locations: "San Jose, CA",
    role: "Software Engineer",
    link: "https://www.nutanix.com/company/careers",
  },
  {
    company: "Nvidia",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/nvidia.com",
    locations: "India; United States; Europe",
    role: "Software Engineer, Software Engineering Manager, Hardware Engineer, Program Manager, Marketing, Sales, Human Resources",
    link: "https://www.nvidia.com/en-in/about-nvidia/careers/",
  },
  {
    company: "o9 Solutions",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/o9solutions.com",
    locations: "India",
    role: "Software Engineer",
    link: "https://www.linkedin.com/company/o9solutions/jobs/",
    comments: "",
    geos: "",
  },
  {
    company: "Ocado Group",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/ocadogroup.com",
    locations:
      "London, United Kingdom; Poland; Bulgaria; Sweden; Barcelona, Spain",
    role: "Software Engineer, Engineering Manager, Data Scientist, Product Manager",
    link: "https://careers.ocadogroup.com/search?size=10",
  },
  {
    company: "Ocado Technology",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/ocadogroup.com",
    locations:
      "London, United Kingdom; Barcelona, Spain; Krakow, Poland; Stockholm, Sweden; Athens, Greece",
    role: "Software Engineer, Data Scientist, Product Designer, Product Manager",
    link: "https://careers.ocadogroup.com/careers/technology",
  },
  {
    company: "Ockam",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/OSf7QKR.png",
    locations: "Remote",
    role: "Software Engineer, Support Engineer, Design Engineer, Product Manager",
    link: "https://www.ockam.io/team#open-roles ",
  },
  {
    company: "Octopus Deploy",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/octopus.com",
    locations: "Remote",
    role: "Sales, G&A",
    link: "https://www.octopus.com/company/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Okta",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/okta.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.okta.com/uk/company/careers/#careers-job-postings?department=4183&location=All",
  },
  {
    company: "Okteto",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/UQHB3Es.png",
    locations: "Remote",
    role: "Software Engineer, Solution Architect",
    link: "https://jobs.ashbyhq.com/okteto",
  },
  {
    company: "OKX",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/8dkxjmn.png",
    locations: "San Jose, CA",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/okx ",
  },
  {
    company: "OLX Group",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/olxgroup.com",
    locations: "Barcelona, Spain; Bulgaria; India; Berlin, Germany; US",
    role: "Software Engineer",
    link: "https://careers.olxgroup.com/",
  },
  {
    company: "Olympix",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/olympix.ai",
    locations: "New York, NY",
    role: "Software Engineer",
    link: "",
    comments: "channi@olympix.ai",
  },
  {
    company: "Omio",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/u7Cnmhc.png",
    locations: "Berlin, Germany; Prague, Czech Republic",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.omio.com/corporate/jobs/#jobs",
  },
  {
    company: "Omio",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Omio.com",
    locations: "Remote CET",
    role: "Software Engineer",
    link: "https://www.omio.com/corporate/jobs/",
  },
  {
    company: "Omnipresent",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/omnipresent.com",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.omnipresent.com/jobs?gh_jid=4084326101",
  },
  {
    company: "Onko Health",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/zwgbcNB.png",
    locations: "Remote UK",
    role: "Software Engineer",
    link: "https://weworkremotely.com/company/onko-health",
  },
  {
    company: "OnLogic",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/onlogic.com",
    locations: "South Burlington, VT; Cary, NC",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Product Manager, Finance / Accounting, Recruiter, Sales, Operations",
    link: "https://www.onlogic.com/company/careers/",
  },
  {
    company: "Onward Technologies",
    lastChecked: "20-Dec-2022",
    logo: "https://imgur.com/XvvXTvy.png",
    locations: "Germany",
    role: "Software Engineer",
    link: "https://www.onwardgroup.com/careers.php",
    comments: "Sreenath_vijayan@onwardgroup.com",
    geos: "",
  },
  {
    company: "Open Systems",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/uxYC5C0.png",
    locations: "Remote",
    role: "Site Reliability / DevOps",
    link: "https://opench.bamboohr.com/careers",
  },
  {
    company: "Opencast Software",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/jMgEH6i.png",
    locations: "Remote UK",
    role: "Software Engineer, Business Analyst, QA Engineer, Product Owner",
    link: "https://www.google.com/url?q=https://www.opencastsoftware.com&sa=D&source=editors&ust=1669672528691988&usg=AOvVaw3puRiBwQiFP97YXsvG29nf",
  },
  {
    company: "OpenCorporates",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/IlcwdwL.png",
    locations: "Remote UK",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://opencorporates.com/info/about/",
  },
  {
    company: "OpenProject",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/pT4wG2T.png",
    locations: "Remote; Berlin, Germany",
    role: "Software Engineer",
    link: "https://www.openproject.org/career/",
  },
  {
    company: "Opstree",
    lastChecked: "27-Dec-2022",
    logo: "https://logo.clearbit.com/opstree.com",
    locations: "Noida, India",
    role: "Site Reliability / DevOps",
    link: "https://opstree.com/careers/",
    comments: "hr@opstree.com",
  },
  {
    company: "Optiveum",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Optiveum.com",
    locations: "Remote; Warsaw, Poland",
    role: "Software Engineer",
    link: "https://career.optiveum.com/",
  },
  {
    company: "Osome",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/osome.com",
    locations: "United Kingdom; Singapore; Netherlands",
    role: "Product Analyst, Marketing Insights Analyst",
    link: "https://careers.osome.com/",
  },
  {
    company: "Ostium",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/XYp82BE.png",
    locations: "Boston, MA; New York, NY",
    role: "Software Engineer",
    link: "https://web3.career/back-end-solana-dev-ostium-1/37020",
  },
  {
    company: "ottonova",
    lastChecked: "30-Nov-2022",
    logo: "https://logo.clearbit.com/ottonova.de",
    locations: "Munich, Germany",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager",
    link: "https://www.ottonova.de/en/company/jobs",
  },
  {
    company: "Outfit7",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Outfit7.com",
    locations: "Ljubljana, Slovenia",
    role: "Software Engineer, DevOps Engineers, Quality Assurance Engineer",
    link: "https://outfit7.com/jobs/#job-positions",
  },
  {
    company: "Outgo",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/outgo.co",
    locations: "Seattle, WA",
    role: "Software Engineer",
    link: "https://www.outgo.co/careers",
  },
  {
    company: "Outlook Amusements ",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/outlookamusements.com",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager, Product Manager",
    link: "https://outlookamusements.com/jobs/",
  },
  {
    company: "Paces",
    lastChecked: "25-Jan-2023",
    logo: "https://logo.clearbit.com/paces.com",
    locations: "New York, NY",
    role: "Software Engineer",
    link: "https://paces.com/careers",
  },
  {
    company: "Pacific Northwest National Laboratory",
    lastChecked: "20-Jan-2023",
    logo: "https://logo.clearbit.com/pnnl.gov",
    locations: "Richland, WA; Seattle, WA",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Finance / Accounting, Human Resources, Recruiter",
    link: "https://careers.pnnl.gov/",
    comments: "",
    geos: "",
  },
  {
    company: "Palandin Cyber",
    lastChecked: "27-Jan-2023",
    logo: "https://logo.clearbit.com/paladin.insure",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Product Designer, Sales, Operations",
    link: "https://boards.greenhouse.io/paladincyber",
  },
  {
    company: "Palantir",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Palantir.com",
    locations: "United States; Canada; London, United Kingdom",
    role: "Software Engineer",
    link: "https://www.palantir.com/careers/",
  },
  {
    company: "Papaya Global",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/555wGo3.png",
    locations: "Krakow, Poland; Tel-Aviv, Israel; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Software Engineering Manager",
    link: "https://www.papayaglobal.com/careers/",
  },
  {
    company: "PAR Technology ",
    lastChecked: "09-Dec-2022",
    logo: "https://logo.clearbit.com/partech.com",
    locations: "Remote",
    role: "Software Engineer, Hardware Engineer, Product Manager, Sales, Marketing, Operations, Field Technician, Installation Field Engineer",
    link: "https://partech.com/careers/",
  },
  {
    company: "Parallel Domain",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/paralleldomain.com",
    locations: "Remote; Vancouver, BC; San Francisco, CA",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Product Designer, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://paralleldomain.com/careers/",
  },
  {
    company: "Paramount",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/paramount.com",
    locations: "New York, NY; Remote",
    role: "Cloud Engineer, Network Cloud Engineer",
    link: "https://www.paramount.com/careers/experienced",
    comments: "jack.yu@paramount.com",
  },
  {
    company: "Pariveda",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/parivedasolutions.com",
    locations: "Dallas, TX; Seattle, WA; Toronto, Canada",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://careers-parivedasolutions.icims.com/jobs/search?hashed=-435745496&mobile=false&width=1140&height=500&bga=true&needsRedirect=false&jan1offset=-360&jun1offset=-300",
    comments: "",
    geos: "",
  },
  {
    company: "PartsTrader",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/8zLcvb4.png",
    locations: "Wellington, New Zealand",
    role: "Software Engineer",
    link: "https://partstrader.teamtailor.com/#section-jobs",
  },
  {
    company: "PatchRx",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/patchrx.io",
    locations: "Remote",
    role: "Software Engineer, Hardware Engineer, Product Designer, Recruiter, Sales, Operations",
    link: "https://apply.workable.com/patchrx",
  },
  {
    company: "Paxos",
    lastChecked: "08-Feb-2023",
    logo: "https://logo.clearbit.com/itbit.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://paxos.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "PAYBACK",
    lastChecked: "20-Nov-2022",
    logo: "https://imgur.com/d8GodRD.png",
    locations: "Germany",
    role: "Software Engineer",
    link: "https://www.payback.group/de/en/jobs",
  },
  {
    company: "PeakData",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/CYnhKKB.png",
    locations: "United Kingdom; Switzerland; Poland; Netherlands",
    role: "Engineering Manager",
    link: "https://peakdata.bamboohr.com/careers/78",
  },
  {
    company: "Pento",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/pento.io",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.notion.so/Principal-Backend-Engineer-Remote-3-3h-CET-ee1726318d5743dc9fd942761013bd01",
  },
  {
    company: "Percayso",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/3UbAfyg.png",
    locations: "Nottingham, UK; Remote UK",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://careers.percayso-inform.com/",
  },
  {
    company: "Perficient",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/perficient.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Sales, IT Consultant",
    link: "https://www.perficient.com/careers",
  },
  {
    company: "Personio",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/8NGa9wC.png",
    locations: "Germany; United Kingdom; Spain; Netherlands; Ireland",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.personio.com/about-personio/careers/",
  },
  {
    company: "Pfizer",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Pfizer.com",
    locations: "Greece; Ireland",
    role: "Software Engineer, Data Engineer, AI/ML Engineer",
    link: "https://pfizer.wd1.myworkdayjobs.com/PfizerCareers",
  },
  {
    company: "Phorest Salon Software",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/phorest.com",
    locations: "Remote; Dublin, Ireland",
    role: "Engineering Manager, Security Engineer, Product Manager, Software Engineer",
    link: "https://careers.phorest.com/",
  },
  {
    company: "PhotoShelter",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/VE4ix3l.png",
    locations: "Remote UK",
    role: "Software Engineer",
    link: "https://www.photoshelter.com/about/index/team/jobs",
  },
  {
    company: "Pikky",
    lastChecked: "16-Nov-2022",
    logo: "https://imgur.com/XeGVQIx.png",
    locations: "United Sates; India; Remote",
    role: "Software Engineer, Product Manager, Operations",
    link: "https://www.pikky.io/",
    comments: "humanresources@pikky.io",
  },
  {
    company: "Pilot",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/eP5XfBk.png",
    locations: "San Francisco, CA; Remote US",
    role: "Software Engineer",
    link: "https://pilot.com/careers ",
  },
  {
    company: "Ping Identity",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/2MRkcrS.png",
    locations: "United States; India",
    role: "Software Engineer",
    link: "https://jobs.jobvite.com/pingidentitycareers/jobs ",
  },
  {
    company: "Plain",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/pnux13C.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://plain-public.notion.site/Help-us-power-support-for-the-world-s-best-companies-7ea2f1a4cc084b96a95141a30e136b5b",
  },
  {
    company: "Planet",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Planet.com",
    locations: "Remote US; Canada; Germany; United Kingdom; Netherlands",
    role: "Software Engineer, Geospatial Engineer, Engineering Manager",
    link: "https://www.planet.com/company/careers/",
  },
  {
    company: "Play Sports Network",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/PlaySportsNetwork.com",
    locations: "Spain; Uniteed Kingdom; Ireland; Italy; Germany",
    role: "Software Engineer",
    link: "https://playsportsnetwork.com/jobs-2/",
  },
  {
    company: "Plum Guide",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/plumguide.com",
    locations: "London, United Kingdom; Portugal; Poland",
    role: "Software Engineer",
    link: "https://www.plumguide.com/careers",
  },
  {
    company: "Plusgrade",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/plusgrade.com",
    locations: "Canada; US; Remote",
    role: "Software Engineer, Product Manager, Data Scientist, Product Designer",
    link: "https://jobs.lever.co/plusgrade",
  },
  {
    company: "Polarsteps",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/RZusSmR.png",
    locations: "Netherlands",
    role: "Product Manager, Software Engineer",
    link: "https://careers.polarsteps.com/vacancies",
  },
  {
    company: "Poloniex",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/poloniex.com ",
    locations: "Boston, MA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Finance / Accounting",
    link: "https://poloniex.careers/",
  },
  {
    company: "Pomelo.com",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/pomelo.com",
    locations: "Remote US",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.pomelo.com/careers",
  },
  {
    company: "Popcore",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Popcore.com",
    locations: "Remote Europe",
    role: "Data Engineer, Software Engineer, System Administrator",
    link: "https://popcore.com/careers",
  },
  {
    company: "Portcast",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/portcast.io",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://jobs.lever.co/portcast",
  },
  {
    company: "PostHog",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/posthog.com",
    locations: "Remote US; Remote EU",
    role: "Software Engineer",
    link: "https://posthog.com/careers/full-stack-engineer-experimentation",
  },
  {
    company: "Potloc",
    lastChecked: "20-Jan-2023",
    logo: "https://logo.clearbit.com/potloc.com",
    locations: "Montreal, Canada",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps",
    link: "https://jobs.lever.co/Potloc",
  },
  {
    company: "Premise",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/6pemARJ.png",
    locations: "San Francisco, CA; New York, NY; Washington, DC",
    role: "Software Engineer, ML Engineer",
    link: "https://www.premise.com/careers/ ",
  },
  {
    company: "Preply",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/preply.com",
    locations: "Barcelona, Spain; Kyiv, Ukraine",
    role: "Software Engineer, Data Engineer, Site Reliability / DevOps",
    link: "https://preply.com/en/careers",
  },
  {
    company: "Preql",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/WJvrQIa.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.preql.com/careers",
  },
  {
    company: "Presspage",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/presspage.com",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://jobs.presspage.com/",
  },
  {
    company: "Prestatech",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/prestatech.com",
    locations: "Berlin, Germany",
    role: "Software Engineer",
    link: "https://apply.workable.com/prestatech/j/9D0065AEF6/",
  },
  {
    company: "Prezi",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/prezi.com",
    locations: "Remote Europe",
    role: "Software Engineer, Data Engineer, Engineering Manager",
    link: "https://prezi.com/jobs/",
  },
  {
    company: "PriceHubble",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/pricehubble.com",
    locations: "Paris, France; Berlin, Germany",
    role: "Engineering Manager",
    link: "https://careers.pricehubble.com/",
  },
  {
    company: "Prisma",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/AjlZPiS.png",
    locations: "Remote",
    role: "Software Engineering Manager",
    link: "https://boards.greenhouse.io/prisma",
  },
  {
    company: "Prismic",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/DHqAG9A.png",
    locations: "Remote Europe, Africa",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://jobs.lever.co/prismic",
  },
  {
    company: "Prodigal",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/prodigaltech.com",
    locations: "Mumbai, India; Bangalore, India; Pune, India",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Finance / Accounting",
    link: "https://jobs.lever.co/Prodigal",
    comments: "",
    geos: "",
  },
  {
    company: "Prodrive Technologies",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/prodrive-technologies.com",
    locations: "Boston, MA; Eindhoven, Netherlands; Suzhou, China",
    role: "Software Engineer, Mechanical Engineer, Electronic Engineer, Electrical Engineer, Mechatronic Engineer",
    link: "https://prodrive-technologies.com/careers",
  },
  {
    company: "Productboard",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/6vNbAYB.png",
    locations: "Europe",
    role: "Software Engineer",
    link: "https://www.productboard.com/careers/open-positions/",
  },
  {
    company: "Productsup",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/productsup.com",
    locations: "United States; Europe; India; Remote",
    role: "Software Engineer, Product Manager, Data Scientist, Product Designer, Sales, Marketing, Operations",
    link: "https://www.productsup.com/careers/open-roles/",
  },
  {
    company: "Prometheus",
    lastChecked: "01-Feb-2023",
    logo: "https://imgur.com/heZkeA0.png",
    locations: "New York, NY",
    role: "Software Engineer, Founding Engineer",
    link: "https://foggy-honeycrisp-28f.notion.site/Pinky-Chan-Referrals-Founding-Engineer-6c12106d280649a28e497e1c0e52a1a4",
  },
  {
    company: "Proofpoint",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/proofpoint.com",
    locations: "Remote",
    role: "Software Engineer, Account Manager, Marketing, Product Designer",
    link: "https://proofpoint.wd5.myworkdayjobs.com/ProofpointCareers",
  },
  {
    company: "Proton AG",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/proton.ch",
    locations: "Remote Europe",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Marketing",
    link: "https://careers.proton.me/",
  },
  {
    company: "Prove Identity",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/prove.com",
    locations: "Remote; Denver, CO; San Francisco, CA",
    role: "Software Engineer, Data Engineer",
    link: "https://www.prove.com/careers",
  },
  {
    company: "PSPDFKit",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/HRfK7sJ.png",
    locations: "Remote",
    role: "Software Engineer, Azure Architect, Support",
    link: "https://pspdfkit.com/company/careers/",
  },
  {
    company: "PushPress",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/FhHLDOk.png",
    locations: "Remote",
    role: "Director of Engineering",
    link: "https://apply.workable.com/pushpress/",
  },
  {
    company: "Q Bio",
    lastChecked: "10-Nov-2022",
    logo: "https://imgur.com/xMF7nEr.png",
    locations: "San Carlos, CA",
    role: "Software Engineer, Hardware Engineer, Site Reliability / DevOps, Product Manager",
    link: "https://q.bio/careers/",
  },
  {
    company: "Qonto",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/qonto.com",
    locations:
      "Milano, Italy; Paris, France; Berlin, Germany; Barcelona, Spain; Remote",
    role: "Software Engineer, Engineering Manager",
    link: "https://refer.hellotrusty.io/0niaoxqwa0/jobs",
  },
  {
    company: "Qt",
    lastChecked: "01-Feb-2023",
    logo: "https://logo.clearbit.com/qt.io",
    locations: "Boston, MA; Dallas, TX",
    role: "Sales, Marketing, Customer Success",
    link: "https://www.qt.io/careers",
    comments: "",
    geos: "",
  },
  {
    company: "QuantCo",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/quantco.com",
    locations: "Germany; Zurich, Switzerland; London, United Kingdom; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager",
    link: "https://jobs.lever.co/quantco-",
  },
  {
    company: "Quantstamp",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/quantstamp.com",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Auditing Engineer, Applied Cryptographer, DevOps Engineer",
    link: "https://quantstamp.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "R3",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/r3.com",
    locations: "London, Dublin, Sofia, Mumbai",
    role: "Software Engineer, Solution Engineer, Product Manager, Devops Engineer, Test Engineer",
    link: "https://www.r3.com/careers/#job-openings",
  },
  {
    company: "Radiopaper",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/radiopaper.com",
    locations: "Seattle, WA; Remote",
    role: "Software Engineer, Mobile Developer",
    link: "https://docs.google.com/document/u/1/d/e/2PACX-1vTVN7LgxoYNEE4jGpZkuJCXocZ4VLaPHRODWnNBY6F5h7PT3xVRumMw4WKImOC_-nUmKQZXE4PY5buk/pub",
  },
  {
    company: "Radware",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/Radware.com",
    locations: "New Jersey; Remote",
    role: "Security Analyst",
    link: "https://www.radware.com/careers/",
  },
  {
    company: "Raiffeisen International Bank",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/rbinternational.com",
    locations: "Vienna, Austria; Linz, Austria",
    role: "Software Engineer, Data Analyst",
    link: "https://jobs.rbinternational.com/stellenangebote.html?sid=067511cad0a25672842f8142657544fe",
  },
  {
    company: "Rally",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/rally.win",
    locations: "United States; Remote",
    role: "Software Engineer",
    link: "https://jobs.gusto.com/boards/rally-a68ee223-4f32-4395-b815-9840c3749170",
  },
  {
    company: "Ramp",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/qE2AhOG.png",
    locations: "United States; Canada",
    role: "Software Engineer",
    link: "https://ramp.com/careers ",
  },
  {
    company: "Rangle.io",
    lastChecked: "02-Feb-2023",
    logo: "https://logo.clearbit.com/rangle.io",
    locations: "Toronto, Canada; Amsterdam, Netherlands",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://rangle.io/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "RapidAPI",
    lastChecked: "18-Nov-2022",
    logo: "https://i.imgur.com/U1iqE0k.png",
    locations: "Berlin, Germany; San Francisco, CA; TelAviv, Israel",
    role: "Software Engineer",
    link: "https://jobs.lever.co/rapidapi",
  },
  {
    company: "Rartogo",
    lastChecked: "16-Jan-2023",
    logo: "https://i.imgur.com/TGoIE2N.png",
    locations: "Gurugram, India",
    role: "Software Engineer",
    link: "",
    comments: "brij@rartogo.com",
  },
  {
    company: "Rasa",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/rasa.com",
    locations: "Remote UK; Germany; Serbia",
    role: "Software Engineer, Data Scientist, Marketing, Customer success",
    link: "https://rasa.com/careers/#jobs",
  },
  {
    company: "Ravelin",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/ravelin.com",
    locations: "Remote EU; London, United Kingdom",
    role: "Software Engineer",
    link: "https://www.ravelin.com/careers",
  },
  {
    company: "Raven Industries",
    lastChecked: "08-Feb-2023",
    logo: "https://logo.clearbit.com/ravenind.com",
    locations: "Scottsdale, AZ; Sioux Falls, SD",
    role: "Software Engineer",
    link: "https://ravenind.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Ravio",
    lastChecked: "28-Nov-2022",
    logo: "https://i.imgur.com/Flwac32.png",
    locations: "Remote UK",
    role: "Software Engineer",
    link: "https://jobs.ashbyhq.com/Ravio",
  },
  {
    company: "ReachMobi",
    lastChecked: "19-Jan-2023",
    logo: "https://logo.clearbit.com/reachmobi.com",
    locations: "Bonita Springs, FL; Philadelphia, PA; Kansas City, MO",
    role: "Software Engineer, Product Manager, Data Scientist, Marketing, Data Analyst, Product Development Leader, Digital Media Buyer",
    link: "https://reachmobi.com/about-us/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "Recharge",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/rechargeapps.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Product Designer, Staff Analyst",
    link: "https://rechargepayments.com/careers/",
  },
  {
    company: "Recology",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/recology.com",
    locations:
      "San Francisco, CA; Seattle, WA; Portland, OR; Dixon, CA; San Carlos, CA; Remote; Sacramento, CA",
    role: "Finance / Accounting, Human Resources, Sales, Operations, Business Analyst, Customer Service Specialist",
    link: "https://www.recology.com/Careers",
  },
  {
    company: "Red Hat",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/redhat.com",
    locations: "Remote",
    role: "Software Engineer, Solutions Architect, Sales, Project Manager, Marketing",
    link: "https://jobs.redhat.com",
  },
  {
    company: "Red Sift",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/w91qjkZ.png",
    locations: "Remote Europe",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://careers.redsift.com/",
  },
  {
    company: "Redpanda",
    lastChecked: "18-Nov-2022",
    logo: "https://i.imgur.com/3NkSlyF.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Marketing, Product Design, Product Manager",
    link: "https://redpanda.com/careers",
  },
  {
    company: "Reedsy",
    lastChecked: "21-Nov-2022",
    logo: "https://i.imgur.com/MWuZB14.png",
    locations: "Europe",
    role: "Software Engineer, Data Engineer",
    link: "https://apply.workable.com/reedsy/?lng=en#jobs",
  },
  {
    company: "Reev",
    lastChecked: "15-Dec-2022",
    logo: "https://logo.clearbit.com/reev.com",
    locations: "Munich, Germany; Zagreb, Croatia",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://reev.com/en_de/jobs-2/",
  },
  {
    company: "Refermarket",
    lastChecked: "01-Feb-2023",
    logo: "https://logo.clearbit.com/rfrmkt.com",
    locations: "Worlwide",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://app.refermarket.com/",
  },
  {
    company: "Regalix",
    lastChecked: "19-Jan-2023",
    logo: "https://imgur.com/jUnVWLr.png",
    locations: "Hyderabad, India; Bangalore, India",
    role: "Finance / Accounting, Sales, Operations",
    link: "https://careers.regalix.com",
    comments: "Muneeruddin@regalix-inc.com",
  },
  {
    company: "Regrow",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/regrow.ag",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Sales, Marketing",
    link: "https://grnh.se/d88651414us",
  },
  {
    company: "Reliance Industries Limited",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/ril.com",
    locations: "India",
    role: "Software Engineer, Product Manager",
    link: "https://careers.ril.com/rilcareers/",
  },
  {
    company: "Relive",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/iVakyXC.png",
    locations: "Remote",
    role: "CTO",
    link: "https://jobs.relive.cc/",
  },
  {
    company: "RELX",
    lastChecked: "24-Jan-2023",
    logo: "https://imgur.com/DZjrvRK.png",
    locations: "Mumbai, India; Chennai, India; Gurgaon, India",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://relx.wd3.myworkdayjobs.com/relx",
    comments: "",
    geos: "",
  },
  {
    company: "Remitly",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/remitly.com",
    locations: "Remote US; Seattle, WA",
    role: "Data Analyst, Software Engineer, Engineering Manager, Account Manager",
    link: "https://careers.remitly.com/",
  },
  {
    company: "RepairSmith",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/repairsmith.com",
    locations:
      "Los Angeles, CA; Phoenix, AZ; Austin, TX; Dallas, TX; Portland, OR; Miami, FL; Chicago, IL",
    role: "Software Engineer, Recruiter, Sales, Operations, Customer Service",
    link: "https://www.repairsmith.com/careers",
  },
  {
    company: "Replicant",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/replicant.ai",
    locations: "Canada; United States; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Recruiter, Marketing",
    link: "https://jobs.lever.co/replicant",
  },
  {
    company: "Replicon",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/replicon.com",
    locations: "Bay Area, CA; United States; Calgary, Canada; Remote",
    role: "Software Engineer, Software Engineering Manager, Sales, Marketing",
    link: "https://www.replicon.com/company/careers",
    comments: "",
    geos: "",
  },
  {
    company: "ResearchGate",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/ResearchGate.com",
    locations: "Germany; United Kingdom; Poland",
    role: "Software Engineer, Data Scientist, Product Manager",
    link: "https://www.researchgate.net/careers",
  },
  {
    company: "Resmed",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/resmed.com",
    locations: "San Diego, CA; Remote",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://careers.resmed.com/",
    comments: "Sibon.barman@resmed.com",
  },
  {
    company: "Resumedia",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/E9EWbu0.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://resumedia.recruitee.com/o/backend-cloud-developer",
  },
  {
    company: "Retain.ai",
    lastChecked: "31-Jan-2023",
    logo: "https://logo.clearbit.com/retain.ai",
    locations: "Remote; United States; Canada",
    role: "Software Engineer",
    link: "https://retain.ai/careers",
  },
  {
    company: "Retool",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/retool.com",
    locations: "San Francisco, CA; New York, NY; Seattle, WA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Human Resources, Recruiter, Sales, Marketing",
    link: "https://retool.com/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "Revenue Analytics",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/revenueanalytics.com",
    locations: "Remote US",
    role: "Software Architect, Software Engineer, QA Engineer",
    link: "https://www.revenueanalytics.com/careers",
  },
  {
    company: "RevenueCat",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/hGF2Cid.png",
    locations: "Remote",
    role: "Data Engineer, Software Engineer, Solutions Engineer",
    link: "https://boards.greenhouse.io/revenuecat",
  },
  {
    company: "Reverie Labs",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/reverielabs.com",
    locations: "Boston, MA; Remote",
    role: "Software Engineer",
    link: "https://www.reverielabs.com/careers",
  },
  {
    company: "Revolut",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/Q3mzSb8.png",
    locations:
      "Krakow, Poland; Madrid, Spain; Porto, Portugal; Vilnius, Lithuania",
    role: "Software Engineer, Software Engineering Manager, Data Analyst, Data Scientist",
    link: "https://www.revolut.com/careers/ ",
  },
  {
    company: "Ribbon Health",
    lastChecked: "06-Dec-2022",
    logo: "https://i.imgur.com/6aXyRiY.png",
    locations:
      "New York; Connecticut; New Jersey; Massachusetts; Philadelphia; California",
    role: "Software Engineer, Product Manager, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://www.ribbonhealth.com/careers",
  },
  {
    company: "Ridgeline",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/ridgelineapps.com",
    locations: "Reno, NV; Incline Village, NV; New York, NY; San Ramon, CA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Human Resources",
    link: "https://www.ridgelineapps.com/about/careers",
  },
  {
    company: "Ridgeline International",
    lastChecked: "06-Dec-2022",
    logo: "https://i.imgur.com/Eha5H1l.png",
    locations: "Washington DC; Remote",
    role: "Software Engineer, Linux System Administrators",
    link: "https://careers.smartrecruiters.com/RidgelineInternational",
  },
  {
    company: "Rippling",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/rippling.com",
    locations:
      "Remote US; Seattle, WA; San Francisco, CA; New York, NY; Poland; Remote EU; Bengaluru, India",
    role: "Engineering Manager, Software Engineer",
    link: "https://www.rippling.com/careers",
  },
  {
    company: "Riverside.fm",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/v4eb3dg.png",
    locations: "Israel",
    role: "Software Engineer",
    link: "https://riverside.fm/careers ",
  },
  {
    company: "Ro",
    lastChecked: "01-Feb-2023",
    logo: "https://logo.clearbit.com/ro.co",
    locations: "New York, NY; Remote East Coast",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Marketing, Legal, Medical Affairs, Pharmacy",
    link: "https://ro.co/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "Roche",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/roche.com",
    locations: "Sant Cugat des Valles, Spain; Switzerland; San Francisco, CA",
    role: "Software Engineer, UX Designer, Product Owner, DevOps Engineer, DevSecOps Engineer, Security Engineer",
    link: "https://go.roche.com/vcunp",
  },
  {
    company: "Rocksbox",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/tUxg6k3.png",
    locations: "Remote PST",
    role: "Software Engineer",
    link: "https://www.rocksbox.com/careers ",
  },
  {
    company: "Rockstar Games",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/rockstargames.com",
    locations: "New York City, NY; San Diego, CA; Toronto, Canada; Andover, MA",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist",
    link: "https://www.rockstargames.com/careers",
  },
  {
    company: "Rodeo",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/wszleR3.png",
    locations: "Amsterdam, Netherlands; New York, NY; Atlanta, GA",
    role: "Head of Product",
    link: "https://www.getrodeo.io/company/career",
  },
  {
    company: "Rodeo Software",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/dtGQ4cQ.png",
    locations: "Amsterdam, Netherlands; Noord Holland, Netherlands",
    role: "Head of Product, Software Engineer",
    link: "https://www.getrodeo.io/company/career",
  },
  {
    company: "Roslaind",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/rosalind.bio",
    locations: "San Diego, CA",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Marketing",
    link: "https://www.rosalind.bio/join-the-rosalind-team",
  },
  {
    company: "SadaPay",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/Jtz78VD.png",
    locations: "Remote",
    role: "Software Engineer, Data Engineer, Data Analyst, DevOps, Product Manager",
    link: "https://sadapay.pk/careers/",
  },
  {
    company: "Safe",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/safe.global",
    locations: "Berlin, Germany; Lisbon, Portugal; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Senior Solidity Engineer, Developer Relations Lead, Senior Product Manager, Senior Data Analyst",
    link: "https://safe.global/careers#positions",
    comments: "Visa and relocation assistance offered",
  },
  {
    company: "Sagely",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/growsagely.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.growsagely.com/careers",
  },
  {
    company: "Sahaj Software",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/sahaj.ai",
    locations: "United Kingdom; Australia; India; United States",
    role: "Software Engineer",
    link: "https://www.linkedin.com/jobs/view/3283871694/?refId=1cOMcQEXjtxnGV1Qt209kQ%3D%3D&trackingId=qW%2FOdlRDRw5un1vkxJVH9w%3D%3D",
  },
  {
    company: "Sail Trip Mallorca",
    lastChecked: "12-Dec-2022",
    logo: "https://logo.clearbit.com/sailtripmallorca.com",
    locations: "Palma, Spain",
    role: "Sales",
    link: "",
    comments: "sailtripmallorca@gmail.com",
  },
  {
    company: "Sainsbury's",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Sainsburys.com",
    locations:
      "London, United Kingdom; Manchester, United Kingdom; Coventry, United Kingdom; Remote",
    role: "Engineering Manager, Software Engineer, Data Scientist",
    link: "https://sainsburys.jobs/roles/digital-tech-data/",
  },
  {
    company: "SaltPay",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/u6OUv3Z.png",
    locations: "Europe; Cape Town, South Africa",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.saltpay.co/careers",
  },
  {
    company: "SambaNova Systems",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/sambanova.ai",
    locations: "Palo Alto, CA; Austin, TX",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager",
    link: "https://sambanova.ai/sambanova-available-positions/",
  },
  {
    company: "Samsara",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/samsara.com",
    locations: "Remote",
    role: "Software Engineer, Sales Engineer, Data Scientist, Data Engineer, UI Designer, Marketing",
    link: "https://www.samsara.com/company/careers/roles",
  },
  {
    company: "Sandia National Laboratories",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/sandia.gov",
    locations:
      "Livermore, CA; Albuquerque, NM; Remote; Carlsbad, NM; Amarillo, TX",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Finance / Accounting, Human Resources, Recruiter, Marketing, Operations",
    link: "https://sandia.jobs/",
  },
  {
    company: "Sanofi",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/sanofi.com",
    locations: "Cambridge, MA",
    role: "Data Scientist",
    link: "https://sanofi.wd3.myworkdayjobs.com/SanofiCareers/job/Cambridge-MA/Data-Scientist--Industrial-Affairs_R2667279",
  },
  {
    company: "SAP",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/SAP.com",
    locations: "Remote US; Remote Canada",
    role: "Software Engineer",
    link: "https://jobs.sap.com/search/",
  },
  {
    company: "Satcon",
    lastChecked: "13-Jan-2023",
    logo: "https://i.imgur.com/uSftEq2.jpeg",
    locations: "Remote US",
    role: "Data Engineer",
    link: "https://www.linkedin.com/company/satconinc/jobs/",
    comments: "",
    geos: "",
  },
  {
    company: "Satellite Vu",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/satellitevu.com",
    locations: "London, United Kingdom",
    role: "Software Engineer, Product Manager",
    link: "https://www.satellitevu.com/careers",
  },
  {
    company: "Satispay",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/IyK8AHh.png",
    locations: "Milan, Italy",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://apply.workable.com/satispay/ ",
  },
  {
    company: "Scalable Capital",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/XS2PfTg.png",
    locations: "Berlin, Germany; Munich, Germany; Remote",
    role: "Software Engineer, Software Engineering Manager, Data Engineer",
    link: "https://de.scalable.capital/careers ",
  },
  {
    company: "Scandit",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/Z1yRgib.png",
    locations: "Remote Europe",
    role: "Software Engineering Manager, Software Engineer, Site Reliability / DevOps, Test Automation Engineer",
    link: "https://www.scandit.com/careers/current-openings/",
  },
  {
    company: "Schuettflix",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Schuettflix.com",
    locations: "Germany",
    role: "Engineering Manager, Software Engineer",
    link: "https://schuettflix.com/global/en/jobs/",
  },
  {
    company: "Scribble Data",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/ScribbleData.io",
    locations: "Toronto, Canada; Bengaluru, India; Remote",
    role: "Software Engineer",
    link: "https://www.scribbledata.io/careers/",
  },
  {
    company: "Secfi",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/wiUt5Hk.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://secfi.com/about-us/careers",
  },
  {
    company: "Second Order Effects",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/soeffects.com",
    locations: "El Segundo, CA; Redmond, WA",
    role: "Software Engineer, Hardware Engineer, Operations, Electrical Engineer, Mechanical Engineer, Firmware Engineer, Test Software Engineer, Systems Engineer, Test Engineer, Engineering Physicist, Engineering Technician, Technical Project Manager, Operations Lead, Operations Coordinator, Electrical Engineering Manager, Mechanical Engineering Major, PCB Designer",
    link: "https://boards.greenhouse.io/soeffects",
  },
  {
    company: "Secureframe",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/secureframe.com",
    locations: "Remote US; Remote Canada",
    role: "Customer Success Manager, Marketing, Software Engineer",
    link: "https://secureframe.com/careers",
  },
  {
    company: "SeeviceNow",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/servicenow.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://careers.servicenow.com/careers",
  },
  {
    company: "Seismic",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/KEDA4KJ.png",
    locations:
      "Remote; New York, NY; Chicago, IL; San Diego, CA; Toronto, Canada",
    role: "Software Engineer, Cloud Engineer, Site Reliability / DevOps",
    link: "https://seismic.com/careers/job-listings/ ",
  },
  {
    company: "Semrush",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/w2LpSNF.png",
    locations: "Remote Europe",
    role: "Software Engineer",
    link: "https://careers.semrush.com/en/jobs/ ",
  },
  {
    company: "sennder",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/l87oXRy.png",
    locations: "Berlin, Germany; Barcelona, Spain; Remote",
    role: "Software Engineering Manager, Software Engineer, ML Engineer, Data Scientist",
    link: "https://www.sennder.com/career",
  },
  {
    company: "Seqera Labs",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/XSTHbbW.png",
    locations: "Europe",
    role: "Software Engineer",
    link: "https://seqera.io/careers/",
  },
  {
    company: "Sequin",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/sequin.io",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://sequin.notion.site/Sequin-is-Hiring-94fbe23db23847fc92c778a2e1428f98",
  },
  {
    company: "SERES",
    lastChecked: "12-Dec-2022",
    logo: "https://logo.clearbit.com/driveseres.com",
    locations: "Milpitas, CA",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager",
    link: "https://driveseres.com/career/",
  },
  {
    company: "SerpApi",
    lastChecked: "12-Dec-2022",
    logo: "https://logo.clearbit.com/serpapi.com",
    locations: "Remote",
    role: "Product Designer",
    link: "https://jobs.polymer.co/serpapi-llc",
  },
  {
    company: "SES",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/ses.com",
    locations:
      "Betzdorf, Luxembourg; Munich, Germany; Manassas, VA; Woodbine, MD; Washington, DC; The Hague, Netherlands; Bucharest, Romania",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://www.ses.com/company/careers",
    comments: "kevin.gonzalez@ses.com",
  },
  {
    company: "Shape",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/shape.dk",
    locations: "Copenhagen, Denmark; Remote",
    role: "Software Engineer",
    link: "https://careers.shape.dk/",
  },
  {
    company: "Shape Digital",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/shapedigital.com",
    locations: "Rio de Janeiro, Brazil; Sao Paulo, Brazil; Singapore",
    role: "Software Engineer, Engineering Manager",
    link: "https://apply.workable.com/shapedigital/j/7A0718DAD5/",
  },
  {
    company: "Shell Recharge",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/shellrecharge.com",
    locations: "United States; Europe; India; Singapore",
    role: "Software Engineer, Engineering Manager",
    link: "https://shellrecharge.com/en-gb/solutions/company/careers",
  },
  {
    company: "Shop Apotheke Europe",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/waHIy8T.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://shopapothekeeurope.recruitee.com/",
  },
  {
    company: "SiFive",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/sifive.com",
    locations: "Austin, TX; Santa Clara, CA",
    role: "Software Engineer, Hardware Engineer, Site Reliability / DevOps, Product Manager",
    link: "https://www.sifive.com/careers",
  },
  {
    company: "Sift",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/siftscience.com",
    locations: "San Francisco, CA; Seattle, WA; Remote US; Ireland; Ukraine",
    role: "Software Engineer, Product Manager, Sales, Operations",
    link: "https://sift.com/careers",
  },
  {
    company: "Sigma Computing",
    lastChecked: "30-Jan-2023",
    logo: "https://imgur.com/VKgoZ2S.png",
    locations: "San Francisco, CA; New York, NY",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.sigmacomputing.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Signal",
    lastChecked: "22-Nov-2022",
    logo: "https://i.imgur.com/EFn3pgg.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "Signal.org/workworkwork",
  },
  {
    company: "Signiant",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/signiant.com",
    locations: "Ottawa, Canada; Boston, MA; Germany",
    role: "Software Engineer, Sales",
    link: "https://www.signiant.com/company/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "SimplePractice",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/simplepractice.com",
    locations: "Santa Monica, CA; Remote",
    role: "Software Engineer, Marketing, Operations, Customer Success Manager",
    link: "https://www.simplepractice.com/careers/#grnhse_app",
  },
  {
    company: "Simply Business",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/SimplyBusiness.com",
    locations: "London, United Kingdom; Boston, MA; Atlanta, GA",
    role: "Software Engineer, Data Engineer, Product Manager",
    link: "https://www.simplybusiness.co.uk/about-us/careers/jobs/",
  },
  {
    company: "Simpplr",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/simpplr.com",
    locations:
      "Toronto, Canada; Brighton, United Kingdom; Bengaluru, India; Gurugram, India",
    role: "Software Engineer",
    link: "https://www.simpplr.com/about/careers/",
  },
  {
    company: "Simulmedia",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/simulmedia.com",
    locations: "New York, NY",
    role: "Software Engineer, Sales, Marketing",
    link: "https://www.simulmedia.com/careers",
  },
  {
    company: "Sinch",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/cPJNQdB.png",
    locations: "United States; India; Spain; Sweden",
    role: "Software Engineer, Security Specialist, Data Engineer",
    link: "https://www.sinch.com/careers/",
  },
  {
    company: "Sioux Technologies",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Sioux.eu",
    locations: "Eindhoven, Netherlands",
    role: "Software Engineer",
    link: "https://www.sioux.eu/jobs/",
  },
  {
    company: "Sitemark",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/sitemark.com",
    locations: "Leuven, Belgium",
    role: "Software Engineer",
    link: "https://www.sitemark.com/company/careers/",
  },
  {
    company: "Skutopia",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/Ae8HC5I.png",
    locations:
      "Remote Australia; Sydney, Australia; New South Wales, Australia",
    role: "Engineering Manager, Software Engineer",
    link: "https://skutopia.com/careers-tech/",
  },
  {
    company: "Sky Portugal",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/37j8Ev8.png",
    locations: "Lisbon, Portugal; Aveiro, Portugal; Funchal, Portugal",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.linkedin.com/company/skyportugal/jobs/",
  },
  {
    company: "SlashID",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/SlashID.com",
    locations: "Remote EU; Remote US; Remote Canada; Remote Latin America",
    role: "Software Engineer",
    link: "https://www.slashid.dev/careers/",
  },
  {
    company: "Sling TV",
    lastChecked: "07-Dec-2022",
    logo: "https://logo.clearbit.com/sling.com",
    locations: "Denver, CO",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Marketing",
    link: "https://careers.dish.com/",
  },
  {
    company: "SmartRent",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/smartrent.com",
    locations: "Scottsdale, AZ; Orlando, FL; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Marketing, Operations",
    link: "https://smartrent.com/careers/apply/",
  },
  {
    company: "Snowflake",
    companyDomain: "snowflake.com",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/xTmHrZh.png",
    locations: "United States; EMEA",
    role: "Software Engineers, Engineering Manager, Product Manager, Product Security, Data Scientist",
    link: "https://careers.snowflake.com/us/en",
  },
  {
    company: "Socure",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/socure.com",
    locations: "Remote US; Remote India",
    role: "Software Engineer",
    link: "https://www.socure.com/company/careers#job_details",
  },
  {
    company: "Soloplan",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/soloplan.de",
    locations: "Kempten, Germany; Dresden, Germany; Katowice, Poland",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.soloplan.de/karriere/",
  },
  {
    company: "Songbird Therapy",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/songbirdcare.com",
    locations: "Remote; San Francisco, CA; New York, NY",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/songbirdtherapy",
  },
  {
    company: "Sonos",
    lastChecked: "30-Jan-2023",
    logo: "https://imgur.com/LTiDwGD.png",
    locations: "Boston, MA; Seattle, WA; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist",
    link: "https://sonos.wd1.myworkdayjobs.com/Sonos",
    comments: "",
    geos: "",
  },
  {
    company: "Sony Music",
    lastChecked: "16-Nov-2022",
    logo: "https://logo.clearbit.com/sonymusic.com",
    locations: "New York City, NY; London, UK; Remote",
    role: "Software Engineer, Product Manager, Product Designer, Human Resources",
    link: "https://boards.greenhouse.io/sonymusic/jobs/6354828002",
  },
  {
    company: "Sotheby's",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/sothebys.com",
    locations: "Remote US",
    role: "Software Engineer, Data Engineer, Product Manager",
    link: "https://boards.greenhouse.io/sothebys",
  },
  {
    company: "SpaceX",
    companyDomain: "spacex.com",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/spacex.com",
    locations: "Hawthorne, CA",
    role: "Recruiter",
    link: "https://grnh.se/c78cbd282us",
  },
  {
    company: "SPAN",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/span.io ",
    locations: "San Francisco, CA; Atlanta, GA; Milwaukee, WI",
    role: "Software Engineer, Hardware Engineer, Data Scientist, Human Resources, Recruiter, Sales, Operations",
    link: "https://www.span.io/mission-careers",
    comments: "",
    geos: "",
  },
  {
    company: "SpanIdea Systems ",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/spanidea.com",
    locations: "Bangalore, India",
    role: "Software Engineer, Human Resources, Recruiter, Linux SME, Golang Developer, SA Architecture",
    link: "https://careers.spanidea.com/?_ga=2.172787115.902197743.1669031950-559253528.1669031950",
    comments: "harinia@spanidea.com",
  },
  {
    company: "Sparkwise",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/sparkwise.co",
    locations: "Remote US",
    role: "Product Engineer",
    link: "https://sparkwise.co/careers",
  },
  {
    company: "Sphinx Defense",
    lastChecked: "05-Dec-2022",
    logo: "https://logo.clearbit.com/sphinxdefense.com",
    locations:
      "Remote US; Colorado Springs, CO; Washington, DC; Los Angeles, CA",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Recruiter",
    link: "https://www.sphinxdefense.com/#Careers",
  },
  {
    company: "SpotHero",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/spothero.com",
    locations: "Chicago, IL; Toronto, Canada",
    role: "Software Engineer, Data Engineer, Data Scientist",
    link: "https://spothero.com/careers-engineering",
  },
  {
    company: "Spotnana",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/vANTtDm.png",
    locations: "Seattle, WA; Bay Area, CA",
    role: "Software Engineer",
    link: "https://www.spotnana.com/careers/#positions ",
  },
  {
    company: "Spreadex",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Spreadex.com",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://www.spreadex.com/financials/careers/",
  },
  {
    company: "Springboard",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/vyEjm9Z.png",
    locations: "Bangaluru, India",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobs.lever.co/springboard?department=Technology",
  },
  {
    company: "Stack Overflow",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/StackOverflow.co",
    locations: "Remote US; Remote UK; Remote Germany; Remote Canada",
    role: "Software Engineer, Engineering Manager, Data Engineer",
    link: "https://stackoverflow.co/company/work-here",
  },
  {
    company: "Stakefish",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/VibZi3Z.png",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://stake.fish/jobs ",
  },
  {
    company: "Starburst",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Starburst.io",
    locations: "Remote US",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.starburst.io/careers/open-roles/",
  },
  {
    company: "Starling Bank",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/starlingbank.com",
    locations: "United Kingdom; Ireland; Remote",
    role: "Software Engineer, Data Scientists",
    link: "https://www.starlingbank.com/careers/",
  },
  {
    company: "StartupTAP",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/startuptap.com",
    locations: "California; New York; Remote US",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Sales, Marketing, Operations",
    link: "https://jobs.lever.co/startuptap",
  },
  {
    company: "Stellar Development Foundation",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/stellar.org",
    locations: "San Francisco, CA; New York, NY; Remote US; Remote Canada",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Marketing",
    link: "https://grnh.se/911f65244us",
  },
  {
    company: "StevenDouglas",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/stevendouglas.com",
    locations: "United States",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Finance / Accounting, Human Resources, Recruiter, Operations",
    link: "https://www.stevendouglas.com/who-we-are/careers",
  },
  {
    company: "Storytel",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/VUZAmEF.png",
    locations: "Worldwide",
    role: "Software Engineer, Data Engineer, Content Coordinator, CRM Manager, Finance / Accounting, Product Manager, Product Designer",
    link: "https://jobs.storytel.com/jobs ",
  },
  {
    company: "Stratoflow",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/stratoflow.com",
    locations: "Poland",
    role: "Software Engineer",
    link: "https://stratoflow.com/careers/",
  },
  {
    company: "Stream",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/getstream.io",
    locations: "Amsterdam, Netherlands; Boulder, CO; Portugal; Remote EMEA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Recruiter, Marketing, Operations",
    link: "https://getstream.io/team/",
  },
  {
    company: "STRM Privacy",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/strmprivacy.io",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer",
    link: "https://join.strm.team",
  },
  {
    company: "StrongDM",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/strongDM.com",
    locations: "Remote",
    role: "Software Engineer, Sales",
    link: "https://www.strongdm.com/careers",
  },
  {
    company: "Stuart",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Stuart.com",
    locations:
      "Remote CET; Remote GMT; France; Spain; Italy; United Kingdom; Poland; Portugal",
    role: "Data Engineering Manager",
    link: "https://stuart.com/careers/",
  },
  {
    company: "Studocu",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/huHdssx.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer, Engineering Manager",
    link: "https://jobs.studocu.com/",
  },
  {
    company: "Summus Global",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/summusglobal.com",
    locations: "New York, NY; Boston, MA; Remote",
    role: "Software Engineer",
    link: "https://apply.workable.com/summusglobal/j/89C800A760/",
  },
  {
    company: "SumUp",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/ZK0U7Ji.png",
    locations: "Berlin, Germany",
    role: "Software Engineer, Analytics Lead, Technical Product Manager",
    link: "https://www.sumup.com/careers/positions/ ",
  },
  {
    company: "SunPower",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/0gVkth2.png",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Technical Program Manager",
    link: "https://jobs.lever.co/sunpower",
  },
  {
    company: "Sunsave",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Sunsave.energy",
    locations: "London, United Kingdom; Remote UK",
    role: "Software Engineer",
    link: "https://app.otta.com/jobs/VytxNy9R",
  },
  {
    company: "Sunstone Credit",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/sunstonecredit.com",
    locations: "Remote US",
    role: "Software Engineer",
    link: "Sunstonecredit.com/careers",
  },
  {
    company: "Sunweb Group",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/sunwebgroup.com",
    locations: "Rotterdam, Netherlands; Barcelona, Spain",
    role: "Engineering Manager, Software Engineer",
    link: "https://jobs.sunwebgroup.com/",
  },
  {
    company: "Superblocks",
    lastChecked: "01-Dec-2022",
    logo: "https://logo.clearbit.com/Superblocks.com",
    locations: "New York, NY; San Francisco, CA",
    role: "Software Engineer, Product Designer, Product Manager, Solutions Engineer, Marketing, Sales",
    link: "https://boards.greenhouse.io/superblocks",
  },
  {
    company: "SupplyPike",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/supplypike.com",
    locations: "Fayetteville, AR; Remote CST",
    role: "Software Engineer, Engineering Manager, Technical Program Manager, Site Reliability Engineer",
    link: "https://careers.supplypike.com/",
  },
  {
    company: "Sweco",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/sweco.se",
    locations: "Sweden",
    role: "Software Engineer, Project Manager, Solution Architect",
    link: "https://www.sweco.se/karriar/lediga-tjanster/?scroll-to=vacancies-top&area=174&region=&search=",
  },
  {
    company: "Swisslog",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/VnpDN46.png",
    locations:
      "Germany; United Kingdom; United States; Italy; Switzerland; Malaysia; France",
    role: "Software Engineer",
    link: "https://www.swisslog.com/en-us/about-swisslog/careers ",
  },
  {
    company: "Swoop",
    lastChecked: "15-Nov-2022",
    logo: "https://i.imgur.com/02vcWDf.png",
    locations: "Remote; Los Angeles, CA",
    role: "Software Engineer, Product Designer, Sales",
    link: "",
    comments: "michael@swoopapp.com",
  },
  {
    company: "Synectics Solutions",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/cJXauVQ.png",
    locations: "United Kingdom",
    role: "Software Engineer, Human Resources, Operations",
    link: "https://careers.synectics-solutions.com/vacancies/vacancy-search-results.aspx",
  },
  {
    company: "Synthesia",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/X6aO0T8.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.synthesia.io/careers ",
  },
  {
    company: "TalentMP",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/bqFkiIv.png",
    locations: "Global",
    role: "Software Engineer",
    link: "https://talentmp.com/ ",
  },
  {
    company: "Tata Consultancy Services",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/tcs.com",
    locations: "India; United States",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Human Resources, Recruiter",
    link: "https://www.tcs.com/careers",
  },
  {
    company: "Tatari",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/tatari.tv",
    locations: "San Francisco, CA; Los Angeles, CA; New York, NY; Remote",
    role: "Software Engineer, Data Engineer, Engineering Manager",
    link: "https://www.tatari.tv/careers",
  },
  {
    company: "TaxBit",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/8j8nKtQ.png",
    locations:
      "Seattle, WA; Salt Lake, UT; San Fransisco, CA; New York, NY; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Product Designer",
    link: "https://taxbit.com/careers",
  },
  {
    company: "TaxScouts",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/TaxScouts.com",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://taxscouts.com/careers/senior-frontend-developer-react/",
  },
  {
    company: "Teads",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/WNiuiRS.png",
    locations: "Paris, France; Montpellier, France; Bucharest, Romania",
    role: "Software Engineer, Data Scientist, Software Engineering Manager, Product Manager, Data Analyst, Product Designer",
    link: "https://engineering.teads.com/jobs/",
  },
  {
    company: "Teamwork",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/qnu2xzz.png",
    locations:
      "Ireland; United kingdom; Spain; Germany; Poland; Bulgaria; Netherlands",
    role: "SysOps Lead, Product Manager",
    link: "https://www.teamwork.com/careers",
  },
  {
    company: "Tecton.ai",
    lastChecked: "20-Nov-2022",
    logo: "https://logo.clearbit.com/tecton.ai",
    locations: "San Francisco, CA; New York, NY; Remote",
    role: "Software Engineer, Product Manager",
    link: "https://jobs.lever.co/tecton?lever-via=akfSFQQeRm",
  },
  {
    company: "TELUS",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/telus.com ",
    locations: "Canada; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Sales, Marketing, Operations",
    link: "https://www.telus.com/en/about/careers",
  },
  {
    company: "Tempest",
    lastChecked: "30-Jan-2023",
    logo: "https://imgur.com/l9hB3pl.png",
    locations: "Ireland; Germany; France; Portugal",
    role: "Software Engineer, Software Engineering Manager, Product Manager",
    link: "https://tempesttechinc.applytojob.com/apply",
  },
  {
    company: "Temporal",
    lastChecked: "12-Dec-2022",
    logo: "https://logo.clearbit.com/temporal.io",
    locations: "Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Sales",
    link: "https://temporal.io/careers",
  },
  {
    company: "Tempus",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/wgSkhiN.png",
    locations: "Chicago, IL; New York, NY; Bay Area, CA; Remote US",
    role: "Software Engineer",
    link: "https://www.tempus.com/job-postings/",
  },
  {
    company: "Tenzo",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/gotenzo.com",
    locations: "London, UK",
    role: "Software Engineer, Product Designer, Software Tester/QA Analyst",
    link: "https://careers.gotenzo.com/",
  },
  {
    company: "Tesco",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/N3BHFAA.png",
    locations: "London, UK",
    role: "ML Engineer, Software Engineer",
    link: "https://www.tesco-careers.com/",
  },
  {
    company: "Tessian",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Tessian.com",
    locations: "Remote UK",
    role: "Software Engineer, Senior Data Engineer",
    link: "https://www.tessian.com/careers/jobs-list/",
  },
  {
    company: "The Athletic",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/QXngoI7.png",
    locations: "United States; Remote",
    role: "Data Analyst, Sales, Editor, Software Engineer, Product Manager",
    link: "https://jobs.lever.co/theathletic",
  },
  {
    company: "The Body Coach",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/T25avp4.png",
    locations: "United Kingdom",
    role: "Software Engineer, Product Manager",
    link: "https://apply.workable.com/the-body-coach/?lng=en",
  },
  {
    company: "The Chain Company ",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/F00zVgg.png",
    locations: "Netherlands",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager",
    link: "https://www.thechaincompany.nl/vacatures",
  },
  {
    company: "The New York Times",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/h8dL4Xc.png",
    locations: "Remote - US",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.nytco.com/careers/ ",
  },
  {
    company: "The RealReal",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/therealreal.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.therealreal.com/careers",
  },
  {
    company: "The Very Group",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/TheVeryGroup.com",
    locations: "Liverpool, United Kingdom",
    role: "Software Engineer",
    link: "https://theverygroup.jobs/",
  },
  {
    company: "The Washington Post",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/washingtonpost.com",
    locations: "New York, NY; Washington D.C; Remote",
    role: "Software Engineer",
    link: "https://washpost.wd5.myworkdayjobs.com/en-US/washingtonpostcareers/details/Senior-Software-Engineer_JR-90274112?jobFamilyGroup=79ba452a16f2106b1e23978d0bff16c0",
  },
  {
    company: "TheyDo",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/JdMeVPG.png",
    locations: "EMEA",
    role: "Software Engineer",
    link: "https://jobs.theydo.io/ ",
  },
  {
    company: "Thndr",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/Fv9vGy7.png",
    locations: "Cairo, Egypt",
    role: "Software Engineering Manager",
    link: "https://thndr-talent.freshteam.com/jobs",
  },
  {
    company: "Thomann.io",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/dfLDYKD.png",
    locations: "Europe",
    role: "Software Engineer",
    link: "https://thomann.io/jobs ",
  },
  {
    company: "Thought Machine",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/uFNDYUk.png",
    locations: "London, United Kingdom",
    role: "Product Manager, Engineering Manager, Software Engineer",
    link: "https://thoughtmachine.avature.net/careers/",
  },
  {
    company: "ThousandEyes",
    lastChecked: "06-Feb-2023",
    logo: "https://logo.clearbit.com/thousandeyes.com",
    locations: "San Francisco, CA; Austin, TX; Seattle, WA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager",
    link: "https://www.thousandeyes.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Tide",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/tide.co",
    locations: "Remote; London, UK; Sofia, Bulgaria; Hyderabad, India",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager",
    link: "https://boards.greenhouse.io/tide",
  },
  {
    company: "Tier Mobility",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Tier.app",
    locations:
      "Remote Germany; Remote Spain; Remote Greece; Remote Hungary; Remote Poland",
    role: "Software Engineer",
    link: "https://www.tier.app/en/jobs",
  },
  {
    company: "Tines",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Tines.com",
    locations: "Remote",
    role: "Engineering Manager, Software Engineer",
    link: "https://www.tines.com/careers/",
  },
  {
    company: "Toast",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/bgHZvdL.png",
    locations: "United States; India; Ireland; Canada",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps",
    link: "https://careers.toasttab.com ",
  },
  {
    company: "TodayTix Group",
    lastChecked: "10-Nov-2022",
    logo: "https://logo.clearbit.com/todaytixgroup.com",
    locations: "New York, NY; London, UK; Colombia; Remote EST",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer",
    link: "https://www.todaytixgroup.com/careers",
  },
  {
    company: "TomTom",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/tomtom.com",
    locations: "Netherlands; Spain; Poland",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.tomtom.com/careers/jobdetails/1117251f-5b13-4dfd-a35b-78306417fbce/software-engineering-team-lead-cloud/",
  },
  {
    company: "ToolTime",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/VfsyT90.png",
    locations: "Berlín, Germany",
    role: "Software Engineer",
    link: "https://www.tooltime.de/jobs ",
  },
  {
    company: "Toplyne",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/toplyne.io",
    locations: "Bengaluru, India",
    role: "Data Engineer",
    link: "https://www.toplyne.io/join-us?ashby_jid=ba1672d4-f75a-4909-8ccc-c6ac79bb44d5",
  },
  {
    company: "Trade Republic Bank",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/traderepublic.com",
    locations: "Berlin, Germany",
    role: "Software Engineer",
    link: "https://traderepublic.com/careers",
  },
  {
    company: "Trade Tepublic",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/bHrmR4Z.png",
    locations: "Germany; Spain; Italy; France",
    role: "Software Engineering Manager, Software Engineer",
    link: "https://traderepublic.com/careers",
  },
  {
    company: "Trail",
    lastChecked: "22-Nov-2022",
    logo: "https://i.imgur.com/Rbavc43.png",
    locations: "Stockholm, Sweden",
    role: "Software Engineer, Data Scientist",
    link: "https://careers.trail.gg/",
  },
  {
    company: "Trainline",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/thetrainline.com",
    locations: "London, United Kingdom; Barcelona, Spain; Paris, France",
    role: "Software Engineer, Engineering Manager, Product Director, Data Scientist",
    link: "https://www.trainlinegroup.com/careers/en/",
  },
  {
    company: "Transdev",
    lastChecked: "30-Jan-2023",
    logo: "https://logo.clearbit.com/transdev.com",
    locations: "Lombard, IL; Chandler, AZ; California",
    role: "Data Scientist, Human Resources, HRIS",
    link: "https://transdevna.jobs",
  },
  {
    company: "TransferGo",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/TransferGo.com",
    locations: "Lithuania; Poland; Remote Europe",
    role: "Software Engineer, Engineering Manager",
    link: "https://www.transfergo.com/en/career/open-positions",
  },
  {
    company: "TransFICC",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/Yl2Ejd9.png",
    locations: "EMEA; New York, NY",
    role: "Software Engineer, Site Reliability / DevOps, Technical Presales",
    link: "https://transficc.com/join",
  },
  {
    company: "TravelPerk",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/T6ZV7wc.png",
    locations:
      "Barcelona, Spain; Berlin, Germany; London, UK; Edinburgh, Scotland",
    role: "Software Engineer, Product Manager, Product Designer, Software Engineering Manager, Sales, Data Analyst",
    link: "https://www.travelperk.com/careers/",
  },
  {
    company: "Tray.io",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/tray.io",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://tray.io/careers",
  },
  {
    company: "Treatwell",
    lastChecked: "18-Nov-2022",
    logo: "https://i.imgur.com/ixC8Mog.png",
    locations: "United Kingdom; Italy; Spain; France; Germany; Lithuania",
    role: "Software Engineer",
    link: "https://apply.workable.com/treatwell/",
  },
  {
    company: "Trengo",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/1QG9cD6.png",
    locations: "Utrecht, Netherlands",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://trengo.com/en/careers/",
  },
  {
    company: "Tripadvisor",
    lastChecked: "29-Nov-2022",
    logo: "https://logo.clearbit.com/tripadvisor.com",
    locations:
      "Lisbon, Portugal; London, UK; Oxford, UK; New York, NY; Needham, MA; Barcelona, Spain",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Sales, Operations",
    link: "https://grnh.se/b6d7aebb1us",
  },
  {
    company: "Trueblue",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/5Pdo4T7.png",
    locations: "Verona, Italy; Milano, Italy",
    role: "Product Owner, Software Engineer, Solutions Architect",
    link: "https://www.linkedin.com/company/trueblue-spa/jobs/",
  },
  {
    company: "Truecaller",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/truecaller.com",
    locations: "Stockholm, Sweden",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://www.truecaller.com/careers/jobs/4692011",
  },
  {
    company: "TrueML",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/mKMamnS.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://www.trueaccord.com/about-us/careers ",
  },
  {
    company: "Truist",
    lastChecked: "17-Nov-2022",
    logo: "https://logo.clearbit.com/truist.com",
    locations: "Charlotte, , NC; Atlanta, GA; Remote",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://careers.truist.com/",
  },
  {
    company: "Trunk.io",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/DnQ6GGx.png",
    locations: "Remote US",
    role: "Software Engineer",
    link: "https://trunk.io/jobs",
  },
  {
    company: "Truveta",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/truveta.com",
    locations: "Seattle, WA",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://www.truveta.com/careers/",
  },
  {
    company: "Tulip",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/mDjpFcQ.png",
    locations: "Boston, MA; Munich, Germany; Budapest, Hungary; Remote US",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://tulip.co/careers/",
  },
  {
    company: "Turo",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/turo.com",
    locations:
      "San Francisco, CA; Phoenix, AZ; Toronto, Canada; London, United Kingdom; Remote",
    role: "Software Engineer, Data Scientist, Data Engineer",
    link: "https://grnh.se/6d0b62691us",
  },
  {
    company: "Tutorful",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/tutorful.co.uk",
    locations: "Remote UK",
    role: "Software Engineer",
    link: "https://careers.tutorful.co.uk/jobs/2136467-full-stack-php-developer",
  },
  {
    company: "TV 2",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/QxUyb8k.png",
    locations: "Copenhagen, Denmark; Odense, Denmark; Remote",
    role: "Software Engineer, Solutions Architect",
    link: "https://omtv2.tv2.dk/job/ledige-stillinger/",
  },
  {
    company: "Twilio",
    lastChecked: "14-Nov-2022",
    logo: "https://logo.clearbit.com/twilio.com",
    locations: "Remote US; Remote India",
    role: "Software Engineer, Site Reliability / DevOps",
    link: "https://boards.greenhouse.io/twilio",
  },
  {
    company: "Twitter",
    lastChecked: "03-Dec-2022",
    logo: "https://logo.clearbit.com/twitter.com",
    locations: "San Francisco, CA; New York, NY",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://careers.twitter.com/en/roles.html",
    comments:
      "Senior SWE: $162k - $226k\nSr Staff SWE: $243k - $338k\nSenior PM: $175k - $259k\nSenior Designer: $154k - $226k",
  },
  {
    company: "Uber",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/ext.uber.com",
    locations:
      "Remote; United States; Australia; Netherlands; Denmark; Bulgaria",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.uber.com/us/en/careers/",
  },
  {
    company: "Ubermetrics Technologies",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/ubermetrics-technologies.com",
    locations: "Berlin, Germany; Cologne, Germany; Remote",
    role: "Software Engineer, Data Engineer",
    link: "https://ubermetricstechnologies.softgarden.io/job/23510169/Data-Engineer-m-f-d-?l=de",
  },
  {
    company: "UCSF Health",
    lastChecked: "06-Dec-2022",
    logo: "https://i.imgur.com/JasOdYj.png",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Product Designer, QA Lead",
    link: "https://www.linkedin.com/jobs/search/?currentJobId=3360658914&f_C=3029&geoId=92000000&originToLandingJobPostings=3360658914%2C3368963900%2C3314155439%2C3377758500%2C3368970026%2C3366605964%2C3349043924%2C3365299727%2C3387760243",
  },
  {
    company: "Udemy",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/udemy.com",
    locations:
      "San Francisco, CA; Sao Paulo, Brazil; Dublin, Ireland; Ankara, Turkey; Istanbul, Turkey; Denver, CO; Austin, TX; Remote",
    role: "Product Manager, Data Scientist, Product Designer, Finance / Accounting, Sales, Marketing",
    link: "https://app.careerpuck.com/job-board/udemy",
    comments: "",
    geos: "",
  },
  {
    company: "Uhmbrella",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/3i4CZb5.png",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://www.linkedin.com/posts/uhmbrella_uhmbrella-is-hiring-engineers-to-be-part-activity-6983171419752792064-AgTZ?utm_source=share&utm_medium=member_desktop ",
  },
  {
    company: "Unbabel",
    lastChecked: "11-Nov-2022",
    logo: "https://logo.clearbit.com/unbabel.com",
    locations: "Lisbon, Portugal",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Sales",
    link: "https://unbabel.com/careers/",
  },
  {
    company: "Underdog Sports",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/0qWdwPP.png",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager, Product Manager",
    link: "https://boards.greenhouse.io/underdogfantasy",
  },
  {
    company: "Understood",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/understood.org",
    locations: "New York, NY",
    role: "Software Engineer",
    link: "https://www.understood.org/en/join-our-team",
  },
  {
    company: "Uniswap Labs",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/uniswap.org",
    locations: "New York, NY; Remote",
    role: "Software Engineer, Software Engineering Manager, Product Designer",
    link: "https://grnh.se/b99d20cb5us",
  },
  {
    company: "UnitX",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/unitxlabs.com",
    locations: "Santa Clara, CA",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Product Manager, Sales",
    link: "https://unitx.recruitee.com/",
  },
  {
    company: "Universal Quantum",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/UniversalQuantum.com",
    locations: "Remote UK; Remote Germany",
    role: "Software Engineer, Hardware Engineer",
    link: "https://apply.workable.com/universalquantum/",
  },
  {
    company: "University of Washington ",
    lastChecked: "15-Nov-2022",
    logo: "https://logo.clearbit.com/uw.edu",
    locations: "Seattle, WA",
    role: "Software Engineer",
    link: "https://hr.uw.edu/jobs/",
  },
  {
    company: "Upbound",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/zvozBAB.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://grnh.se/4093dd654us",
  },
  {
    company: "Upstart",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/upstart.com",
    locations: "Remote US",
    role: "Data Scientist, Machine Learning",
    link: "https://upstart.com/careers",
  },
  {
    company: "Upvest",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/DVP0Uci.png",
    locations: "Berlin, Germany",
    role: "Product Engineer",
    link: "https://careers.upvest.co/",
  },
  {
    company: "US Bank",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/usbank.com",
    locations: "United States",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://careers.usbank.com/global/en",
  },
  {
    company: "USIM",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/theusim.com",
    locations: "Irvine, CA; New York, NY; Remote",
    role: "Associate Media Director, SEM Specialist, Media Trader, Media Planner, Senior Paid Social Media Specialist, Senior Media Planner",
    link: "theusim.com/careers",
  },
  {
    company: "Valve Software",
    lastChecked: "20-Nov-2022",
    logo: "https://imgur.com/KjlOb0V.png",
    locations: "Seattle, WA",
    role: "Software Engineer, Hardware Engineer, Site Reliability / DevOps, Finance / Accounting, Human Resources, Operations",
    link: "https://www.valvesoftware.com",
  },
  {
    company: "VEED",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/zMDeGjs.png",
    locations: "Remote; Amsterdam, Netherlands; London, UK; Lisbon, Portugal",
    role: "Software Engineer",
    link: "https://veed.teamtailor.com/",
  },
  {
    company: "Veed.io",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/veed.io",
    locations: "Remote",
    role: "Engineering Manager, Software Engineer",
    link: "https://www.veed.io/careers",
  },
  {
    company: "Veepee",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/aFQViVn.png",
    locations:
      "Barcelona, Spain; Amsterdam, Netherlands; Paris, France; Warsaw, Poland; Remote",
    role: "Software Engineer, Community Manager, Finance / Accounting, Human Resources, Business Analyst, Data Analyst, Marketing, Sales",
    link: "https://careers.veepee.com/en-offers/",
  },
  {
    company: "Veeva Systems",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/veeva.com",
    locations: "Remote US; Remote Canada",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://careers.veeva.com/",
  },
  {
    company: "Velocity Global ",
    lastChecked: "17-Nov-2022",
    logo: "https://logo.clearbit.com/velocityglobal.com",
    locations: "Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Recruiter, Sales, Marketing, Operations",
    link: "https://velocityglobal.com/about-us/careers/",
  },
  {
    company: "Vinted",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Vinted.com",
    locations: "Berlin, Germany; Vilnius, Lithuania",
    role: "Software Engineer",
    link: "https://www.vinted.com/jobs",
  },
  {
    company: "Visa",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/visa.com",
    locations:
      "Ashburn, VA; Foster City, CA; Palo Alto, CA; Austin, TX; Atlanta, GA",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist",
    link: "https://usa.visa.com/en_us/jobs/?categories=Cyber%20Security&categories=Data%20Architect%2FEngineering%2FScience&categories=Data%20Science%2FData%20Engineering&categories=Software%20Development%20%26%20Engineering&categories=Software%20Development%2FEngineering&categories=Software%20Quality%20Assurance%20and%20Testing&cities=Ashburn&cities=Atlanta&cities=Austin&cities=Bellevue&cities=Foster%20City&cities=Highlands%20Ranch&cities=Mentor&cities=Miami&cities=New%20York&cities=Palo%20Alto&cities=San%20Francisco",
  },
  {
    company: "Visma e-conomic",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/ttrTfy9.png",
    locations: "Europe",
    role: "Software Engineer, Software Engineering Manager, Architect",
    link: "https://www.e-conomic.dk/om/job",
  },
  {
    company: "Vital",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/zL8bGfq.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://vital-api.notion.site/Senior-Backend-Engineer-97c19737ae3248e2a1123359ca1f5621",
  },
  {
    company: "Vivcourt Trading",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/vivcourt.com.au",
    locations: "Sydney, Australia; Brisbane, Australia; Melbourne, Australia",
    role: "Software Engineer",
    link: "https://www.vivcourt.com.au/job-listings/",
  },
  {
    company: "Vivian Health",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/vivian.com",
    locations: "Remote US",
    role: "Software Engineer, Software Engineering Manager, Product Manager, Data Scientist, Product Designer, Sales, Marketing, Operations",
    link: "https://boards.greenhouse.io/vivian",
  },
  {
    company: "Vivun",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/vivun.com",
    locations: "Remote",
    role: "Software Engineer, Engineering Manager, Data Scientist, Data Engineer, Researcher",
    link: "https://jobs.lever.co/Vivun",
  },
  {
    company: "Vizit Labs",
    lastChecked: "25-Nov-2022",
    logo: "https://i.imgur.com/IdrcuCC.png",
    locations: "Boston, MA; Remote, US",
    role: "Researcher, Software Engineer",
    link: "https://www.vizit.com/careers",
  },
  {
    company: "Vizzly",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/NxoNEJp.png",
    locations: "Remote UK",
    role: "Software Engineer",
    link: "https://www.workatastartup.com/companies/vizzly",
  },
  {
    company: "Voicemod",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/Voicemod.com",
    locations: "Remote Europe",
    role: "Software Engineer",
    link: "https://www.voicemod.com/careers",
  },
  {
    company: "Volume Graphics",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Volumegraphics.com",
    locations: "Germany",
    role: "Software Engineer",
    link: "https://www.volumegraphics.com/en/company/career-at-volume-graphics/jobs-and-application.html",
  },
  {
    company: "Volvo Car",
    lastChecked: "26-Jan-2023",
    logo: "https://imgur.com/N172cKI.png",
    locations: "Stockholm, Sweden",
    role: "Software Engineer, Hardware Engineer, Site Reliability / DevOps, Product Manager, Product Designer, Marketing",
    link: "https://www.volvocars.com/en-se/on-demand/careers#roles",
    comments: "",
    geos: "",
  },
  {
    company: "Vouched",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/xDk0EKm.png",
    locations: "Remote US",
    role: "Software Engineer, ML Engineer, Applied Scientist",
    link: "https://apply.workable.com/vouched/",
  },
  {
    company: "VSCO",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/vsco.co",
    locations: "Remote US",
    role: "Engineering Manager, Software Engineer",
    link: "https://www.vsco.co/about/careers",
  },
  {
    company: "VTEX",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/WvKIewk.png",
    locations: "Brazil",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobsearch.vtex.com/job-search/?locations=&teams=&",
  },
  {
    company: "Vulnerability Research Labs",
    lastChecked: "23-Nov-2022",
    logo: "https://i.imgur.com/55OCsPe.png",
    locations: "Maryland, US",
    role: "Software Engineer, Vulnerability Researcher",
    link: "https://vrlsec.applicantpro.com/jobs/",
  },
  {
    company: "Wallbox",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/gvBJ6zU.png",
    locations: "Spain",
    role: "Software Engineer, Software Engineering Manager, Hardware Engineer",
    link: "https://apply.workable.com/wallbox/?lng=en ",
  },
  {
    company: "Warner Bros. Games",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/e0dKIbE.png",
    locations:
      "San Francisco, CA; San Diego, CA; Boston, MA; New York, NY; Remote",
    role: "Software Engineer, Product Designer, Product Manager",
    link: "https://warnerbrosgames.com/careers",
  },
  {
    company: "WeFox",
    lastChecked: "24-Nov-2022",
    logo: "https://logo.clearbit.com/wefox.com",
    locations: "Germany; France; Spain",
    role: "Software Engineer",
    link: "https://www.wefox.com/en-de/careers",
  },
  {
    company: "WellSaid Labs",
    lastChecked: "10-Nov-2022",
    logo: "https://imgur.com/z94T4JX.png",
    locations: "Remote US",
    role: "Software Engineer, Site Reliability / DevOps, Product Designer, Sales",
    link: "https://wellsaidlabs.com/careers/",
  },
  {
    company: "WeTransfer",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/x6s0XLl.png",
    locations:
      "Amsterdam, Netherlands; London, UK; Paris, France; New York, NY; Remote Europe; Remote US",
    role: "Software Engineer, Software Engineering Manager, Data Engineer, Infrastructure Engineer",
    link: "https://jobs.ashbyhq.com/wetransfer",
  },
  {
    company: "Which?",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Which.co.uk",
    locations: "London, United Kingdom",
    role: "Software Engineer",
    link: "https://careers.which.co.uk/jobs/search?department_uids%5B%5D=a39b6a8a44aebb6549267cfb0d90ab54",
  },
  {
    company: "Whoppah",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/2fgwHux.png",
    locations: "Randstad, Netherlands",
    role: "Tech Lead",
    link: "https://hiring.rocketx.group/join-whoppah/",
  },
  {
    company: "Win Source",
    lastChecked: "28-Dec-2022",
    logo: "https://logo.clearbit.com/win-source.net",
    locations: "Spokane, WA; United States",
    role: "Marketing",
    link: "https://www.win-source.net/contactus.html",
    comments: "service@win-source.net",
  },
  {
    company: "Wise",
    lastChecked: "28-Nov-2022",
    logo: "https://imgur.com/02POvzz.png",
    locations:
      "Budapest, Hungary; Tallinn, Estonia; London, UK; Singapore; Austin, TX",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.wise.jobs/search/?sort=oldest&t=engineering",
  },
  {
    company: "WIss",
    lastChecked: "08-Feb-2023",
    logo: "https://logo.clearbit.com/wiss.com",
    locations: "Florham Park, NJ; New York, NY",
    role: "Finance / Accounting",
    link: "https://wiss.com/join-our-team/",
    comments: "",
    geos: "",
  },
  {
    company: "Wix",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/uk53R1f.png",
    locations: "Kyiv, Ukraine",
    role: "Software Engineer",
    link: "https://www.wix.com/jobs/home",
  },
  {
    company: "Wiz.io",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/wiz.io",
    locations: "Remote",
    role: "Software Engineer, Solution Architect",
    link: "https://www.wiz.io/careers#open-position",
  },
  {
    company: "Wolt",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/wolt.com",
    locations: "Germany; Finland; Estonia; Sweden; Denmark",
    role: "Software Engineer, Data Engineer, Data Scientist",
    link: "https://wolt.com/fi/jobs",
  },
  {
    company: "Workday",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/GMwQT1Y.png",
    locations: "Global",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://workday.wd5.myworkdayjobs.com/Workday",
  },
  {
    company: "Workflow86",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/x1oxJlf.png",
    locations: "Remote US; Remote Australia",
    role: "Software Engineer",
    link: "https://www.workflow86.com/careers/",
  },
  {
    company: "Worldsensing",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/hHLdSJu.png",
    locations: "Global",
    role: "Software Engineer",
    link: "https://www.worldsensing.com/careers/ ",
  },
  {
    company: "Writesonic",
    lastChecked: "17-Nov-2022",
    logo: "https://logo.clearbit.com/writesonic.com",
    locations: "Remote",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://writesonic.zohorecruit.com/jobs/Careers",
  },
  {
    company: "Wunderman Thompson",
    lastChecked: "21-Nov-2022",
    logo: "https://imgur.com/0aqsKVr.png",
    locations: "Madrid, Spain; Copenhagen, Denmark; Lisbon, Portugal",
    role: "Software Engineer, Technical Project Manager, Data Engineer, Site Reliability / DevOps",
    link: "https://www.wunderman.dk/careers/ ",
  },
  {
    company: "XanPool",
    lastChecked: "28-Nov-2022",
    logo: "https://logo.clearbit.com/XanPool.com",
    locations: "Singapore; Bangkok, Thailand; Remote Asia",
    role: "Software Engineer",
    link: "https://boards.greenhouse.io/xanpool",
  },
  {
    company: "xDesign",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/xdesign.com",
    locations: "Remote UK",
    role: "Software Engineer, Solutions Architect",
    link: "https://careers.xdesign.com/",
  },
  {
    company: "Xeneta",
    lastChecked: "21-Nov-2022",
    logo: "https://logo.clearbit.com/xeneta.com",
    locations: "Oslo, Norway; Hamburg, Germany",
    role: "DevOps, Software Engineer",
    link: "https://xeneta.pinpointhq.com/?department_id=%5B8811%5D",
  },
  {
    company: "Xero",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/LHzVCUJ.png",
    locations: "United States; Canada; United Kingdom; New Zealand; Australia",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://jobs.lever.co/xero",
  },
  {
    company: "XOi Technologies",
    lastChecked: "23-Nov-2022",
    logo: "https://logo.clearbit.com/xoi.io",
    locations: "Remote; Nashville, TN",
    role: "Software Engineer, Product Manager",
    link: "https://www.jobscore.com/careers/xoitechnologies",
  },
  {
    company: "Xomnia",
    lastChecked: "18-Nov-2022",
    logo: "https://imgur.com/2G8oUdC.png",
    locations: "Amsterdam, Netherlands",
    role: "Software Engineer, Data Engineer, Data Architect",
    link: "https://www.xomnia.com/careers/",
  },
  {
    company: "Xpirit",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/oLcnQlp.png",
    locations: "Belgium; Netherlands; United States; Germany",
    role: "Site Reliability / DevOps",
    link: "https://xpirit.com/company/careers/ ",
  },
  {
    company: "Yahoo",
    lastChecked: "30-Nov-2022",
    logo: "https://logo.clearbit.com/yahooinc.com",
    locations:
      "Remote Denmark; Remote France; Remote Germany; Remote US; Remote Ireland; Remote Singapore; Remote Taiwan; Remote Israel",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer, Finance / Accounting, Human Resources, Sales, Marketing, Operations, Content Producer, Editor",
    link: "https://www.yahooinc.com/careers/",
  },
  {
    company: "Yelp",
    lastChecked: "29-Nov-2022",
    logo: "https://logo.clearbit.com/yelp.com",
    locations:
      "Toronto, Canada; San Francisco, CA; London, UK; Hamburg, Germany; Austin, TX",
    role: "Software Engineer, Site Reliability / DevOps, Product Manager, Data Scientist, Product Designer",
    link: "https://www.yelp.careers",
  },
  {
    company: "YouGov",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/jWdkiOJ.png",
    locations: "Europe; United States",
    role: "Software Engineer, Software Engineering Manager, Cloud Architect, Site Reliability / DevOps",
    link: "https://jobs.yougov.com/",
  },
  {
    company: "Yulife",
    lastChecked: "25-Nov-2022",
    logo: "https://logo.clearbit.com/Yulife.com",
    locations: "London, United Kingdom; Remote",
    role: "Software Engineer",
    link: "https://yulife.com/careers/",
  },
  {
    company: "Yum",
    lastChecked: "06-Dec-2022",
    logo: "https://logo.clearbit.com/yum.com",
    locations: "Chicago, IL; Dallas, TX; Louisville, KY; Remote",
    role: "Software Engineer, Software Engineering Manager, Site Reliability / DevOps, Product Manager",
    link: "https://jobs.yum.com",
  },
  {
    company: "Zalando",
    lastChecked: "18-Nov-2022",
    logo: "https://logo.clearbit.com/zalando.com",
    locations: "Europe",
    role: "Software Engineer, Senior Software Engineer, Data Engineer, Machine Learning Engineer, Engineering Manager, Data Engineering Manager",
    link: "https://jobs.zalando.com/en/tech/jobs/?gh_src=22377bdd1us&filters%5Bcategories%5D%5B0%5D=Applied%20Science&filters%5Bcategories%5D%5B1%5D=Product%20Design%2C%20User%20Research%2C%20Content%20Design&filters%5Bcategories%5D%5B2%5D=Product%20Management%20%28Technology%29&filters%5Bcategories%5D%5B3%5D=Software%20Engineering%20-%20Architecture&filters%5Bcategories%5D%5B4%5D=Software%20Engineering%20-%20Backend&filters%5Bcategories%5D%5B5%5D=Software%20Engineering%20-%20Data&filters%5Bcategories%5D%5B6%5D=Software%20Engineering%20-%20Frontend&filters%5Bcategories%5D%5B7%5D=Software%20Engineering%20-%20Full%20Stack&filters%5Bcategories%5D%5B8%5D=Software%20Engineering%20-%20Leadership&filters%5Bcategories%5D%5B9%5D=Software%20Engineering%20-%20Machine%20Learning&filters%5Bcategories%5D%5B10%5D=Software%20Engineering%20-%20Mobile&filters%5Bcategories%5D%5B11%5D=Software%20Engineering%20-%20Principal%20Engineering",
  },
  {
    company: "Zeals",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/zeals.co.jp ",
    locations: "San Francisco, CA; Bay Area, CA; Tokyo, Japan",
    role: "Software Engineer, Sales, Customer Success Manager",
    link: "https://zeals.co.jp/recruit",
  },
  {
    company: "ZF - Scalar",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/kH83quF.png",
    locations:
      "France; Germany; Poland; India; Switzerland; Dublin, Ireland; Brussels, Belgium",
    role: "Software Engineer, Product Manager, Product Designer",
    link: "https://jobs.zf.com/search/?createNewAlert=false&q=Commercial+Vehicle+Solutions&optionsFacetsDD_facility=Research+and+Development&optionsFacetsDD_shifttype=&optionsFacetsDD_country=&locationsearch=",
  },
  {
    company: "Ziina",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/jje723l.png",
    locations: "Dubai, United Arab Emirates",
    role: "Software Engineer, Data Analyst, Product Designer",
    link: "https://www.workatastartup.com/companies/ziina",
  },
  {
    company: "Zimmer Biomet",
    lastChecked: "30-Nov-2022",
    logo: "https://imgur.com/O41ew5H.png",
    locations: "Gurgaon, India",
    role: "Product Manager, Sales, Marketing, Operations",
    link: "https://www.zimmerbiomet.com/en/about-us/careers.html",
  },
  {
    company: "Zip",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/ziphq.com",
    locations: "San francisco, CA",
    role: "Software Engineer",
    link: "https://ziphq.com/careers",
    comments: "jingjing@ziphq.com",
    geos: "",
  },
  {
    company: "Zipline",
    companyDomain: "flyzipline.com",
    lastChecked: "26-Jan-2023",
    logo: "https://logo.clearbit.com/flyzipline.com",
    locations: "San Francisco, CA; Remote",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Recruiter",
    link: "https://www.flyzipline.com/careers",
    comments: "",
    geos: "",
  },
  {
    company: "Zocdoc",
    lastChecked: "22-Nov-2022",
    logo: "https://logo.clearbit.com/zocdoc.com",
    locations: "Remote; New York, NY",
    role: "Software Engineer, Product Designer",
    link: "https://www.zocdoc.com/about/careers-list/",
  },
  {
    company: "ZOE",
    lastChecked: "24-Nov-2022",
    logo: "https://i.imgur.com/lFwOiRz.png",
    locations: "Remote UK",
    role: "Software Engineer, Engineering Manager, Product Manager",
    link: "https://jobs.lever.co/joinzoe",
  },
  {
    company: "zolar",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/nRm3eaQ.png",
    locations: "Europe",
    role: "Software Engineer, Software Engineering Manager",
    link: "https://www.zolar.de/en/careers/job-offers ",
  },
  {
    company: "Zoox",
    lastChecked: "23-Jan-2023",
    logo: "https://logo.clearbit.com/zoox.com",
    locations: "Bay Area, CA; Seattle, WA; San Diego, CA; Boston, MA",
    role: "Software Engineer, Hardware Engineer, Software Engineering Manager, Site Reliability / DevOps, Data Scientist, Finance / Accounting, Human Resources, Recruiter",
    link: "https://zoox.com/careers/",
    comments: "",
    geos: "",
  },
  {
    company: "Zuva",
    lastChecked: "23-Nov-2022",
    logo: "https://imgur.com/TVOw8ni.png",
    locations: "Canada",
    role: "Software Engineering Manager",
    link: "https://jobs.lever.co/Zuva/ ",
  },
  {
    company: "Zwift",
    lastChecked: "22-Nov-2022",
    logo: "https://imgur.com/WbLfulz.png",
    locations: "Remote",
    role: "Software Engineer",
    link: "https://www.zwift.com/careers",
  },
];
