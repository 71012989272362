import "./App.css";
import MainPage from "./pages/MainPage/MainPage";
import { Routes, Route } from "react-router-dom";
import SignUp from "./pages/SignUp/SignUp";
import SignIn from "./pages/SignIn/SignIn";
import FooterMain from "./pages/footer/footerMain";
import BlogMain from "./pages/blog/blogMain";
import AboutUsMain from "./pages/about_us/aboutUsMain";
import ForgotPasswordMain from "./pages/auth/forgot_password/ForgotPasswordMain";
import MainPageError404 from "./pages/page404/MainPageError404";
import PrivateRouteEmp from "./common/PrivateRouteEmp";
import { useEffect, useState } from "react";
import { AuthContext } from "./context/cognitoUser";
import { USER_TYPE } from "./constants/constants";
import EmailVerification from "./pages/verification/EmailVerification";
import PrivateRouteStudent from "./common/PrivateRouteStudent";
import DashboardCommon from "./pages/DashboardCommon";
import JobBoard from "./pages/JobBoard/JobBoard";
import RecDashboard from "./pages/rec_dashboard/RecDashboard";
import HireTalent from "./pages/hire/HireTalent";
import InMakingBanner from "./pages/banner/in_making_banner/InMakingBanner";
import PrivateRouteRec from "./common/PrivateRouteRed";
import SignInRec from "./pages/hire/signin/SignInRec";
import SignUpRec from "./pages/hire/signup/SignUpRec";
import ContestMain from "./pages/contest/ContestMain";
import StudentDashboard from "./pages/student/dashboard/StudentDashboard";
import EmpDashboardMain from "./pages/employee/dashboard/components/EmpDashboardMain";
import OpenRoles from "./pages/openRoles/OpenRoles";
import ContactUsMain from "./pages/contactUs/ContactUsMain";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsOfConditions from "./pages/toc/TermsOfConditions";
import Pricing from "./pages/pricing/Pricing";
import TeamMain from "./pages/team/TeamMain";
import LearnMain from "./pages/learn/LearnMain";
import ServicesMain from "./pages/services/ServicesMain";
import OnboardingMain from "./pages/onboarding/components/OnboardingMain";
import { Amplify, Auth, Hub } from "aws-amplify";
import {
  employeeUserPoolConfig,
  studentUserPoolConfig,
} from "./configs/cognito_user_pool";
import PrivateCommonRoute from "./common/PrivateCommonRoute";
import ForUniversity from "./pages/for-university/ForUniversity";
import InMaking from "./common/in_making/InMaking";
import UniAdminDashboard from "./pages/admin/university/dashboard/UniAdminDashboard";
const userType = localStorage.getItem("userType");

export default function App() {
  const [globalUserType, setGlobalUserType] = useState(() => {
    if (typeof userType === "undefined" || userType == null) {
      return "NO_USER";
    } else {
      return userType;
    }
  });
  const [user, setUser] = useState(null);
  const [userSession, setUserSession] = useState();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          localStorage.removeItem("isAuth");
          localStorage.removeItem("userType");
          localStorage.removeItem("accessToken");
          setUser(null);
          setUserSession(null);
          break;

        case "signIn_failure":
          localStorage.removeItem("isAuth");
          localStorage.removeItem("userType");
          localStorage.removeItem("accessToken");
          setUser(null);
          setUserSession(null);
          break;
        case "cognitoHostedUI_failure":
          break;
      }
    });

    getUser().then((userData) => {
      if (userData == null) {
        localStorage.removeItem("isAuth");
      } else {
        localStorage.setItem("isAuth", true);
      }
      setUser(userData);
    });

    getSeesion().then((userData) => {
      setUserSession(userData);
      if (userData)
        window.localStorage.setItem(
          "accessToken",
          `${userData.accessToken.jwtToken}`
        );
    });
  }, []);

  useEffect(() => {
    if (globalUserType === USER_TYPE.STUDENT) {
      Amplify.configure(studentUserPoolConfig);
    } else if (globalUserType === USER_TYPE.EMP) {
      Amplify.configure(employeeUserPoolConfig);
    } else {
      Amplify.configure(studentUserPoolConfig);
    }
  }, [globalUserType]);

  useEffect(() => {
    if (userSession && !userSession.isValid()) {
      window.location.reload(false);
    }
  }, [userSession]);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        localStorage.removeItem("isAuth");
        setUser(null);
        setUserSession(null);
      });
  }

  function getSeesion() {
    return Auth.currentSession()
      .then((userData) => userData)
      .catch(() => {
        setUserSession(null);
      });
  }

  return (
    <AuthContext.Provider
      value={{
        globalUserType,
        setGlobalUserType,
        user,
        setUser,
        userSession,
        setUserSession,
      }}
    >
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/for-university" element={<ForUniversity />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPasswordMain />} />
        <Route path="/blog" element={<BlogMain />} />
        <Route path="/about-us" element={<AboutUsMain />} />
        <Route path="/our-team" element={<TeamMain />} />
        <Route path="/open-roles" element={<OpenRoles />} />
        <Route path="/contact-us" element={<ContactUsMain />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/confirm-email" element={<EmailVerification />} />
        <Route path="*" element={<MainPageError404 />} />
        <Route path="/dashboard" element={<DashboardCommon />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/toc" element={<TermsOfConditions />} />
        <Route path="/hire" element={<HireTalent />} />
        <Route path="/contest" element={<ContestMain />} />
        <Route path="/hire/signin" element={<SignInRec />} />
        <Route path="/hire/signup" element={<SignUpRec />} />
        <Route path="/onboarding" element={<OnboardingMain />} />

        <Route
          path="/hire/dashboard"
          element={
            <PrivateRouteRec>
              <RecDashboard />
            </PrivateRouteRec>
          }
        />

        <Route
          path="/learn/*"
          element={
            <PrivateCommonRoute>
              <LearnMain />
            </PrivateCommonRoute>
          }
        ></Route>

        <Route path="/services/*" element={<ServicesMain />}></Route>

        <Route path="/job-board" element={<JobBoard />}></Route>

        <Route
          path="/student/dashboard/*"
          element={
            <PrivateRouteStudent>
              <StudentDashboard />
            </PrivateRouteStudent>
          }
        ></Route>
        <Route
          path="/admin/dashboard/*"
          element={
            // <InMaking />
            <UniAdminDashboard />
          }
        ></Route>

        <Route
          path="/employee/dashboard/*"
          element={
            <PrivateRouteEmp>
              <EmpDashboardMain />
            </PrivateRouteEmp>
          }
        ></Route>
      </Routes>
      <InMakingBanner />
      <FooterMain />
    </AuthContext.Provider>
  );
}
