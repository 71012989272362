import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import AXIOS_CLIENT from "../../../utils/api_client";
import AlertBanner from "../../../common/components/AlertBanner";

export default function PricingComponent() {
  const [isProcessing, setIsProcessing] = useState(false);

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);

  const html_dom = `<script src="https://static.airtable.com/js/embed/embed_snippet_v1.js"></script><iframe class="airtable-embed airtable-dynamic-height" src="https://airtable.com/embed/shrQaTvtPVq0BbWFW?backgroundColor=purple" frameborder="0" onmousewheel="" width="100%" height="1040" style="background: transparent; border: 1px solid #ccc;"></iframe>`;
  const renderAirtableForm = (
    <div dangerouslySetInnerHTML={{ __html: html_dom }} />
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsProcessing(true);

    const data = new FormData(e.currentTarget);
    const fullName = data.get("full-name");
    const email = data.get("email");
    const message = data.get("message");
    const contactNumber = data.get("phone");
    const universityName = data.get("university-name");

    const salesInquiryObj = {
      fullName: fullName,
      email: email,
      message: message,
      contactNumber: contactNumber,
      universityName: universityName,
    };

    AXIOS_CLIENT.post(`api/util/sales-inquiry`, salesInquiryObj)
      .then((res) => {
        setIsProcessing(false);
        setIsDanger(false);
        setIsError(true);
        setErrorMessage("Your feedback is added!");
      })
      .catch((error) => {
        setIsDanger(true);
        setIsError(true);
        setIsProcessing(false);
        setErrorMessage(error.toString());
      });
  };

  return (
    <div className="mx-auto max-w-7xl bg-white py-24 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Pricing plans for all of your need
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">
        If you are a university representative, please contact our sales team
        for onboarding your university on the FutureForte platform. Our prices
        range from $20,000 to over $100,000 to use and it is based on the size
        of students enrollment.
      </p>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl py-16 px-6 lg:px-8">
          <div className="mx-auto max-w-lg md:grid md:max-w-none md:grid-cols-2 md:gap-8">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                Sales Support
              </h2>
              <div className="mt-3">
                <p className="text-lg text-gray-500">
                  Please fill out contact us form for more details or any
                  queries. You can also reach out to our sales team on following
                  email.
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <PhoneIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>+1 (000) 000 0000</p>
                    <p className="mt-1">24x7 We are here to help.</p>
                  </div>
                </div>
                <div className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <EnvelopeIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>contact@futureforte.co</p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white py-8 px-4 lg:col-span-3 lg:py-4 lg:px-3 xl:pl-12">
                <div className="mx-auto max-w-lg lg:max-w-none">
                  {/* <form
                    onSubmit={handleSubmit}
                    className="grid grid-cols-1 gap-y-6"
                  >
                    <div>
                      <label htmlFor="university-name" className="sr-only">
                        University name
                      </label>
                      <input
                        type="text"
                        name="university-name"
                        id="university-name"
                        autoComplete="university-name"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="University name"
                      />
                    </div>
                    <div>
                      <label htmlFor="full-name" className="sr-only">
                        Full name
                      </label>
                      <input
                        type="text"
                        name="full-name"
                        id="full-name"
                        autoComplete="name"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Representative name"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Email"
                      />
                    </div>
                    <div>
                      <label htmlFor="phone" className="sr-only">
                        Phone
                      </label>
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Phone"
                      />
                    </div>
                    <div>
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>
                      <textarea
                        id="message"
                        name="message"
                        rows={4}
                        className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <div>
                      <button
                        disabled={isProcessing}
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        Submit
                      </button>
                    </div>
                  </form> */}
                  {renderAirtableForm}
                  <AlertBanner
                    errorMessage={errorMessage}
                    isDanger={isDanger}
                    setIsError={setIsError}
                    isError={isError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
