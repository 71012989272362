import { useEffect } from "react";
import HireTalentMain from "./component/HireTalentMain";
import MainNavbarFloating from "../../common/components/MainNavbarFloating";

export default function HireTalent() {
  const TITLE = "FutureForte: Hire the best talents within few minutes";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <>
      <MainNavbarFloating />
      <HireTalentMain />
    </>
  );
}
