import { useEffect, useState } from "react";
import ExploreRow from "./components/ExploreRow";
import StudentProfileOverlay from "./components/StudentProfileOverlay";
import AXIOS_CLIENT from "../../../../../utils/api_client";
import AlertBanner from "../../../../../common/components/AlertBanner";
import LoadingPulse from "../../../../../common/components/LoadingPulse";
import { useNavigate } from "react-router-dom";

export default function ExploreMain({ eid, email }) {
  const [isLoading, setIsLoading] = useState(true);
  const [companyDomain, setCompnayDomain] = useState();
  const [schoolDomain, setSchoolDomain] = useState();
  const [requests, setRequests] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSid, setSelectedSid] = useState();
  const [selectedReferral, setSelectedReferral] = useState();
  const [curIndex, setCurIndex] = useState();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const navigate = useNavigate();

  const [empInfo, setEmpInfo] = useState();
  const [isAlert, setIsAlert] = useState(false);

  useEffect(() => {
    const getEmpInfo = async () => {
      AXIOS_CLIENT.get(`emp/user/${eid}`)
        .then((response) => {
          if (response.data.data.length > 0) {
            const userInfo = response.data.data[0];
            setEmpInfo(response.data.data[0]);
            if (userInfo.companyDomain)
              setCompnayDomain(userInfo.companyDomain);
            if (userInfo.schoolDomain) setSchoolDomain(userInfo.schoolDomain);
            if (!userInfo.companyDomain) {
              const goTOProfile = () => {
                if (!isAlert) {
                  setIsAlert(true);
                  alert(
                    "Please complete your profile then you can see all referral requests"
                  );
                  navigate("/employee/dashboard/profile");
                }
              };
              goTOProfile();
            }
          } else {
            const goTOProfile = () => {
              if (!isAlert) {
                setIsAlert(true);
                alert(
                  "Please complete your profile then you can see all referral requests"
                );
                navigate("/employee/dashboard/profile");
              }
            };
            goTOProfile();
          }
        })
        .catch((error) => {});
    };
    if (eid) getEmpInfo();
  }, [eid]);

  useEffect(() => {
    if (companyDomain) {
      AXIOS_CLIENT.get(`emp/api/referral/open_referral`, {
        params: { companyDomain: companyDomain, schoolDomain: schoolDomain },
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            setRequests(response.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsDanger(true);
          setErrorMessage(error.toString());
          setIsError(true);
        });
    }
  }, [companyDomain]);

  const removeElement = (index) => {
    setRequests((requests) => {
      let left = requests.slice(0, index);
      let right = requests.slice(index + 1);
      return [...left, ...right];
    });
  };

  const mainContent = requests ? (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {requests.map((referral, index) => (
          <ExploreRow
            index={index}
            referral={referral}
            emp={empInfo}
            eid={eid}
            setIsOpen={setIsOpen}
            setSelectedSid={setSelectedSid}
            setSelectedReferral={setSelectedReferral}
            removeElement={removeElement}
            setCurIndex={setCurIndex}
            setIsError={setIsError}
            setErrorMessage={setErrorMessage}
            setIsDanger={setIsDanger}
          />
        ))}
      </ul>
    </div>
  ) : (
    <>Cannot find any referral request at this momment</>
  );

  return isLoading ? (
    <LoadingPulse />
  ) : (
    <>
      {mainContent}

      <StudentProfileOverlay
        index={curIndex}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedSid={selectedSid}
        setSelectedSid={setSelectedSid}
        referral={selectedReferral}
        eid={eid}
        removeElement={removeElement}
        isEnable={true}
        setIsError={setIsError}
        setErrorMessage={setErrorMessage}
        setIsDanger={setIsDanger}
      />
      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </>
  );
}
