import UniDBStudentActivity from "../home/components/UniDBStudentActivity";
import UniDBAlumniDistri from "./components/UniDBAlumniDistri";
import UniDBMainAnalytics from "./components/UniDBMainAnalytics";
import UniDBStudentDistri from "./components/UniDBStudentDistri";
import UniDBStudentGenderDistri from "./components/UniDBStudentGenderDistri";
import UniDBTopContributor from "./components/UniDBTopContributor";
import UniDBUserTrafficAnalytics from "./components/UniDBUserTrafficAnalytics";

export default function UniDBAnalytics() {
  return (
    <div className="pt-4 px-4">
      <UniDBMainAnalytics />
      <UniDBTopContributor />

      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
        <UniDBStudentActivity />
        <UniDBUserTrafficAnalytics />
        <UniDBAlumniDistri />
        <UniDBStudentDistri />
        <UniDBStudentGenderDistri />
      </div>
    </div>
  );
}
