import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  PhoneIcon,
  ArrowDownTrayIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { format } from "timeago.js";
import { ClockIcon } from "@heroicons/react/24/outline";
import AXIOS_CLIENT from "../../../../../../utils/api_client";
import CopyCliboard from "../../../../../../common/components/CopyClipboard";
import { EMP_DASHBOARD_NAV } from "../../../../../../constants/constants";
import { useNavigate } from "react-router-dom";

export default function ExploreRow({
  index,
  referral,
  emp,
  eid,
  setIsOpen,
  setSelectedSid,
  setSelectedReferral,
  removeElement,
  setCurIndex,
  setIsError,
  setErrorMessage,
  setIsDanger,
}) {
  //TODO: Need to change uid to username (firebase to cognito)
  const sid = referral.sid;
  const [name, setName] = useState("");
  const [studentInfo, setStudentInfo] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    // Need to update this
    AXIOS_CLIENT.get(`student/user/${sid}`)
      .then((response) => {
        if (response.data.data.length >= 0) {
          const userInfo = response.data.data[0];
          setStudentInfo(userInfo);
          setName(userInfo.firstName + " " + userInfo.lastName);
        }
      })
      .catch((error) => {});
  }, [sid]);

  const renderJobIds = referral?.jobIds ? (
    <ul role="list" className="divide-gray-200">
      {referral.jobIds.map((item) => (
        <li key={item}>
          <div className="flex flex-row">
            <CopyCliboard text={item} />

            <p className="ml-2 truncate text-indigo-600 text-sm font-medium">
              {item}
            </p>
          </div>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );

  const handleOpenOverlay = () => {
    setSelectedReferral(referral);
    setSelectedSid(sid);
    setIsOpen(true);
    setCurIndex(index);
  };

  const handleRejectRequest = () => {
    setIsOpen(false);
    setSelectedReferral(null);
    removeElement(index);
    setCurIndex();
  };

  const onAcceptReferral = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const userObject = referral;
    userObject["eid"] = eid;
    userObject["referralsAccepted"] = true;
    userObject["emp"] = emp?._id;
    const config = {
      headers: {
        username: eid,
      },
    };
    await AXIOS_CLIENT.put(
      `emp/api/referral/accept_referral/${referral._id}`,
      userObject,
      config
    )
      .then((res) => {
        removeElement(index);

        try {
          AXIOS_CLIENT.put(`api/conversations/emp`, referral)
            .then((res) => {
              navigate("/employee/dashboard/messages");
              setIsProcessing(false);
            })
            .catch((err) => {
              setIsProcessing(false);
            });
        } catch (err) {
          setIsProcessing(false);
        }
      })
      .catch((error) => {
        setIsProcessing(false);
        setIsDanger(true);
        setIsError(true);
        setErrorMessage("Error while processing your request");
      });
  };

  return (
    <li key={referral.email}>
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="flex min-w-0 flex-1 items-center">
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-4 md:gap-4">
            <div>
              <p className="truncate text-sm font-medium text-indigo-600">
                {name}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <EnvelopeIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span className="truncate">{studentInfo?.email ?? ""}</span>
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {studentInfo?.contact ? (
                  <PhoneIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                <span className="truncate">
                  {studentInfo?.contact ? studentInfo?.contact : ""}
                </span>
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {referral.updatedAt ? (
                  <ClockIcon
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <></>
                )}
                <span className="">
                  {referral.updatedAt ? format(referral?.updatedAt) : ""}
                </span>
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <p className="text-sm text-gray-900">Job IDs: </p>
                {renderJobIds}
              </div>
            </div>

            <div className="hidden md:block">
              <a
                type="button"
                href={studentInfo?.resumeLink}
                target="_blank"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Resume
                <ArrowDownTrayIcon
                  className="ml-3 -mr-1 h-5 w-5"
                  aria-hidden="true"
                />
              </a>
            </div>

            <div className="hidden md:block">
              <span className="isolate inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  onClick={handleRejectRequest}
                  className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  Reject
                </button>
                <button
                  onClick={onAcceptReferral}
                  type="button"
                  className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  Accept
                </button>
              </span>
            </div>
          </div>
        </div>
        <div>
          <button onClick={handleOpenOverlay}>
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </div>
    </li>
  );
}
