import { useEffect } from "react";
import PricingComponent from "./components/PricingComponent";
import MainNavbar from "../../common/components/MainNavbar";

export default function Pricing() {
  const TITLE = "Pricing plans for all of your need by FutureForte";
  useEffect(() => {
    document.title = TITLE;
  }, []);
  return (
    <>
      <MainNavbar />
      <PricingComponent />
    </>
  );
}
