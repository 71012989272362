import { PhotoIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import AXIOS_CLIENT from "../../../../utils/api_client";
import AlertBanner from "../../../../common/components/AlertBanner";
import RenderCoverLetter from "./RenderCoverLetter";
import axios from "axios";
import LoadingPulse from "../../../../common/components/LoadingPulse";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function CoverLetterGenForm() {
  const [cvHeader, setCvHeader] = useState();
  const [cvBody, setCvBody] = useState();
  const [file, setFile] = useState();
  const [fileName, setFilename] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const [fullName, setFullName] = useState();
  const [companyName, setCompanyName] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [jobDesc, setJobDesc] = useState();

  function handleFile(event) {
    event.preventDefault();
    setFile(event.target.files[0]);
    setFilename(event.target.files[0].name);
  }

  function getCoverLetter(event) {
    event.preventDefault();
    setIsProcessing(true);

    const data = new FormData();
    data.append("file", file);
    data.append("filename", fileName);
    data.append("fullName", fullName);
    data.append("jobTitle", jobTitle);
    data.append("jobDesc", jobDesc);
    data.append("companyName", companyName);

    axios
      .post("https://api2.futureforte.co/api/services/cover-letter", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((response) => {
        setIsProcessing(false);
        if (response.data.cvBody) setCvBody(response.data.cvBody);
        if (response.data.cvHeader) setCvHeader(response.data.cvHeader);
      })
      .catch((error) => {
        setIsDanger(true);
        setErrorMessage(error.toString());
        setIsError(true);
        setIsProcessing(false);
      });
  }

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl">
          <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
            <form
              className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-3"
              onSubmit={getCoverLetter}
            >
              <div className="px-4 py-6 sm:p-8">
                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="website"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Full name
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                          value={fullName}
                          type="text"
                          name="full-name"
                          id="full-name"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="Tony Stark"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Company name
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                        value={companyName}
                        type="text"
                        name="company-name"
                        id="company-name"
                        placeholder="FutureForte"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Job title
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => {
                          setJobTitle(e.target.value);
                        }}
                        value={jobTitle}
                        type="text"
                        name="job-title"
                        id="job-title"
                        placeholder="Software Engineer"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Job decription
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        onChange={(e) => {
                          setJobDesc(e.target.value);
                        }}
                        placeholder="Add job requirements, like "
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                        value={jobDesc}
                      />
                    </div>
                  </div>

                  <div className="col-span-full">
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                      <div className="text-center">
                        <PhotoIcon
                          className="mx-auto h-12 w-12 text-gray-300"
                          aria-hidden="true"
                        />

                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                          >
                            <span>
                              {fileName ? fileName : "Upload a resume"}
                            </span>
                            <input
                              required
                              accept="application/pdf"
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              className="sr-only"
                              onChange={handleFile}
                            />
                          </label>
                          <p className="pl-1">
                            {fileName ? "" : "or drag and drop"}
                          </p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">
                          PDF, up to 1MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center mb-4">
                <button
                  type="submit"
                  disabled={isProcessing}
                  className={classNames(
                    isProcessing
                      ? "px-3 py-2 bg-indigo-400 h-max w-max rounded-lg text-white font-bold hover:bg-indigo-300 hover:cursor-not-allowed"
                      : "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                >
                  {isProcessing ? "Processing..." : "Generate Cover Letter"}
                </button>
              </div>
            </form>
          </div>

          {isProcessing ? (
            <LoadingPulse />
          ) : (
            <RenderCoverLetter cvHeader={cvHeader} cvBody={cvBody} />
          )}
        </div>
      </div>
      <AlertBanner
        errorMessage={errorMessage}
        isDanger={isDanger}
        setIsError={setIsError}
        isError={isError}
      />
    </>
  );
}
