import { useNavigate } from "react-router-dom";
import { USER_TYPE } from "../constants/constants";
import { useAuth } from "../context/cognitoUser";
import { useEffect } from "react";

export default function DashboardCommon() {
  const navigate = useNavigate();
  const { globalUserType } = useAuth();

  useEffect(() => {
    globalUserType === USER_TYPE.EMP
      ? navigate("/employee/dashboard")
      : navigate("/student/dashboard");
  }, [globalUserType]);

  return <>Redirecting</>;
}
