import { useEffect, useState } from "react";
import MainNavbar from "../../../../common/components/MainNavbar";
import JobTableMain from "../../../JobBoard/JobsTableMain";
import { FALL_INTERNSHIP_LIST } from "../../../../constants/all_opps";

export default function Fall23Internship({
  sid,
  email,
  studentInfo,
  totalOpenReferral,
  setTotalOpenReferral,
}) {
  const TITLE = "Fall 2023 internship list by FutureForte";
  useEffect(() => {
    document.title = TITLE;
  }, []);

  const [curOpp, setCurOpp] = useState(FALL_INTERNSHIP_LIST);

  return (
    <>
      <MainNavbar />
      <h1 className="flex justify-center items-baseline border-b border-gray-200 pt-24 pb-6 text-4xl font-bold tracking-tight text-gray-900">
        Fall 2023 Internship
      </h1>
      <JobTableMain
        sid={sid}
        email={email}
        studentInfo={studentInfo}
        curOpp={curOpp}
        totalOpenReferral={totalOpenReferral}
        setTotalOpenReferral={setTotalOpenReferral}
      />
    </>
  );
}
