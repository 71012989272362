export const mentorsList = [
  {
    id: 1,
    name: "Vatsal Nahata",
    title: "Research Analyst at IMF",
    company: "IMF",
    rating: 5.0,
    reviewCount: 3,
    linkedInUrl: "https://www.linkedin.com/in/vatsalnahata/",
    imageSrc: "/assets/mentors/vatsal.jpg",
    imageAlt: "Vatsal is mentoring on FutureForte",
    href: "https://topmate.io/vatsalnahata",
    tags: ["resume-review", "mock-interview", "career-guidance"],
  },
  {
    id: 2,
    name: "Ashutosh Hathidara",
    title: "Machine Learning Engineer",
    company: "TikTok",
    rating: 5.0,
    reviewCount: 3,
    linkedInUrl: "https://www.linkedin.com/in/ashutosh1919/",
    imageSrc: "/assets/mentors/ashutosh.jpg",
    imageAlt: "Ashutosh is mentoring at FutureForte",
    href: "https://topmate.io/ashutosh1919",
    tags: ["resume-review", "mock-interview", "ai-ml"],
  },
  {
    id: 3,
    name: "Hardik Ruparel",
    title: "Staff Software Engineer",
    company: "Nutanix",
    rating: 5.0,
    reviewCount: 3,
    linkedInUrl: "https://www.linkedin.com/in/hardik-ruparel/",
    imageSrc: "/assets/mentors/hardik.jpg",
    imageAlt: "TODO",
    href: "https://adplist.org/mentors/hardik-ruparel",
    tags: ["mock-interview", "software-engineering"],
  },
  {
    id: 4,
    name: "Phani Sai Ram Munipalli",
    title: "Product Manager ",
    company: "Walmart",
    rating: 5.0,
    reviewCount: 3,
    linkedInUrl: "https://www.linkedin.com/in/iamphanisairam/",
    imageSrc: "/assets/mentors/phani.jpg",
    imageAlt: "TODO",
    href: "https://topmate.io/iamphanisairam",
    tags: ["mock-interview", "resume-review", "product-manager"],
  },
  {
    id: 5,
    name: "Aryyama Kumar Jana",
    title: "Software Engineer",
    company: "Amazon",
    rating: 5.0,
    reviewCount: 3,
    linkedInUrl: "https://www.linkedin.com/in/aryyamajana/",
    imageSrc: "/assets/mentors/aryamma.jpg",
    imageAlt: "TODO",
    href: "https://topmate.io/aryyama_kumar_jana",
    tags: ["mock-interview", "resume-review", "software-engineering"],
  },
  {
    id: 6,
    name: "Shreeshiv Patel",
    title: "CTO at FutureForte",
    company: "FutureForte",
    rating: 5.0,
    reviewCount: 3,
    linkedInUrl: "https://www.linkedin.com/in/shreeshiv/",
    imageSrc: "/assets/mentors/shreeshiv.jpg",
    imageAlt: "TODO",
    href: "https://topmate.io/shreeshiv",
    tags: ["mock-interview", "resume-review", "software-engineering"],
  },
];
