import { Navigate } from "react-router-dom";

const PrivateRouteEmp = ({ loggedInEmpUser, children }) => {
  const isAuth = localStorage.getItem("isAuth");

  if (!isAuth) {
    return <Navigate to="/signin" replace />;
  }

  return children;
};
export default PrivateRouteEmp;
