export default function RenderListStudentOverlay({ arr, label }) {
  return arr ? (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        <ul role="list" className=" divide-gray-200">
          {arr.map((item) => (
            <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-sm font-medium text-indigo-800">
              {item.value}
            </span>
          ))}
        </ul>
      </dd>
    </div>
  ) : (
    <></>
  );
}
