import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AXIOS_CLIENT from "../../../utils/api_client";
import { Switch } from "@headlessui/react";
import AlertBanner from "../../../common/components/AlertBanner";

export default function Registration({
  sid,
  isRegisterForContest1,
  setIsRegisteredForContest1,
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDanger, setIsDanger] = useState(false);
  const openToWorkOptions = ["Yes", "No"];
  const roleLevelsOptions = ["Internship", "New-grad", "Mid-senior"];
  const [openToWork, setOpenToWork] = useState();
  const [roleLevels, setRoleLevels] = useState();
  const [gender, setGender] = useState("");
  const genders = ["Male", "Female", "Other"];

  const [isVisaSponsorship, setVisaSponsorship] = useState();

  const navigate = useNavigate();
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function handleRegistration(e) {
    const formaData = new FormData(e.currentTarget);
    const hackerRankHandle = formaData.get("hackerrank");
    e.preventDefault();
    setIsProcessing(true);
    const userObject = {
      isRegisterForContest1: true,
      hackerRankHandle: hackerRankHandle,
      isVisaSponsorship: isVisaSponsorship,
      openToWork: openToWork,
      roleLevels: roleLevels,
      gender: gender,
      isVisaSponsorship: isVisaSponsorship,
    };
    if (sid) {
      AXIOS_CLIENT.put(`student/user/${sid}`, userObject)
        .then((res) => {
          setIsDanger(false);
          setIsError(true);
          setErrorMessage("Your data is successfully updated!");
          setIsProcessing(false);
          setIsRegisteredForContest1(true);
        })
        .catch((error) => {
          setIsDanger(true);
          setIsError(true);
          setIsProcessing(false);
          alert(error.toString());
        });
      setIsProcessing(false);
    } else {
      alert("Please register first!");
      navigate("/signup");
      setIsProcessing(false);
    }
  }

  return (
    <>
      <div className="flex flex-col">
        {/* <div className="flex min-h-full flex-col justify-center py-2 sm:px-2 lg:px-8">
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md max-w-screen-lg"> */}
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={handleRegistration}>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-900 font-semibold text-left"
              >
                HackerRank ID for prizes (optional)
              </label>
              <div className="mt-1">
                <input
                  id="hackerrank"
                  name="hackerrank"
                  type="text"
                  autoComplete="hackerrank"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            {/* Job preferences */}
            <div className="pt-2">
              <div>
                <h3 className="text-lg font-semibold leading-6 text-gray-900 text-left">
                  Job Preference/ Equal employment
                </h3>
                {/* Open to work */}
                <div className="pt-2 flex flex-col">
                  <label className="text-gray-900 font-semibold text-left">
                    Are you actively looking for new opportunity?
                  </label>

                  <fieldset className="mt-2">
                    <legend className="sr-only">Looking for jobs</legend>
                    <div className="space-y-4 sm:flex  sm:space-x-10 sm:space-y-0">
                      {openToWorkOptions.map((temp1) => (
                        <div key={temp1} className="flex items-center">
                          <input
                            id={temp1}
                            name="openToWork"
                            type="radio"
                            onClick={(event) => {
                              setOpenToWork(event.target.value);
                            }}
                            value={temp1}
                            checked={temp1 === openToWork}
                            // checked={temp1 === gender}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor={temp1}
                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                          >
                            {temp1}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>

                {/* Role levels */}
                <div className="pt-2 flex flex-col">
                  <label className="text-gray-900 font-semibold text-left">
                    {" "}
                    What level of role are you looking for?
                  </label>

                  <fieldset className="mt-2">
                    <legend className="sr-only">Looking for jobs</legend>
                    <div className="space-y-4 sm:flex  sm:space-x-10 sm:space-y-0">
                      {roleLevelsOptions.map((temp1) => (
                        <div key={temp1} className="flex items-center">
                          <input
                            id={temp1}
                            name="roleLevels"
                            type="radio"
                            onClick={(event) => {
                              setRoleLevels(event.target.value);
                            }}
                            value={temp1}
                            checked={temp1 === roleLevels}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor={temp1}
                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                          >
                            {temp1}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>

                <div className="pt-2 flex flex-col">
                  <label className="text-gray-900 font-semibold text-left">
                    Gender
                  </label>

                  <fieldset className="mt-2">
                    <legend className="sr-only">Gender</legend>
                    <div className="space-y-4 sm:flex  sm:space-x-10 sm:space-y-0">
                      {genders.map((temp1) => (
                        <div key={temp1} className="flex items-center">
                          <input
                            id={temp1}
                            name="gender"
                            type="radio"
                            onClick={(event) => {
                              setGender(event.target.value);
                            }}
                            value={temp1}
                            // defaultChecked={temp1 === gender}
                            checked={temp1 === gender}
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                          <label
                            htmlFor={temp1}
                            className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                          >
                            {temp1}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>

                {/* Visa sponsorship */}
                <div className="pt-4 flex flex-row">
                  <label className="text-gray-900 font-semibold text-left">
                    {" "}
                    Do you need visa sponsorship?
                  </label>
                  <div className="px-8">
                    <Switch.Group as="div" className="flex items-center">
                      <Switch
                        checked={isVisaSponsorship}
                        onChange={setVisaSponsorship}
                        className={classNames(
                          isVisaSponsorship ? "bg-indigo-600" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            isVisaSponsorship
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                    </Switch.Group>
                  </div>
                </div>
              </div>
            </div>

            {/* Gender */}

            <div>
              <div className="flex justify-between pb-2">
                <div className="text-sm">
                  By signing up you agree to our
                  <a
                    href="/toc"
                    target="_blank"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    target="_blank"
                    href="/privacy-policy"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold
                 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Register
              </button>
            </div>
          </form>
          <AlertBanner
            errorMessage={errorMessage}
            isDanger={isDanger}
            setIsError={setIsError}
            isError={isError}
          />
        </div>
      </div>
      {/* </div>
      </div> */}
    </>
  );
}
