import { Route, Routes } from "react-router-dom";
import ResumeReview from "./resumeReview/ResumeReview";
import CoverLetterGen from "./coverLetterGen/CoverLetterGen";

export default function ServicesMain() {
  return (
    <Routes>
      <Route path="resume-review" element={<ResumeReview />}></Route>
      <Route path="cover-letter" element={<CoverLetterGen />}></Route>
    </Routes>
  );
}
